import React from "react"
import {ScrollView} from "react-native"
import use from "library-react/hook"
import {styles} from "@main/res"
import AuthenticateView from "@main/component/AuthenticateView";
import BasketItem from "./BasketItem";
import Spinner from "@main/component/Spinner";
import ErrorView from "@main/component/ErrorView";
import EmptyBasket from "./EmptyBasket"

export default function BasketContent({loadable, setQuantityOf, removeItem, ...props}) {
	use.subscribe(loadable?.onStateChanged, [loadable]);

	const display = use.context.display();
	const {value: items, loading, load} = loadable || {};

	props.style = use.defaultStyle(props.style, localStyles.layout);
	props.contentContainerStyle = use.defaultStyle(
		props.contentContainerStyle,
		localStyles.content(display),
		[display],
	);

	return (
		<ScrollView {...props}>
			{
				!loadable ? // authenticate
					<AuthenticateView
						title={`Connectez-vous rapidement via un de vos compte afin d'accéder à votre panier.`}
						style={localStyles.auth}/> :

					// display content
					items ? (
							items.length > 0 ?
								items.map((item, index) =>
									<BasketItem
										key={item.id}
										item={item}
										index={index}
										quantity={item.quantity} // force render item when quantity changed
										setQuantityOf={setQuantityOf}
										removeItem={removeItem}
										style={localStyles.item}
									/>
								) :
								// empty
								<EmptyBasket style={styles.fit}/>
						) :

						loading ? <Spinner style={styles.fit}/> :
							<ErrorView
								retry={load}
								style={styles.fit}/>
			}
		</ScrollView>
	);
}

const localStyles = {
	layout: {},

	content: styles.static(
		{flexGrow: 1},
		{
			mobile: {
			},

			default: { // desktop & tablet
				flexDirection: styles.flexDirection.row,
				flexWrap: styles.flexWrap.wrap,
			}
		}
	),

	auth: {
		padding: 10,
		...styles.fit,
		...styles.center,
	},

	item: {
		marginBottom: 40,
	}
};
