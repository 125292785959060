import Event from "../Event"

export default class Message extends Event {
	get ok(){
		return Boolean(
			super.ok
			&& this.author
		);
	}

	get safeText(){
		return this.text?.trim() || "";
	}

	/**
	 * Getter is overridden by ChatManager on initialisation
	 * @returns {null}
	 */
	get isAuthor(){
		return null;
	}
}

Message.addProperties({
	author: String,
	text: String
});
