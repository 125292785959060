import jsStrings from "../library-js/res/strings"
import reactStrings from "./strings"
import { clone, Objects } from "../library-js/utils";
import fonts from "./fonts"

export let strings = Objects.deepMerge(reactStrings, clone(jsStrings));

export { default as icons } from "./icons"
export { default as images } from "./image"
export { default as styles } from "./styles"
export { fonts };
