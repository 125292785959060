import React from "react";
import {styles} from "@main/res";
import use from "library-react/hook";
import View from "library-react/component/View/v2";
import Icon from "library-react/component/Icon/v2";

function ContactItem({icon, text, title, iconSize, iconStyle, ...props}) {
	const {Text} = use.theme();

	props.style = use.defaultStyle(props.style, localStyles.layout);
	iconStyle = use.defaultStyle(iconStyle, localStyles.icon);
	return (
		<View {...props}>
			<Icon name={icon} size={iconSize} style={iconStyle}/>
			<View>
				<Text style={localStyles.title}>
					{title}
				</Text>
				<Text style={localStyles.text}>
					{text}
				</Text>
			</View>
		</View>
	);
}

export default React.memo(ContactItem);

const localStyles = {
	layout: {
		alignItems: styles.alignItems.flexStart,
		flexDirection: styles.flexDirection.column
	},
	title: {
		bold: true,
		fontSize: 15,
		lineHeight: 33,
	},
	text: {
		fontSize: 15,
		lineHeight: 33,
	},
	icon: {
		marginBottom: 21,
	}
};
