import React from "react"
import { View as RNView } from "react-native"
import use from "../../../../../../hook"
import { styles } from "../../../../../../res"
import Icon from "../../../../../../component/Icon/v2";

function RemoveButton({ ...props }) {
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<Icon
			name='AntDesign/close'
			size={13}
			{...props} />
	);
}

export default React.memo(RemoveButton);


const localStyles = {
	layout: {
		...styles.absolute({ top: -5, right: -5 }),
		...styles.circle(24),
		backgroundColor: '#dad9e1',
		color: '#1e2432',
		zIndex: 1,
		elevation: 3,
	},
};
