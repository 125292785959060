let strings;
export default strings = {
	fragment: {
		order: {
			title: "Mes commandes",
			billNumber: "Facture n°",
			numberOfItems: "nombre de produits",
			state: "statut",
			sum: "total",
			phone: "Tel.",
			firstName: "Nom",
			lastName: "Prénom",
			address: "Adresse",
			zipCode: "Code postal",
			city: "Ville",
			boughtProducts: "Produits achetés",
			boughtBy: "Acheté par",
			paymentMethod: "Mode de paiement",
			cardNumber: "numéro de carte",
			cardExpiration: "expire le",
			boughtProduct: (numberOfItems) => numberOfItems > 1 ? "Produits achetés" : "Produit acheté",

			goodsReady: "Commande prête",
			goodsReceived: "Commande reçue",
			cancel: "Annuler la commande",
			totalWithoutTaxes: "Total HT",
			VAT: "TVA",
			totalTaxesIncluded: "Total TTC",
			size: "Taille",
			orderState: "Statut de la commande",
			deal: "Deal",
			sale: "Vente privée",
		},

		sale: {
			calendar: {
				sameDay: '[à] LT',
				nextDay: '[demain à] LT',
				lastDay: '[hier à] LT',
			},
			open: 'Ouvre',
			close: 'Ferme',
			buy: 'Acheter',
			availableSoon: 'Bientot disponible',
			addToBasket: 'Ajouter au panier',
			soldOut: 'Sold out',
			specificities: 'Spécificités',
			chooseSize: "Choisissez votre taille",
			select: "Sélectionner",
			to: "à",
			phone: "Tél.",
			unavailable: "Indisponible",
			productUnavailable: "Produit indisponible",
			online: "En ligne",
			offline: "Hors-ligne",
			available: "Indisponible",
			reserved: "Réservé",
		},

		chatList: {
			deal: "Deal: ",
			noChats: "Nouveau client !\nAucune conversation",
		},

		chat: {
			online: "En ligne",
			propose: "Faire une offre",
			endsIn: "Expire dans ",
			sent: "Envoyé ",
			newProductContext: "Ajouter un produit à la discussion",
			addSize: "Ajouter une taille",
			size: "Taille",
			productUpdate: (update) => `La taille a été changé en: ${update.size}`,
			newDeal: "Créer un nouveau deal",
			addDealToProduct: "Accompagner l'article d'une offre",
			deal: {
				size: "Taille: "
			},
			sending: "Envoi en cours..",
			selectProductTitle: interlocutorName => [
				"Demandez à ",
				interlocutorName,
				" un produit de son catalogue",
			],
			productInContext: "Produit en discussion",
			productContextDefaultMessage: {
				retailer: "Cet article vous intéresse t-il ?",
				user: "Est-ce que l'article ci-dessus est disponible ?",
			},

			ProductPreview: {
				dealSwitch: {
					true: "Achat activé",
					false: "Achat désactivé",
				}
			}
		},
	},

	component: {
		SelectProduct: {
			search: "Rechercher un produit"
		},

		SelectProductDialog: {
			title: "Selectionnez un article"
		},

		SelectSizeDialog: {
			title: "Selectionnez une taille",
			noSizes: "Cet article ne possède pas de tailles.",
		},

		WeekDayInput: {
			day: ["D", "L", "M", "Me", "J", "V", "S"],
		},

		OpenHoursInput: {
			from: "De",
			to: "À",
			add: "Ajouter une plage horaire",
		},

		AddressInput: {
			street: "Rue",
			number: "Numéro de rue",
			city: "Ville",
			postalCode: "Code postal",
			Country: "Pays",
		},

		CommentFrame: {
			rate: "Notes et avis",
			votes: "votes",
			yourComment: "Votre avis",
			comments: "Les avis",
			seeMore: "Voir plus",
			update: "Modifier",
			noComments: "Aucun commentaire"
		},

		RateView: {
			rates: "avis"
		}
	},
};
