/**
 * WARNING
 * You should not import any file here as it's should be the root file of the library.
 */

try { // define Async Function constructor
	window.AsyncFunction = eval("(async function(){})").constructor;
} catch (e) {
}

// ---- Error ------
// constructor error + message
Error.Code = class {
	constructor(code, message, ...params) {
		let error = new Error(message, ...params);
		error.code = code;
		return error;
	}
};
// code setter
Error.prototype.setError = function (code) {
	this.code = code;
	return this;
};

// ---- Array ----
Object.defineProperties(Array.prototype, {
	head: {
		configurable: true,
		get: function () {
			return this.first;
		},

		set: function (value) {
			this.first = value;
		}
	},

	first: {
		configurable: true,
		get: function () {
			return this[0];
		},

		set: function (value) {
			this[0] = value;
		}
	},

	tail: {
		configurable: true,
		get: function () {
			return this.last;
		},

		set: function (value) {
			this.last = value;
		}
	},

	last: {
		configurable: true,
		get: function () {
			return this[this.lastIndex]
		},

		set: function (value) {
			this[this.lastIndex] = value;
		}
	},

	lastIndex: {
		configurable: true,
		get: function () {
			return Math.max(this.length - 1, 0);
		}
	},
});


// ---- flatMap ----
if (Array.prototype.flatMap) { // add default mapper parameter
	const originalFlatmap = Array.prototype.flatMap;
	Array.prototype.flatMap = function (mapper = (item => item), ...params) {
		return originalFlatmap.apply(this, [mapper, ...params]);
	};
} else // polyfill
	Array.prototype.flatMap = function (mapper = (item => item), thisArg) {
		if (thisArg)
			mapper = mapper.bind(thisArg);

		return this.reduce((result, item) => result.concat(mapper(item)), []);
	};

// Array.prototype.flat = Array.prototype.flatMap;

// ---- fill ----
if (!Array.prototype.fill)
// polyfill from MDN
	Array.prototype.fill = function (value) {
		let copy = Object(this);

		// Steps 3-5.
		let len = copy.length >>> 0;

		// Steps 6-7.
		let start = arguments[1];
		let relativeStart = start >> 0;

		// Step 8.
		let k = relativeStart < 0 ?
			Math.max(len + relativeStart, 0) :
			Math.min(relativeStart, len);

		// Steps 9-10.
		let end = arguments[2];
		let relativeEnd = end === undefined ?
			len : end >> 0;

		// Step 11.
		let final = relativeEnd < 0 ?
			Math.max(len + relativeEnd, 0) :
			Math.min(relativeEnd, len);

		// Step 12.
		while (k < final) {
			copy[k] = value;
			k++;
		}

		// Step 13.
		return copy;
	};


// ---- static range ----
let setIndexAsValue = i => i;
Array.range = function (length, valueOrFunction = setIndexAsValue) {
	if (!(length >= 0))
		length = 0;

	let array = [];
	for (let i = 0; i < length; i++) {
		if (typeof valueOrFunction === "function")
			array[i] = valueOrFunction(i, length);
		else
			array[i] = valueOrFunction;
	}

	return array;
};

// --- The following map function disables stacktrace-js (web) ----
// Array.prototype.map = function(callback, thisArg) {
// 	if (!thisArg)
// 		thisArg = this;
//
// 	let result = [];
//
// 	for (let index = 0; index < thisArg.length; index++)
// 		result.push(callback(thisArg[index], index, thisArg, result));
//
// 	return result;
// };


Object.defineProperties(Array.prototype, {
	copy: {
		enumerable: false,
		writable: true,
		configurable: true,
		value: function () {
			return this.slice();
		}
	},
	distinct: {
		enumerable: false,
		writable: true,
		configurable: true,
		value: function (areSame) {
			if (!areSame)
				areSame = (item, item2) => (item === item2);

			return this.filter((item, index) =>
				!index // get first item anyways
				|| !this.slice(0, index)
					.some(item2 => areSame(item, item2))
			);
		}
	},
	/**
	 * Map filtering items in one go.
	 * Better performance than chaining filter & map.
	 * @param {Function?} mapper The mapper function. Default behavior will map item as it is.
	 * @param {Function?} filter The filter function. Default behavior will accept all item.
	 * @returns {Array} The array filtered & mapped.
	 */
	mapFiltering: {
		enumerable: false,
		writable: true,
		configurable: true,
		value: function (mapper, filter) {
			const result = [];
			this.forEach((...params) => {
				if (!filter || filter(...params))
					result.push(mapper ? mapper(...params) : params.first);
			});
			return result;
		}
	},
	/**
	 * Maps all values of an array, into an object.
	 * The key for each value is defined by the 2nd parameter which is a function which generate the key for a given value (passed as parameter).
	 * @param {Function?} keyGenerator Function which receive an item and its index from the array, and return the corresponding key in which the value will be stored inside the object. Default function returns the index as key.
	 * @param {Function?} valueConverter Function which receive an item, along with its index and its generated key, from the array, and return the corresponding value to set. Default function will return the item as value.
	 * @return {Object} The built object.
	 */
	toObject: {
		enumerable: false,
		writable: true,
		configurable: true,
		value: function (keyGenerator, valueConverter) {
			// default key generator
			keyGenerator = keyGenerator || ((item, index) => index);
			valueConverter = valueConverter || (item => item);

			let object = {};
			this.forEach((value, index) => {
				const key = keyGenerator(value, index);
				object[key] = valueConverter(value, index, key);
			});
			return object;
		}
	},
	/**
	 * Will filter item according to a key return by the filter function, and place them in an object.
	 * valueConverter function is just an optional way to convert items by the way of filtering.
	 * @param {Function} filter Function to filter items. It should return a key for different items.
	 * @param {Function?} valueConverter Function to convert data.
	 * @returns {Object} Map containing all arrays of filtered items.
	 * @example
	 *     // returns { true : [3, 4, 5], false : [1, 2] }
	 *     [1, 2, 3, 4, 5].groupBy(value => value >= 3);
	 * @example
	 *     // using the mapper function
	 *     // returns { true : [3, 4, 5], false : [1, 2] }
	 *     [{number: 1}, {number: 2}, {number: 3}, {number: 4}, {number: 5}]
	 *     		.groupBy(({number}) => number >= 3, ({number}) => number);
	 */
	groupBy: {
		enumerable: false,
		writable: true,
		configurable: true,
		value: function (filter, valueConverter, requiredGroups) {
			// default value convertor
			valueConverter = valueConverter || (item => item);

			let groups = {};

			this.forEach((value, index) => {
				let groupKey = filter(value, index, requiredGroups || []);
				// check key
				if (!isPrimitive(groupKey))
					console.warn("The returned key by the key generator function is not a primitive.");

				// check if an array is created
				if (!groups[groupKey])
					groups[groupKey] = [];

				groups[groupKey].push(valueConverter(value, index, groupKey));
			});

			// fill required groups
			if (requiredGroups)
				requiredGroups.forEach(group => {
					if (!groups[group])
						groups[group] = [];
				});

			return groups;
		}
	},

	findLastIndex: {
		enumerable: false,
		writable: true,
		configurable: true,
		value: function (predicate) {
			let index = this.length - 1;

			while (index >= 0 && !predicate(this[index], index))
				index--;

			return index;
		}
	},
	findLast: {
		enumerable: false,
		writable: true,
		configurable: true,
		value: function (predicate) {
			let index = this.findLastIndex(predicate);
			if (index >= 0)
				return this[index];
		}
	},
	findAllIndex: {
		enumerable: false,
		writable: true,
		configurable: true,
		value: function (predicate) {
			let indexes = [];

			this.forEach(function (item, index) {
				if (predicate(item, index))
					indexes.push(index);
			});

			return indexes;
		}
	},
	move: {
		enumerable: false,
		writable: true,
		configurable: true,
		value: function (from, to) {
			this.splice(to, 0, ...this.splice(from, 1));
		}
	},
	swap: {
		enumerable: false,
		writable: true,
		configurable: true,
		value: function (index1, index2) {
			let temp = this[index1];
			this[index1] = this[index2];
			this[index2] = temp;
		}
	},
	reorder: {
		enumerable: false,
		writable: true,
		configurable: true,
		value: function (index, range, to) {
			// correct index
			index = Number(index); // number only
			if (!index)
				index = 0;
			else if (index < 0) // starts from the end
				index = this.length + Math.max(index, -(this.length));
			else if (index > this.length) // correct to not overflow
				index = this.length;

			// correct range
			range = Number(range); // number only
			if (!range)
				range = 0;
			else if (range < 0) { // range takes items previous to the index
				index = index + range;
				range = -range;
				// correct
				if (index < 0) {
					let overflow = index;
					index = 0;
					range += overflow;
				}
			} else if (index + range > this.length)
				range = this.length - index;


			let copy = this.copy();
			let items = copy.splice(index, range);
			copy.splice(to, 0, ...items);

			return copy;
		}
	},
	resplice: {
		enumerable: false,
		writable: true,
		configurable: true,
		value: function (...params) {
			const copy = this.copy();
			copy.splice(...params);
			return copy;
		}
	},

	repush: {
		enumerable: false,
		writable: true,
		configurable: true,
		value: function (...added) {
			let copy = this.copy();
			copy.push(...added);
			return copy;
		}
	},

	replaceAt: {
		enumerable: false,
		writable: true,
		configurable: true,
		value: function (index, numberToDelete, ...itemsToInsert) {
			let copy = this.copy();
			copy.splice(index, numberToDelete, ...itemsToInsert);
			return copy;
		}
	},

	reverseForEach: {
		enumerable: false,
		writable: true,
		configurable: true,
		value: function (doOnEach) {
			for (let index = (this.length - 1); index >= 0; index--)
				doOnEach(this[index], index);
		}
	},

	set: {
		enumerable: false,
		writable: true,
		configurable: true,
		value: function (key, value) {
			this[key] = value;
			return this;
		}
	},

	match: {
		enumerable: false,
		writable: true,
		configurable: true,
		value: function (array) {
			if (!array || !(array.length >= 0))
				return false;

			let match = true;
			let length = Math.max(this.length, array.length);
			for (let i = 0; i < length && match; i++)
				match = Object.is(this[i], array[i]);

			return match;
		}
	},

	startsWith: {
		enumerable: false,
		writable: true,
		configurable: true,
		value: function (array) {
			if (!(array instanceof Array))
				return false;

			return array.every((item, index) => Object.is(item, this[index]));
		}
	},

	getClosest: {
		enumerable: false,
		writable: true,
		configurable: true,
		value: function (index) {
			if (index < 0)
				return this.first;

			if (index >= this.length)
				return this.last;

			return this[index];
		}
	},

	clear: {
		enumerable: false,
		writable: true,
		configurable: true,
		value: function () {
			this.splice(0, this.length);
		}
	},
});


// ------ Object ------
if (!Object.is)
	Object.is = function (v1, v2) {
		if (v1 === v2) //Étapes 1-5, 7-10
			return v1 !== 0 || 1 / v1 === 1 / v2;
		return v1 !== v1 && v2 !== v2;
	};

// ------ Function ------
// NOT STABLE: impossible to bind the function to the original method's instance
Function.prototype.runAsync = async function (...params) {
	return this(...params);
};

Function.prototype.set = function (property, value) {
	this[property] = value;
	return this;
};

// ----- Promise ----
{
	const then = Promise.prototype.then;
	const copyProperties = (from, to) => {
		to._shouldStopOn = from._shouldStopOn;
	};
	Promise.prototype.then = function (onFulfilled, onRejected) {
		if (!onFulfilled && !onRejected)
			onFulfilled = result => result;

		const promise = then.call(this,
			onFulfilled && (
				(result) => {
					if (!this._shouldStopOn || !this._shouldStopOn())
						return onFulfilled(result);
				}
			),
			onRejected && ((result) => {
				if (!this._shouldStopOn || !this._shouldStopOn())
					return onRejected(result);
			})
		);

		copyProperties(this, promise);
		return promise;
	};
	Promise.prototype.stopOn = function (callback) {
		const promise = this.then(result => result);
		promise._shouldStopOn = callback;
		return promise;
	};

	Promise.await = (milliseconds = 0) => new Promise(resolve => setTimeout(resolve, milliseconds));
	Promise.resultAll = promises => Promise.all(
		promises.map(promise =>
			promise.result((result, error) => [result, error])
		)
	);

	Promise.process = stopOn => Promise.resolve().stopOn(stopOn);

	// Promise.all accept object
	const all = Promise.all.bind(Promise);
	Promise.all = async promises => {
		if (promises instanceof Array) {
			promises = promises.map(Promise.resolve.bind(Promise));
			return all(promises);
		}

		if (!(promises instanceof Promise) && promises instanceof Object) {
			const map = new Map();
			const promisesArray = [];
			for (let key of Object.keys(promises)) {
				let promise = promises[key];
				if (!(promise instanceof Promise))
					promise = Promise.resolve(promise);

				map.set(promise, key);
				promisesArray.push(promise);
			}

			return all(promisesArray)
				.then(results => results.toObject(
					(result, index) => map.get(promisesArray[index])
					)
				);
		}
	};

	Promise.prototype.result = function (onResult) {
		return this.then(
			result => onResult ? onResult(result, undefined) : [result, undefined],
			error => {
				if (onResult)
					onResult(undefined, error);
				else
					console.warn(error);

				return [undefined, error];
			}
		);
	};
	Promise.prototype.transparent = function (procedure) {
		this.result(procedure);
		return this;
	};
	Promise.prototype.anyway = function (run) {
		return this.result((_, error) => {
			if (error)
				console.warn(error);

			return run();
		});
	};

	Promise.result = (result, error) => error ? Promise.reject(error) : Promise.resolve(result);

	Promise.external = () => {
		let resolve, reject;
		const promise = new Promise((res, rej) => {
			resolve = res;
			reject = rej;
		});
		return [
			promise,
			value => {
				resolve(value);
				return promise;
			},
			reason => {
				reject(reason);
				return promise;
			},
		];
	};


// add to promise a getter "cancelable", which will attach a 'cancel' method to it.
	Object.defineProperties(Promise.prototype, {
		cancelable: {
			configurable: true,
			get: function () {
				let canceled = false;
				this.cancel = () => {
					canceled = true
				};

				return this.then(result => {
					if (canceled)
						throw {canceled: true};
					return result;
				}).catch(error => {
					if (canceled)
						throw {canceled: true};
					throw error;
				});
			}
		}
	});
}

// ---- strings ----
// TODO no working in react
Object.defineProperties(String.prototype, {
	first: {
		configurable: true,
		get: function () {
			return this[0];
		},

		set: function (value) {
			this[0] = value;
		}
	},

	last: {
		configurable: true,
		get: function () {
			return this[this.lastIndex]
		},

		set: function (value) {
			this[this.lastIndex] = value;
		}
	},

	lastIndex: {
		configurable: true,
		get: function () {
			return this.length ? this.length - 1 : 0;
		}
	},

	firstUp: {
		configurable: true,
		get: function () {
			return (this[0] || '').toUpperCase() + this.slice(1);
		}
	},

	firstDown: {
		configurable: true,
		get: function () {
			return (this[0] || '').toLowerCase() + this.slice(1);
		}
	},

	dot: {
		configurable: true,
		get: function () {
			return this + ".";
		}
	}
});


// ---- select  & run ----
Boolean.prototype.select =
	Number.prototype.select =
		String.prototype.select = function (map) {
			if (this in map)
				return map[this];

			return map.default;
		};

Boolean.prototype.run =
	Number.prototype.run =
		String.prototype.run = function (map) {
			if (this in map)
				return map[this]();

			return map.default && map.default();
		};


// nanoseconds
try { // navigator
	if (window.performance.now())
		Date.rightNow = () => ((Date.now() + (window.performance.now() % 1)) * Math.pow(10, 6));
} catch (e) {
	try { // node
		if (process.hrtime())
			Date.rightNow = () => {
				let times = process.hrtime();
				return times[0] * Math.pow(10, 9) + times[1];
			};
	} catch (e) {
		try { // react native
			if (global.nativePerformanceNow())
				Date.rightNow = () => ((Date.now() + (global.nativePerformanceNow() % 1)) * Math.pow(10, 6));
		} catch (e) {
			Date.rightNow = () => (Date.now() * Math.pow(10, 6));
		}
	}
}

// Time constants
Date.SECOND = 1000;
Date.MINUTE = 60 * Date.SECOND;
Date.HOUR = 60 * Date.MINUTE;
Date.DAY = 24 * Date.HOUR;
Date.WEEK = 7 * Date.DAY;
Date.MONTH = 30 * Date.DAY; // approximately
Date.YEAR = 365 * Date.DAY;

// timeout.new
setTimeout.new = (callback, timeout) => {
	let key = setTimeout(callback, timeout);
	return () => clearTimeout(key);
};

/**
 * Compare 2 values with a tolerance for the difference between them.
 */
Math.equals = (value1, value2, tolerance = 0) => {
	if (!tolerance)
		tolerance = 0;

	// accept tolerance as string
	if ((typeof tolerance) === "string") {
		// percentage
		if (tolerance[tolerance.length - 1] === '%') {
			const percent = Number(tolerance.slice(0, -1));
			// tolerance is the max percentage of one of the 2 values
			tolerance = value1 / percent;
		} else // convert to number
			tolerance = Number(tolerance);
	}

	return Math.abs(value1 - value2) < tolerance;
};

// make Math.round accept as 2nd parameter the number of decimal to round
const round = Math.round;
Math.round = (x, decimal = 0) => round(x * (10 ** decimal)) / (10 ** decimal);


// -- console --
try {
	console.watch = (fn, tag) => {
		if (fn instanceof Function)
			return (...params) => {
				const result = fn(...params);

				const log = (returned = result) => console.log({[tag || fn.name || 'watching']: {params, returned}});
				if (!(result instanceof Promise))
					log();
				else
					result.then(log);

				return result;
			};

		return fn;
	};

	// console.log.once
	['log', 'warn', 'error'].forEach(key => {
		const channel = console[key];
		console[`${key}Once`] = (data) => {
			if (!loggedOnce.has(data)) {
				loggedOnce.add(data);
				channel(data);
			}
		};
	});
	const loggedOnce = new Set();
} catch (consoleWatchError) {
	console.error({consoleWatchError});
}


// --------------------
const primitives = ["number", "string", "boolean"];

function isPrimitive(value) {
	return !value || primitives.includes(typeof value);
}


// --- web ---
try {
	EventTarget.prototype.on = function (type, listener, options) {
		this.addEventListener(type, listener, options);
		return () => this.removeEventListener(type, listener, options);
	}
} catch (e) {
	// not web
}
