import { pipe, map, filter, join, trim } from "ramda"
import isDefined from "../isDefined";

export default function smartJoin(separatorOrArray, maybeArray) {
	if (Array.isArray(separatorOrArray))
		return smartJoinCore(' ', separatorOrArray);

	else {
		if (isDefined(maybeArray))
			return smartJoinCore(separatorOrArray, maybeArray);

		// curry
		return (items) => smartJoinCore(separatorOrArray, items);
	}

}

function smartJoinCore(separator, items) {
	return pipe(
		filter(isDefined), // remove nils
		map(pipe(String, trim)),
		filter(Boolean), // remove empty strings
		join(separator)
	)(items);
}