import use from "../../../hook";
import {parallel} from "../../../library-js/utils/function";
import is from "../../../library-js/utils/is";

export default function (props, data) {
	const {hoverable, onHoverChanged, hoverStyle} = props;
	delete props.hoverable;
	delete props.onHoverChanged;
	delete props.hoverStyle;

	const [hover, {yes, no}] = use.state.bool(false);
	data.hover = hover;
	use.onChange(
		onHoverChanged,
		[hover],
	);

	if (is.defined(hoverable) ? hoverable : ((onHoverChanged instanceof Function) || hoverStyle)) {
		props.onMouseEnter = parallel([props.onMouseEnter, yes]);
		props.onMouseLeave = parallel([props.onMouseLeave, no]);
	}

	props.style = use.defaultStyle(props.style, hover && hoverStyle, [hover, hoverStyle]);
}
