import React from "react"
import {ScrollView} from "react-native"
import use from "../../../hook"
import ItemRow from "./ItemRow";
import CheckoutStateContext from "../CheckoutUI/CheckoutStateContext";
import Portal from "../../../component/Portal";

function ItemsReview({prodshopToRoute, ...props}) {
	const {items} = use.context(CheckoutStateContext);

	props.style = use.defaultStyle(props.style, localStyles.layout);
	props.contentContainerStyle = use.defaultStyle(props.contentContainerStyle, localStyles.content);
	return (
		<ScrollView {...props}>
			{
				items?.map(vItem => {
					let row = <ItemRow key={vItem.id} item={vItem}/>;
					if (prodshopToRoute){
						const route = prodshopToRoute(vItem.prodshop);
						row = (
							<Portal key={vItem.id} {...route}>
								{row}
							</Portal>
						);
					}
					return row;
				})
			}
		</ScrollView>
	);
}

export default React.memo(ItemsReview);


const localStyles = {
	layout: {},

	content: {
		flexGrow: 1,
	}
};
