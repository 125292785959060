import {adjust, assoc, drop, identity, map, nthArg, pipe, reduce, split, startsWith, tryCatch, when} from "ramda";

const decode = pipe(
	when(startsWith('?'), drop(1)),
	split('&'),
	map(pipe(
		split('='),
		map(decodeURIComponent),
		adjust(1, tryCatch(JSON.parse, nthArg(1))),
	)),
	reduce((params, [key, value]) => assoc(key, value, params), {/* accumulator */}),
	map(identity), // if empty, prevents to return the original accumulator
);

export default decode;
