import React from "react"
import {Pipe} from "../utils";
import use from "../hook";
import ComponentUtils from "../ComponentUtils";
import {is} from "../library-js/utils";

function PipedOut({from: pipe, children}) {
	// correct pipe if necessary
	pipe = use.memo(() => {
		let pipeInstance = pipe;

		if (!(pipeInstance instanceof Pipe)) {
			pipeInstance = new Pipe();
			pipeInstance.in(pipe);
		}

		return pipeInstance;
	}, [pipe]);

	// listen for changes
	const forceUpdate = use.forceUpdate();
	const memory = use.memo({last: pipe.last}, [pipe]);
	use.effect(() => {
		// subscribe to pipe
		const remove = pipe.out(value => {
			if (value !== memory.last) {
				memory.last = value;
				forceUpdate();
			}
		});
		// check if value changed since last render
		if (pipe.last !== memory.last)
			forceUpdate();
		// return undo-subscription
		return remove;
	}, [pipe]);

	// render value
	const child = ComponentUtils.childrenToArray(children).first;
	return child ? child(pipe.last) : null;
}

export default React.memo(
	PipedOut,
	function areEquals(oldProps, props) {
		return props.memoize
			&& (oldProps.from === props.from)
			&& matchDependencies(oldProps.dependencies, props.dependencies);
	}
);

function matchDependencies(oldDependencies, dependencies) {
	if (oldDependencies === dependencies) // same instance
		return true;

	if (!oldDependencies && !dependencies) // both null
		return true;

	const defined1 = oldDependencies || dependencies;
	const defined2 = defined1 === oldDependencies ? dependencies : oldDependencies;

	if (!defined2)
		return defined1.every(is.null);

	return defined1.match(defined2);
}
