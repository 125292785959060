import React from "react"
import {ScrollView} from "react-native";
import use from "library-react/hook";
import {styles} from "@main/res"
import BrandView from "@main/component/BrandView";
import TitleWithBack from "@main/component/TitleWithBack";
import ProductGrid from "@main/component/ProductGrid";
import Button from "@main/component/Button";

export default function DesktopUI({brand, iterator, onSelected, ...props}) {
	const {Text, Spinner} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<ScrollView contentContainerStyle={localStyles.content} {...props}>
			<TitleWithBack>
				{brand?.name}
			</TitleWithBack>

			{
				brand.logo &&
				<BrandView
					brand={brand}
					logoStyle={localStyles.logo}
					style={localStyles.title}
					seeMore/>
			}

			{
				iterator &&
				<ProductGrid
					items={iterator.items}
					onSelected={onSelected}
					style={localStyles.items}/>
			}
			{
				Boolean(iterator?.items?.length) && (
					iterator.loading ?
						<Spinner style={localStyles.button}/> :

						!iterator.end &&
						<Button
							onPress={iterator.load}
							style={localStyles.button}>
							<Text style={localStyles.text}>
								{`Voir plus`}
							</Text>
						</Button>
				)
			}
		</ScrollView>
	);
}
const localStyles = {
	layout: {
		flex: 1,
	},

	logo: {
		margin: 20,
		height: 100,
	},

	content: {
		marginHorizontal: 71,
		marginBottom: 50,
		flexGrow: 1,
	},
	title: {
		width: "100%",
		maxWidth: 1280,
		paddingBottom: 20,
	},
	items: {
		marginTop: 80,
	},
	button: {
		alignSelf: styles.alignSelf.center,
		paddingHorizontal: 200,
		paddingVertical: 16,
		marginBottom: 50,
		marginTop: 42,
	},

	text: {
		fontSize: 23,
	},
};
