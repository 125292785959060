import Base from "../../../../class/Base"
import Rate from "./Rate"
import Shop from "../../entity/Shop"

export default class VShop extends Base {
	get id(){
		return this.shop?.id;
	}

	get name(){
		return this.shop?.name;
	}

	get logo(){
		return this.shop?.logo;
	}

	get location(){
		return this.shop?.location;
	}
}
VShop.addProperties({
	shop : Shop,
	rate : Rate,
	numberOfMembers : Number,

	// @deprecated
	// freed on: [marketer, shop-web, retailer]
	category: Shop.Category,
});
