import Debug from "library-js/Debug";
import Firebase from "library-react/Firebase/index";
import AuthManager from "library-js/AuthManager";
import assignParamsToUrl from "../../../library-react/library-js/utils/URL/assignParamsToUrl"

export default function authenticate(method) {
	const origin = window.location.href;
	const url = assignParamsToUrl({ origin, method }, AUTH_URL);

	return AuthManager.authenticating = new Promise((resolve, reject) => {
			const authWindow = window.open(url, "auth");
			const onClosed = () => reject(new Error("Auth window closed or origin changed."));

			const detach = window.on("message", ({ data: credentialJson, origin, source }) => {
				const isOrigin = origin.startsWith(AUTH_URL);
				const isAuthWindow = (source === authWindow);

				if (isAuthWindow) {
					if (isOrigin) {
						resolve(credentialJson);
						authWindow.close();
					} else
						onClosed();

					detach();
				}
			}
			);

			// pooling every seconds to check if window still existing
			const timer = setInterval(
				() => {
					// TODO check if url's domain changed
					if (authWindow.closed) {
						clearInterval(timer);
						onClosed();
						detach();
					}
				},
				1000
			);
		})
		.then(credentialJson => Firebase.auth.AuthCredential.fromJSON(credentialJson))
		.then(credential => firebase.auth().signInWithCredential(credential))
		.then(() => true)
		.catch(error => {
			console.warn(error);
			return false;
		});
}


const AUTH_URL = Debug.select({
	// development: "http://localhost:8080",
	default: "https://auth.shopinzon.com",
});
