let number = 0;

/**
 * Generator for unique strings and numbers.
 * <ul>
 *     <li>Number {@link number} : Getter which generate a unique number in the instance of the process.</li>
 *     <li>String {@link string} : Getter which generate a unique string in the instance of the process. ⚠Not ready.</li>
 *     <li>Number {@link getNumberNotIn}(Number[]) : Generate a number not contained in the passed array of numbers.</li>
 *     <li>String {@link getCharNotIn}(String) : Generate a char not contained in the passed string.</li>
 * </ul>
 */
export default {
	/**
	 * @returns {Number} A unique number (in the instance of the process).
	 */
	get number(){
		return this.getNumber();
	},

	/**
	 * Generate a number not contained in the passed array of numbers.
	 * @param {Array.<Number>} array Array of number to exclude.
	 * @returns {number} The generated number.
	 */
	getNumberNotIn(array = []){
		let map = {};
		let number = 0;
		// convert into map of true
		array.forEach(number => {map[number] = true});
		// while a number was defined in the array, increment
		while (map[number]) number++;

		return number;
	},

	/**
	 * Generate a char not contained in the passed string.
	 * @param {String} string String of chars to exclude.
	 * @returns {number} The generated char.
	 */
	getCharNotIn(string){
		// max ascii char
		const max = 127;
		// char number. 0-31 are control chars (see https://www.w3schools.com/charsets/ref_html_ascii.asp)
		let number = 32;
		// while the char was defined in the string increment
		while (string.includes(String.fromCharCode(number)) && number < max) number++;

		return number < max ? String.fromCharCode(number)
		// all char have been tried
			: null;
	},

	/**
	 * @returns {string} A unique String.
	 */
	getString(){
		return toBaseUnicode(nextString++);
	},

	getNumber(){
		return ++number;
	}

}

let nextString = 0;
const UnicodeLength = 10176;
const toBaseUnicode = n => n < UnicodeLength ? String.fromCharCode(n) : f(Math.floor(n/UnicodeLength)) + f(n % UnicodeLength);
