import {Trigger} from "../../library-js/utils";

export default class Pipe {
	constructor(){
		// bind
		this.in = this.in.bind(this);
		this.out = this.out.bind(this);

		privates.set(this, {
			trigger: new Trigger(),
		});
	}

	in(value){
		this.last = value;
		this.over(value);
		return this;
	}

	over(value){
		const {trigger} = privates.get(this);
		trigger.fire(value);
	}

	out(to){
		const {trigger} = privates.get(this);
		return trigger.add(to);
	}
}

const privates = new WeakMap();
