import React from "react"
import { View as RNView } from "react-native"
import ContactDetails from "../../component/ContactDetails";
import CheckoutStateContext from "../CheckoutStateContext";
import use from "../../../../hook";
import ReceptionInfos from "../../component/ReceptionInfos";
import PaymentMeanInfos from "../../component/PaymentMeanInfos";
import BasketItem from "./BasketItem";
import Text from "../../../../component/Text";
import { styles } from "../../../../res";
import screens from "../screens";
import CheckoutFrameContext from "../CheckoutFrameContext";
import useAppWidthLevel from "../../../../app/useAppWidthLevel";
import { useNavigation } from "@react-navigation/native";
import { parallel } from "../../../../library-js/utils/function";
import { pipe, prop, defaultTo } from "ramda";

export default function SummaryUI({ submit, ...props }) {
	const { items, vShop, paymentMean } = use.context(CheckoutStateContext);

	const [submitting, setSubmittingTo] = use.state.bool();
	const [error, setError] = use.state();

	function onSubmitClicked() {
		setSubmittingTo(true);
		setError(undefined);
		submit()
			.catch(
				parallel(
					pipe(
						prop('message'),
						defaultTo(`Une erreur s'est produite`),
						setError,
					),
					setSubmittingTo.false,
				)
			);
	}

	const navigation = useNavigation();
	const [isDesktop] = useAppWidthLevel([1024]);
	const FrameControl = use.context(CheckoutFrameContext);
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<FrameControl step={4}
			title={`VERIFICATION`}
			submit={onSubmitClicked}
			submitLabel={paymentMean.is.online ? `PAYER LA COMMANDE` : `CONFIRMER`}
			loading={submitting}
			error={error && String(error)}
			{...props}>
			{
				!isDesktop && items?.map((item, index) =>
					<BasketItem key={index}
						vBasketItem={item}
						style={localStyles.item} />
				)
			}
			<Text style={localStyles.note}>
				{vShop?.shop?.checkoutNote}
			</Text>


			<Subtitle subtitle={`MODE DE RECEPTION`}
				onChangeClicked={() => navigation.navigate(screens.reception)} />
			<ReceptionInfos style={localStyles.infos} />

			<Subtitle subtitle={`MODE DE PAIEMENT`} onChangeClicked={() => navigation.navigate(screens.payment)} />
			<PaymentMeanInfos style={localStyles.infos} />

			<Subtitle subtitle={`INFORMATIONS DE CONTACT`}
				onChangeClicked={() => navigation.navigate(screens.contact)} />
			<ContactDetails style={localStyles.infos} />
		</FrameControl>
	);
}

const Subtitle = React.memo(({ subtitle, onChangeClicked }) => {
	return (
		<RNView style={localStyles.subtitle.layout}>
			<Text style={localStyles.subtitle.title}>
				{subtitle}
			</Text>
			<Text style={localStyles.subtitle.change} onPress={onChangeClicked}>
				{`CHANGER`}
			</Text>
		</RNView>
	)
});

const localStyles = {
	item: {
		marginBottom: 15,
	},
	subtitle: {
		layout: {
			flexDirection: styles.flexDirection.row,
			justifyContent: styles.justifyContent.spaceBetween,
			marginBottom: 16,
		},
		title: {
			fontSize: 17,
			color: '#000000',
			bold: true,
		},
		change: {
			fontSize: 12,
			color: '#395aff',
			bold: true,
		}
	},
	infos: {
		marginBottom: 26
	},
	note: {
		fontSize: 20,
		lineHeight: 20,
		color: '#395aff',
		marginBottom: 26,
	}
};
