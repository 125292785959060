import AuthManager from "../../../../AuthManager";
import Message from "../event/message/Message";
import config from "./config";
import Manager from "./Manager";

export default Manager;

AuthManager.onUserChanged(() => Manager.reset());

// Override Message.isAuthor getter
Object.defineProperties(Message.prototype, {
	isAuthor: {
		configurable: true,
		get: function () {
			if (config.id)
				return this.author === config.id;
		}
	}
});
