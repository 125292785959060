import React from "react"
import ProdshopsView from "../../../../../BrandListUI/BrandItem/ProdshopsView";
import use from "../../../../../../../library-react/hook";
import {styles} from "../../../../../../res";


export default function CatalogBrandView({loading, iterator, ...props}) {
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<ProdshopsView prodshops={loading ? undefined : iterator?.items} itemStyle={localStyles.item} {...props}/>
	);
}

const localStyles = {
	layout: {
		overflow: 'hidden',
		flexGrow: 1,
		...styles.shadow,
	},
	item: {
		transform: [{scale: 1.55}]
	}
}
