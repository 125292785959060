import React from "react"
import { ScrollView, View as RNView } from "react-native"
import use from "library-react/hook"
import { styles } from "@main/res"
import BackNavigationButton from "@main/component/BackNavigationButton";
import AuthManager from "library-js/AuthManager";
import MyInfos from "./MyInfos"
import AccountTabs from "./AccountTabs";

export default function AccountUI_desktop(
	{
		renders,
		onAccountClicked,
		...props
	}) {

	const { Text } = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<RNView {...props}>
			{/* header */}
			<RNView style={localStyles.header}>
				<BackNavigationButton />

				{/* title */}
				<Text style={localStyles.title}>
					{`MON COMPTE`}
				</Text>

				{/* disconnect */}
				<Text
					onPress={AuthManager.signOut}
					style={localStyles.disconnect}>
					{`SE DÉCONNECTER`}
				</Text>
			</RNView>

			<ScrollView
				contentContainerStyle={localStyles.scroll.content}
				style={localStyles.scroll.layout}>
				<AccountTabs
					onAccountClicked={onAccountClicked}
					style={localStyles.tabs} />
				<RNView style={localStyles.main}>
					<MyInfos style={localStyles.myInfos} />
				</RNView>
			</ScrollView>
		</RNView>
	);
}


const localStyles = {
	layout: {
		...styles.newPaddings(40, 124, 0, 34),
		backgroundColor: "#f7f7f7",
		flex: 1,
	},

	header: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
		paddingBottom: 16,
	},

	title: {
		fontSize: 40,
		bold: true,
		flex: 1,
	},

	disconnect: {
		fontSize: 17,
		paddingBottom: 8,
		color: styles.color.black,
		borderStyle: styles.borderStyle.solid,
		borderBottomWidth: .5,
		alignSelf: styles.alignSelf.flexEnd,
	},

	scroll: {
		layout: { flex: 1 },
		content: {
			paddingTop: 20,
			flexGrow: 1,
		}
	},

	tabs: {
		marginBottom: 10,
	},
	form: {
		backgroundColor: styles.color.white,
		flexDirection: styles.flexDirection.row,
		flexGrow: 1,
	},
	main: {
		backgroundColor: styles.color.white,
		paddingHorizontal: 30,
		paddingVertical: 40,
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.flexStart,
		flexWrap: styles.flexWrap.wrap,
		flexGrow: 1,
		paddingBottom: 100,
		marginBottom: 100,
	},

	myInfos: {
		width: 400,
		marginRight: 5,
	},

	myAddresses: {
		width: 370,
		marginRight: 30,
	},

	myCards: {
		width: 470,
		flexGrow: 1,
	},
};
