import useForceUpdate from "../useForceUpdate";
import useSyncEffect from "../useSyncEffect";
import AuthManager from "../../library-js/AuthManager";

/**
 * @deprecated
 */
export default function useAuthState() {
	const forceUpdate = useForceUpdate();
	useSyncEffect(() => AuthManager.onStateChanged(forceUpdate));
	return AuthManager.state;
}
