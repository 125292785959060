

export default async function convertEntries(entries, convert, shouldStop, shouldConvert, shouldWarn){
	if (!shouldConvert)
		shouldConvert = YES;

	// convert
	const incorrect = [];
	const results = [];

	for (const entry of entries) {
		if (!shouldConvert(entry))
			continue; // skip

		await Promise.await(); // free some cpu
		if (shouldStop()) break;

		let result = undefined; // Not assigning the variable makes it shared between loop, keeping the value from last loop
		let error = undefined;
		try {
			result = convert(entry);
		} catch (caught) {
			error = caught;
		}

		if (result?.ok)
			results.push(result);
		else
			incorrect.push({from: entry, to: result, error});
	}

	results.incorrectCount = incorrect.length; // used by isEnd() to know how much events have been loaded

	const incorrectToWarn = shouldWarn ? incorrect.filter(shouldWarn) : incorrect;
	if (incorrectToWarn.length) // warning
		console.warn("Some entries are incorrect: ", incorrectToWarn);

	return results;
}

const YES = () => true;
