import Base from "../../../../../class/Base";
import VShowcase from "./VShowcase";
import Showcase from "../../../entity/Showcase";

export default class VShowcaseShop extends Base {
	get title(){
		return this.showcase?.title;
	}

	get showcase(){
		return this.vShowcase?.showcase;
	}

	get activated(){
		return this.showcaseShop?.activated;
	}
}
VShowcaseShop.addProperties({
	vShowcase: VShowcase,
	showcaseShop: Showcase.Shop,
	itemsCount: Number,
});

VShowcaseShop.sort = (vss1, vss2) => vss1.showcaseShop?.creationDate - vss2.showcaseShop?.creationDate;
