import authenticate from "./authenticate";
import AuthMethod from "./AuthMethod";

authenticate.with = {
	google: () => authenticate(AuthMethod.google),
	facebook: () => authenticate(AuthMethod.facebook),
	apple: () => authenticate(AuthMethod.apple),
};

export default authenticate;
