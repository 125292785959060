import React from 'react'
import createGallery from "./createGallery";
import View from "../View";
import use from "../../hook";
import Icon from "../Icon/v2";
import adaptSize from "../../../main/adaptSize";
import styles from "../../../main/res/styles";
import ComponentUtils from "../../ComponentUtils";

export default React.memo(
	createGallery(
		function GalleryWithCenterArrow({ renderItem, ...props }, { items, selected, next, back }) {

			props.style = use.defaultStyle(props.style, localStyles.layout);

			return (
				<View {...props}>
					{renderItem(items[selected])}
					{
						Boolean(items?.length > 1) &&
						<>
							<Icon onPress={next}
								name='rightArrow'
								size={adaptSize(20)}
								style={localStyles.arrow('right')} />
							<Icon onPress={back}
								name='leftArrow'
								size={adaptSize(20)}
								style={localStyles.arrow('left')} />
						</>
					}


				</View>
			);
		}
	),
	(oldProps, props) =>
		!ComponentUtils.props.arrayLengthChanged(oldProps.items, props.items) // items length not changed

)

const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
		...styles.center,
	},

	main: {
		flex: 1,
	},
	arrowLayout: {
		...styles.fit,
	},
	arrow: styles.static({
		position: styles.position.absolute,
		bottom: '48%',
		width: 50,
		height: 50,
		color: styles.color.white,
		backgroundColor: 'rgba(54,54,53,0.4)'
	},
		{
			right: {
				right: 0,
			},
			left: {
				left: 0,
			}

		})
};
