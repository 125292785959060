import React from "react"
import use from "library-react/hook"
import {styles} from "@main/res"
import View from "library-react/component/View/v2";
import Icon from "library-react/component/Icon/v2";
import {Logo, Name} from "library-react/component/company";
import AuthenticateView from "@main/component/AuthenticateView";

function AuthModalMobile({user, close, title, ...props}) {
	const {Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<View {...props}>
			<Text style={localStyles.title.wrapper}>
				{user && `Vous êtes connecté en tant que `}

				<Text style={localStyles.title.main}>
					{user ? user.displayName : `Se connecter`}
				</Text>
			</Text>

			<Text style={localStyles.invitation}>
				{title}
			</Text>

			<View style={localStyles.header.layout}>
				<Text style={localStyles.header.label}>
					{`Powered by`}
				</Text>

				<Logo style={localStyles.header.logo}/>
				<Name style={localStyles.header.name}/>
			</View>

			<AuthenticateView
				title={null}
				style={localStyles.form.layout}/>

			{
				close &&
				<Icon
					name="EvilIcons/close"
					size={30}
					onPress={close}
					style={localStyles.close}
				/>
			}
		</View>
	);
}

export default React.memo(AuthModalMobile);

const localStyles = {
	layout: {
		padding: 15,
		paddingTop: 70,
		...styles.fit,
		backgroundColor: styles.color.white,
	},

	title: {
		wrapper: {
			fontSize: 25,
			marginBottom: 10,
		},

		main: {
			bold: true,
		}
	},

	invitation: {
		fontSize: 20,
		marginBottom: 10,
	},

	header: {
		layout: {
			flexDirection: styles.flexDirection.row,
			alignItems: styles.alignItems.flexEnd,
			marginBottom: 50,
		},

		label: {
			fontSize: 11,
			marginRight: 8,
		},

		logo: {
			height: 20,
			width: 20,
			marginRight: 5,
		},

		name: {
			width: 73,
			height: 20,
		},
	},

	form: {
		layout: {},

		google: {
			marginBottom: 40,
		},

		facebook: {}
	},

	close: {
		position: styles.position.absolute,
		top: 0,
		right: 0,
		padding: 20,
		color: 'grey'
	}
};
