import {Base, Enum} from "../../../../class";

export default class ReservationState extends Base {
}

const Type = Enum.make({
	waiting: 'WAITING',
	accepted: 'ACCEPTED',
	refused: 'REFUSED',
	_waiting: '"WAITING"',
	_accepted: '"ACCEPTED"',
	_refused: '"REFUSED"',
});
ReservationState.Type = Type;

ReservationState.addProperties({
	creationDate: Number,
	type: Type,
});
