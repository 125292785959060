import React from "react";
import use from "library-react/hook";
import View from "library-react/component/View";
import ContactView from "../ContactView/desktop";
import {styles} from "../../../res";
import globals from "../../../globals";
import adaptSize from "../../../adaptSize";
import Image from "library-react/component/Image";
import RegionSupport from "@main/ui/ShopUI/Footer/RegionSupport";

export default React.memo(
	function Footer({item, ...props}) {
		const {shop} = item;
		props.style = use.defaultStyle(props.style, localStyles.layout);

		return (
			<View {...props}>
				{/*Map*/}
				<Image
					source={{uri: `https://maps.googleapis.com/maps/api/staticmap?${
							URL.Params.encode({
								size: "900x450",
								key: globals.apiKeys.google,
								center: `${shop.location.latitude},${shop.location.longitude}`,
								markers: `|${shop.location.latitude},${shop.location.longitude}`
							})
						}`}}
					resizeMode="cover"
					style={localStyles.map}
				/>
				<ContactView style={localStyles.contact} shop={shop}/>
			</View>
		);
	}
)

const localStyles = {

	layout: {
		flexDirection: styles.flexDirection.row,
		justifyContent: styles.justifyContent.spaceBetween,
	},
	map: {
		width: adaptSize(655),
		height: adaptSize(348),
	},
	contact: {
		width: adaptSize(655),
	}
};
