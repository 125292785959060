import React from "react";
import inputComponent from "../abstract/inputComponent"
import { isNil, pipe } from "ramda";
import use from "../../hook";
import ComponentUtils from "../../ComponentUtils";
import { TextStyleContext } from "../Text/v2";
import { countriesReversedMap } from "../CountryInput/countries";
import getObjectIdOf from "../../library-js/utils/getObjectIdOf";

function WebDropdown({ options, onValueChanged, placeholder, validate, invalid, editable, webIcon, ...props }, refProp) {
	props.ref = refProp;
	props.onChange = pipe(getValue, onValueChanged);

	const textStyle = use.context(TextStyleContext);

	if (invalid === undefined && validate) {
		const valid = validate instanceof Function ? validate(countriesReversedMap[props.value]) :
			validate ? !props.value : undefined;

		if (valid !== undefined)
			invalid = !valid;
	}

	if (!isNil(editable) && !editable)
		props.disabled = true;

	props.style = use.memo(style =>
		ComponentUtils.style.merge(textStyle, invalid && { color: 'red' }, props.disabled && { appearance: 'none' }, style),
		[props.style, textStyle, Boolean(invalid), props.disabled]);


	if (isNil(props.value))
		props.value = PLACEHOLDER_VALUE;

	const id = use.memo(() => `p${Date.now()}`);

	return (
		<>
			<select id={id} {...props}>

				<option value={PLACEHOLDER_VALUE} disabled>
					{placeholder}
				</option>

				{
					options?.map((option, index) =>
						<option
							key={index}
							value={option}>
							{option}
						</option>
					)
				}
			</select>

			{
				!webIcon &&
				<style>
					{`#${id} {-moz-appearance: none; -webkit-appearance: none;}`}
				</style>
			}
		</>
	);
}

const PLACEHOLDER_VALUE = String(Date.now());

export default React.forwardRef(inputComponent(WebDropdown));

function getValue(event) {
	const value = event?.target?.value;
	return value !== PLACEHOLDER_VALUE ? value : undefined;
}
