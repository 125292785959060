import pipe from "./pipe";
import parallel from "./parallel";
import procedure from "./procedure";
import curry from "./curry";

export default {
	pipe,
	parallel,
	procedure,
	curry,
}

export {
	pipe,
	parallel,
	procedure,
	curry,
}
