import fonts from "library-react/res/fonts"

export default fonts;

fonts.HelveticaNeue = {
	light: require("./HelveticaNeue/light.ttf"),
	bold: require("./HelveticaNeue/bold.ttf"),
	italic: require("./HelveticaNeue/italic.ttf"),
	boldItalic: require("./HelveticaNeue/bold.ttf"), // TODO
	default : require("./HelveticaNeue/light.ttf"),
	medium: require("./HelveticaNeue/medium.ttf"),
};

fonts.BebasNeue = require("./BebasNeue.ttf");
