import React from "react"
import {ScrollView} from "react-native"
import use from "../../../../../hook"
import {styles} from "../../../../../res"
import {Server} from "../../../../../library-js/app";
import Text from "../../../../../component/Text";
import View from "../../../../../component/View/v2";
import Search from "../../../../../library-js/Search";
import is from "../../../../../library-js/utils/is";


function CatalogTabs({shopId, iterator, ready, onTabChanged, ...props}) {
	const [hasDiscounts, setHasDiscounts] = use.state(undefined);
	use.subscribe(rerender => iterator.onStateChanged(rerender), [iterator]);


	const tabState = use.state(DISCOUNT);
	const [tab, setTab] = tabState;
	use.onChange(onTabChanged, [tab]);

	// check discount tab content
	use.effect((firstPageLoaded) => {
		if (!is.defined(hasDiscounts) && iterator._configuration.discounted && firstPageLoaded) {
			const hasDiscounts = iterator.items.length > 0;
			setHasDiscounts(hasDiscounts);

			if (tab === DISCOUNT && !hasDiscounts) // switch tab to all
				setTab(/* all */ null);
		}
	}, [iterator.page > 0]);


	const cupboardLoadable = use.loadable.server(() => Server.public.shop.getSections(shopId), [shopId]);
	const facetsLoadable = use.loadable(() =>
			Search.index.prodshop.getIterator({shopId, inShop: true, available: true})
				.getMetadatas()
				.then(response => {
					if (response.ok)
						return response.content?.facets;
					throw response;
				}),
		[shopId],
	);


	const sections = use.memorise(([cupboard, quantities], sections) => {
			if (!sections && cupboard && quantities)
				sections = cupboard.getRootSections()
					.filter(section => quantities[section.name] > 0);

			return sections;
		},
		[
			cupboardLoadable.value,
			facetsLoadable.end ? (facetsLoadable.value?.sections || []) : undefined
		],
	);


	const /* component */ Tab = use.memo(createTab, [setTab]);

	ready = ready && cupboardLoadable.end && facetsLoadable.end && is.defined(hasDiscounts);

	props.style = use.defaultStyle(props.style, localStyles.layout, [3]);
	return (
		sections?.length === 0 && !hasDiscounts ? null :
		// do not display tabs if shop has no discount and no sections
		<ScrollView
			{...props}
			horizontal
			showsHorizontalScrollIndicator={false}
			contentContainerStyle={localStyles.content}>
			{
				ready &&
				<>
					{
						hasDiscounts &&
						<Tab value={DISCOUNT}
							 tab={tab}>
							{`PROMO`}
						</Tab>
					}

					{/* all */}
					{
						<Tab value={null}
							 tab={tab}>
							{`TOUT`}
						</Tab>
					}

					{
						sections?.map(section => (
							<Tab
								key={section.name}
								value={section}
								tab={tab}>
								{section?.name.toUpperCase()}
							</Tab>
						))
					}
				</>
			}

		</ScrollView>
	);
}

const DISCOUNT = CatalogTabs.DISCOUNT = Symbol('discounts');
export default Object.assign(React.memo(CatalogTabs), CatalogTabs);



const createTab = setTab => React.memo(({value, tab, children}) => {
	const selected = tab === value;
	return (
		<View
			onPress={!selected && (() => setTab(value))}
			style={localStyles.tab.layout(selected)}>
			<Text style={localStyles.tab.text(selected)}>
				{children}
			</Text>
		</View>
	);
});


const localStyles = {
	layout: {
		height: 44,
		backgroundColor: styles.color.white,
	},

	content: {
		paddingHorizontal: 20,
	},

	tab: {
		layout: styles.static.bool({
				minWidth: 80,
				paddingHorizontal: 7.5,
				...styles.center,
				borderBottomWidth: 2,
			},
			{
				borderColor: styles.color.aquaBlue,
			},
			{
				borderColor: styles.color.transparent,
			},
		),

		text: styles.static.bool({
				bold: true,
				fontSize: 14,
			},
			{color: '#1e2432'},
			{color: styles.color.secondary3},
		)
	},
};
