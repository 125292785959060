import React from "react"
import {View as RNView} from "react-native"
import use from "library-react/hook"
import {styles} from "@main/res"
import StarsView from "library-react/component/StarsView";
import adaptSize from "@main/adaptSize";
import Icon from "library-react/component/Icon/v2";
import View from "library-react/component/View/v2";

function RateView({rate, ...props}) {
	const {Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<View {...props}>
			<RNView style={localStyles.evaluation.layout}>
				<StarsView
					stars={rate.stars}
					size={30}
					useEyalStyle
					style={localStyles.evaluation.stars}
				/>

				<Text
					numberOfLines={1}
					style={localStyles.evaluation.average}>
					{rate.stars.toFixed(1)}
				</Text>
			</RNView>

			<View style={localStyles.people.layout}>
				<Icon
					name="account"
					size={adaptSize(16)}
					style={localStyles.people.icon}
				/>

				<Text
					numberOfLines={1}
					style={localStyles.people.number}>
					{rate.numberOfVotes} {`avis`}
				</Text>
			</View>
		</View>
	);
}

export default React.memo(RateView);


const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
		justifyContent: styles.justifyContent.spaceBetween,
		flexWrap: styles.flexWrap.wrap,
	},

	evaluation: {
		layout: {
			flexDirection: styles.flexDirection.row,
			alignItems: styles.alignItems.center,
		},

		stars: {
			marginRight: adaptSize(15)
		},

		average: {
			fontSize: adaptSize(18),
		},
	},

	people: {
		layout: {
			flexDirection: styles.flexDirection.row,
			alignItems: styles.alignItems.center,
		},

		icon: {
			marginRight: adaptSize(9),
		},

		number: {
			fontSize: adaptSize(18),
			textDecorationLine: styles.textDecorationLine.underline,
		},
	}
};
