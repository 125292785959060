import abstractComponent from "./abstractComponent";
import use from "../../hook";
import is from "../../library-js/utils/is";

const SelectionInput = abstractComponent((render, options = {items: undefined, defaultSelected: undefined}) =>
	function Component(props) {
		let items = options.items || props.items;
		const defaultSelectedValue = props.defaultValue >= 0 ? props.defaultValue : options?.defaultSelected;

		// selected index
		let [selected, setSelected] = use.state(defaultSelectedValue);

		// correct selected index
		const max = items?.length -1;
		const correction = correctIndex(selected, max);
		use.syncEffect(() => {
			if (correction !== selected)
				setSelected(selected = correction);
		}, [selected, correction]);

		use.onChange(props.onValueChanged, [selected]);

		//Warning: implements forced value by prop AFTER the onValueChanged call above
		if (props.value >= 0)
			selected = correctIndex(props.value, max);

		return render(
			props,
			{
				items: items || [],
				setSelected,
				selected,
				get item() {
					return this.items?.[this.selected];
				},
				get valid(){
					return !props.validate || ((props.validate instanceof Function) ? props.validate(this.selected) : (selected >= 0));
				},
			}
		);
	}
);

export default SelectionInput;

function correctIndex(value, max) {
	if (!is.defined(value) || max < 0)
		return undefined;

	if (!(value >= 0))
		return 0;

	if (value > max)
		return Number(max);

	return Number(value);
}
