import React from "react"
import {View as RNView} from "react-native"
import use from "library-react/hook"
import {styles} from "@main/res"

export default function AccountSectionMobile({title, subtitle, children, ...props}) {
	const {Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<RNView {...props}>
			<Text style={localStyles.title}>
				{title}
			</Text>

			<Text style={localStyles.subtitle}>
				{title}
			</Text>

			{children}
		</RNView>
	);
}

const localStyles = {
	layout: {
		paddingBottom: 34
	},

	title: {
		fontSize: 25,
		bold: true,
		borderStyle: styles.borderStyle.solid,
		borderBottomWidth: 7,
		borderColor: "#f7f7f7",
		paddingBottom: 10,
		marginBottom: 15,
	},

	subtitle: {
		fontSize: 19,
		bold: true,
		marginBottom: 15,
	},
};
