import is from "./is";
import moment from "moment";
import OpenHour from "library-js/app/model/entity/Shop/OpenHour";
import Functions from "library-js/utils/Functions";

let OpenHoursUtils = {}
export default OpenHoursUtils = {
	isInOpenHours(openHours, timezone, day, hour, minute) {
		const week = OpenHoursUtils.weekOpenHours(openHours);

		if (Number.isFinite(day) && day > 6) // timestamp
			day = moment(day).tz(timezone);

		if (day instanceof moment) {
			minute = day.minute();
			hour = day.hour();
			day = day.day();
		}

		if (is.defined(day) && is.defined(hour) && is.defined(minute)) {
			const totalMinutes = hour * 60 + minute;
			return week[day]
				.some(({open, close}) => (
					// after open
					open <= totalMinutes
					// before close
					&& totalMinutes < close
				));
		} else if (day < 7)
			return week[day].length > 0;
	},

	weekOpenHours(openHours) {
		const week = {};
		OpenHour.Days.forEach((key, day) => (week[day] = week[day.value] = []));

		openHours?.filter(oh => oh?.day)
			.sort(Functions.generateSorter("open"))
			.forEach(openHour => {
				week[openHour.day].push(openHour)
			});

		return week;
	}
}