import React from "react";
import Navigation from "../../library-js/web/Navigation";
import defaultWillOpenModal from "./willOpenModal.js"

export default function /* web */ willOpenModal(state){
	const openModal = defaultWillOpenModal(state);
	return function(...params){
		const close = openModal(...params);
		const [, update] = state;
		Navigation.pushState(() => update(modals => modals.pop()));
		return close;
	}
};
