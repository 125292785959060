/**
 * Do not use this module for graphs with loop inside.
 */
import is from "./is";
import Objects from "./Objects";

const Graphs = {
	map(root, getChildren, setChildren, map) {
		let copy = map ? map(root) : root;

		if (copy) {
			let newChildren = (getChildren(root) || [])
				.map(child => this.map(child, getChildren, map))
				.filter(Boolean);

			if (setChildren)
				setChildren(copy, newChildren);
		}

		return copy;
	},

	find(root, getChildren, predicate) {
		if (predicate(root))
			return root;

		return getChildren(root)
			.find(child => this.find(child, getChildren, predicate));
	},

	flattenObjectTree(tree) {
		if (is.primitive(tree))
			return tree;

		return Objects.reduce(tree,
			(flatResult, key, value) => {
				if (is.primitive(value))
					flatResult[key] = value;

				else
					Object.assign(flatResult,
						Graphs.flattenObjectTree(
							Objects.map(value, null, subKey => `${key}.${subKey}`)));

				return flatResult;
			},
			{}
		);
	}
};

export default Graphs;
