export const reservation = () => () =>
	<svg width="50" height="45" viewBox="0 0 50 45" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M31.5469 34.559L35.0189 31.136C35.3246 30.8368 35.7148 30.6388 36.1368 30.5689C36.5587 30.499 36.992 30.5605 37.3779 30.745L37.7449 30.916C37.8306 30.9557 37.906 31.0145 37.9655 31.0878C38.0251 31.1612 38.067 31.2471 38.0883 31.3392C38.1095 31.4312 38.1094 31.5269 38.088 31.6189C38.0666 31.7109 38.0245 31.7968 37.9649 31.87L34.3829 36.345L31.5469 34.559Z" fill="white"/>
		<path d="M24.9928 7.968C26.9169 7.968 28.4768 6.40816 28.4768 4.484C28.4768 2.55984 26.9169 1 24.9928 1C23.0686 1 21.5088 2.55984 21.5088 4.484C21.5088 6.40816 23.0686 7.968 24.9928 7.968Z" fill="white" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M47.9768 30.537H2.02176C1.84499 30.5375 1.66986 30.5031 1.50643 30.4357C1.34301 30.3683 1.19453 30.2692 1.06953 30.1442C0.944529 30.0192 0.845476 29.8708 0.778071 29.7073C0.710665 29.5439 0.676236 29.3688 0.676764 29.192V28.642C0.676236 28.4652 0.710665 28.2901 0.778071 28.1267C0.845476 27.9632 0.944529 27.8148 1.06953 27.6898C1.19453 27.5648 1.34301 27.4657 1.50643 27.3983C1.66986 27.3309 1.84499 27.2965 2.02176 27.297H47.9768C48.1535 27.2965 48.3287 27.3309 48.4921 27.3983C48.6555 27.4657 48.804 27.5648 48.929 27.6898C49.054 27.8148 49.1531 27.9632 49.2205 28.1267C49.2879 28.2901 49.3223 28.4652 49.3218 28.642V29.192C49.3186 29.5477 49.1759 29.888 48.9244 30.1396C48.6728 30.3912 48.3325 30.5339 47.9768 30.537Z" fill="#CA293E" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M24.9923 4.802C22.0364 4.80108 19.1093 5.38262 16.3782 6.51338C13.6471 7.64413 11.1656 9.30195 9.07545 11.3921C6.9853 13.4822 5.32747 15.9638 4.19672 18.6949C3.06596 21.426 2.48443 24.3531 2.48535 27.309H47.4994C47.5003 24.3531 46.9187 21.426 45.788 18.6949C44.6572 15.9638 42.9994 13.4822 40.9093 11.3921C38.8191 9.30195 36.3376 7.64413 33.6065 6.51338C30.8754 5.38262 27.9483 4.80108 24.9923 4.802V4.802Z" fill="white" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M33.0869 33.043L35.0189 31.136C35.2668 30.8942 35.5706 30.7174 35.9033 30.6215C36.236 30.5255 36.5873 30.5133 36.9259 30.586" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M9.69922 34.926L14.0642 31.698C15.0227 30.99 16.1733 30.5894 17.3642 30.549C17.84 30.5344 18.3159 30.5754 18.7822 30.671L23.7332 32.236L28.9782 30.647C29.4604 30.4982 29.9765 30.5005 30.4574 30.6535C30.9382 30.8065 31.3607 31.1029 31.6682 31.503L31.6922 31.527C31.7697 31.628 31.8243 31.7445 31.8523 31.8687C31.8802 31.9928 31.8808 32.1216 31.8541 32.246C31.8274 32.3704 31.7739 32.4875 31.6975 32.5892C31.621 32.6909 31.5233 32.7748 31.4112 32.835L27.0592 35.207L34.6142 34.058L38.9542 30.83C39.2638 30.6332 39.624 30.5307 39.9908 30.5349C40.3576 30.5392 40.7153 30.65 41.0202 30.854L41.5952 31.233C41.6854 31.2937 41.7599 31.375 41.8125 31.4701C41.8652 31.5652 41.8946 31.6714 41.8982 31.7801C41.9018 31.8887 41.8795 31.9967 41.8333 32.095C41.7871 32.1934 41.7182 32.2794 41.6322 32.346L37.0722 36.246C35.9781 37.0726 34.7316 37.6751 33.4042 38.019L29.6262 38.997C27.9234 39.4375 26.1617 39.6063 24.4062 39.497L20.8992 39.049C20.0305 38.9135 19.1428 39.1145 18.4172 39.611L16.9382 40.785L9.69922 34.926Z" fill="white" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M14.3819 43.386L7.97592 36.148C7.80258 35.9517 7.71427 35.6946 7.73039 35.4332C7.74652 35.1719 7.86576 34.9276 8.06192 34.754L9.24792 33.703C9.44423 33.5297 9.70133 33.4414 9.96272 33.4575C10.2241 33.4736 10.4684 33.5929 10.6419 33.789L17.0479 41.027C17.1339 41.1242 17.1998 41.2373 17.242 41.36C17.2842 41.4826 17.3019 41.6124 17.2939 41.7419C17.2859 41.8713 17.2525 41.9979 17.1955 42.1145C17.1385 42.231 17.0592 42.3352 16.9619 42.421L15.7759 43.472C15.6795 43.5595 15.5666 43.6268 15.4437 43.6699C15.3209 43.713 15.1907 43.7311 15.0608 43.7231C14.9309 43.7151 14.8039 43.6811 14.6873 43.6232C14.5707 43.5653 14.4669 43.4847 14.3819 43.386V43.386Z" fill="#CA293E" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
	</svg>
