/**
 * Contains functions for strings:
 * <ul>
 *     <li>{@link toUnderscore}(String)	: Convert a camelCase string to a snake_case.</li>
 *     <li>{@link toDash}(String)	: Convert a camelCase string to a kebab-case.</li>
 * </ul>
 */
import is from "./is";
import {test} from "ramda"

const regex = {
	email: /^[a-zA-Z0-9_-]+@([a-zA-Z0-9-]+\.)+[a-zA-Z]+$/,
};

const Strings = {
	toUnderscore(text) {
		return this.splitCamel(text)
			.map(subText=> subText[0].toLowerCase() + subText.slice(1))
			.join("_");
	},

	toDash(text) {
		return this.splitCamel(text)
			.map(subText=> subText[0].toLowerCase() + subText.slice(1))
			.join("-");
	},

	splitCamel(text){
		return text.split(/(?=[A-Z])/);
	},

	formatPrice(price){
		if (!price)
			return price;

		price = price.replace(/[^0-9.]/g,"") || "";
		let matches = price.match(/^\d+\.?\d{0,2}/g);
		return matches ? matches.head : "";
	},

	isEmail: test(regex.email),

	regex,

	toString(value){
		return is.defined(value) ? String(value) : "";
	}
};

export default Strings;
