import { map, is, head, pipe, juxt, last, equals, repeat, allPass } from "ramda";

const enumsAdapter = {
	input: adaptInput,
	output: adaptOutput,
};
export default enumsAdapter;

function adaptInput(node) {
	if (node instanceof Object)
		return map(adaptInput, node);

	if (doesSeemsToBeAnEnum(node)) {
		try {
			return JSON.parse(node);
		} catch (e) { }
	}

	return node;
}

const doesSeemsToBeAnEnum = allPass([
	is(String),
	pipe(
		juxt([head, last]),
		equals(repeat('"', 2))
	)
]);

function adaptOutput(node) {
	if (node instanceof Object)
		return map(adaptOutput)(node);

	if (allEnumValues.includes(node))
		return JSON.stringify(node);

	return node;
}

const allEnumValues = [
	// ReservationState
	'WAITING', 'ACCEPTED', 'REFUSED',
	// BankAccountType
	"IBAN", "GB", "US", "CA", "OTHER",
	// Commercialization
	"WITH_ORDERS", "WITHOUT_PRICES",
	// DocumentStatus
	"CREATED", "VALIDATION_ASKED", "VALIDATED", "REFUSED", "OUT_OF_DATE",
	// KYCDocRefusedReasonType
	"DOCUMENT_UNREADABLE", "DOCUMENT_NOT_ACCEPTED", "DOCUMENT_HAS_EXPIRED", "DOCUMENT_INCOMPLETE", "DOCUMENT_MISSING", "DOCUMENT_DO_NOT_MATCH_USER_DATA", "DOCUMENT_DO_NOT_MATCH_ACCOUNT_DATA", "SPECIFIC_CASE", "DOCUMENT_FALSIFIED", "UNDERAGE_PERSON", "SPECIFIC_CASE",
	// KYCDocumentType
	"IDENTITY_PROOF", "REGISTRATION_PROOF", "ARTICLES_OF_ASSOCIATION", "SHAREHOLDER_DECLARATION", "ADDRESS_PROOF",
	// KYCLevel
	"LIGHT", "REGULAR",
	// LegalPersonType
	"BUSINESS", "ORGANIZATION", "SOLETRADER",
	// PaymentConfigurationShopStatus
	'ASKED', 'VALID', 'INCOMPLETE',
	// PaymentMean
	"ONLINE", "CASH", "CARD", "CHECK", "RESTAURANT_TICKET", "TRANSFERT", "ELSE", "NATURAL", "LEGAL",
	// ReceptionMean
	"COLLECT", "DELIVERY",
	// OrderStateAuthor
	"SHOPINZON", "PAYMENT_SYSTEM", "USER", "RETAILER",
	// TransactionState
	"CREATED", "SUCCEEDED", "FAILED",
	// UBODeclarationStatus
	"CREATED", "VALIDATION_ASKED", "INCOMPLETE", "VALIDATED", "REFUSED",
	// UBOReasonType
	"MISSING_UBO", "WRONG_UBO_INFORMATION", "UBO_IDENTITY_NEEDED", "SHAREHOLDERS_DECLARATION_NEEDED", "ORGANIZATION_CHART_NEEDED", "DOCUMENTS_NEEDED", "DECLARATION_DO_NOT_MATCH_UBO_INFORMATION", "SPECIFIC_CASE",
];
