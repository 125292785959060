import React from 'react'
import moment from "moment";
import { __, complement, curry, dec, evolve, inc, intersperse, isNil, map, max, min, pipe, prop, times, uniq, unless, assoc } from "ramda";
import { Pressable, ScrollView, View as RNView } from "react-native";
import GradientView from "react-native-linear-gradient";
import use from "../../hook";
import lastDayOf from "../../library-js/app/model/utils/closure/lastDayOf";
import toIsoDate from "../../library-js/utils/toIsoDate";
import toMoment from "../../library-js/utils/toMoment";
import { styles } from "../../res";
import Icon from "../Icon/v2";
import Image from "../Image";
import SelectInput from "../input/SelectInput";
import MainButton from '../MainButton';
import Environment from '../../library-js/Environment';

export default function ReservationTimeForm({ shop, reservation, setReservation, next, ...props }) {
	const people = reservation.numberOfPlaces;
	const addPeople = willAddPeople(setReservation);
	const removePeople = willRemovePeople(setReservation);

	const nextPossibleDates = use.memo(() => {
		const allOpenHours = shop.reservationSpans.length ?
			shop.reservationSpans.flatMap(prop('openHoursList'))
			: shop.openHoursList;

		const dates = [];

		if (allOpenHours.length) {
			const weekDays = pipe(
				map(prop('day')),
				uniq,
			)(allOpenHours);

			let momentIterator = moment(); // reserve from tommorow
			while (dates.length < NEXT_DATES) {
				const inClosure = shop.reservationClosures.find((closure) => {
					const lastDay = lastDayOf(closure);
					return momentIterator.isBetween(closure.start, lastDay);
				});

				if (inClosure) // skip it
					momentIterator.add(inClosure.days, 'days');
				else {
					if (weekDays.includes(momentIterator.day()))
						dates.push(toIsoDate(momentIterator));
					momentIterator.add(1, 'day');
				}
			}
		}

		return dates;
	});

	const [date, setDate] = use.state(() => reservation.time ? toIsoDate(reservation.time) : nextPossibleDates[0]);
	// TODO take care of closures

	const dateMoment = moment(date);

	const [minutesTime, setMinutesTime] /* minutes */ = use.state(() => {
		if (reservation.time) {
			const reservationMoment = moment.parseZone(reservation.time);
			return reservationMoment.hour() * 60 + reservationMoment.minutes();
		}
	});

	const spans = shop.reservationSpans?.filter(span => span.openHoursList.some(oh => oh.day == dateMoment.day()));
	const [spanIndex, setSpanIndex] = use.state(() => {
		if (spans?.length > 0) {
			if (minutesTime)
				return spans.map(prop('openHoursList')).findIndex(includesTime(minutesTime));

			return 0;
		}
	});

	const openHours = (spans?.[spanIndex] || shop).openHoursList;
	const isInOpenHours = includesTime(__, openHours);
	const timeSlots = times(i => {
		const minutes = i * INTERVAL;
		if (isInOpenHours(minutes))
			return minutes;
	}, 24 * 60 / INTERVAL)
		.filter(complement(isNil));

	const [submitted, setSubmitted] = use.state.bool();
	function submit() {
		setSubmitted(true);

		if (!date || !minutesTime)
			return;


		const time = toMoment(shop.timezone, date)
			.hours(Math.trunc(minutesTime / 60))
			.minutes(minutesTime % 60)
			.format();


		setReservation(assoc('time', time));
		next();
	}


	function showSelectionSlots(slots) {
		if (Environment.OS?.is?.windows)
			return (
				<RNView style={[
					{
						justifyContent: 'space-between',
						flexDirection: 'row',
						flexWrap: 'wrap'
					},
					localStyles.selectionScrollContainer,
				]}>
					{slots}
				</RNView>
			);

		// not desktop
		return (
			<ScrollView
				key={slots.length}
				horizontal
				showsHorizontalScrollIndicator={false}
				contentContainerStyle={localStyles.selectionScrollContainer}>
				{slots}
			</ScrollView>
		);
	}

	const { Text } = use.theme();

	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<ScrollView {...props}>
			<Text style={localStyles.title}>
				{`Réservons votre table`}
			</Text>

			<RNView style={localStyles.shopRow}>
				<Image
					source={{ uri: shop.logoUrl }}
					resizeMode="cover"
					style={localStyles.shopLogo}
				/>

				<Text style={localStyles.shopName}>
					{shop.name}
				</Text>
			</RNView>

			<RNView style={localStyles.peopleRow}>
				<Text style={localStyles.subtitle}>
					{`Combien de personnes ?`}
				</Text>

				<RNView style={localStyles.people}>
					<Icon
						name="Feather/minus"
						size={13}
						onPress={removePeople}
						style={[localStyles.peopleButton, localStyles.removePeople]}
					/>

					<Text style={localStyles.peopleNumber}>
						{people}
					</Text>

					<Icon
						name="Feather/plus"
						size={13}
						onPress={addPeople}
						style={[localStyles.peopleButton, localStyles.addPeople]}>
						{SELECTED_BUTTON_BACKGROUND}
					</Icon>
				</RNView>
			</RNView>

			<Text style={[localStyles.subtitle, localStyles.dayTitle]}>
				{`Pour quand ?`}

				{
					submitted && !date &&
					<RequiredLabel />
				}
			</Text>

			<RNView style={localStyles.dayRow}>
				<Text style={localStyles.day}>
					{
						moment(date).calendar({
							sameDay: `[Aujourd'hui]`,
							nextDay: `[Demain]`,
							nextWeek: `dddd`,
							sameElse: DATE_FORMAT,
						}).firstUp
					}
				</Text>


				<SelectInput
					value={null}
					onValueChanged={unless(isNil, formatted => {
						const date = toIsoDate(moment(formatted, DATE_FORMAT));
						setDate(date);
						setSpanIndex(0); // reset
						setMinutesTime(undefined);
					})}
					placeholder={`CHOISIR UNE DATE`}
					options={nextPossibleDates.map(date => moment(date).format(DATE_FORMAT))}
					style={{ color: '#395aff', fontWeight: 'bold', fontSize: 13, flex: 1, paddingVertical: 5, textAlign: 'right' }} />
			</RNView>


			{
				spans?.length > 1 &&
				<>
					<Text style={[localStyles.subtitle, inCenterColumn]}>
						{`Choisissez un service`}

						{
							!(spanIndex >= 0) && submitted &&
							<RequiredLabel />
						}
					</Text>

					{
						showSelectionSlots(
							spans.map(({ name, openHoursList }, index) => {
								const isSelected = spanIndex === index;
								return (
									<Pressable
										key={index}
										onPress={() => {
											setSpanIndex(index);
											if (!includesTime(minutesTime, openHoursList))
												setMinutesTime(undefined);
										}}
										style={localStyles.selectionButton}>
										<Text style={localStyles.selectionButtonText(isSelected)}>
											{name}
										</Text>

										{
											isSelected &&
											SELECTED_BUTTON_BACKGROUND
										}
									</Pressable>
								);
							})
						)
					}
				</>
			}



			<Text style={[localStyles.subtitle, inCenterColumn]}>
				{`Horaires disponibles`}

				{
					!minutesTime && submitted &&
					<RequiredLabel />
				}
			</Text>

			{
				showSelectionSlots(
					timeSlots.map((minutes, index) => {
						const isSelected = minutes <= minutesTime && minutesTime < minutes + INTERVAL;
						return (
							<Pressable
								key={index}
								onPress={() => setMinutesTime(minutes)}
								style={[localStyles.selectionButton, { minWidth: 87 }]}>
								<Text style={localStyles.selectionButtonText(isSelected)}>
									{moment.utc(moment.duration(minutes, 'minutes').as('milliseconds')).format("HH:mm")}
								</Text>

								{
									isSelected &&
									SELECTED_BUTTON_BACKGROUND
								}
							</Pressable>
						);
					})
				)
			}

			<MainButton
				onPress={submit}
				style={[{ marginTop: 60, margin: 25 }]}>
				{`CONTINUER`}
			</MainButton>
		</ScrollView>
	);
}

const NEXT_DATES = 30;

const DATE_FORMAT = 'ddd DD/MM/YY';

const INTERVAL = 15; // mins

const [willAddPeople, willRemovePeople] = [
	pipe(inc, min(99)), // add
	pipe(dec, max(1)), // remove
].map(numberOfPlaces =>
	setReservation =>
		() => setReservation(evolve({ numberOfPlaces }))
);

const isTimeInOpenHour = curry((time, oh) => oh.open <= time && time < oh.close);
const includesTime = curry((time, openHours) => openHours.some(isTimeInOpenHour(time)));

function RequiredLabel() {
	const { Text } = use.theme();
	return (
		<Text style={{ color: 'red' }}>
			{`  Requis`}
		</Text>
	);
}

const inCenterColumn = {
	paddingHorizontal: 25,
};

const localStyles = {
	layout: {
	},

	title: {
		fontSize: 23,
		bold: true,
		...inCenterColumn,
		paddingTop: 21,
		paddingBottom: 12,
	},

	shopRow: {
		...inCenterColumn,
		flexDirection: "row",
		alignItems: 'center',
		marginBottom: 30,
	},

	shopLogo: {
		...styles.circle(23),
		marginRight: 9,
	},

	shopName: {
		fontSize: 17,
	},

	peopleRow: {
		...inCenterColumn,
		flexDirection: "row",
		alignItems: 'center',
		marginBottom: 30,
		justifyContent: 'space-between',
	},

	people: {
		flexDirection: "row",
		alignItems: 'center',
	},

	subtitle: {
		fontSize: 14,
		bold: true,
	},

	peopleButton: {
		...styles.circle(32),
	},

	removePeople: {
		...styles.newBorder(.5, '#c1c0c9'),
		color: '#c1c0c9',
	},

	peopleNumber: {
		width: 40,
		fontSize: 14,
		bold: true,
		textAlign: 'center',
	},

	addPeople: {
		color: 'white',
	},

	dayTitle: {
		marginBottom: 14,
		...inCenterColumn,
	},

	dayRow: {
		flexDirection: 'row',
		marginBottom: 30,
		...inCenterColumn,
	},

	day: {
		marginLeft: 11,
		color: '#395aff',
		fontSize: 14,
		bold: true,
	},

	selectionScrollContainer: {
		paddingVertical: 10,
		...inCenterColumn,
	},

	selectionButton: {
		height: 40,
		minWidth: 130,
		paddingHorizontal: 15,
		backgroundColor: '#dad9e1',
		overflow: 'hidden',
		borderRadius: 8,
		...styles.center,
		margin: 5,
	},

	selectionButtonText: styles.static.bool({
		fontSize: 11,
		bold: true,
		zIndex: 1, // to above the gradient when selected
	},
		{ color: 'white' },
		{ color: '#656565' },
	)
};

const addGaps = intersperse(<RNView style={{ width: 11 }} />);
const SELECTED_BUTTON_BACKGROUND = (
	<GradientView
		colors={['#6200ea', '#1976d2']}
		{...styles.gradient.vertical}
		style={[styles.fit, { zIndex: -1 }]}
	/>
);