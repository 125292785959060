import use from "library-react/hook";
import generateShopCupboardLoadable from "library-js/app/generateShopCupboardLoadable";

// get sections from algolia rather than server, in order to only get items with products
const loadable = generateShopCupboardLoadable(window.shopId);
loadable?.load();

/**
 * @returns {Loadable<CatalogCupboard>}
 */
export default function sectionsLoadable() {
	use.subscribe(refresh => loadable?.onStateChanged(refresh));
	return loadable;
}
