import React from "react";
import use from "library-react/hook";
import View from "library-react/component/View";
import ContactItem from "../ContactItem/desktop";
import adaptSize from "../../../adaptSize";
import createContact from "./abstract";
import {styles} from "../../../res";

export default React.memo(
	createContact(
		function ({...props}, {name, contact, weekOpenHoursText,}) {
			props.style = use.defaultStyle(props.style, localStyles.layout);

			return (
				<View {...props}>
					<ContactItem title={name}
								 icon={'EvilIcons/location'}
								 iconSize={adaptSize(40)}
								 iconStyle={{width: adaptSize(30)}}
								 text={contact}
								 style={localStyles.address}
					/>
					<ContactItem title={'Horaires d’ouverture'}
								 icon={'SimpleLineIcons/clock'}
								 iconSize={adaptSize(30)}
								 iconStyle={{width: adaptSize(30), paddingHorizontal: adaptSize(20)}}
								 text={weekOpenHoursText}/>
				</View>
			);
		}
	)
);
const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.column,
	},
	address: {
		marginBottom: adaptSize(50),
	}
};
