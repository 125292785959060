import React from "react"
import Header from "../../component/Header/mobile";
import use from "library-react/hook";
import Footer from "./Footer/mobile";
import ScrollView from "library-react/component/ScrollView";
import RegionSupport from "@main/ui/ShopUI/Footer/RegionSupport";
import { View as RNView } from "react-native-web";
import { styles } from "@main/res";

export default function MobileUI({ alt, ...props }) {
	const { vShop } = use.context.infos();

	props.contentContainerStyle = localStyles.content;
	return (
		<ScrollView {...props}>
			<RNView>
				<Header title={'VENEZ NOUS RENDRE VISITE'}
					gallery={vShop.shop.gallery}
					description={vShop.shop.description}
					alt={alt} />
				<Footer item={vShop} />
			</RNView>
			<RegionSupport style={localStyles.support} />
		</ScrollView>
	);
};

const localStyles = {
	content: {
		flexGrow: 1,
		justifyContent: styles.justifyContent.spaceBetween
	},
	support: {
		marginTop: 50
	},
}