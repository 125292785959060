import {Loadable} from "../../utils"

/**
 * @deprecated
 */
export default class ServerLoadable extends Loadable {
	constructor(handler, serverFunction) {
		super(serverFunction);
		this.handler = handler;
	}

	async load(...params){
		let response = await this.onLoading(...params);
		if (response.ok)
			return response.content;

		response.log();
		throw response;
	}
}
