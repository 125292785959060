import moment from "moment";
import { nil } from "../../../../../types";
import { LegacyOrderState, OrderCurrentState } from "../../../../graphql";

export default function canBeSettled(orderState: Pick<OrderCurrentState, "state" | "time"> | nil) {
	if (!orderState) return;
	const {state, time} = orderState;
	const delayPast = moment(time).add(5, "minutes").isBefore(moment());

	return state == LegacyOrderState.prepaymentFailed
		|| (state == LegacyOrderState.processingPrepayment && delayPast);
}
