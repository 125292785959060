// WARNING ! This file should use pure JS only without using functionalities placed in the environment by the init.js file (like for instance Array.prototype.first).
import is from "./utils/is"

const Modes = {
	/**
	 * Development mode: crash when it can.
	 * In this mode, all {@link assert} with a true condition will throw an error.
	 **/
	development : "development",

	/**
	 * Deployment mode: The app is run from an apk (not stable, not optimized)
	 * In this mode, all {@link assert} with a true condition will warn error, and run the fallback function.
	 **/
	deployment : "deployment",

	get staging(){
		return Debug.deployment;
	},

	/**
	 * Release mode: The app is for end client in test (stable, not optimized)
	 * In this mode, all {@link assert} with a true condition will warn error, and run the fallback function.
	 **/
	release : "release",

	/**
	 * Production mode (stable & optimized).
	 * In this mode, all {@link assert} with a true condition will warn error, and run the fallback function.
	 **/
	production : "production",
};

/**
 * Allow to assert on error, with a different behavior according to its mode.
 * Contains:
 * <ul>
 *     <li>{@link Modes} : Object which contains the different modes of debug: development, release & production.</li>
 *     <li>mode : Current mode to adopt. On of the values defined in {@link Modes}</li>
 *     <li>{*} {@link select}(Object) : Select the value according to the current mode.</li>
 *     <li>{*} {@link run}(Object) : Run the function according to the current mode.</li>
 *     <li>{*} {@link assert}(condition, error, fallbackInProd) : If the condition (1st parameter) is true, throw an error (2nd parameter) if not in production mode. Otherwise, run the fallback function (3rd parameter).</li>
 *     <li>{String} {@link LOREM_IPSUM} : A lorem ipsum string.</li>
 * </ul>
 */
const Debug = {
	/**
	 * Current debug mode. The value must be one of the {@link Modes} values.
	 * Default value is {@link Modes.development}.
	 */
	mode : Modes.production,

	/**
	 * Contains all the possible debug modes.
	 */
	Modes : Modes,

	/**
	 * Function that takes, as parameter, an object of values mapped by their debug mode, and returns the selected value according to the current {@link mode} set.
	 * Select the 'default' property if no value was attributed to the current mode
	 * @param modesSwitch
	 * @returns {*}
	 */
	select(modesSwitch){
		return modesSwitch[Debug.mode] || modesSwitch.default;
	},

	run(modesSwitch){
		let func = Debug.select(modesSwitch);
		if (func)
			return func();
	},

	get is(){
		return Object.keys(Modes)
			.toObject(
				mode => mode,
				mode => mode === Debug.mode,
			);
	},

	/**
	 * If condition is true when {@link Debug.mode} is in {@link Debug.Modes.product} is:
	 * <ul>
	 *     <li>True, throws an error with the message parameter.</li>
	 *     <li>False, executes doInProd parameter if possible.</li>
	 * </ul>
	 * @param {Boolean} condition If true, assert the app.
	 * @param {*} message Message to throw or log. Should be basicly a string, but can be anything to log.
	 * @param {Function|Boolean?} fallbackInProd Callback method triggered when the condition is true and the mode is not in {@link Modes.development}.
	 * The assert function will return the returned value by this fallback function.
	 * You can set true instead of an empty function, if you wish to ignore the error.
	 */
	assert(condition, message, fallbackInProd) {
		let inDevelopmentMode = Debug.mode === Debug.Modes.development;

		if (inDevelopmentMode) { // In dev mode check parameters
			if (!(is(fallbackInProd, Function, true) || is(fallbackInProd, Boolean))) // check the callback in prod
				throw new Error("Debug.assert can only take a function or a boolean for the fallbackInProd parameter.");
		}

		if (condition) {
			if (message instanceof Function)
				message = message(condition);

			if (inDevelopmentMode) // in development
				throw new Error(message);

			else { // an error occurred in production
				console.warn(message); // log error
				if (is(fallbackInProd, Function)) // check if doInProd runnable
					return fallbackInProd(); // runs it
			}
		}
	},

	/**
	 * A shortcut method for assert with true as condition (1st parameter).
	 * Same as calling Debug.assert(true, ...)
	 */
	throw(message, fallbackInProd){
		return Debug.assert(true, message, fallbackInProd);
	},
};

export default Debug;
