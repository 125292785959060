import { isNil } from "ramda"

/**
 * @param {string} pathname 
 * @returns {string[]}
 */
export default function parsePathname(pathname) {
	if (isNil(pathname)) return;

	pathname = String(pathname).trim();

	if (pathname.startsWith('/'))
		pathname = pathname.slice(1);

	if (pathname.endsWith('/'))
		pathname = pathname.slice(0, pathname.length - 1);

	return pathname.split('/').filter(Boolean);
}