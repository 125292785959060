import PaymentMean from "../../app/model/entity/Order/PaymentMean";

export default function getResourcesForPaymentMean(mean) {
	mean = (mean instanceof PaymentMean) ? mean : PaymentMean.from(mean);
	if (mean) {
		const resources = mean.select(RESOURCES);
		return RESOURCES_KEYS.reduce((copy, key) => {
			const resource = resources[key];
			copy[key] = (resource instanceof Function) ? resource : () => resource;
			return copy;
		}, {});
	}
}

const RESOURCES_KEYS = ['title', 'icon'];

const RESOURCES = {
	online: {
		title: `En ligne`,
		icon: (index = 0) => ['PaymentMean.online', 'PaymentMean.online-mobile'][index]
	},

	card: {
		title: `Carte bancaire`,
		icon: 'PaymentMean.card'
	},

	cash: {
		title: `Espèces`,
		icon: 'PaymentMean.cash',
	},

	check: {
		title: `Chèque`,
		icon: 'PaymentMean.check',
	},

	restaurantTicket: {
		title: `Ticket restaurant`,
		icon: 'PaymentMean.restaurantTicket',
	},

	transfert: {
		title: `Virement bancaire`,
		icon: 'PaymentMean.transfert',
	},

	else: {
		title: `Autre`,
	}
};
