import {Base} from "../../../../../class"
import VShop from "../VShop"
import Price from "../../../Price"
import {Order, User} from "../../../entity"

export default class VOrder extends Base {
	constructor() {
		super();
		this.price = new Price(0); // price should not be null
	}


	get id(){
		return this.order?.id;
	}

	get creationDate(){
		return this.order?.creationDate;
	}

	get address(){
		return this.order?.card?.address;
	}

	get card(){
		return this.order?.card;
	}

	get state(){
		return this.currentState;
	}

	get receptionMean(){
		return this.order?.receptionMean;
	}

	set state(state){
		this.currentState = state;
	}

	get cancelable(){
		const {Type} = Order.State;
		return [
			Type.processingPayment,
			Type.preparing,
			Type.processingPrepayment,
			// Type.ready,
		].includes(this.currentState.type);
	}

	get shop(){
		return this.vShop?.shop;
	}

	isToBePaid(){
		return !this.currentState?.type?.is.settled;
	}

	canBePaidOnline(){
		return this.isToBePaid() && Boolean(
			this.order?.card
			&& this.order.expectedPaymentMean?.is.online
			&& !this.currentState?.type?.is.paymentFailed
		);
	}
}

VOrder.addProperties({
	order : Order,
	user : User,
	vShop : VShop,
	currentState : Order.State,
	price : Price,
	numberOfItems : Number,
});
