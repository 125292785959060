import { StyleSheet } from "react-native";
import styles from "../library-js/res/styles"
import { getStatusBarHeight } from "react-native-status-bar-height";
import ComponentUtils from "../ComponentUtils";
import Environment from "../library-js/Environment";

function newShadow(x = 0, y = 0, blur = 0, opacity = 0, color = 'black') {
	return {
		elevation: Math.trunc((y + blur) / 2),
		shadowColor: color,
		shadowOffset: { width: x, height: y },
		shadowOpacity: opacity,
		shadowRadius: blur,

		...Environment.select({
			ios: { overflow: 'visible' },
			default: {},
		}),
	};
}

/**
 * @returns {{
 * 		width: number,
 * 		height: number,
 * }}
 */
function square(size) {
	return {
		height: size,
		width: size,
	};
}

/**
 * @returns {{
 * 		width: number,
 * 		height: number,
 * 		borderRadius: number,
 * 		overflow: 'hidden',
 * }}
 */
function circle(size) {
	return {
		...square(size),
		borderRadius: size,
		overflow: 'hidden',
	};
}

/**
 * @returns {{
 * borderWidth: number,
 * borderColor: string,
 * borderStyle: import("react-native").ViewStyle["borderStyle"],
 * }}
 */
function newBorder(borderWidth = 1, borderColor = "black", borderStyle = "solid"){
	return {
		borderWidth,
		borderColor,
		borderStyle,
	};
};

/**
 * @param {number=} fontSize
 * @param {string=} color
 * @param {any=} font
 * @param {number=} lineHeight
 * @param {any=} bold
 * @param {any=} italic
 * @returns {import('react-native').TextStyle}
 */
function text(fontSize, color, font, lineHeight, bold, italic){
	return {
		fontSize,
		color, font, bold, italic,
		lineHeight,
	};
}

/**
 *  @type {const}
 */
const staticStyles = {
	center: {
		justifyContent: "center",
		alignItems: "center"
	},
};

/**
 *  @type {const}
 */
const reactStyles = {
	...staticStyles,

	appBarHeight: 54,
	statusBarHeight: getStatusBarHeight(),

	// ----- View ------
	flexWrap: {
		wrap: "wrap",
		nowrap: "nowrap"
	},

	flexDirection: {
		row: "row",
		column: "column",
		rowReverse: "row-reverse",
		columnReverse: "column-reverse",
	},

	alignContent: {
		flexStart: "flex-start",
		flexEnd: "flex-end",
		center: "center",
		stretch: "stretch",
		spaceBetween: "space-between",
		spaceAround: "space-around"
	},

	alignSelf: {
		auto: "auto",
		flexStart: "flex-start",
		flexEnd: "flex-end",
		center: "center",
		stretch: "stretch",
		baseline: "baseline"
	},

	direction: {
		inherit: "inherit",
		ltr: "ltr",
		rtl: "rtl"
	},

	display: {
		none: "none",
		flex: "flex"
	},

	justifyContent: {
		flexStart: "flex-start",
		flexEnd: "flex-end",
		center: "center",
		spaceBetween: "space-between",
		spaceAround: "space-around",
		spaceEvenly: "space-evenly"
	},

	alignItems: {
		flexStart: "flex-start",
		flexEnd: "flex-end",
		center: "center",
		stretch: "stretch",
		baseline: "baseline"
	},

	overflow: {
		visible: "visible",
		hidden: "hidden",
		scroll: "scroll",
		auto: "auto"
	},

	position: {
		absolute: "absolute",
		relative: "relative"
	},

	borderStyle: {
		solid: "solid",
		dotted: "dotted",
		dashed: "dashed",
	},

	// ------ Text ------
	textAlign: {
		auto: "auto",
		left: "left",
		right: "right",
		center: "center",
		justify: "justify",
	},

	fontStyle: {
		normal: "normal",
		italic: "italic",
	},

	fontWeight: {
		normal: "normal",
		bold: "bold",
		100: "100",
		200: "200",
		300: "300",
		400: "400",
		500: "500",
		600: "600",
		700: "700",
		800: "800",
		900: "900",
	},

	textDecorationLine: {
		none: "none",
		underline: "underline",
		lineThrough: "line-through",
		underlineLineThrough: "underline line-through",
	},

	// ------- text input -----
	textAlignVertical: {
		top: "top",
		center: "center",
		bottom: "bottom",
	},

	// ------ image -----
	resizeMode: {
		cover: "cover",
		contain: "contain",
		stretch: "stretch",
		repeat: "repeat",
		center: "center",
	},

	centerVertical: {
		justifyContent: "center",
	},

	fit: {
		position: "absolute",
		top: 0,
		right: 0,
		bottom: 0,
		left: 0
	},

	fill: {
		flexGrow: 1,
		alignSelf: "stretch",
	},

	absolute: (position) => ({
		position: 'absolute',
		...position,
	}),

	newShadow,

	shadow: {
		...newShadow(0, 3, 5, .16),
		zIndex: 1,
	},

	square,
	circle,
	newBorder,

	link: {
		color: "#0000EE",
		textDecorationLine: "underline",
	},

	popHover: {
		...newShadow(0, 3, 16, .16),
		backgroundColor: 'white',
		borderRadius: 14,
		paddingVertical: 6,
		paddingHorizontal: 18,
		fontSize: 12,
		position: 'absolute',
	},

	color: {
		...styles.color,
		darkTransparent: "#00000020",

		accent3: "#6c63ff",
		brownishGrey: "#4f4e4e",
		blackish: "#2a2a2a",
		greyish: "#a7a7a7",
		cherry: "#d81059",
		tealish: "#23bba5",
		link: "#0000EE",
		blue: "#2d56ff",
		azure2: '#0bafee',
	},

	gone: { display: "none" },
	invisible: { opacity: 0 },
	nothing: {},

	if: (condition, style) => condition ? style : reactStyles.nothing,
	phantom: (appear, interact) => !appear ? (interact ? reactStyles.invisible : reactStyles.gone) : undefined,

	newPaddings: (top, right, bottom, left) => ({
		paddingTop: top,
		paddingRight: right,
		paddingBottom: bottom,
		paddingLeft: left,
	}),

	newMargins: (top, right, bottom, left) => ({
		marginTop: top,
		marginRight: right,
		marginBottom: bottom,
		marginLeft: left,
	}),

	static: (() => {
		function staticStyles(common, statesStyles) {
			const styleFunc = state => styleFunc[state] || styleFunc.default;

			const states = Object.keys(statesStyles);
			if (!states.includes('default'))
				states.push('default');

			states.forEach(state => {
				const style = statesStyles[state];
				styleFunc[state] = ComponentUtils.style.merge(ComponentUtils.defaultStyle(style, common));
			});

			return styleFunc;
		}

		staticStyles.bool = (common, trueStyle, falseStyle) => {
			const styleFunc = staticStyles(
				common,
				{
					true: trueStyle,
					false: falseStyle,
				}
			);

			// force state to be a boolean
			const booleanStyleFunc = state => {
				state = Boolean(state);
				return styleFunc(state);
			};

			// assign all states styles
			Object.assign(booleanStyleFunc, styleFunc);

			return booleanStyleFunc;
		};


		return staticStyles;
	})(),

	text,

	gradient: {
		vertical: {
			start: { x: 0, y: 0 },
			end: { x: 0, y: 1 },
		},

		horizontal: {
			start: { x: 0, y: 0 },
			end: { x: 1, y: 0 },
		},
	},
};

export default {
	...styles,
	...reactStyles,
};