import { curry, is } from "ramda";
import moment from "moment";
import 'moment-timezone'

/**
 * 
 * @param {string} timezone 
 * @param {any} value 
 * @returns {moment | undefined}
 */
function toMoment(timezone, value, ...params) {
	if (value) {
		let result;

		if (is(String, value)) {
			const [format] = params;
			result = timezone ? moment.tz(value, format, timezone)
				: moment(value, format);
		} else {
			result = moment(value);
			if (timezone)
				result.tz(timezone);
		}

		if (result?.isValid())
			return result;
	}
}

export default curry(toMoment);
