import {Enum} from "../../../../../class";
import {times} from "ramda";

const Days = Enum.make({
	Sunday : 0,
	Monday : 1,
	Tuesday : 2,
	Wednesday : 3,
	Thursday : 4,
	Friday : 5,
	Saturday : 6,
});

export default Days;


Object.defineProperties(Days, {
	values: {
		configurable: true,
		get: function (){
			return times(this.from, 7);
		}
	}
});
