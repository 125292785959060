import InfiniteIterator from "../../../../../InfiniteIterator";
import config from "../config";

/**
 * @abstract
 */
export default class Iterator extends InfiniteIterator {
	shouldStop(){
		return super.shouldStop() // destroyed
			|| !config.id || !config.firebase; // chat manager reset
	}
}
