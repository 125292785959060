import Iterator from "./Iterator";
import config from "../config";
import {convertEntries, entryToEvent, nodeToEntry, Paths} from "../utils";
import {pipe, procedure} from "../../../../../utils/function";
import {fromIterator, map, reverse} from "../../../../../utils/array";

export default class EventIterator extends Iterator {
	constructor(interlocutorId) {
		super();
		this.interlocutorId = interlocutorId;
		this.items = [];
	}

	onLoading(page) {
		const {cursor, interlocutorId} = this;

		return Promise.process(this.shouldStop)
			// load from db
			.then(
				pipe([
					() => config.firebase.database()
						.ref(Paths.chat(interlocutorId).timeline),
					ref => ref.orderByKey()
						.limitToLast(NUMBER_PER_PAGE + (cursor ? 1 : 0)),
					ref => cursor ? ref.endAt(cursor) : ref,
					ref => ref.once("value"),
				])
			)
			// convert node into array of event data
			// IMPORTANT: node.forEach is better than Objects.forEach(node.val()) because it keeps order
			.then(node =>
				fromIterator(push =>
					node.forEach(item => (push(item), 0)) // forEach stops when fn returns a true value (and push returns the array length)
				)
			)
			.then(map(nodeToEntry))
			.then(reverse())
			// convert array of event data into event instances
			.then(async entries => {
				const events = await convertEntries(
					entries,
					entryToEvent,
					this.shouldStop,
					({key: id}) => (id !== cursor)
				);

				events.cursor = entries.last?.key;
				return events;
			})
			// add new events to .items | log error
			.transparent((events, error) => {
				if (events)
					this.items.push(...events);
				else if (error)
					console.warn(error);
			});
	}

	isEnd(page, events) {
		this.cursor = events.cursor;
		return events.length < NUMBER_PER_PAGE - events.incorrectCount;
	}
}

const NUMBER_PER_PAGE = 30;
