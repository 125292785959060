import createResForEnum from "./createResForEnum";
import Reservation from "../app/model/entity/Reservation";
import { isNil } from "ramda";
import Platform from "../Platform";

// - machou:	COLOR NEED TO BE IN HEX CODE TO EASILY ADD OPACITY
// - yair:		No, you can do it like so:	new Color(color).fade(.5).toString()
//				Do no hesitate to make a shortcut function if you need frequently to do so

const getResourcesForReservationState = createResForEnum(Reservation.State.Type, {
	waiting: {
		icon: time => isPast(time) ? '' : 'MaterialCommunityIcons/timer-sand',
		title: time => isPast(time) ? `Manquée`
			: Platform.select({
				retailer: `En attente`,
				user: `En attente de validation`,
			}),
		color: time => isPast(time) ? '#808080'
			: Platform.select({
				retailer: '#fd9926',
				user: '#ffc700',
			}),
	},

	accepted: {
		icon: 'MaterialCommunityIcons/check-bold',
		title: time => isPast(time) ? `Acceptée` : `Validée`,
		action: `Accepter`,
		color: time => isPast(time) ? '#8cc281'
			: Platform.select({
				retailer: '#6ac259',
				user: '#27ae60',
			})
		,
	},

	refused: {
		icon: 'Feather/slash',
		title: () => Platform.select({
			retailer: `Refusée`,
			user: `Annulée`,
		}),
		action: `Annuler`,
		color: time => isPast(time) ? '#e56871' : '#eb1b2b',
	},
});

export default getResourcesForReservationState;

const isPast = time => !isNil(time) && Number(time) && time < Date.now();