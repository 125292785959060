import InfiniteIterator from "../InfiniteIterator"
import { Base, Enum } from "../class"
import { Product, Sale, Shop } from "../app/model/entity"
import Debug from "../Debug"
import { VProdshop, VShop } from "../app/model/view"
import Platform from "../Platform"
import { is } from "../utils";
import VShowcase from "../app/model/view/general/VShowcase";
import { omit } from "ramda";
import willParse, { SourceModel } from "library-js/app/model/parse";

export default function buildSearchModule(algoliasearch) {
	/**
	 * Search module for the Shopinzon app.
	 * Contains:
	 * <ul>
	 *     <li>{@link InfiniteIterator} {@link getIterator}({@link Search.index}, query) : Return an {@link InfiniteIterator} on the given index with the query.</li>
	 *     <li>{@link Search.index} : Contains all the indexes to search in: 'product' & 'shop'.</li>
	 * </ul>
	 */
	const Search = {
		init(apiKey) {
			const Algolia = algoliasearch('9MJ02WLLYH', apiKey);

			// ---------- Defined search functions -----------
			this.index.forEach((key, indexEnum) => {
				const index = Algolia.initIndex(indexEnum.value);
				indexEnum.search = (request => index.search(request));
				indexEnum.clearCache = () => index.clearCache();

				// get iterator shortcut
				indexEnum.getIterator = (...params) => Search.getIterator(indexEnum, ...params);

				//Default configuration implemented at initialisation of each app
				indexEnum.default = {};

				// set a map of ids for deleted entities to prevent algolia to display deleted items
				indexEnum.deleted = {};
			});
		},

		/**
		 * Maximum number of results per page.
		 */
		MAX: 60,

		/**
		 * Build an {@link InfiniteIterator} to iterate page over page on the index filtering results according to the query.
		 * @param index Index to search in.
		 * @param query Query to search for. Can be a String, or an object which can contain the following properties:
		 * <ul>
		 *     <li><b>query String </b> The text query to search for.</li>
		 *     <li><b>categories String[] </b> Filter results to be only in the given categories.</li>
		 *     <li><b>tags String[] </b> Filter results to be only in the given tags.</li>
		 *     <li><b>productId Number </b> Id of a product. Filter results to match the product's id.</li>
		 *     <li><b>shopId Number </b> Id of a shop. Filter results to match the shop's id.</li>
		 *     <li>@deprecated <b>catalogOnly Boolean </b> Only prodshops in shop's catalog are matched. Works if shopId is set. Use inShop instead. Default value: false.</li>
		 *     <li><b>inShop Boolean </b> Indicates if prodshops should be in shop's catalog or not. A null value will include both while a false value will exclude prodshop in shop.</li>
		 *     <li><b>location Location </b> Order result by closer to the location.</li>
		 *     <li><b>region Region </b> Filter results matching only in the defined region.</li>
		 * </ul>
		 *
		 * As a hack, you can also add any properties which can be accepted <a href="https://www.algolia.com/doc/api-reference/search-api-parameters/">as Algolia parameter</a>.
		 * @returns {InfiniteIterator.<{ok, content, count, page, pages, error, log()}>} An iterator to iterate over the index page after page.
		 * The iterator return an object with the folowing properties:
		 * <ul>
		 *     <li><b>ok Boolean</b>: True if the request has done well. False in case of error.</li>
		 *     <li><b>content Array</b>: The content loaded (only if ok is true).</li>
		 *     <li><b>count Number</b>: The total number of hits matching the search (only if ok is true).</li>
		 *     <li><b>page Number</b>: The current page loaded (only if ok is true).</li>
		 *     <li><b>pages Number</b>: The total number of pages matching the search (only if ok is true).</li>
		 *
		 *     <li><b>error Error</b>: The occurred error (only if ok is false).</li>
		 *     <li><b>log Function</b>: A function to log the error (only if ok is false).</li>
		 * </ul>
		 * Check the {@link InfiniteIterator.ready} property before calling {@link InfiniteIterator.load} method.
		 */
		getIterator(index, query = "") {
			Debug.assert(!Search.index.includes(index), "Search.getIterator function takes a Search.index enum as 1st parameter.");

			// if config is not an object, force to cast into string
			if (is.null(query))
				query = "";
			else if (!is(query, Object))
				query = String(query);

			const configuration = is(query, Object) ? query : { query };
			Object.assign(configuration, index.default, configuration);

			return new Iterator(index, configuration);
		},

		async getSuggestionsFor(query) {
			if (!query)
				return [];

			let suggestions = [];
			let request = { query };
			try {
				const autocomplete = {};
				let result = await Search.index.prodshop.search(request);

				for (const hit of result.hits) {
					let { brand, name, tags } = hit._highlightResult.product;
					let matched_word = "";

					if (name?.matchedWords?.length > 0)
						matched_word += makeStandardWord(name.value) + " ";

					if (brand?.matchedWords?.length > 0) {
						const word = makeStandardWord(brand.value);
						if (!matched_word.includes(word))
							matched_word += word + " ";

					}

					if (!tags)
						tags = [];
					for (const tag of tags) {
						if (tag?.matchedWords?.length > 0) {
							const word = makeStandardWord(tag.value);
							if (!matched_word.includes(word))
								matched_word += word + " ";
						}
					}
					matched_word = matched_word.trim();
					if (matched_word) {
						// First time we see this match word
						if (!autocomplete[matched_word])
							autocomplete[matched_word] = {};

						if (!autocomplete[matched_word][""])
							autocomplete[matched_word][""] = 0;

						autocomplete[matched_word][""] += 1;
						//Concatenate matched word with each tag
						for (const tag of tags) {
							const value = makeStandardWord(tag.value);
							if (!matched_word.includes(value)) {
								if (!autocomplete[matched_word][value])
									autocomplete[matched_word][value] = 0;
								autocomplete[matched_word][value] += 1
							}
						}
					}
				}
				const suggest = Object.keys(autocomplete)
					.sort((a, b) => autocomplete[b][""] - autocomplete[a][""]);
				const concatenatedSuggest = {};
				for (const root of Object.keys(autocomplete)) {
					for (const tag of Object.keys(autocomplete[root]))
						if (tag !== "")
							concatenatedSuggest[`${root} ${tag}`] = autocomplete[root][tag]
				}
				const concatSuggest = Object.keys(concatenatedSuggest)
					.sort((a, b) => concatenatedSuggest[b] - concatenatedSuggest[a]);

				suggestions = suggest.concat(concatSuggest);
			} catch (e) {
				console.warn(e);
			}
			return suggestions;

		},

		index: new Enum({
			product: "product_0",
			shop: "shop_0",
			prodshop: "prodshop_0",
			sale: "sale_0",
			showcase: "showcase_0",

			// prodshop copy distinct by shop
			prodshopShop: "prodshop_shop",
		}),
	};


	// ----- classes ----
	class Response extends Base {
		constructor(content) {
			super();
			this.content = content;
			this.page = 0;
		}

		get ok() {
			return is(this.content, Array);
		}

		get left() {
			return this.count - (this.page + 1) * this.content.length;
		}

		log() {
			if (this.ok)
				console.log(this.toJSON());
			else
				console.warn("Error loading search results: ", this.content);
		}

		toJSON() {
			return { ...this };
		}
	}

	Response.addProperties({
		content: null, // Array or Error
		count: Number,
		pages: Number,
		page: Number,
		request: Object,
		facets: Object,
	});

	class Iterator extends InfiniteIterator {
		constructor(index, configuration) {
			super();
			this._index = index;
			this._configuration = configuration;
			this.items = [];
			this.responses = [];
		}

		async load() {
			let response;
			try {
				response = await super.load();

				if (this._configuration.all) { // load all pages
					while (response.ok && !this.end)
						response = await super.load();

					response.content = this.items;
				}
			} catch (error) {
				response = is(error, Response) ? error : new Response(error);
			}

			return response;
		}

		getRequest(page = 0) {
			let index = this._index;
			let configuration = this._configuration;
			let request = {
				query: is.null(configuration.query) ? "" : String(configuration.query),
				filters: [],
				facets: ["*"],
			};

			// --- shop categories ---
			if (configuration.shopCategories?.length > 0) {
				const toFilter = index.is.showcase ? shopCategory => `showcase.categories=${shopCategory}`
					: shopCategory => `shopCategoriesIds=${shopCategory}`;

				request.filters.push(configuration.shopCategories.map(toFilter));
			}

			// ------- product & prodshop -------
			if ([Search.index.product, Search.index.prodshop, Search.index.prodshopShop].includes(index)) {
				// filter by categories
				let categories = configuration.categories;
				if (categories)
					request.filters.push(categories.map(category => `categories:"${category}"`));

				// filter by age classes
				let ageClasses = configuration.ageClasses;
				if (ageClasses)
					request.filters.push(ageClasses.filter(Boolean).map(ageClass => `product.ageClass=${ageClass.value}`));

				// filter by gender
				let gender = configuration.gender;
				if (is(gender))
					request.filters.push(`product.gender:${Boolean(gender)}`);

				// filter by tags
				let tags = configuration.tags;
				if (tags)
					request.filters.push(tags.map(tag => `product.tags:"${tag}"`));

				//filter by brand
				const brands = configuration.brands || (configuration.brand ? [configuration.brand] : undefined);
				if (brands)
					request.filters.push(brands.map(brand => `product.brand:"${brand}"`));

				// filter for a specific product id
				let productId = configuration.productId;
				if (productId)
					request.filters.push(`product.id=${productId}`);

				if (is(configuration.private))
					request.filters.push(`product.isPublic=${!configuration.private ? 1 : 0}`);
			}

			// ------- prodshop distinct by shop ---
			index.run({
				prodshop() {
					// remove duplicates
					request.distinct = !configuration.productId;
				},

				prodshopShop() {
					request.distinct = configuration.distinct || true;
				},
			});


			// -- by shop id --
			if (configuration?.shopId && index.is.withShop)
				request.filters.push(`shop.id=${configuration?.shopId}`);

			// -- activated --
			if (is.defined(configuration?.activated) && index.is.withShop) {
				const activated = Boolean(configuration.activated);
				request.filters.push(`(shop.activated:${activated} OR shop.production:${activated})`);
				// request.filters.push(`shop.activated:${activated}`); TODO use this line when production field will be removed
			}

			// -- discounted --
			if (configuration?.discounted && index.is.withProdshop)
				request.filters.push(`prodshop.discount.value > 0`);


			index.run({
				// ----- sale ------
				sale() {
					if (configuration.accepted)
						request.filters.push(`sale.accepted > 0`);

					if (configuration.from)
						request.filters.push(`sale.end > ${configuration.from}`);
				},
			});

			// ------ prodshop only -----
			if ([Search.index.prodshop, Search.index.prodshopShop].includes(index)) {
				// availability
				if (Platform.is.user) // force user to see only available prodshop
					request.filters.push(`prodshop.available:${true}`);
				else if (is(configuration.available))
					request.filters.push(`prodshop.available:${Boolean(configuration.available)}`);

				// sections
				let { sections } = configuration;
				if (sections) {
					if (!(sections instanceof Array))
						sections = [sections];

					const sectionsName = sections.map(section => {
						if (!section) return; // filter empty string too
						return String(section); // CatalogSection.toString() => name
					})
						.filter(Boolean);

					request.filters.push(sectionsName.map(name => `sections:${JSON.stringify(name)}`));
				}
			}


			// Excludes
			if (is(configuration.exclude, Array))
				configuration.exclude.forEach(exclusion => {
					let key = Object.keys(exclusion)
						.first;
					const value = exclusion[key];

					// skip "vProduct" or vShop
					if ([Search.index.prodshop, Search.index.prodshopShop].includes(index))
						key = key.split(".")
							.slice(1)
							.join(".");

					request.filters.push(`${!is(value, Number) ? "NOT " : ""}${key}${is(value, Number) ? "!=" : ':'}${value}`)
				});


			// sort by location
			let location = configuration.location;
			if (location)
				request.aroundLatLng = `${location.latitude},${location.longitude}`;

			let region = configuration.region;
			if (region)
				request.insideBoundingBox = convertRegion(region);


			// number of hits per page
			if (configuration.all) // load all
				request.hitsPerPage = Number.MAX_SAFE_INTEGER;
			else
				request.hitsPerPage = configuration.number;

			// indicate page to load
			request.page = page;

			// add custom filters if set
			if (configuration.customFilters)
				request.filters.push(...configuration.customFilters);

			// convert filters in string
			request.filters = request.filters.reduce((exp, item) => {
				let result = (item instanceof Array) ?
					(item.length ? `(${item.join(" OR ")})` : "")
					: item;

				if (!result)
					result = exp;
				else if (exp)
					result = `${exp} AND ${result}`;

				return result
			}, "");

			return request;
		}

		async getMetadatas() {
			const request = this.getRequest();
			request.hitsPerPage = 0;

			const response = {};
			try {
				const result = await this.sendRequest(request);
				response.ok = true;
				response.content = result;
			} catch (error) {
				response.ok = false;
				response.log = () => console.warn("Error loading algolia index meta datas: ", error);
			}

			return response;
		}

		/**
		 * @override
		 */
		async onLoading(page) {
			// --- send ---
			let request, result, error;
			try {
				request = this.getRequest(page);
				result = await this.sendRequest(request);
			} catch (e) {
				error = e;
				console.warn(error);
			}

			let response;
			if (result) {
				response = new Response()
					.hydrate({
						count: result.nbHits,
						pages: result.nbPages,
						facets: result.facets,
						page,
					});

				this.facets = result.facets; // save facets in iterator too

				let index = this._index;

				if ([Search.index.product, Search.index.prodshop].includes(index)) {
					response.categoriesCounts = result.facets.categories || {};
					response.brandsCounts = result.facets["product.brand"] || {};
				}

				if (index === Search.index.prodshop)
					response.shopsCounts = result.facets["product.id"] || {};

				response.content = [];
				for (const hit of result.hits) {
					if (this.shouldStop()) break;

					const highlightResult = hit._highlightResult;
					try {
						cleanHit(hit);
						const result = index.converter ? await index.converter(hit) : hit;

						await Promise.await();
						result.highlightResult = highlightResult;
						response.content.push(result);

					} catch (e) {
						console.warn(hit, e);
					}
				}

				if (this.onResolveResponse)
					this.onResolveResponse(response);

				if (response.ok)
					this.addToItems(response.content);
			} else
				response = new Response(error);


			response.request = request;

			if (response.ok) {
				this.responses.push(response);
				return response;
			}
			throw response; // for handlers
		}

		async sendRequest(request) {
			let index = this._index;
			let result = await index.search(request);

			Platform.run({
				user() {
					// keep cache for user
				},

				// for retailer & marketer clear cache
				default() {
					// Algolia.clearCache();
					index.clearCache();
				}
			});

			return result;
		}

		onResolveResponse(response) {
		}

		addToItems(items) {
			this.items.push(...items);
		}

		/**
		 * @override
		 */
		shouldIncrementPage(response) {
			return response.ok;
		}

		static join(iterators, isResultEmpty) {
			let iterator = super.join(iterators, isResultEmpty);
			iterators = iterators.copy();
			Object.defineProperties(iterator, {
				items: {
					configurable: true,
					get: () => iterators.flatMap(iterator => iterator.items)
				}
			});

			return iterator;
		}

		/**
		 * @override
		 */
		isEnd(page, response) {
			return page >= response.pages;
		}
	}

	Search.index.sale.Result = class SearchIndexSaleResult extends Base {
	};
	Search.index.sale.Result.addProperties({
		sale: Sale,
		shop: Shop,
	});

	// ---------- Define convert functions -------
	Search.index.product.converter = hit => parse(Product, hit.product);
	Search.index.sale.converter = hit => Search.index.sale.Result.from(hit);
	Search.index.shop.converter = hit => parse(VShop, {
		shop: hit.shop,
		rate: hit.shopRate,
	});
	Search.index.prodshop.converter =
		Search.index.prodshopShop.converter = hit =>
			parse(VProdshop, {
				vProduct: hit.product && {
					product: hit.product,
					rate: hit.productRate || {}
				},
				vShop: hit.shop && {
					shop: omit(['category'], hit.shop),  // TODO remove omit once this task is solved: https://app.clickup.com/t/6bw3n2
					rate: hit.shopRate || {},
				},
				prodshop: hit.prodshop,
				vSaleProduct: hit.privateSale && {
					sale: hit.privateSale.sale,
					saleProduct: hit.privateSale.product,
				},
				sections: hit.sections,
			});

	Search.index.showcase.converter = hit => parse(VShowcase, { showcase: hit.showcase, shopsCount: hit.shopsCount });

	Search.index.domains.withShop = [Search.index.shop, Search.index.prodshop, Search.index.prodshopShop];
	Search.index.domains.withProdshop = [Search.index.prodshop, Search.index.prodshopShop];

	return Search;
}

const parse = willParse(SourceModel.algolia);


// ----- functions --------

function cleanHit(hit) {
	delete hit.objectID;
	delete hit._highlightResult;
	delete hit._geoloc;
}

// convert region into an array of 2 oposite locations
function convertRegion(region) {
	const boundingBox = [];
	region.bounds.forEach(location => boundingBox.push(location.latitude, location.longitude));
	return boundingBox;
}

const makeStandardWord = value => value.replace(/<em>|<\/em>/g, '').trim().toLowerCase();
