import React from "react"
import {View as RNView} from "react-native"
import use from "library-react/hook"
import {styles} from "@main/res"

function ProdshopPrice({prodshop, finalPrice, oldPrice, fontSize, finalPriceStyle, oldPriceStyle, gap, ...props}) {
	if (!finalPrice)
		finalPrice = prodshop?.finalPrice;

	if (!oldPrice)
		oldPrice = prodshop?.oldPrice;


	const {Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);
	finalPriceStyle = use.defaultStyle(finalPriceStyle, localStyles.finalPrice, [oldPrice, fontSize]);
	oldPriceStyle = use.defaultStyle(oldPriceStyle, localStyles.oldPrice, [fontSize]);

	return (
		<RNView {...props}>
			{
				oldPrice &&
				<Text
					numberOfLines={1}
					style={oldPriceStyle}>
					{oldPrice.value}
					<Text style={localStyles.currency}>
						{oldPrice.symbol}
					</Text>
				</Text>
			}

			{
				oldPrice &&
				<RNView style={styles.square(gap || 5)}/>
			}

			<Text
				numberOfLines={1}
				style={finalPriceStyle}>
				{finalPrice.value}
				<Text style={localStyles.currency}>
					{finalPrice.symbol}
				</Text>
			</Text>
		</RNView>
	);
}

export default React.memo(ProdshopPrice);


const localStyles = {
	layout: {},

	finalPrice: (discounted, fontSize) => ({
		textAlign: styles.textAlign.center,
		bold: true,
		fontSize: fontSize || 19,
		...(
			discounted ? {
					color: styles.color.white,
					paddingHorizontal: 7,
					backgroundColor: '#cd081e',
				} :
				{color: styles.color.black}
		)
	}),

	oldPrice: fontSize => ({
		fontSize: (fontSize * 16 / 19) || 12,
		textDecorationLine: styles.textDecorationLine.lineThrough,
		bold: true,
		textAlign: styles.textAlign.center,
		flexShrink: 1,
	}),

	currency: {
		fontSize: '.7em'
	},
};
