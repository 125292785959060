import React from "react"
import {FlatList as RNFlatList} from "react-native"
import use from "../../hook"
import Environment from "../../library-js/Environment";
import parallel from "../../library-js/utils/function/parallel";


const FlatList = React.forwardRef(
	function FlatList({...props}, refProp) {

		// WEB WORKAROUND for issue https://github.com/necolas/react-native-web/issues/1608
		// wrap onContentSizeChange to trigger onEndReached when content height shorter than layout
		if (Environment.is.web) {
			const memory = use.memo({layout: null});
			const {onEndReached} = props;

			// must save layout to compare with content height later
			props.onLayout = use.callback(
				parallel([
					({nativeEvent}) => memory.layout = nativeEvent.layout,
					props.onLayout,
				]),
				[props.onLayout]
			);

			// define onContentSizeChange wrapper only if onEndReached defined
			const onContentSizeChange = use.callback(
				onEndReached &&
				parallel([
					(_, contentHeight) => {
						if (contentHeight < memory.layout?.height)
							onEndReached();
					},
					props.onContentSizeChange,
				])
				,
				[props.onContentSizeChange, onEndReached]
			);

			// wrap onContentSizeChange to trigger onEndReached
			if (onContentSizeChange)
				props.onContentSizeChange = onContentSizeChange;
		}

		return <RNFlatList ref={refProp} {...props} />;
	}
);

export default FlatList;
