import React from "react"
import {FlatList} from "react-native";
import use from "library-react/hook";
import View from "library-react/component/View/v2";
import {styles} from "@main/res"
import Image from "library-react/component/Image";
import Portal from "library-react/component/Portal";
import {screens} from "@main/links";

export default function MobileUI({iterator, ...props}) {
	const {Text, Spinner} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<FlatList
			data={iterator.items}
			keyExtractor={brand => brand.name}
			renderItem={({item: brand}) =>
				<Portal to={screens.brand} params={{name: brand.name}}>
					<View style={localStyles.row}>
						{
							brand.logo ?
								<Image
									source={brand.logo}
									resizeMode="contain"
									style={localStyles.logo}
								/> :
								<Text
									numberOfLines={2}
									style={localStyles.name}>
									{brand.name}
								</Text>
						}
					</View>
				</Portal>
			}

			onEndReached={iterator.ready ? iterator.load : undefined}
			onEndReachedThreshold={.01}

			ListFooterComponent={
				!iterator.end &&
				<Spinner style={localStyles.spinner}/>
			}

			contentContainerStyle={localStyles.content}
		/>
	);
}

const localStyles = {
	layout: {},

	content: {
		paddingTop: 25,
		alignSelf: styles.alignSelf.center,
		width: '98%',
		maxWidth: 400,
	},

	row: {
		...styles.newBorder(1, '#9a9999'),
		padding: 5,
		height: 105,
		marginBottom: 25,
		...styles.center,
	},

	logo: {
		...styles.absolute({
			top: 5,
			bottom: 5,
			right: 10,
			left: 10,
		}),
	},

	name: {
		fontSize: 26,
		bold: true,
		textAlign: styles.textAlign.center,
	},

	spinner: {
		marginBottom: 20,
	}
};
