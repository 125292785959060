import {Base} from "../../../../../class"
import {Collection} from "../../../entity"
import vProduct from "../VProduct"

export default class VCollection extends Base {
	get id(){
		return this.collection && this.collection.id;
	}
}
VCollection.addProperties({
	collection: Collection,
	count: Number,
});

VCollection.Item = class VCollectionItem extends Base {
	get collectionId(){
		return this.vCollection && this.vCollection.id;
	}

	get collection(){
		return this.vCollection && this.vCollection.collection;
	}

	get product(){
		return this.vProduct && this.vProduct.product;
	}
};
VCollection.Item.addProperties({
	vCollection: VCollection,
	vProduct: vProduct
});
