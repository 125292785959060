import React from "react"
import use from "library-react/hook"
import GridItem from "./GridItem";
import ListItem from "./ListItem";
import styles from "library-react/res/styles";

function BasketItemView({setQuantityOf, removeItem, ...props}) {
	const display = use.context.display();
	const {item} = props;

	props.increase = use.callback(
		() => setQuantityOf(item, item.quantity + 1),
		[item, setQuantityOf]
	);
	props.decrease = use.callback(
		() => setQuantityOf(item, item.quantity - 1),
		[item, setQuantityOf]
	);
	props.remove = use.callback(
		() => removeItem(item),
		[item, removeItem]
	);

	props.style = use.defaultStyle(props.style, localStyles.layout(display), [display]);

	return (
		display.is.mobile ?
			<ListItem {...props} /> :
			<GridItem {...props}/>
	);
}

export default React.memo(BasketItemView);

const localStyles = {
	layout: styles.static({},
		{
			mobile: {},
			default: {marginRight: 56},
		}
	),
}
