import React from "react";
import {Text as RNText} from "react-native";
import {addIndex, is, map, pipe, split, test, when} from "ramda";
import LinkContext from "../../context/LinkContext";


const URL_REGEX = /^(http(s)?:\/\/)?([-a-zA-Z0-9]+\.)+[a-z]{2,6}(\/[-a-zA-Z0-9%]+)*(\?[-a-zA-Z0-9%&=]*)?(#[-a-zA-Z0-9%]*)?[.:;,]*$/;
const URL_TOLERATED_ENDS = ['.', ':', ';', ','];

const parseLinks = when(is(String),
	pipe(
		split(/(\ |\n)/),
		addIndex(map)(
			when(test(URL_REGEX),
				(word, index) => {
					const letters = word.split('');
					while (URL_TOLERATED_ENDS.includes(letters.last)) letters.pop();
					const linkTxt = word.slice(0, letters.length);
					const end = word.slice(letters.length);
					const link = test(/^http(s)?:\/\//, linkTxt) ? linkTxt : `https://${linkTxt}`;

					return (
						<>
							<LinkContext.Provider key={index} value={true}>
								<RNText
									href={link}
									accessibilityRole="link"
									target="_blank"
									style={LINK_STYLE}>
									{linkTxt}
								</RNText>
							</LinkContext.Provider>
							{end}
						</>
					);
				})
		),
	)
);

export default parseLinks;

const LINK_STYLE = {color: 'blue'};
