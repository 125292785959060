import { Trigger } from "./utils";
import Enum from "./class/Enum";
import crashlytics from "./crashlytics";
import firebasePromise from "./firebasePromise";
import { parallel } from "./utils/function";
import timeout from "./utils/timeout";

/**
 * Authentication Manager.
 */
const AuthManager = {
	async init() {
		firebase = await firebasePromise;

		let auth;
		try {
			auth = firebase.auth();
		} catch (error) {
			console.warn("AuthManager: firebase/auth not installed");
		}

		if (auth)
			auth.onAuthStateChanged(onUserChanged);

		return AuthManager.onceReady;
	},

	get ready() {
		return ready;
	},

	get onceReady() {
		return readyPromise;
	},

	/**
	 * @return {Promise | null}
	 */
	get authenticating() {
		return (!this.ready && this.onceReady) || (!this.user && authenticating) || null;
	},

	set authenticating(promise) {
		const oldState = this.state; // memorise before update

		// update
		if (promise instanceof Promise) { // accept promise only
			(authenticating = promise).anyway(() => {
				if (authenticating === promise) // check if variable has been override by another promise more recent
					this.authenticating = undefined; // clean & notify
			});
		} else if (!promise) // clean
			authenticating = undefined;

		// check if state changed
		const shouldNotify = oldState !== this.state;
		if (shouldNotify) // fire
			stateChangeTrigger.fire(this.state, oldState);
	},

	/**
	 * @deprecated
	 */
	get state() {
		return this.user ? State.yes
			: this.authenticating ? State.authenticating
				: State.no;
	},

	get user() {
		return firebase?.auth().currentUser;
	},

	get token() {
		return this.getTokenOf(this.user);
	},

	/**
	 * @returns {Claim}
	 */
	get claims() {
		return this.getClaimsOf(this.user);
	},

	async getToken(refresh) {
		if (AuthManager.user)
			return getTokenData(refresh)
				.then(data => data?.token);
	},

	async getClaims(refresh) {
		if (AuthManager.user)
			return getTokenData(refresh)
				.then(data => data?.claims);
	},

	getTokenOf(user) {
		return tokensData[user?.uid]?.token;
	},

	async reloadUser() {
		const oldUser = AuthManager.user;
		return AuthManager.user?.reload()
			.then(() => {
				const { user } = AuthManager;
				if (user) {
					userChangeTrigger.fireAsyncOnce(user, oldUser);
					return user;
				}
			});
	},

	/**
	 *
	 * @param {firebase.User} user
	 * @returns {Claim}
	 */
	getClaimsOf(user) {
		return tokensData[user?.uid]?.claims;
	},

	onUserChanged(callback) {
		return userChangeTrigger.add(callback);
	},

	/**
	 * @deprecated
	 */
	onStateChanged(callback) {
		console.warn("DEPRECATED: Use AuthManager.onUserChanged() instead of onStateChanged.");
		return stateChangeTrigger.add(callback);
	},

	onceAuthenticated(callback) {
		let remove;
		const cancel = timeout(() => {
			if (AuthManager.user)
				callback(AuthManager.user);
			else
				remove = AuthManager.onUserChanged((user, ...rest) => {
					if (user) {
						callback(user, ...rest);
						remove(); // called once only
					}
				});
		});

		return parallel(cancel, () => remove?.());
	},

	linkCredentialOnceAuthenticated(credential) {
		AuthManager.onceAuthenticated(user => user.linkWithCredential(credential));
	},

	signOut() {
		return firebase.auth().signOut();
	},

	async signInAndRetrieveDataWithCredential(credential) {
		try {
			await firebase.auth().signInAndRetrieveDataWithCredential(credential);
		} catch (firebaseError) {
			const code = AuthFirebaseErrors[firebaseError.code] || AuthErrors.bug;

			if (code === AuthErrors.credentialCollision)
				AuthManager.linkCredentialOnceAuthenticated(credential)


			throw new Error(firebaseError)
				.setError(code);
		}
	}
};

export default AuthManager;

/**
 * @deprecated
 */
export const State = new Enum("no", "authenticating", "yes");

let firebase;
let savedUser;
let authenticating;
const tokensData = {/* uid -> token data */ };

let userChangeTime = 0;
const userChangeTrigger = new Trigger();
const stateChangeTrigger = new Trigger();
userChangeTrigger.add((user, oldUser) => {
	if (!user !== oldUser) {
		const oldState = oldUser ? State.yes :
			AuthManager.authenticating ? State.authenticating : State.no;
		stateChangeTrigger.fire(AuthManager.state, oldState);
	}
});

function getTokenData(refresh) {
	const user = AuthManager.user;
	const uid = user?.uid;

	return user?.getIdTokenResult(refresh)
		.then(data => tokensData[uid] = {
			token: data.token,
			claims: {
				...data.claims,
				shopId: data.claims.shop_id,
			},
		});
}

let ready = false;
const [readyPromise, onReady] = Promise.external();

function onUserChanged(user) {
	if (!ready) { // firebase auth initiated
		ready = true;
		onReady(user);
	}

	if (savedUser === user) return;

	if (user?.uid !== savedUser?.uid) {
		if (user) // update token
			AuthManager.getToken(); // save token

		// notify
		userChangeTrigger.fire(user, savedUser, userChangeTime++);
	}

	savedUser = user;
}

export const AuthErrors = AuthManager.Errors = new Enum([
	"notExist",
	"disabled",
	"invalid",
	"credentialCollision",
	"permissions",
	"bug",
]);

AuthErrors.fromFirebaseError = firebaseError => AuthFirebaseErrors[firebaseError.code] || AuthErrors.bug;

export class AuthError extends Error {
	constructor(message, type) {
		super(message);
		this.type = type || AuthErrors.bug;
	}
}

export const AuthFirebaseErrors = {
	"auth/account-exists-with-different-credential": AuthErrors.credentialCollision,
	"auth/user-not-found": AuthErrors.notExist,
	"auth/user-disabled": AuthErrors.disabled,
	"auth/invalid-credential": AuthErrors.invalid,
	"auth/wrong-password": AuthErrors.invalid,
};

/**
 * @typedef {{
 * 		passwordRenewalExpiration: number,
 * 		shopId: number
 * }} Claim
 */

/**
 * Crashlytics log user
 */
if (crashlytics)
	userChangeTrigger.add((user, oldUser, time) => {
		crashlytics.log(
			user ? `Authenticated as:\n email: ${user.email}\n id: ${user.uid}`
				: oldUser ? `Logout from:\n email: ${oldUser.email}\n id: ${oldUser.uid}`
					: `Not Authenticated`
		);

		if (user)
			crashlytics.setUserId(user.uid);

		crashlytics.setAttributes({
			name: user?.displayName,
			email: user?.email,
		});
	});
