import Text from "../../../../component/Text";
import Icon from "../../../../component/Icon/v2";
import { is } from "../../../../library-js/utils";
import use from "../../../../hook";
import { styles } from "../../../../res";
import { View as RNView } from "react-native";
import { Linking } from "react-native";
import React from "react";
import SimpleButton from "../../../../component/SimpleButton";
import PaymentSecureCertification from "../PaymentSecureCertification";
import StaticComponent from "../../../../component/StaticComponent";

export default function CheckoutBottomBar_desktop(
	{ sum, loading, onSumClicked, nextLabel, onNextClicked, ...props }
) {
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<RNView {...props}>
			<RNView style={localStyles.wrapper}>
				<PaymentSecureCertification style={localStyles.secure} />

				<Conditions />

				{/* total */}
				<RNView style={localStyles.total.layout}>
					<Text style={localStyles.total.label}>
						{`Total`}
					</Text>

					<Text style={localStyles.total.tva}>
						{`TVA compris`}
					</Text>

					<Text style={localStyles.total.price}>
						{sum?.formatted}
					</Text>
				</RNView>

				{/* submit */}
				<SimpleButton
					border
					disabled={loading || !onNextClicked}
					onVoidPress={!loading && onNextClicked}
					filled={!loading}
					colors={localStyles.submit.colors}
					hoverColors={localStyles.submit.hoverColors}
					style={localStyles.submit.style}>
					<Text style={localStyles.submit.text}>
						{loading ? (is(loading, String) ? loading : `Envoi...`) : (nextLabel || `VALIDER`)}
					</Text>
				</SimpleButton>
			</RNView>
		</RNView>
	);
}


const Conditions = new StaticComponent(
	<RNView>
		<RNView style={{
			flexDirection: styles.flexDirection.row,
			alignItems: styles.alignItems.center,
			maxWidth: 350,
		}}>
			<Icon
				name="MaterialCommunityIcons/shield-lock"
				size={35}
				style={{ marginRight: 13, color: "#9a9999" }}
			/>

			<Text
				numberOfLines={3}
				style={{ width: 340, color: "#9a9999", fontSize: 11 }}>
				{`En effectuant votre achat, vous acceptez `}
				<Text
					{...Linking.linkPropsFor("https://shopinzon.com/legal-agreement")}
					style={{ color: styles.color.black }}>
					{`nos conditions d’utilisation`}
				</Text>
				{` et `}
				<Text
					{...Linking.linkPropsFor("https://shopinzon.com/private-policy")}
					style={{ color: styles.color.black }}>
					{`notre politique de confidentialité`}
				</Text>
			</Text>
		</RNView>
	</RNView>
);

export const MAX_CONTENT_WIDTH = 1470;

const localStyles = {
	layout: {
		borderTopWidth: 1,
		borderColor: styles.color.lightgrey,
		flexDirection: styles.flexDirection.row,
	},

	wrapper: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
		justifyContent: styles.justifyContent.spaceBetween,
		paddingLeft: 20,
		height: 100,
		maxWidth: MAX_CONTENT_WIDTH,
		flex: 1,
	},

	secure: {
		height: 46,
		width: 280,
	},

	total: {
		layout: {
			flexDirection: styles.flexDirection.row,
			alignItems: styles.alignItems.baseline,
			backgroundColor: "#f7f7f7",
			padding: 20,
			width: 350,
			height: 62,
		},

		label: {
			fontSize: 19,
			bold: true,
		},

		tva: {
			fontSize: 14,
			color: "#9a9999",
			flex: 1,
			paddingHorizontal: 10,
		},

		price: {
			fontSize: 20,
			bold: true,
		}
	},

	submit: {
		colors: {
			primary: '#395afb',
			secondary: styles.color.white,
		},
		hoverColors: {
			primary: 'rgba(57,90,251,0.90)',
			secondary: styles.color.white,
		},


		text: {
			fontSize: 20,
		},

		style: {
			minWidth: 370,
			height: 65,
		}
	}
};
