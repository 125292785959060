import React from 'react'
import {
	CardStyleInterpolators,
	createStackNavigator as createOriginalStackNavigator,
	TransitionSpecs
} from '@react-navigation/stack'
import use from "../hook";
import Environment from "../library-js/Environment";

/**
 * Apply default options (no app bar, card flex)
 */
export default function createStackNavigator(...params){
	const original = createOriginalStackNavigator(...params);

	function Navigator({screenOptions, ...props}){
		const cardStyle = use.defaultStyle(screenOptions?.cardStyle, DEFAULT_OPTIONS.cardStyle);
		props.screenOptions = {...DEFAULT_OPTIONS, ...(screenOptions || {}), cardStyle};

		return <original.Navigator {...props}/>;
	}


	return {...original, Navigator};
}

const DEFAULT_OPTIONS = {
	headerShown: false,
	cardStyle: {flex: 1},
};

if (Environment.is.mobile)
	Object.assign(DEFAULT_OPTIONS, {
		cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
		transitionSpec: {
			open: TransitionSpecs.TransitionIOSSpec,
			close: TransitionSpecs.TransitionIOSSpec,
		},
	});
