import { Order } from "../app/model/entity";
import Platform from "../Platform";
import VOrder from "../app/model/view/general/VOrder";
import OrderItem from "../app/model/entity/Order/Item";
import ReceptionMean from "../app/model/entity/Shop/ReceptionMean";

export default function getResourcesForOrderState(type) {

	if (type instanceof VOrder)
		type = type.currentState;

	if (type instanceof Order.State)
		type = type.type;


	if (!(type instanceof Order.State.Type))
		type = Order.State.Type.from(type);


	if (type instanceof Order.State.Type) {
		const display = type.select(DISPLAYS);
		return copyDisplay(display);
	}
}

getResourcesForOrderState.item = (state) => {
	if (state instanceof OrderItem)
		state = state.state;

	if (!(state instanceof OrderItem.State))
		state = OrderItem.State.from(state);

	if (state instanceof OrderItem.State) {
		const display = state.select(ITEM_DISPLAYS);
		return copyDisplay(display);
	}
}

function copyDisplay(display) {
	if (!display.shortTitle)
		display.shortTitle = display.title;

	if (!display.action)
		display.action = display.title;

	// using RESOURCES rather than mapping the object allows to never have a undefined called as function
	return RESOURCES.reduce((copy, resName) => {
		let res = display[resName];

		// force each resource to be a function
		if (!(res instanceof Function)) {
			const staticRes = res;
			res = () => staticRes;
		}

		copy[resName] = res;
		return copy;
	}, {});
}

const RESOURCES = ['icon', 'color', 'shortTitle', 'title', 'description', 'action'];

export const ICONS = {
	right: 'orderValidated',
	wrong: 'orderCanceled',
	pending: 'orderPending',
};

export const COLORS = {
	right: '#27ae60',
	wrong: '#f42d55',
	pending: '#ff9901',
	neutral: '#707070',
};

export const DISPLAYS = {
	processingPrepayment: {
		icon: ICONS.pending,
		color: COLORS.pending,
		title: `En vérification`,
		description: `Le paiement est en cours de vérification par le système.`,
	},

	prepaymentFailed: {
		icon: ICONS.wrong,
		color: COLORS.wrong,
		shortTitle: 'Échoué',
		title: () => Platform.select({
			user: 'Paiement échoué',
			default: `Prépaiement échoué`,
		}),
		description: () => Platform.select({
			user: `Une erreur s’est produite lors de votre paiement.`,
			default: `Le prépaiement a échoué.`,
		}),
	},

	preparing: {
		icon: ICONS.pending,
		color: COLORS.pending,
		shortTitle: `Préparation`,
		title: () => Platform.select({
			default: `En préparation`,
			retailer: `À préparer`,
		}),
		description: () => Platform.select({
			default: `La commande est en préparation par le commerçant.`,
			retailer: `La commande est à préparer. Confirmez une fois la commande prête à être délivrer.`,
		}),
	},

	ready: {
		icon: ICONS.right,
		color: COLORS.right,
		shortTitle: `Prête`,
		title: (receptionMean) => ReceptionMean.select(receptionMean, {
			delivery: `En livraison`,
			collect: `Prête à être récuperée`,
			default: `Prête à délivrer`,
		}),
		description: (receptionMean) =>
			`La commande est ` +
			ReceptionMean.select(receptionMean, {
				delivery: `en livraison`,
				collect: `prête à être récuperée`,
				default: `prête à délivrer`,
			}),
	},

	processingPayment: {
		icon: ICONS.pending,
		color: COLORS.pending,
		action: `Encaisser`,
		shortTitle: `Encaissement`,
		title: `Encaissement...`,
		description: `Le paiement sur la commande est en cours d'encaissement.`,
	},

	paymentFailed: {
		icon: ICONS.wrong,
		color: COLORS.wrong,
		shortTitle: `Échoué`,
		title: `Encaissement échoué`,
		description: () => Platform.select({
			default: `L'encaissement du paiement a échoué. Prenez contact avec le commerçant pour régler la commande.`,
			retailer: `L'encaissement du paiement en ligne a échoué. Prenez contact avec le client pour régler la commande, et ensuite entrez le moyen de paiement hors ligne utilisé.`,
		}),
	},

	reception: {
		icon: ICONS.right,
		color: () => COLORS.right,
		shortTitle: `Réception`,
		title: `En réception`,
		description: () => Platform.select({
			user: `La commande a été encaissée et doit être confirmé par vous d'avoir été reçu.`,
			default: `La commande a été encaissée et est en attente de confirmation de réception par le client.`,
		}),
	},

	finished: {
		icon: ICONS.right,
		color: COLORS.neutral,
		title: `Terminée`,
		description: `La commande a été encaissé et confirmé comme receptionné par le client.`,
	},

	canceled: {
		icon: ICONS.wrong,
		color: COLORS.neutral,
		title: `Annulé`,
		action: `Annuler la commande`,
		description: `La commande a été annulé.`,
	},
};

export const ITEM_DISPLAYS = {
	canceled: {
		icon: ICONS.wrong,
		color: COLORS.wrong,
		title: `Annulé`,
	},
	pending: {
		icon: ICONS.pending,
		color: COLORS.pending,
		title: `En préparation`,
	},
	ready: {
		icon: ICONS.right,
		color: COLORS.right,
		title: `Confirmé`,
	},
}
