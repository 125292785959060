import Image from "./Image"
import Audio from "./Audio"
import Video from "./Video"
import Debug from "../../../Debug"
import is from "../../../utils/is"
import Objects from "../../../utils/Objects"

let Medias;
export default Medias = {
	Image,
	Video,
	Audio,

	/**
	 * Finds the format of the given extension or mime-type.
	 * @param {String} mimeTypeOrExtension The mime-type or the extension (with or without dot at the start).
	 * @return {{extension, description, mimeType, type}} The format object corresponding to the parameter. Undefined if no format matches.
	 */
	getFormatOf : function (mimeTypeOrExtension) {
		Debug.assert(!is(mimeTypeOrExtension, String), "Medias.getFormatOf function's parameter must be a String.");

		if (mimeTypeOrExtension){
			let format;

			if (mimeTypeOrExtension.split('/').length === 2){ // mime type
				let mimeType = mimeTypeOrExtension;

				format = Objects.find(Medias.Formats, (key, format) => format.mimeType === mimeType);
			}
			else { // extention
				let extension = mimeTypeOrExtension;

				// if no dot at the start, correct it
				if (extension[0] !== '.')
					extension =  '.' + extension;

				format = Objects.find(Medias.Formats, (key, format) => format.extension === extension);
			}

			return format;
		}
	},

	Formats : {
		// ------- Audio ------- //

		AAC : {
			extension : ".aac",
			description : "Audio file",
			mimeType : "audio/aac",
			type : Audio,
		},

		OGA : {
			extension : ".oga",
			description : "OGG audio",
			mimeType : "audio/ogg",
			type : Audio,
		},

		WAV : {
			extension : ".wav",
			description : "Waveform Audio Format",
			mimeType : "audio/x-wav",
			type : Audio,
		},

		WEBA : {
			extension : ".weba",
			description : "WEBM audio",
			mimeType : "audio/webm",
			type : Audio,
		},

		THREE_GPP_AUDIO : {
			extension : ".3gp",
			description : "3GPP audio container",
			mimeType : "audio/3gpp",
			type : Audio,
		},

		THREE_GPP2_AUDIO : {
			extension : ".3g2",
			description : "3GPP2 audio container",
			mimeType : "audio/3gpp2",
			type : Audio,
		},



		// ------- Video ------- //

		MKV : {
			extension : ".mkv",
			description : "Video MKV",
			mimeType : "video/x-matroska",
			type : Video,
		},

		MP4 : {
			extension : ".mp4",
			description : "Video MP4",
			mimeType : "video/mp4",
			type : Video,
		},

		AVI : {
			extension : ".avi",
			description : "Audio Video Interleave",
			mimeType : "video/x-msvideo",
			type : Video,
		},

		MPEG : {
			extension : ".mpeg",
			description : "MPEG Video",
			mimeType : "video/mpeg",
			type : Video,
		},

		OGV : {
			extension : ".ogv",
			description : "OGG video",
			mimeType : "video/ogg",
			type : Video,
		},

		WEBM : {
			extension : ".webm",
			description : "WEBM video",
			mimeType : "video/webm",
			type : Video,
		},


		THREE_GPP_VIDEO : {
			extension : ".3gp",
			description : "3GPP video container",
			mimeType : "video/3gpp",
			type : Video,
		},

		THREE_GPP2_VIDEO : {
			extension : ".3g2",
			description : "3GPP2 video container",
			mimeType : "video/3gpp2",
			type : Video,
		},



		// ------- Image ------- //

		GIF : {
			extension : ".gif",
			description : "Graphics Interchange Format",
			mimeType : "image/gif",
			type : Image,
		},

		ICO : {
			extension : ".ico",
			description : "Icon format",
			mimeType : "image/x-icon",
			type : Image,
		},

		JPEG : {
			extension : ".jpeg",
			description : "JPEG images",
			mimeType : "image/jpeg",
			type : Image,
		},

		JPG : {
			extension : ".jpg",
			description : "JPEG images",
			mimeType : "image/jpeg",
			type : Image,
		},

		PNG : {
			extension : ".png",
			description : "Portable Network Graphics",
			mimeType : "image/png",
			type : Image,
		},

		SVG : {
			extension : ".svg",
			description : "Scalable Vector Graphics",
			mimeType : "image/svg+xml",
			type : Image,
		},

		TIF : {
			extension : ".tif",
			description : "Tagged Image File Format",
			mimeType : "image/tiff",
			type : Image,
		},

		TIFF : {
			extension : ".tiff",
			description : "Tagged Image File Format",
			mimeType : "image/tiff",
			type : Image,
		},

		WEBP : {
			extension : ".webp",
			description : "WEBP image",
			mimeType : "image/webp",
			type : Image,
		},
	}
};
