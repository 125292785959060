import React from "react";
import {
	BrandPin,
	BrandListPin,
	SectionPin,
	ShowcasePin
} from "library-js/app/ShopWebsiteConfiguration/Home/PINS";
import BrandPinView from "./BrandPinView";
import BrandListPinView from "./BrandListPinView";
import SectionPinView from "./SectionPinView";
import ShowcasePinView from "./ShowcasePinView";

export default function PinView({...props}) {

	if (props.item instanceof BrandPin)
		return <BrandPinView {...props}/>

	else if (props.item instanceof BrandListPin)
		return <BrandListPinView {...props}/>

	else if (props.item instanceof SectionPin)
		return <SectionPinView {...props}/>

	else if (props.item instanceof ShowcasePin)
		return <ShowcasePinView {...props}/>

	else
		return null;
}