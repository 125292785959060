import Base from "../../../../class/Base"
import Rate from "./Rate"
import Product from "../../entity/Product"

export default class VProduct extends Base {
	constructor() {
		super();
		this.product = new Product();
	}
	get id(){
		return this.product && this.product.id;
	}
}

VProduct.addProperties({
	product : Product,
	rate : Rate,
});
