import React from "react"
import use from "library-react/hook"
import Search from "library-js/Search/index";
import MobileDisplay from "./mobile"
import DesktopDisplay from "./desktop"
import CatalogCupboard from "library-js/app/model/CatalogCupboard";
import {VProdshopContext} from "../contexts";

export default function RelativeProducts({productSections, ...props}) {
	// relative products
	const {vShop} = use.context.infos();
	const {product} = use.context(VProdshopContext);
	const iterator =
		props.iterator =
			use.infiniteIterator(
				() => {
					if (product) {
						let section;
						if (productSections) {
							section = CatalogCupboard.retrieveBestMatchingSection(productSections, 2);

							return Search.index.prodshop.getIterator({
								shopId: vShop.id,
								inShop: true,
								available: true,
								sections: section && [section],
								exclude: [{"vProduct.product.id": product.id}],
							});
						}
					}
				},
				[product, productSections],
			);

	const display = use.context.display();

	props.title = `Voir aussi`;

	return (
		Boolean(
			iterator?.loading
			|| iterator?.items.length
		) &&
		(
			display.is.mobile ?
				<MobileDisplay {...props} /> :
				<DesktopDisplay {...props} />
		)
	);
}
