import React from "react"
import MessageLayout from "../MessageLayout";
import TextContentView from "../TextView";

export default React.memo(
	function TextMessageView({message, ...props}) {
		return (
			<MessageLayout message={message} {...props}>
				<TextContentView message={message}/>
			</MessageLayout>
		);
	}
);
