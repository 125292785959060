import { apply, equals, map, pipe, prop } from "ramda";
import React from "react";
import { StyleSheet } from "react-native";
import { Objects } from "./library-js/utils";
import override from "./library-js/utils/function/override";
import is from "./library-js/utils/is";
import Strings from "./library-js/utils/Strings";

let ComponentUtils;
export default ComponentUtils = {
	$$typeof: React.createElement(() => null).$$typeof,

	isElement(value, nullAccepted) {
		return (value === null && nullAccepted)
			|| (value && value.$$typeof === ComponentUtils.$$typeof && value.type);
	},

	is(component, Class, nullable) {
		if (!component)
			return Boolean(nullable) || component;

		if (!Class)
			return component.type;

		return component.type === Class || (is(Class, Function) && is(component.type, Class));
	},

	ref: {
		set(refProp, value) {
			if (is(refProp, Function)) {
				refProp(value);
				return () => refProp(); // undo
			} else if (refProp) {
				refProp.current = value;
				return () => (refProp.current = undefined);
			}
		},

		add(refProp1, refProp2) {
			return value => {
				this.set(refProp1, value);
				this.set(refProp2, value);
			};
		}
	},


	convertProps({ ...props }) {
		// ----- style --------
		props.style = ComponentUtils.styleToArray(props.style);

		// ----- children ------
		props.children = ComponentUtils.childrenToArray(props.children);

		return props;
	},

	isComponent(value) {
		return Boolean(value && value.$$typeof);
	},

	/**
	 * @deprecated You should RN Stylesheet only.
	 */
	defaultStyle(style, ...defaults) {
		return StyleSheet.compose(defaults, style);
	},

	/**
	 * @deprecated You should RN Stylesheet only.
	 */
	staticStateStyles(common, stateStyles) {
		return Objects.map(stateStyles, (state, style) => ({ ...common, ...style }));
	},

	/**
	 * @deprecated You should RN Stylesheet only.
	 */
	mergeProps(props, defaultProps, deleteKeys) {
		let propsArray = [defaultProps, props].filter(props => is(props, Object)); // accept object only
		let styles = propsArray.map(props => props.style); // retrieve styles
		let result = Object.assign({}, ...propsArray); // merge props
		result.style = styles.flatMap(); // flat styles

		if (deleteKeys)
			deleteKeys.forEach(key => {
				delete result[key];
			});

		return result;
	},

	/**
	 * @deprecated You should RN Stylesheet only.
	 */
	mergeStyles(...styles) {
		return this.style.merge(...styles);
	},

	/**
	 * @deprecated You should RN Stylesheet only.
	 */
	styleToArray(...styles) {
		return styles.flatMap().filter(Boolean);
	},

	childrenToArray(children) {
		if (ComponentUtils.is(children, React.Fragment))
			return ComponentUtils.childrenToArray(children.props.children);

		if (is(children, Array))
			return children.flatMap(ComponentUtils.childrenToArray);

		return [children];
	},

	regroupTextChildren(children) {
		let result = [];
		let group = [];

		children.forEach(child => {
			if (is(child, String))
				group.push(child);
			else {
				if (group.length) {
					result.push(group);
					group = [];
				}

				result.push(child);
			}
		});

		if (group.length)
			result.push(group);

		return result;
	},

	executeFunctionChildren(children, ...params) {
		return ComponentUtils.childrenToArray(children).map((child, index) => {
			if (is(child, Function)) {
				child = child(...params);

				if (ComponentUtils.isComponent(child) && !child.key)
					child = React.cloneElement(child, { key: `f${index}` });
			}

			return child;
		});
	},

	getBoundingBox(style) {
		return ComponentUtils.style.getBoundingBox(style);
	},

	props: {
		merge(propsArray, propsDefinitions) {
			return propsArray.reduce(
				(result, props) => {
					if (props)
						Objects.forEach(props, (key, value) => {
							if (key === 'style' || propsDefinitions?.style?.includes(key))
								result[key] = ComponentUtils.defaultStyle(value, result[key]);
							else
								result[key] = value;
						});

					return result;
				},
				{},
			);
		},

		equals(props1, props2, except) {
			let equals = true;
			const props2Copy = { ...props2 };

			const isException = (key) => Boolean(except?.includes(key));

			Object.keys(props1)
				.find(key => {
					const value1 = props1[key];
					const value2 = props2[key];
					delete props2Copy[key];
					return !(equals = isException(key) || (value1 === value2));
				});

			if (equals)
				Object.keys(props2Copy).find(key => {
					const value1 = props1[key];
					const value2 = props2[key];
					return !(equals = isException(key) || (value1 === value2));
				});

			return equals;
		},

		arrayLengthChanged(array1, array2) {
			let changed = (array1 !== array2);

			if (array2) { // save length
				const array = array2;

				if (!changed) {
					// compare with last saved length
					let lastLength = arrayLengthMemory.get(array);
					changed = (lastLength !== array.length);
				}

				// save length
				arrayLengthMemory.set(array, array.length);
			}

			return changed;
		},

		listPropShouldMatch(propName = 'items') {
			return pipe(map(prop(propName)), apply(equals));
		}
	},

	/**
	 * @deprecated You should RN Stylesheet only.
	 */
	style: {
		merge(...styles) {
			return StyleSheet.flatten(styles);
		},

		getBoundingBox(style) {
			style = ComponentUtils.style.merge(style);
			const result = {};

			["padding", "margin"]
				.forEach((parentProp) =>
					["Top", "Right", "Bottom", "Left"]
						.forEach(position => {
							const prop = parentProp + position;
							const related = getRelatedProps(prop);
							result[prop] = style[related.find(prop => is(style[prop]))] || 0; // get value
						})
				);

			// TODO borders

			return result;
		},

		concat(...styles) {
			return styles.flatMap();
		}
	}
};

function getRelatedProps(styleProp) {
	const propertyWithAxe = ["margin", "padding"];

	let [property, position] = Strings.splitCamel(styleProp);
	let result = [styleProp, property];

	if (propertyWithAxe.includes(property)) {
		let axe = {
			"Top": "Vertical",
			"Right": "Horizontal",
			"Bottom": "Vertical",
			"Left": "Horizontal"
		}[position];

		result.splice(1, 0, property + axe);
	}

	return result;
}

const arrayLengthMemory = new WeakMap();