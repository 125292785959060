import Base from "../class/Base";

export default class Async extends Base {
	constructor() {
		super();
		this.destroyed = false;
		this.delay = 0;
		this.shouldStop = this.shouldStop.bind(this);
	}

	shouldStop(){
		return this.destroyed;
	}

	destroy(){
		this.destroyed = true;
	}
}

Async.addProperties({
	destroyed: Boolean,
	delay: Number,
});
