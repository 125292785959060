import React from "react";
import use from "library-react/hook";
import {strings, styles} from "../../../res"
import adaptSize from "../../../adaptSize";
import {Linking} from "react-native-web";

export default function createContact(render) {
	const ContactView = function ({shop, ...props}) {
		const {Text} = use.theme();
		const contact = use.memo(() => getContact(shop, Text), [shop.address, shop.phone, Text]);
		const weekOpenHoursText = use.memo(() => getOpenHoursText(shop.weekOpenHours), [shop.weekOpenHours]);

		props.style = use.defaultStyle(props.style, localStyles.layout);
		return render(
			props,
			{
				name: shop.name,
				contact,
				weekOpenHoursText,
			}
		);
	};

	return ContactView;
}

function getContact(shop, Text) {
	return (
		<>
			<Text >
				{shop.address.formatted}{`\n`}
			</Text>

			<Text onPress={() => Linking.openURL(`tel:${shop.phone}`)}>
				Tel: {shop.phone}
			</Text>
		</>
	);
}

function getOpenHoursText(weekOpenHours) {
	let result = '';
	strings.week.forEach((dayString, day) => {
		result += dayString + ':';
		if (weekOpenHours[day].length) {
			const openHours = weekOpenHours[day];
			openHours.forEach(openHour => result += ' ' + openHour.formatted)
		} else
			result += ' Fermé';

		result += '\n';
	});
	return result;
}

const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.column,
	},
	address: {
		marginBottom: adaptSize(50),
	}
};
