import Entity from "./Entity"
import {Image} from "../media"

export default class Collection extends Entity {}
Collection.addProperties({
	title : String,
	subtitle : String,

	gallery : {
		type : Array,
		template : Image
	},

	description : String,

	creationDate : Number,
});
