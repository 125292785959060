import moment from "moment"
import toIsoDate from "../../../../utils/toIsoDate";
import { max } from "ramda"

export default function lastDayOf(closure) {
	if (closure)
		return toIsoDate(
			moment(closure.start)
				.add(max(1, closure.days - 1), 'days')
		);
}