import React from "react"
import {View as RNView} from "react-native";
import use from "../../../../../../../hook";
import Text from "../../../../../../../component/Text";
import {fonts, styles} from "../../../../../../../res";
import TimeView from "../TimeView";

export default React.memo(
	function TextContentView({message, ...props}) {
		props.style = use.defaultStyle(props.style, localStyles.layout, [message.attachment, 1]);
		return (
			<RNView {...props}>
				<Text style={localStyles.text(message.isAuthor)}>
					{message.text}
				</Text>

				<TimeView
					time={message.time}
					isAuthor={message.isAuthor}
					style={localStyles.time}
				/>
			</RNView>
		);
	}
)

const localStyles = {
	layout: styles.static.bool(
		{
			flexShrink: 1,
		},
		{
			flexDirection: styles.flexDirection.column,
		},
		{
			flexDirection: styles.flexDirection.row, // for 1 line text
			alignItems: styles.alignItems.baseline, // for 1 line text
			justifyContent: styles.justifyContent.flexEnd, // for >1 lines: set time right
			flexWrap: styles.flexWrap.wrap, // for >1 lines: set time below
		}
	),

	text: styles.static.bool(
		{
			alignSelf: styles.alignSelf.flexStart,
			font: fonts.Poppins,
			minHeight: 10,
			paddingTop: 5,
			marginBottom: 8,
		},
		{
			color: styles.color.white,
		},
		{
			color: '#1e2432',
		},
	),

	time: {
		alignSelf: styles.alignSelf.flexEnd,
		marginLeft: 10
	},
};
