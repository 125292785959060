
import { StyleSheet, View } from "react-native";
import Image from "../../../../library-react/component/Image";
import Portal from "../../../../library-react/component/Portal";
import Row from "../../../../library-react/component/Row";
import use from "../../../../library-react/hook";
import { formatPrice } from "../../../../library-react/library-js/app/model/utils/price";
import { screens } from "../../../links";
import { OrderItemViewProps } from "./types";

export default function OrderItemView_mobile({ item, ...props }: OrderItemViewProps) {
	const { prodshop, title, subtitle, quantity, price, currency } = item;
	const product = prodshop?.product;
	const imageUri = product?.galleryList?.[0]?.url;

	const { Text } = use.theme();

	return (
		<Portal to={product && screens.product} params={{id: product?.id}}>
			<Row
				columnsStyles={columnsStyles}
				{...props}>
				<View style={localStyles.main}>
					<Image
						source={{ uri: imageUri }}
						resizeMode="cover"
						style={localStyles.image}
					/>
					<View style={{flex: 1}}>
						<Text
							numberOfLines={2}
							style={localStyles.title}>
							{title}
						</Text>

						<Text
							numberOfLines={2}
							style={localStyles.subtitle}>
							{subtitle}
						</Text>
					</View>
				</View>

				<Text
					numberOfLines={1}
					style={localStyles.quantity}>
					{quantity}
				</Text>

				<Text
					numberOfLines={1}
					style={localStyles.price}>
					{formatPrice(price, currency)}
				</Text>
			</Row>
		</Portal>
	);
}

const columnsStyles = [
	{}, // title
	{ // quantity
		flex: undefined, // disable flex
		minWidth: 30,
		flexBasis: undefined,
		marginHorizontal: 4,
		alignSelf: "center",
	},
	{ // price
		flex: undefined, // disable flex
		minWidth: 80,
		flexBasis: undefined,
		alignSelf: "center",
	},
] as const;

const localStyles = StyleSheet.create({
	image: {
		width: 58,
		height: 80,
		marginRight: 15,
	},

	main: {
		flexDirection: "row",
	},

	title: {
		fontSize: 17,
		bold: true,
	},

	subtitle: {
		flex: 1,
		fontSize: 15,
	},

	quantity: {
		fontSize: 14,
		bold: true,
		textAlign: "center",
	},

	price: {
		fontSize: 14,
		bold: true,
		textAlign: "right",
		marginRight: 20,
	},
});
