import {paths} from "@main/links";
import pageTitle from "@main/utils/pageTitle";
import SEO from "library-react/component/SEO";
import Sharable from "library-react/component/sharable/Sharable";
import use from "library-react/hook";
import {createShopWebUrl} from "library-react/utils/showSocialModal";
import React from "react";
import {mergeStatus, optionalLoadableToStatus} from "../../../library-react/component/SEO";
import {useStaticShopCategoryLoadable} from "../../hook/useStaticShopCategory";
import {useStaticShopTagsNamesLoadable} from "../../hook/useStaticShopTags";
import toReadableTags from "../../utils/toReadableShopTags";
import PinHome from "./PinHome";
import WallHome from "./WallHome";
import buildSeoParams, {buildSeoParamsForShop} from "library-js/utils/buildSeoParams";

function HomeUI({...props}) {
	const {vShop: {shop}, config} = Object.assign(props, use.context.infos());

	const categoryLoadable = useStaticShopCategoryLoadable();
	const category = categoryLoadable.value;

	const shopTagsNamesLoadable = useStaticShopTagsNamesLoadable();
	const shopTagsNames = shopTagsNamesLoadable.value;

	const display = use.context.display();

	return (
		<>
			<SEO
				status={mergeStatus([
					optionalLoadableToStatus(categoryLoadable),
					optionalLoadableToStatus(shopTagsNamesLoadable),
				])}
				canonical={paths.home}
				optimizationParams={buildSeoParamsForShop({shop, shopTagsNames, category})}
				image={
					(!config.home?.mode?.is.pinList && config?.home?.banner)
					|| shop.mainPicture
				}
				title={
					pageTitle([
						`Accueil`,
						shop.name,
						(({locality, postalCode}) => `${locality} ${postalCode}`)(shop.address),
						toReadableTags(shopTagsNames),
						category?.name,
					])
				}
				description={
					shop.address.formatted + '. '
					+ shop.description
				}/>


			{
				config.home?.mode?.is.pinList ?
					<PinHome {...props} /> :
					<WallHome {...props} />
			}
		</>
	);
}

export default React.memo(HomeUI);

