/**
 * Get or check the type of a value.
 * To get the type use <code>is(value)</code>. For instance <code>is("abc") // String</code>.
 * To check a type use <code>is(value, type)</code>. For instance <code>is("abc", String) // true</code>.
 * To check a type, and accept undefined/null values, use <code>is(value, type, true)</code>. For instance <code>is(undefined, String, true) // true</code>.
 * You can check class instance like : <code>is(new MyClass(), MyClass) // true</code>.
 * Or class inheritance : <code>is(MyClass, ParentClass)</code>.
 * @param {*} value Value to get/check type.
 * @param {*?} types Type to check if value is.
 * @param {Boolean?} acceptNull If true, it will accept undefined or null value when checking type.
 * @returns {*} If no type precised, returns the type of the value. Otherwise, return if the value is of the given type.
 * @see is.primitive
 */
function is(value, types, acceptNull) {
	// no type set, return the type
	if (!types) {
		if (is.null(value))
			return value; // return null or undefined

		let type = TYPES_OF[typeof value];

		if (type === Object)
			type = value.constructor || Object;

		return type;
	}

	// get type
	let valueType = is(value);

	if (!valueType)
		return Boolean(acceptNull); // value not set

	if (types.constructor !== Array)
		types = [types];

	return types.some(type => {
		switch (valueType) {
			case Function:
				return value === type
					|| valueType === type
					|| (type.prototype && value.prototype instanceof type);

			default: // check if value has the same type
				return type === valueType
					|| (type.prototype && value instanceof type);
		}
	});
}

export default is;


export const isPrimitive = value => !(value instanceof Object);
is.primitive = isPrimitive;

export const isDefined = value => (value !== undefined) && (value !== null);
is.defined = isDefined;

export const isNull = value => !isDefined(value);
is.null = isNull;


const TYPES_OF = {
	"number": Number,
	"string": String,
	"boolean": Boolean,
	"object": Object,
	"function": Function,
};

is.primitives = [Boolean,  Number, String];


// -- Add symbol if exists
try {
	if (Symbol){
		TYPES_OF.symbol = Symbol;
		is.primitives.push(Symbol);
	}
} catch (e) {
}
