import { map, pipe, filter, join, trim, toLower, flatten, replace } from 'ramda'


function buildSeoParamsForProduct({ vProdshop, shopTagsNames }) {
	return (
		buildSeoParams({ keywords: [vProdshop?.product?.name, vProdshop?.product?.brand, vProdshop?.shop?.address.city, shopTagsNames?.[0]] })
	);
}

function buildSeoParamsForShop({ shop, shopTagsNames, category }) {
	return buildSeoParams({
		keywords: [
			category?.name,
			...(shopTagsNames || []),
			shop.address.city,
			shop.address.postalCode
		]
	});
}

function buildSeoParams({ keywords }) {
	const _keywords = pipe(
		flatten,
		filter(Boolean),
		map(pipe(
			String,
			trim,
		)),
		join('-'),
		replace(/\ /g, '-'),
		toLower,
	)(keywords);

	return { _keywords };
}


export default buildSeoParams;
export { buildSeoParamsForProduct, buildSeoParamsForShop };