import { createRequestWithDefaultDomain } from "../Request"
import HTTPRequest from "../../../network/HTTPRequest"
import { IBANBankAccount, LegalUser } from "../../model/pay/mango"
import { Brand, Retailer, Shop } from "../../model/entity"
import APIFunction from "../APIFunction";
import Public from "./public"
import Search from "../../../Search"
import Debug from "../../../Debug";
import Showcase from "../../model/entity/Showcase";

let API;
export default API = {
	Request: null,

	init() {
		API.Request = createRequestWithDefaultDomain('https://v9-dot-marketer-dot-rcm55-bagshop.appspot.com');
	},

	auth: {
		/**
		 * Authenticate via cookie or via email & password.
		 * @param email The cookie or email.
		 * @param password The password, only if the first parameter is an email.
		 * @returns {Promise} Containing a non-null {@link Server.Response}.
		 * @see https://docs.google.com/document/d/1-VtiMqTY-26dobTWieWc8qpDzFecohj2gyw9eP-m808/edit#bookmark=id.749tatyzvzzq
		 */
		authenticate(email, password) {
			return new API.Request("/connect", HTTPRequest.Methods.POST)
				.setParameters({ email, password })
				.send();
		}
	},

	shop: {
		/**
		 * @param shopId {Number} Shop's id.
		 * @return Promise.<Server.Response.<LegalUser>>
		 * @see https://docs.google.com/document/d/1-VtiMqTY-26dobTWieWc8qpDzFecohj2gyw9eP-m808/edit#bookmark=id.vncqwnb3rxhw
		 */
		getPaymentInfosOf(shopId) {
			return new API.Request("/shop/paymentInfos")
				.needsAuth()
				.setParameters({ id: shopId })
				.setResponseType(LegalUser)
				.send();
		},

		/**
		 * @param shop {Shop} Shop to create.
		 * @param legalUser {LegalUser} Payment infos.
		 * @return Promise.<Server.Response.<Number>> Shop's id.
		 * @see https://docs.google.com/document/d/1-VtiMqTY-26dobTWieWc8qpDzFecohj2gyw9eP-m808/edit#bookmark=id.gy1l5q6wl3om
		 */
		create(shop, legalUser) {
			return new API.Request("/shop/", HTTPRequest.Methods.PUT)
				.needsAuth()
				.setParameters({ shop, legalUser })
				.send()
				.then(response => {
					if (response.ok)
						shop.id = response.content;
					return response;
				});
		},

		/**
		 * @param id {Number} Shop's id to edit.
		 * @param editions {Shop} Only filled shop's field will be overwritten.
		 * @return Promise.<Server.Response>
		 * @see https://docs.google.com/document/d/1-VtiMqTY-26dobTWieWc8qpDzFecohj2gyw9eP-m808/edit#bookmark=id.gf1719u9c23c
		 */
		edit(id, editions) {
			return new API.Request("/shop/", HTTPRequest.Methods.POST)
				.needsAuth()
				.setParameters({ id, shop: editions })
				.send();
		},

		/**
		 * https://docs.google.com/document/d/1-VtiMqTY-26dobTWieWc8qpDzFecohj2gyw9eP-m808/edit#bookmark=id.na235mvy7e
		 */
		delete(id) {
			return new API.Request("/shop/", HTTPRequest.Methods.DELETE)
				.needsAuth()
				.setParameters({ id })
				.send()
				.then(response => {
					if (response.ok)
						Search.index.shop.deleted[id] = true;
					return response;
				});
		},

		/**
		 * Overwrite shop's infos and payment.
		 * @return Promise.<Server.Response>
		 */
		async persist(shop, type, legalUser) {
			let response;

			if (!shop.domain) // prevent to send a empty string
				shop.domain = null;

			if (shop.id) { // edit
				let editions = shop.clone();
				delete editions.id;

				// persist shop
				response = await API.shop.edit(shop.id, editions);
				if (legalUser && response.ok)
					response = await API.shop.persistPaymentInfos(shop.id, legalUser);
			} else // otherwise create
				response = await API.shop.create(shop, legalUser);

			if (shop.domain && type && response.ok) {
				response = await Public.shop.getConfigOf(shop.id);
				if (response.ok) {
					let config = response.content || ShopWebsiteConfiguration.DEFAULT;
					config.type = type;
					response = await API.shop.persistWebConfiguration(shop.id, shop.domain, config);
				}
			}

			return response;
		},

		/**
		 * @return Promise.<Server.Response.<String>> Token to allow be authenticated as a retailer.
		 * @see https://docs.google.com/document/d/1-VtiMqTY-26dobTWieWc8qpDzFecohj2gyw9eP-m808/edit#bookmark=id.ge8sf5bd8gtk
		 */
		control(id) {
			return new API.Request("/shop/control")
				.needsAuth()
				.setParameters({ id })
				.send();
		},

		/**
		 * @param shopId {Number} Shop's id owner of the payment infos to edit.
		 * @param legalUser {LegalUser} The previous payment infos will be entirely overwritten.
		 * @return Promise.<Server.Response>
		 * @see https://docs.google.com/document/d/1-VtiMqTY-26dobTWieWc8qpDzFecohj2gyw9eP-m808/edit#bookmark=id.eqy33fqubmed
		 */
		persistPaymentInfos(shopId, legalUser) {
			return new API.Request("/shop/paymentInfos", HTTPRequest.Methods.POST)
				.needsAuth()
				.setParameters({ id: shopId, legalUser })
				.send();
		},


		/**
		 * @see https://docs.google.com/document/d/1-VtiMqTY-26dobTWieWc8qpDzFecohj2gyw9eP-m808/edit#bookmark=id.m1e6jvjet5iw
		 */
		addBankAccount(id, bankAccount) {
			return new API.Request("/shop/bankAccount", HTTPRequest.Methods.PUT)
				.needsAuth()
				.setParameters({ id, bankAccount })
				.send();
		},

		/**
		 * @return Promise.<Server.Response.<Array.<BankAccount>>>
		 * @see https://docs.google.com/document/d/1-VtiMqTY-26dobTWieWc8qpDzFecohj2gyw9eP-m808/edit#bookmark=id.9l6tu31f86ad
		 */
		listBankAccounts(shopId) {
			return new API.Request("/shop/bankAccount")
				.needsAuth()
				.setParameters({ id: shopId })
				.setResponseType({
					type: Array,
					template: IBANBankAccount,
				})
				.send();
		},

		persistWebConfiguration(shopId, domain, config) {
			return new API.Request("/shop/configuration", HTTPRequest.Methods.POST)
				.needsAuth()
				.setParameters({ shopId, domain, config })
				.send();
		},

		isDomainAvailable(domain) {
			return new API.Request("/shop/domain/available")
				.needsAuth()
				.setParameters({ domain })
				.send();
		},

		/**
		 * https://docs.google.com/document/d/1-VtiMqTY-26dobTWieWc8qpDzFecohj2gyw9eP-m808/edit#bookmark=kix.ag8jol9vzu9i
		 */
		setActivated(shopId, activated) {
			return new API.Request("/shop/activated", HTTPRequest.Methods.POST)
				.needsAuth()
				.setParameters({ shopId, activated })
				.send();
		},
		setVerified(shopId) {
			return new API.Request("/shop/verified")
				.needsAuth()
				.setParameters({ shopId })
				.send();
		},
		getListOfNotVerified: new APIFunction({
			request: (from, emailVerified = false) => {
				if (emailVerified !== true)
					emailVerified = false;
				return new API.Request('/shop/list/not-verified')
					.needsAuth()
					.setParameters({ from, emailVerified })
					.setResponseType({
						type: Array,
						template: Shop,
					});
			},
			iterator: {
				load: ({ params: [emailVerified], cursor, api }) => api(cursor, emailVerified),
				getCursor: shop => shop.creationDate,
				minimum: 10,
			},
		}),
		sendMail(shopId, template) {
			return new API.Request("/shop/mail", HTTPRequest.Methods.POST)
				.needsAuth()
				.setParameters({ shopId, template })
				.send();
		},
	},

	retailer: {
		/**
		 * @return Promise.<Server.Response.<Array.<Retailer>>>
		 * @see https://docs.google.com/document/d/1-VtiMqTY-26dobTWieWc8qpDzFecohj2gyw9eP-m808/edit#bookmark=id.l5531fvw17q
		 */
		getByShop(shopId) {
			return new API.Request("/retailer/shop")
				.needsAuth()
				.setParameters({ shopId })
				.setResponseType({
					type: Array,
					template: Retailer
				})
				.send();
		},

		/**
		 * @see https://docs.google.com/document/d/1-VtiMqTY-26dobTWieWc8qpDzFecohj2gyw9eP-m808/edit#bookmark=id.pvxcd0w3b1rg
		 */
		persist(shopId, id, retailer, password) {
			if (retailer && retailer.email)
				retailer.email = retailer.email.toLowerCase();

			return new API.Request("/retailer/", HTTPRequest.Methods.POST)
				.needsAuth()
				.setParameters({ shopId, id, retailer, password })
				.send();
		}
	},

	brand: {
		/**
		 * https://docs.google.com/document/d/1-VtiMqTY-26dobTWieWc8qpDzFecohj2gyw9eP-m808/edit#bookmark=kix.jp9z5ajbcimh
		 */
		persist: new APIFunction({
			request: (id, brand) => (
				new API.Request("/brand", HTTPRequest.Methods.POST)
					.needsAuth()
					.setParameters({ id, brand })
					.setResponseType(Brand)
			)
		}),

		/**
		 * https://docs.google.com/document/d/1-VtiMqTY-26dobTWieWc8qpDzFecohj2gyw9eP-m808/edit#bookmark=kix.ai47afoirxwx
		 */
		fusion: new APIFunction({
			request: (id, name) => (
				new API.Request("/brand/fusion", HTTPRequest.Methods.POST)
					.needsAuth()
					.setParameters({ id, name })
			)
		})
	},

	showcase: {
		/**
		 * https://docs.google.com/document/d/1-VtiMqTY-26dobTWieWc8qpDzFecohj2gyw9eP-m808/edit#bookmark=kix.qm4lj3rbsvt
		 */
		persist: new APIFunction({
			request: (id, showcase) =>
				new API.Request('/showcase', HTTPRequest.Methods.POST)
					.needsAuth()
					.setParameters({ id, showcase })
					.setResponseType(Showcase),
		})
	}
};

// init at least once with default behavior
API.init();
