import {Enum} from "../../../../class"
import Entity from "../Entity"

export default class PaymentConfigurationStatus extends Entity {
	get ok() {
		return Boolean(this.configStatus?.is?.VALID)
			&& !this.invalidFields.length;
	}

	get invalidFields() {
		return ["IDENTITY_PROOF", "REGISTRATION_PROOF", "ARTICLES_OF_ASSOCIATION", "UBO_DECLARATION"]
				.filter(field => !this[field]?.is.VALIDATED)
				.concat(!this.bankAccountId ? ['bankAccountId'] : []);
	}
}

export const PaymentConfigurationDocumentStatus =
	PaymentConfigurationStatus.DocumentStatus =
		Enum.make([
			'CREATED', 'ASKED', 'VALIDATED', 'INCOMPLET', 'REFUSED'
		]);

export const PaymentConfigurationShopStatus =
	PaymentConfigurationStatus.ShopStatus =
		Enum.make(['ASKED', 'VALID', 'INCOMPLETE']);


PaymentConfigurationStatus.addProperties({
	bankAccountId: String,
	userId: String,
	shopId: String,
	IDENTITY_PROOF: PaymentConfigurationDocumentStatus,
	REGISTRATION_PROOF: PaymentConfigurationDocumentStatus,
	ARTICLES_OF_ASSOCIATION: PaymentConfigurationDocumentStatus,
	UBO_DECLARATION: PaymentConfigurationDocumentStatus,
	configStatus: PaymentConfigurationShopStatus,
});
