import {Base} from "../../../../../class";
import {is} from "../../../../../utils";
import Days from "./Days";
import paragraph from "../../../../../utils/function/paragraph";

export default class OpenHour extends Base {
	constructor(day, open, close) {
		super();
		this.day = day;
		this.open = open;
		this.close = close;
	}

	get valid() {
		return this.day
			&& this.open >= 0
			&& this.open <= 1000 * 60 * 60 * 24
			&& this.close >= 0
			&& this.close <= 1000 * 60 * 60 * 24
			&& this.close > this.open;
	}

	get openHour() {
		if (is(this.open, Number))
			return Math.floor(this.open / 60);
		else
			return "";
	}

	get openMinute() {
		if (is(this.open, Number))
			return Math.floor(this.open % 60);
		else
			return "";
	}

	get closeHour() {
		if (is(this.close, Number))
			return Math.floor(this.close / 60);
		else
			return "";
	}

	get closeMinute() {
		if (is(this.close, Number))
			return Math.floor(this.close % 60);
		else
			return "";
	}

	get openTime(){
		return paragraph`${(this.openHour < 10 ? '0' : '') + this.openHour}:${(this.openMinute < 10 ? '0' : '') + this.openMinute}`;
	}
	get closeTime(){
		return paragraph`${(this.closeHour < 10 ? '0' : '') + this.closeHour}:${(this.closeMinute < 10 ? '0' : '') + this.closeMinute}`;
	}

	get formatted() {
		return `${this.openTime} - ${this.closeTime}`;
	}
};

OpenHour.Days = Days;

OpenHour.addProperties({
	day: Days,
	open: Number,
	close: Number,
});
