import React from 'react'
import vectoIcons from "./vectorIcons"
import Debug from "../../library-js/Debug"
import View from "../View"
import ComponentUtils from "../../ComponentUtils"
import Colorable from "../../plugin/Colorable";
import {styles} from "../../res"
import use from "../../hook";
import {Objects} from "../../library-js/utils";
import * as SVG from "react-native-svg";
import icons, {add} from "./icons";


/**
 * @param String name Icon to display: [group]/[icon]
 * @see https://oblador.github.io/react-native-vector-icons/
 * @param props
 * @constructor
 */
let Icon1;
export default Icon1 = React.memo(({name, size, ...props}) => {
	// convert style to object in order to extract icon styles: width, height & color
	props.style = use.memo(
		style =>
			ComponentUtils.style.merge(
				...ComponentUtils.defaultStyle(style, styles.center)
			),
		[props.style]
	);

	// get icon styles
	const {color, height, width} = use.memo((style) => {
		const {color} = Objects.retrieve(style, ["color"], true);
		let boundingBox = ComponentUtils.getBoundingBox(style);
		return {
			height: (style.height || 14) - (boundingBox.paddingTop + boundingBox.paddingBottom),
			width: (style.width || 14) - (boundingBox.paddingRight + boundingBox.paddingLeft),
			color,
		};
	}, [props.style]);


	let icon; // result

	// vector icons
	if (name) {
		if (name.includes("/")) {
			let [namespace, iconName] = name.split("/");
			let VectorIcon = vectoIcons[namespace];
			Debug.assert(!VectorIcon, "Non existing icon set : " + namespace, true);

			if (VectorIcon)
				icon = (
					<VectorIcon
						name={iconName}
						color={color}
						size={Math.min(height, width)}/>
				);
		} else { // app icons
			let renderIcon = icons[name];
			let colors = [color].flat(); // force colors as array

			let element;
			if (renderIcon)
				try {
					element = renderIcon(...colors);
				} catch (error) {
					console.warn("Error displaying icon: ", error);
				}
			else
				try {
					element = evalIcon(name)(colors);
				} catch (error) {
					console.warn(`icon ${name} not defined & cannot be evaluated.\n`, error);
				}

			if (element) // set size to Svg
				icon = React.cloneElement(element, {width, height});
		}
	}

	//Workaround while issue:https://github.com/react-native-community/react-native-svg/issues/794 not resolved
	if (!props.onPress)
		props.pointerEvents = "none";

	return (
		<View {...props}>
			{icon}
		</View>
	);
});

// --- auto styling ----
Icon1.colorable = Colorable.fromStyle;

Icon1.add = add;

function evalIcon(codeIcon) {
	return eval(`({
	  Svg, Rect, Circle, Ellipse,
	  Line, Polygon, Polyline,
	  Path, Text, TSpan,
	  TextPath, G, Use,
	  Symbol, Defs, Image,
	  ClipPath, LinearGradient, RadialGradient,
	  Mask, Pattern,
	}, React) => ${codeIcon}`)(SVG, React);
}
