const style = document.createElement('style');
document.head.append(style);

export const NO_SCROLLBAR_TAG = 'no-scrollbar';
style.appendChild(
	document.createTextNode(`*[data-${NO_SCROLLBAR_TAG}]::-webkit-scrollbar {width: 0}`)
);

export const BUTTON_ANIMATED_ON_HOVER_TAG = 'button-animated-on-hover';
style.appendChild(
	document.createTextNode(`
		*[data-${BUTTON_ANIMATED_ON_HOVER_TAG}] {
			padding-left: 15px;
			padding-right: 15px;
			transition: padding-right .5s ease, padding-left .5s ease;
		}
		
		*[data-${BUTTON_ANIMATED_ON_HOVER_TAG}]:hover {
			padding-left: 0;
			padding-right: 0;
		}
	`)
);
