import { reduce, groupBy, prop, pipe } from "ramda"
import AuthManager from "../../../AuthManager"
import Debug from "../../../Debug"
import Environment from "../../../Environment"
import HTTPRequest from "../../../network/HTTPRequest"
import { is } from "../../../utils"
import Objects from "../../../utils/Objects"
import CatalogCupboard from "../../model/CatalogCupboard"
import { Brand, Shop } from "../../model/entity"
import CatalogSection from "../../model/entity/CatalogSection"
import ReservationClosure from "../../model/entity/ReservationClosure"
import ReservationSpan from "../../model/entity/ReservationSpan"
import { Medias } from "../../model/media"
import { Rate, VCollection, VComment, VProdshop, VProduct, VSale, VShop, VShopCollection } from "../../model/view"
import VBrand from "../../model/view/general/VBrand"
import VShowcase from "../../model/view/general/VShowcase"
import ShopWebsiteConfiguration from "../../ShopWebsiteConfiguration"
import APIFunction from "../APIFunction"
import cache, { ListCacheAccessor, SplitCacheAccessor, updateMultipleLists } from "../cache"
import Iterator from "../Iterator"
import { AUTH_TOKEN_KEY, createRequestWithDefaultDomain } from "../Request"
import Response from "../Response"
import cacheUpdateCenter from "./cacheUpdateCenter"



const API = {
	Request: null,

	init() {
		this.domain = "https://v9-dot-public-dot-rcm55-bagshop.appspot.com";
		this.Request = createRequestWithDefaultDomain(this.domain);
	},

	product: {
		/**
		 * @param ids {Number} Products' ids.
		 * @return {Promise.<Server.Response.<VProduct>>}
		 * @see https://docs.google.com/document/d/1BfXQ622eF5nWZQKhwuBJKuaTlY4ivBFOxUoVI6DlFg0/edit#bookmark=id.mwbl197yqc1h
		 */
		get: new APIFunction({
			constructor() {
				cacheUpdateCenter.VProduct.persist.listen(this, vProducts => {
					const params = vProducts.map(({ id }) => id);
					this.cache.persist(params, vProducts, cache.TIME.MAX);
				});
			},

			request: (...ids) => (
				new API.Request("/product/list/ids")
					.setParameters({ ids })
					.setResponseType({ type: Array, template: VProduct })
			),

			cache: {
				time: cache.TIME.MAX,
				accessor: new SplitCacheAccessor,
			},

			// convert array to map
			onSuccess: response => {
				response.content = response.content.toObject(({ id }) => id);
			},

			updateOtherAPIFunctions(content) {
				let vProducts = Objects.valuesOf(content);
				cacheUpdateCenter.VProduct.persist.fire(this, ...vProducts);
			}
		}),

		getAllCategories: new APIFunction({
			request: () => new API.Request("/product/categories/all")
		}),

		rate: new APIFunction({
			request: (id) =>
				new API.Request('/product/rate')
					.setParameters({ id })
					.setResponseType(Rate)
		},
		)
	},

	shop: {
		/**
		 * @param {Number} ids Shops' ids to load.
		 * @return {Promise.<Server.Response.<Object<VShop>>>>}
		 * @see https://docs.google.com/document/d/1BfXQ622eF5nWZQKhwuBJKuaTlY4ivBFOxUoVI6DlFg0/edit#bookmark=id.n0htyl9j1fye
		 */
		get: new APIFunction({
			constructor() {
				cacheUpdateCenter.VShop.persist.listen(this, vShops => {
					let params = vShops.map(({ id }) => id);
					this.cache.persist(params, vShops, cache.TIME.MAX);
				});
			},

			request: (...ids) => (
				new API.Request("/shop/list/ids")
					.setParameters({ ids })
					.setResponseType({ type: Array, template: VShop })
			),

			cache: {
				time: cache.TIME.MAX,
				accessor: new SplitCacheAccessor,
			},

			// map by id
			onSuccess(response, request) {
				response.content = response.content.toObject(({ id }) => id);
				// set ids with no shop
				response.content = request.parameters.ids.toObject(
					id => id,
					id => response.content[id]
				);
			},

			updateOtherAPIFunctions(content) {
				let vShops = Objects.valuesOf(content);
				cacheUpdateCenter.VShop.persist.fire(this, ...vShops);
			}
		}),

		/**
		 * @param {Number} id Shop's id.
		 * @param {Number?} from Cursor, last {@link VProdshop.prodshop.creationDate} from the last list.
		 * @return {Promise.<Server.Response.<Array.<VProdshop>>>} Infinite list of {@link VProdshop}.
		 * @see https://docs.google.com/document/d/1BfXQ622eF5nWZQKhwuBJKuaTlY4ivBFOxUoVI6DlFg0/edit#bookmark=id.erpxbhekzwzj
		 */
		getCatalogOf: new APIFunction({
			constructor() {
				cacheUpdateCenter.VProdshop.persist.listen(this, vProdshops => {
					// convert into a 2D map
					let map = vProdshops.groupBy(({ shop }) => shop.id);
					map = Objects.map(map, (shopId, vProdshops) => vProdshops.toObject(({ product }) => product.id));

					updateMultipleLists(
						this.config.request(),
						({ shop, product }) => map[shop.id][product.id],
						({ shop, product }) => map[shop.id][product.id],
					);
				})
			},

			request(id, from) {
				let request = new API.Request("/shop/catalog");
				request.parameters = { id, from };
				request.responseType = { type: Array, template: VProdshop };
				return request;
			},

			cache: { accessor: new ListCacheAccessor },

			updateOtherAPIFunctions(list) {
				cacheUpdateCenter.VProdshop.persist.fire(this, ...list);
			},
		}),

		getCatalogIterator(shopId) {
			return new Iterator()
				.hydrate({
					function: cursor => API.shop.getCatalogOf(shopId, cursor),
					getCursor: response => response.content.last.prodshop.creationDate,
					// minimum : 10
				});
		},

		getConfigOf: new APIFunction({
			constructor() {
				cacheUpdateCenter.ShopWebsiteConfiguration.persist.listen(this,
					([configuration]) => this.cache.persist(null, configuration)
				);
			},

			request(shopId) {
				let request = new API.Request("/shop/config");
				request.parameters = { id: shopId };
				return request;
			},

			cache: true,

			onSuccess(response) {
				if (response.content) {
					let configuration;
					try {
						configuration = JSON.parse(response.content);
					} catch (error) {
						return Response.from({
							code: Response.Status.BUG,
							message: "Error parsing JSON configuration. JSON string in content.",
							content: response.content,
						});
					}

					response.content = ShopWebsiteConfiguration.from(configuration);
				}
			},

			updateOtherAPIFunctions(configuration) {
				cacheUpdateCenter.ShopWebsiteConfiguration.persist.fire(this, configuration);
			}
		}),

		getCategories: new APIFunction({
			request() {
				return new API.Request("/shop/categories")
					.setResponseType({
						type: Array,
						template: Shop.Category,
					});
			},

			onSuccess(response) {
				if (is(response.content, Array) && this.addIcon)
					response.content.forEach(category => {
						try {
							category.icon = this.addIcon(category);
						} catch (error) {
							console.warn(`Error adding icon of shop category ${category.name}: `, error);
						}
					});
			},

			iterator: {
				getCursor: (category) => category.id,
				minimum: 20,
			},

			cache: true,
		}),

		rate: new APIFunction({
			request: (id) =>
				new API.Request('/shop/rate')
					.setParameters({ id })
					.setResponseType(Rate)
		},
		),

		getSections: new APIFunction({
			request: (shopId) => (
				new API.Request("shop/section")
					.setParameters({ shopId })
					.setResponseType({
						type: Array,
						template: CatalogSection
					})
			),

			onSuccess(response) {
				response.content = new CatalogCupboard(response.content);
			},
		}),

		loadCategories: new APIFunction({
			request() {
				return new API.Request("/shop/categories/all")
					.setResponseType({
						type: Array,
						template: Shop.Category,
					});
			},
		}),
	},

	prodshop: {
		PATH: "/prodshop",

		/**
		 * @param {Object.<Array.<Number>>} mapShopIdToProductIds Arrays of products' id mapped by shop ids.
		 * @returns {Promise.<Server.Response.<Object.<Object.<VProdshop>>>>} A server response containing a map of all requested catalog items mapped by their product ids which are themself mapped by their shop ids.
		 * Exemple:
		 * parameter : {
		 * 		shopId1 : [productId1, productId2, ..],
		 * 		shopId2 : [productId1, productId2, ..],
		 * }
		 *
		 * Returns : {
		 * 		shopId1 : {
		 * 			productId1 : VProdshop,
		 * 			productId2 : VProdshop,
		 *	 		...
		 * 		},
		 *
		 * 		shopId2 : {
		 * 			productId1 : VProdshop,
		 * 			productId2 : VProdshop,
		 *	 		...
		 * 		}
		 * }
		 */
		get: new APIFunction({
			constructor() {
				cacheUpdateCenter.VProdshop.persist.listen(this, vProdshops => {
					// convert into a 2D map
					let mapShopIdToProductIds = vProdshops.groupBy(({ shop }) => shop.id);
					mapShopIdToProductIds = Objects.map(mapShopIdToProductIds, (shopId, vProdshops) => vProdshops.map(({ product }) => product.id));

					this.cache.persist([mapShopIdToProductIds], vProdshops);
				});
			},

			request(mapShopIdToProductIds) {
				// convert parameters
				let ids = [];

				if (mapShopIdToProductIds)
					Objects.forEach(mapShopIdToProductIds, (shopIdTxt, productIds) => {
						let shopId = Number(shopIdTxt);
						if (shopId) {
							productIds.forEach(productIdTxt => {
								let productId = Number(productIdTxt);
								if (productId)
									ids.push({ shopId, productId });
								else
									console.warn("Server.public.prodshop.get : A productId parameter is not a number : ", productIdTxt);
							});
						} else
							console.warn("One of shopIds passed as parameter is not a number: ", shopIdTxt, mapShopIdToProductIds);
					});

				// build request
				let request = new API.Request(API.prodshop.PATH);
				request.parameters = { ids };
				request.responseType = {
					type: Array,
					template: VProdshop,
				};

				request.mapShopIdToProductIds = mapShopIdToProductIds; // cheat: pass this map to updateOtherAPIFunctions

				return request;
			},

			cache: { accessor: new SplitCacheAccessor },

			onSuccess(response, request) {
				if (response.content) {
					// convert into a 2D map
					response.content = Objects.map(
						response.content.groupBy(item => item.shop.id),
						(shopId, catalog) => catalog.toObject(item => item.product.id)
					);

					//Fill empty shops' prodshops map
					request.parameters.ids.forEach(prodshopId => {
						if (prodshopId && prodshopId.shopId && !response.content[prodshopId.shopId])
							response.content[prodshopId.shopId] = {};
					})
				}
			},

			updateOtherAPIFunctions(content) {
				let list = Objects.valuesOf(content);
				list = list.flatMap(map => Objects.valuesOf(map));
				cacheUpdateCenter.VProdshop.persist.fire(this, ...list);
			},
		}),

		/**
		 * Will return like {@link prodshop.get} but with vProdshops with only the vProduct if they are not in the catalog.
		 * ⚠️ Those forced vProdshop have no other properties (like the vShop) than the vProduct.
		 * ⚠️ The function makes 2 request when necessary. The 2nd request is {@link product.get} for products which are not in catalog. If the 2nd request fails, it is returned no matter if the first succeeded or not.
		 * @param {Object.<Array.<Number>>} mapShopIdToProductIds Arrays of products' id mapped by shop ids.
		 * @returns {Promise.<Server.Response.<Object.<Object.<VProdshop>>>>} A server response containing a map of all requested catalog items mapped by their product ids which are themself mapped by their shop ids.
		 */
		async forceGet(mapShopIdToProductIds) {
			let response = await API.prodshop.get(mapShopIdToProductIds);

			if (!response.ok)
				return response;

			let productIdsLeft = [];
			Objects.forEach(mapShopIdToProductIds, (shopId, productIds) =>
				productIds.forEach(productId => {
					if (!response.content[shopId][productId] && productIdsLeft.indexOf(productId) < 0)
						productIdsLeft.push(productId);
				})
			);

			if (productIdsLeft.length) {
				let leftProductsResponse = await API.product.get(...productIdsLeft);
				if (leftProductsResponse.ok)
					// fill the original response with vProdshops without prodshop
					Objects.forEach(mapShopIdToProductIds, (shopId, productIds) =>
						productIds.forEach(productId => {
							if (!response.content[shopId][productId]) {
								let vProduct = leftProductsResponse.content[productId];
								if (vProduct) {
									response.content[shopId][productId] = new VProdshop()
										.setVProduct(vProduct.clone());
								}
							}
						})
					);

				else // return the error response
					response = leftProductsResponse;
			}

			return response;
		},

		/**
		 * https://docs.google.com/document/d/1BfXQ622eF5nWZQKhwuBJKuaTlY4ivBFOxUoVI6DlFg0/edit#bookmark=id.1q7r0c3gc1rt
		 */
		getSectionsOf: new APIFunction({
			request: (shopId, productId) => (
				new API.Request("/prodshop/sections")
					.setParameters({ shopId, productId })
					.setResponseType({
						type: Array,
						template: CatalogSection
					})
			),
		}),
	},

	comment: {
		/**
		 * @param {Number} id Shop's id.
		 * @param {Number?} from Cursor, last {@link VComment.comment.creationDate} from the last list.
		 * @return {Promise.<Server.Response.<Array.<VComment>>>} Infinite list of {@link VComment}.
		 * @see https://docs.google.com/document/d/1BfXQ622eF5nWZQKhwuBJKuaTlY4ivBFOxUoVI6DlFg0/edit#bookmark=id.aaemd11vtyxe
		 */
		getListOnProduct: new APIFunction({
			request(id, stars, from, displayUser = true) {
				const request = new API.Request("/comment/product");

				request.parameters = { id, from };

				if (displayUser)
					request.parameters.userId = AuthManager.user?.uid;

				if (stars)
					request.parameters.stars = stars;

				request.responseType = { type: Array, template: VComment };
				return request;
			},

			iterator: {
				load: ({ params: [id, stars, displayUser], cursor, api }) => api(id, stars, cursor, displayUser),
				getCursor: vComment => vComment.comment.creationDate,
				minimum: 10,
			},

			cache: {
				time: cache.TIME.MAX,
				accessor: new ListCacheAccessor,
			}
		}),

		/**
		 * @param {Number} id Shop's id.
		 * @param {Number?} from Cursor, last {@link VComment.comment.creationDate}  from the last list.
		 * @return {Promise.<Server.Response.<Array.<VComment>>>} Infinite list of {@link VComment}.
		 * @see https://docs.google.com/document/d/1BfXQ622eF5nWZQKhwuBJKuaTlY4ivBFOxUoVI6DlFg0/edit#bookmark=id.aaemd11vtyxe
		 */
		getListOnShop: new APIFunction({
			request(id, stars, from, displayUser = true) {
				const request = new API.Request("/comment/shop");

				request.parameters = { id, from };

				if (displayUser)
					request.parameters.userId = AuthManager.user?.uid;

				if (stars)
					request.parameters.stars = stars;

				request.responseType = { type: Array, template: VComment };
				return request;
			},

			iterator: {
				load: ({ params: [id, stars, displayUser], cursor, api }) => api(id, stars, cursor, displayUser),
				getCursor: vComment => vComment.comment.creationDate,
				minimum: 10,
			},

			cache: {
				time: cache.TIME.MAX,
				accessor: new ListCacheAccessor,
			},
		}),
	},

	media: {
		uploadFile: Environment.select({
			async web(file) {
				try {
					if (!(file instanceof File))
						throw new Error("Parameter must be an instance of File");

					const type = file.type.split('/').first;
					const MediaClass = ({
						image: Medias.Image,
						audio: Medias.Audio,
						video: Medias.Video,
					})[type];
					const media = new MediaClass(file.url);
					media.contentType = file.type;
					await media.loadDatas();

					return new API.Request("/media", HTTPRequest.Methods.POST)
						.needsAuth()
						.setContentType(file.type)
						.addHeader('media', JSON.stringify(media))
						.setBody(file)
						// don't serialize the file, upload it
						.setSerializer(null)
						.send()
						.then(response => {
							if (response.ok)
								response.content = new MediaClass(response.content.url);
							return response;
						});
				} catch (error) {
					return new Response(Response.Status.BUG, error);
				}
			},

			// accept images only for now
			default(data, onProgress) {
				return new Promise(async (resolve, reject) => {
					try {
						const metaData = {
							type: data.type || data.mime,
							width: data.width,
							height: data.height,
						};

						const request = new XMLHttpRequest();
						request.open('POST', API.domain + '/media');
						request.setRequestHeader(AUTH_TOKEN_KEY, await AuthManager.getToken());
						request.setRequestHeader('Content-Type', metaData.type);
						request.setRequestHeader('media', JSON.stringify(metaData));

						// 3. set up callback for request
						request.onload = () => {
							const response = Response.from(JSON.parse(request.response));
							if (response.ok) {
								const image = response.content = new Medias.Image(response.content.url);
								Object.assign(image, metaData);
							}

							resolve(response);
						};

						request.onerror = reject;
						request.ontimeout = reject;

						console.log('request: ', request.upload);

						if (request.upload && onProgress)
							request.upload.onprogress = ({ total, loaded }) => {
								const percent = Number((loaded / total).toFixed(PERCENT_NUMBERS));
								onProgress(percent);
							};

						request.send({ uri: data.uri || data.path });
					} catch (error) {
						console.error(error);
						reject(error);
					}
				});
			},
		}),

		/**
		 * Upload a media.
		 * @param media {Media} Media to upload. Url should point a local blob.
		 * @return {Promise.<Response>}
		 * @see https://docs.google.com/document/d/1BfXQ622eF5nWZQKhwuBJKuaTlY4ivBFOxUoVI6DlFg0/edit#bookmark=id.3mnmlqgnxddq
		 */
		async upload(media, blob) {
			Debug.assert(!media || !media.url, "Impossible to upload a media without any url.", true);
			if (!media || !media.url)
				return Response.BUG;

			// check if url is local
			let isLocal = ["blob:", "file:"].includes(media.url.slice(0, 5));
			if (!isLocal)
				// media already uploaded
				return Response.Success
					.setContent(media.url);
			// check blob
			if (!blob) {
				// try to fetch it
				let response = await fetch(media.url);
				if (response.ok)
					blob = await response.blob();

				// recheck
				if (!blob) {
					// return error
					console.warn("Trying to upload an url without blob datas.");
					return Response.BUG;
				}
			}
			media.contentType = blob.type;
			// wait for datas to be filled
			if (!media.hasDatas)
				await media.loadDatas();

			// retrieve datas only
			let metaDatas = Object.assign({}, media);
			// remove url file
			delete metaDatas.url;

			// build and send request
			let response = await
				new API.Request("/media", HTTPRequest.Methods.POST)
					.needsAuth()
					.setContentType(blob.type)
					.addHeader('media', JSON.stringify(metaDatas))
					.setBody(blob)
					// don't serialize the file, upload it
					.setSerializer(null)
					.send();


			if (response.ok)
				media.url = response.content.url;

			return response;
		}
	},

	sale: {
		/**
		 * https://docs.google.com/document/d/1BfXQ622eF5nWZQKhwuBJKuaTlY4ivBFOxUoVI6DlFg0/edit#bookmark=kix.8a63sf8hndzh
		 */
		getList: new APIFunction({
			constructor() {
				cacheUpdateCenter.VSale.persist.listen(this, vSales => {
					let mapVSales = vSales.toObject(({ id }) => id);

					updateMultipleLists(
						this.config.request(),
						({ id }) => mapVSales[id],
						({ id }) => mapVSales[id],
					);
				});
			},

			request: (shopId, from) => (
				new API.Request('/sale/shop')
					.setParameters({ shopId, from })
					.setResponseType({ type: Array, template: VSale })
			),

			cache: {
				time: cache.TIME.LONG,
				accessor: new ListCacheAccessor,
			},

			updateOtherAPIFunctions(content) {
				if (content)
					cacheUpdateCenter.VSale.persist.fire(this, ...content);
			}
		}),

		getIterator(shopId) {
			return new Iterator().hydrate({
				function: cursor => API.sale.getList(shopId, cursor),
				getCursor: response => response.content.last.sale.start,
				minimum: 5,
			});
		},

		/**
		 * https://docs.google.com/document/d/1BfXQ622eF5nWZQKhwuBJKuaTlY4ivBFOxUoVI6DlFg0/edit#bookmark=kix.2mu7htbzjjb2
		 */
		get: new APIFunction({
			constructor() {
				cacheUpdateCenter.VSale.persist.listen(this, vSales => {
					let entries = vSales.map(vSale => ({
						params: [vSale.id],
						content: vSale,
					}));

					this.cache.persistMulti(entries, cache.TIME.MAX);
				});
			},

			request: id => (
				new API.Request('/sale')
					.setParameters({ id })
					.setResponseType(VSale)
			),

			cache: { time: cache.TIME.MAX },

			updateOtherAPIFunctions(vSale) {
				if (vSale)
					cacheUpdateCenter.VSale.persist.fire(this, vSale);
			}
		}),

		/**
		 * https://docs.google.com/document/d/1BfXQ622eF5nWZQKhwuBJKuaTlY4ivBFOxUoVI6DlFg0/edit#bookmark=kix.8wsk0ymavj2h
		 */
		getCatalog: new APIFunction({
			constructor() {
				cacheUpdateCenter.VSaleProduct.persist.listen(this, vProdshops => {
					// convert to 2D map with sale id and product id
					let map = vProdshops.groupBy(({ vSaleProduct }) => vSaleProduct.sale.id);
					// map products by ids
					map = Objects.map(map, (saleId, vProdshops) => vProdshops.toObject(({ product }) => product.id));

					updateMultipleLists(
						this.config.request(),
						({ vSaleProduct, product }) => map[vSaleProduct.sale.id][product.id],
						({ vSaleProduct, product }) => map[vSaleProduct.sale.id][product.id],
					);
				});
			},

			request: (saleId, from) => (
				new API.Request('/sale/catalog')
					.setParameters({ saleId, from })
					.setResponseType({ type: Array, template: VProdshop })
			),

			cache: {
				accessor: new ListCacheAccessor,
			},

			updateOtherAPIFunctions(vProdshops) {
				cacheUpdateCenter.VSaleProduct.persist.fire(this, ...vProdshops);
			}
		}),

		getCatalogIterator(saleId) {
			return new Iterator().hydrate({
				function: cursor => API.sale.getCatalog(saleId, cursor),
				getCursor: response => response.content.last.saleProduct.creationDate,
				minimum: 20,
			});
		},

		/**
		 * https://docs.google.com/document/d/1BfXQ622eF5nWZQKhwuBJKuaTlY4ivBFOxUoVI6DlFg0/edit#bookmark=kix.m59biavw0wr9
		 */
		getProduct: new APIFunction({
			request: (saleId, productId) => (
				new API.Request('/sale/catalog/product')
					.setParameters({ saleId, productId })
					.setResponseType(VProdshop)
			),

			cache: { time: cache.TIME.LONG },

			updateOtherAPIFunctions(vProdshop) {
				cacheUpdateCenter.VSaleProduct.persist.fire(this, vProdshop);
			}
		}),

		/**
		 * https://docs.google.com/document/d/1BfXQ622eF5nWZQKhwuBJKuaTlY4ivBFOxUoVI6DlFg0/edit#bookmark=kix.7imcuaiwnw9o
		 */
		getStock: new APIFunction({
			request(saleId, productId) {
				return new API.Request('/sale/stock')
					.setParameters({ saleId, productId })
					.setResponseType({ type: Array, template: VSale.Product.Stock });
			}
		}),
	},

	brand: {

		/**
		 * https://docs.google.com/document/d/1BfXQ622eF5nWZQKhwuBJKuaTlY4ivBFOxUoVI6DlFg0/edit#bookmark=kix.cmjqduo29kai
		 */
		get: new APIFunction({
			constructor() {
				cacheUpdateCenter.Brand.persist.listen(this, brands => {
					let names = brands.map(brand => brand.name);
					this.cache.persist(names, brands, cache.TIME.MAX);
				});
			},

			request: (...names) => (
				new API.Request("/brand")
					.setParameters({ names })
					.setResponseType({
						type: Array,
						template: Brand,
					})
			),

			cache: {
				time: cache.TIME.MAX,
				accessor: new SplitCacheAccessor(
					({ names }) => names.map(name => ({ names: [name] })),
					({ name }) => ({ names: [name] }),
				),
			},

			onSuccess(response) {
				const allBrands = response.content;

				response.content = pipe(
					groupBy(prop('parsedName')),
					Object.entries,
					reduce((result, [parsedName, sameBrands]) => {
						let participants = sameBrands.slice();
						const getTheWinner = (brands) => brands.length === 1 ? brands[0] : undefined;
						let winner = getTheWinner(participants);
						[
							prop('logo'),
							prop('description'),
							(() => { // keep the oldest
								const oldest = sameBrands.reduce((oldest, brand) => (!oldest || brand.creationDate < oldest) ? brand : oldest);
								return brand => brand === oldest;
							})(),
						].some(eliminate => {
							if (!winner) {
								const rest = participants.filter(eliminate);
								if (rest.length)
									participants = rest;

								winner = getTheWinner(participants);
							}

							return winner;
						});

						result[parsedName] = winner;

						return result;
					}, {}),
				)(response.content);

				response.content._originals = allBrands.toObject(brand => brand.name);
			},

			updateOtherAPIFunctions(brandsMap) {
				let brands = Objects.valuesOf(brandsMap);
				cacheUpdateCenter.Brand.persist.fire(...brands);
			}
		}),

		/**
		 * https://docs.google.com/document/d/1BfXQ622eF5nWZQKhwuBJKuaTlY4ivBFOxUoVI6DlFg0/edit#bookmark=kix.u2jtryy9q91p
		 */
		getDetailed: new APIFunction({
			request: (names, config = {}) =>
				new API.Request('/brand/detail')
					.setParameters({ ...config, names })
					.setResponseType({
						type: Array,
						template: VBrand,
					}),

			onSuccess(response) {
				response.content = {
					...response.content.toObject(vBrand => vBrand.parsedName),
				};
			},
		}),

		/**
		 * https://docs.google.com/document/d/1BfXQ622eF5nWZQKhwuBJKuaTlY4ivBFOxUoVI6DlFg0/edit#bookmark=kix.hko7c9pdtadk
		 */
		getById: new APIFunction({
			request: (...ids) => (
				new API.Request("/brand/id")
					.setParameters({ ids })
					.setResponseType({
						type: Array,
						template: Brand,
					})
			),

			onSuccess(response) {
				response.content = response.content.toObject(brand => brand.id);
			},

			updateOtherAPIFunctions(brandsMap) {
				let brands = Objects.valuesOf(brandsMap);
				cacheUpdateCenter.Brand.persist.fire(...brands);
			}
		}),

		/**
		 * https://docs.google.com/document/d/1BfXQ622eF5nWZQKhwuBJKuaTlY4ivBFOxUoVI6DlFg0/edit#bookmark=kix.tmijbfapabkr
		 */
		getList: new APIFunction({
			constructor() {
				cacheUpdateCenter.Brand.persist.listen(this, brands => {
					brands = brands.toObject(brand => brand.id);

					updateMultipleLists(
						this.config.request(),
						({ id }) => brands[id],
						({ id }) => brands[id],
					);
				});
			},

			request: (query, from) => {
				let created, shopId, available;

				// query can be an object with the "created" option
				if (is(query, Object)) {
					created = query.created;
					shopId = query.shopId;
					available = query.available

					query = query.query;
				}

				return new API.Request("/brand/list")
					.setParameters({ query, created, shopId, available, from })
					.setResponseType({
						type: Array,
						template: Brand,
					});
			},

			cache: {
				time: cache.TIME.MAX,
				accessor: new ListCacheAccessor,
			},

			updateOtherAPIFunctions(brands) {
				cacheUpdateCenter.Brand.persist.fire(this, ...brands);
			},

			iterator: {
				getCursor: brand => brand.name,
				minimum: 20,
			}
		}),

		getDetailedList: new APIFunction({
			request: (query = {}, from) => {
				return new API.Request("/brand/list/detail")
					.setParameters({ ...query, from })
					.setResponseType({
						type: Array,
						template: VBrand,
					});
			},

			iterator: {
				getCursor: vBrand => vBrand.brand.name,
				minimum: 20,
			}
		}),

		/**
		 * https://docs.google.com/document/d/1BfXQ622eF5nWZQKhwuBJKuaTlY4ivBFOxUoVI6DlFg0/edit#bookmark=id.kawjn0xh25s8
		 */
		getProdshopsMap: new APIFunction({
			request: (names, shopId, numberOfProducts) => (
				new API.Request("/brand/prodshop/map")
					.setParameters({ names, shopId, numberOfProducts })
					.setResponseType({
						type: Object,
						key: Number, // Only for reading: brand.id
						template: {
							type: Array,
							template: VProdshop,
						},
					})
			),
		}),

		/**
		 * https://docs.google.com/document/d/1BfXQ622eF5nWZQKhwuBJKuaTlY4ivBFOxUoVI6DlFg0/edit#bookmark=kix.q6zjdpaqhmy8
		 */
		getProdshopsList: new APIFunction({
			request: (name, shopId, from) => (
				new API.Request("/brand/prodshop/list")
					.setParameters({ name, shopId, from })
					.setResponseType({
						type: Array,
						template: VProdshop,
					})
			),
			iterator: {
				getCursor: vProdshop => vProdshop.product.creationDate,
				minimum: 20,
			}
		}),

	},

	collection: {
		/**
		 * https://docs.google.com/document/d/1BfXQ622eF5nWZQKhwuBJKuaTlY4ivBFOxUoVI6DlFg0/edit#bookmark=kix.27udmfpcfp9s
		 */
		get: new APIFunction({
			request: (...ids) => (
				new API.Request("/collection")
					.setParameters({ ids })
					.setResponseType({
						type: Array,
						template: VCollection,
					})
			),

			cache: {
				time: cache.TIME.MAX,
				accessor: new SplitCacheAccessor(),
			},

			onSuccess(response) {
				response.content = response.content.toObject(vCollection => vCollection.id);
			}
		}),

		/**
		 * https://docs.google.com/document/d/1BfXQ622eF5nWZQKhwuBJKuaTlY4ivBFOxUoVI6DlFg0/edit#bookmark=kix.ahdux0lktgjh
		 */
		getList: new APIFunction({
			request: (from) => (
				new API.Request("/collection/list")
					.setParameters({ from })
					.setResponseType({
						type: Array,
						template: VCollection,
					})
			),

			cache: {
				time: cache.TIME.MAX,
				accessor: new ListCacheAccessor,
			},

			iterator: {
				getCursor: vCollection => vCollection.collection.creationDate,
				minimum: 10,
			},
		}),


		/**
		 * https://docs.google.com/document/d/1BfXQ622eF5nWZQKhwuBJKuaTlY4ivBFOxUoVI6DlFg0/edit#bookmark=kix.d7j5f72wuk5l
		 */
		getItems: new APIFunction({
			request: (collectionId, from) => (
				new API.Request("/collection/item")
					.setParameters({ collectionId, from })
					.setResponseType({
						type: Array,
						template: VCollection.Item,
					})
			),

			cache: {
				time: cache.TIME.MAX,
				accessor: new ListCacheAccessor,
			},

			iterator: {
				getCursor: item => item.vProduct.product.creationDate,
				minimum: 10,
			},
		}),

		/**
		 * https://docs.google.com/document/d/1BfXQ622eF5nWZQKhwuBJKuaTlY4ivBFOxUoVI6DlFg0/edit#bookmark=kix.ktq307cia04b
		 */
		getListForShop: new APIFunction({
			request: (shopId, from) => (
				new API.Request("/collection/shop/list")
					.setParameters({ shopId, from })
					.setResponseType({
						type: Array,
						template: VShopCollection,
					})
			),

			cache: {
				time: cache.TIME.MAX,
				accessor: new ListCacheAccessor,
			},

			iterator: {
				getCursor: vShopCollection => vShopCollection.collection.creationDate,
				minimum: 10,
			},
		}),

		/**
		 * https://docs.google.com/document/d/1BfXQ622eF5nWZQKhwuBJKuaTlY4ivBFOxUoVI6DlFg0/edit#bookmark=kix.ktq307cia04b
		 */
		getItemsForShop: new APIFunction({
			request: (collectionId, shopId, from) => (
				new API.Request("/collection/shop/item")
					.setParameters({ collectionId, shopId, from })
					.setResponseType({
						type: Array,
						template: VShopCollection.Item,
					})
			),

			cache: {
				time: cache.TIME.MAX,
				accessor: new ListCacheAccessor,
			},

			iterator: {
				getCursor: item => item.vProdshop.prodshop.creationDate,
				minimum: 10,
			},
		}),
	},

	utils: {
		connectByEmail: new APIFunction({
			request: (email) =>
				new API.Request("/utils/password-recovery")
					.setParameters({ email })
		}),
	},

	showcase: {
		getByID: new APIFunction({
			request: (ids) => (
				new API.Request('/showcase')
					.setParameters({ ids })
					.setResponseType({
						type: Array,
						template: VShowcase,
					})
			),

			onSuccess(response) {
				response.content = response.content.toObject(vShowcase => vShowcase.id);
			},

		}),


		shop: {
			getShowcases: new APIFunction({
				request: (shopId) => (
					new API.Request('/showcase/shop')
						.setParameters({ shopId })
						.setResponseType({
							type: Array,
							template: VShowcase.Shop,
						})
				),

				onSuccess(response) {
					response.content = response.content.sort(VShowcase.Shop.sort);
				}
			}),

			getItems: new APIFunction({
				request: (showcaseId, shopId, from) => (
					new API.Request("/showcase/shop/item")
						.setParameters({ showcaseId, shopId, from })
						.setResponseType({
							type: Array,
							template: VShowcase.Shop.Item,
						})
				),
				iterator: {
					getCursor: ({ item }) => item.creationDate,
					minimum: 20,
				}
			}),
		},
	},

	reservation: {
		getReservationSpans: APIFunction({
			request: (shopId) => new API.Request('/shop/reservation/spans')
				.setParameters({ shopId })
				.setResponseType({
					spans: {
						type: Array,
						template: ReservationSpan,
					},
					closures: {
						type: Array,
						template: ReservationClosure,
					},
				}),
		})
	},
};

export default API;

// init at least once with default behavior
API.init();

const PERCENT_NUMBERS = 4;
