import React from "react"
import {View as RNView} from "react-native"
import use from "library-react/hook"
import {styles} from "@main/res"
import Image from "library-react/component/Image";
import QuantityControl from "../QuantityControl";
import Portal from "library-react/component/Portal";
import View from "library-react/component/View/v2";
import {screens} from "@main/links";
import ProdshopPrice from "@main/component/ProdshopPrice";

function BasketListItem({item, index, setQuantityOf, increase, decrease, remove, ...props}) {
	const {product, prodshop, size} = item || {};

	const {Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout(index), [index]);

	return (
		<RNView {...props}>
			<RNView style={localStyles.product}>
				<Portal to={screens.product} params={{id: product.id}}>
					<View style={localStyles.image}>
						<Image
							source={product?.gallery.first}
							resizeMode="cover"
							style={styles.fit}
						/>
					</View>
				</Portal>

				<RNView style={localStyles.main}>
					<Portal to={screens.product} params={{id: product.id}}>
						<View style={localStyles.clickableInfos}>
							<RNView style={localStyles.mainInfos}>
								<Text
									numberOfLines={1}
									style={localStyles.title}>
									{product?.title}
								</Text>

								<ProdshopPrice
									prodshop={prodshop}
									fontSize={19}
									style={localStyles.price} />
							</RNView>

							<Text
								numberOfLines={2}
								style={localStyles.subtitle}>
								<Text numberOfLines={2}>
									{product?.subtitle}
								</Text>
							</Text>
						</View>
					</Portal>

					<RNView style={localStyles.footer}>
						<Text
							numberOfLines={1}
							style={localStyles.size}>
							{size ? `TAILLE ${size}` : " "}
						</Text>

						<QuantityControl
							quantity={item.quantity}
							onPlusClicked={increase}
							onMinusClicked={decrease}
						/>
					</RNView>
				</RNView>
			</RNView>

			<Text
				onPress={remove}
				style={localStyles.remove}>
				{`SUPPRIMER`}
			</Text>
		</RNView>
	);
}

export default React.memo(BasketListItem);


const localStyles = {
	layout: styles.static(
		{},
		{
			0: {},

			default: {
				borderTopWidth: .5,
				borderColor: styles.color.borderColor,
				paddingTop: 45,
			},
		}
	),

	product: {
		flexDirection: styles.flexDirection.row,
		height: 120,
	},

	image: {
		width: 120,
	},

	infos: {
		paddingLeft: 10,
	},

	main: {
		paddingLeft: 10,
		flex: 1,
	},

	mainInfos: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.baseline,
	},

	clickableInfos: {
		flex: 1,
	},

	title: {
		fontSize: 20,
		color: styles.color.black,
		bold: true,
		flex: 1,
	},


	price: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.flexEnd,
		justifyContent: styles.justifyContent.flexEnd,
	},

	subtitle: {
		fontSize: 14,
		color: styles.color.black,
	},

	footer: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
	},

	size: {
		fontSize: 16,
		color: styles.color.black,
		bold: true,
		flex: 1,
	},

	remove: {
		fontSize: 15,
		color: styles.color.black,
		textDecorationLine: styles.textDecorationLine.underline,
		alignSelf: styles.alignSelf.flexEnd,
		paddingVertical: 20,
	}
};
