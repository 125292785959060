import React from "react";
import screens from "./screens";
import ReceptionUI from "./ReceptionUI"
import SelectPaymentMeanUI from "./PaymentUI"
import ContactUI from "./ContactUI"
import SummaryUI from "./SummaryUI"
import use from "../../../hook";
import CheckoutStateContext from './CheckoutStateContext'
import CheckoutFrame from "./CheckoutFrame";
import useAuth from "../../../hook/useAuth";
import Server from "../../../library-js/app/Server";
import { pick, pipe } from "ramda";
import assign from "library-js/utils/function/assign";
import createStackNavigator from "../../../utils/createStackNavigator";
import { View } from "react-native";

export default function CheckoutUI(
	{
		loadable, items = loadable?.value, vShop = items?.first?.vShop,
		defaultReceptionMean, defaultPaymentMean, defaultContactInfos, defaultInstructions,
		prodshopToRoute,
		checkout,
		...props
	}
) {
	const user = useAuth.user();
	const contactLoadable = use.loadable.server(
		user && function () {
			return Server.user.auth.contactInfo.get();
		},
		[user],
	);

	use.subscribe(loadable?.onStateChanged, [loadable]);

	const submit = use.callback(() => {
		if (!submit.ing) { // prevent multiple clicks
			submit.ing = Promise.resolve(submit.checkout?.(submit.params));  // force to return a promise
			submit.ing.finally(() => submit.ing = undefined); // clean
		}

		return submit.ing;
	});
	submit.checkout = checkout;
	const saveAsCheckoutParams = state => submit.params = state;

	props.style = use.defaultStyle(props.style, localStyles.layout);
	return Boolean(user) && (
		<View {...props}>
			<CheckoutState
				key={loadable?.end}
				{...{
					loadable, items, vShop,
					defaultReceptionMean, defaultPaymentMean,
					defaultContactInfos, defaultInstructions,
					contactLoadable,
				}}
				onStateChanged={saveAsCheckoutParams}>
				<CheckoutFrame prodshopToRoute={prodshopToRoute}>
					<Navigator screenOptions={OPTIONS}>

						<Screen name={screens.reception} component={ReceptionUI} />
						<Screen name={screens.payment} component={SelectPaymentMeanUI} />
						<Screen name={screens.contact} component={ContactUI} />
						<Screen name={screens.summary}>
							{
								props =>
									<SummaryUI submit={submit} {...props} />
							}
						</Screen>

					</Navigator>
				</CheckoutFrame>
			</CheckoutState>
		</View>
	);
}

const { Navigator, Screen } = createStackNavigator();

const OPTIONS = { cardStyle: { paddingHorizontal: 1 } };

function CheckoutState(
	{
		defaultReceptionMean,
		defaultPaymentMean,
		defaultContactInfos,
		defaultInstructions,
		children,
		onStateChanged,
		...props
	}
) {
	const [receptionMean, setReceptionMean] = use.state(defaultReceptionMean);
	const [paymentMean, setPaymentMean] = use.state(defaultPaymentMean);
	const [contactInfos, setContactInfos] = use.state(defaultContactInfos);
	const [instructions, setInstructions] = use.state(defaultInstructions);

	const state = use.memo({
		receptionMean, setReceptionMean,
		paymentMean, setPaymentMean,
		contactInfos, setContactInfos,
		instructions, setInstructions,
		...props
	},
		[receptionMean, paymentMean, contactInfos, instructions]
	);

	const checkoutParams = use.memo(
		pipe(
			pick(['receptionMean', 'paymentMean', 'contactInfos', 'instructions']),
		),
		[state]
	);

	use.effect(onStateChanged, [checkoutParams]);

	return (
		<CheckoutStateContext.Provider value={state}>
			{children}
		</CheckoutStateContext.Provider>
	);
}

const localStyles = {
	layout: {
		flexGrow: 1
	}
}