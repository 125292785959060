import React from "react"
import {View as RNView} from "react-native"
import use from "library-react/hook"
import Image from "library-react/component/Image";
import QuantityControl from "../QuantityControl";
import {strings, styles} from "@main/res"
import Portal from "library-react/component/Portal";
import {screens} from "@main/links";
import ProdshopPrice from "@main/component/ProdshopPrice";

function BasketGridItem({item, increase, decrease, remove, ...props}) {
	const {product, prodshop, size, quantity} = item;

	const {Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<RNView {...props}>
			<Portal to={screens.product} params={{id: product.id}}>
				<RNView>
					<Image
						source={product?.gallery?.first}
						style={localStyles.image}
					/>

					<Text
						numberOfLines={1}
						style={localStyles.title}>
						{product?.title}
					</Text>

					<Text
						numberOfLines={1}
						style={localStyles.subtitle}>
						{product?.subtitle}
						{strings.invisibleChar}
					</Text>
				</RNView>
			</Portal>

			<ProdshopPrice
				prodshop={prodshop}
				fontSize={23}
				style={localStyles.price}
				finalPriceStyle={localStyles.finalPrice}
				oldPriceStyle={localStyles.oldPrice}
			/>

			<Text
				numberOfLines={1}
				style={localStyles.size}>
				{size && `TAILLE ${size}`}
				{strings.invisibleChar}
			</Text>

			<QuantityControl
				quantity={quantity}
				onMinusClicked={decrease}
				onPlusClicked={increase}
				style={localStyles.quantity}
			/>

			<Text
				onPress={remove}
				style={localStyles.remove}>
				{`SUPPRIMER`}
			</Text>
		</RNView>
	);
}

export default React.memo(BasketGridItem);


const localStyles = {
	layout: {
		width: 170,
	},

	image: {
		height: 206,
		marginBottom: 9,
	},

	title: {
		...styles.text(23,"#504747", undefined, 28, true),
		marginBottom: 2,
	},

	subtitle: {
		fontSize: 20,
		lineHeight: 24,
		color: "#504747",
		marginBottom: 10,
	},

	price: {
		flexDirection: styles.flexDirection.rowReverse,
		justifyContent: styles.justifyContent.flexEnd,
		marginBottom: 10,
		alignItems: styles.alignItems.flexEnd,
	},

	finalPrice: {
		fontSize: 23,
		lineHeight: 28,
		marginRight: 10,
	},

	oldPrice: {
		fontSize: 18,
	},


	size: {
		fontSize: 16,
		color: "#504747",
		lineHeight: 19,
		bold: true,
		marginBottom: 15,
	},

	quantity: {
		alignSelf: styles.alignSelf.flexStart,
		height: 33,
		marginBottom: 17,
	},

	remove: {
		alignSelf: styles.alignSelf.flexStart,
		paddingVertical: 10,
		fontSize: 17,
		textDecorationLine: styles.textDecorationLine.underline,
	}
};
