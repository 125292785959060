import React from "react"
import Environment from "../../library-js/Environment";
import {is} from "../../library-js/utils"
import toAbsoluteUrl from "to-absolute-url";
import Image from "./Image"

export default function ({...props}) {
	if (is(props.source, String))
		props.source = toAbsoluteUrl(props.source, 1); // get absolute url

	if (Environment.crawling) // if bot crawling, return the img element
		return <img src={props.source && (props.source.uri || props.source)}
					alt={props.alt}/>;

	return <Image  {...props}/>;
}
