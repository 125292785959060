import Storage from "./Storage"

/**
 * Abstract class of a storage system using keys.
 * All methods are asynchronous.
 * <ul>
 *     <li>{@link IMapStorage.set}(Object map) : Persist data.</li>
 *     <li>{@link IMapStorage.get}(Array keys) : Load data.</li>
 *     <li>{@link IMapStorage.remove}(Array keys) : Remove data.</li>
 *     <li>{@link IMapStorage.getKeys}() : Returns all stored keys.</li>
 * </ul>
 */
export default class IMapStorage extends Storage {
	/**
	 * @abstract
	 * Persist values by their keys.
	 * WARNING Only values that are serializable can be stored.
	 * @param {Object} map Object containing all values mapped by their keys.
	 * @returns {Promise.<Object.<Error>>} An object of errors mapped by keys. For each key, if no error is set, then the key's value stored fine.
	 */
	set(map){
		throw "IMapStorage.set method not implemented.";
	}

	/**
	 * @abstract
	 * @param {Array.<String|Number|Boolean>} keys Keys of all values to load.
	 * @returns {Promise.<Object.<{value, error}>>} Object of items mapped by their keys. Each item has 'value' & 'error' fields.
	 * If an error occurred, the value field will be empty, end the error field will contain the error.
	 */
	get(...keys){
		throw "IMapStorage.get method not implemented.";
	}

	/**
	 * @abstract
	 * Remove values of all passed keys.
	 * @param {Array.<String|Number|Boolean>} keys Keys of all values to remove.
	 * @returns {Promise.<Object.<Error>>} An object of errors mapped by keys. For each key, if no error is set, then the key's value removed fine.
	 */
	remove(...keys){
		throw "IMapStorage.remove method not implemented.";
	}

	/**
	 * @abstract
	 * @returns {Promise.<Arrays.<String|Number|Boolean>>} The array of all stored keys.
	 */
	async getKeys(){
		throw "IMapStorage.getKeys method not implemented.";
	}
}
