import React from "react";
import useStack from "../hook/useStack";
import use from "../hook";
import useFocusEffect from "library-react/hook/navigation/useFocusEffect";

export default function createStackController() {
	const SetterContext = React.createContext();
	const GetterContext = React.createContext();
	const LastGetterContext = React.createContext();


	function Provider({...props}) {
		const [entries, setEntry] = useStack();
		return (
			<SetterContext.Provider value={setEntry}>
				<GetterContext.Provider value={entries}>
					<LastGetterContext.Provider value={entries.last} {...props}/>
				</GetterContext.Provider>
			</SetterContext.Provider>
		);
	}

	const Controller = React.memo(
		function Controller(props) {
			const setEntry = use.context(SetterContext);

			const memo = use.memo({entry: null});

			//Append or update
			useFocusEffect((focus) => {
					if (focus) {
						setEntry(props, memo.entry);
						memo.entry = props;
					}
				},
				Object.entries(props).flat(1)
			);

			//Remove on unmount (without dependencies)
			useFocusEffect((focus) => {
				if (focus)
					return () => setEntry(null, memo.entry);
			});

			return null;
		}
	);

	function useControls() {
		return use.context(LastGetterContext);
	}

	return [Provider, useControls, Controller, {SetterContext, GetterContext, LastGetterContext}];
}