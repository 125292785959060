import React from "react"
import use from "../../hook"
import {styles} from "../../res"
import Text from "../Text";

function OldPrice({price, ...props}) {
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<Text
			numberOfLines={1}
			{...props}>
			{price?.value}
			<Text style={localStyles.currency}>
				{price?.symbol}
			</Text>
		</Text>
	);
}

export default React.memo(OldPrice);


const localStyles = {
	layout: {
		fontSize: 10,
		color: '#1e2432',
		textDecorationLine: styles.textDecorationLine.lineThrough,
		bold: true,
	},

	currency: {
		fontSize: 7,
	}
};
