import React from "react";
import {View as RNView} from "react-native";
import use from "../../../../../../hook";
import {is} from "../../../../../../library-js/utils";
import {VOrder, VProdshop} from "../../../../../../library-js/app/model/view";
import ProductAttachmentPreview from "./ProductAttachmentPreview";
import OrderItemAttachmentPreview from "./OrderItemAttachmentPreview";
import {styles} from "../../../../../../res";

function AttachmentPreview({attachment, onRemoveClicked, ...props}) {
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<RNView {...props}>
			{
				use.memo(() => {
						switch (is(attachment)) {
							case VOrder.Item:
								return (
									<OrderItemAttachmentPreview
										item={attachment}
										onRemoveClicked={onRemoveClicked}/>
								);

							case VProdshop:
								return (
									<ProductAttachmentPreview
										item={attachment}
										onRemoveClicked={onRemoveClicked}
									/>
								);
						}
					},
					[attachment]
				)
			}
		</RNView>
	);
}

export default React.memo(AttachmentPreview);

const localStyles = {
	layout: {
		paddingTop: 10,
		alignItems: styles.alignItems.flexStart,
	},
};
