import {screens} from "@main/links";
import useSectionsLoadable from "@main/hook/useSectionsLoadable";
import use from "library-react/hook";

export default function useMenuItems() {
	const {value: cupboard} = useSectionsLoadable();
	const roots = cupboard?.getRootSections() || [];

	function createRoot(root) {
		const children = cupboard.getChildrenOf(root);
		const subItems = !Boolean(children.length) ? [] :
			children?.map(
				section => ({
					key: section.name,
					title: section.path.last.toUpperCase(),
					screen: screens.catalog,
					params: {section: section.name},
				})
			);


		return {
			key: root.name,
			title: root.name.toUpperCase(),
			screen: screens.catalog,
			params: {section: root.name},
			subItems,
			shouldBeOpened: ({name, params}) => (
				name === screens.catalog
				&& params
				&& params.section?.startsWith(root.name)
			)
		};
	}

	const items = use.memo(() => roots.map(createRoot), [cupboard]);
	return items;
}

