import {INPUT_PROPS} from "../../input/TextInput";
import MaterialInputWrapper from "./MaterialInputWrapper";
import inputComponent from "../../abstract/inputComponent";
import {Enum} from "../../../library-js/class";
import use from "../../../hook";
import parallel from "../../../library-js/utils/function/parallel";
import {Objects} from "../../../library-js/utils";

export default function withMaterialInputStyle(Input, givenInputProps) {
	function HOCComponent({value, validate, onFocus, onBlur, inputStyle, ...props}) {
		const inputProps = Objects.retrieve(props, INPUT_PROPS.concat(givenInputProps), true);

		const [focus, setFocusTo] = use.state.bool(false);
		inputProps.onFocus = parallel([inputProps.onFocus, setFocusTo.true]);
		inputProps.onBlur = parallel([inputProps.onBlur, setFocusTo.false]);

		const waitingSubmit = "submitted" in inputProps && !inputProps.submitted;
		const valid = waitingSubmit || !validate || (validate instanceof Function ? validate(value) : Boolean(value));

		props.state =
			inputProps.editable === false ? State.disabled :
				focus ? State.focus :
					!valid ? State.error :
						(validate && value && valid) ? State.valid
							: State.blur;

		inputProps.style = use.defaultStyle(inputStyle, localStyles.input);
		inputProps.placeholder = inputProps.placeholder || props.label;

		return (
			<MaterialInputWrapper value={value} {...props}>
				<Input value={value} {...inputProps}/>
			</MaterialInputWrapper>
		);
	}

	return inputComponent(HOCComponent);
}
const State = Enum.make(['focus', 'valid', 'blur', 'error', "disabled"]);
export {State}


const localStyles = {
	input: {
		paddingLeft: 15,
		paddingTop: 9,
		paddingBottom: 9,
		borderWidth: 0,
		backgroundColor: "#00000000",
		flex: 1,
	}
};
