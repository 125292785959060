import React from "react"
import {is} from "../../../../../../../library-js/utils";
import {OrderItemMessage, ProductMessage} from "../../../../../../../library-js/app/model/chat";
import TextMessageView from "./TextMessageView"
import ProductMessageView from "./ProductMessageView"
import OrderItemMessageView from "./OrderItemMessageView"
import use from "../../../../../../../hook";


function MessageView({item: message, onOrderItemClicked, onProductClicked, hidePrice, ...props}) {
	props.style = use.defaultStyle(props.style, localStyle);

	return (
		is(message, OrderItemMessage) ?
			<OrderItemMessageView
				message={message}
				onOrderItemClicked={onOrderItemClicked}
				{...props}/> :

			is(message, ProductMessage) ? (
					<ProductMessageView
						message={message}
						onProductClicked={onProductClicked}
						hidePrice={hidePrice}
						{...props}/>
				) :

				// default
				<TextMessageView message={message} {...props}/>
	);
}

export default React.memo(MessageView);

const localStyle = {marginVertical: 11};
