import React from "react"
import moment from "moment";
import {View} from "react-native";
import use from "library-react/hook";
import Icon from "library-react/component/Icon/v2";
import ReservationState from "@main/ui/reservation/ReservationListUI/ReservationState";
import Spinner from "@main/component/Spinner";
import {styles} from "@main/res";
import {TextStyleContext} from "library-react/component/Text/v2";

export default function ReservationRowMobileUI({item: vReservation, canceling, cancel, ...props}) {
	const {reservation} = vReservation;

	const {Text, Spinner} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<View {...props}>
			<View style={localStyles.dateAndState}>
				<View>
					<Text style={localStyles.label}>
						{`Créée le ${moment(reservation.creationDate).format("DD/MM/YYYY")}\n`}
					</Text>
					<Text style={localStyles.id}>
						{`N°${vReservation.id}`}
					</Text>
				</View>

				<TextStyleContext.Provider value={{fontSize: 12}}>
					<ReservationState item={vReservation} iconSize={15} style={localStyles.state}/>
				</TextStyleContext.Provider>

			</View>

			<View style={localStyles.infos}>
				<View style={localStyles.wrapper(120)}>
					<Icon name={'MaterialCommunityIcons/calendar-range'}
						  size={20}
						  style={localStyles.icon}/>
					<Text style={localStyles.number}>
						{moment(reservation.time).format("DD/MM/YYYY")}
					</Text>
				</View>

				<View style={localStyles.wrapper(100)}>
					<Icon name={'MaterialCommunityIcons/clock-time-nine'}
						  size={20}
						  style={localStyles.icon}/>
					<Text style={localStyles.number}>
						{moment(reservation.time).format("hh:mm")}
					</Text>
				</View>

				<View style={localStyles.wrapper(100)}>
					<Icon name={'MaterialIcons/check'}
						  size={20}
						  style={localStyles.icon}/>
					<Text style={localStyles.number}>
						{reservation.numberOfPlaces}
					</Text>
				</View>
			</View>

			<View>
				{
					canceling ?
						<Spinner size={15}
								 style={localStyles.cancel}/> :
						(
							vReservation.isCancellable && cancel &&
							<Text style={localStyles.cancel}
								  onPress={() => cancel(vReservation)}>
								{`Annuler`}
							</Text>
						)
				}
			</View>
		</View>
	);
}

const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.column,
		paddingVertical: 20,
		paddingHorizontal: 26,
	},
	wrapper: (maxWidth) => ({
		flexDirection: styles.flexDirection.row,
		maxWidth,
	}),
	dateAndState: {
		flexDirection: styles.flexDirection.row,
		justifyContent: styles.justifyContent.spaceBetween,
		alignItems: styles.alignItems.center,
		marginBottom: 15,
	},
	state: {
		minWidth: 106,
		paddingVertical: 4,
	},
	infos: {
		flexDirection: styles.flexDirection.row,
		justifyContent: styles.justifyContent.spaceBetween,
		alignItems: styles.alignItems.center,
		marginBottom: 15,
	},
	label: {
		fontSize: 16,
		marginBottom: 2,
	},
	id: {
		color: "#acb1c0",
		fontSize: 14,
	},
	date: {
		fontSize: 17,
		bold: true,
	},
	time: {
		fontSize: 17,
		bold: true,
	},

	number: {
		fontSize: 17,
		bold: true,
	},
	cancel: {
		paddingHorizontal: 14,
		paddingVertical: 2,
		fontSize: 16,
		color: "#5e6383",
		textAlign: styles.textAlign.center,
		alignSelf: styles.alignSelf.flexEnd,
		bold: true,
	},
	icon: {
		color: "#5e6383",
		marginRight: 11,
	}
}

