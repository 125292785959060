import React, { useRef } from "react"
import useEffect from "./useEffect";

/**
 * @template T
 * @param {T | (...p: any[]) => T} factory
 * @param {Array} [dependencies]
 * @param {Function} [destructor]
 * @returns {T}
 */
export default function useMemo(factory, dependencies, destructor) {
	if (!dependencies)
		dependencies = [/*No dependencies by default*/];

	const memorized = useRef({}).current;

	if (!dependencies.match(memorized.dependencies)) {
		if (memorized.destructor)
			memorized.destructor(memorized.value, memorized.dependencies);

		memorized.value = factory instanceof Function ? factory(...dependencies) : factory;
		memorized.destructor = destructor;
		memorized.dependencies = dependencies;
	}

	useEffect(() =>
		() => { // on unmount - destroy memory
			if (memorized.destructor)
				memorized.destructor(memorized.value, memorized.dependencies);
		},
	);

	return memorized.value;
}
