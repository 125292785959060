import Base from "../../../../class/Base"
import Reservation from "../../entity/Reservation"
import VShop from "./VShop"
import VUser from "./VUser"
import moment from "moment";
import "moment-timezone"
import {isNil} from "ramda";

export default class VReservation extends Base {
	getMoment() {
		const at = moment(this.reservation.time);
		const timeZone = this.vShop?.shop?.timeZone;
		if (timeZone)
			at.tz(timeZone);
		return at;
	}

	get isCancellable() {
		return !this.reservation?.expired && this.currentState?.type.is.waiting;
	}

	get id() {
		return this.reservation?.id;
	}

	get time() {
		return this.reservation?.time;
	}
}
VReservation.addProperties({
	reservation: Reservation,
	vShop: VShop,
	vUser: VUser,
	currentState: Reservation.State,
});

