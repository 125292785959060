import Entity from "./Entity"
import {Image} from "../media"

export default class Brand extends Entity {
	constructor(name) {
		super();
		this.name = name;
	}

	get parsedName() {
		return Brand.parseName(this.name);
	}
}
Brand.addProperties({
	name: String,
	slogan: String,
	description: String,

	logo: Image,

	creationDate: Number,
});

Brand.parseName = function (name) {
	if (name && name.trim)
		return name?.trim().toLowerCase();
}