import Base from "../../../../class/Base"
import Evaluation from "./Evaluation"
import Comment from "../../Comment"
import VUser from "../general/VUser"
import VShop from "../general/VShop"

export default class Client extends Base {
	get id(){
		return this.user?.id;
	}

	get user(){
		return this.vUser?.user;
	}
}
Client.addProperties({
	vUser : VUser,
	vShop : VShop,
	membership : Number,
	comment : Comment,
	evaluation : Evaluation,
	lastPurchaseDate : Number,
	numberOfOrders : Number,
	totalPaid : Number,
});
