import React from "react";
import MobileUI from "./mobile"
import DesktopUI from "./desktop"
import use from "library-react/hook";
import {is} from "../../../../library-react/library-js/utils";

function WallHome({...props}) {
	const {vShop: {shop}, config} = use.context.infos();

	props.banner = config?.home?.banner || shop.gallery?.first;

	const shouldDisplayTitle = config?.home?.displayTitle;
	props.shouldDisplayTitle = is(shouldDisplayTitle) ? Boolean(shouldDisplayTitle) : true;

	props.title = config?.home?.title || shop.name;

	const subtitle = config?.home?.subtitle;
	props.subtitle = is(subtitle) ? String(subtitle) : `Visiter notre établissement`;

	const display = use.context.display();
	return (
		display.is.mobile ?
			<MobileUI {...props}/> :
			<DesktopUI {...props}/>
	);
}

export default React.memo(WallHome);
