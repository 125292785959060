import React from "react"
import useAuth from "library-react/hook/useAuth";
import View from "library-react/component/View/v2";
import AuthManager from "library-js/AuthManager";
import Spinner from "@main/component/Spinner";
import {styles} from "@main/res";
import ConnexionButton from "@main/component/ConnexionButton";
import authenticate from "@main/utils/authenticate";
import use from "library-react/hook";
import isInPreview from "@main/utils/isInPreview";

function AuthenticateView_render({...props}) {
	const user = useAuth.user();
	const authenticating = useAuth.state().is.authenticating;

	let title;
	if (!("title" in props)) {
		title = user ? `Utilisez un autre compte` : `Connectez-vous rapidement via un de vos compte`
	}
	else {
		title = props.title;
	}

	const [narrow, setNarrow] = use.state(false);
	props.onLayout = ({nativeEvent}) => {
		const width = nativeEvent.layout.width;
		setNarrow(width <= NARROW_WIDTH);
	};

	const {Text} = use.theme();
	return (
		<View {...props}>
			{
				!isInPreview() ?


					!AuthManager.ready || authenticating ?
						<Spinner
							style={styles.fit}/> :

						<>
							{
								Boolean(title) &&
								<Text style={localStyles.title}>
									{title}
								</Text>
							}

							<View style={localStyles.form.layout}>

								<ConnexionButton
									provider="apple"
									onPress={authenticate.with.apple}
									fontSize={narrow && 14}
									space={narrow && 10}
									style={localStyles.form.apple}/>

								<ConnexionButton
									provider="google"
									onPress={authenticate.with.google}
									fontSize={narrow && 14}
									space={narrow && 10}
									style={localStyles.form.google}/>

								<ConnexionButton
									provider="facebook"
									onPress={authenticate.with.facebook}
									fontSize={narrow && 14}
									space={narrow && 10}
									style={localStyles.form.facebook}/>
							</View>
						</> :

					// preview mode
				<>
					<Text>
						Aucune connexion n'est possible en mode apperçu.
					</Text>
				</>
			}
		</View>
	);
}

const AuthenticateView = React.memo(AuthenticateView_render);

export default AuthenticateView;

const HORIZONTAL_MIN_WIDTH = 705;
const NARROW_WIDTH = 400;
const Direction = AuthenticateView.Direction = {
	auto: "auto",
	horizontal: "horizontal",
	vertical: "vertical",
};

const localStyles = {
	title: {
		marginBottom: 20,
	},

	form: {
		layout: {

		},

		apple: {
			marginBottom: 20,
		},

		google: {
			marginBottom: 20,
		},

		facebook: {

		},
	}
};
