import React from "react"
import {View as RNView} from "react-native"
import use from "../../../hook"
import {styles} from "../../../res"
import Color from "../../../library-js/utils/Color";
import Text from "../../../component/Text";
import useAppWidthLevel from "../../../app/useAppWidthLevel";


function MaterialInputWrapper({label, value, children, state, textError, statesStyles, displayLabel, gap, ...props}) {
	const [isDesktop] = useAppWidthLevel([1024]);

	const contentStyle = use.memo(localStyles.content, [statesStyles]);
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<RNView {...props}>
			<RNView style={localStyles.title.layout(gap)(isDesktop)}>
				{
					Boolean(label) && (Boolean(value) || Boolean(displayLabel)) &&
					<Text style={localStyles.title.label(isDesktop)}>
						{label}
					</Text>
				}
			</RNView>

			<RNView style={contentStyle(state)}>
				{children}
			</RNView>

			{/* error message */}
			<Text style={localStyles.error}>
				{
					state?.is.error &&
					(textError || `Champs incorrect`)
				}
			</Text>
		</RNView>
	);
}

export default React.memo(MaterialInputWrapper);


const localStyles = {
	layout: {},

	title: {
		layout: (gap) => styles.static.bool(
			{
				flexDirection: styles.flexDirection.row,
				alignItems: styles.alignItems.center,
			},
			{
				marginBottom: gap || 15,
			},
			{
				marginBottom: gap,
			}
		),
		label: styles.static.bool(
			{
				color: '#4d4d4d',
			},
			{
				fontSize: 14,
			},
			{
				fontSize: 12,
				bold: true,
			}),
	},

	content: (statesStyles = {}) =>
		styles.static(
			{borderBottomWidth: 1, flex: 1},
			{...defaultStyles, ...statesStyles}
		),

	error: {
		marginTop: 2,
		marginLeft: 2.5,
		color: '#f42d55',
		fontSize: 10,
	}
};

const defaultStyles = {
	valid: {
		borderColor: '#27ae60',
		backgroundColor: new Color('#27ae60').fade(1 - 0.04).toString(),
	},
	blur: {
		borderColor: '#d1d0da',
		backgroundColor: '#f7f7f7',
	},
	focus: {
		borderColor: '#5d5d5d',
		backgroundColor: '#f7f7f7',
	},
	error: {
		borderColor: '#e95454',
		backgroundColor: 'rgba(233,84,84,0.04)'
	},
	disabled: {
		backgroundColor: '#e1e1e1',
		borderColor: '#ababab',
	}
}
