import Debug from "../Debug"
import URL from "../utils/URL"
import {AutoMap, equals, is, Trigger} from "../utils"
import "../Environment"
import parallel from "../utils/function/parallel";

const Navigation = {
		toString() {
			return Location.pathname + Location.search + Location.hash;
		},

		get path() {
			let path = pathToArray(Location.pathname);

			// prevent [""] to return an array with an empty string
			if (path.length === 1 && !path.first)
				path = [];

			return path;
		},
		set path(path) {
			Debug.assert(!is(path, [Array, String]), "Navigation.path setter can only receive an array of strings");
			Navigation.replace(path, Navigation.params, History.state);
		},


		get params() {
			return URL.Params.decode(Location.search);
		},
		set params(params) {
			Debug.assert(!is(params, Object), "Navigation.params setter can only receive an object");
			Navigation.replace(Navigation.path, params, History.state);
		},

		// ---- navigation ----

		go(path, params = {}, state = null) {
			Debug.assert(!is(path, [String, Array]), "Navigation.go must receive a string or an array of strings as 1st parameter.");
			Debug.assert(!is(params, [String, Object], true), "Navigation.go must receive a string or an object as 2nd parameter.");

			if (!is(path, Array))
				path = pathToArray(path);

			if (!is(params, Object))
				params = URL.Params.decode(params);


			if (!equals(path, Navigation.path) || !equals(Navigation.params, params) || !equals(state, History.state)) {
				path = pathToString(path); // convert to string
				const to = Object.keys(params).length ? `${path}?${URL.Params.encode(params)}` : path;
				History.pushState(state, null, to);
			}
		},

		replace(path, params = {}, state = null) {
			Debug.assert(!is(path, [String, Array]), "Navigation.replace must receive a string or an array of strings as 1st parameter.");
			Debug.assert(!is(params, [String, Object]), "Navigation.replace must receive a string or an object as 2nd parameter.");

			if (!is(path, Array))
				path = pathToArray(path);

			if (!is(params, Object))
				params = URL.Params.decode(params);

			if (!equals(path, Navigation.path) || !equals(Navigation.params, params) || !equals(state, History.state)) {
				path = pathToString(path); // convert to string
				const to = !Object.keys(params).length ? path : `${path}?${URL.Params.encode(params)}`;
				History.replaceState(state, null, to);
			}
		},

		pushState(onBack, /*not supported yet*/ forward = true) {
			let key;

			key = callbacksMap.push(() => {
				if (!forward) { // delete the next state
					batching = true;
					let current = Navigation.toString();
					// TODO find another hack than the one below which doesn't work (on chrome at least)
					// History.back();
					// History.pushState(null, null, current);
					batching = false;
					// firePathChanged will be run by the onPopState after this callback
				}

				if (onBack)
					onBack();
			});

			batch(() => {
				const currentFullPath = Navigation.toString();
				History.replaceState({callback: key}, null, currentFullPath);
				History.pushState(null, null, currentFullPath);
			});
		},

		// ---- back & forward history -----

		forward(number = 1) {
			History.go(number);
		},

		back(number = 1) {
			History.go(-number);
		},

		// ---- add & remove listener  functions ----

		addListener: listener => pathChangedTrigger.add(listener),

		clear() {
			pathChangedTrigger.clear();
		},
	};

if (Debug.is.development)
	window.Navigation = Navigation;

export default Navigation;

const Location = window.location;
const History = window.history;
const callbacksMap = new AutoMap;

// make module aware of changes
const pathChangedTrigger = new Trigger({/*copy path & params*/
	paramsConverter: (path, params) => [[...path], {...params}]
});

let batching = false;
let lastFiredDatas = {
	params: null,
	path: null,
};

function firePathChanged() {
	if (batching) return;

	let path = Navigation.path;
	let params = Navigation.params;

	// compare if changed
	if (!equals(lastFiredDatas.path, path) || !equals(lastFiredDatas.params, params)) {
		lastFiredDatas = {path, params}; // save
		pathChangedTrigger.fire(path, params); // fire
	}
}

function batch(batcher) {
	batching = true;
	batcher();
	batching = false;
	firePathChanged();
}


// --- bind to history events ---

const navigationQueue = {
	queue: [],
	waiting: false,

	push(resolve) {
		this.queue.push(resolve);
		this.resolve();
	},

	asyncResolved: () => {
		navigationQueue.waiting = false;
		navigationQueue.resolve();
	},

	resolve() {
		while (!this.waiting && this.queue[0]) {
			const next = this.queue.shift();
			this.waiting = next.async;
			next();
		}
	}
};

function overrideHistory(keys, override) {
	keys.forEach(key => {
		const fn = History[key].bind(History);
		History[key] = override(fn);
	});
}

// sync fns
overrideHistory(["pushState", "replaceState"], fn =>
	(...p) => {
		const run = () => {
			fn(...p);
			firePathChanged();
		};
		navigationQueue.push(run);
	},
);

// async fns
overrideHistory(["back", "forward", "go"], fn =>
	(...p) => {
		const run = () => fn(...p);
		run.async = true;
		navigationQueue.push(run);
	},
);

// async fns resolver
window.addEventListener("popstate", parallel([
	navigationQueue.asyncResolved,
	({state}) => {
		if (state && callbacksMap[state.callback]) {
			const callback = callbacksMap.pop(state.callback);
			callback();
		}
	},
	firePathChanged,
]));


// --- utils ----

/**
 * Convert a string path into an array.
 */
function pathToArray(path) {
	let result = path.split('/');

	if (!result.first)
		result = result.slice(1);

	if (!result.last)
		result = result.slice(0, result.length - 1);

	// prevent [""] to return an array with an empty string
	if (result.length === 1 && !result.first)
		result = [];

	return result;
}

function pathToString(path) {
	return '/' + path.join('/');
}
