import React from "react";
import ShowcaseItem from "@main/component/ShowcaseItem";
import {useShowcase} from "@main/hook/useShowcases";
import use from "library-react/hook";

export default function ShowcasePinView({item: showcasePin, ...props}) {
	const item = useShowcase(showcasePin.id);

	const display = use.context.display();
	props.style = use.defaultStyle(
		props.style,
		(isM, isMobile) => ({
			flexBasis: isM && !isMobile ? '47%' : '100%',
			[isMobile ? 'paddingHorizontal' : 'paddingLeft']: 20,
		}),
		[showcasePin?.size.is.M, display.is.mobile]
	);

	return (item || null) &&
		<ShowcaseItem
			item={item?.vShowcase}
			portrait={display.is.mobile || showcasePin?.size?.is.M} {...props}/>;
}
