import React from "react"
import {View as RNView} from "react-native"
import screens from "./screens";
import use from "../../../hook";
import CheckoutStateContext from "./CheckoutStateContext";
import SelectButton from "../component/SelectButton";
import getResourcesForReceptionMean from "../../../library-js/res/receptionMeans";
import ErrorText from "../component/ErrorText";
import {styles} from "../../../res";
import ErrorView from "../../../component/ErrorView";
import CheckoutFrameContext from "./CheckoutFrameContext";
import Text from "../../../component/Text/v2";
import {useNavigation} from "@react-navigation/native";

export default function ReceptionUI({ ...props}) {
	const {
		loadable, vShop,
		receptionMean, setReceptionMean
	} = use.context(CheckoutStateContext);

	use.subscribe(loadable?.onStateChanged, [loadable]);

	const [submitted, setSubmittedTo] = use.state.bool();

	const navigation = useNavigation();
	const submit = (mean) => {
		setSubmittedTo(true);

		const finalMean = mean || receptionMean;

		if (finalMean) {
			if (receptionMean !== finalMean)
				setReceptionMean(finalMean);
			setTimeout(() => navigation.navigate(screens.payment));
		}
	};

	const FrameControl = use.context(CheckoutFrameContext);
	const {Spinner} = use.theme();

	return (
		<FrameControl
			step={1}
			title={`COMMENT VOULEZ VOUS COMMANDER ?`}
			submit={submit}>
			{
				vShop ?
					<>
						<Text style={localStyles.note}>
							{vShop?.shop?.checkoutNote}
						</Text>

						<ErrorText display={submitted && !receptionMean}>
							{`Selectionez un choix`}
						</ErrorText>

						{
							vShop.shop.receptionMeans.map(mean => {
								const resource = getResourcesForReceptionMean(mean);
								return (
									<SelectButton
										key={mean.key}
										icon={resource.icon()}
										title={resource.title()}
										selected={receptionMean === mean}
										onPress={() => submit(mean)}
										style={localStyles.selectButton}/>
								);
							})
						}
					</> :

					<RNView style={[styles.center, {flex: 1}]}>
						{
							(!loadable || loadable.loading) ?
								<Spinner/> :
								<ErrorView onLoadClicked={loadable.load}/>
						}
					</RNView>
			}
		</FrameControl>

	);
}

const localStyles = {
	selectButton: {
		marginBottom: 50,
		minHeight: 195,
	},
	note: {
		fontSize: 20,
		lineHeight: 20,
		textAlign: styles.textAlign.center,
	}
}
