const listenersRemovers = [];
listenersRemovers.add = function(remove){
	this.push(remove);

	return () => {
		remove();

		// remove the function from array
		const index = this.indexOf(remove);
		if (index >= 0)
			this.splice(index, 1);
	};
};

export default listenersRemovers;
