import React from "react"
import use from "library-react/hook";
import {styles} from "@main/res"
import {ScrollView} from "react-native";
import ProductGrid from "@main/component/ProductGrid";
import Spinner from "@main/component/Spinner";
import Button from "@main/component/Button";
import Header from "./Header";
import Sharable from "library-react/component/sharable/Sharable";
import {createShopWebUrl} from "library-react/utils/showSocialModal";

export default function DesktopUI({vShowcase, iterator, onSelected, optimizationParams, title, ...props}) {
	const vProdshops = iterator.items.map(({vProdshop}) => vProdshop);
	const {vShop} = use.context.infos();
	const {Text} = use.theme();

	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<ScrollView contentContainerStyle={localStyles.content} {...props}>
			<Sharable url={createShopWebUrl(vShop.shop, vShowcase, optimizationParams)}/>

			<Header item={vShowcase} title={title}/>

			{
				iterator &&
				<ProductGrid
					items={vProdshops}
					onSelected={onSelected}
					style={localStyles.items}/>
			}

			{
				Boolean(iterator?.items?.length) && (
					iterator.loading ? <Spinner style={localStyles.button}/> :
						!iterator.end &&
						<Button
							onPress={iterator.load}
							style={localStyles.button}>
							<Text style={localStyles.text}>
								{`Voir plus`}
							</Text>
						</Button>
				)
			}
		</ScrollView>
	);
}
const localStyles = {
	layout: {
		flex: 1,
	},
	showcase: {
		layout: {
			flexDirection: styles.flexDirection.row,
			justifyContent: styles.justifyContent.spaceBetween,
			alignItems: styles.alignItems.center,
		},
		infos: {
			layout: {
				flexDirection: styles.flexDirection.column
			},
			title: {
				fontSize: 50,
			},
			description: {
				fontSize: 20,
				maxWidth: 750,
			},
		},
		image: {
			width: 555,
			height: 300,
		}
	},
	content: {
		paddingHorizontal: 52,
		paddingBottom: 26,
	},
	items: {
		marginTop: 80,
	},
	button: {
		alignSelf: styles.alignSelf.center,
		paddingHorizontal: 200,
		paddingVertical: 16,
		marginBottom: 50,
		marginTop: 42,
	},

	text: {
		fontSize: 23,
	},
};
