import React from "react"
import use from "library-react/hook"
import CommentView from "./CommentView";
import View from "library-react/component/View";
import Header from "./Header";
import FlatList from "library-react/component/FlatList";


export default function MobileCommentList({name, rateLoadable, iterator, edit, ...props}) {
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<View {...props}>
			<Header title={name} rateLoadable={rateLoadable} edit={edit} style={localStyles.header}/>
			<View style={localStyles.separator}/>
			<FlatList
				data={iterator.items}
				keyExtractor={keyExtractor}
				contentContainerStyle={{paddingTop: 25}}
				renderItem={({item}) =>
					<CommentView
						item={item}
						style={localStyles.item}
					/>
				}/>
		</View>
	);
}

function keyExtractor(vComment) {
	return vComment.author.id;
}

const localStyles = {
	layout: {
		flex: 1,
		paddingLeft: 21,
		paddingRight: 21,
	},
	header: {
		marginBottom: 26,
	},
	separator: {
		height: 2,
		backgroundColor: '#f7f7f7'
	},

	item: {
		marginBottom: 10,
	}
};
