import React from "react"
import {ScrollView} from "react-native"
import use from "library-react/hook"
import useAuth from "library-react/hook/useAuth";
import View from "library-react/component/View/v2";
import Image from "library-react/component/Image";
import Icon from "library-react/component/Icon/v2";
import {Server} from "library-react/library-js/app";
import AuthManager from "library-react/library-js/AuthManager";
import selectFile from "library-react/utils/selectFile";
import Button from "@main/component/Button";
import simpleValidate from "library-js/utils/simpleValidate";
import ByShopinzon from "@main/App/Frame/NavigationView/ByShopinzon";
import ShopLogo from "@main/component/ShopLogo";
import {styles} from "../../res"

export default function Form({onSuccess, ...props}) {
	const user = useAuth.user();
	const display = use.context.display();

	const [picture, setPicture] = use.state(user?.photoURL && {uri: user?.photoURL});
	const selectPicture = use.asyncCallback(shouldStop =>
		() => Promise.process(shouldStop)
			.then(() => selectFile("image/*", false))
			.then(image => {
				image.uri = image.url;
				setPicture(image)
			})
			.catch(error => console.error(error))
	);

	const [submitted, setSubmitted] = use.state(false);
	const [submitting, setSubmitting] = use.state(false);

	const [name, setName] = use.state(user?.displayName);
	const [email, setEmail] = use.state(user?.email);

	const updated = email !== user?.email || name !== user?.displayName || picture?.uri !== user?.photoURL;
	const valid = simpleValidate.text(name) && simpleValidate.email(email);

	const submit = async () => {
		if (!updated) {
			onSuccess(AuthManager.user);
			return;
		}
		setSubmitted(true);

		if (!valid) return;

		setSubmitting(true);
		try {
			// upload image
			let uri = picture?.uri;
			if (uri && !uri.startsWith('https://')) {
				const response = await Server.public.media.uploadFile(picture);
				if (response.ok) {
					uri = response.content.url;
					setPicture({uri});
				} else
					response.throw();
			}


			// persist infos
			const response = await Server.user.auth.persistProfile({name, picture: uri});
			if (response.ok && onSuccess) {
				await AuthManager.reloadUser();
				onSuccess(AuthManager.user);
			} else
				response.throw();

		} catch (error) {
			console.error('Error updating user profile', error);
		} finally {
			setSubmitting(false);
		}
	};

	const {MaterialTextInput, Text, Spinner} = use.theme();

	return (
		<ScrollView {...props}>

			<ShopLogo style={localStyles.logo}/>

			{/* title */}
			<Text style={localStyles.title}>
				{`Compléter votre profil`}
			</Text>

			{/* subtitle */}
			<Text style={localStyles.subtitle}>
				{`Au plus votre profil est complet, meilleur sera votre \nexpérience`}
			</Text>

			{/*	user picture */}
			<View
				onPress={!submitting && selectPicture}
				style={localStyles.pictureFrame}>
				<Image
					source={picture}
					resizeMode='cover'
					style={localStyles.picture}
				/>

				<Icon
					name={picture ? 'MaterialIcons/edit' : 'MaterialIcons/add'}
					size={19}
					style={localStyles.editIcon}
				/>

				{/* placeholder */}
				{
					!picture &&
					<Icon
						name='MaterialIcons/camera-alt'
						size={40}
						style={[styles.fit, {color: '#c1c0c8'}]}
					/>
				}
			</View>

			<MaterialTextInput
				label={`Nom complet`}
				defaultValue={name}
				onValueChanged={setName}
				validate={submitted && simpleValidate.text}
				editable={!submitting}
				statesStyles={localStyles.statesStyles}
				style={localStyles.input}
			/>
			<MaterialTextInput
				label={`Email`}
				defaultValue={email}
				onValueChanged={setEmail}
				validate={submitted && simpleValidate.email}
				editable={!user?.email}
				statesStyles={localStyles.statesStyles}
				style={localStyles.input}
			/>

			<Button
				filled
				onPress={submit}
				disabled={submitting}
				colors={COLORS}
				hoverColors={!(display.is.mobile || submitting) && HOVER_COLORS}
				style={localStyles.submit}>
				{
					Boolean(submitting) ? <Spinner size={display.is.mobile ? 30 : 35}/> :
						<Text style={localStyles.text}>
							{(valid && !updated) ? `Passer` : `Mettre a jour`}
						</Text>
				}
			</Button>

			<ByShopinzon style={localStyles.sponsored}/>
		</ScrollView>
	);
}


const COLORS = {
	primary: styles.color.black,
	secondary: styles.color.white,
};
const HOVER_COLORS = {
	primary: '#70707020',
	secondary: styles.color.black,
};

const localStyles = {
	logo: {
		height: 75,
		width: 75,
		marginBottom: 26,
		alignSelf: styles.alignSelf.center,
	},
	title: {
		...styles.text(23, styles.color.black, undefined, true),
		textAlign: styles.textAlign.center,
		bold: true,
		marginBottom: 5,
	},

	subtitle: {
		...styles.text(18, styles.color.black),
		textAlign: styles.textAlign.center,
		marginBottom: 42,
	},

	pictureFrame: {
		...styles.square(100),
		alignSelf: styles.alignSelf.center,
		marginBottom: 22,
	},

	picture: {
		...styles.circle(100),
		...styles.newBorder(1, '#c1c0c8'),
		backgroundColor: '#f7f7f7',
	},

	editIcon: {
		...styles.absolute({right: 0, top: 0}),
		...styles.circle(28),
		backgroundColor: styles.color.white,
		...styles.newBorder(1, '#c1c0c8'),
		color: '#c1c0c8',
		zIndex: 1,
	},

	input: {
		marginHorizontal: 50,
		marginBottom: 54,
		width: '100%',
		alignSelf: styles.alignSelf.center,
		maxWidth: 440,
	},
	submit: {
		marginBottom: 36,
		width: '100%',
		alignSelf: styles.alignSelf.center,
		maxWidth: 340,
	},
	sponsored: {
		alignSelf: styles.alignSelf.center,
	},
	text: {
		...styles.text(13, styles.color.white),
	},
	statesStyles: {
		blur: {
			borderColor: '#9a9999',
		},
		focus: {
			borderColor: '#3c60fb',
		},
	}
};
