import React from "react";
import View from "library-react/component/View/v2";
import use from "library-react/hook";
import {strings, styles} from "@main/res";
import Image from "library-react/component/Image";
import LoadingPlaceholder from "library-react/component/LoadingPlaceholder";
import fonts from "@main/res/fonts";
import {Text} from "react-native";

export default function ArticleUI({item: article, inverted, ...props}) {
	const {Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<View {...props}>

			<LoadingPlaceholder disabled={article} style={localStyles.wrapper}>
				<Text>
					{
						article.media &&
							<Image source={article.media}
								   resizeMode="cover"
								   style={localStyles.image(inverted)}/>
					}
					<Text style={localStyles.title} numberOfLines={1}>{article?.title}</Text>

					{/* Margin bottom between title and description, because we are in a text component to make the float woarking */}
					{SPACE_BETWEEN_TITLE_DESCRIPTION}

					<Text style={localStyles.description}>
						{article?.description || strings.invisibleChar}
					</Text>
				</Text>
			</LoadingPlaceholder>
		</View>
	);
}

const SPACE_BETWEEN_TITLE_DESCRIPTION = (
	<>
		<Text style={{lineHeight: 0}}>
			{`\n`}
		</Text>
		<Text style={{lineHeight: 17}}>
			{strings.invisibleChar}
		</Text>
		<Text style={{lineHeight: 0}}>
			{`\n`}
		</Text>
	</>
);

const localStyles = {
	layout: {},

	image: styles.static.bool({
			height: 275,
			width: 380,
			marginBottom: 26,
		},
		{
			marginRight: 90,
			float: 'left',
		},
		{
			marginLeft: 90,
			float: 'right',
		}
	),


	wrapper: {
		borderRadius: 5,
	},

	title: {
		font: fonts.BebasNeue,
		fontSize: 44,
		bold: true,
	},
	description: {
		fontSize: 19,
		lineHeight: 24,
	},
}
