import React from "react"
import moment from "moment";
import rowStyles from "./styles";
import { styles } from "@main/res"
import use from "library-react/hook";
import Row from "library-react/component/Row"
import StateView from "library-react/component/OrderStateView/StateView";
import View from "library-react/component/View/v2";
import Portal from "library-react/component/Portal";
import { screens } from "@main/links";
import { sum, map, prop } from "ramda";

/**
 * 
 * @param {{
 * 	item: import("../../../../../library-react/library-js/app/graphql").Order_V2,
 * }}  
 * @returns 
 */
export default function OrderRow({ item: order, onSelected, ...props }) {
	const { id } = order;
	const currentState = order?.states[0]?.state;
	const price = order.items_aggregate?.aggregate?.sum?.totalPrice;

	const { Text } = use.theme();
	props.columnsStyles = rowStyles.columns;
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<Portal to={screens.order} params={{ id: id }}>
			<Row {...props}>
				{/* ID */}
				<View style={localStyles.infos.layout}>
					<Text style={localStyles.infos.date}>
						{'Cde'} {moment(order.creationDate).format("DD/MM/YYYY")}
					</Text>
					<Text style={localStyles.infos.id}>
						{id}
					</Text>
				</View>

				{/* State */}
				<StateView
					state={currentState}
					receptionMean={order.receptionMean}
					displayText
					background
					innerStyles={localStyles.state.innerStyles}
					style={localStyles.state.layout} />

				{/* Price */}
				{
					price &&
					<Text style={localStyles.price}>
						{price}
					</Text>
				}
			</Row>
		</Portal>
	);
}


const localStyles = {
	layout: [
		rowStyles.row,
		{
			borderTopWidth: .5,
			borderTopColor: styles.color.background,
		},
	],

	infos: {
		layout: {},
		date: {
			color: '#000000',
			fontSize: 20,
			bold: true,
		},
		id: {
			color: '#000000',
			fontSize: 15,
		},
	},

	price: {
		color: '#000000',
		fontSize: 19,
		bold: true,
	},

	state: {
		layout: {
			width: 160,
			borderRadius: 0,
		},
		innerStyles: {
			text: { fontSize: 15 }
		}
	},

};

