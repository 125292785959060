import {useState} from "react"
import useCallback from "./useCallback";

/**
 * Safe hook. No need to use it as a dependency.
 */
export default function useForceUpdate(){
	const [, set] = useState(0); //boolean state
	return useCallback(() => set(value => value + 1)); // update the state to force render (DO NOT USE A BOOLEAN HERE!)
}
