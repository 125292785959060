import {getFontFamily} from "./Text"
import {Text as RNText} from "react-native"
import ComponentUtils from "../../ComponentUtils"
import React from "react"
import {is, Objects} from "../../library-js/utils"
import styles from "../../res/styles"

const TextV1 = React.memo(
	function (props){
		props = ComponentUtils.mergeProps(props, TextV1.default);
		props.style = ComponentUtils.mergeStyles(props.style); // apply default style
		props.children = ComponentUtils.childrenToArray(props.children);

		// retrieve our text styles
		let {font, bold, italic} = Objects.retrieve(props.style, ["font", "bold", "italic"], true);

		// sync bold
		if (is(bold)) // if bold defined, priority to it over fontWeight
			props.style.fontWeight = bold ? styles.fontWeight.bold : styles.fontWeight.normal;
		else
			bold = Boolean(props.style.fontWeight === styles.fontWeight.bold);

		// sync italic
		if (is(italic)) // if italic defined, priority to it over fontStyle
			props.style.fontStyle = italic ? styles.fontStyle.italic : styles.fontStyle.normal;
		else
			italic = Boolean(props.style.fontStyle === styles.fontStyle.italic);

		// set font family if not imposed
		if (font && !props.style.fontFamily)
			props.style.fontFamily = getFontFamily(font, bold, italic);

		if (!props.style.lineHeight){
			let fontSize = props.style.fontSize = Number(props.style.fontSize);
			if (isNaN(fontSize))
				fontSize = props.style.fontSize = TextV1.default.style.fontSize;

			props.style.lineHeight = sizeToLineHeight(fontSize);
		}


		// if text default set, set default current style to text children first
		if (Object.keys(TextV1.default.style).length)
			props.children = props.children.map((child, index) => {
				if (child && child.type && child.type.text)
					return React.cloneElement(child, {
						key: child.key || index,
						style: [props.style, child.props.style].flat(),
					});

				return child;
			});

		return <RNText {...props}/>;
	}
);

export default TextV1;

TextV1.text = true; // tell other component can be styled like a text
// default props
TextV1.default = {
	style: {
		fontSize: 14,
	}
};

const sizeToLineHeight = size => size * (17/11);
