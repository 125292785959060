import React from "react"
import View from "library-react/component/View/v2";
import {styles} from "@main/res";
import ComponentUtils from "library-react/ComponentUtils";
import Image from "library-react/component/Image";
import images from "library-react/res/image";
import StarsView from "library-react/component/StarsView";
import moment from "moment";
import OpenableText from "@main/component/GradientOpenableText";
import use from "library-react/hook";

function CommentItem({item: {author, comment}, ...props}) {
	const {Text} = use.theme();
	props.style = ComponentUtils.defaultStyle(props.style, localStyles.layout);

	return (
		<View {...props}>
			<View style={localStyles.header.layout}>
				<View style={{flexDirection: styles.flexDirection.row,}}>
					<View style={localStyles.header.author.picture.layout}>
						<Image source={{uri: author.picture}}
							   defaultSource={images.interlocutor}
							   style={localStyles.header.author.picture.image}/>
					</View>
					<View>
						<Text style={localStyles.header.name}>{author.name}</Text>
						<Text style={localStyles.header.date}>{moment(comment.creationDate).calendar()}</Text>
					</View>
				</View>
				<StarsView
					useEyalStyle
					size={9}
					style={localStyles.header.stars}
					stars={comment.stars}
				/>
			</View>
			<OpenableText
				maxHeight={90}
				style={localStyles.comment}>
				{comment.text}
			</OpenableText>
		</View>
	)
}

export default React.memo(CommentItem);

let localStyles = {
	layout: {},

	header: {
		layout: {
			flex: 1,
			flexDirection: styles.flexDirection.row,
			justifyContent: styles.justifyContent.spaceBetween,
			marginLeft: 10,
			marginRight: 20
		},
		author: {
			name: {},
			picture: {
				layout: [
					styles.shadow,
					{
						width: 50,
						height: 50,
						backgroundColor: styles.color.white,
						borderRadius: 50 / 2,
						marginRight: 12.5
					}
				],

				image: [
					styles.fit,
					{
						backgroundColor: "#e5e6e7",
						borderRadius: 50 / 2,
					}
				]
			}
		},
		stars: {
			alignSelf: styles.alignSelf.flexStart,
		},
		name: {
			fontSize: 15,
		},
		date: {
			color: '#a6a5a6',
			fontSize: 11,
		},
	},

	comment: {
		fontSize: 13,
		padding: 15,
	}
};
