import React from "react"
import { BackHandler, View as RNView } from "react-native"
import ModalFrame from "../../component/ModalFrame";
import crashlytics from "../../library-js/crashlytics";
import use from "../../hook";
import { DefaultTheme, NavigationContainer, NavigationHelpersContext, useNavigationContainerRef } from '@react-navigation/native';
import { appContext } from "../useApp"
import Environment from "../../library-js/Environment";
import willOpenModal from "./willOpenModal"
import { AutoMap, Trigger } from "../../library-js/utils";
import { AppWidthContext } from "../useAppWidthLevel";
import Objects from "../../library-js/utils/Objects";
import { map, prop } from "ramda";
import produce from "immer"

/**
 * An application is the component responsible to manage which Activity to display, and to store global variables.
 */
function AppLayout({ children, screensBackgroundColor = 'white', ...props }, refProp) {
	const containerProps = Objects.retrieve(props, CONTAINER_PROPS, true);

	use.effect(() => crashlytics?.log("Application renders."));

	const modalState = use.legacyState([]);
	const [modals] = modalState;

	const [navigationContainer, setNavigationContainer] = use.state();
	const app = use.memo(navigationContainer && {
		navigation: navigationContainer,
		openModal: willOpenModal(modalState),
		store: new AutoMap(),
	}, [navigationContainer]);

	React.useImperativeHandle(refProp, () => app, [app]);

	// android: listen to back to pop modal
	if (Environment.is.android) {
		use.effect(currentModal => {
			if (currentModal)
				return BackHandler.listen(() => {
					currentModal.close();
					return true;
				});
		},
			[modals.last],
		);
	}

	const [widthContext, widthTrigger] = use.memo(() => {
		const trigger = new Trigger();
		return [
			{
				value: undefined,
				listen: trigger.add,
			},
			trigger,
		];
	});

	props.onLayout = event => {
		const width = widthContext.value = event.nativeEvent.layout.width;
		widthTrigger.fireAsyncOnce(width);
	};


	props.style = use.defaultStyle(props.style, localStyles.layout);
	containerProps.theme = use.memo(() =>
		produce(localStyles.navigation.theme, theme => {
			theme.colors.background = screensBackgroundColor;
		}),
		[screensBackgroundColor],
	);


	return (
		<RNView {...props}>
			<AppWidthContext.Provider value={widthContext}>
				<NavigationContainer
					ref={setNavigationContainer}
					{...containerProps}>
					{
						app &&
						<NavigationHelpersContext.Provider value={app.navigation}>
							<appContext.Provider value={app}>
								{children}

								<ModalFrame>
									{renderModals(modals)}
								</ModalFrame>
							</appContext.Provider>
						</NavigationHelpersContext.Provider>
					}
				</NavigationContainer>
			</AppWidthContext.Provider>
		</RNView>
	);
}



export default React.forwardRef(AppLayout);

const renderModals = map(prop('component'));

const CONTAINER_PROPS = ["initialState", "onStateChange", "onReady", "linking", "fallback", "documentTitle", "theme"];


{ // try to import react-native-gesture-handler (needed by NavigationContainer)
	if (!Environment.is.web)
		try {
			require('react-native-gesture-handler');
		} catch (error) {
			console.warn('react-native-gesture-handler not installed');
		}
}

const localStyles = {
	layout: {
		flex: 1,
	},

	navigation: {
		theme: {
			...DefaultTheme,
			colors: {
				...DefaultTheme.colors,
				background: 'transparent',
			},
		},
	},
}
