import View from "../../component/View/v2";
import { styles } from "../../res";
import React from "react";
import use from "../../hook";
import Icon from "../../component/Icon/v2";
import mailToUrl from "library-js/utils/mailToUrl";
import URL from "library-js/utils/URL"
import Portal from "../../component/Portal";

export default function SocialModal({ url, ...props }) {
	const { Text, DefaultModal } = use.theme();

	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<DefaultModal {...props}>
			<View style={localStyles.header.layout}>

				<Icon name="Ionicons/share-outline"
					style={localStyles.header.icon}
					size={25} />
				<Text style={localStyles.header.title}>
					{`Partager`}
				</Text>
				<Text style={localStyles.header.subtitle}>
					{`Via les réseaux sociaux`}
				</Text>
			</View>
			<View style={localStyles.content}>

				<SocialButton title={"Email"}
					color={styles.color.grey}
					link={mailToUrl({ body: url })}
					icon="MaterialCommunityIcons/email" />

				<SocialButton title={"Whatsapp"}
					color={"#31e663"}
					link={`https://api.whatsapp.com/send?${URL.Params.encode({ text: url })}`}
					icon="MaterialCommunityIcons/whatsapp" />

				<SocialButton title={"Twitter"}
					color={"#1d8eee"}
					link={`https://twitter.com/share?${URL.Params.encode({ url })}`}
					icon="MaterialCommunityIcons/twitter" />

				<SocialButton title={"Facebook"}
					color={"#116dea"}
					link={`https://www.facebook.com/dialog/share?${URL.Params.encode({
						app_id: 638614729580760,
						href: url
					})}`}
					icon="MaterialCommunityIcons/facebook" />

			</View>
		</DefaultModal>
	)
}

function SocialButton({ icon, title, link, color }) {
	const { Text } = use.theme();
	const [hover, setHover] = use.state.bool();

	return (
		<View onHoverChanged={setHover} style={localStyles.button.layout}>
			{
				<Text>
					{hover ? title : " "}
				</Text>
			}
			<Portal to={link} debug>
				<Icon name={icon}
					size={60}
					style={localStyles.button.icon(hover ? color : undefined)} />
			</Portal>
		</View>
	)
}

const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.column,
		alignItems: styles.alignItems.stretch,
		paddingVertical: 30,
		paddingBottom: 70,
	},
	header: {
		layout: {
			flexDirection: styles.flexDirection.column,
			alignSelf: styles.alignSelf.center,
			alignItems: styles.alignItems.center,
			paddingVertical: 10,
			marginBottom: 50,
		},
		icon: {
			marginBottom: 4,
		},
		title: {
			fontSize: 28,
			bold: true,
			marginBottom: 5,
		},
		subtitle: {
			fontSize: 18,
		}
	},
	content: {
		flexDirection: styles.flexDirection.row,
		flexWrap: styles.flexWrap.wrap,
		alignSelf: styles.alignSelf.center,
		justifyContent: styles.justifyContent.spaceEvenly,
		maxWidth: 360,
	},
	button: {
		layout: {
			flexDirection: styles.flexDirection.column,
			alignItems: styles.alignItems.center,
			marginHorizontal: 15,
			width: 60,
		},
		text: {},
		icon: (color) => ({ color }),
	},
};
