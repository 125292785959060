export default class PromiseQueue {
	constructor() {
		this.queue = [];
	}

	newPromise(){
		return new Promise(resolve => this.add(resolve));
	}

	add(resolve){
		this.queue.unshift(resolve);
	}

	resolveAll(...params){
		while (this.queue.length > 0){
			const next = this.queue.pop();
			try {
				next(...params);
			} catch (error) {
				console.warn(error);
			}
		}
	}
}
