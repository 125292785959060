import React from "react";
import use from "library-react/hook";
import View from "library-react/component/View/v2";
import {styles} from "@main/res";
import Portal from "library-react/component/Portal";
import fonts from "@main/res/fonts";
import useRouteForFrame from "@main/App/Frame/useRoute";
import doesThisRouteTargetThisScreen from "../doesThisRouteTargetThisScreen";

export default function MenuItem({title, screen, params, subItems, close, shouldBeOpened, ...props}) {
	const route = useRouteForFrame();

	const isSelected = doesThisRouteTargetThisScreen(route, screen, params);

	const displaySubItems = Boolean(
		route
		&& subItems?.length
		&& (
			(shouldBeOpened instanceof Function) ?
				shouldBeOpened(route, screen, params)
				: shouldBeOpened
		)
	);

	const {Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<View {...props}>
			<Portal to={!isSelected ? screen : undefined} params={params} onWillNavigate={close}>
				<Text
					style={localStyles.option(isSelected, false)}>
					{title}
				</Text>
			</Portal>

			{
				displaySubItems &&
				<View style={localStyles.subOptions}>
					{
						subItems.map(({key, title, screen, params}) => {
							const isSelected = doesThisRouteTargetThisScreen(route, screen, params);
							return (
								<Portal
									key={key}
									to={!isSelected && screen}
									params={params}
									onWillNavigate={close}>
									<Text
										style={localStyles.option(isSelected, true)}>
										{title}
									</Text>
								</Portal>
							);
						})
					}
				</View>
			}
		</View>
	)
}

const localStyles = {
	subOptions: {
		paddingVertical: 10,
	},

	option: (isSelected, isSubOption) => ({
		color: styles.color.white,
		fontFamily: isSubOption ? fonts.HelveticaNeue.medium : fonts.HelveticaNeue.bold,
		fontSize: isSubOption ? 18 : 23,
		paddingLeft: isSubOption ? 87 : 52,
		backgroundColor: isSelected ? '#404040' : undefined,
		paddingVertical: 10,
	}),
};
