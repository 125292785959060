import Trigger from "../../library-js/utils/Trigger";
import {Functions} from "../../library-js/utils";




export default function selectFile(contentType, multiple){
	return new Promise(resolve => {
		input.multiple = multiple;
		input.accept = contentType;

		input.onchange = (...params) => {
			let files = Array.from(input.files);

			if (multiple)
				resolve(files);
			else
				resolve(files.first);

			// reset
			input.onchange = undefined;
			input.value = "";
		};

		let removeDocumentListener = documentFocused.add(async () => {
			removeDocumentListener();

			await Promise.await(500); // there's no other way than to wait some time for the input.onchange to be triggered by the input
			if (input.onchange)
				input.onchange();
		});

		documentFocused.shouldTrigger = true;
		input.click();
	});
}

const documentFocused = new Trigger();
document.body.onfocus = Functions.append(document.body.onfocus, () => {
	if (documentFocused.shouldTrigger){
		documentFocused.fire();
		documentFocused.shouldTrigger = false;
	}
});

let input = document.createElement('input');
input.type = 'file';
