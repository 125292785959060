import RecorderComponent from "./RecorderComponent";

export default class RecorderReached extends RecorderComponent {
	get top(){
		return this.get("top");
	}

	get bottom(){
		return this.get("bottom");
	}

	get left(){
		return this.get("left");
	}

	get right(){
		return this.get("right");
	}

	get(side){
		const {recorder} = this;
		return recorder.offset[side] <= (Math.max(recorder.threshold[side], 1) || 1);
	}
}
