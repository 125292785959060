import React from "react"
import {Animated, View as RNView} from "react-native"
import View from "library-react/component/View/v2"
import {strings, styles} from "@main/res"
import use from "library-react/hook";
import Image from "library-react/component/Image";
import StaticComponent from "library-react/component/StaticComponent";
import StarsView from "library-react/component/StarsView";
import LoadingPlaceholder from "library-react/component/LoadingPlaceholder";
import {DisplayType} from "@main/contexts";
import ComponentUtils from "library-react/ComponentUtils";
import ProdshopPrice from "./ProdshopPrice";

/**
 * Display a vProdshop.
 * The discount is displayed only if the sale is active or the prop showDiscount is true.
 * @param {VProdshop?} vProdshop
 * @param {*} loading
 * @param {object} props
 */
function ProductItem({vProdshop, loading, renderInfos, noCard, ...props}) {
	const {product, prodshop, shop} = vProdshop || {};
	const {rate} = vProdshop?.vProduct || {}

	const {Text} = use.theme();
	const display = use.context.display();

	props.style = use.defaultStyle(props.style, localStyles.layout, [display, noCard]);

	return (
		<View hoverable {...props}>
			{/* disable hover on mobile */}
			{(render => display.select({
				mobile: render({hover: false}),
				default: render,
			}))(({hover}) => (
				<>
					{/*	picture */}
					<LoadingPlaceholder
						disabled={product || !loading}
						style={localStyles.image(display)}>
						{
							product &&
							<>
								<Image
									source={product?.gallery?.first}
									alt={product?.fullTitle}
									resizeMode="cover"
									style={styles.fit}
								/>

								{hover && <ImageHoverFilter/>}
							</>
						}
					</LoadingPlaceholder>


					<RNView style={localStyles.footer(display)}>
						{
							renderInfos ?
								((renderInfos instanceof Function) ? renderInfos(vProdshop) : renderInfos) :

								<>
									<RNView style={localStyles.infos(display)}>
										{/* title */}
										{(() => {
											let style = localStyles.title(display);
											if (hover && !display.is.mobile)
												style = ComponentUtils.style.concat(style, {textDecorationLine: styles.textDecorationLine.underline});

											return (
												<LoadingPlaceholder
													View={Animated.Text}
													numberOfLines={1}
													disabled={product || !loading}
													style={style}>
													{
														product?.title
														|| strings.invisibleChar // Force component to always take one line height
													}
												</LoadingPlaceholder>
											);
										})()}

										{/* subtitle */}
										<Text
											numberOfLines={1}
											style={localStyles.subtitle(display)}>
											{product?.subtitle}
										</Text>


										<RNView style={localStyles.rate(display)}>
											{
												(rate?.stars > 0) &&
												<>
													<StarsView
														stars={rate.stars}
														size={STARS_SIZE(display)}
														gap={display.select({
															mobile: 2.6,
															default: 4,
														})}
														useEyalStyle
														style={localStyles.stars}
													/>

													<Text
														numberOfLines={1}
														style={localStyles.people(display)}>
														{
															rate.numberOfVotes > 0 &&
															`${rate.numberOfVotes} avis`
														}
													</Text>
												</>
											}
										</RNView>
									</RNView>

									{
										!shop?.hiddenPrice && prodshop &&
										<ProdshopPrice
											prodshop={prodshop}
											fontSize={19}
											style={localStyles.price(display)}
										/>
									}
								</>
						}
					</RNView>
				</>
			))}
		</View>
	);
}

export default React.memo(ProductItem);

const ImageHoverFilter = new StaticComponent(
	<RNView style={[styles.fit, {backgroundColor: styles.color.white, opacity: .3}]}/>
);

const STARS_SIZE = display => display.is.mobile ? 8.4 : 13.2;

const localStyles = {
	layout: (display, noCard) => ({
		backgroundColor: styles.color.white,
		...(noCard ? {} : styles.newShadow(0, 3, 6, .16)),
		overflow: styles.overflow.hidden,
		width: display?.is.mobile ? 173 : 272,
	}),

	image: styles.static(
		{},
		{
			mobile: {height: 173},
			default: {height: 272},
		}
	),

	footer: styles.static(
		{
			minHeight: 95,
		},
		{
			mobile: {
				padding: 7,
			},
			default: {
				paddingVertical: 14,
				paddingLeft: 13,
				paddingRight: 10,
				flexDirection: styles.flexDirection.row,
			}
		}
	),

	infos: styles.static(
		{
			justifyContent: styles.justifyContent.spaceBetween,
		},
		{
			default: {
				flex: 1,
			},
			mobile: {flexGrow: 1},
		}
	),

	title: styles.static(
		{
			fontWeight: styles.fontWeight.bold,
		},
		{
			mobile: {fontSize: 15},
			default: {fontSize: 17},
		},
	),

	subtitle: styles.static(
		{
			color: "#504747",
			maxWidth: 150,
		},
		{
			mobile: {fontSize: 11},
			default: {fontSize: 14},
		},
	),

	price: styles.static(
		{},
		{
			default: {
				alignSelf: styles.alignSelf.center,
				marginLeft: 2,
			},
			mobile: {
				maxWidth: '100%',
				justifyContent: styles.justifyContent.flexEnd,
				flexDirection: styles.flexDirection.row,
				alignItems: styles.alignItems.flexEnd,
			}
		}
	),

	rate: styles.static(
		{
			flexDirection: styles.flexDirection.row,
			alignItems: styles.alignItems.center,
		},
		{
			mobile: {
				minHeight: 15,
			},
			default: {
				minHeight: STARS_SIZE(DisplayType.desktop),
			},
		}
	),

	stars: {
		marginRight: 7,
	},

	people: styles.static(
		{color: "#6c6c6c"},
		{
			mobile: {fontSize: 10},
			desktop: {fontSize: 12},
		}
	),
};
