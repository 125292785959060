import React from "react"
import use from "../hook";
import View from "./View";
import Text from "./Text";
import { fonts, styles } from "../res";
import moment from "moment";
import OrderStateView from "./OrderStateView";
import Color from "../library-js/utils/Color"
import Objects from "../library-js/utils/Objects";
import getResourcesForOrderState from "../library-js/res/getResourcesForOrderState";
import { isNil } from "ramda";

/**
 * @param {any}
 */
function OrderInfo({ order, displayOrderInfos = true, innerStyles, ...props }) {
	const currentState = order?.currentState?.state ?? order?.states?.[0]?.state;
	const display = getResourcesForOrderState(currentState);

	let { details: detailsStyle, state: stateStyles } = innerStyles || {};
	detailsStyle = use.defaultStyle(detailsStyle, localStyles.details);
	stateStyles = Objects.map(
		{ ...stateStyles, ...localStyles.orderState.inner },
		key => Object.assign(
			{},
			localStyles.orderState.inner[key],
			(stateStyles || {})[key],
		),
	);

	props.style = use.defaultStyle(props.style, localStyles.layout(display?.color()));
	return (
		<View {...props}>
			<View style={localStyles.header}>
				{
					displayOrderInfos &&
					<View style={localStyles.title.layout}>
						<Text style={localStyles.title.id}>
							{`Cde ${order.id}`}
						</Text>
						<Text style={localStyles.title.date}>
							{`${moment(order.creationDate).format('DD/MM/YYYY')}`}
						</Text>
					</View>
				}


				{
					!isNil(currentState)  &&
					<OrderStateView
						state={currentState}
						receptionMean={order.receptionMean}
						innerStyles={stateStyles}
						background={styles.color.transparent}
					/>
				}
			</View>

			<Text style={detailsStyle}>
				{display?.description(order.receptionMean)}
			</Text>
		</View>
	);
}

export default React.memo(OrderInfo);

const localStyles = {
	layout: (color) => ({
		paddingTop: 16,
		paddingHorizontal: 20,
		paddingBottom: 9,
		flexDirection: styles.flexDirection.column,
		borderRadius: 11,
		backgroundColor: new Color(color).fade(.9).toString(),
	}),

	header: {
		flexDirection: styles.flexDirection.row,
		justifyContent: styles.justifyContent.spaceBetween,
		alignItems: styles.alignItems.center,
	},

	orderState: {
		inner: {
			text: {
				flex: undefined, // cancel flex
				marginLeft: 10
			}
		}
	},

	title: {
		layout: {
			flexDirection: styles.flexDirection.column,
		},

		id: {
			font: fonts.Poppins,
			fontSize: 14,
			color: '#1e2432',
		},

		date: {
			font: fonts.Poppins,
			bold: true,
			fontSize: 11,
			color: '#acb1c0',
		},
	},

	details: {
		marginTop: 8,
		font: fonts.Poppins,
		fontSize: 12,
		color: '#1e2432',
	}

};
