import MemoryMapStorage from "./MemoryMapStorage"
import Objects from "../Objects"
import Debug from "../../Debug"
import Base from "../../class/Base"

const singletons = new Map();

export default class Cache {
	constructor(mapStorage, options) {
		// set storage
		this._storage = mapStorage || new MemoryMapStorage();

		// default options
		options = options || {version: undefined, refreshOnRead: false, defaultTime: null, maxTime: null};

		// set version
		this.version = options.version;
		delete options.version;


		// if a cache has already been instantiated for this storage, then return it
		let sameStorage = Array.from(singletons.keys()).find(storage => this._storage.equals(storage));
		if (sameStorage)
			return singletons.get(sameStorage);
		// otherwise set current cache for this storage
		singletons.set(this._storage, this);

		// set options
		this.options = options || {};
		if (!this.options.maxTime)
			this.options.maxTime = this.constructor.MAX_TIME;

		// timeouts
		this._timeouts = {};

		// init (load from storage to memory)
		this.initiating = this._init();
	}

	has(key) {
		// check directly in memory
		return (key in this._memory);
	}

	set(map, time) {
		// correct inputs
		time = time || this.options.defaultTime;
		if (this.options.maxTime)
			time = Math.min(time, this.options.maxTime);

		if (time > 0) {
			// set in memory for quick access
			let now = Date.now();
			let records = Objects.map(map, (key, value) => {
				let record = this._memory[key] = new Cache.Record(value, time);

				record.created = now; // same timeout each
				this._checkTimout(record);

				return record;
			});


			// store (return promise)
			return this._set(records);
		}

		// return promise to do nothing since time is not valid
		return Promise.resolve();
	}

	get(...keys) {
		if (this.options.refreshOnRead)
			this.refresh(keys);

		let results = {};

		keys.forEach(key => {
			let record = this._memory[key];
			if (record && !record.deprecated)
				results[key] = record.value;
		});

		return results;
	}

	get keys() {
		return Object.keys(this._memory);
	}

	refresh(keys, time) {
		// correct inputs
		keys = keys || [];
		if (time && this.options.maxTime)
			time = Math.min(time, this.options.maxTime);

		let now = Date.now();
		let records = Objects.retrieve(this._memory, keys);
		records = Objects.filter(records, (key, record) => {
			if (
				record
				&& !record.deprecated
				// condition below is true if time is not defined or is more than time left in record
				&& !(time < record.timeLeft)
			) {
				record.created = now;

				// if a time is defined, then set it
				if (time > 0)
					record.time = time;

				return true; // needs to be updated
			}
		});

		return this._set(records);
	}

	clean() {
		let deprecatedKeys = Object.keys(
			Objects.filter(this._memory, (key, record) => {
				if (!record.deprecated) // if not deprecated, check timeout
					this._checkTimout(record);

				return record.deprecated;
			})
		);

		return this.remove(...deprecatedKeys);
	}

	remove(...keys) {
		// remove from memory
		keys.forEach(key => {
			delete this._memory[key]
		});

		// remove from storage
		return this._remove(keys);
	}

	clear() {
		// clear memory
		this._memory = {};

		// clear timeouts
		Objects.forEach(this._timeouts, (expire, timeoutKey) => clearTimeout(timeoutKey));
		this._timeouts = {};

		// clear storage
		return this._clear();
	}

	destroy() {
		singletons.delete(this._storage);
	}


	// ---- private -----

	/**
	 * Load all entries in _memory.
	 * @private
	 */
	async _init() {
		try {
			let {META_KEY} = this.constructor;

			let keys = await this._storage.getKeys();
			keys.push(META_KEY);

			let results = await this._storage.get(...keys);
			let metaDatas = !results[META_KEY].error ? results[META_KEY].value : null;
			delete results[META_KEY];

			// check version
			if (metaDatas && metaDatas.version === this.version){
				this._memory = Objects.map(results, (key, {value, error}) => {
					if (error)
						throw error;
					return Cache.Record.from(value);
				});

				// clean expired entries, set timeouts for others
				await this.clean();
			}

			// if no meta datas has been found, or version has been changed, clear the cache for stability
			else {
				this._memory = {};
				this._storage.remove(...keys);

				// set new meta datas
				this._storage.set({
					[META_KEY] : {
						version: this.version
					}
				});
			}

			this.initiating = null; // no more initiating
		} catch (error) {
			Debug.assert(true, ["Error initializing cache", error], () => {
				this._storage = new MemoryMapStorage(); // replace storage with a stable one
				this._memory = {}; // create a new clean memory
				this.initiating = null; // no more initiating
			});
		}
	}

	/**
	 * Check if a timeout
	 * @private
	 */
	_checkTimout(record) {
		if (!(record && record.expire)) return;

		// a timeout is not set, then set a new one
		if (!record.deprecated && !this._timeouts[record.expire])
			this._timeouts[record.expire] = setTimeout(() => this.clean(), record.timeLeft);
	}

	async _set(records) {
		this._storage.set(records);
	}

	async _remove(keys) {
		this._storage.remove(...keys);
	}

	async _clear() {
		let keys = await this._storage.getKeys();
		return this._remove(keys);
	}
}
Cache.META_KEY = "__cache_meta_datas";
Cache.MAX_TIME = Date.MONTH;


Cache.Record = class CacheRecord extends Base.extendsWith({value: null}) {
	constructor(value, time) {
		super();

		this.value = value;
		this.created = Date.now();
		this.time = time; // default time
	}

	getValue() {
		let value = super.getValue();
		return JSON.parse(value);
	}

	setValue(value) {
		super.setValue(JSON.stringify(value));
	}

	get expire() {
		return this.created
			&& this.time
			&& (this.created + this.time);
	}

	get timeLeft() {
		return this.expire && this.expire - Date.now();
	}

	get deprecated() {
		return !this.expire || Date.now() > this.expire;
	}
};
Cache.Record.addProperties({
	time: Number,
	created: Number,
});
