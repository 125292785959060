import React from "react"
import use from "library-react/hook";
import View from "library-react/component/View/v2";
import {styles} from "@main/res";
import ProdshopsView from "./ProdshopsView";
import BrandView from "../../../component/BrandView";
import {screens} from "../../../links";
import Portal from "../../../../library-react/component/Portal";

export default function ProdshopBrandView({brand, prodshops, onBrandSelected, ...props}) {
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<View {...props}>
			<Portal
				to={screens.brand}
				params={{name: brand.name}}>
				<BrandView brand={brand} style={localStyles.brand}/>
			</Portal>
			<ProdshopsView prodshops={prodshops}/>
		</View>
	);
}

const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
		overflow: styles.overflow.hidden,
	},
	brand: {
		flex: 1,
		marginRight: 20,
	},
	text: {
		fontSize: 16,
		maxWidth: 560,
		textAlign: styles.textAlign.center,
	}
};
