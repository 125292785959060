import { ApolloClient, ApolloProvider, from, HttpLink, InMemoryCache } from "@apollo/client";
import { offsetLimitPagination } from "@apollo/client/utilities";
import { setContext } from "apollo-link-context";
import { complement, isNil, pipe, prop, when, omit, keys } from "ramda";
import React from "react";
import AuthManager from "../../AuthManager";
import Platform from "../../Platform";
import safeParseJson from "../../utils/safeParseJson";
import ModelAdapterLink from "./ModelAdapterLink";

export * from '@apollo/client';

const listKeyArgs = ['order_by', 'where'];
const defaultList = offsetLimitPagination(listKeyArgs);

function derivedField(field, derive, noWarning) {
	return function (_, { fieldName, readField }) {
		const value = readField(field);
		if (isNil(value) && !noWarning)
			console.warn(`Apollo cache tries to derive ${fieldName} from ${field} but it's undefined.`);
		return derive(value);
	};
}

const whenDefined = when(complement(isNil));

const openHoursList = derivedField('openHours', whenDefined(safeParseJson));
const paymentMeansList = derivedField('paymentMeans', whenDefined(safeParseJson));

export default function createGraphQLClient() {
	const cache = new InMemoryCache({
		typePolicies: {
			query: {
				queryType: true,
				fields: {
					order_v2: offsetLimitPagination(["where", "order_by"])
				}
			},

			shop: {
				fields: {
					logoUrl: derivedField('logo', whenDefined(
						pipe(safeParseJson, prop('url'))
					)),

					openHoursList,
					paymentMeansList,

					reservationsWithCurrentState: defaultList,
				}
			},

			product: {
				fields: {
					galleryList: derivedField('gallery', whenDefined(safeParseJson)),
					sizesList: derivedField('sizes', whenDefined(safeParseJson)),
				}
			},

			user: {
				fields: {
					reservations: {
						keyArgs: pipe(omit(['limit', 'offset']), keys),
						merge(...params) {
							console.log('merge ', {
								existing: params[0]?.length,
								incoming: params[1]?.length,
							});
							return defaultList.merge(...params);
						}
					},
				}
			},

			reservationSpan: {
				fields: { openHoursList }
			}
		}
	})

	const link = from([
		new ModelAdapterLink,

		setContext(async (_, context) => {
			await AuthManager.onceReady;

			if (AuthManager.user) {
				let token = AuthManager.token;

				if (!tokenHasBeenRefreshed) {
					token = await AuthManager.getToken(true);
					tokenHasBeenRefreshed = true;
				}

				context.headers = Object.assign({},
					context.headers,
					{ Authorization: `Bearer ${token}` },
					Platform.is.retailer ? { "x-hasura-role": "retailer" } : undefined
				);
			}

			return context;
		}),

		new HttpLink({ uri: "https://endless-earwig-71.hasura.app/v1/graphql" }),
	]);

	const client = new ApolloClient({ cache, link });

	Object.assign(client, apolloMethods);

	function GraphQLProvider({ children }) {
		return (
			<ApolloProvider client={client}>
				{children}
			</ApolloProvider>
		);
	}

	GraphQLProvider.client = client;

	return GraphQLProvider;
};

let tokenHasBeenRefreshed = false;

const apolloMethods = (function() {
	function handleStrictly(result) {
		const error = result.error || result.errors?.[0];
		if (error) throw error;
		return result.data;
	}

	return {
		strictQuery(...params) {
			return this.query(...params)
				.then(handleStrictly);
		},
		strictMutate(...params) {
			return this.mutate(...params)
				.then(handleStrictly);
		}
	};
}());


// --- support for js ---
export const LegacyOrderState = {
	processingPrepayment: 5,
	prepaymentFailed: 6,
	preparing: 4,
	ready: 2,
	processingPayment: 1,
	paymentFailed: -2,
	reception: 7,
	finished: 0,
	canceled: -1,
};
export const allPaymentMeans = ["ONLINE", "CASH","CARD","CHECK","RESTAURANT_TICKET","TRANSFERT","ELSE"];