import React from "react"
import {View as RNView} from "react-native"
import use from "../../../hook";
import {styles} from "../../../res";
import CheckoutStateContext from "./CheckoutStateContext";
import CheckoutInput from "../component/input/CheckoutInput";
import ContactInfos from "../../../library-js/app/model/entity/ContactInfos";
import screens from "./screens";
import CheckoutFrameContext from "./CheckoutFrameContext";


export default function ContactUI({navigation, ...props}) {
	const {
		receptionMean,
		instructions, setInstructions,
		contactInfos, setContactInfos,
		contactLoadable,
	} = use.context(CheckoutStateContext);

	const [submitted, setSubmittedTo] = use.state.bool();

	const instructionsContainer = use.memo({ // no need for a state to store instructions
		value: instructions || "",
		set: value => instructionsContainer.value = value
	});

	const contactInfosForm = use.memo(() => {
		const form = contactInfos?.copy()
			|| contactLoadable?.value?.copy()
			|| new ContactInfos();
		form.bindSetters();
		return form;
	});


	function submit() {
		setSubmittedTo(true);
		setInstructions(instructionsContainer.value);

		const invalidFields = contactInfosForm.getInvalidFields(receptionMean);
		if (!invalidFields.length) {
			setContactInfos(contactInfosForm);
			setTimeout(() => navigation.push(screens.summary));
		} else
			console.error('Invalid fields: ', ...invalidFields);
	}

	const FrameControl = use.context(CheckoutFrameContext);
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<FrameControl
			step={3}
			title={`INFORMATIONS DE CONTACT`}
			submit={submit}>

			<CheckoutInput
				label={`Instructions concernant votre commande`}
				multiline
				defaultValue={instructions}
				onValueChanged={instructionsContainer.set}
				inputStyle={localStyles.instructionsInput}
				textAlignVertical="top"
				style={localStyles.instructions}
			/>

			<RNView style={localStyles.inputsRow}>
				<CheckoutInput label={`Nom`}
							   validate
							   submitted={submitted}
							   defaultValue={contactInfosForm.lastName}
							   onValueChanged={contactInfosForm.setLastName}
							   style={localStyles.commonInput}
				/>
				<CheckoutInput label={`Prénom`}
							   validate
							   submitted={submitted}
							   defaultValue={contactInfosForm.firstName}
							   onValueChanged={contactInfosForm.setFirstName}
							   style={localStyles.commonInput}
				/>
			</RNView>

			<RNView style={localStyles.inputsRow}>
				<CheckoutInput label={`Adresse`}
							   validate={receptionMean?.is.delivery}
							   submitted={submitted}
							   defaultValue={contactInfosForm.address}
							   onValueChanged={contactInfosForm.setAddress}
							   style={localStyles.commonInput}
				/>
				<CheckoutInput label={`Tel`}
							   validate
							   submitted={submitted}
							   defaultValue={contactInfosForm.phone}
							   onValueChanged={contactInfosForm.setPhone}
							   style={localStyles.commonInput}
				/>
			</RNView>
		</FrameControl>

	);
}

const localStyles = {
	subtitle: {
		marginBottom: 60,
		fontSize: 12,
		color: '#777777',
		alignSelf: styles.alignSelf.center,
		maxWidth: 390,
		width: '100%',
	},

	instructions: {
		marginBottom: 15,
	},

	instructionsInput: {
		height: 170,
	},

	inputsRow: {
		flexDirection: styles.flexDirection.row,
		justifyContent: styles.justifyContent.spaceBetween,
		marginBottom: 30,
	},

	commonInput: {
		width: '47%',
	},
};
