import React from "react"
import use from "library-react/hook"
import responsive from "@main/hoc/responsive";
import mobile from "../CatalogSampleView/mobile"
import desktop from "../CatalogSampleView/desktop"
import Search from "library-js/Search";
import CatalogSection from "library-js/app/model/entity/CatalogSection";
import {screens} from "@main/links";

function CatalogSectionView({loading, onItemsLoaded, ...props}, {Component}) {
	const {section} = props;

	// products iterator
	const {vShop} = use.context.infos();
	const iterator = props.iterator = use.infiniteIterator(
		() => {
			if (section) {
				const iterator = Search.index.prodshop.getIterator({
					available: true,
					inShop: true,
					shopId: vShop.id,
					sections: [section],
				});
				if (onItemsLoaded)
					iterator.onResolveResponse = response => {
						if (response.ok)
							onItemsLoaded(response.content)
						return response;
					}

				return iterator;
			}
		},
		[section, onItemsLoaded],
	);

	props.title = props.title || (
		section instanceof CatalogSection ?
			section.path.last :
			section?.trim().split('/').filter(Boolean).last
	)?.toUpperCase();

	loading = props.loading = iterator?.loading || (!(iterator?.items.length > 0) && loading);

	const content = props.content = (
		(iterator?.items.length > 0 || iterator?.end) ? // content ready
			iterator.items.slice(0, Component.MAX_ITEMS) // products
			: (loading && Array.range(Component.MAX_ITEMS, null)) // loading placeholder
	);

	props.portalProps = use.memo({
			to: screens.catalog,
			params: {section: section?.toString()}
		},
		[section],
	);

	const facet = iterator?.facets?.sections;
	props.numberOfItemsLeft = facet && Math.max(facet[section] - Component.MAX_ITEMS, 0);

	return (
		(content?.length > 0) &&
		<Component {...props} />
	);
}


export default React.memo(
	responsive({mobile, default: desktop},
		CatalogSectionView,
	)
);
