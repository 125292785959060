import React from "react"
import {View as RNView} from "react-native"
import use from "library-react/hook"
import {styles} from "../../res"
import SimpleButton from "library-react/component/SimpleButton";
import Portal from "library-react/component/Portal";
import {screens} from "@main/links";
import isEligibleForReservations from "library-js/app/isEligibleForReservations";

function AccountTabs({onAccountClicked, ...props}) {
	const {Text} = use.theme();
	const display = use.context.display();
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<RNView {...props}>
			<SimpleButton
				border
				filled
				onPress={onAccountClicked}
				colors={localStyles.colors}
				style={localStyles.tab}>
				<Text style={localStyles.text}>
					{`MON COMPTE`}
				</Text>
			</SimpleButton>

			<Portal to={screens.orderList}>
				<SimpleButton
					border
					toggleFilledOnHover
					colors={localStyles.colors}
					style={[localStyles.tab, display.is.mobile && {flexGrow: 1, maxWidth: 230}]}>
					<Text style={localStyles.text}>
						{`MES COMMANDES`}
					</Text>
				</SimpleButton>
			</Portal>

			{
				isEligibleForReservations(vShop.shop.categoryId) &&
				<Portal to={screens.reservationList}>
					<SimpleButton
						border
						toggleFilledOnHover
						colors={localStyles.colors}
						style={[localStyles.tab, display.is.mobile && {flexGrow: 1, maxWidth: 230}]}>
						<Text style={localStyles.text}>
							{`MES RESERVATIONS`}
						</Text>
					</SimpleButton>
				</Portal>
			}
		</RNView>
	);
}

export default React.memo(AccountTabs);


const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
	},

	tab: {
		marginRight: 27,
		paddingHorizontal: 20,
		paddingVertical: 10,
	},

	text: {
		bold: true,
	},

	colors: {
		primary: styles.color.black,
		secondary: styles.color.white,
	},
};
