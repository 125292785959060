import Base from "../../../../class/Base"
import {MangoPayAddressField} from "./Address"
import BankAccountType from "./BankAccountType";

export default class BankAccount extends Base {}
BankAccount.addProperties({
	Type: BankAccountType,
	OwnerAddress: MangoPayAddressField,
	OwnerName: String,
	Tag: String,
	CreationDate: Number,
	UserId: String,
	Active: Boolean,
});

