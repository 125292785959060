import React from "react";
import Spinner from "@main/component/Spinner";
import {styles} from "@main/res";
import use from "library-react/hook";

export default function OrderListFooter({iterator}) {
	const {Text} = use.theme();
	return (
		!iterator.end ? (
				!iterator.failed &&
				<Spinner size={20} />
			) :
			!iterator.items.length &&
			<Text style={{fontSize: 20}}>
				{`Vous n'avez passé aucune commande.`}
			</Text>
	);
}

OrderListFooter.WRAPPER_STYLE = {
	paddingVertical: 30,
	flexGrow: 1,
	...styles.center,
}
