import Base from "../../../../class/Base"

export default class Rate extends Base.extendsWith({stars : Number}) {
	constructor(){
		super();

		this.stars = 0;
		this.numberOfVotes = 0;
	}

	getStars(){
		let value = super.getStars();

		// display 2 decimal if has decimals. None otherwise.
		let hasDecimal = value % 1;
		return value && Number(value.toFixed(hasDecimal ? 1 : 0));
	}
}
Rate.addProperties({
	numberOfVotes : Number,
	star1: Number,
	star2: Number,
	star3: Number,
	star4: Number,
	star5: Number,
});
