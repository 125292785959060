import {Base} from "../../../../../class"
import VCollection from "./VCollection"
import VShop from "../VShop"
import VProdshop from "../VProdshop"

export default class VShopCollection extends Base {
	get id(){
		return this.collection && this.collection.id;
	}

	get collection(){
		return this.vCollection && this.vCollection.collection;
	}

	get shop(){
		return this.vShop && this.vShop.shop;
	}
}
VShopCollection.addProperties({
	vCollection: VCollection,
	vShop: VShop,
});

VShopCollection.Item = class VShopCollectionItem extends Base {
	get collection(){
		return this.item && this.item.collection;
	}
};
VShopCollection.Item.addProperties({
	item: VCollection.Item,
	vProdshop: VProdshop,
});
