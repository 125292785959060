import is from "../utils/is"
import Debug from "../Debug"
import Objects from "../utils/Objects"
import clone from "../utils/clone"


let canInstantiate = false;

/**
 * Abstract enum.
 * @see Enum.make
 */
export default class Enum /*Must not extends Base in case Base.from would not work*/ {
	/**
	 * @param {*} values
	 * @return {library-js.EnumClass.}
	 */
	constructor(...values) {
		if (values.length)  // called from outside to build new enums
			return this.constructor.make(...values);

		// called from a child class
		Debug.assert(!canInstantiate, "Enum class cannot be instantiated.");

		// bind some methods
		['select', 'run'].forEach(fn =>
			this[fn] = this[fn].bind(this));
	}

	toJSON() {
		return this.value;
	}

	toString() {
		return this.key;
	}

	selectKey(map) {
		Debug.assert(!is(map, Object), "Enum.selectKey needs an object as parameter.");

		if (this.key in map)
			return this.key;

		const domainKey = Objects.findKey(this.constructor.domains, (key, values) => values.includes(this) && (key in map));
		if (domainKey)
			return domainKey;

		if ("default" in map)
			return "default";
	}

	select(map) {
		Debug.assert(!is(map, Object), "Enum.select needs an object as parameter.");

		const keySelection = this.selectKey(map);
		if (is(keySelection))
			return map[keySelection];
	}

	run(map) {
		Debug.assert(!is(map, Object), "Enum.run needs an object as parameter.");

		let keySelection = this.selectKey(map);
		if (is(keySelection)) {
			Debug.assert(!is(map[keySelection], Function), `${keySelection} is not a function`);
			return map[keySelection](this);
		}
	}

	get is() {
		const Class = this.constructor;
		return {
			...Objects.map(Class.domains, (key, domain) => domain.includes(this)),
			...Class.map(key => this.key === key),
		};
	}

	// ------- static ------------

	/**
	 * Build an enum class. The builded class is {@link object.freeze}d, so the user won't be able to add any new value to it.
	 * @param specs Object containing all the possible values of the enum.
	 * @return {library-js.EnumClass.} The built enum, making its constructor private (impossible to instance new values), and add useful methods:
	 * <ul>
	 *     <li>values() : returns an array of all the possible values of the enum.</li>
	 *     <li>contains(value) : check if the parameter is an enum value.</li>
	 *     <li>from(value) : return the enum corresponding to the value of the parameter.</li>
	 * </ul>
	 */
	static make(...specs) {

		if (specs.length === 1 && is(specs[0], Object))
			specs = specs[0];

		// check parameter
		Debug.assert(!(is(specs, Object) || is(specs, Array)), "Cannot make an enum with a non object parameter.");

		let values;

		// build the child enum class
		/**
		 * @typedef {EnumClass.}
		 * @memberOf library-js
		 * @name EnumClass
		 */
		const EnumClass = class EnumClass extends Enum {
			constructor(key, value) {
				super();

				// define a read only properties
				Object.defineProperties(this, {
					key: {
						configurable: true,
						enumerable: true,
						value: key,
					},

					value: {
						configurable: true,
						enumerable: true,
						value: value,
					}
				});
			}

			static get values() {
				return Object.values(values);
			}

			static forEach(doOnEach) {
				Objects.forEach(values, doOnEach);
			}

			static map(func) {
				return Objects.map(values, func);
			}

			/**
			 * @deprecated
			 */
			static contains(value) {
				return this.includes(value);
			}

			static includes(value) {
				return this.values.includes(value);
			}

			static from(value) {
				return Object.values(values)
					.find(enumValue =>
						enumValue === value
						|| enumValue.value == value
						|| enumValue.key == value
					);
			}

			static keyOf(param) {
				return Objects.findKey(values, (key, enumValue) => (enumValue === param || enumValue.value === param));
			}
		};

		Object.defineProperties(EnumClass, {
			domains: {
				configurable: true,
				value: {}
			}
		});

		// instantiate all values

		canInstantiate = true;
		// for array
		if (is(specs, Array))
			values = specs.toObject(key => key, key => new EnumClass(key, key));

		else // for object
			values = Objects.map(specs, (key, value) => new EnumClass(key, value));

		canInstantiate = false;

		// add all values to the enum class
		Object.assign(EnumClass, values); // prevent enums from being changed.

		return EnumClass;
	}

	/**
	 * @deprecated
	 */
	static runOn(enumValue, cases) {
		if (!(enumValue instanceof this))
			enumValue = this.from(enumValue);

		if (enumValue instanceof this)
			return enumValue.run(cases);

		return (cases.default instanceof Function ? cases.default : () => cases.default)();
	}

	static select(value, options) {
		return (value instanceof this) ? value.select(options)
			: options?.default;
	}

	static run(value, options) {
		if (!(value instanceof this))
			value = this.from(value);

		return (value instanceof this) ?
			value.run(options) :
			options?.default?.();
	}
}

clone.unclonables.push(Enum);
