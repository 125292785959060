import React from "react"
import {ScrollView} from 'react-native'
import use from "library-react/hook";
import ProductGrid from "@main/component/ProductGrid";
import {styles} from "@main/res";
import Button from "@main/component/Button";
import BrandView from "@main/component/BrandView";

export default function MobileUI({brand, iterator, onSelected, ...props}) {
	const {Text, Spinner} = use.theme();

	props.style = use.defaultStyle(props.style, localStyles.layout);
	props.contentContainerStyle = use.defaultStyle(props.contentContainerStyle, localStyles.content);

	return (
		<ScrollView {...props} >
			<BrandView
				brand={brand}
				logoStyle={localStyles.logo}
				seeMore
				style={localStyles.title} />

			{
				iterator &&
				<ProductGrid
					items={iterator.items}
					onSelected={onSelected}
					style={localStyles.items}/>
			}

			{
				Boolean(iterator?.items?.length) && (
					iterator.loading ? <Spinner style={localStyles.button}/> :
						!iterator.end &&
						<Button
							onPress={iterator.load}
							style={localStyles.button}>
							<Text style={localStyles.text}>
								{`Voir plus`}
							</Text>
						</Button>
				)
			}
		</ScrollView>
	);
}

const localStyles = {
	layout: {},

	logo: {
		height: 100,
	},

	content: {
		alignItems: styles.alignItems.center,
		marginHorizontal: 20,
		marginVertical: 15,
	},

	title: {
		alignSelf: styles.alignSelf.stretch,
		paddingVertical: 10,
	},

	items: {
		marginTop: 50,
	},

	button: {
		alignSelf: styles.alignSelf.center,
		paddingHorizontal: 100,
		paddingVertical: 19,
		marginBottom: 46,
		marginTop: 10,
	},

	text: {
		fontSize: 20,
	},
};
