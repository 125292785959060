import React from "react";
import {View as RNView} from "react-native";
import use from "library-react/hook";
import Pipe from "library-react/utils/Pipe";
import ScrollViewRecorder from "library-react/plugin/ScrollViewRecorder";
import PipedOut from "library-react/component/PipedOut";
import ScrollView from "library-react/component/ScrollView";
import {strings, styles} from "@main/res";
import ProductItem from "@main/component/ProductItem";
import StaticComponent from "library-react/component/StaticComponent";
import Spinner from "@main/component/Spinner";
import CatalogSectionView from "../CatalogSectionView";
import useSectionsLoadable from "@main/hook/useSectionsLoadable";
import Portal from "library-react/component/Portal";
import {screens} from "@main/links";

export default function CatalogMobile({section, productsIterator, header, ...props}) {
	const sectionsLoadable = useSectionsLoadable();
	const {/** @type {CatalogCupboard} */ value: cupboard} = sectionsLoadable;

	const instances = use.instances({scroll: null, content: null, indexes: null});
	const willJumpTo = (index) =>
		() => {
			const children = instances?.content
				?.getInnerViewNode()
				.children[index];
			const offset = children?.offsetTop;

			const headerElement = header ? instances?.scroll.getInnerViewNode().children[0] : null;
			const headerHeight = headerElement?.getBoundingClientRect().height || 0;
			instances?.scroll.scrollTo({y: offset + headerHeight, animated: true});
		};

	const sections = use.memo(() => cupboard?.getChildrenOf(section), [cupboard, section]);
	const sectionsToDisplay = (
		sections // child sections
		|| (sectionsLoadable.loading ? LOADINGS : null) // or loading
	);

	const allProductsIndex = sectionsToDisplay?.length || 0; // index of ALL section

	const sectionSelectedPipe = use.memo(() => new Pipe().in(sections?.first), [sections]);
	const scrollRecorder = use.memo(() => new ScrollViewRecorder());
	if (sections)
		scrollRecorder.onScroll = () => {
			let {y} = scrollRecorder.offset;
			y += 50;

			const {content, indexes} = instances;
			if (content) {
				const children = Array.from(content.getInnerViewNode().children)
					.slice(0, allProductsIndex + 1);

				const index = children.findIndex(childElement => {
					const childY = childElement.offsetTop;
					const childBottomY = childY + childElement.getBoundingClientRect().height;
					return y <= childBottomY;
				});

				sectionSelectedPipe.in(sections[index]);

				const selected = indexes?.getInnerViewNode()?.children[index];
				const offset = selected?.offsetLeft;
				indexes?.scrollTo({x: offset, animated: true});
			}
		};

	const {Text, ErrorView} = use.theme();

	props.style = use.defaultStyle(props.style, localStyles.layout);
	props.stickyHeaderIndices = props.stickyHeaderIndices || [1]; // Section selection is sticky
	props.ref = instances.set.scroll;

	props.onEndReached = productsIterator?.load;

	return (
		<ScrollView
			{...props}
			{...scrollRecorder.sensors}>

			{header}

			{/* sections */}
			{
				(!sections || sections.length > 0) ?
					<PipedOut
						from={sectionSelectedPipe}
						dependencies={[sections]}>
						{(sectionSelected) => (
							<ScrollView
								ref={instances.set.indexes}
								horizontal
								contentContainerStyle={localStyles.sectionsIndex.content}
								style={localStyles.sectionsIndex.scroll}>
								{
									sections?.map((section, index) => (
										<Text
											key={section.name}
											onPress={willJumpTo(index)}
											style={localStyles.sectionLink(sectionSelected?.name === section.name)}>
											{section.path.last.toUpperCase()}
										</Text>
									))
								}


								<Text
									onPress={willJumpTo(allProductsIndex)}
									style={localStyles.sectionLink(!sectionSelected)}>
									{(sections?.length > 0) ? `TOUT` : strings.invisibleChar}
								</Text>
							</ScrollView>
						)}
					</PipedOut> :
					<RNView style={{height: 20}}/> // Margin if there is not a section selector
			}

			{/* content */}
			<ScrollView
				ref={instances.set.content}
				contentContainerStyle={localStyles.content.container}
				style={localStyles.content.scroll}>
				{
					sectionsToDisplay?.map((section, index) =>
						<CatalogSectionView
							key={index}
							section={section}
							loading
							style={localStyles.catalogSection}
						/>
					)
					|| <ErrorView retry={sectionsLoadable.load}/>
				}

				{
					productsIterator &&
					<RNView style={localStyles.products}>
						{
							(sections?.length > 0) &&
							<Text
								numberOfLines={1}
								style={localStyles.allTitle}>
								{`TOUT`}
							</Text>
						}

						{
							productsIterator.items.map(item =>
								<Portal
									key={item.product.id}
									to={screens.product}
									params={{id: item.product.id}}>
									<ProductItem
										vProdshop={item}
										style={localStyles.product}
									/>
								</Portal>
							)
						}

						{
							!productsIterator.end &&
							<EndSpinner/>
						}
					</RNView>
				}
			</ScrollView>
		</ScrollView>
	);
};

const LOADINGS = [null];
const EndSpinner = new StaticComponent(
	<Spinner style={{marginVertical: 20, alignSelf: styles.alignSelf.center, width: "100%"}}/>
);


const localStyles = {
	layout: {
		flex: 1,
		backgroundColor: styles.color.white,
	},

	sectionsIndex: {
		scroll: {
			backgroundColor: "#f7f7f7",
			flexGrow: 0,
			marginTop: -1,
		},

		content: {
			justifyContent: styles.justifyContent.center,
			flexGrow: 1,
		}
	},

	sectionLink: styles.static.bool(
		{
			fontSize: 14,
			paddingHorizontal: 10,
			paddingVertical: 16,
		},
		{
			bold: true,
		}
	),

	content: {
		scroll: {
			flex: 1,
		},

		container: {
			width: 380,
			alignSelf: styles.alignSelf.center,
			paddingBottom: 32,
		}
	},

	catalogSection: {
		paddingTop: 20
	},

	allTitle: {
		paddingBottom: 8.5,
		borderBottomWidth: 1,
		borderBottomColor: styles.color.lightgrey,
		paddingTop: 20,
		marginTop: 16,
		fontSize: 16,
		bold: true,
		width: "100%",
	},

	products: {
		flexDirection: styles.flexDirection.row,
		justifyContent: styles.justifyContent.spaceBetween,
		flexWrap: styles.flexWrap.wrap,
	},

	product: {
		marginTop: 16,
	},
};
