import React, {useContext, useState} from "react";
import useSyncEffect from "../hook/useSyncEffect";
import useEffect from "../hook/useEffect";
import {curry, pipe} from "ramda";

export default function useAppWidthLevel(levels = []) {
	const appWidth = useContext(AppWidthContext);
	let [currentLevel, setCurrentLevel] = useState(appWidth.value);

	// correct levels
	{
		if (!(levels instanceof Array))
			levels = [];
		levels = levels.map(Number).filter(level => level > 0);
		if (levels.last !== Infinity)
			levels.push(Infinity);
	}

	// correct current level (in case levels array changed)
	{
		const correctLevel = currentLevel !== undefined ?
			calculateLevel(levels, appWidth.value)
			: undefined;

		useSyncEffect(() => {
			if (correctLevel !== currentLevel)
				setCurrentLevel(currentLevel = correctLevel);
		}, [currentLevel, correctLevel]);
	}

	// listen app width
	useEffect(() =>
			appWidth.listen(newWidth => {
				const newLevel = calculateLevel(levels, newWidth);
				if (newLevel !== currentLevel)
					setCurrentLevel(newLevel);
			}),
		[appWidth, currentLevel, ...levels]
	);

	return [currentLevel, /*current width*/ appWidth.value];
}

export const AppWidthContext = React.createContext();

const calculateLevel = curry((levels, width) => levels.findIndex(level => level > width));
