import React from "react"
import use from "library-react/hook"
import {styles} from "../../../res"
import AbstractChatFragment from "library-react/fragment/chat/ChatFragment"
import {Response} from "library-js/app/Server";
import Interlocutor from "library-react/fragment/chat/Interlocutor";
import {useChatControls} from "@main/App/Frame/Chat";
import SuggestedAttachment from "@main/App/Frame/Chat/SuggestedAttachment";

function ChatUI({...props}, refProp) {
	const display = use.context.display();
	const {vShop} = use.context.infos();
	const loadInterlocutor = use.callback(() => new Response(Response.Status.Success, Interlocutor.fromVShop(vShop)));


	const {suggestedAttachment} = useChatControls() || {};

	const instances = use.instances({
		chat: instance => {
			refProp?.(instance);
		}
	});
	const onAcceptSuggestedAttachment = use.callback(
		() => instances.chat?.setAttachment(suggestedAttachment),
		[suggestedAttachment]
	);
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<>
			<AbstractChatFragment
				ref={instances.set.chat}
				id={vShop.id}
				shop={vShop.shop}
				hidePrice={vShop.shop.hiddenPrice}
				selectProduct={display.is.mobile}
				loadInterlocutor={loadInterlocutor}
				noOrderTab
				{...props}
			/>
			{
				suggestedAttachment &&
				<SuggestedAttachment value={suggestedAttachment}
									 onAcceptSuggestedAttachment={onAcceptSuggestedAttachment}
				/>

			}
		</>
	);
}

export default React.memo(React.forwardRef(ChatUI));

const localStyles = {
	layout: {
		backgroundColor: styles.color.white,
	},
};
