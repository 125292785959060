import React from "react";
import use from "library-react/hook";
import MobileUI from "./mobile";
import DesktopUI from "./desktop";
import delay from "library-js/utils/function/delay";

export default function SearchBar({...props}) {
	const display = use.context.display();

	const instances = use.instances({
		input: delay(100, input => input?.focus())
	});

	props.clear = () => {
		instances.input?.clear();
		instances.input?.focus();
	};

	props.inputRef = instances.set.input;

	return (
		display.is.mobile ?
			<MobileUI {...props}/> :
			<DesktopUI  {...props}/>
	);
}
