import React from "react"
import {ActivityIndicator} from "react-native"
import use from "library-react/hook";

export default function Spinner({...props}) {
	const display = use.context.display();

	if (!props.size)
		props.size = display.is.mobile ? 30 : 60;

	const {size} = props;
	props.style = use.defaultStyle(props.style, {minHeight: size, minWidth: size}, [size]);

	return <ActivityIndicator {...props}/>;
}
