import Entity from "../Entity";
import {is} from "../../../../utils";
import latinize from 'latinize';

export default class CatalogSection extends Entity.extendsWith({name: String, path: Array}) {
	constructor(path) {
		super();

		if (is(path, String))
			this.name = path;
		else
			this.path = (is(path, Array) && path) || [];
	}

	get parentName() {
		return this.path.length > 1 ?
			this.path.slice(0, this.path.length - 1)
			: null;
	}

	get title(){
		return this.path.last;
	}

	set title(title){
		this.path = this.path.slice(0, -1).concat(title);
	}

	getName() {
		return this.path?.join('/') || "";
	}

	setName(name) {
		this.path = name?.split('/') || [];
	}

	setPath(path){
		return super.setPath(path?.map(parseName));
	}

	toString() {
		return latinize(this.name);
	}

	get fullPath(){
		return this.path.map((_, index, path) => path.slice(0, index + 1).join('/'));
	}

	toJSON() {
		const json = super.toJSON();
		// replace path with name
		json.name = this.name;
		delete json.path;

		return json;
	}

	get ascendants(){
		return this.cupboard?.getAscendantsOf(this);
	}

	get descendants(){
		return this.cupboard?.getDescendantsOf(this);
	}

	getDependents(upward){
		return upward ? this.ascendants : this.descendants;
	}
}

CatalogSection.addProperties({
	index: Number,
	shopId: Number,
	quantity: Number,
	// cupboard: CatalogCupboard
});


function parseName(name){
	return name?.trim().toLowerCase() || "";
}
CatalogSection.parseName = parseName;
