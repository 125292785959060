import images from "library-react/res/image"
export default images;

images.success = require("./success.gif");
images.fail = require("./fail.gif");
images.spinner = require("./spinner.gif");
images.google = require("./google.png");
images.facebook = require("./facebook.png");
images.apple = require("./apple.png");
images.logoWithName = require("./logoWithName.png");
images.byShopinzon = require("./byShopinzon.png");
images.byShopinzonWhite = require("./byShopinzonWhite.png");
images.supportedByRegion = require("./supported-by-Region.png");
