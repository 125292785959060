
import Base from "../../class/Base";
import CatalogSection from "./entity/CatalogSection";
import willSafeSort from "../../utils/function/willSafeSort";
import { append, concat, constructN, defaultTo, filter, flatten, is as isA, map, not, pipe, prop, propEq, startsWith, uniqBy, unless, without } from "ramda";
import is from "../../utils/is";
import assign from "../../utils/function/assign";

/**
 * Utility class to manage {@link CatalogSection}s.
 */
export default class CatalogCupboard extends Base {
	constructor(sections) {
		super();

		["getSection", "add", "update", "delete"]
			.forEach(methodName => this[methodName] = this[methodName].bind(this));
		this.sections = [];

		if (sections)
			this.add(sections);
	}

	add(sections) {
		// should not be used
		if (!(sections instanceof Array))
			sections = [sections];

		sections = sections.map(section => (section instanceof CatalogSection) ? section : new CatalogSection(section))
			.filter(section => !this.getSection(section.name)); // remove already existing

		const newCatalog = constructN(1, CatalogSection);
		this.sections = pipe(
			map(unless(isA(CatalogSection), newCatalog)),
			concat(this.sections),
		)(sections);
	}

	update(updatedSection, oldSection) {
		const index = oldSection ? this.sections.indexOf(oldSection) :
			this.sections.findIndex(section => section.id === updatedSection.id);

		if (index >= 0) {
			const { descendants } = oldSection;

			this.sections = pipe(
				without([oldSection, ...descendants]),
				append(updatedSection),
				// replace new path in all sub-sections
				concat(descendants.map(section => {
					const pathEnd = section.path.slice(oldSection.path.length);
					const path = updatedSection.path.concat(pathEnd);
					return section.updateACopy({ path });
				}))
			)(this.sections);

			return true;
		}

		return false;
	}

	delete(deletedSection) {
		// remove all it and its children
		this.sections = this.sections.filter(
			pipe(prop('path'), startsWith(deletedSection.path), not)
		);
	}

	getRootSections() {
		return this.sections.filter(section => section.path.length === 1)
			.sort(sort);
	}

	getChildrenOf(section) {
		if (!section)
			return this.getRootSections();

		return this.sections.filter(child => child.name.startsWith(`${section.name}/`))
			.sort(sort)
	}

	getDescendantsOf(section) {
		if (is.primitive(section))
			section = this.getSection(section);

		if (section) {
			const { name } = section;
			return this.sections.filter(pipe(nameProp, startsWith(name + '/')));
		}
	}

	getAscendantsOf(section) {
		if (is.primitive(section))
			section = this.getSection(section);

		return section.fullPath.slice(0, -1).map(this.getSection);
	}

	getParentOf(section) {
		if (!section?.path)
			return;

		return this.sections.find(sectionIn => section.path.startsWith(sectionIn.path));
	}

	getSection(name) {
		const path = (
			isA(String, name) ? name.trim().split('/') :
				(name instanceof Array) ? name :
					[]
		)
			.flat()
			.filter(Boolean);

		return this.sections.find(propEq('path', path));
	}

	getAll(sorted = false) {
		if (sorted)
			return this.getRootSections()
				.map(root => [root].concat(this.getChildrenOf(root)))
				.flat();

		return this.sections.slice();
	}

	getLastIndex(parentSection) {
		return this.getChildrenOf(parentSection).last?.index;
	}

	pathExist(path) {
		const pathString = Array.isArray(path) ? path.map(name => name.trim()).join('/')
			: String(path)
		return this.sections.some(section => section.name === pathString);
	}

	static retrieveBestMatchingSection(sections, quantityMin = 0) {
		return sections?.reduce((selected, section) => {
			return (
				(
					(!quantityMin || section.quantity >= quantityMin) && (
						!selected
						|| (
							selected.path.length < section.path.length
							&& (!quantityMin || section.quantity >= quantityMin)
						)
						|| (
							selected.quantity < section.quantity
							&& selected.path.length === section.path.length
						)
					)
				) ? section : selected

			);
		}, null);
	}
}

const nameProp = prop('name');

CatalogCupboard.addProperties({
	sections: {
		type: Array,
		template: CatalogSection,
		set: function (sections) {
			return pipe(
				defaultTo([]),
				flatten,
				filter(nameProp),
				uniqBy(nameProp),
				map(assign({ cupboard: this })),
				Object.freeze,
			)(sections);
		}
	}
});

const sort = willSafeSort(prop('index'));
