import React from "react"
import styles from "../../res/styles";
import Icon from "../Icon/v2";
import View from "../View/v2";
import ComponentUtils from "../../ComponentUtils";
import Environment from "../../library-js/Environment";
import use from "../../hook";

export default function AppBar(staticProps) {
	let { statusBarPadding, ...props } = ComponentUtils.mergeProps(staticProps, AppBar.default);
	if (Environment.is.web)
		statusBarPadding = false;

	props.style = use.defaultStyle(props.style, {
		height: styles.appBarHeight + (statusBarPadding ? styles.statusBarHeight : 0),
		paddingTop: (statusBarPadding ? styles.statusBarHeight : 0),
	}, [statusBarPadding]);

	return <View {...props} />;
}

export const AppBarActionButton = AppBar.ActionButton = function ActionButton({ icon, ...props }) {
	props.style = use.defaultStyle(props.style, { paddingHorizontal: 12 });

	return (
		<Icon
			name={icon}
			size={24}
			{...props}
		/>
	);
};

AppBar.default = {
	statusBarPadding: true,

	style: {
		borderColor: "#ebedf0",
		borderBottomWidth: .5,
		backgroundColor: styles.color.white,
		paddingHorizontal: 4,
	}
};
