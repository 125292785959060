import React from "react"
import {View as RNView, ScrollView} from "react-native"
import use from "library-react/hook"
import ProductItem from "@main/component/ProductItem";
import Portal from "library-react/component/Portal";
import {screens} from "@main/links";
import {NO_SCROLLBAR_TAG} from "@src/cssTags";

export default function RelativeProductsMobile({title, iterator, ...props}) {
	const {Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<RNView {...props}>
			<Text style={localStyles.title}>
				{title}
			</Text>

			<ScrollView
				horizontal
				dataSet={{[NO_SCROLLBAR_TAG]: 1}}
				contentContainerStyle={localStyles.products.container}>
				{
					iterator.items.map(vProdshop => {
						const {id} = vProdshop.product;
						return (
							<Portal
								key={id}
								to={screens.product}
								params={{id}}>
								<ProductItem
									vProdshop={vProdshop}
									style={localStyles.products.item}
								/>
							</Portal>
						);
					})
				}
			</ScrollView>
		</RNView>
	);
};

const localStyles = {
	layout: {},

	title: {
		fontSize: 23,
		bold: true,
		color: "#504747",
		marginBottom: 15,
		marginLeft: 15,
	},

	products: {
		container: {
			paddingRight: 30,
			paddingBottom: 60,
			paddingLeft: 20,
		},

		item: {
			marginRight: 22,
		},
	},
};
