import React from "react";
import {View as RNView} from "react-native";
import use from "../../../../../hook";
import View from "../../../../../component/View/v2";
import Text from "../../../../../component/Text";
import Image from "../../../../../component/Image";
import {fonts, strings, styles} from "../../../../../res";
import LoadingPlaceholder from "../../../../../component/LoadingPlaceholder";
import RatingBadge from "../../../../../component/badge/RatingBadge";

function CatalogItem({item: vProdshop, ...props}) {
	const {product, rate} = vProdshop?.vProduct || {};

	const {finalPrice, oldPrice} = vProdshop?.prodshop || {};

	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<View {...props}>

			{/* picture */}
			<LoadingPlaceholder
				disabled={vProdshop}
				style={localStyles.picture}>
				<Image
					source={product?.gallery?.first}
					resizeMode="cover"
					style={styles.fit}
				/>
			</LoadingPlaceholder>

			{/* infos */}
			<RNView style={localStyles.content}>

				{/* title */}
				<LoadingPlaceholder
					disabled={vProdshop}
					style={localStyles.titleWrapper}>
					<Text
						numberOfLines={1}
						style={localStyles.title}>
						{product?.title}
						{strings.invisibleChar}
					</Text>
				</LoadingPlaceholder>

				{/* subtitle */}
				<LoadingPlaceholder
					disabled={vProdshop}
					style={localStyles.subtitleWrapper}>
					<Text
						numberOfLines={1}
						style={localStyles.subtitle}>
						{product?.subtitle}
						{strings.invisibleChar}
					</Text>
				</LoadingPlaceholder>

				{/* footer */}
				<RNView style={localStyles.footer}>
					{/* rate */}
					<RNView style={localStyles.badges}>
						{
							(rate?.numberOfVotes > 0) &&
							<RatingBadge style={localStyles.rate}>
								{rate.stars}
							</RatingBadge>
						}
					</RNView>

					{/* prices */}
					<RNView style={localStyles.prices}>
						<RNView style={localStyles.price.layout(oldPrice)}>
							<Text numberOfLines={1}
								  style={localStyles.price.text(oldPrice)}>
								{finalPrice?.value}
								<Text style={localStyles.priceSymbol}>
									{finalPrice?.symbol}
								</Text>
							</Text>
						</RNView>

						{
							oldPrice &&
							<Text numberOfLines={1}
								  style={localStyles.oldPrice}>
								{oldPrice?.value}
								<Text style={localStyles.priceSymbol}>
									{oldPrice?.symbol}
								</Text>
							</Text>
						}
					</RNView>
				</RNView>
			</RNView>
		</View>
	);
}

export default React.memo(CatalogItem);


const localStyles = {
	layout: {
		width: 162,
		backgroundColor: styles.color.white,
		borderRadius: 9,
		...styles.newShadow(0, 0, 5, .15),
	},

	picture: {
		height: 114,
		backgroundColor: styles.color.lightgrey,
		overflow: styles.overflow.hidden,
		borderRadius: 8,
	},

	content: {
		padding: 7,
	},

	titleWrapper: {
		borderRadius: 8,
		alignSelf: styles.alignSelf.flexStart,
		minWidth: 50,
		marginBottom: 2,
	},

	title: {
		fontSize: 11,
		font: fonts.Poppins,
		bold: true,
		color: styles.color.black,
		lineHeight: 12,
	},

	subtitleWrapper: {
		borderRadius: 4,
		alignSelf: styles.alignSelf.flexStart,
		minWidth: 90,
	},

	subtitle: {
		fontSize: 10,
		font: fonts.Poppins,
		color: "#1e2432",
		lineHeight: 16,
	},

	footer: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
		justifyContent: styles.justifyContent.spaceBetween,
	},

	badges: {
		flexDirection: styles.flexDirection.row,
		flexShrink: 1,
	},

	rate: {},

	prices: {
		flexDirection: styles.flexDirection.rowReverse,
		alignItems: styles.alignItems.center,
	},

	oldPrice: {
		fontSize: 9,
		font: fonts.Poppins,
		bold: true,
		marginRight: 4,
		textDecorationLine: styles.textDecorationLine.lineThrough,
	},

	price: {
		layout: styles.static.bool({
				paddingHorizontal: 7,
				borderRadius: 10,
				height: 20,
				...styles.center,
			},
			{
				backgroundColor: "#e95454",
				marginLeft: 3,
			},
			{}
		),

		text: styles.static.bool({
				font: fonts.Poppins,
				bold: true,
			},
			{
				color: styles.color.white,
				fontSize: 10,
			},
			{
				color: styles.color.black,
				fontSize: 11,
			}
		)
	},

	priceSymbol: {
		fontSize: 7,
	}
};
