import { curry, isNil } from 'ramda';

function assignParamsToUrl(params, url) {
	const urlInstance = new URL(url);

	if (params instanceof Object)
		Object.entries(params)
			.forEach(([key, value]) => {
				if (isNil(value))
					urlInstance.searchParams.delete(key);
				else
					urlInstance.searchParams.set(key, value);
			});

	return urlInstance.href;
}

export default curry(assignParamsToUrl);