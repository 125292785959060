import React from "react"
import AbstractOrderCheckoutUI from "../../library-react/fragment/checkout/OrderCheckoutUI"
import {screens} from "../links";
import {useRoute} from "@react-navigation/native";
import use from "library-react/hook";
import {not, when} from "ramda";


export default function OrderCheckoutUI({navigation, ...props}) {
	const route = useRoute();
	const {id} = route.params || {};

	use.effect(when(not, () => navigation.replace(screens.orderList)), [id]);

	return (
		<AbstractOrderCheckoutUI
			orderId={id}
			oncePaymentFinished={({orderId, secure}) => {
				if (secure)
					window.location.replace(secure);
				else
					navigation.replace(screens.paymentResult, {orderId});
			}}
			ifCannotSettle={() => navigation.replace(screens.order, {id})}
			secureCallbackURL={SECURE_CALLBACK_URL}
			prodshopToRoute={prodshopToRoute}
			{...props} />
	);
};

const SECURE_CALLBACK_URL = `${location.origin}/${screens.paymentResult}?`

function prodshopToRoute({id: {productId}}){
	return {to: screens.product, params: {id: productId}};
}
