import Environment from "library-js/Environment";
import {View as RNView} from "react-native"
import parallel from "../../../library-js/utils/function/parallel";
import Objects from "../../../library-js/utils/Objects";

export default function pressable(props, data) {
	const {onVoidPress} = Objects.retrieve(props, ['onVoidPress'], true);
	if (!props.onPress)
		delete props.onPress;

	if (onVoidPress)
		props.onPress = parallel(props.onPress, () => onVoidPress());

	data.wasDisabled = (props.disabled !== undefined) && Boolean(props.disabled);
	data.pressable = CALLBACKS.some(name => props[name]);

	if (Environment.is.web){
		if (props.href) // link
			data.Component = RNView;
	}

	if (props.disabled === undefined)
		props.disabled = !data.pressable; // prevent to be clickable if no handler
}

const CALLBACKS = ["onPressIn", "onPressOut", "onPress", "onLongPress", "onClick"];
