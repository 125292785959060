import {Entity} from "../../library-js/app/model/entity";
import {Image} from "../../library-js/app/model/media";

export default class Interlocutor extends Entity {
	constructor(id, name, picture) {
		super();
		this.hydrate({id, name, picture});
	}

	static fromUser(user){
		if (!user) return;

		const {id, name, picture} = user;
		return this.instantiate(id, name, picture)
			.setOrigin(user);
	}

	static fromVShop(vShop){
		if (!vShop) return;

		const {shop} = vShop;
		const interlocutor = shop && new Interlocutor(shop.id, shop.name, shop.logo);
		if (interlocutor)
			interlocutor.origin = vShop;

		return interlocutor;
	}
}
Interlocutor.addProperties({
	id : null,
	name : String,
	picture : Image,
	origin: null, // user or vShop
});
