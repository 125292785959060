import React from "react";
import {View as RNView} from "react-native"
import use from "../../../../../../hook";
import ProductPreview from "../../ProductPreview";
import RemoveButton from "./RemoveButton";

function ProductAttachmentPreview({item, onProductClicked, onRemoveClicked, ...props}) {
	const {product, prodshop, shop} = item || {};
	const {finalPrice, oldPrice} = prodshop || {};

	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<RNView {...props}>
			<ProductPreview
				product={product}
				price={finalPrice}
				oldPrice={oldPrice}
				innerStyles={localStyles.innerStyles}
				hidePrice={shop.hiddenPrice}
				style={localStyles.card}
			/>

			<RemoveButton onPress={onRemoveClicked} />
		</RNView>
	);
}

export default React.memo(ProductAttachmentPreview);

const localStyles = {
	card: {
		width: 80,
		zIndex: 0,
	},

	innerStyles: {
		// image: {height: 80 / styles.perfectPictureRatio },
		image: {height: 50 },
		title: {fontSize: 6},
		subtitle: {fontSize: 6},

		price: {
			fontSize: 7,
			lineHeight: 12,
		},

		priceLayout: {
			paddingVertical: 0,
			paddingHorizontal: 4,
		},

		oldPrice: {
			fontSize: 6,
			marginRight: 1,
		},
	},
};
