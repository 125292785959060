import Entity from "./Entity"
import {Image, Video} from "../media"
import {Enum} from "../../../class"
import Brand from "./Brand";

/**
 * A product.
 */
export default class Product extends Entity.extendsWith({
	url: String,
	gallery: {
		type: Array,
		template: Image
	}
}) {
	constructor() {
		super();
		this.gallery = [];
		this.sizes = [];
		this.tags = [];
	}

	/**
	 * TODO delete [shopWeb, user]
	 */
	get categoriesName() {
		return this.categories && this.categories.map(c => c.name);
	}

	setUrl(url) {
		if (url && !(url.startsWith("http://") || url.startsWith("https://")))
			url = 'http://' + url;
		return super.setUrl(url);
	}

	get fullTitle() {
		let fullTitle = "";
		const brand = this.safeBrand;
		const name = this.name?.trim();

		// check if brand is not included in name
		if (brand && name && (
			!name.startsWith(`${brand} `) &&
			!name.includes(` ${brand} `) &&
			!name.endsWith(` ${brand}`)
		))
			fullTitle += `${brand || ''} `;

		fullTitle += name || '';

		return fullTitle;
	}

	get title() {
		return this.name;
	}

	get subtitle() {
		return this.safeBrand || this.description
	}

	get shortSubtitle() {
		return this.safeBrand;
	}

	get safeBrand() {
		const brand = this.brand?.trim();
		if (brand && brand !== '-')
			return brand;
	}

	getGallery() {
		let gallery = super.getGallery();
		if (!gallery)
			super.setGallery(gallery = []);
		return gallery;
	}

	get mainPicture() {
		return this.gallery.first;
	}

	get hasSizes() {
		return Boolean(this.sizes && this.sizes.length > 0);
	}

	get parsedBrand() {
		return Brand.parseName(this.brand);
	}
}

Product.AgeClass = new Enum({
	baby: 0,
	child: 1,
	adult: 2,
});

Product.addProperties({
	name: String,
	brand: String,
	color: String,
	description: String,

	reference: String,
	gtin: String,

	tags: {
		type: Array,
		template: String
	},

	/**
	 * TODO delete [shopWeb, user, retailer]
	 */
	categories: Array,

	/**
	 * List of sizes available for this product. Sizes are just strings.
	 */
	sizes: Array,

	video: Video,

	gender: Boolean,
	// category: Category, // set by Category to prevent require loop because Category require server which require product
	ageClass: Product.AgeClass,

	/***
	 * Indicates if the product os private to the shop.
	 */
	isPublic: Boolean,

	creationDate: Number,
});
