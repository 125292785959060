import React from "react"
import use from "../../../../hook"
import Icon from "../../../../component/Icon/v2"
import { styles } from "../../../../res"
import View from "../../../../component/View";

export default function Tab({ icon, selected, ...props }) {
	props.style = use.defaultStyle(
		props.style,
		localStyles.layout[selected],
		[selected]
	);

	return (
		<View {...props}>
			<Icon
				name={icon}
				size={25}
				style={localStyles.icon[selected]} />
		</View>
	);
}

const localStyles = {
	layout: styles.static.bool(
		{
			...styles.center,
			flex: 1,
			height: 65,
		},
		{
			borderBottomColor: '#452ee0',
			borderBottomWidth: 3,
		},
		{
			borderBottomColor: '#c1c0c8',
			borderBottomWidth: 1,
		},
	),

	icon: styles.static.bool(
		{
		},
		{
			color: '#452ee0',
		},
		{
			color: '#c1c0c8',
		},
	),
};
