import React from "react"
import {View as RNView} from "react-native"
import use from "library-react/hook"
import {styles} from "@main/res"
import View from "library-react/component/View/v2";

function QuantityControl({quantity = 0, onMinusClicked, onPlusClicked, ...props}) {

	const {Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<RNView {...props}>
			<View
				onPress={onMinusClicked}
				style={localStyles.button.left}>
				<Text style={localStyles.text.button}>
					-
				</Text>
			</View>

			<Text style={localStyles.text.value}>
				{quantity}
			</Text>

			<View
				onPress={onPlusClicked}
				style={localStyles.button.right}>
				<Text style={localStyles.text.button}>
					+
				</Text>
			</View>
		</RNView>
	);
}

export default React.memo(QuantityControl);


const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
		borderWidth: 1,
		borderColor: "#666666",
		minWidth: 117,
	},

	button: styles.static(
		{
			borderColor: "#666666",
			...styles.center,
			width: 33,
		},
		{
			left: {borderRightWidth: 1},
			right: {borderLeftWidth: 1},
		}
	),

	text: styles.static(
		{
			fontSize: 19,
			color: styles.color.black,
			textAlign: styles.textAlign.center,
			fontWeight: 200,
		},
		{
			button: {},
			value: {
				flexGrow: 1,
				alignSelf: styles.alignSelf.center,
			},
		}
	),
};
