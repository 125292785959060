import useApp from "library-react/app/useApp";
import BasketCheckoutUI from "../../library-react/fragment/checkout/BasketCheckoutUI";
import use from "../../library-react/hook";
import { screens } from "../links";


export default function CheckoutUI({ ...props }) {
	const navigation = props.navigation;
	const shopId = use.context.infos().vShop.id;
	const app = useApp();

	use.effect(() => () => console.log('CheckoutUI unmounted'));

	return (
		<BasketCheckoutUI
			shopId={shopId}
			onceOrderCreated={(orderId) => navigation.replace(screens.paymentResult, { orderId })}
			oncePaymentLinkCreated={url => window.location.replace(url)}
			back={app.navigation?.goBack}
			onEmptyBasket={() => navigation.replace(screens.basket)}
			style={{ paddingHorizontal: 20 }}
			{...props} />
	);
};