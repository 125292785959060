import Base from "../../../../class/Base";
import Brand from "../../entity/Brand";
import VShop from "./VShop";

export default class VBrand extends Base {
	get name(){
		return this.brand?.name;
	}

	get logo(){
		return this.brand?.logo;
	}

	get parsedName(){
		return this.brand?.parsedName;
	}
}
VBrand.addProperties({
	brand: Brand,
	viewShop: VShop,
	numberOfProducts: Number,
	numberOfAvailableProducts: Number,
});
