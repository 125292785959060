import Notification from "./Notification";
import {VOrder} from "../../../view";

export default class OrderItemNotification extends Notification {
	get ok(){
		return Boolean(
			super.ok
			&& this.vOrderItem?.item?.id
			&& this.vOrderItem?.item?.orderId
			&& this.vOrderItem?.item?.state
			&& this.vOrderItem?.product?.id
			&& this.vOrderItem?.product?.gallery
		);
	}
}

OrderItemNotification.addProperties({
	vOrderItem: VOrder.Item
});
