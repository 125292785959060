import { useState } from "react";
import { Pressable, StyleSheet, ViewProps } from "react-native";
import use from "../../../library-react/hook";
import useToast from "../../../library-react/hook/useToast";
import { callMicroservice } from "../../../library-react/library-js/app/Server/microservices";
import setPathToUrl from "../../../library-react/library-js/utils/URL/setPathToUrl";
import bindPromiseToBoolState from "../../../library-react/utils/action/bindPromiseToBoolState";
import willCatchAndToast from "../../../library-react/utils/willCatchAndToast";

export default function SettleButton({ orderId, ...props }: SettleButtonProps) {
	const [isLoading, setLoading] = useState(false);
	const { Text, Spinner } = use.theme();

	const toast = useToast();
	function settle() {
		const returnUrl = setPathToUrl(`order/${orderId}`, window.location.href);
		const promise = callMicroservice("/order/paymentLink", { orderId, returnUrl })
			.then(({ url }) => window.location.href = url)
			.catch(willCatchAndToast(toast));

		bindPromiseToBoolState(setLoading, promise);
	}

	props.style = use.defaultStyle(props.style, [localStyles.layout, !isLoading && localStyles.button], [isLoading]);
	return isLoading ? <Spinner /> : (
		<Pressable
			{...props}
			disabled={isLoading}
			onPress={settle}>

			<Text style={localStyles.text}>
				{`Régler la commande`}
			</Text>
		</Pressable>
	);
}

interface SettleButtonProps extends ViewProps {
	orderId: number,
}

const localStyles = StyleSheet.create({
	layout: {
	},

	button: {
		backgroundColor: '#395aff',
		paddingVertical: 15,
		paddingHorizontal: 20,
		borderRadius: 10,
	},

	text: {
		color: '#ffffff',
		fontSize: 17,
		textAlign: "center",
		letterSpacing: 2,
	},
});