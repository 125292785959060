import { is } from "ramda";

export default async function strictFetch(...params) {
	const response = await fetch(...params);
	if (!response.ok) {
		const contentType = response.headers.get('Content-Type')?.toLowerCase();
		const body = await (
			contentType?.startsWith('application/json') ? response.json()
				: response.text()
		);

		const error = new Error(`HTTP error ${response.status}: ${is(String, body) ? body : response.statusText}`);
		error.response = response;
		error.status = response.status;
		error.body = body;

		throw error;
	}
	return response;
}