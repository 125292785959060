import React from "react";
import use from "library-react/hook";
import View from "library-react/component/View";
import ComponentUtils from "library-react/ComponentUtils";
import {styles} from "@main/res";

export default React.memo(
	function Grid({items, renderItem, numberOfColumns, rowStyle, spaceHorizontalBetweenItems, spaceVerticalBetweenRows, ...props}) {
		const arraysByRow = use.memo(
			() => chunk(items, numberOfColumns),
			[items.length, numberOfColumns]
		);
		props.style = use.defaultStyle(props.style, localStyles.layout);
		rowStyle = use.defaultStyle(rowStyle, localStyles.row);
		return (
			<View {...props}>
				{
					arraysByRow.map((row, rowIndex) =>
						<View key={rowIndex}
							style={[rowStyle, {marginTop: rowIndex === 0 ? 0 : spaceVerticalBetweenRows}]}>
							{
								row.map(
									(item, index) =>
										<View
											key={index}
											style={{marginLeft: index % numberOfColumns === 0 ? 0 : spaceHorizontalBetweenItems}}>
											{renderItem(item)}
										</View>
								)
							}
						</View>
					)
				}

			</View>
		)
	},
	(oldProps, props) => ( // are equals only if
		ComponentUtils.props.equals(oldProps, props) // instances not changed
		&& !ComponentUtils.props.arrayLengthChanged(oldProps.items, props.items) // and items length not changed
	)
);
const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.column,
	},
	row: {
		flexDirection: styles.flexDirection.row,
		justifyContent: styles.justifyContent.flexStart
	},
};

function chunk(array, chunkSize) {
	let chunkedArray = [];

	let i, j;
	for (i = 0, j = array.length; i < j; i += chunkSize)
		chunkedArray.push(array.slice(i, i + chunkSize));

	return chunkedArray;

}
