const style = document.createElement('style');
style.innerHTML = `
* {
	outline: none;
}

html, body {
	margin: 0;
	padding: 0;
	overflow: hidden;
}

body {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex;
	flex-direction: column;
}

#root {
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow: hidden;
}

a {
	text-decoration: none;
}

div {
	user-select: auto !important;
}

*[data-zoom-on-hover] {
	transition: transform .5s ease;
}

*[data-zoom-on-hover]:hover {
	transform: scale(1.2);
}

@keyframes disableHorizontalPaddingAnimation {
	100% {padding-right: 0px;}
	100% {padding-left: 0px;}
}

*[data-disableHorizontalPaddingOnHover]:hover {
	animation: disableHorizontalPaddingAnimation .5s ease 0s 1 normal forwards;
}
select {
	border: 0px;
	background: transparent;
}
`;
document.head.appendChild(style);
