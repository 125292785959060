import Platform from "../../../../../Platform";
import config from "../config";

export default {
	chat(interlocutorId) {
		const userId = Platform.is.user ? config.id : interlocutorId;
		const shopId = Platform.is.user ? interlocutorId : config.id;
		return `${userId}_${shopId}`;
	},

	interlocutor(chatId) {
		const [userId, shopId] = chatId.split("_");
		return Platform.is.user ? shopId : userId;
	},
};
