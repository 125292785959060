import React from "react"
import {View as RNView} from "react-native"
import use from "library-react/hook"
import {styles} from "@main/res"

function BasketEmpty({...props}) {
	const {Text} = use.theme();
	const display = use.context.display();
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<RNView {...props}>
			<Text style={localStyles.text(display)}>
				{`VOTRE PANIER NE COMPORTE AUCUN ARTICLE.`}
			</Text>
		</RNView>
	);
}

export default React.memo(BasketEmpty);


const localStyles = {
	layout: styles.center,

	text: styles.static(
		{
			color: styles.color.black,
			textAlign: styles.textAlign.center,
		},
		{
			mobile: {
				fontSize: 17,
			},

			default: {
				fontSize: 26,
			}
		}
	)
};
