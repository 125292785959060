import React from "react";
import Desktop from "./desktop";
import Mobile from "./mobile";
import use from "library-react/hook";

export default React.memo(
	function CommentButton(props){
		const display = use.context.display();
		return (
			display.is.mobile ?
				<Mobile {...props} /> :
				<Desktop {...props} />

		)
	}
)