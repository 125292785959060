import { Base, Enum } from "../../../../class";
import Platform from "../../../../Platform";

/**
 * @property {Type} type
 */

const Type = Enum.make({
	processingPrepayment: 5,
	prepaymentFailed: 6,
	preparing: 4,
	ready: 2,
	processingPayment: 1,
	paymentFailed: -2,
	reception: 7,
	finished: 0,
	canceled: -1,
});
export default class State extends Base.extendsWith({
	type: Type,
}) {
	constructor(type, orderId, time = Date.now()) {
		super();
		this.hydrate({ type, orderId, time });
	}

	get canGetTo() {
		return this.type.canGetTo;
	}

	getType() {
		const type = super.getType();
		const delayPast = this.time + 5 * Date.MINUTE < Date.now();

		if (type.is.processingPrepayment && delayPast)
			return Type.prepaymentFailed;

		return type;
	}
};

State.Type = Type;
Type.domains.ended = [Type.finished, Type.canceled];
Type.domains.itemsEditable = [Type.preparing, Type.ready, Type.paymentFailed];
Type.domains.settled = [Type.reception, Type.finished, Type.canceled];
Type.domains.canRetryPayment = [Type.prepaymentFailed];

Type.forEach((_, type) => type.canGetTo = {});
Platform.whenReady.then(() => Platform.run({
	user() {
		Type.prepaymentFailed.canGetTo.canceled = true;
		Type.reception.canGetTo.finished = true;
	},

	retailer() {
		// cancelable
		[/*Type.processingPrepayment, */ Type.preparing, Type.ready, Type.paymentFailed].forEach(from => {
			from.canGetTo.canceled = true;
		});
		Type.preparing.canGetTo.ready = true;
	}
}));

const Author = State.Author = Enum.make({
	shopinzon: "SHOPINZON",
	paymentSystem: "PAYMENT_SYSTEM",
	user: "USER",
	retailer: "RETAILER",
});

State.addProperties({
	orderId: Number,
	time: Number,
	author: Author,
	message: String,
});
