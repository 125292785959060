import listenersRemovers from "./listenersRemovers";
import nodeToEntry from "../utils/nodeToEntry";

export default function listenChildrenOfRef(ref, listener) {
	let onChildAdded, onChildChanged;
	// wrap listener: convert node into entry
	const fire = node => {
		const entry = nodeToEntry(node);
		listener(entry);
	};

	// first load the last added child and then start to listen
	let removed = false; // check if listener has been asked to be removed before load finish
	ref.limitToLast(1)
		.once('value')
		.stopOn(() => removed)
		.then(node => {
			const data = node.val();
			const lastKey = data && Object.keys(data).first;

			// prevent to fire for a child already added
			onChildAdded = node => {
				const newKey = node.key;
				if (newKey !== lastKey)
					fire(node);
			};
			onChildChanged = fire;

			// --- start listening ---

			// on change
			ref.on("child_changed", onChildChanged);

			// on added
			ref = ref.limitToLast(1);
			ref.on("child_added", onChildAdded);
		});

	// removers
	return listenersRemovers.add(() => {
		removed = true;
		if (onChildChanged)
			ref.off("child_changed", onChildChanged);

		if (onChildAdded)
			ref.off("child_added", onChildAdded);
	});
}
