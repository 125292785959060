import VOrder from "./VOrder";
import {Base} from "../../../../../class";
import {Order} from "../../../entity";


export default class VOrderItem extends Base {
	get id() {
		return this.item?.id;
	}

	get order() {
		return this.vOrder?.order;
	}

	get product() {
		return this.item?.product;
	}

	get prodshop() {
		return this.item?.prodshop;
	}

	get shop() {
		return this.vShop?.shop || this.item?.shop;
	}

	get unitPrice() {
		return this.item?.unitPrice;
	}

	get price() {
		return this.item?.price;
	}

	get finalPrice(){
		return this.item?.price;
	}

	get oldPrice() {
		const paidPrice = this.price;
		const originalUnitPrice = this.prodshop?.price;
		const quantity = Number(this.quantity);
		if (originalUnitPrice && paidPrice && quantity >= 0) {
			const originalPriceValue = originalUnitPrice.value * quantity;
			if (originalPriceValue > 0 && paidPrice?.value !== originalPriceValue)
				return originalUnitPrice.copy().setValue(originalPriceValue);
		}
	}

	get state() {
		return this.item?.state;
	}

	get size() {
		return this.item?.size;
	}

	get quantity() {
		return this.item?.quantity;
	}

	get creationDate() {
		return this.vOrder?.creationDate;
	}

	get vShop() {
		return this.vOrder?.vShop;
	}

	get orderId() {
		return this.item?.orderId;
	}

	get vProdshop(){
		return this.item.vProdshop;
	}
};

VOrderItem.addProperties({
	item: Order.Item,
	vOrder: VOrder,
});
