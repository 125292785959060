import React from "react";
import Desktop from "./desktop";
import Mobile from "./mobile";
import EmptyResult from './EmptyResult'
import use from "library-react/hook";
import Spinner from "@main/component/Spinner";

export default React.memo(
	function CommentList({setEditMode, iterator, ...props}) {
		const display = use.context.display();
		const edit = use.callback(() => setEditMode(true), [setEditMode]);
		use.infiniteIterator(() => iterator, [iterator]);

		return (
			(iterator.loading && iterator.page === 0) ?
				<Spinner style={{flex: 1, paddingVertical: 100}}/> :
				(iterator.end && !iterator.items.length) ?
					<EmptyResult edit={edit}/> :
					display.is.mobile ?
						<Mobile edit={edit} iterator={iterator} {...props} /> :
						<Desktop edit={edit} iterator={iterator} {...props} />
		)
	}
)
