import useRenderTime from "./useRenderTime";
import useEffect from "./useEffect";
import useSyncEffect from "./useSyncEffect";

export default function useOnChange(effect, dependencies){
	const renderTime = useRenderTime();
	useEffect((renderTime > 1) && effect, dependencies);
}

useOnChange.sync = function onChangeSync(effect, dependencies){
	const renderTime = useRenderTime();
	useSyncEffect((renderTime > 1) && effect, dependencies);
}
