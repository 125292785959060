import React from "react"
import { ScrollView, View as RNView } from "react-native"
import use from "../../../hook"
import { styles } from "../../../res"
import BottomBar from "./BasketBottomBar";
import StepPoint from "../component/StepPoint";
import Text from "../../../component/Text";
import ItemsReview from "../component/ItemsReview";
import PaymentSecureCertification from "../component/PaymentSecureCertification";
import ErrorText from "../component/ErrorText";
import useAppWidthLevel from "../../../app/useAppWidthLevel";
import Icon from "../../../component/Icon/v2";
import { MAX_CONTENT_WIDTH } from "../component/BottomBar/desktop";
import { useNavigation } from "@react-navigation/native";
import CheckoutFrameContext from "./CheckoutFrameContext"
import Environment from "../../../library-js/Environment";
import { __, append, pick, prop, update, without } from "ramda";

export default function CheckoutFrame(
	{
		prodshopToRoute,
		showSecurityCertificate,
		maxMobileWidth,
		children,
		...props
	}
) {
	const [entries, setEntries] = use.state([{ step: 1 }]);

	function setEntry(entry, oldEntry) {
		setEntries(entries => {
			const index = oldEntry && entries.indexOf(oldEntry);

			const mutate = !(index >= 0) ? append(entry)
				: entry ? update(index, entry)
					: without([oldEntry]);

			return mutate(entries);
		});
	}


	const { title, loading, error, step, submitLabel } = entries.last;

	const [isDesktop] = useAppWidthLevel([maxMobileWidth || MAX_MOBILE_WIDTH]);
	const isMobile = !isDesktop;

	const FrameControl = use.callback(
		function CheckoutFrameControl({ submit, children, ...props }) {
			const frameProps = pick(FRAME_PROPS, props);
			frameProps.submit = submit;

			const memory = use.memo({ saved: undefined });
			if (memory.saved) // update current used entry in case other props didn't change
				memory.saved.submit = submit;

			// update on change
			use.effect(() => {
				setEntry(frameProps, memory.saved);
				memory.saved = frameProps;
			},
				FRAME_PROPS.map(prop(__, frameProps))
			);

			// clean on unmount
			use.effect(() => () => setEntry(null, memory.saved));

			const [isDesktop] = useAppWidthLevel([maxMobileWidth || MAX_MOBILE_WIDTH]);
			const isMobile = !isDesktop;

			const { title } = props;
			return (
				<ScrollView
					style={localStyles.scroll.layout}
					contentContainerStyle={localStyles.scroll.content(isMobile)}>
					{
						Boolean(title && !isDesktop) &&
						<Text style={localStyles.title(isMobile)}>
							{title}
						</Text>
					}

					{
						isDesktop ? children :
							<RNView style={{ flexGrow: 1 }}>
								{children}
							</RNView>
					}
				</ScrollView>
			);
		}
	);

	const navigation = useNavigation();

	props.style = use.defaultStyle(props.style, localStyles.layout,);

	return (
		<CheckoutFrameContext.Provider value={FrameControl}>
			<RNView {...props}>
				{
					(isDesktop || isDesktop === undefined) ?
						<>
							<RNView style={localStyles.header.layout}>
								<Icon
									name='back'
									size={28}
									onPress={Environment.run({
										web: () => window.history.back,
										default: () => navigation.goBack,
									})}
									style={localStyles.header.back} />

								<Text style={localStyles.title(false)}>
									{title}
								</Text>
							</RNView>

							<RNView style={localStyles.bodyDesktop}>
								<RNView style={localStyles.scroll.desktopWrapper}>
									{children}

									<ErrorText display>
										{error}
									</ErrorText>
								</RNView>

								<ItemsReview
									prodshopToRoute={prodshopToRoute}
									style={localStyles.basket.layout}
									contentContainerStyle={localStyles.basket.content} />
							</RNView>
						</> :

						<>
							<StepPoint current={step} style={localStyles.steps} />

							{children}

							{
								<ErrorText display={Boolean(error)}>
									{error}
								</ErrorText>
							}
							{
								showSecurityCertificate &&
								<PaymentSecureCertification style={localStyles.secure} />
							}
						</>
				}

				{
					(isDesktop !== undefined) &&
					<BottomBar
						onNextClicked={() => entries.last.submit?.()}
						nextLabel={submitLabel}
						loading={loading}
						isMobile={isMobile}
						style={localStyles.footer(isMobile)} />
				}
			</RNView>
		</CheckoutFrameContext.Provider>
	);
}

const MAX_MOBILE_WIDTH = 1024;

const FRAME_PROPS = ['title', 'loading', 'error', 'step', 'submitLabel'];

const localStyles = {
	layout: {
		flex: 1,
	},

	steps: {
		marginVertical: 15,
		alignSelf: styles.alignSelf.center,
	},

	header: {
		layout: {
			flexDirection: styles.flexDirection.row,
			paddingTop: 30,
			paddingLeft: 30,
		},
		back: {
			width: 50,
			height: 50,
			backgroundColor: '#e4e4e4',
			marginRight: 11,
		}
	},
	title: styles.static.bool({
		color: '#000000',
		bold: true,
		width: '100%',
	},
		{
			fontSize: 25,
			marginBottom: 30,
			textAlign: styles.textAlign.center,
			maxWidth: 270,
			alignSelf: styles.alignSelf.center,
		},
		{
			fontSize: 40,
			maxWidth: 470,
			height: 180,
			overflow: styles.overflow.hidden,
		}
	),

	bodyDesktop: {
		flexDirection: styles.flexDirection.row,
		justifyContent: styles.justifyContent.spaceBetween,
		flex: 1,
		maxWidth: MAX_CONTENT_WIDTH,
		paddingLeft: 80
	},

	scroll: {
		desktopWrapper: {
			flex: 1,
			maxWidth: 550,
		},

		layout: {
			flex: 1,
		},

		content: styles.static.bool({
			flexGrow: 1,
		},
			{ maxWidth: 500, alignSelf: styles.alignSelf.center, width: '100%' },
			{ paddingTop: 1 /* prevent top to be cut */ },
		)
	},

	basket: {
		layout: {
			flex: 1,
			maxWidth: 700,
			backgroundColor: styles.color.white,
			...styles.newShadow(0, 6, 15, .16),
			alignSelf: styles.alignSelf.flexStart,
			height: 550,
			maxHeight: '100%',
		},

		content: {
			paddingHorizontal: 60,
			paddingVertical: 30,
		}
	},

	secure: {
		marginBottom: 20,
	},

	footer: styles.static.bool({},
		{
			paddingBottom: 20,
			alignSelf: styles.alignSelf.center,
			width: '100%',
			maxWidth: 390,
		})
};
