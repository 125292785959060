import use from "../../library-react/hook"
import useShopCategories from "../../library-react/hook/useShopCategories"

export default function useStaticShopCategory() {
	return useStaticShopCategoryLoadable().value;
}

export function useStaticShopCategoryLoadable() {
	const categoryId = use.context.infos()?.vShop?.shop?.categoryId;
	const [, treeLoadable] = useShopCategories();
	return treeLoadable.proxy(tree => tree?.get(categoryId));
}