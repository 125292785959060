import Base from "../../class/Base"
import simpleValidate from "../../utils/simpleValidate";
import retrieveInvalidFields from "../../utils/formValidator/retrieveInvalidFields";

export default class Address extends Base {
	get formatted(){
		return (this.number ? this.number + " " : "") +
			(this.street ? this.street + ", " : "") +
			(this.city ? this.city + " " : "") +
			(this.postalCode ? this.postalCode + ", " : "") +
			(this.country ? this.country : "") ;
	}

	// city

	get city(){
		return this.locality;
	}

	set city(city){
		this.locality = city;
	}

	setCity(city){
		this.locality = city;
		return this;
	}


	// street

	get street(){
		return this.route;
	}

	set street(street){
		this.route = street;
	}

	setStreet(street){
		this.route = street;
		return this;
	}

	// number

	get number(){
		return this.streetNumber;
	}

	set number(number){
		this.streetNumber = number;
	}

	setNumber(number){
		this.streetNumber = number;
		return this;
	}

	get missingFields(){
		return Object.keys(this.constructor.properties)
			.filter(field => !this[field]);
	}

	getInvalidFields(){
		return retrieveInvalidFields(this.constructor.validator, this);
	}

	static from(address, ...params){
		if (address)
			delete address.formatedAddress;

		return super.from(address, ...params);
	}

	static fromGooglePlace(place){
		if (!place?.address_components)
			return undefined;

		const address = new Address();
		const addressComponents = Array.from(place.address_components);

		while (address.missingFields.length > 0 && addressComponents.length > 0){
			const component = addressComponents.shift();
			if (component){
				// each address component have multiple types. Find the one describing on of our properties
				const placeType = component.types.find(type => typeToFieldMap[type]);
				if (placeType){
					const {from, to} = typeToFieldMap[placeType];
					address[to] = component[from];
				}
			}
		}

		return address;
	}
}

Address.addProperties({
	country : String,
	postalCode : String,
	locality : String,
	route : String,
	streetNumber : String,
});

Address.validator = {
	country: () => simpleValidate.text,
	locality: () => simpleValidate.text,
	route: () => simpleValidate.text,
};


// ----- convert config for google place -----

const typeToFieldMap = {
	country : {
		from : "short_name",
		to : "country"
	},

	locality : {
		from : "long_name",
		to : "city",
	},

	postal_code : {
		from : "long_name",
		to : "postalCode"
	},

	route : {
		from : "long_name",
		to : "street",
	},

	street_number : {
		from : "long_name",
		to : "number",
	},
};
