import React from "react"
import View from "library-react/component/View/v2";
import use from "library-react/hook";
import {styles} from "@main/res"
import BasketContent from "./BasketContent";
import CheckoutFooter from "@main/component/CheckoutFooter/desktop"
import BackNavigationButton from "@main/component/BackNavigationButton";

export default function DesktopBasket({loadable, total, setQuantityOf, removeItem, ...props}) {
	const {Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<View {...props}>
			<View style={localStyles.header}>
				<BackNavigationButton />

				<Text style={localStyles.title}>
					{`PANIER`}
				</Text>
			</View>

			<BasketContent
				loadable={loadable}
				setQuantityOf={setQuantityOf}
				removeItem={removeItem}
				contentContainerStyle={localStyles.content.container}
				style={localStyles.content.layout}/>

			{
				total &&
				<CheckoutFooter
					totalPrice={total}
					alignRightAt={MAX_WIDTH}
				/>
			}
		</View>
	);
}

const MAX_WIDTH = 1456;

const localStyles = {
	layout: {
		flex: 1,
	},

	header: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
		paddingLeft: 30,
		paddingVertical: 24,
	},

	title: {
		fontSize: 40,
		bold: true,
		color: styles.color.black,
	},

	content: {
		layout: {
			flex: 1,
		},

		container: {
			paddingLeft: 30,
			maxWidth: MAX_WIDTH,
			paddingVertical: 40,
		}
	},

	bottom: {
		layout: {
			flexDirection: styles.flexDirection.row,
			justifyContent: styles.justifyContent.flexEnd,
			height: 62,
			marginTop: 100,
			marginBottom: 200,
		},

		total: {
			layout: {
				backgroundColor: "#f7f7f7",
				paddingHorizontal: 20,
				marginRight: 45,
				flexDirection: styles.flexDirection.row,
				alignItems: styles.alignItems.center,
				width: 400,
			},

			title: {
				bold: true,
				color: styles.color.black,
				fontSize: 19,
				marginRight: 10,
			},

			label: {
				fontSize: 14,
				color: "#9a9999",
				flex: 1,
			},

			price: {
				fontSize: 22,
				bold: true,
				color: styles.color.black,
			}
		},

		checkout: {
			props: {
				filled: true,
				style: {
					paddingVertical: undefined,
					paddingHorizontal: 70,
				},
				colors: {
					primary: styles.color.black,
					secondary: styles.color.white,
				}
			},

			text: {
				fontSize: 23,
			}
		}
	},

};
