import Entity from "./Entity"
import Image from "../media/Image"

export default class Retailer extends Entity {
	get name(){
		return (this.firstName && (this.firstName + " ")) + this.lastName;
	}
}

Retailer.addProperties({
	firstName : String,
	lastName : String,
	picture : Image,
	email : String,
	phone : String,
	isAdmin : Boolean
});
