import React from "react";
import View from "library-react/component/View";
import CommentButton from "../CommentButton";
import use from "library-react/hook";
import {styles} from '@main/res'

function EmptyResult({edit, ...props}) {
	const {Text} = use.theme();
	const display = use.context.display();
	props.style = use.defaultStyle(props.style, localStyles.layout, [display]);

	return (
		<View {...props}>
			<Text style={localStyles.text}>
				{`Encore aucun commentaire, soyez le premier a rediger un avis`}
			</Text>
			<CommentButton style={localStyles.button} onPress={edit}/>
		</View>
	)
}

export default React.memo(EmptyResult);

const localStyles = {
	layout: styles.static(
		{
			alignItems: styles.alignItems.center,
			paddingBottom: 20,
		},
		{
			mobile: {
				padding: 10,
			}
		}
	),
	text: {
		fontSize: 18,
		marginBottom: 45,
	},
	button: {
		minWidth: 210,
	}
};
