import { ApolloLink } from "@apollo/client";
import { evolve } from "ramda";
import enumAdapter from "./enumAdapter";


export default class ModelAdapterLink extends ApolloLink {
	request(operation, forward) {
		operation.query.definitions = enumAdapter.output(operation.query.definitions);
		operation.variables = enumAdapter.output(operation.variables);

		return forward(operation)
			.map(evolve({ data: enumAdapter.input }));
	}
}