export default function runUndo(undo){
	if (!(undo instanceof Array))
		undo = [undo];

	undo.flat() // run all functions undo
		.forEach(undo => {
			if (undo instanceof Function)
				undo();
		});
}
