import React from "react"
import {View as RNView} from "react-native"
import Section from "../Section";
import use from "library-react/hook";
import {styles} from "@main/res";

export default function MyInfosMobile({user, updateInfos, ...props}) {
	const {Text} = use.theme();
	return (
		<RNView {...props}>

			<Section
				title={`Mes informations`}
				subtitle={user.displayName}>

				<Text style={localStyles.email}>
					{user.email}
				</Text>
				{
					updateInfos &&
					<Text
						onPress={updateInfos}
						style={localStyles.add}>
						{`Modifier`}
					</Text>
				}
			</Section>
		</RNView>
	);
}

const localStyles = {
	email: {fontSize: 19},
	add: {
		color: "#0ab9ed",
		fontSize: 15,
		textDecorationLine: styles.textDecorationLine.underline,
		alignSelf: styles.alignSelf.flexEnd,
		padding: 7,
		marginTop: 30,
	}
};
