import Base from "../../../../../class/Base";
import Showcase from "../../../entity/Showcase";

export default class VShowcase extends Base {
	get id(){
		return this.showcase?.id;
	}

	get banner(){
		return this.showcase?.banner;
	}

	get portrait(){
		return this.showcase?.portrait;
	}

	get title(){
		return this.showcase?.title;
	}

	get description(){
		return this.showcase?.description;
	}

	get articles(){
		return this.showcase?.articles;
	}

	get ownerShopId(){
		return this.showcase?.ownerShopId;
	}

	get isPublic(){
		return this.showcase?.isPublic;
	}
}

VShowcase.addProperties({
	showcase: Showcase,
	shopsCount: Number,
});
