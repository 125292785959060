import React, { useEffect } from "react"
import use from "library-react/hook"
import useAuth from "library-react/hook/useAuth";
import DesktopUI from "./desktop"
import MobileUI from "./mobile"

function AuthDialog({ ...props }) {
	const user = props.user = useAuth.user();
	const { onSuccess, close } = props;
	
	useEffect(() => {
		if (user) {
			onSuccess?.(user);
			close?.();
		}
	},
		[user]
	);

	const display = use.context.display();

	if (!props.title)
		props.title = user ? `Utilisez un autre compte`
			: `Connectez-vous rapidement via un de vos compte`

	return (
		display.is.mobile ?
			<MobileUI {...props} /> :
			<DesktopUI {...props} />
	);
}

export default React.memo(AuthDialog);
