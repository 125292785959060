import MobileUI from "./mobile";
import React from "react";
import use from "library-react/hook";

export default function CatalogView(props) {
	const display = use.context.display();

	return (
		display.is.mobile ?
			<MobileUI {...props}/> :
			null
	);
}
