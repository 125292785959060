import React from "react"
import use from "../hook";


export default class StaticComponent {
	constructor(view){
		if (!view || React.isValidElement(view))
			return React.memo(() => view);

		return React.memo((props) => {
			let [element, propsRef] = use.memo(() => {
				// keep ref to props
				const propsRef = {};
				return [view(propsRef), propsRef];
			});


			Object.keys(propsRef) // clean props
				.forEach(key => {delete propsRef[key]});
			Object.assign(propsRef, props); // add new props

			return element; // return static view
		});


		// return (props) => view(props, ...statics);
	}

	/**
	 *
	 * @deprecated
	 */
	static Auto = class AutoStaticComponent {
		constructor(view, ...staticsProps) {
			let staticElement = React.createElement(view, ...staticsProps);
			return (props) => (
				!Object.keys(props).length ? staticElement
					: React.cloneElement(staticElement, props)
			);
		}
	}
}
