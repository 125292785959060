import React from "react"
import use from "../../hook"
import {images} from "../../res"
import Image from "../Image";

function CompanyName({dark, ...props}) {
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<Image
			source={dark ? images.company.nameDark : images.company.name}
			resizeMode="contain"
			{...props}/>
	);
}

export default React.memo(CompanyName);


const localStyles = {
	layout: {},
};
