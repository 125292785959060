export function getFontFamily(font, bold, italic){
	let fontFamily;
	if (font instanceof Object){
		if (bold){
			if (italic)
				fontFamily = font.boldItalic;
			else
				fontFamily = font.bold;
		}
		else if (italic)
			fontFamily = font.italic;

		if (!fontFamily)
			fontFamily = font.default;
	}
	else
		fontFamily = font;

	return fontFamily; //css automatically accord font to style (italic/bold)
}
