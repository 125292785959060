import "./js"
import Debug from "./Debug"
import Server from "./app/Server"
import Geocoder from "./location/Geocoder"
import Platform from "./Platform"
import "./utils/URL"
import globals from "./globals"
import AuthManager from "./AuthManager";
import Search from "./Search"
import crashlytics from "./crashlytics";
import { resolveFirebase } from "./firebasePromise";
import syncFCMToken from "./syncFCMToken";


export default function init(config) {
	const { mode, platform, firebase, apiKey, searchKey, globalMapStorage, serverMapStorage } = config;

	if (firebase)
		resolveFirebase(firebase);

	Debug.mode = mode || Debug.Modes.development;
	if (crashlytics)
		crashlytics.setAttribute('Mode', Debug.mode);

	globals.firebase = firebase;
	Platform.current = Platform.from(platform);

	Geocoder.init(apiKey);
	Server.init(firebase, serverMapStorage);

	AuthManager.init();
	syncFCMToken(globalMapStorage);

	if (searchKey)
		Search.init(searchKey);

	resolveOnceInitialized(config);
}

const [onceInitialized, resolveOnceInitialized] = Promise.external();
export { onceInitialized };
