import React from "react";
import Server from "library-react/library-js/app/Server";
import use from "library-react/hook";
import { Brand } from "library-react/library-js/app/model/entity";
import { styles } from "@main/res";
import BrandView from "@main/component/BrandView";
import View from "library-react/component/View/v2";
import Portal from "library-react/component/Portal";
import { screens } from "@main/links";
import BrandSampleView from "./BrandSampleView";

export default function BrandPinView({ item, ...props }) {
	const name = Brand.parseName(item.name);

	const { value: brand, loading } = use.loadable.server(
		() => Server.public.brand.get(name)
			.updateContent(map => map[name]),
		[name]
	);


	const display = use.context.display();
	props.style = use.defaultStyle(props.style, localStyles.layout(display), [display]);

	return (
		(loading || brand) ?
			<View {...props}>

				<Portal
					to={Boolean(brand) && screens.brand}
					params={{ name: brand?.name }}>
					<BrandView brand={brand} style={localStyles.brand(display)} discoverButton />
				</Portal>

				<BrandSampleView
					brand={name}
					contentContainerStyle={localStyles.sample.content(display)}
					style={localStyles.sample.layout(display)} />
			</View> :
			null
	);
}

const localStyles = {
	layout: styles.static(
		{
			flex: 1,
			flexBasis: '100%'
		},
		{
			mobile: {
				flexDirection: styles.flexDirection.column,
			},
			default: {
				flexDirection: styles.flexDirection.row,
			}
		}
	),

	brand: styles.static(
		{
			flex: 1,
		},
		{
			mobile: {
				height: 380,
				marginBottom: 50,
				marginHorizontal: 20,
			},
			default: {
				flexBasis: '60%',
				height: 580,
				marginLeft: 20,
			}
		}
	),

	sample: {
		layout: styles.static(
			{},
			{
				mobile: {},
				default: {
					marginLeft: 50,
				},
			}
		),

		content: styles.static(
			{},
			{
				mobile: {
					paddingHorizontal: 20,
				},
				default: {
				},
			}
		),
	}
}
