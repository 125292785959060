import {setSharableContext} from "./context";
import use from "../../hook";
import useFocusEffect from "../../hook/navigation/useFocusEffect";
import {identity, when} from "ramda";

export default function Sharable({url}) {
	const setSharable = use.context(setSharableContext);

	useFocusEffect(when(identity, () => setSharable(url)), [url]);

	return null;
}
