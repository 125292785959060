import React from "react";
import use from "library-react/hook";
import View from "library-react/component/View";
import ContactItem from "../ContactItem/mobile";
import {styles} from "../../../res";
import createContact from "./abstract";

export default React.memo(
	createContact(
		function ({shop, ...props}, {name, contact, weekOpenHoursText,}) {
			props.style = use.defaultStyle(props.style, localStyles.layout);

			return (
				<View {...props}>
					<ContactItem title={name}
								 icon={'EvilIcons/location'}
								 iconSize={40}
								 iconStyle={{width: 30,}}
								 text={contact}
								 style={localStyles.address}
					/>
					<ContactItem title={'Horaires d’ouverture'}
								 icon={'SimpleLineIcons/clock'}
								 iconSize={30}
								 iconStyle={{width: 30,}}
								 text={weekOpenHoursText}/>
				</View>
			);
		}
	)
);
const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.column,
	},
	address: {
		marginBottom: 50,
	}
};
