import React from "react";
import { View as RNView } from "react-native"

import use from "../../hook";
import { styles } from "../../res";
import Image from "../Image";
import Icon from "../Icon/v2";
import useAuth from "../../hook/useAuth";
import MainButton from "../MainButton";
import LottieView from "lottie-react-native";
import moment from "moment";

export default function ConfirmationForm({ shop, reservation, next, ...props }) {
	const user = useAuth.user();

	const reservationMoment = moment.parseZone(reservation.time);

	const { Text } = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout, [props.style]);
	return (
		<RNView {...props}>
			<RNView style={localStyles.header.layout}>
				<Text style={localStyles.header.title}>
					{`Super ${user?.displayName} !`}
				</Text>

				<LottieView
					source={require('./success-mark.json')}
					autoPlay
					speed={.5}
					loop={false}
					style={[styles.square(150), { alignSelf: 'center' }]}
				/>
				<Text style={localStyles.header.subtitle}>
					{`Votre demande de réservation a bien été enregistrée`}
				</Text>
			</RNView>

			<RNView style={localStyles.summary.layout}>

				<RNView style={{ marginBottom: 30, ...localStyles.summary.item.layout }}>
					<Image source={{ uri: shop.logoUrl }}
						resizeMode="cover"
						style={localStyles.summary.title.logo} />
					<Text style={localStyles.summary.title.name}>
						{shop.name}
					</Text>
				</RNView>


				<RNView style={{ marginBottom: 30, ...localStyles.summary.row }}>
					<RNView style={localStyles.summary.item.layout}>
						<Icon style={localStyles.summary.item.icon} name="reservation-place" size={17} />
						<Text style={localStyles.summary.item.text}>
							{reservation.numberOfPlaces}
						</Text>
					</RNView>
					<RNView style={localStyles.summary.item.layout}>
						<Icon style={localStyles.summary.item.icon} name="reservation-calendar" size={17} />
						<Text style={localStyles.summary.item.text}>
							{reservationMoment.format('ddd D MMMM YYYY')}
						</Text>
					</RNView>
					<RNView style={localStyles.summary.item.layout}>
						<Icon style={localStyles.summary.item.icon} name="reservation-clock" size={17} />
						<Text style={localStyles.summary.item.text}>
							{reservationMoment.format("hh:mm")}
						</Text>
					</RNView>
				</RNView>
				<RNView style={localStyles.summary.row}>
					<RNView style={localStyles.summary.item.layout}>
						<Icon style={localStyles.summary.item.icon} name="reservation-phone" size={17} />
						<Text style={localStyles.summary.item.text}>
							{reservation.phone}
						</Text>
					</RNView>
					<RNView style={localStyles.summary.item.layout}>
						<Text style={localStyles.summary.item.text}>
							{reservation.fullName}
						</Text>
					</RNView>
				</RNView>

			</RNView>

			<Text style={localStyles.footer}>
				{`Merci de respecter l’horaire de votre réservation pour le bon déroulement du service : après 15 minutes de retard sans nouvelles de votre part, la réservation n’est plus maintenue.`}
			</Text>

			<MainButton onPress={next}>
				{`OK`}
			</MainButton>
		</RNView>
	);
}
const localStyles = {
	layout: {
		paddingHorizontal: 13,
		paddingVertical: 30,
		justifyContent: styles.justifyContent.center,
	},
	header: {
		layout: {
			maxWidth: 268,
			marginBottom: 17,
			alignSelf: styles.alignSelf.center,
		},
		title: {
			fontSize: 25,
			bold: true,
			color: "#080808",
			textAlign: styles.textAlign.center,
		},
		subtitle: {
			fontSize: 17,
			color: "#080808",
			textAlign: styles.textAlign.center,
		},
	},
	summary: {
		layout: {
			flexDirection: styles.flexDirection.column,
			backgroundColor: "#f7f7f7",
			flex: 1,
			alignSelf: styles.alignSelf.stretch,
			alignItems: styles.alignItems.center,
			paddingVertical: 13,
			paddingHorizontal: 20,
			marginBottom: 17,
		},
		title: {
			logo: {
				width: 23,
				height: 23,
				...styles.circle(22),
				marginRight: 10,
			},
			name: {
				fontSize: 17,
				color: "#080808"
			},
		},
		row: {
			flexDirection: styles.flexDirection.row,
			alignSelf: styles.alignSelf.stretch,
			justifyContent: styles.justifyContent.spaceEvenly,
		},

		item: {
			layout: {
				flexDirection: styles.flexDirection.row,
				...styles.center,
			},
			icon: {
				width: 20,
				height: 20,
				marginRight: 6,
			},
			text: {
				fontSize: 14,
				bold: true,
				color: "#000000"
			},
		},
	},
	footer: {
		fontSize: 12,
		color: "#080808",
		textAlign: styles.textAlign.center,
		marginBottom: 21,
		maxWidth: 330,
		alignSelf: styles.alignSelf.center,
	},
}