import Base from "../../../../class/Base"
import KYCLevel from "./KYCLevel"
import PersonType from "./PersonType"
import LegalPersonType from "./LegalPersonType"
import {MangoPayAddressField, validateMangoPayAddress} from "./Address"
import simpleValidate from "../../../../utils/simpleValidate";
import moment from "moment";
import retrieveInvalidFields from "../../../../utils/formValidator/retrieveInvalidFields";

export default class LegalUser extends Base {
	get LegalRepresentativeBirthday(){
		return Math.trunc(this.legalRepresentativeBirthday / 1000);
	}

	set LegalRepresentativeBirthday(timestampInSecs){
		this.legalRepresentativeBirthday = Math.trunc(timestampInSecs * 1000);
	}

	getPayOutInvalidFields() {
		return retrieveInvalidFields(LegalUser.generateValidatePayOutOf, this);
	}

	getInvalidFields(){
		return retrieveInvalidFields(LegalUser.validator, this);
	}

	toJSON(){
		const json = super.toJSON();
		json.LegalRepresentativeBirthday = this.LegalRepresentativeBirthday;
		return json;
	}
}

LegalUser.addProperties({
	Id : String,
	CreationDate : Number,
	Tag : String,
	PersonType : PersonType,
	Email : String,
	KYCLevel : KYCLevel,
	HeadquartersAddress : MangoPayAddressField,
	LegalPersonType : LegalPersonType,
	Name : String,
	LegalRepresentativeAddress : MangoPayAddressField,
	legalRepresentativeBirthday : Number,
	LegalRepresentativeCountryOfResidence : String,
	LegalRepresentativeNationality : String,
	LegalRepresentativeEmail : String,
	LegalRepresentativeFirstName : String,
	LegalRepresentativeLastName : String,
	LegalRepresentativeProofOfIdentity : String,
	Statute : String,
	ShareholderDeclaration : String,
	ProofOfRegistration : String,
	CompanyNumber : String,
});

LegalUser.validator = LegalUser.generateValidateOf = {
	Name: () => simpleValidate.text,
	Email: () => simpleValidate.email,
	// LegalRepresentativeBirthday: () => timestampInSecs => LegalUser.generateValidateOf.legalRepresentativeBirthday()(timestampInSecs * 1000),
	legalRepresentativeBirthday: () => (timestamp) => {
		const difference = Math.abs(timestamp - Date.now());
		return Boolean(
			!isNaN(timestamp) &&
			moment.duration(difference).asYears() >= 16 // 16 years old
		);
	},
	LegalRepresentativeCountryOfResidence:() => simpleValidate.text,
	LegalRepresentativeNationality:() => simpleValidate.text,
	LegalRepresentativeFirstName:() => simpleValidate.text,
	LegalRepresentativeLastName:() => simpleValidate.text,
	LegalRepresentativeEmail:() => simpleValidate.text,
	LegalRepresentativeAddress: () => validateMangoPayAddress,
	HeadquartersAddress: () => validateMangoPayAddress,
};

LegalUser.generateValidatePayOutOf = {
	...LegalUser.generateValidateOf,
	CompanyNumber: () => simpleValidate.text,
};
