import Media from "./Media"
import Environment from "../../../Environment"


export default class Video extends Media {
	/**
	 * Returns the datas of a video asynchronously.
	 * @param {String} url Url of the video.
	 * @return {Promise<{height, width}>} Promise of the video's datas.
	 */
	static loadDatas(url){
		return Environment.run({
			web(){
				return new Promise(resolve => {
					let video = document.createElement('video');
					video.preload="metadata";
					video.addEventListener("loadedmetadata", function() {
						resolve({
							height : video.videoHeight,
							width : video.videoWidth,
						});
					}, false);
					video.src = url;
				});
			},

			default(){
				return Promise.resolve();
			}
		});
	}

	onReset(){
		super.onReset();
		this.width = null;
		this.height = null;
		this.duration = null;
	}

	onLoadDatas(url){
		return Video.loadDatas(url);
	}

	get hasDatas(){
		return super.hasDatas
			&& Boolean(
				this.width
				&& this.height
				// && this.duration
			);
	}
}

Video.TYPE = "video";

Video.addProperties({
	width : Number,
	height : Number,
	duration : Number,
});
