import React from "react"
import { View as RNView } from "react-native"
import use from "library-react/hook"
import { styles } from "../../../res"
import ChatButton from "./Button";
import ChatWindow from "./ChatWindow";
import PromiseQueue from "library-js/utils/PromiseQueue";
import { ChatManager } from "library-js/app/model/chat";
import useApp from "library-react/app/useApp";
import { screens } from "@main/links";
import { map } from "ramda";
import { parallel } from "library-js/utils/function";
import createStackController from "library-react/utils/createStackController";

function Chat({ ...props }, refProp) {
	const { navigation } = useApp();
	const [opened, setOpened] = use.state.bool(() => {
		const route = navigation?.getCurrentRoute();
		return route?.params?.chat;
	});


	const instancePromises = use.memo(() => new PromiseQueue);
	const { vShop } = use.context.infos();

	const open = use.callback(async () => { // must always return a promise
		ChatManager.onRead(vShop.id);
		setOpened(true);

		if (instances.chatWindow)
			return instances.chatWindow;

		return instancePromises.newPromise();
	});

	const close = setOpened.false;
	use.effect(() => {
		if (opened)
			return window.on("popstate", close);
	}, [opened]);


	const display = use.context.display();
	const app = useApp();

	const navigationCallbacks = use.memo((isMobile) => {
		let navigates = {
			onProductClicked: (product) => app.navigation?.navigate(screens.product, { id: product.id }),
			onOrderClicked: (vOrder) => app.navigation?.navigate(screens.order, { id: vOrder.id }),
			onOrderItemClicked: (vOrderItem) => app.navigation?.navigate(screens.order, { id: vOrderItem.item.orderId }),
		};

		if (isMobile)
			navigates = map(navigate => parallel(close, navigate), navigates);

		return navigates;
	},
		[display.is.mobile]
	);


	const instances = use.instances({
		chatWindow: instance => {
			if (instance)
				instancePromises.resolveAll(instance);
		}
	});

	use.setRef(refProp, () => ({
		open,
		close,

		setAttachment(attachment) {
			if (instances.chatWindow)
				instances.chatWindow.setAttachment(attachment);
			else
				open().then(chatWindow => chatWindow.setAttachment(attachment));
		}
	}));

	props.style = use.defaultStyle(
		localStyles.layout[opened](display),
		props.style,
		[opened, display],
	);

	return (
		<RNView {...props}>
			{
				opened &&
				<ChatWindow
					ref={instances.set.chatWindow}
					{...navigationCallbacks}
					close={close}
					style={localStyles.fragment(display)} />
			}

			{
				!(display.is.mobile && opened) &&
				<ChatButton
					opened={opened}
					onPress={opened ? close : open}
					style={localStyles.button(display)} />
			}
		</RNView>
	);
}

const [ChatProvider, useChatControls, ChatController] = createStackController(["suggestedAttachment"]);
export { ChatProvider, ChatController, useChatControls };

export default React.memo(React.forwardRef(Chat));

const localStyles = {
	layout: {
		true: styles.static(
			{
				justifyContent: styles.justifyContent.flexEnd,
			},
			{
				mobile: styles.fit,

				default: {
					height: 700,
				},
			}
		),

		false: styles.static(
			{},
			{
				mobile: {},

				default: {},
			}
		)
	},

	fragment: styles.static(
		{ flex: 1 },
		{
			mobile: {},

			default: {
				...styles.newShadow(0, 8, 20, .16)
			}
		}
	),

	button: styles.static(
		{
			alignSelf: styles.alignSelf.flexEnd,
		},
		{
			default: {}
		}
	)
};
