import React from "react";
import View from "library-react/component/View/v2";
import Image from "library-react/component/Image";
import LoadingPlaceholder from "library-react/component/LoadingPlaceholder";
import {strings, styles} from "@main/res";
import fonts from "@main/res/fonts";
import use from "../../../library-react/hook";
import Text from "library-react/component/Text/v2";

export default function DesktopUI({item: vShowcase, image, inPortal, ...props}) {
	const {Text} = use.theme();

	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<View {...props}>
			{
				inPortal(
					<LoadingPlaceholder disabled={vShowcase} style={localStyles.image.wrapper}>
						<Image source={{uri: image?.url, width: 1500}} dataSet={{'zoom-on-hover': true}}
							   style={styles.fit}/>
					</LoadingPlaceholder>
				)
			}

			{
				inPortal(
					<Text style={localStyles.title} numberOfLines={1}>{vShowcase?.title}</Text>
				)
			}

			<LoadingPlaceholder disabled={vShowcase} style={localStyles.wrapper}>
				<Text style={localStyles.description}
					  numberOfLines={3}>{vShowcase?.description || strings.invisibleChar}</Text>
			</LoadingPlaceholder>
		</View>
	);
}

const localStyles = {
	layout: {},

	image: {
		wrapper: {
			height: 600,
			overflow: styles.overflow.hidden,
		},
	},

	wrapper: {
		borderRadius: 5,
		minWidth: 300,
		alignSelf: styles.alignSelf.center,
	},

	title: {
		paddingTop: 27,
		marginBottom: 13,
		fontSize: 68,
		bold: true,
		textAlign: styles.textAlign.center,
		fontFamily: fonts.BebasNeue,
	},

	description: {
		fontSize: 20,
		alignSelf: styles.alignSelf.center,
		textAlign: styles.textAlign.center,
		lineHeight: 36,
		maxWidth: '65%',
	},
}
