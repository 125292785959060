import React from "react";
import CatalogSectionView from "@main/component/CatalogSectionView";
import {styles} from "@main/res";
import ScrollView from "library-react/component/ScrollView";
import View from "library-react/component/View/v2";
import CatalogSection from "library-react/library-js/app/model/entity/CatalogSection";
import use from "library-react/hook";
import Title from "./Title";
import Portal from "library-react/component/Portal";
import {screens} from "@main/links";
import {NO_SCROLLBAR_TAG} from "@src/cssTags";

export default function SectionPinView({item: sectionPin, ...props}) {

	const display = use.context.display();
	const title = sectionPin.title || (
		sectionPin?.name instanceof CatalogSection ?
			sectionPin?.name.path.last :
			sectionPin?.name?.trim().split('/').filter(Boolean).last
	)?.toUpperCase();

	props.style = use.defaultStyle(props.style, localStyles.layout);

	const [displayTitle, setDisplayTitle] = use.state.bool();

	const onItemsLoaded = use.callback(content => setDisplayTitle(content?.length))

	return (
		<View {...props}>
			{
				displayTitle &&
				<Portal to={screens.catalog} params={{section: sectionPin?.name?.toString()}}>
					<Title style={localStyles.title}>
						{title}
					</Title>
				</Portal>
			}
			<ScrollView
				horizontal
				dataSet={{[NO_SCROLLBAR_TAG]: 1}}
				contentContainerStyle={localStyles.content(display)}>
				<CatalogSectionView
					loading
					onItemsLoaded={onItemsLoaded}
					section={sectionPin?.name}
					seeMoreOnLastProduct
					noCards
					titleStyle={localStyles.disableTitle}
					gap={display.is.mobile ? 48 : 37}
					contentStyle={localStyles.catalog.content}
					style={localStyles.catalog.layout}
				/>
			</ScrollView>
		</View>
	);
}

const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.column,
		flex: 1,
		flexBasis: '100%',
	},

	title: {
		paddingLeft: 20,
	},
	content: styles.static(
		{},
		{
			mobile: {
				paddingHorizontal: 20,
			},
			default: {
				flexBasis: '100%',
				paddingLeft: 20,
			},
		}
	),
	catalog: {
		layout: {
			flexBasis: '100%',
			paddingVertical: 10,
		},
		content: {
		},

	},
	disableTitle: {
		display: styles.display.none,
	}
}
