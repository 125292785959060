import Address from "../../Address"
import simpleValidate from "../../../../utils/simpleValidate";
import {is} from "../../../../utils";

export default class MangoPayAddress extends Address {
	get AddressLine1() {
		if (is.defined(this.route) || is.defined(this.streetNumber))
			return (this.streetNumber ? this.streetNumber + " " : "") +
				(this.route ? this.route : "");
	}

	set AddressLine1(line) {
		console.warn('MangoPay.Address.AddressLine1 should not be set directly. Set streetNumber & route instead.');
		this.streetNumber = undefined;
		this.route = line;
	}

	get City() {
		return this.locality;
	}

	set City(locality) {
		this.locality = locality;
	}

	get PostalCode() {
		return this.postalCode;
	}

	set PostalCode(postalCode) {
		this.postalCode = postalCode;
	}

	get Country() {
		return this.country;
	}

	set Country(country) {
		this.country = country;
	}

	getInvalidFields() {
		return Object.entries(MangoPayAddress.generateValidateOf)
			.filter(([, validator]) => !validator.length) // remove validator that need arguments
			.filter(([field, getValidator]) => !getValidator().apply(this, [this[field], field]))
			.map(([field]) => field);
	}

	isValid() {
		return this.getInvalidFields()?.length === 0;
	}

	toJSON() {
		const json = super.toJSON();
		['AddressLine1', 'City', 'PostalCode', 'Country']
			.forEach(field => json[field] = this[field]);
		return json;
	}
}

MangoPayAddress.addProperties({
	AddressLine2: String,
	Region: String,
});

MangoPayAddress.generateValidateOf = {
	AddressLine1: () => simpleValidate.text,
	City: () => simpleValidate.text,
	PostalCode: () => simpleValidate.text,
	Country: () => simpleValidate.text,
};

export const MangoPayAddressField = {
	type: MangoPayAddress,
	set: function (address, {throwInvalidTypeError}) {
		if (is(address, MangoPayAddress, true))
			return address;

		if (address instanceof Address)
			return toMangoPayAddress(address);

		throwInvalidTypeError();
	}
};

export const validateMangoPayAddress = (address) => {
	if ((address instanceof Address) && !(address instanceof MangoPayAddress))
		address = toMangoPayAddress(address);

	if (address instanceof MangoPayAddress)
		return address.isValid();
};

function toMangoPayAddress(address) {
	return MangoPayAddress.from(address.toJSON());
}
