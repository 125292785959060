
const screens = {
	reception: "reception",
	payment: "payment",
	contact: "contact",
	summary: "summary"
};

export default screens;

export const linking = {
	screens: {
		reception: 'reception',
		payment: 'payment',
		contact: 'contact',
	},
};
