import React from "react"
import { View as RNView } from "react-native"
import Image from "library-react/component/Image/index.native";
import Icon from "library-react/component/Icon/v2";
import use from "library-react/hook";
import { styles } from "@main/res"
import { screens } from "@main/links";
import Portal from "library-react/component/Portal";

export default function HomeDesktop(
	{
		vShop, config,
		banner,
		shouldDisplayTitle, title, subtitle,
		...props
	}) {

	let link = config?.home?.link;
	let LinkComponent = link ? A : Portal;

	const { Text } = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<LinkComponent to={link || screens.catalog}>
			<RNView {...props}>
				<Image
					source={banner}
					resizeMode="cover"
					style={localStyles.image}
				/>
				{
					shouldDisplayTitle &&
					<RNView style={localStyles.banner.layout}>
						<Text
							accessibilityRole="heading"
							style={localStyles.banner.title}>
							{title}
						</Text>

						<RNView style={localStyles.banner.subtitle.layout}>
							<Text style={localStyles.banner.subtitle.text}>
								{subtitle}
							</Text>

							<Icon name='MaterialCommunityIcons/arrow-right'
								size={25} />
						</RNView>
					</RNView>
				}
			</RNView>
		</LinkComponent>
	);
}

function A({ to, children }) {
	return (
		<a href={to}>{children}</a>
	);
}

const localStyles = {
	layout: {
		...styles.fit,
		...styles.center,
	},

	image: {
		zIndex: -1,
		...styles.fit,
	},

	banner: {
		layout: {
			backgroundColor: "rgba(255,255,255,0.68)",
			backdropFilter: `saturate(180%) blur(${10}px)`,
			paddingHorizontal: 50,
			paddingTop: 20,
			paddingBottom: 30,
		},

		title: {
			fontSize: 42
		},

		subtitle: {
			layout: {
				flexDirection: styles.flexDirection.row,
				alignItems: styles.alignItems.center,
				justifyContent: styles.justifyContent.spaceBetween,
			},
			text: {
				fontSize: 18,
				marginRight: 20
			},
		}
	}
};
