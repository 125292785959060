import React from "react"
import use from "library-react/hook"
import {styles} from "../../../res"
import Icon from "library-react/component/Icon/v2";
import View from "library-react/component/View/v2";
import ChatCounterLabel from "@main/component/ChatLabelCounter";
import GradientView from "library-react/component/GradientView";

function ChatButton({opened, ...props}) {
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<View
			{...props}>
			<GradientView
				start={{x: 0, y: 0}}
				end={{x: 0, y: 1}}
				colors={['#6200ea', '#1976d2']}
				style={[styles.fit, styles.circle(SIZE)]}
			/>

			<Icon
				name={opened ? "EvilIcons/close" : "chat"}
				size={24}
				style={{
					color: opened ?
						styles.color.white :
						[styles.color.white, "rgb(21, 146, 236)", styles.color.white]
				}}
			/>

			{
				!opened &&
				<ChatCounterLabel style={localStyles.label}/>
			}
		</View>
	);
}

export default React.memo(ChatButton);

const SIZE = 52;

const localStyles = {
	layout: {
		...styles.center,
		marginTop: 20,
		...styles.circle(SIZE),
		overflow: styles.overflow.visible,
	},


	label: styles.absolute({top: -5, right: -5})
};
