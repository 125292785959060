import MobileUI from "./MobileUI";
import DesktopUI from "./DesktopUI";
import use from "library-react/hook";

export default function ReservationRow(props){
	const display = use.context.display();

	return (
		display.is.mobile ?
			<MobileUI {...props}/> :
			<DesktopUI {...props}/>
	)
}

ReservationRow.Header = function Header(props){
	const display = use.context.display();

	return (
		display.is.mobile ?
			<MobileUI.Header {...props}/> :
			<DesktopUI.Header {...props}/>
	)
}