import {user as Server} from "../../Server";
import {Trigger} from "../../../utils";
import Functions from "../../../utils/Functions";
import AuthManager from "../../../AuthManager";
import Platform from "../../../Platform";
import {VBasket} from "../../model/view/user";
import debounce from "../../../utils/debounce";

const BasketManager = {
	set shopId(id) {
		_shopId = id;
		this.reset();
	},

	get basketFilled() {
		return _shopId ?
			basketFilled : // int
			Boolean(basketFilled);
	},

	listenNumberOfItems(listener) {
		return trigger.add(listener);
	},


	// --- app user only ----
	async loadBasket() {
		const response = await Server.basket.getList();
		if (response.ok && !_shopId)
			setBasketFilled(response.content.length > 0);

		return response;
	},

	getIterator() {
		const iterator = Server.basket.getList.getIterator();

		if (!_shopId)
			iterator.load = Functions.append(
				iterator.load,
				(promise, page) => {
					promise.then(response => {
						if (response.ok) {
							const hasItems = response.content.length > 0;
							if (hasItems || page === 0)
								setBasketFilled(hasItems);
						}
					});

					return promise;
				}
			);

		return iterator;
	},

	getItemsIn(shopId, limit) {
		return Server.basket.getItems(shopId, limit)
			.transparent(getFilledFromLoadedItems(shopId));
	},

	// -------

	async getItemsForProductInShop(productId, shopId = _shopId) {
		const response = await Server.basket.getItemsForProductInShop(productId, shopId);

		if (response.ok && response.content?.first) {
			if (_shopId) // shop
				setBasketFilled(response.content.first);
			else // app user
				setBasketFilled(true);
		}

		return response;
	},

	async persist(shopId, productId, size, quantity) {
		const response = await Server.basket.persistItem(shopId, productId, size, quantity);
		if (response.ok) {
			if (_shopId === shopId) { // for shop only
				const basket = response.content?.basket;
				if (basket)
					setBasketFilled(basket);
				else if (!(quantity > 0))
					setBasketFilled(basketFilled - 1);
			} else { // for user app
				if (quantity > 0)
					setBasketFilled(true);
				else
					BasketManager.sync();
			}
		} else
			response.log();

		return response;
	},

	async remove(shopId, productId, size) {
		return this.persist(shopId, productId, size, 0);
	},

	sync() {
		if (_shopId) // shop only
			return BasketManager.getItemsIn(_shopId, 150);

		// user app
		return BasketManager.loadBasket();
	},

	reset() {
		basketFilled = null;
	},
};

export default BasketManager;


const trigger = new Trigger;
let _shopId = null;
let basketFilled = null; // number

// set & fire if changed
function setBasketFilled(data) {
	let filled = data;

	if (_shopId) { // shop only
		if (data instanceof VBasket.Item)
			data = data.basket;

		if (data instanceof VBasket)
			filled = data.numberOfItems;
	}


	if (basketFilled !== filled) {
		basketFilled = filled;
		trigger.fire(BasketManager.basketFilled);
	}
}


function getFilledFromLoadedItems(forShopId){
	return function (response){
		if (!response.ok) return;

		// for shop only
		if (_shopId){
			if (!forShopId || _shopId === forShopId){
				const item = response.content?.first;
				setBasketFilled(item || 0);
			}
		}

		// for user app
		else if (!basketFilled && response.content?.length > 0)
			setBasketFilled(true);
	}
}


// reset automatically on user change
Platform.whenReady.then(() => {
	if (Platform.is.user) {
		const synchronize = debounce(BasketManager.sync,100);

		AuthManager.onUserChanged(user => {
			BasketManager.reset();
			if (user)
				synchronize();
		});

		if (AuthManager.user)
			synchronize();
	}
});
