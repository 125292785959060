import * as SVG from "react-native-svg";
import React from "react";
import Server from "../../library-js/app/Server";

const icons = {};
export function add(set){
	Object.assign(icons, set( /* inject SVG & react dependencies */ SVG, React));
}

export default icons;


// add shop categories icons
Server.public.shop.getCategories.addIcon = category => {
	const iconName = `shop.category.${category.name}`;

	add({[iconName]: evalIcon(category.icon)});

	return iconName;
};
