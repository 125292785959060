import { curry } from "ramda";

/**
 * @param {(input: any) => void} setState 
 * @param {Promise<any>} promise 
 */
function bindPromiseToBoolState(setState, promise) {
	setState(Boolean(promise));
	promise?.finally(() => setState(false));
}

export default curry(bindPromiseToBoolState);