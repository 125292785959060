import Base from "../../../../class/Base"
import Prodshop from "../../Prodshop"
import VProduct from "./VProduct"
import VShop from "./VShop"
import VSale from "./VSale"

export default class VProdshop extends Base {
	constructor() {
		super();
		this.vProduct = new VProduct();
	}
	get id(){
		return this.prodshop && this.prodshop.id;
	}
	/**
	 * Shortcut for this.vProduct.product.
	 */
	get product() {
		return this.vProduct?.product;
	}
	get rate() {
		return this.vProduct?.rate;
	}
	/**
	 * Shortcut for this.vShop.shop.
	 */
	get shop() {
		return this.vShop?.shop;
	}

	get sale(){
		return this.vSaleProduct?.sale;
	}
	/**
	 * Give the sale price if there is else the prodshop one.
	 */
	get price(){
		return this.prodshop?.price;
	}

	get finalPrice(){
		return this.prodshop?.finalPrice;
	}

	get oldPrice(){
		return this.prodshop?.oldPrice;
	}

	get discount(){
		return this.prodshop?.discount;
	}

	/**
	 * Shortcut for this.vSaleProduct.saleProduct.
	 */
	get saleProduct() {
		return this.vSaleProduct && this.vSaleProduct.saleProduct;
	}

	/**
	 * check if there is an active sale for the prodshop.
	 */
	get saleActive() {
		return this.vSaleProduct && this.vSaleProduct.sale && this.vSaleProduct.sale.active;
	}

	get quantity() {
		return this.vSaleProduct && this.vSaleProduct.quantity
	}

	get inBasket() {
		return this.vSaleProduct &&  this.vSaleProduct.inBasket
	}

	get available() {
		return this.prodshop?.available;
	}

	get reserved() {
		return this.vSaleProduct && !this.quantity && Boolean(this.inBasket);
	}

	get unavailable() {
		return this.vSaleProduct && !this.quantity && !this.inBasket;
	}
}
VProdshop.addProperties({
	vProduct: VProduct,
	vShop: VShop,
	prodshop: Prodshop,
	vSaleProduct: VSale.Product,
	sections: {
		type: Array,
		template: String,
	},
});