import Base from "../../../../class/Base"
import Address from "./Address"
import {pipe, trim} from "ramda";
import simpleValidate from "../../../../utils/simpleValidate";

export default class NaturalUser extends Base {
	get valid() {
		return this.validate();
	}

	validate() {
		return this.getInvalidFields().length === 0;
	}

	getInvalidFields() {
		return Object.entries(this.constructor.validator)
			.filter(([, willValidate]) => !willValidate.length) // remove validates with dependencies
			.filter(([key, willValidate]) => !willValidate().apply(this, [this[key], key]))
			.map(([key]) => key);
	}
}

NaturalUser.addProperties({
	Tag: String,
	FirstName: String,
	LastName: String,
	Address: Address,
	Birthday: Number,
	Nationality: String,
	CountryOfResidence: String,
	Occupation: String,
	IncomeRange: String,
	Email: String,
	Capacity: String
});

NaturalUser.validator = {
	FirstName: () => pipe(trim, Boolean),
	LastName: () => pipe(trim, Boolean),
	Nationality: () => pipe(trim, Boolean),
	Birthday: () => isFinite,
	CountryOfResidence: () => pipe(trim, Boolean),
	Email: () => simpleValidate.email,
};
