import {Base} from "../../../../class";
import {Image} from "../../media";
import simpleValidate from "../../../../utils/simpleValidate";

export default class ShowcaseArticle extends Base {
	getInvalidFields(){
		return Object.entries(ShowcaseArticle.generateValidateOf)
			.filter(([, validator]) => !validator.length) // remove validator that need arguments
			.filter(([field, getValidator]) => !getValidator().apply(this, [this[field], field]))
			.map(([field]) => field);
	}
}

ShowcaseArticle.addProperties({
	title: String,
	description: String,
	media: Image,
})
ShowcaseArticle.generateValidateOf = {
	media: () => () => true,
	title: () => text => {
		if (simpleValidate.text(text))
			return text.length <= 40;
	},
	description: () => text => {
		if (simpleValidate.text(text))
			return text.length >= 100;
	},
};