import { AppState } from "react-native";

const States = ["active", "background", "inactive"];
States.forEach(state => AppState[state] = state);

let onActivePromise, onInactive;
Object.defineProperties(AppState, {
	is: {
		configurable: true,
		get: function () {
			return States.toObject(state => state, state => this.currentState === state);
		}
	},

	// @deprecated
	isActive: {
		configurable: true,
		get: function () {
			return this.is.active;
		}
	},

	on: {
		configurable: true,
		get: function () {
			return {
				get active() {
					if (AppState.isActive)
						return Promise.resolve();

					if (!onActivePromise)
						onActivePromise = new Promise(resolve => {
							const remove = subscribe((appState) => {
								if (appState === AppState.active) {
									remove();
									onActivePromise = null;
									resolve();
								}
							});
						});

					return onActivePromise;
				},

				get inactive() {
					if (!AppState.isActive)
						return Promise.resolve();

					if (!onInactive)
						onInactive = new Promise(resolve => {
							const remove = subscribe((appState) => {
								if (appState !== AppState.active) {
									remove();
									onInactive = null;
									resolve();
								}
							});
						});

					return onInactive;
				}
			}
		}
	},

	listen: {
		configurable: true,
		value: listener => subscribe(() => listener(AppState.is)),
	},
});

function subscribe(handler) {
	const subscription = AppState.addEventListener('change', handler);

	if (subscription)
		return () => subscription.remove();
	return () => AppState.removeEventListener('change', handler);
}