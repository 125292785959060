import { isNil } from "ramda";

export default function pen(texts, ...variables) {
	return texts.reduce((result, text, index) => {
		if (!index) return text;

		const variable = variables[index - 1];
		return result + safeText(variable) + text;
	}, "");
};

function safeText(variable) {
	if (isNil(variable) || variable === false)
		return "";

	return variable;
}
