import React from "react";
import Desktop from "./desktop";
import Mobile from "./mobile";
import use from "library-react/hook";

export default React.memo(
	function Header(props){
		use.subscribe(render => props.rateLoadable?.onStateChanged(render), [props.rateLoadable]);
		const display = use.context.display();

		return (
			display.is.mobile ?
				<Mobile {...props} /> :
				<Desktop {...props} />
		);
	}
)
