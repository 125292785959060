import React from "react"

/**
 * Like React's useRef but you can edit any other properties than "current".
 * @param initialValue Initial "current" value.
 * @returns Object Always the same instance of an object.
 * @deprecated use useMemo instead.
 */
export default function useRef(initialValue){
	return React.useRef(() => ({current: initialValue})).current;
}
