import config from "./config"
import Server from "../../../Server"
import Debug from "../../../../Debug"
import InfiniteIterator from "../../../../InfiniteIterator"
import {Order, Product} from "../../entity"
import server from "./server";
import {entryToChat, entryToEvent, Paths} from "./utils";
import {listenChildrenOfRef, listenersRemovers, listenValueOfRef} from "./sync";
import {ChatIterator, EventIterator} from "./iterator";
import pipe from "../../../../utils/function/pipe";

const ChatManager = {
	/**
	 * Initialize the module.
	 * @param {firebase} firebase Firebase module.
	 * @param {String|Number} chatMemberId Chat id of the current user.
	 * @returns {Boolean} True if initialized. False otherwise.
	 */
	init(firebase, chatMemberId){
		// check parameters
		Debug.assert(!firebase, "ChatManager.init must take a non-null firebase module as 1st parameter.");
		Debug.assert(!chatMemberId, "ChatManager.init must take a non-null chatMemberId as 2nd parameter.");

		config.firebase = firebase;
		config.id = String(chatMemberId); // cast to string

		return this.isInit;
	},

	get isInit(){
		return Boolean(config.id && config.firebase);
	},

	get id(){
		return config.id;
	},

	/**
	 * Send an event to a recipient.
	 * @param {String|Number} recipientId Id of the recipient of the event to send.
	 * @param {String} text Event to send.
	 * @param {Order.Item | Product} [attachment] Attachment to the message.
	 * @return {Promise} A promise of the server response {@link Server.Response}.
	 */
	send(recipientId, text, attachment){
		return server.send(recipientId, text, attachment);
	},

	/**
	 * Notify server that the user read a chat.
	 * You should call this function when a chat/message is being read by the user.
	 * @param {String|Number} interlocutorId Id of the interlocutor of the chat read.
	 * @return {Promise} A promise of the server response {@link Server.Response}.
	 */
	onRead(interlocutorId){
		return server.onRead(interlocutorId);
	},

	/**
	 * @returns {InfiniteIterator} Iterator to load chat page after page, managing cursor itself.
	 */
	getChatIterator(){
		return new ChatIterator();
	},

	/**
	 * @param interlocutorId Interlocutor's id to target chat to load from.
	 * @returns {InfiniteIterator} Iterator to load events page after page, managing cursor itself.
	 */
	getEventIterator(interlocutorId){
		return new EventIterator(interlocutorId);
	},

	listenEvents(interlocutorId, onEvent) {
		return listenChildrenOfRef(
			config.firebase.database()
				.ref(Paths.chat(interlocutorId).timeline)
				.orderByKey(),

			entry => {
				try {
					if (entry.value){
						const event = entryToEvent(entry);
						if (event?.ok)
							onEvent(event);
					}
				} catch(error){
					console.warn(error)
				}
			}
		);
	},

	/**
	 * Listen for notifications at an app scope.
	 * @param onNewAppNotification ({unread: Number}) => void. The object received as parameter contains a field `unread` which is the number of unread messages.
	 * @return Function Function to remove listener.
	 */
	listenApp(onNewAppNotification){
		return listenValueOfRef(
			config.firebase.database()
				.ref(Paths.app),
			entry => {
				onNewAppNotification({
					...(entry?.value || {}),
					unread: Object.keys(entry?.value?.unread || {}).length,
				});
			}
		);
	},

	syncChat(interlocutorId, onChange){
		return listenValueOfRef(
			config.firebase.database()
				.ref(Paths.oneChat(interlocutorId)),
			entry => {
				if (entry?.value)
					pipe([entryToChat, onChange])(entry)
			},
		);
	},

	/**
	 * Starts listening for new/changed chats.
	 * @param {Function} onNewChat Callback to trigger. It receives the new/changes chats.
	 * @return {Function} The function to remove the listener.
	 */
	listenChats(onNewChat){
		return listenChildrenOfRef(
			// ref
			config.firebase.database()
				.ref(Paths.list)
				.orderByChild("lastEventTime"),

			entry => {
				let chat;
				try {
					chat = entryToChat(entry);
				} catch (error) {
					console.warn(error);
				}

				if (chat && chat.ok)
					onNewChat(chat);
			}
		);
	},

	listenInterlocutor(interlocutorId, onInfosChanged){
		return listenValueOfRef(
			config.firebase.database()
				.ref(Paths.chat(interlocutorId).infos),
			onInfosChanged
		);
	},

	/**
	 * Reset the module:
	 * Un-initialize the module, and remove all previous placed listeners.
	 * You should use this method when the user disconnect.
	 */
	reset(){
		// remove values
		config.id = null;

		// remove all listeners
		listenersRemovers.forEach(removeListener => removeListener());
	}
};

export default ChatManager;
