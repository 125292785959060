import use from "../../hook";
import abstractComponent from "../abstract/abstractComponent";
import Selection from "../abstract/Selection";

export default abstractComponent(render =>
	Selection((props, selection) => {
			const {setSelected, items} = selection;

			const next = use.callback(() =>
					setSelected(selected => (selected + 1) % items.length),
				[setSelected, items],
			);
			const back = use.callback(() =>
					//add items length to prevent case of selected being 0
					setSelected(selected => (items.length + selected - 1) % items.length),
				[setSelected, items],
			);

			return render(
				props,
				Object.assign(
					selection,
					{next, back}
				)
			);
		},
		{defaultSelected: 0}
	)
);
