import React from "react"
import use from "library-react/hook";
import ReservationRow from "@main/ui/reservation/ReservationListUI/ReservationRow";
import {View as RNView} from "react-native";
import BackNavigationButton from "@main/component/BackNavigationButton";
import {styles} from "@main/res";
import FlatList from "library-react/component/FlatList";
import {isNil} from "ramda";

export default function ReservationListUI({iterator, cancel, handling, ...props}) {
	const {Text, Spinner} = use.theme();

	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<FlatList
			ListHeaderComponent={() =>
				<RNView style={localStyles.header}>
					<RNView style={localStyles.title.layout}>
						<BackNavigationButton/>

						<Text style={localStyles.title.text}>
							{`RESERVATIONS`}
						</Text>
					</RNView>
					{
						!isNil(iterator) && !iterator.loading &&
						(
							iterator?.items?.length ?
								<ReservationRow.Header style={localStyles.row(true)}/> :
								<Text style={localStyles.empty}>
									{`Vous n'avez passé aucune reservation.`}
								</Text>
						)
					}
				</RNView>
			}
			stickyHeaderIndices={[0]}
			data={iterator?.items}
			keyExtractor={item => String(item.id)}
			renderItem={({item, index}) =>
				<ReservationRow key={item.id} item={item}
								canceling={handling[item.id]}
								cancel={cancel}
								style={localStyles.row(index % 2)}/>
			}
			onEndReachedThreshold={.0001}
			onEndReached={iterator?.ready ? iterator.load : undefined}
			ListFooterComponent={() => (!iterator || (!iterator.end && !iterator.failed)) && <Spinner size={20}/>}
			contentContainerStyle={localStyles.content}
			{...props}
		/>
	)
}

const localStyles = {
	layout: {},
	header: {
		backgroundColor: styles.color.white,
		borderBottomColor: '#f7f7f7',
		borderBottomWidth: .5,
	},

	title: {
		layout: {
			flexDirection: styles.flexDirection.row,
			alignItems: styles.alignItems.center,
			paddingBottom: 35,
			paddingTop: 24,
			paddingLeft: 31,
		},

		text: {
			fontSize: 40,
			bold: true,
			paddingLeft: 35,
		},
	},
	row: styles.static.bool(
		{
			marginLeft: 20,
		},
		{},
		{
			backgroundColor: '#f7f7f7'
		}
	),
	empty: {
		fontSize: 20,
		padding: 20,
		margin: 20,
	}
}