import React from "react"
import {View as RNView} from "react-native"
import use from "library-react/hook"
import {styles} from "@main/res"
import {ChatManager} from "library-js/app/model/chat";
import useAuth from "library-react/hook/useAuth";

function ChatLabelCounter({textStyle, ...props}) {
	const [count, setCount] = use.state(null);

	const user = useAuth.user();
	const shopId = use.context.infos().vShop.id;
	use.effect(() =>
		user &&
		ChatManager.syncChat( shopId, chat =>
			setCount(chat?.unread)
		),
		[user, shopId]
	);

	const {Text} = use.theme();

	const display = use.context.display();
	props.style = use.defaultStyle(props.style, localStyles.layout, [display]);
	textStyle = use.defaultStyle(textStyle, localStyles.text, [display]);

	return (
		(count > 0) &&
		<RNView {...props}>
			<Text style={textStyle}>
				{count > 99 ? '+99' : count}
			</Text>
		</RNView>
	);
}

export default React.memo(ChatLabelCounter);

const localStyles = {
	layout: styles.static(
		{
			backgroundColor: "#cd081e",
			...styles.center,
		},
		{
			mobile: styles.circle(25),
			default: styles.circle(30),
		}
	),

	text: styles.static(
		{
			color: styles.color.white,
			textAlign: styles.textAlign.center,
			bold: true,
		},
		{
			mobile: {fontSize: 13},
			default: {fontSize: 15},
		}
	),

};
