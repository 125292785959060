import React from "react"
import AppBar from "./AppBar";
import SideBar from "./SideBar";
import { styles } from "../../../res"
import use from "library-react/hook";
import AuthManager from "library-js/AuthManager";
import useApp from "library-react/app/useApp";
import { screens } from "@main/links";
import AuthDialog from "@main/component/AuthDialog";
import useNavigationItems from "./useNavigationItems";
import useMenuItems from "./useMenuItems";

function NavigationView({ ...props }) {
	props.menuItems = useMenuItems();
	props.navigationItems = useNavigationItems();

	const app = useApp();
	props.onAccountClicked = app.navigation &&
		function checkAuthAndNavigateToAccount() {
			const goToAccount = () => app.navigation.navigate(screens.account);
			if (AuthManager.user)
				goToAccount();
			else
				app.openModal(<AuthDialog onSuccess={goToAccount} />);
		};


	const display = use.context.display();
	props.style = use.defaultStyle(props.style, localStyles.layout(display), [display]);

	return (
		display.is.mobile ?
			<AppBar {...props} /> :
			<SideBar {...props} />
	);
}

export default React.memo(NavigationView);

const localStyles = {
	layout: styles.static(
		{ backgroundColor: styles.color.white },
		{
			mobile: {},
			default: {
				zIndex: 1,
			},
		}
	),
};
