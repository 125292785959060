import React from "react";
import useAppWidthLevel from "library-react/app/useAppWidthLevel";

export default function responsiveFactory(MobileComponent, DesktopComponent, defaultThreshold) {
	return function ResponsiveComponent({ threshold, ...props }) {
		const [isDesktop] = useAppWidthLevel([threshold || defaultThreshold || MAX_MOBILE_WIDTH]);
		const Component = isDesktop ? DesktopComponent : MobileComponent;
		return <Component {...props} />;
	}
}

const MAX_MOBILE_WIDTH = 1024;