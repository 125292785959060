import React from "react"
import {ActivityIndicator, FlatList} from "react-native"
import use from "../../../../../hook";
import View from "../../../../../component/View/v2";
import CatalogTabs from "./CatalogTabs";
import CatalogItem from "./CatalogItem";
import {styles} from "../../../../../res";
import Search from "../../../../../library-js/Search";
import Server from "../../../../../library-js/app/Server";
import AppBar from "../../../../../component/AppBar/AppBar";
import TextInput from "../../../../../component/input/TextInput";
import EmptyResult from "../../../../../component/EmptyResult";


export default function SearchView({shopId, goBack, onItemSelected, ...props}) {
	const [query, setQuery] = use.state('');
	const [section, setSection] = use.state(CatalogTabs.DISCOUNT);

	const isDiscountsTab = !query && section === CatalogTabs.DISCOUNT;

	const iterator = use.infiniteIterator(() =>
			Search.index.prodshop.getIterator({
				available: true,
				shopId,
				query,
				sections: (!isDiscountsTab && section) ? [section.name] : undefined,
				discounted: isDiscountsTab,
			})
				.setDelay(800),
		[query, shopId, section]
	);

	const shopLoadable = use.loadable.server(
		() => Server.public.shop.get(shopId).updateContent(map => map[shopId]),
		[shopId]
	);

	const {value: vShop} = shopLoadable;

	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<View {...props}>
			<AppBar style={localStyles.topBar}>
				<AppBar.ActionButton
					icon="back"
					size={19}
					onVoidPress={goBack}
					style={localStyles.back}
				/>

				<TextInput
					onChangeText={setQuery}
					style={localStyles.searchInput}
					placeholder={`Recherche dans ${vShop?.name || 'le catalogue'}`}/>
			</AppBar>

			<CatalogTabs
				shopId={shopId}
				ready={shopLoadable.end}
				iterator={iterator}
				onTabChanged={setSection}
				style={localStyles.tabs(query)}/>

			<FlatList
				data={
					(iterator.loading && !iterator.page)
					// prevent glitches when empty discount tab is removed
					|| (isDiscountsTab && iterator.end && !iterator.items.length) ?
						new Array(4).fill(PLACEHOLDER_CARD)
						: iterator.items
				}
				keyExtractor={(item, index) => String(index)}
				numColumns={2}
				columnWrapperStyle={{justifyContent: styles.justifyContent.spaceEvenly}}
				renderItem={({item}) =>
					<CatalogItem item={item === PLACEHOLDER_CARD ? null : item}
								 onPress={onItemSelected && (() => onItemSelected(item))}
								 style={localStyles.item}/>
				}
				keyboardShouldPersistTaps='handled'
				onEndReached={iterator?.ready ? iterator.load : undefined}
				onEndReachedThreshold={.01}
				ListFooterComponent={
					iterator.loading && iterator.items.length &&
					<ActivityIndicator style={{flex: 1, padding: 20}}/>}
				ListEmptyComponent={
					iterator.end
					&& !iterator.items.length
					&& !isDiscountsTab
					&& <EmptyResult/>
				}
				style={localStyles.list}
			/>
		</View>
	)
}

const PLACEHOLDER_CARD = {};

const localStyles = {
	layout: {
		...styles.fit,
		backgroundColor: styles.color.white,
	},
	topBar: {
		flexDirection: styles.flexDirection.row,
	},
	back: {
		color: styles.color.black,
		paddingLeft: 34,
	},
	searchInput: {
		paddingLeft: 20,
		fontSize: 14,
		flex: 1,
	},
	item: {
		marginVertical: 5,
		...styles.shadow,
		backgroundColor: styles.color.white,
		overflow: styles.overflow.visible,
	},
	tabs: styles.static.bool({
			height: 44 + 14,
			flexGrow: 0,
			paddingTop: 14,
		},
		styles.gone,
		null,
	),

	list: {
		flex: 1,
		paddingTop: 10,
	},

	listContent: {
		flexGrow: 1,
	}
};
