export default function parallel(...fns){
	fns = fns.flat().filter(fn => (fn instanceof Function));
	return function parallelize(...params){
		return fns.map((fn) => {
			if (fn){
				try {
					return fn(...params);
				}
				catch (error) {
					console.error(error);
				}
			}
		});
	}
}
