import View from "./View/v2";
import styles from "../res/styles";
import is from "../library-js/utils/is";
import {TextStyleContext} from "./Text/v2"
import React from "react"
import ComponentUtils from "../ComponentUtils";
import use from "../hook";
import {Arrays} from "../library-js/utils";
import {parallel, pipe} from "../library-js/utils/function";

/**
 * @param {any}
 */
export default function SimpleButton(
	{
		filled = false,
		border = false,
		colors = defaults.colors,
		hoverColors,
		toggleFilledOnHover = false,
		disabledColors,
		children,
		...props
	}) {
	const {Text} = use.theme();
	const [hover, setHover] = use.state(false);
	props.onHoverChanged = parallel([props.onHoverChanged, setHover]);

	filled = Boolean(toggleFilledOnHover ? (!hover !== !filled) : filled);
	border = Boolean(border);
	const finalColors = (
		props.disabled && disabledColors ? disabledColors
			: hover && hoverColors ? hoverColors
			: colors
	) || defaults.colors;

	props.style = use.defaultStyle(
		props.style,
		() => {
			const {primary} = finalColors;
			return [
				defaults.styles.layout,
				border && {
					borderWidth: 1,
					borderColor: primary,
				},

				filled && {
					backgroundColor: primary,
				},
			];
		},
		[
			filled,
			border,
			colors,
			hoverColors,
			toggleFilledOnHover,
			disabledColors,
			props.disabled,
			hover
		]
	);

	const color = filled ? finalColors.secondary : finalColors.primary;
	const textStyle = use.memo([{color}, defaults.styles.text], [color]);

	children = use.memo(
		pipe([
			ComponentUtils.childrenToArray,
			Arrays.mapper((child, index) => {

				if (is(child, String))
					child = (
						<Text key={index}>
							{child}
						</Text>
					);

				else {
					const type = ComponentUtils.is(child);
					if (type) {
						if (type.text)
							child = React.cloneElement(child, {
								key: child.key || String(index),
								style: ComponentUtils.defaultStyle(child.props.style, textStyle)
							});

						else if (type.colorable && !type.colorable.get(child))
							child = type.colorable.set(child, color);
					}
				}

				return child;
			})
		]),
		[
			children,
			textStyle,
			color,
		]
	);

	return (
		<View hoverable {...props}>
			<TextStyleContext.Provider value={textStyle}>
				{children}
			</TextStyleContext.Provider>
		</View>
	);
}

const defaults = {
	colors: {
		primary: styles.color.black,
		secondary: styles.color.white,
	},

	styles: {
		layout: {
			flexDirection: styles.flexDirection.row,
			...styles.center,
			padding: 10,
		},

		text: {
			textAlign: styles.textAlign.center
		},
	}
};
