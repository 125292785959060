import React from "react";
import {View as RNView} from "react-native";
import {images, styles} from "@main/res";
import use from "library-react/hook";
import SimpleButton from "library-react/component/SimpleButton";
import {is} from "library-js/utils";
import {user as Server} from "library-js/app/Server";
import AuthManager from "library-js/AuthManager";
import Image from "library-react/component/Image";
import ReservationHelp from "@main/component/ReservationHelp";
import {screens} from "@main/links";
import Portal from "library-react/component/Portal";
import {StackActions} from "@react-navigation/native";

function PaymentStateFragment({route, navigation, ...props}) {
	const orderId = route.params?.orderId;
	const display = use.context.display();

	const {value: vItems, loading, load} = use.loadable.server(
		() => AuthManager.onceReady.then(() => Server.order.getItems(orderId)),
		[orderId]
	);

	const vOrder =  vItems?.first?.vOrder;
	const failed = vOrder?.currentState.type.is.canRetryPayment;
	const ok = is(failed) && !failed;

	const {Text, Spinner, ErrorView} = use.theme();

	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<RNView {...props}>
			{
				(loading) ? <Spinner/> :
					is(ok) ?
						<RNView style={localStyles.wrapper(display)}>
							<RNView style={localStyles.content(display)}>
								<Image
									source={ok ? images.success : images.fail}
									resizeMode="cover"
									style={localStyles.icon}
								/>

								<Text style={localStyles.title(display)}>
									{CONTENT[ok].title}
								</Text>


								{
									ok &&
									<ReservationHelp vOrder={vOrder} style={{marginBottom: 20}}/>
								}

								<Text style={localStyles.subtitle(display)}>
									{CONTENT[ok].subtitle}
								</Text>

								<Portal to={screens.order} params={{id: orderId}} action={StackActions.replace}>
									<SimpleButton
										border
										toggleFilledOnHover
										filled
										colors={localStyles.confirm.colors}
										style={localStyles.confirm.layout(display)}>
										<Text style={localStyles.confirm.text(display)}>
											{CONTENT[ok].confirm}
										</Text>
									</SimpleButton>
								</Portal>
							</RNView>

							{
								(ok && !display.is.mobile) &&
								<Text style={localStyles.thanks(display)}>
									{CONTENT[ok].thanks}
								</Text>
							}
						</RNView> :
						<ErrorView retry={load}/>
			}
		</RNView>
	);
}

export default React.memo(PaymentStateFragment);

const CONTENT = {
	true: {
		icon: "Entypo/check",
		title: `FÉLICITATIONS !`,
		subtitle: `Vous pouvez suivre toutes vos commandes dans la section “Mes commandes”.`,
		confirm: `VOIR MA COMMANDE`,
		thanks: `Shopinzon vous remercie de votre achat.`,
	},

	false: {
		icon: "Entypo/cross",
		title: `OUPS !`,
		subtitle: `Une érreur s'est produite lors de l'achat.`,
		confirm: `RÉESSAYER`,
	},
};

const localStyles = {
	layout: {
		flex: 1,
		...styles.center,
	},

	wrapper: styles.static(
		{
			borderWidth: 2,
			overflow: styles.overflow.hidden,
		},
		{
			mobile: {
				maxWidth: 330,
				borderWidth: 0,
			},
		}
	),

	content: styles.static(
		{
			alignItems: styles.alignItems.center,
		},
		{
			mobile: {
				padding: 30,
			},
			default: {
				padding: 40,
			}
		}
	),

	icon: {
		...styles.circle(150),
		marginBottom: 20,
	},

	title: styles.static(
		{bold: true},
		{
			default: {
				fontSize: 30,
				marginBottom: 15,
			},

			mobile: {
				fontSize: 25,
				marginBottom: 25,
			},
		}
	),

	subtitle: styles.static(
		{
			textAlign: styles.textAlign.center,
			marginBottom: 25,
		},
		{
			default: {
				fontSize: 20,
			},
			mobile: {
				fontSize: 15,
			}
		},
	),

	confirm: {
		colors: {
			primary: styles.color.black,
			secondary: styles.color.white,
		},

		layout: styles.static(
			{
				marginBottom: 15,
				alignSelf: styles.alignSelf.stretch,
			},
			{
				default: {
					paddingVertical: 20,
					paddingHorizontal: 40,
				},
			},
		),

		text: styles.static(
			{},
			{
				default: {
					fontSize: 20,
				},

				mobile: {
					fontSize: 17,
				}
			},
		),
	},

	thanks: styles.static(
		{
			backgroundColor: "#e4e4e4",
			textAlign: styles.textAlign.center,
			alignSelf: styles.alignSelf.stretch,
		},
		{
			default: {
				fontSize: 20,
				paddingVertical: 20,
			},

			mobile: {
				fontSize: 17,
				paddingVertical: 10,
			}
		},
	)
}
