import RecorderComponent from "./RecorderComponent";

export default class RecorderOffset extends RecorderComponent {
	x = 0;
	y = 0;

	get top(){
		return this.y;
	}

	get left(){
		return this.x;
	}

	get bottom(){
		const {layout, content} = this.recorder;
		return RecorderOffset.getBottomOffsetFor(layout, content, this);
	}

	get right(){
		let {content, layout} = this.recorder;
		return content.width - (this.x + layout.width);
	}

	static getBottomOffsetFor(layout, content, offset){
		return content.height - (offset.y + layout.height);
	}
}
