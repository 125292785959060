import use from "../../../library-react/hook";
import MobileUI from "./mobile";
import DesktopUI from "./desktop";
import Portal from "library-react/component/Portal";
import {screens} from "@main/links";
import React from "react";

export default function ShowcaseItem({portrait, ...props}) {
	const vShowcase = props.item;
	props.inPortal = child => (
		<Portal
			to={Boolean(vShowcase) && screens.showcase}
			params={{id: vShowcase?.id}}>
			{child}
		</Portal>
	);

	const display = use.context.display();
	props.image = portrait ? props.item?.portrait : props.item?.banner;

	return (
		display.is.mobile ?
			<MobileUI {...props}/> :
			<DesktopUI {...props}/>
	);
}
