import React from "react"
import {View as RNView} from "react-native"
import use from "library-react/hook"
import Icon from "library-react/component/Icon/v2"
import {styles} from "../../res"
import {BasketManager} from "library-js/app/model/basket";
import Portal from "library-react/component/Portal";
import {screens} from "@main/links";

function BasketButton({...props}) {
	if (!props.size)
		props.size = 22;

	use.subscribe(BasketManager.listenNumberOfItems);

	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<Portal to={screens.basket}>
			<Icon
				name="basket"
				{...props}>
				<Label filled={BasketManager.basketFilled}/>
			</Icon>
		</Portal>
	);
}

export default React.memo(BasketButton);

function Label({filled}) {
	const {shop} = use.context.infos().vShop;
	const {Text} = use.theme();

	return (filled > 0) && (
		<RNView style={{
			...styles.circle(LABEL_SIZE),
			...styles.absolute({bottom: -LABEL_SIZE / 3, right: -LABEL_SIZE / 3}),
			backgroundColor: shop.ordersAvailable ? "#cd081e" : 'black',
			...styles.center,
		}}>
			<Text style={{color: styles.color.white, fontSize: 11, lineHeight: 10, bold: true}}>
				{Math.min(filled, 99)}
			</Text>
		</RNView>
	);
}

const LABEL_SIZE = 20;

const localStyles = {
	layout: styles.center,
};
