import { gql } from "@apollo/client";
import { StackScreenProps } from "@react-navigation/stack";
import React from "react";
import { StyleSheet } from "react-native";
import LoaderHall from "../../../library-react/component/LoaderHall";
import useAuthUser from "../../../library-react/hook/useAuth/user";
import { useQuery } from "../../../library-react/library-js/app/graphql";
import useDisplay from "../../hook/useDisplay";
import { RootParamList } from "../../links";
import DesktopUI from "./desktop";
import MobileUI from "./mobile";

function OrderUI({ route }: OrderUIProps) {
	const id = route.params?.id;

	const user = useAuthUser();
	const skip = !id || !user;
	const loader = useQuery(ORDER_QUERY, {
		variables: { id },
		skip: !id || !user,
	});

	const order = loader.data?.order_v2_by_pk;

	// Replace order items in basket
	// TODO How do you get the order item size?
	// const items = order?.items;
	// const toast = useToast();
	// async function addToBasket() {
	// 	function add({ prodshop, subtitle, quantity }: OrderItem) {
	// 		if (prodshop)
	// 			return BasketManager.persist(order.shopId, prodshop.product?.id, size, quantity || 1);
	// 	}

	// 	const [lastItem, ...restItems] = items.slice().reverse();
	// 	await Promise.all(restItems.map(add));
	// 	// add a last item after all others in order to let the manager have consistent state number of items
	// 	await add(lastItem);
	// 	toast("Les articles de cette commande ont ete placés dans votre panier!");
	// };

	const display = useDisplay();
	const UI = display == "mobile" ? MobileUI : DesktopUI;
	const total = order?.items_aggregate?.aggregate?.sum?.totalPrice;
	const currency = order?.items[0]?.currency;

	return skip ? null : (
		<LoaderHall
			loader={loader}
			style={localStyles.flex}>
			<UI
				order={order!}
				total={total || 0}
				currency={currency || "EUR"}
				// addToBasket={addToBasket}
				style={localStyles.flex}
			/>
		</LoaderHall>
	);
}

export default React.memo(OrderUI);

type OrderUIProps = StackScreenProps<RootParamList, "order">;

export const ORDER_QUERY = gql`
query order_query($id: bigint!) {
  order_v2_by_pk(id: $id) {
    id
    shopId
    userId
	
    creationDate
	receptionMean
	instructions
	customerAddressFormated
    customerEmail
    customerName
    customerPhone
    currentState {
      state
      time
    }
    items {
      id
	  title
	  subtitle
	  prodshop {                     
	  	id                     
	  	product {                         
			id    
        	name
			brand
			description
        	creation_date                    
			gallery                         
			galleryList @client                     
	  	} 
	  }
      price currency
      quantity
      state
    }
	items_aggregate {
		aggregate {
		  count
		  sum {
			  totalPrice
		  }
		}
	}

	#required for invoice hash
	id
	shopName
	creationDate
  }
}`;

const localStyles = StyleSheet.create({
	flex: { flex: 1 },
});