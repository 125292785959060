import React from "react"
import {Animated, Linking, ScrollView, View as RNView} from "react-native"
import {styles} from "@main/res"
import use from "library-react/hook";
import Image from "library-react/component/Image";
import {images} from "library-react/res";
import Icon from "library-react/component/Icon/v2";
import View from "library-react/component/View/v2";
import SEO from "library-react/component/SEO";


export default function DomainAvailableUI({...props}) {
	const {Text} = use.theme();
	const [domain] = window.location.host.split('.');

	props.contentContainerStyle = use.defaultStyle(props.contentContainerStyle, localStyles.layout);
	return (
		<ScrollView {...props}>
			<SEO status={404}/>

			<Image
				source={images.logoWithName}
				resizeMode="contain"
				style={localStyles.image}
			/>
			<RNView style={localStyles.info}>
				<Icon name="MaterialCommunityIcons/check-bold"
					  size={30}
					  style={localStyles.check}/>
				<Text style={localStyles.available}>
					{`${domain}.`}
					<Text style={localStyles.shopinzonDomain}>
						{`shopinzon.com`}
					</Text>
					{` est disponible.`}
				</Text>
			</RNView>
			<Text style={localStyles.description}>
				{`C’est votre jour de chance, le nom de domaine`}
				{` «${domain}.`}
				<Text style={localStyles.shopinzonDomain}>
					{`shopinzon.com`}
				</Text>
				{`» est libre sur notre plateforme.`}
			</Text>
			<Text style={localStyles.description}>
				{`Shopinzon est la solution de digitalisation de point de vente la plus intuitive et la plus rapide.`}
			</Text>
			<Text style={localStyles.description}>
				{`Alors si vous voulez vendre vos services et vos produits à une communauté regroupant des milliers d’acheteurs autour de vous, inscrivez vous sur shopinzon.com.`}
			</Text>
			<DiscoverButton/>

		</ScrollView>
	);
};


function DiscoverButton({...props}) {
	const {Text} = use.theme();

	const av = use.memo(() => new Animated.Value(0));

	const hoverAnimation = use.memo(() => Animated.timing(av, {
			toValue: 1,
			duration: ANIMATION_DURATION,
			useNativeDriver: true,
		})
	);
	const blurAnimation = use.memo(() => Animated.timing(av, {
			toValue: 0,
			duration: ANIMATION_DURATION,
			useNativeDriver: true,
		})
	);

	props.onHoverChanged = use.callback(
		(hover) => {
			if (hover) {
				blurAnimation.stop();
				hoverAnimation.start();
			} else {
				hoverAnimation.stop();
				blurAnimation.start();
			}
		});

	props.onPress = () => Linking.openURL(`http://www.shopinzon.com`)
	props.style = use.defaultStyle(localStyles.discover.layout, props.style);
	return (
		<View hoverable {...props}>

			<Text style={localStyles.discover.text}>
				{`Decouvrir Shopinzon`}
			</Text>

			<RNView>
				{
					Array.range(3,
						(index, length) =>
							<Icon
								key={index}
								name="MaterialIcons/keyboard-arrow-right"
								size={30}
								Layout={Animated.View}
								style={{
									position: styles.position.absolute,
									opacity: (index + 1) / length,
									color: "#395aff",
									transform: [{
										translateX: av.interpolate({
											inputRange: [0, (index + 1) * 1 / length],
											outputRange: [0, (index + 1) * 15],
											extrapolate: "clamp",
										})
									}],
								}}/>
					)
				}
			</RNView>
		</View>
	)
}

const ANIMATION_DURATION = 500;


const localStyles = {
	layout: {
		alignSelf: styles.alignSelf.center,
		...styles.center,
		paddingTop: 120,
		maxWidth: 850,
		padding: 10,
	},
	image: {
		width: 200,
		height: 140,
		marginBottom: 80
	},
	info: {
		flexDirection: styles.flexDirection.row,
	},
	check: {
		width: 30,
		height: 22,
		color: "#30b167",
		marginRight: 11,
	},
	shopinzonDomain: {
		color: "#395aff"
	},
	available: {
		textAlign: styles.textAlign.center,
		fontSize: 23,
		bold: true,
		marginBottom: 60
	},
	description: {
		textAlign: styles.textAlign.center,
		fontSize: 20,
	},
	discover: {
		layout: {
			marginTop: 50,
			flexDirection: styles.flexDirection.row,
		},
		text: {
			textAlign: styles.textAlign.center,
			fontSize: 23,
			bold: true,
			color: "#395aff",
		},
	},
}
