import useMemo from "./useMemo";
import useSubscribe from "./useSubscribe";

export default function useCreateLoadable(factory, dependencies){
	const loadable = useMemo(
		() => {
			const loadable = factory && factory();

			if (loadable)
				loadable.load(); // start load

			return loadable || undefined; // prevent returning empty string or other false values
		},
		dependencies,
		destructor,
	);

	// listen for changes
	useSubscribe(update => loadable?.onStateChanged(update), [loadable]);

	return loadable;
}

function destructor(loadable){
	if (loadable)
		loadable.destroy();
}
