import Objects from "../utils/Objects"
import is from "../utils/is"
import Debug from "../Debug"
import Classes from "./Classes"
import Enum from "./Enum"
import identity from "ramda/es/identity";
import Trigger from "../utils/Trigger";
import getObjectIdOf from "../utils/getObjectIdOf";
import { isNil } from "ramda"

/**
 * Base abstract class.
 * Once a class inherits it, you can add safe getters & setters using the {@link addProperties} function on the class.
 * All properties added are in the 'properties' static property.
 * Exemple:
 * <code>
 *     class Point extends Base {}
 *     Point.addProperties({
 *     		x : Number,
 *     		y : Number
 *     });
 *
 *     console.log(Point.properties) // {x : {type: Number}, y : {type: Number}}
 *
 *     let point = new Point();
 *     point.setX(1)
 *            .setY(2);
 *     point.y = 2;
 *     point.y = "a string"; // throw error
 *     console.log(point.getX())
 * </code>
 *
 * You can use the static {@link from} function to convert an anonymous object into an instance of the class:
 * <code>let point = Point.from({ x : 1, y : 2 })</code>
 */
export default class Base {
	constructor() {
		Object.defineProperties(this, {
			__objectID__: {
				enumerable: false,
				get: () => getObjectIdOf(this),
			}
		});

		privates.set(this, {});

		['onPropertyChanged', 'onPropertyChangedSync'] // bound methods
			.forEach(name => this[name] = this[name].bind(this));
	}

	// TODO curried onPropertyChanged(field, callback)
	onPropertyChanged(listener) {
		const myPrivates = privates.get(this);
		if (!myPrivates.__trigger)
			myPrivates.__trigger = new Trigger;
		return myPrivates.__trigger.add(listener);
	}

	onPropertyChangedSync(listener) {
		const myPrivates = privates.get(this);
		if (!myPrivates.__syncTrigger)
			myPrivates.__syncTrigger = new Trigger;
		return myPrivates.__syncTrigger.add(listener);
	}

	/**
	 * @return {String} The object in a string.
	 */
	toString() {
		return "instance of " + this.constructor.name;
	}

	toJSON() {
		return Objects.map(this.constructor.properties, key => this[key]);
	}

	toObject() {
		return Object.keys(this.constructor.properties)
			.toObject(
				key => key,
				key => this[key],
			);
	}

	static toString() {
		return this.name;
	}

	/**
	 * Copy all properties of the parameter to the current object.
	 * @param {Object} water Set of properties to set.
	 * @return {Base} The current instance, to chain methods call.
	 */
	hydrate(water) {
		if (water) {
			Objects.forEach(
				this.constructor.properties,
				key => {
					if (key in water)
						this[key] = water[key];
				}
			);

			Object.assign(this, water);
		}

		return this;
	}

	/**
	 * @return {Base} A deep clone.
	 */
	clone() {
		const clone = this.constructor.instantiate();

		Objects.forEach(
			this.constructor.properties,
			key => {
				if (this[key]?.clone instanceof Function)
					clone[key] = this[key].clone();
				else if (this[key]?.copy instanceof Function)
					clone[key] = this[key].copy();
				else
					clone[key] = this[key];
			}
		);

		return clone;
	}

	copy(nested) {
		const copy = this.constructor.instantiate();

		Objects.forEach(
			this.constructor.properties,
			key => {
				const value = this[key];

				if (nested /*prevent falsy*/ && nested[key] && (value instanceof Base)) {
					const nestedCopy = (nested[key] instanceof Object) && /* nested copy config */ nested[key];
					copy[key] = value.copy(nestedCopy);
				} else
					copy[key] = this[key];
			},
		);

		return copy;
	}

	bindSetters() {
		Objects.forEach(this.constructor.properties, key => {
			const setter = `set${key.firstUp}`;
			this[setter] = this[setter].bind(this);
		});
		return this;
	}

	equalsTo(value, comparators) {
		if (this === value)
			return true;

		if (!(value instanceof this.constructor))
			return false;

		return Object.keys(this.constructor.properties)
			.every(key => (comparators?.[key] || compare)(this[key], value[key]));
	}

	/**
	 * Set the value of a property.
	 * @return {Base} The current instance, to chain methods call.
	 */
	set(property, value) {
		this[property] = value;
		return this;
	}

	updateACopy(map) {
		if (map) {
			const copy = this.copy();
			Object.entries(map)
				.forEach(([key, update]) => {
					if (
						is.primitive(update)
						|| update instanceof Base
						|| Array.isArray(update)
					)
						copy[key] = update;

					else if (update instanceof Function)
						copy[key] = update(copy[key])

					else {
						let fieldCopy;

						const field = copy[key];
						if (field instanceof Base)
							fieldCopy = field.updateACopy(update);
						else {
							const spec = this.constructor.properties[key];
							if (!spec)
								this[field] = update;
							else
								fieldCopy = Base.from(update, spec);
						}

						copy[key] = fieldCopy;
					}
				});

			return copy;
		}
	}

	/**
	 * Add setters ang getters to the class.
	 * @param {Object} properties Properties to make setters and getters for.
	 */
	static addProperties(properties) {
		// if properties definitions are from parent, define a new one (to prevent adding properties to the parent class)
		if (this.properties === Classes.getParentOf(this).properties)
			// copy parent properties
			Object.defineProperty(this, "properties", {
				configurable: true,
				value: Objects.map(this.properties)
			});

		// convert type spec into object wrapping type
		properties = Objects.map(properties, (key, spec) => !spec || spec instanceof Function ? { type: spec } : spec);
		Object.values(properties).forEach(spec => {
			if (!spec.get)
				spec.get = identity;
		});

		// add new properties to class properties
		Object.assign(this.properties, properties);

		Object.defineProperties(
			this.prototype,
			Objects.reduce(properties,
				(definitions, name, spec) => {
					const getterFnName = `get${name.firstUp}`;

					return Object.assign(definitions, {
						[getterFnName]: {
							writable: true,
							configurable: true,
							value: function () {
								return spec.get.apply(this, [privates.get(this)[name]]);
							}
						},

						[`set${name.firstUp}`]: {
							writable: true,
							configurable: true,
							value: function (newValue) {
								const myPrivates = privates.get(this);
								const oldValue = myPrivates[name];

								// set the value
								myPrivates[name] = setterCheck.apply(this, [this.constructor, name, spec, newValue]);

								if (oldValue !== newValue) {
									if (myPrivates.__trigger)
										myPrivates.__trigger.fireAsyncOnce();
									if (myPrivates.__syncTrigger)
										myPrivates.__syncTrigger.fire();
								}

								return this;
							}
						},

						[name]: {
							enumerable: true,
							configurable: true,

							get: function () {
								return this[`get${name.firstUp}`]();
							},

							set: function (newValue) {
								this[`set${name.firstUp}`](newValue);
							},
						}
					});
				},
				{}
			)
		);

		return this;
	}

	static extendsWith(properties) {
		return (class /*anonymous*/ extends this {
		})
			.addProperties(properties);
	}

	/*Used by the static from() function to instantiate*/
	static instantiate(...params) {
		return new this(...params);
	}

	/**
	 * Convert an anonymous object, or array or map of anonymous objects.
	 * @param {Object} value Value to convert.
	 * @param {Class} To The class to convert to. Default value is the current class.
	 * @param {Class} template Template class. Use this for Array or map of classes.
	 * @returns {*} The converted object.
	 * Exemple:
	 * <code>
	 *     let arrayOfPoints = Base.from(array, Array, Point) // convert all the array values
	 *     // or
	 *     arrayOfPoints = Base.from(array, {type: Array, template: Point})
	 * </code>
	 */
	static from(value, To, template, propertyName) {
		// default To value
		To = To || this;
		Debug.assert(To === Base, "Base is an abstract class and cannot be created with from().", true);

		// result to return
		let result;

		// Enum must be before primitive check in case the value is for a enum's value
		// Enum

		if (is.null(value))
			result = value;

		else if ([Array, Object].includes(To)) {
			if (template) {
				if (is(value, To)) {
					if (To === Array)
						result = value.map((item, index) => Base.from(item, template, null, `${propertyName}.${index}`));
					else
						result = Objects.map(value, (key, item) => Base.from(item, template, null, `${propertyName}.${key}`));
				} else
					Debug.assert(true, ["Trying to convert a non array/object value into an array/object : ", value],
						// in production
						function () {
							result = value;
						});
			} else
				result = value;
		}

		// Base child class
		else if (is(To, Base) && !is.primitive(value)) {
			result = To.instantiate();
			Objects.forEach(value, (key, value) => {
				let propertySpec = To.properties[key];
				if (propertySpec)
					result[key] = Base.from(value, propertySpec, null, key);
				else {
					console.warnOnce(`${To.name}.${key} has no spec!`);
					result[key] = value;
				}
			});
		} else if (To.from && To.from !== Base.from)
			result = To.from(value);

		// allow nested complex types like {type: Array, template: Number}.
		else if ("type" in To) {
			if (To.type) {
				if (is(To.type, Base))
					result = To.type.from(value, null, To.template, propertyName);
				else
					result = Base.from(value, To.type, To.template, propertyName);
			} else // type is null, no change to make to value
				result = value;
		} else if (To.constructor === Object && value) {
			Debug.assert(!is(value, Object, true), [`${this.name} cannot convert a value into an object: `, value],
				() => result = value);

			if (is(value, Object))
				result = Objects.map(value, (key, item) => {
					if (To[key])
						return this.from(item, To[key], null, key);
					else {
						console.warnOnce(`${this.name}.${propertyName || "(unknownPropertyName)"}.${key} has no spec!`);
						return item;
					}
				});
		} else
			result = value;

		return result;
	}

	static async fromAsync(value, To, template, propertyName) {
		await Promise.await();

		// default To value
		To = To || this;
		Debug.assert(To === Base, "Base is an abstract class and cannot be created with from().");

		// result to return
		let result;

		// Enum must be before primitive check in case the value is for a enum's value
		// Enum

		if (is.null(value))
			result = value;

		else if ([Array, Object].includes(To)) {
			if (template) {
				if (is(value, To)) {
					if (To === Array) {
						result = Promise.all(
							value.map((item, index) =>
								Base.fromAsync(item, template, null, `${propertyName}.${index}`)
							)
						);
					} else {
						result = await Promise.all(
							Objects.map(value, (key, item) =>
								Base.fromAsync(item, template, null, `${propertyName}.${key}`)
							)
						);
					}

					// if (To === Array){
					// 	result = [];
					// 	for (let item of value)
					// 		result.push(await Base.fromAsync(item, template, null, `${propertyName}.${result.length}`));
					// }
					// else {
					// 	const result = {};
					// 	const keys = Object.keys(value);
					// 	for (const key of keys){
					// 		const item = value[key];
					// 		result[key] = await Base.fromAsync(item, template, null, `${propertyName}.${key}`);
					// 	}
					// }

				} else
					Debug.assert(true, ["Trying to convert a non array/object value into an array/object : ", value],
						// in production
						function () {
							result = value;
						});
			} else
				result = value;
		}

		// Base child class
		else if (is(To, Base) && !is.primitive(value)) {
			result = To.instantiate();
			const values = await Promise.all(
				Objects.map(value, (key, value) => {
					let propertySpec = To.properties[key];
					if (propertySpec)
						return Base.fromAsync(value, propertySpec, null, key);

					console.warnOnce(`${To.name}.${key} has no spec!`);
					return value;
				})
			);
			Object.assign(result, values);

			// const keys = Object.keys(value);
			// for (const key of keys) {
			// 	let item = value[key];
			//
			// 	let propertySpec = To.properties[key];
			// 	if (propertySpec)
			// 		item = await Base.fromAsync(item, propertySpec, null, key);
			// 	else
			// 		console.warn(`${To.name}.${key} has no spec!`);
			//
			// 	result[key] = item;
			// }

		} else if (To.fromAsync)
			result = await To.fromAsync(value);
		else if (To.from)
			result = To.from(value);

		// allow nested complex types like {type: Array, template: Number}.
		else if ("type" in To) {
			if (To.type) {
				if (is(To.type, Base))
					result = await To.type.fromAsync(value, null, To.template, propertyName);
				else
					result = await Base.fromAsync(value, To.type, To.template, propertyName);
			} else // type is null, no change to make to value
				result = value;
		} else if (To.constructor === Object && value) {
			Debug.assert(!is(value, Object, true), [`${this.name} cannot convert a value into an object: `, value],
				() => result = value);

			if (is(value, Object))
				result = Objects.map(value, (key, item) => {
					if (To[key])
						return this.fromAsync(item, To[key], null, key);
					else {
						console.warnOnce(`${this.name}.${propertyName || "(unknownPropertyName)"}.${key} has no spec!`);
						return item;
					}
				});
		} else
			result = value;

		return result;
	}
}

// force properties to be an object
Base.properties = {};


// ------ private -------
const privates = new WeakMap();

function setterCheck(constructor, name, spec, value) {
	const typeOfValue = is(value);

	const assertType = (isValueOk) => Debug.assert(
		!isValueOk,
		`${constructor.name || 'ClassAnonyme'}.${name} must be a ${spec.type.name}. ` + (typeOfValue ? " Not a " + typeOfValue.name : ""),
		true
	);

	if (spec?.set instanceof Function) {
		const helper = {
			name,
			constructor,
			throwInvalidTypeError: () => assertType(false),
		};

		value = spec.set.apply(this, [value, helper]);
	} else if (spec?.type) { // check if a type was defined
		if (!isNil(value)) {
			// check if it's an enum
			if (is(spec.type, Enum))
				value = spec.type.from(value) || value;

			// a number
			else if (spec.type == Number) {
				value = Number(value);
				Debug.assert(
					isNaN(value),
					"Trying to assign NaN to an entity's number-field.",
					() => value = undefined
				);
			}

			// a boolean
			else if (spec.type == Boolean)
				value = Boolean(value);

			// a string
			else if (spec.type == String)
				value = String(value);

			else if (spec.type === Date)
				value = new Date(value);
		}

		// validate type
		let isValueOk = is(value, spec.type, true);
		assertType(isValueOk);
	}

	return value;
}


function compare(field1, field2) {
	return (
		Object.is(field1, field2) ||
		(
			field1 instanceof Base
			&& field1.equalsTo(field2)
		) ||
		(
			field1 instanceof Array
			&& field2 instanceof Array
			&& field1.length === field2.length
			&& field1.every((value, index) => compare(field1[index], field2[index]))
		)
	);
}
