import React from "react"
import use from "library-react/hook"
import Search from "library-js/Search";
import DesktopUI from "./DesktopUI";
import MobileUI from "./MobileUI";


export default function BrandSampleView({...props}) {
	const {brand} = props;

	// products iterator
	const {vShop} = use.context.infos();
	const iterator = props.iterator = use.infiniteIterator(
		(shopId, brand) => brand &&
			Search.index.prodshop.getIterator({
				shopId,
				brand,
				inShop: true,
				available: true
			}),
		[vShop.shop.id, brand]
	);

	props.loading = iterator?.loading || !(iterator?.items.length > 0);
	props.title = props.title || brand;

	const display = use.context.display();

	if (iterator?.end && !iterator.items.length) // no sample
		return null;
	return (
		display.is.mobile ?
			<MobileUI {...props}/> :
			<DesktopUI  {...props}/>
	);
}
