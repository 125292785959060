import {useState} from "react"
import useEffect from "./useEffect";
import {is} from "../library-js/utils";

export default function useInterval(interval){
	let [time, setTime] = useState(0);

	if (is(interval, Function))
		interval = interval(time);

	useEffect(() => {
		if (interval > 0){
			let key = setInterval(() => {
				setTime(time => ++time);
			}, interval);

			return () => clearInterval(key);
		}
	}, [setTime, (interval > 0 && interval)]);

	return time;
}
