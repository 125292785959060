import React from "react";
import use from "../../../../../../hook";
import {styles} from "../../../../../../res";
import ProductPreview from "../../ProductPreview";
import RemoveButton from "./RemoveButton";
import {View as RNView} from "react-native";
import OrderInfos from "../../OrderInfos";

function OrderItemAttachmentPreview({item, onProductClicked, onRemoveClicked, ...props}) {
	const {product, vOrder, price, oldPrice, size} = item;

	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<RNView {...props}>
			<OrderInfos
				state={vOrder?.currentState}
				orderId={vOrder?.id}
				creationDate={vOrder?.creationDate}
				iconSize={8}
				iconStyle={styles.circle(15)}
				textStyle={{fontSize: 8}}
				style={localStyles.orderInfos}
			/>

			<RNView>
				<ProductPreview
					product={product}
					price={price}
					oldPrice={oldPrice}
					size={size}
					innerStyles={localStyles.innerStyles}
					style={localStyles.card}
				/>

				<RemoveButton onPress={onRemoveClicked}/>
			</RNView>
		</RNView>
	);
}

export default React.memo(OrderItemAttachmentPreview);


const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.flexStart,
	},

	orderInfos: {
		alignItems: styles.alignItems.flexEnd,
		marginRight: 7,
	},

	card: {
		width: 80,
		zIndex: 0,
	},

	innerStyles: {
		// image: {height: 80 / styles.perfectPictureRatio },
		image: {height: 50 },
		title: {fontSize: 6},
		subtitle: {fontSize: 6},

		price: {
			fontSize: 7,
			lineHeight: 12,
		},

		priceLayout: {
			paddingVertical: 0,
			paddingHorizontal: 4,
		},

		oldPrice: {
			fontSize: 6,
			marginRight: 1,
		},
	},
};
