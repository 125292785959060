import Message from "./Message";
import {VOrder} from "../../../view";

export default class OrderItemMessage extends Message {
	get ok(){
		return Boolean(
			super.ok
			&& this.vOrderItem?.item.id
			// && this.vOrderItem?.item.orderId
			&& this.state
			&& this.vOrderItem?.product?.id
			&& this.vOrderItem?.product?.name
			// && this.vOrderItem?.product?.gallery
		);
	}

	get product(){
		return this.vOrderItem?.product;
	}

	get price(){
		return this.vOrderItem?.price;
	}

	get state(){
		return this.vOrderItem?.state;
	}

	get attachment(){
		return this.vOrderItem;
	}

	get safeText(){
		return super.safeText || this.product?.title || "";
	}
}
OrderItemMessage.addProperties({
	vOrderItem: VOrder.Item
});
