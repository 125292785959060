import React from "react";
import {View as RNView} from "react-native";
import {styles} from "@main/res";
import BackNavigationButton from "./BackNavigationButton";
import use from "library-react/hook";

export default function TitleWithBack({children, textStyle, ...props}) {
	const {Text} = use.theme();
	textStyle = use.defaultStyle(textStyle, localStyles.text);
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<RNView style={localStyles.layout}>
			<BackNavigationButton/>

			<Text style={textStyle}>
				{children}
			</Text>
		</RNView>
	)
}
const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
		paddingBottom: 35,
		paddingTop: 24,
	},

	text: {
		fontSize: 40,
		bold: true,
		paddingLeft: 35,
	},
}
