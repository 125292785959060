import RecorderComponent from "./RecorderComponent";

export default class RecorderSensors extends RecorderComponent {

	onLayout = (event) => {
		const {recorder} = this;

		const oldLayout = Object.assign({}, recorder.layout);
		Object.assign(recorder.layout, event.nativeEvent.layout);

		if (recorder.onLayout)
			recorder.onLayout(event);

		if (recorder.onLayoutChanged)
			recorder.onLayoutChanged(recorder.layout, oldLayout);

		if (recorder.onUpdate)
			recorder.onUpdate(recorder);
	};

	onContentSizeChange = (width, height) => {
		const {recorder} = this;
		const oldContent = recorder.content;
		recorder.content = {width, height};

		if (recorder.onContentSizeChange)
			recorder.onContentSizeChange(width, height);

		if (recorder.onContentChanged)
			recorder.onContentChanged(recorder.content, oldContent);

		if (recorder.onUpdate)
			recorder.onUpdate(recorder);
	};

	onScroll = (event) => {
		const {recorder} = this;
		const {contentSize, layoutMeasurement, contentOffset} = event.nativeEvent;

		Object.assign(recorder.content, contentSize);
		Object.assign(recorder.layout, layoutMeasurement);
		Object.assign(recorder.offset, contentOffset);

		if (recorder.onScroll)
			recorder.onScroll(event);

		if (recorder.onUpdate)
			recorder.onUpdate(recorder);
	};
}
