import React from "react";
import { Keyboard, SafeAreaView, View as RNView } from "react-native"
import use from "../../../../../hook";
import TextInput from "../../../../../component/input/TextInput";
import { styles } from "../../../../../res"
import Icon from "../../../../../component/Icon/v2";
import { Pipe } from "../../../../../utils";
import PipedOut from "../../../../../component/PipedOut";
import { ChatManager } from "../../../../../library-js/app/model/chat";
import Platform from "../../../../../library-js/Platform";
import SelectProduct from "./SelectProduct"
import AttachmentPreview from "./AttachmentPreview"
import Environment from "../../../../../library-js/Environment";
import ComponentUtils from "../../../../../ComponentUtils";


function MessageInput(
	{ interlocutorId, initialMessage, visible, selectProduct = true, onProductClicked, textInputStyle, displaySearch, ...props },
	refProp
) {
	let [opened, setOpened] = use.state.bool(false);

	// close catalog on keyboard opened
	use.effect(() => {
		if (opened) {
			Keyboard.addListener('keyboardDidShow', setOpened.false);
			return () => Keyboard.removeListener('keyboardDidShow', setOpened.false);
		}
	}, [opened]);


	const [attachment, setAttachment] = use.state(null);
	const placeAttachment = use.callback(attachment => {
		if (attachment)
			setOpened(false);
		setAttachment(attachment);
	});

	const [input, setInput] = use.state();
	const inputRef = use.memo({ input: null });
	use.syncEffect(() => inputRef.input = input, [input]);
	const runOnInput = (run) => {
		if (inputRef.input)
			run(inputRef.input);
	};

	use.onChange(() =>
		ComponentUtils.ref.set(
			refProp,
			input && {
				setAttachment: placeAttachment,
				focusInput: () => input.focus(),
			}
		),
		[input]
	);

	const onProductSelected = use.callback(vProdshop => {
		placeAttachment(vProdshop);
		runOnInput(input => input?.focus());
	});

	const removeAttachment = use.callback(() => placeAttachment(null));

	const onSelectProductClicked = use.asyncCallback(shouldStop =>
		selectProduct && (
			() => {
				if (selectProduct instanceof Function) // let app open a dialog or something to select
					Promise.process(shouldStop)
						.then(selectProduct)
						.then(placeAttachment);
				else { // open our view below
					setOpened.toggle();
					removeAttachment();
				}
			}
		),
		[selectProduct]
	);

	const onSearchClicked = use.asyncCallback(shouldStop =>
		displaySearch &&
		function () {
			Promise.process(shouldStop)
				.then(displaySearch)
				.then(vProdshop => {
					if (vProdshop)
						placeAttachment(vProdshop);
				})
		},
		[displaySearch],
	);

	const [sending, setSending] = use.state(false);
	const textPipe = use.memo(() => new Pipe().in(""));

	const focus = use.memo(() => {
		const pipe = new Pipe();
		return {
			pipe, // focus pipe
			handler: { // focus handler
				onFocus: () => pipe.in(true),
				onBlur: () => pipe.in(false),
			}
		};
	});


	use.effect(
		() => {
			if (visible) {
				runOnInput(input => input?.focus());
			}
		},
		[visible]
	);

	const memory = use.memo({
		submitPressed: false,
		submitting: false,
	});

	const submit = use.asyncCallback(shouldStop =>
		() => {
			const text = (input?.value || "").trim();
			if (memory.submitting || (!text && !attachment))
				return;

			setSending(memory.submitting = true);

			return Promise.process(shouldStop)
				.then(() => ChatManager.send(interlocutorId, input.value, attachment))
				.then(response => {
					if (response.ok) {
						textPipe.in(""); // clear text
						removeAttachment();
					} else
						response.log();
				})
				.anyway(() => setSending(memory.submitting = false));
		},
		[input, interlocutorId, attachment]
	);

	let onKeyPress;
	if (Environment.is.web)
		onKeyPress = use.callback(({ key, shiftKey }) => {
			if (key === "Enter") {
				if (!shiftKey) {
					memory.submitPressed = true; // will prevent '\n'
					submit();
				}
			}
		},
			[submit]
		);

	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<SafeAreaView {...props}>
			<RNView style={localStyles.wrapper}>
				{/* top part */}
				<RNView style={localStyles.main}>
					{/* attachment button */}
					{
						selectProduct &&
						((displayCross) =>
							<Icon
								name={displayCross ? "MaterialIcons/close" : "Entypo/plus"}
								size={19}
								onPress={attachment ? removeAttachment : onSelectProductClicked}
								style={localStyles.attachmentButton(displayCross)}
							/>
						)(opened || attachment)
					}
					<RNView style={localStyles.whitePan}>
						{/* attachment */}
						{
							attachment &&
							!opened &&
							<AttachmentPreview
								attachment={attachment}
								onRemoveClicked={removeAttachment}
								style={localStyles.attachment}
							/>
						}

						{/* main */}
						<RNView style={localStyles.whitePanRow}>
							{/* input */}
							<PipedOut from={textPipe}>
								{text =>
									<TextInput
										key="message-text-input"
										ref={setInput}
										value={text}
										multiline
										textAlignVertical="center"
										onKeyPress={onKeyPress}
										onValueChanged={text => {
											// don't accept \n without shift
											if (!memory.submitPressed && !sending)
												textPipe.in(text);
											memory.submitPressed = false; // reset
										}}
										caretHidden={sending}
										placeholder={sending ? `Envoi en cours…` : `Message …`}
										placeholderTextColor="#acb1c0"
										editable={visible}
										style={localStyles.input(!sending)}
										{...focus.handler}
									/>
								}
							</PipedOut>

							<Icon
								name="send"
								size={14.7}
								onPress={submit}
								style={localStyles.send}
							/>
						</RNView>
					</RNView>
				</RNView>


				{/* keyboard catalog */}
				{
					opened &&
					<SelectProduct
						of={
							Platform.select({
								retailer: ChatManager.id,
								default: interlocutorId,
							})
						}
						onSelected={onProductSelected}
						onSearchClicked={onSearchClicked}
						style={localStyles.catalog}
					/>
				}
			</RNView>
		</SafeAreaView>
	);
}

export default React.memo(React.forwardRef(MessageInput));

const localStyles = {
	layout: {
		backgroundColor: '#f3f3f3',
	},
	wrapper: {},

	main: {
		paddingVertical: 16,
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.flexEnd,
		paddingLeft: 11,
	},

	attachmentButton: styles.static.bool({
		...styles.circle(33),
		marginRight: 11,
		marginBottom: 6,
	},
		{
			backgroundColor: '#452ee0',
			color: styles.color.white,
		},
		{
			backgroundColor: '#e3e3e3',
			color: '#acb1c0',
		},
	),

	whitePan: {
		backgroundColor: styles.color.white,
		borderRadius: 23,
		borderWidth: 1,
		borderColor: '#eaecef',
		flex: 1,
		marginRight: 14,
	},

	whitePanRow: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
	},

	input: styles.static.bool({
		fontSize: 13,
		letterSpacing: .28,
		paddingLeft: 15,
		paddingRight: 1,
		maxHeight: 100,
		minHeight: 46,
		flex: 1,
		// Add vertical padding for ios because it doesn't align self vertically
		...Environment.select({
			android: {},
			ios: { paddingVertical: 10 },
			web: { paddingTop: 15, fontSize: 16 },
			default: { paddingTop: 15 },
		}),
	},
		{
			color: styles.color.black,
		},
		{ // sending
			color: "lightgrey",
		}
	),

	send: {
		...styles.circle(33),
		color: styles.color.white,
		marginRight: 10,
		backgroundColor: '#452ee0',
		paddingLeft: 3,
	},

	catalog: {},

	attachment: {
		...styles.newMargins(0, 18, 0, 26),
	}
};
