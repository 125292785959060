import React from "react"
import {View as RNView} from "react-native"
import use from "../../../../hook"
import {styles} from "../../../../res";
import Tab from "./Tab";

export default React.memo(({tabs, selected, onSelected, ...props}) => {
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<RNView {...props}>
			{
				tabs.map(({icon}, index) =>
					<Tab
						key={index}
						icon={icon}
						selected={index === selected}
						onPress={() => onSelected(index)}
					/>
				)
			}
		</RNView>
	);
});

const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
	},
};
