import React from "react"
import {View as RNView} from "react-native"
import use from "library-react/hook"
import {TextStyleContext} from "library-react/component/Text/v2";

function ReservationHelp({vOrder, ...props}) {
	const display = use.context.display();

	const titleSize = display.select({
		mobile: 18,
		default: 23,
	});

	const fontSize = display.select({
		mobile: 14,
		default: 16,
	});

	const {paymentMean, receptionMean} = vOrder.order;

	const textStyle = use.memo({fontSize, lineHeight: '150%'}, [fontSize]);

	const {Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<RNView {...props}>
			<Text style={{bold: true, fontSize: titleSize, marginBottom: 10}}>
				{`Les étapes de votre commande`}
			</Text>

			<TextStyleContext.Provider value={textStyle}>
				<>
					{
						paymentMean?.is.online &&
						<Text>
							<Text style={{bold: true}}>
								{`« En validation »`}
							</Text>
							{` nous validons votre paiement.`}
						</Text>
					}

					<Text>
						<Text style={{bold: true}}>
							{`« En préparation »`}
						</Text>
						{` l'établissement prépare votre commande.`}
					</Text>

					<Text>
						<Text style={{bold: true}}>
							{
								receptionMean.select({
									collect: `« Prête à récupérer »`,
									delivery: `« En livraison »`
								})
							}
						</Text>
						{` votre commande est ${
							receptionMean.select({
								collect: `prête`,
								delivery:  `en chemin`
							})
						}.`}
					</Text>

					<Text>
						<Text style={{bold: true}}>
							{`« Terminée »`}
						</Text>
						{` la commande est réglée et delivrée.`}
					</Text>
				</>
			</TextStyleContext.Provider>
		</RNView>
	);
}

export default React.memo(ReservationHelp);


const localStyles = {
	layout: {},
};
