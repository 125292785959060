export default function onSwitch(value){
	let cases = [];
	let defaultCase;
	let comparator = onSwitch.defaultComparator;

	return {
		run: (...params) => {
			let theCase = cases.find(aCase => comparator(value, aCase.value)) || defaultCase;
			if (theCase?.run)
				return theCase.run(value, params);
		},

		setComparator(func) {
			comparator = func;
			return this;
		},

		case(value, run) {
			cases.push({value, run});
			return this;
		},

		default(run){
			defaultCase = {run};
			return this;
		},

		select(value, result){
			this.case(value, () => result);
			return this;
		},
	}
}

onSwitch.defaultComparator = Object.is;
