import {Base} from "../class";

export default class Time extends Base {
	constructor(hour, minute) {
		super();
		this.hour = hour;
		this.minute = minute;
	}


	get totalMinutes(){
		let result = 0;

		if (this.minute)
			result = this.minute;

		if (this.hour)
			result += this.hour * 60;

		return result;
	}

	static fromMinutes(totalMinutes){
		if (!totalMinutes)
			totalMinutes = 0;

		let hour = Math.trunc(totalMinutes / 60);
		let minute = totalMinutes % 60;

		return new this()
			.hydrate({hour, minute});
	}
}

Time.addProperties({
	hour: Number,
	minute: Number,
});
