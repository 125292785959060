import cache from "./cache"

export {default as SplitCacheAccessor} from "./accessor/SplitCacheAccessor"
export {default as ListCacheAccessor} from "./accessor/ListCacheAccessor"

export {default as updateList} from "./method/updateList"
export {default as updateMultipleLists} from "./method/updateMultipleLists"
export {default as updateOneItemInList} from "./method/updateOneItemInList"
export {default as updateMultipleEntries} from "./method/updateMultipleEntries"

export default cache;
