import React from "react"
import useMemo from "./useMemo";

export default function useElement(factory, dependencies){
	return useMemo(() => (
		<MemoComponent
			factory={factory}
			dependencies={dependencies} />
	), dependencies);
}

const MemoComponent = React.memo(({factory, dependencies}) =>  factory(...(dependencies || [])));
