import Loadable from "../../library-react/library-js/utils/Loadable";
import Server from "../../library-react/library-js/app/Server";
import useSubscribe from "../../library-react/hook/useSubscribe";

export default function useShowcases() {
	return useShowcasesLoadable()?.value;
}

export function useShowcase(id) {
	const value = useShowcasesLoadable()?.value;
	if (value) // return null instead of undefined if value defined
		return value?.find(vShowcaseShop => vShowcaseShop.vShowcase.showcase.id == id)
			|| null;
}


// ---

function useShowcasesLoadable() {
	useSubscribe(loadable?.onStateChanged);
	return loadable;
}

useShowcases.loadable = useShowcasesLoadable;

// ---

const loadable = window.shopId ? new Loadable(() => Server.public.showcase.shop.getShowcases(window.shopId)
	.then(response => {
		if (response.ok)
			return response.content;
		response.throw();
	})
) : undefined;

loadable?.load();
