import React, { useContext } from "react"
import { ActivityIndicator } from "react-native"
import Text from "../../component/Text/v2";
import useMemo from "../useMemo";
import ErrorView from "../../component/ErrorView";
import TextInput from "../../component/input/TextInput";
import ModalFrame from "../../component/ModalFrame";
import responsiveFactory from "../../component/abstract/responsiveFactory";
import Environment from "../../library-js/Environment";
import DefaultToast from "../../component/DefaultToast";

export const DEFAULT_THEME = {
	Spinner: ActivityIndicator, // size color style
	Text,
	TextInput,
	ErrorView,
	Dialog: ModalFrame.Dialog,
	BottomSheet: ModalFrame.BottomSheet,
	DefaultModal: responsiveFactory(ModalFrame.BottomSheet, ModalFrame.Dialog),
	DefaultToast,
};

const ThemeContext = React.createContext(DEFAULT_THEME);
export default ThemeContext;

// ---- override provider ----
const OriginalProvider = ThemeContext.Provider;
ThemeContext.Provider = function ThemeContextProvider({ value: themeProp, ...props }) {
	const parentTheme = useContext(ThemeContext);

	const isFunction = themeProp instanceof Function;
	props.value = useMemo(() => {
		const newTheme = isFunction ? themeProp(parentTheme) : themeProp;
		return Object.assign({}, parentTheme, newTheme || {});
	},
		[isFunction ? parentTheme : themeProp]
	);

	return <OriginalProvider {...props} />;
};
