import React from "react"
import use from "library-react/hook"
import CommentView from "./CommentView";
import View from "library-react/component/View";
import Header from "./Header";
import FlatList from "library-react/component/FlatList";


export default function DesktopCommentList({name, rateLoadable, iterator, edit, ...props}) {
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<View {...props}>
			<Header title={name} rateLoadable={rateLoadable} edit={edit} style={localStyles.header}/>
			<View style={localStyles.separator}/>
			<FlatList
				data={iterator.items}
				keyExtractor={keyExtractor}
				contentContainerStyle={localStyles.container}
				renderItem={({item}) =>
					<CommentView
						item={item}
						style={localStyles.item}
					/>
				}/>
		</View>
	);
}


function keyExtractor(vComment) {
	return vComment.author.id;
}

const localStyles = {
	layout: {
		flex: 1,
		paddingLeft: 23,
		paddingRight: 35,
	},

	container: {paddingVertical: 25},

	header: {
		paddingLeft: 85,
		paddingRight: 22,
		marginBottom: 16,

	},
	separator: {
		height: 2,
		backgroundColor: '#f7f7f7'
	},

	item: {
		backgroundColor: '#f7f7f7',
		paddingTop: 38,
		paddingLeft: 38,
		paddingBottom: 13,
		paddingRight: 65,
		marginBottom: 10,
	}
};
