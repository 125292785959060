import Entity from "../Entity";
import Image from "../../media/Image";
import ShowcaseArticle from "./Article";
import simpleValidate from "../../../../utils/simpleValidate";
import Trigger from "../../../../utils/Trigger";
import {parallel} from "../../../../utils/function";

export default class Showcase extends Entity {
	get isPublic() {
		return !this.ownerShopId;
	}

	getInvalidFields() {
		return Object.entries(Showcase.generateValidateOf)
			.filter(([, validator]) => !validator.length) // remove validator that need arguments
			.filter(([field, getValidator]) => !getValidator().apply(this, [this[field], field]))
			.map(([field]) => field);
	}

	spliceArticle(start, deleteCount, ...articles) {
		this.articles.splice(start, deleteCount, ...articles);
		// articles?.forEach(article => article.onPropertyChanged(this.__changeTrigger.fire));
		this.__changeTrigger.fire();
	}

	// Only listen for change of the current articles and spliceArticle
	// NOT LISTEN FOR ADDED PRODUCT
	onArticlesChanged(listener) {
		this.__changeTrigger = new Trigger;
		const unlisteners = this.articles?.map(article => article.onPropertyChanged(this.__changeTrigger.fire)) || [];
		return parallel(this.__changeTrigger.add(listener), ...unlisteners);
	}

	copy() {
		const copy = super.copy();
		if (this.articles)
			copy.articles = this.articles.map(article => article.copy());
		return copy;
	}
}

Showcase.addProperties({
	title: String,
	description: String,
	banner: Image,
	portrait: Image,
	articles: {
		type: Array,
		template: ShowcaseArticle,
	},
	ownerShopId: Number,
	categories: Array, // Shop.Category.id[]
	creationDate: Number,
});

Showcase.generateValidateOf = {
	title: () => text => {
		if (simpleValidate.text(text))
			return text.length <= 40;
	},
	banner: () => simpleValidate.image,
	portrait: () => simpleValidate.image,
	description: () => text => {
		if (simpleValidate.text(text))
			return text.length > 100;
	},
	articles: () => (articles) => {
		if (!articles || articles.length === 0)
			return true;
		return articles.every(article => article.getInvalidFields().length === 0);
	}
};
