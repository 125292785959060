import { Pressable, StyleSheet, View } from "react-native";
import Image from "../../../../library-react/component/Image";
import Portal from "../../../../library-react/component/Portal";
import use from "../../../../library-react/hook";
import { formatPrice } from "../../../../library-react/library-js/app/model/utils/price";
import { screens } from "../../../links";
import { OrderItemViewProps } from "./types";

export default function OrderItemViewDesktop({ item, ...props }: OrderItemViewProps) {
	const { prodshop, title, subtitle, quantity, price, currency } = item;
	const product = prodshop?.product;
	const imageUri = product?.galleryList?.[0]?.url;

	const { Text } = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		// @ts-ignore
		<Portal to={product && screens.product} params={{ id: product?.id }} a>
			<View {...props}>
				<Image
					source={{ uri: imageUri }}
					resizeMode="cover"
					style={localStyles.image}
				/>

				{/* infos */}
				<View style={localStyles.infos}>
					{/* title */}
					<Text
						numberOfLines={2}
						style={localStyles.title}>
						{title}
					</Text>

					{/* subtitle */}
					<Text
						numberOfLines={1}
						style={localStyles.subtitle}>
						{subtitle}
					</Text>
				</View>

				{/*	 quantity */}
				<Text
					numberOfLines={1}
					style={localStyles.quantity}>
					{quantity}
				</Text>

				{/* price */}
				<Text
					numberOfLines={1}
					style={localStyles.price}>
					{formatPrice(price, currency)}
				</Text>

			</View>
		</Portal>
	);
}


const localStyles = StyleSheet.create({
	layout: {
		flexDirection: "row",
		alignItems: "center",
	},

	image: {
		height: 100,
		width: 80,
		alignSelf: "flex-start",
	},

	infos: {
		paddingLeft: 35,
		flex: 1,
		alignSelf: "stretch",
	},

	title: {
		fontSize: 23,
		bold: true,
	},

	subtitle: {
		maxWidth: 430,
		fontSize: 20,
		color: "#504747",
		flex: 1,
	},
	
	quantity: {
		fontSize: 23,
		textAlign: "center",
		minWidth: 100,
	},

	price: {
		fontSize: 23,
		bold: true,
		textAlign: "right",
		minWidth: 170,
	},
});