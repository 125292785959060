import React from "react"
import {View as RNView} from "react-native"
import use from "../../../hook"
import {styles} from "../../../res"
import Tabs from "./Tabs";
import ChatUI from "./ChatUI"
// import OrderUI from "./OrderUI"
import Environment from "../../../library-js/Environment";
import {ShopChatContext} from "../useShop";
import Platform from "../../../library-js/Platform";
import { ChatManager } from "../../../library-js/app/model/chat";


function ChatFragment(
	{
		id,
		loadInterlocutor,
		initialAttachment,
		onOrderClicked,
		onOrderItemClicked,
		onProductClicked,
		onReservationClicked,
		selectProduct,
		noOrderTab,
		notVisible,
		textInputStyle,
		hidePrice,
		shop,
		...props
	},
	refProp
) {
	let [tab, setTab] = use.state(0);
	if (noOrderTab)
		tab = 0;

	const interlocutorLoadable = use.loadable.server(
		() => loadInterlocutor(id),
		[id],
	);

	let instances;
	const chatUiApi = use.memo({
		setAttachment(attachment) {
			instances.chat?.setAttachment(attachment);
			setTab(0); // open chat tab
		},

		focusInput() {
			instances.chat?.focusInput();
		}
	});

	instances = use.instances({
		chat: chat => {
			use.setRef.set(refProp, chat && chatUiApi);

			if (chat && initialAttachment) {
				chat.setAttachment(initialAttachment);
				initialAttachment = undefined;
			}
		},
	});

	const shopId = shop?.id || Platform.select({user: id, retailer: ChatManager.id});
	const userId = Platform.select({user: ChatManager.id, retailer: id});


	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<ShopChatContext.Provider value={shop}>
			<RNView {...props}>
				{/* {
					!noOrderTab &&
					<Tabs
						tabs={TABS}
						selected={tab}
						onSelected={setTab}
					/>
				} */}

				<RNView style={localStyles.content}>
					<ChatUI
						ref={instances.set.chat}
						interlocutorId={id}
						textInputStyle={textInputStyle}
						interlocutorLoadable={interlocutorLoadable}
						visible={!notVisible && tab === 0}
						onOrderClicked={onOrderClicked}
						onOrderItemClicked={onOrderItemClicked}
						onProductClicked={onProductClicked}
						onReservationClicked={onReservationClicked}
						selectProduct={selectProduct}
						hidePrice={hidePrice}
						style={localStyles.ui(tab === 0)}
					/>

					{/* {
						!noOrderTab &&
						<OrderUI
							shopId={shopId}
							userId={userId}
							interlocutorId={id}
							interlocutorLoadable={interlocutorLoadable}
							onOrderClicked={onOrderClicked}
							style={localStyles.ui(tab === 1)}
						/>
					} */}
				</RNView>
			</RNView>
		</ShopChatContext.Provider>
	);
}

export default React.forwardRef(ChatFragment);

const TABS = [
	{icon: "round-chat"},
	{icon: "orders-list"}
];

const localStyles = {
	layout: {
		backgroundColor: styles.color.white,
	},

	head: {
		flexDirection: styles.flexDirection.row,
	},

	content: {
		flex: 1,
	},

	ui: styles.static.bool(
		styles.fit,
		null,
		Environment.select({
			default: styles.gone,
			android: {
				opacity: 0,
				zIndex: -1,
			}
		}),
	)
};
