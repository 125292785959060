import React from "react"
import { View as RNView } from "react-native"
import use from "library-react/hook";
import { styles } from "@main/res"
import View from "library-react/component/View/v2";
import Icon from "library-react/component/Icon/v2";
import FlatList from "library-react/component/FlatList";
import CatalogSectionView from "../../component/CatalogSectionView";
import ErrorView from "@main/component/ErrorView";
import CatalogSection from "library-js/app/model/entity/CatalogSection";
import Spinner from "@main/component/Spinner";
import ProductItem from "@main/component/ProductItem";
import BackNavigationButton from "@main/component/BackNavigationButton";
import Portal from "library-react/component/Portal";
import { linking, screens } from "@main/links";
import ShowcasesBanner from "@main/component/ShowcasesBanner";
import { createShopWebUrl } from "library-react/utils/showSocialModal";
import Sharable from "library-react/component/sharable/Sharable";
import { useRoute } from "@react-navigation/core";

export default function CatalogDesktop(
	{
		section,
		productsIterator,
		sectionsLoadable,
		optimizationParams,
		...props
	}
) {
	const {/** @type {CatalogCupboard} */ value: cupboard } = sectionsLoadable;

	const route = useRoute();
	const contentUrl = `${linking.config.screens[route.name]}${URL.Params.encode(route.params) && '?'}${URL.Params.encode(route.params)}`

	const { Text } = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);
	props.contentContainerStyle = use.defaultStyle(props.contentContainerStyle, localStyles.content);

	return (
		<FlatList
			ListHeaderComponent={
				<>
					<Sharable url={createShopWebUrl(vShop.shop, contentUrl, optimizationParams)} />
					{/* title & search */}
					<RNView style={localStyles.head}>
						<BackNavigationButton />

						{/* title */}
						<Text
							accessibilityRole="heading"
							numberOfLines={1}
							style={localStyles.title}>
							{section?.path.last.toUpperCase() || `CATALOGUE`}
						</Text>

						{/* SearchBar */}
						<Portal to={screens.search}>
							<View style={localStyles.search.layout}>
								<Text style={localStyles.search.text}>
									{'RECHERCHER'}
								</Text>

								<Icon
									name="search"
									size={17}
									style={localStyles.search.icon}
								/>
							</View>
						</Portal>
					</RNView>

					{/* Banner */}
					<ShowcasesBanner style={localStyles.banner} />

					{
						(
							cupboard?.getChildrenOf(section) // data
							|| (sectionsLoadable.loading ? Array.range(3) : null) // or loading
						)?.map((section, index) =>
							<CatalogSectionView
								key={index}
								section={(section instanceof CatalogSection) ? section : undefined}
								loading
								style={localStyles.catalogSection}
							/>
						)
						|| <ErrorView retry={sectionsLoadable.load} />
					}

					{
						productsIterator &&
						(cupboard?.getChildrenOf(section).length > 0) &&
						<Text
							numberOfLines={1}
							style={localStyles.allProducts}>
							{`TOUT ${section?.path.last || ""}`.toUpperCase()}
						</Text>
					}
				</>
			}
			// products placed in grid
			data={productsIterator?.items || []}
			numColumns={5}
			columnWrapperStyle={localStyles.productsRow}
			keyExtractor={item => item.product.id}
			renderItem={
				!productsIterator ?
					nothing :
					({ item }) => (
						<Portal
							key={item.product.id}
							to={screens.product}
							params={{ id: item.product.id }}>
							<ProductItem
								vProdshop={item}
								style={localStyles.product}
							/>
						</Portal>
					)
			}
			onEndReached={productsIterator?.ready ? productsIterator?.load : undefined}
			onEndReachedThreshold={.01}
			ListFooterComponent={
				(productsIterator && !productsIterator.end) &&
				<Spinner style={{ marginVertical: 30 }} />
			}
			{...props} />
	);
};

const nothing = () => null;

const localStyles = {
	layout: {
		paddingLeft: 35,
		backgroundColor: "#f7f7f7",
		flex: 1,
	},

	banner: {
		marginBottom: 50,
	},

	content: {
		maxWidth: 1500,
	},

	head: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.baseline,
		marginTop: 40,
		marginBottom: 30,
	},

	title: {
		fontSize: 40,
		flex: 1,
		bold: true,
		flexShrink: 1,
	},

	search: {
		layout: {
			width: 345,
			justifyContent: styles.justifyContent.spaceBetween,
			flexDirection: styles.flexDirection.row,
			alignItems: styles.alignItems.center,
			paddingBottom: 7,
			borderBottomWidth: 1,
			borderColor: "#6f6f6f",
		},

		icon: {
			color: "#6f6f6f",
		},

		text: {
			fontSize: 17,
			color: "#6f6f6f",
		},
	},

	catalogSection: {
		paddingLeft: 25,
		marginBottom: 20,
		paddingVertical: 25,
	},

	allProducts: {
		marginTop: 40,
		marginBottom: 20,
		marginLeft: 20,
		fontSize: 19,
		bold: true,
	},

	productsRow: {
		padding: 20,
	},

	product: {
		marginRight: 20,
	}
};
