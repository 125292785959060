import React from "react"
import {View as RNView} from "react-native"
import {styles} from "@main/res";
import use from "library-react/hook";
import SimpleButton from "library-react/component/SimpleButton";
import BasketContent from "./BasketContent";
import Color from "library-js/utils/Color"
import Environment from "library-js/Environment";
import Portal from "library-react/component/Portal";
import {screens} from "@main/links";
import moment from "moment";
import OrdersUnavailableMessage from "library-react/component/OrdersUnavailableMessage";

export default function MobileBasket({loadable, total, setQuantityOf, removeItem, ...props}) {
	const {Text} = use.theme();
	const {shop} = use.context.infos().vShop;

	const disabled = !shop.ordersAvailable;

	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<RNView {...props}>
			<Text style={localStyles.title}>
				{`PANIER`}
			</Text>

			<BasketContent
				loadable={loadable}
				setQuantityOf={setQuantityOf}
				removeItem={removeItem}
				contentContainerStyle={localStyles.scroll.content}
				style={localStyles.scroll.layout}
			/>

			{
				total &&
				<RNView style={localStyles.footer.layout}>
					<RNView style={localStyles.footer.summary.layout(Environment.browser)}>
						<RNView style={localStyles.footer.summary.wrapper}>
							<RNView style={localStyles.footer.summary.texts}>
								<Text style={localStyles.footer.summary.total}>
									{`Total`}
								</Text>

								<Text style={localStyles.footer.summary.tva}>
									{`TVA compris`}
								</Text>
							</RNView>

							<Text style={localStyles.footer.summary.price}>
								{total.formatted}
							</Text>
						</RNView>
					</RNView>
					<Portal to={!disabled && screens.checkout}>
						<SimpleButton
							disabled={disabled}
							filled={disabled}
							colors={disabled ? localStyles.footer.checkout.disabledColors: localStyles.footer.checkout.colors}
							{...localStyles.footer.checkout.layout}>
							<Text style={localStyles.footer.checkout.text}>
								{`COMMANDER`}
							</Text>

							{
								!shop.ordersOpened &&
								<OrdersUnavailableMessage shop={shop} />
							}
						</SimpleButton>
					</Portal>
				</RNView>
			}
		</RNView>
	);
}

const PADDING_HORIZONTAL = 20;

const localStyles = {
	layout: {
		flex: 1,
	},

	title: {
		color: styles.color.black,
		fontSize: 25,
		bold: true,
		paddingVertical: 15,
		paddingHorizontal: PADDING_HORIZONTAL,
	},

	scroll: {
		layout: {
			flex: 1,
		},
		content: {
			paddingHorizontal: PADDING_HORIZONTAL,
			paddingBottom: 80,
		},
	},

	item: styles.static(
		{
			borderTopWidth: .5,
			borderColor: styles.color.borderColor,
		},
		{
			0: {
				marginTop: 0,
				borderTopWidth: 0,
			},

			default: {}
		}
	),

	footer: {
		layout: {
			marginHorizontal: PADDING_HORIZONTAL,
			paddingTop: 17,
			paddingBottom: 14,
		},

		summary: {
			layout: styles.static(
				{
					flexDirection: styles.flexDirection.row,
					justifyContent: styles.justifyContent.flexEnd,
					paddingVertical: 20,
					paddingHorizontal: 15,
					...styles.absolute({left: 0, right: 0, bottom: "100%"}),
				},
				{
					Firefox: {
						backgroundColor: new Color("rgb(208,208,208)").lighten(.15).toString(),
					},
					default: {
						backdropFilter: `saturate(180%) blur(${50 * 0.2}px)`,
						backgroundColor: "rgba(208,208,208,0.25)",
					}
				}
			),

			wrapper: {
				minWidth: "50%",
				flexDirection: styles.flexDirection.row,
				justifyContent: styles.justifyContent.spaceBetween,
				alignItems: styles.alignItems.flexStart,
			},

			texts: {
				marginRight: 5,
			},

			total: {
				fontSize: 17,
				color: styles.color.black,
				bold: true,
			},

			tva: {
				fontSize: 14,
				color: "#9a9999",
			},

			price: {
				fontSize: 19,
				color: styles.color.black,
				bold: true,
			},
		},

		checkout: {
			layout: {
				border: true,
				style: {
					height: 80,
					paddingHorizontal: undefined,
					flexDirection: styles.flexDirection.column,
					justifyContent: styles.justifyContent.spaceEvenly,
				}
			},

			colors: {
				primary: styles.color.black,
				secondary: styles.color.white,
			},
			disabledColors: {
				primary: '#70707020',
				secondary: '#707070',
			},
			text: {
				fontSize: 20,
			},
			note: {
				marginTop: 5,
				textAlign: styles.textAlign.center,
				italic: true,
				color: styles.color.grey,
				fontSize: 17
			},
		}
	}
};
