import Entity from "./Entity"

/**
 * https://docs.google.com/document/d/1Q6V9MqC98t4Qh5qAK3mx6SAly-ToDvF99DN-Q2TTAZs/edit#bookmark=id.ityfv0cqw0vu
 */
class BasketItem extends Entity {}
BasketItem.addProperties({
	productId: Number,
	shopId: Number,
	size : String,
	quantity: Number,
	userId : String,
	creationDate : Number,
});

export default {Item : BasketItem}
