import React from "react";
import OrderRow from "./OrderRow";
import { styles } from "@main/res";
import { FlatList } from "react-native";
import Footer from "../Footer";
import use from "library-react/hook";

export default function MobileUI({ title, iterator, ...props }) {
	const { Text } = use.theme();
	return (
		<FlatList
			ListHeaderComponent={() =>
				Boolean(iterator.items.length) &&
				<Text style={localStyles.title}>
					{title}
				</Text>
			}

			data={iterator.items}
			keyExtractor={item => String(item.id)}
			renderItem={({ item, index }) =>
				<OrderRow
					item={item}
					style={localStyles.row(index % 2)}
				/>
			}

			onEndReached={iterator.load}
			ListFooterComponentStyle={Footer.WRAPPER_STYLE}
			ListFooterComponent={() => <Footer iterator={iterator} />}

			contentContainerStyle={localStyles.content}
			{...props}
		/>
	)
}

const localStyles = {
	title: {
		marginLeft: 20,
		fontSize: 25,
		bold: true,
	},

	row: styles.static.bool(
		{},
		{
			backgroundColor: '#f7f7f7',
		},
		{
			backgroundColor: styles.color.white,
		}
	),

	content: {
		flexGrow: 1,
	},
};
