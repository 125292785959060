import React from "react"
import Button from "@main/component/Button";
import styles from "library-react/res/styles";
import use from "library-react/hook";
import AuthDialog from "@main/component/AuthDialog";
import BasketManager from "../../../../library-react/library-js/app/model/basket/BasketManager";
import useAuth from "library-react/hook/useAuth";
import {SizeContext, VProdshopContext} from "@main/ui/ProductUI/contexts";
import useApp from "library-react/app/useApp";
import {View} from "react-native";
import moment from "moment";
import BasketToast from "@main/ui/ProductUI/ProductSection/BasketToast";
import useToast from "library-react/hook/useToast";
import {SIDEBAR_WIDTH} from "@main/App/Frame/NavigationView/SideBar";
import AuthManager from "../../../../library-react/library-js/AuthManager";

export default function AddToBasketButton({selectSize, ...props}) {
	const display = use.context.display();

	const vProdshop = use.context(VProdshopContext);
	const size = use.context(SizeContext);

	const {shop} = vProdshop;

	let user = useAuth.user();


	const debouncers = use.memo({/* size: clickedTimes */});

	const [loading, setLoading] = use.state(false);

	const app = useApp();
	const toast = useToast();
	const addProduct = use.asyncCallback(shouldStop =>
			async () => {
				// invite user to authenticate
				if (!user) {
					const [promise, resolve] = Promise.external();

					app.openModal( <AuthDialog />, true, resolve);

					await promise;
					if (shouldStop()) return;
					
					// canceled
					user = AuthManager.user;
					console.log(user);
					if (!user) return;
				}

				const {product} = vProdshop;

				// size selection (always select size on mobile)
				let sizeToAdd = size;
				if (product.sizes?.length > 0 && (display.is.mobile || !sizeToAdd)) {
					sizeToAdd = await selectSize();
					if (shouldStop()) return;

					// canceled
					if (!sizeToAdd) return;
				}


				// ---- ADD PRODUCT algorithm ----
				const debouncer = debouncers[sizeToAdd] || (debouncers[sizeToAdd] = {clicked: 0});
				debouncer.clicked++;

				// already running
				if (debouncer.running)
					return;

				// not running. take the lead
				debouncer.running = true;

				const {shop} = vProdshop;
				try {
					if (debouncer.quantity === undefined) {
						setLoading(true);
						if (!debouncers.basketItems) {
							const response = await BasketManager.getItemsForProductInShop(product.id);
							if (response.ok)
								debouncers.basketItems = response.content.toObject(vItem => vItem.size);
							else
								response.throw();
						}

						const basketItem = debouncers.basketItems[sizeToAdd];
						debouncer.quantity = basketItem?.quantity || 0;
					}

					// debounce user clicks
					let clicked;
					do {
						clicked = debouncer.clicked;
						await Promise.await(500);
					} while (clicked < debouncer.clicked);

					setLoading(true);
					const newQuantity = debouncer.quantity + debouncer.clicked;
					await BasketManager.persist(shop.id, product.id, sizeToAdd, newQuantity);


					// display notification
					toast(<BasketToast vProdshop={vProdshop}/>, BASKET_TOAST_CONFIG);
				} finally {
					debouncer.running = false;
					setLoading(false);
				}
			},
		[user && vProdshop, size, display],
	);

	const {Text, Spinner} = use.theme();

	const disabled = !shop.ordersAvailable || loading;

	props.style = use.defaultStyle(props.style, localStyles.layout(display), [display]);
	return (
		<View {...props}>
			{
				!shop.orderPermanentlyDisabled &&
				<Button
					onPress={addProduct}
					filled={!loading}
					disabled={disabled}
					colors={COLORS}
					disabledColors={!loading && DISABLED_COLORS}
					hoverColors={!(display.is.mobile || disabled) && HOVER_COLORS}
					style={localStyles.button}>
					{
						Boolean(loading) ? <>
								<Spinner size={24} color="black"/>
								<Text style={{marginLeft: 15, fontSize: 18}}>
									{`Ajout en cours...`}
								</Text>
							</> :
							<Text style={localStyles.text(display)}>
								{`Ajouter au panier`}
							</Text>
					}

				</Button>
			}

			{
				!shop.ordersEnabled ? (
						<Text style={localStyles.note(display)}>
							{shop.unavailableNote}
						</Text>
					) :
					(
						!shop.ordersOpened && shop.nextSwitch &&
						<Text style={localStyles.note(display)}>
							{`Ouvre ${moment(shop.nextSwitch.next).calendar().firstDown}`}
						</Text>
					)
			}
		</View>
	);
}

const COLORS = {
	primary: styles.color.black,
	secondary: styles.color.white,
};
const HOVER_COLORS = {
	primary: '#70707020',
	secondary: styles.color.black,
};
const DISABLED_COLORS = {
	primary: '#70707060',
	secondary: '#707070',
};

const BASKET_TOAST_CONFIG = {
	cardStyle: (openingAv, position) => {
		return {
			position: 'absolute',
			right: 0,
			left: SIDEBAR_WIDTH,
			opacity: openingAv,

			transform: [
				{translateY: position},
				{
					translateY: openingAv.interpolate({
						inputRange: [0, 1],
						outputRange: [-100, 0],
					})
				},
			]
		};
	},
}

const localStyles = {
	layout: styles.static({
			justifyContent: styles.justifyContent.center,

		},
		{
			mobile: {flexDirection: styles.flexDirection.columnReverse,},
			default: {flexDirection: styles.flexDirection.column,},
		}
	),

	button: {
		minHeight: 62,
	},

	text: styles.static(
		null,
		{
			mobile: {fontSize: 20},
			default: {fontSize: 23},
		}
	),
	note: styles.static(
		{
			textAlign: styles.textAlign.center,
			italic: true,
			color: styles.color.grey,
		},
		{
			mobile: {
				fontSize: 13,
				marginBottom: 5,
			},
			default: {
				marginTop: 20,
				fontSize: 17
			},
		}
	),
};
