import is from "./is"
import Debug from "../Debug"

/**
 * 🔬
 * Try to clone the rat passed as parameter.
 * @param rat Rat to clone.
 * @param {Map} instanciers Map from types/classes to functions which will be used to instantiate those types.
 * @returns {*} The rat's clone.
 */
export default function clone(rat, instanciers) {
	Debug.assert(!is(instanciers, Map, true), "clone function takes as 2nd parameter a Map from types to function which will be able to instanciate those types.");

	if (// not cloneable
		is.primitive(rat)
		|| is(rat, Function)
		|| clone.unclonables.some(type => is(rat, type))
	)
		return rat; // return as it is


	let type = is(rat);
	switch (type) {
		case Array:
			return rat.map(value => clone(value, instanciers));

		case Map:
			let map = new Map();
			rat.keys()
				.forEach(key => {
					let value = clone(rat.get(key), instanciers);
					map.set(key, value);
				});
			return map;

		default: // Object
			let result;
			if (instanciers && instanciers.has(type))
				result = instanciers.get(type)(rat);

			else if (type === Object)
				result = {};
			else // instance result (Might throw an error)
				result = new type();

			if (result)
				Object.keys(rat)
					.forEach(key => result[key] = clone(rat[key]));

			return result;
	}
}

clone.unclonables = [];
