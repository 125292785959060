import Base from "../../../../class/Base"
import Badges from "../../Badges"
import Comment from "../../Comment"


export default class Evaluation extends Base {
	constructor(){
		super();
		this.badges = new Badges();
		this.comment = new Comment();
	}
}

Evaluation.addProperties({
	comment : Comment,
	badges : Badges,
	note : String,
	creationDate : Number,
});
