import strictFetch from "./strictFetch"
import { pipe, andThen } from "ramda";

const simpleFetch = pipe(strictFetch, andThen(response => {
	const type = response.headers.get('Content-Type')?.toLowerCase();

	if (type.startsWith('application/json'))
		return response.json();

	if (type.startsWith('text/'))
		return response.text();

	return response.blob();
}));

export default simpleFetch;