import Notification from "./Notification";
import {VOrder} from "../../../view";
import {Image} from "../../../media";
import Price from "../../../Price";

export default class OrderNotification extends Notification {
	get ok(){
		return Boolean(
			super.ok
			&& this.vOrder?.currentState?.type
			&& this.vOrder?.order?.id
			&& this.vOrder?.order?.creationDate
			// && this.vOrder?.price // price can be null of all items are canceled
		);
	}

	static from(...params){
		const instance = super.from(...params);

		if (instance?.vOrder && !instance.vOrder.price)
			instance.vOrder.price = new Price();

		return instance;
	}
}

OrderNotification.addProperties({
	vOrder: VOrder,
	gallery: {
		type: Array,
		template: Image
	}
});
