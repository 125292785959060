import React from "react";
import StaticComponent from "../../../../../../../component/StaticComponent";
import {View as RNView} from "react-native";
import {styles} from "../../../../../../../res";
import {Circle, Svg} from "react-native-svg";

export default new StaticComponent(
	<RNView style={{width: 130, alignItems: styles.alignItems.center}}>
		<Svg width="2" height="17" viewBox="0 0 2 17">
			<Circle cx="1" cy="1" r="1" fill="#452ee0" transform="translate(0 2)"/>
			<Circle cx="1" cy="1" r="1" fill="#452ee0" transform="translate(0 7)"/>
			<Circle cx="1" cy="1" r="1" fill="#452ee0" transform="translate(0 12)"/>
			<Circle cx="1" cy="1" r="1" fill="#452ee0" transform="translate(0 17)"/>
		</Svg>
	</RNView>
);
