import ReactResevationFormModal, { Layout } from '../../library-react/component/ResevationFormModal';
import use from '../../library-react/hook';
import useAuthUser from '../../library-react/hook/useAuth/user';
import AuthenticateView from "./AuthenticateView";

export default function ResevationFormModal({ ...props }) {
	const { shop } = use.context.infos().vShop;
	const user = useAuthUser();

	return (
		user ?
			<ReactResevationFormModal shopId={shop.id} {...props} /> :
			<Layout
				{...props}
				style={{ padding: 40, minHeight: 400 }}>
				<AuthenticateView />
			</Layout>

	);
}