import { defaultTo, map, pipe, prop } from "ramda";
import useLoadable from "../../library-react/hook/useLoadable";
import { useApolloClient } from "@apollo/client"
import { SHOP_TAGS_QUERY } from "../../library-react/component/input/ShopTagsInput/hook"
import use from "../../library-react/hook";

export default function useStaticShopTags() {
	return useStaticShopTagsLoadable()?.value;
}

export function useStaticShopTagsNamesLoadable() {
	return useStaticShopTagsLoadable()?.proxy(pipe(
		defaultTo([]),
		// map(prop('name')),
	));
}


export function useStaticShopTagsLoadable() {
	const { vShop } = use.context.infos();
	const apollo = useApolloClient();

	return useLoadable(async () => {
		const { shopTagRelationship } = await apollo.strictQuery({
			query: SHOP_TAGS_QUERY,
			variables: { shopId: vShop.shop.id }
		});

		return shopTagRelationship?.map(shopTag => shopTag.tag.name);
	});
}

