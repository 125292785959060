import Base from "../../../../class/Base"
import VShop from "./VShop"
import Enum from "../../../../class/Enum"
import Sale from "../../entity/Sale"

export default class VSale extends Base {
	get id(){
		return this.sale && this.sale.id;
	}

	get shop(){
		return this.vShop && this.vShop.shop;
	}
}
VSale.addProperties({
	sale : Sale,
	vShop : VShop,
	numberOfProducts : Number,
	maxDiscount : Number,
	quantity : Number,
});


VSale.Product  = class VSaleProduct extends Base {};
VSale.Product.addProperties({
	sale : Sale,
	saleProduct : Sale.Product,
	quantity : Number,
	inBasket : Number,
});


VSale.Product.Stock = class VStock extends Base {
	get size(){
		return this.stock && this.stock.size;
	}

	set size(value){
		if (this.stock)
			this.stock.size = value;
	}

	get volume(){
		return this.stock && this.stock.volume;
	}

	set volume(value){
		if (this.stock)
			this.stock.volume = value;
	}


	// ------ state ------
	get state(){
		return this.available ? VSale.Product.Stock.State.available
			: this.reserved ? VSale.Product.Stock.State.reserved
				: VSale.Product.Stock.State.unavailable;
	}

	get available(){
		return Boolean(this.volume);
	}

	get reserved(){
		return !this.available && Boolean(this.inBasket);
	}

	get unavailable(){
		return !this.available && !this.reserved;
	}
};
VSale.Product.Stock.addProperties({
	stock : Sale.Product.Stock,
	inBasket : Number,
});


VSale.Product.Stock.State = new Enum("available", "reserved", "unavailable");
