import React from "react";
import {View as RNView} from "react-native"
import {styles} from "../../res";
import SimpleButton from "../SimpleButton";
import use from "../../hook";
import {parallel} from "../../library-js/utils/function";

export default function AgeDialog({shop, onceConfirmed, cancelable, close, ...props}) {
	const {Text, DefaultModal} = use.theme();
	return (
		<DefaultModal {...props}>
			<RNView style={localStyles.head}>
				<Text style={localStyles.title}>
					{`Avertissement`}
				</Text>

				<Text style={localStyles.description}>
					{`Vous devez avoir plus de ${shop.requiredAge} ans pour acceder à la boutique `}
					<Text style={{bold: true}}>
						{shop.name}
					</Text>.
				</Text>

				<RNView style={localStyles.footer}>
					{
						cancelable &&
						<SimpleButton
							onPress={close}
							style={localStyles.button}>
							{`Annuler`}
						</SimpleButton>
					}

					<SimpleButton
						filled
						onPress={parallel(onceConfirmed, close)}
						style={localStyles.button}>
						{`Je confirme${!cancelable ? ` avoir ${shop.requiredAge} ans ou plus` : ''}`}
					</SimpleButton>
				</RNView>

			</RNView>
		</DefaultModal>
	);
}

const localStyles = {
	head: {
		paddingVertical: 30,
	},

	title: {
		fontSize: 28,
		color: styles.color.black,
		bold: true,
		textAlign: styles.textAlign.center,
	},

	description: {
		fontSize: 20,
		color: "#504747",
		textAlign: styles.textAlign.center,
		marginVertical: 20,
	},

	footer: {
		marginTop: 20,
		flexDirection: styles.flexDirection.row,
		justifyContent: styles.justifyContent.spaceEvenly,
	},

	button: {
		minWidth: 100,
	},
};
