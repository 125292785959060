import Base from "../../../../class/Base"

export default class Card extends Base {}

Card.addProperties({
	Id: String,
	CreationDate: Number,
	Tag: String,
	ExpirationDate: String,
	Alias: String,
	CardProvider: String,
	CardType: String,
	Country: String,
	Product: String,
	BankCode: String,
	Active: Boolean,
	Currency: String,
	Validity: String,
	Fingerprint: String,
});
