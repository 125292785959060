import React from "react"
import use from "../../../../../hook"
import {styles} from "../../../../../res"
import ScrollView from "../../../../../component/ScrollView";
import ScrollViewRecorder from "../../../../../plugin/ScrollViewRecorder";

export default function WebLayout(
	{
		data,
		keyExtractor,
		renderItem,
		onEndReached,
		onEndReachedThreshold,
		ListFooterComponent,
		...props
	}
) {
	const instances = use.instances({scrollView: null});
	props.ref = instances.set.scrollView;

	const recorder = use.memo(() => {
		let lastBottomOffset = 0;
		const recorder = new ScrollViewRecorder();
		recorder.onScroll = () => lastBottomOffset = recorder.offset.bottom; // save

		// force scroll ref from bottom
		recorder.onContentChanged =
			recorder.onLayout = () => {
				const {layout, content} = recorder;
				const y = content.height - (lastBottomOffset + layout.height);
				instances.scrollView?.scrollTo({
					y: y > 0 ? Math.min(y, content.height) : 0,
					animated: false
				});
			};

		return recorder;
	});

	Object.assign(props, recorder.sensors);

	props.onStartReached = onEndReached;
	props.onStartReachedThreshold = onEndReachedThreshold;

	props.style = use.defaultStyle(props.style, localStyles.layout);
	props.contentContainerStyle = use.defaultStyle(props.contentContainerStyle, localStyles.container);

	return (
		<ScrollView {...props}>
			{data?.map((item, index) =>
				React.cloneElement(
					renderItem({item, index}),
					{key: keyExtractor(item, index)}
				)
			)}

			{ListFooterComponent}
		</ScrollView>
	);
}

const localStyles = {
	layout: {},

	container: {
		flexDirection: styles.flexDirection.columnReverse,
	}
};
