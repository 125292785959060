import use from "../../hook";
import {setSharableContext, sharableContext} from "./context";
import {append, dropLast} from "ramda";

export default function SharableContainer({...props}) {
	const [stack, set] = use.state(() => []);
	const current = stack.last;

	const setSharable = use.callback((value) => {
		set(append(value));
		return () => set(dropLast(1));
	});

	return (
		<setSharableContext.Provider value={setSharable}>
			<sharableContext.Provider value={current} {...props}/>
		</setSharableContext.Provider>
	);
}
