import React from "react"
import use from "library-react/hook";
import View from "library-react/component/View/v2";
import {styles} from "@main/res";
import GradientView from "library-react/component/GradientView";

function OpenableText({textStyle, maxHeight, children, showToggle, ...props}) {
	const [opened, setOpened] = use.state(false);
	const [isOk, setOk] = use.state(true);

	props.onPress = !isOk ? (() => setOpened(!opened)) : undefined;

	const onLayout = use.callback(({nativeEvent}) => {
			setOk(!(nativeEvent.layout.height >= maxHeight - 3));
		},
		[maxHeight]
	);

	const wrapperStyle = use.memo({
			overflow: styles.overflow.hidden,
			maxHeight: opened ? undefined : maxHeight,
		},
		[opened, maxHeight]
	);


	const {Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<View {...props}>
			<View onLayout={onLayout} style={wrapperStyle}>
				<Text style={textStyle}>
					{children}
				</Text>
				{
					!isOk && !opened &&
					<GradientView colors={['#ffffff00', '#ffffff']} style={localStyles.filter}/>
				}
			</View>
			{
				showToggle && !isOk &&
				<Text style={localStyles.toggle}>
					{
						opened ? 'Voir moins' : 'Voir plus'
					}
				</Text>
			}
		</View>

	);
}

export default React.memo(OpenableText);

const localStyles = {
	layout: {
		overflow: styles.overflow.hidden,
	},

	filter: {
		position: styles.position.absolute,
		bottom: 0,
		left: 0,
		right: 0,
		height: 40,
	},
	toggle: {
		fontSize: 20,
		textDecorationLine: styles.textDecorationLine.underline,
		textAlign: styles.textAlign.center,
	}
};
