import useApp from "library-react/app/useApp";
import use from "library-react/hook";
import useSyncEffect from "library-react/hook/useSyncEffect";
import getRouteFromState from "library-react/utils/navigation/getRouteFromState";

export default function useRouteForFrame() {
	const {navigation} = useApp();
	let [route, setRoute] = use.state(() => navigation?.getCurrentRoute());

	useSyncEffect(navigation &&
		function correctRouteOnceNavigationReady() {
			const currentRoute = navigation.getCurrentRoute();
			if (route !== currentRoute)
				setRoute(route = currentRoute);
		},
		[Boolean(navigation)],
	);

	useSyncEffect(navigation &&
		function listenState() {
			return navigation.addListener('state', event => {
				const newRoute = getRouteFromState(event.data.state);
				setRoute(newRoute);
			});
		},
		[navigation],
	);

	return route;
}
