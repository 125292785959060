import {Enum} from "library-js/class";

const DisplayType = Enum.make({
	mobile: 0,
	tablet: 1000,
	desktop: 1200,
});

export default DisplayType;

DisplayType.domains.large = [DisplayType.tablet, DisplayType.desktop];

/**
 * DisplayType[]
 */
const sorted = DisplayType.sorted =
	DisplayType.values.sort((type1, type2) => type1.value - type2.value);

/**
 * @param {number} width
 * @returns {DisplayType} Display corresponding to width.
 */
DisplayType.getFor = (width) =>
	sorted.find((type, index) => {
		const next = sorted[index + 1];
		return (
			type.value <= width
			&& (
				!next
				|| next.value > width
			)
		);
	});

DisplayType.getCurrent = () => DisplayType.getFor(window.innerWidth);