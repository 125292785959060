import React from "react"
import {View as RNView, ScrollView} from "react-native"
import use from "library-react/hook";
import {styles} from "@main/res"
import StarsView from "library-react/component/StarsView";
import SimpleButton from "library-react/component/SimpleButton";
import {STARS_STYLES} from "./constants";
import TextInput from "library-react/component/input/TextInput";

export default function CommentFormMobile(
	{
		loadable: {value: remoteVComment, loading, load, end},  // data
		title, subtitle,
		state, comment,
		update, submit, remove, // actions
		...props
	}
) {
	const {Text, Spinner, ErrorView} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);
	props.contentContainerStyle = use.defaultStyle(props.contentContainerStyle, localStyles.container);

	return (
		<ScrollView {...props}>
			{/* head */}
			<RNView style={localStyles.head}>
				<Text style={localStyles.title}>
					{title}
				</Text>

				{
					subtitle &&
					<Text style={localStyles.subtitle}>
						{subtitle}
					</Text>
				}
			</RNView>
			{
				comment ?
					<>
						<Text style={localStyles.rateLabel}>
							{`Ma note`}
						</Text>

						<StarsView
							stars={comment.stars}
							onStarsChanged={update.stars}
							useEyalStyle
							editable={state.is.editable}
							size={41}
							gap={21}
							{...STARS_STYLES}
							style={localStyles.stars}
						/>

						<Text
							numberOfLines={1}
							style={localStyles.textLabel}>
							{`Mon avis`}
						</Text>

						<TextInput
							value={comment.text}
							onValueChanged={update.text}
							multiline
							disabled={!state.is.editable}
							style={localStyles.input}
						/>


						<RNView style={localStyles.infos}>
							<Text style={localStyles.numberOfChars}>
								{comment.text?.length || 0}{` caractères`}
							</Text>

							{
								state.is.error &&
								<Text style={localStyles.error}>
									{`Une erreur s'est produite`}
								</Text>
							}
						</RNView>

						<Text
							style={localStyles.instruction}>
							{`Nous vous rappelons qu’il n’est pas permis de tenir de propos violents, diffamatoires ou discriminatoires.`}
						</Text>

						<Text style={localStyles.legal}>
							{`En cliquant sur le bouton “Publier mon avis”, vous acceptez `}
							<Text style={localStyles.conditions}>
								{`les conditions d’utilisations de Shopinzon`}
							</Text>
						</Text>

						<RNView style={localStyles.buttons}>
							{/* delete */}
							{
								(!state.is.saving && remoteVComment) &&
								<SimpleButton
									onPress={remove}
									border
									filled={!state.is.deleting}
									colors={localStyles.delete.colors}
									style={localStyles.delete.layout}>
									<Text style={localStyles.delete.text}>
										{
											state.is.deleting ? `Suppression en cours...` :
												`SUPPRIMER`
										}
									</Text>
								</SimpleButton>
							}

							{/* submit */}
							<SimpleButton
								onPress={submit}
								filled={state.is.editable}
								border
								colors={localStyles.submit.colors}
								style={localStyles.submit.layout(!state.is.deleting)}>
								<Text style={localStyles.submit.text}>
									{
										state.is.saving ? `Sauvegarde en cours...` :
											`PUBLIER MON AVIS`
									}
								</Text>
							</SimpleButton>
						</RNView>
					</> :

					// ------
					<RNView style={[styles.fit, styles.center]}>
						{
							loading || (end && !comment) ?
								<Spinner/> :
								// error
								!end &&
								<ErrorView retry={load}/>
						}
					</RNView>
			}
		</ScrollView>
	);
}


const localStyles = {
	layout: {},


	head: {
		paddingVertical: 15,
	},

	title: {
		fontSize: 28,
		color: styles.color.black,
		bold: true,
		textAlign: styles.textAlign.center,
	},

	subtitle: {
		fontSize: 18,
		color: "#504747",
		textAlign: styles.textAlign.center,
	},

	container: {
		paddingHorizontal: 21,
		paddingBottom: 40,
		flexGrow: 1,
	},

	rateLabel: {
		fontSize: 18,
		color: styles.color.black,
		marginRight: 19,
	},

	stars: {
		alignSelf: styles.alignSelf.center,
		marginBottom: 27,
	},

	textLabel: {
		fontSize: 18,
		color: styles.color.black,
		marginBottom: 6,
	},

	input: {
		height: 196,
		padding: 13,
		borderWidth: 1,
		borderColor: "#bfc1cd",
		fontSize: 16,
		color: styles.color.black,
		marginBottom: 15,
	},

	infos: {
		flexDirection: styles.flexDirection.row,
		justifyContent: styles.justifyContent.spaceBetween,
		marginBottom: 48,
	},

	numberOfChars: {
		fontSize: 16,
		color: styles.color.black,
		flex: 1,
	},

	error: {
		color: styles.color.error,
		fontSize: 16,
	},

	instruction: {
		fontSize: 15,
		color: styles.color.black,
		marginBottom: 10,
	},

	legal: {
		fontSize: 15,
		color: styles.color.black,
		marginBottom: 27,
	},

	conditions: {
		bold: true,
	},

	buttons: {},

	delete: {
		colors: {
			primary: "#cd081e",
			secondary: styles.color.white,
		},

		layout: {
			marginBottom: 11,
			height: 62,
			...styles.center,
		},

		text: {
			fontSize: 20,
		}
	},

	submit: {
		colors: {
			primary: styles.color.black,
			secondary: styles.color.white,
		},

		layout: styles.static.bool(
			{
				...styles.center,
				height: 62,
			},
			null,
			styles.invisible
		),

		text: {
			fontSize: 20,
		}
	},
};
