import React from "react";
import {is} from "../library-js/utils";
import useForceUpdate from "./useForceUpdate";
import useCallback from "./useCallback"
import useMemo from "./useMemo";

/**
 * Safe hook.
 * TODO rename file in useLegacyState : [user-native, retailer-web]
 */
export default function useLegacyState(initial) {
	const ref = useMemo(() => {
		const ref = {};

		if (initial instanceof Function)
			ref.state = initial();
		else
			ref.state = initial || {};

		return ref;
	});

	const forceUpdate = useForceUpdate();

	return [
		ref.state,

		// update state
		useCallback((update) => {
			if (is(update, Function))
				update(ref.state);
			else
				Object.assign(ref.state, update);

			forceUpdate();
		}),

		// set state
		useCallback(newState => {
			if (newState instanceof Function)
				newState = newState(ref.state);

			if (ref.state !== newState) {
				ref.state = newState;
				forceUpdate();
			}
		})
	];
}
