import React from "react"
import Form from "./Form";
import View from "library-react/component/View/v2";
import {styles} from "@main/res";
import use from "library-react/hook";


export default function MobileUI({onSuccess, ...props}) {
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<View {...props}>
			<Form onSuccess={onSuccess}/>
		</View>
	);
}

const localStyles = {
	layout: {
		padding: 15,
		paddingTop: 70,
		...styles.fit,
		backgroundColor: styles.color.white,
		borderWidth: .5,
		borderColor: '#cfd0d3'
	},
}