import React from "react"
import use from "../../library-react/hook"
import moment from "moment";

function OrdersUnavailableMessage({shop, children, ...props}) {
	const {ordersAvailable, ordersEnabled, unavailableNote, nextSwitch} = shop || {};

	const shouldShow = (
		shop
		&& !ordersAvailable
		&& (ordersEnabled || unavailableNote)
	);

	const {Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout, [ordersEnabled]);


	return Boolean(shouldShow) && (
		<Text {...props}>
			{
				!ordersEnabled ? `" ${unavailableNote} "` :
					<>
						<Text style={{color: 'red'}}>
							{`Fermé.`}
						</Text>
						{
							nextSwitch &&
							` Ouvre ${moment(nextSwitch.next).calendar()}`
						}
					</>
			}
		</Text>
	);
}

export default React.memo(OrdersUnavailableMessage);


const localStyles = {
	layout: enabled => !enabled && {italic: true, color: 'grey'},
};
