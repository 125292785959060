import React from 'react'
import { View as RNView } from "react-native";
import use from "../hook";
import View from "./View/v2";
import Icon from "./Icon/v2";
import { is } from "../library-js/utils";
import { TextStyleContext } from "./Text/v2";
import { styles } from "../res";

/**
 * @param {any}
 */
export default function PopupMenu(
	{
		trigger,
		children,
		persistAfterClick,
		opened: openedProp,
		alignLeft,
		alignBottom,
		popupStyle,
		Popup,
		...props
	}
) {
	const [opened, setOpenedTo] = use.state.bool(false);
	const { toggle, no: close, yes: open } = setOpenedTo;

	const context = use.memo({ opened, setOpenedTo }, [opened]);

	if (!Popup)
		Popup = RNView;

	// close when disabled
	use.effect(props.disabled && close, [Boolean(props.disabled)]);
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<PopupContext.Provider value={context}>
			<View
				onPress={toggle}
				onBlur={close}
				activeOpacity={1}
				{...props}>
				{
					is(trigger, String, true) ?
						<Icon
							name={trigger || "SimpleLineIcons/options-vertical"}
							size={24}
							style={localStyles.icon}
						/> :
						trigger
				}

				{
					(openedProp !== undefined ? openedProp : opened) &&
					<Popup style={[localStyles.popup({ alignBottom, alignLeft }), popupStyle].flat()}>
						{children}
					</Popup>
				}
			</View>
		</PopupContext.Provider>
	);
}

PopupMenu.Item = function PopupMenuItem({ children, ...props }) {
	const { Text } = use.theme();

	if (is(children, String))
		children = (
			<Text>
				{children}
			</Text>
		);

	props.style = use.defaultStyle(props.style, localStyles.item);

	return (
		<TextStyleContext.Provider value={localStyles.itemText}>
			<View {...props}>
				{children}
			</View>
		</TextStyleContext.Provider>
	);
};

export const PopupContext = React.createContext({});

const localStyles = {
	icon: {},

	popup: ({ alignLeft, alignBottom }) => ({
		...styles.absolute({ [alignLeft ? 'left' : 'right']: 0, [alignBottom ? 'bottom' : 'top']: 0 }),
		backgroundColor: styles.color.white,
		borderRadius: 6,
		...styles.newShadow(-2, 8, 10, .16),
		minWidth: 240,
		cursor: 'default',
		overflow: styles.overflow.hidden,
	}),

	item: {
		paddingHorizontal: 22,
		paddingVertical: 16,
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
		justifyContent: styles.justifyContent.spaceBetween,
		marginRight: 5,
	},

	itemText: {},

};
