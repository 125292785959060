import Offset from "./Offset";
import Sensors from "./Sensors";
import Reached from "./Reached";

export default class ScrollViewRecorder {
	static Offset = Offset;
	static Sensors = Sensors;

	sensors = new Sensors(this);

	layout = {};
	content = {};
	offset = new Offset(this);

	threshold = {
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
	};

	reached = new Reached(this);

	onLayout() {}
	onLayoutChanged(layout, oldLayout){}

	onContentSizeChange() {}
	onContentChanged(content, oldContent){}

	onScroll() {}

	onUpdate(){}
}
