import React from "react"
import use from "library-react/hook"
import {images, styles} from "@main/res"
import View from "library-react/component/View";
import Image from "library-react/component/Image/Image";
import Icon from "library-react/component/Icon/v2";

function ConnexionButton({provider, fontSize, space, ...props}) {
	const display = use.context.display()
	const [hover, setHover] = use.state(false);

	if (display.is.desktop)
		props.onHover = setHover;

	const {Text} = use.theme();

	props.style = use.defaultStyle(props.style, localStyles.layout, [display.is.desktop && hover]);

	return (
		<View {...props}>
			<View style={localStyles.provider.layout}>
				<Image
					source={images[provider]}
					resizeMode="contain"
					style={[localStyles.provider.logo, space && {marginHorizontal: space}]}
				/>

				<Text style={[localStyles.provider.text, fontSize && {fontSize}]}>
					{`S'IDENTIFIER AVEC ${provider.toUpperCase()}`}
				</Text>
			</View>

			{
				display.is.desktop &&
				<View style={localStyles.icon.wrapper}>
					{hover && <Icon name='next' size={20} style={localStyles.icon.item}/>}
				</View>
			}
		</View>
	);
}

export default React.memo(ConnexionButton);


const localStyles = {
	layout: styles.static.bool({
			flexDirection: styles.flexDirection.row,
			alignItems: styles.alignItems.center,
			justifyContent: styles.justifyContent.spaceBetween,
			minHeight: 65,
			padding: 18,
			paddingLeft: 0,
			borderWidth: 1,
			overflow: styles.overflow.hidden,
		},
		{
			backgroundColor: '#e4e4e4',
			borderColor: "#e4e4e4",
		},
		{
			borderColor: "grey",
		}
	),

	provider: {
		layout: {
			flexDirection: styles.flexDirection.row,
			alignItems: styles.alignItems.center,
		},
		logo: {
			height: 26,
			width: 26,
			marginHorizontal: 26,
		},

		text: {
			fontSize: 19
		},
	},

	icon: {
		wrapper: {
			marginLeft: 20,
			width: 20,
			height: 20,
		},
		item: {
			color: '#b2b2b2',
		}
	}
};
