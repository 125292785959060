import React from "react";
import ProductItem from "../../../component/ProductItem";
import GalleryWithCenterArrow from "../../../../library-react/component/Gallery/GalleryWithCenterArrow";
import Portal from "../../../../library-react/component/Portal";
import {screens} from "../../../links";
import use from "../../../../library-react/hook";
import {styles} from "../../../res";

export default function ProdshopsView({prodshops, itemStyle, ...props}) {
	const items = prodshops === undefined ? [PLACEHOLDER_PRODUCT] : prodshops;

	itemStyle = use.defaultStyle(itemStyle, localStyles.item);
	return (
		items &&
		<GalleryWithCenterArrow
			items={items}
			renderItem={item =>
				<Portal
					to={item !== PLACEHOLDER_PRODUCT && screens.product}
					params={{id: item?.product?.id}}>
					<ProductItem
						loading={item === PLACEHOLDER_PRODUCT}
						vProdshop={item}
						style={itemStyle}/>
				</Portal>
			}
			{...props}/>
	);
}

const PLACEHOLDER_PRODUCT = {};

const localStyles = {
	item: {
		alignSelf: styles.alignSelf.center,
		shadowOpacity: 0,
	}
};
