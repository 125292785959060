import React from "react";
import use from "../../hook";
import useAuth from "../../hook/useAuth";
import Server from "../../library-js/app/Server";
import CheckoutUI from "./CheckoutUI"
import { linking } from "./CheckoutUI/screens";
import strictAPI from "../../library-js/app/Server/strictAPI";

export default function OrderCheckoutUI(
	{
		orderId,
		oncePaymentFinished,
		secureCallbackURL,
		ifCannotSettle,
		...props
	}
) {
	const user = useAuth.user();
	const loadable = props.loadable =
		use.loadable.server(user &&
			(() => Server.user.order.getItems(orderId)),
			[orderId, user],
		);

	const vItems = loadable?.value;
	const vOrder = vItems?.first?.vOrder;

	const settleCheckLoadable = use.loadable(vItems && (() => orderIsOrCanBeSettled(vItems)), [vItems]);

	use.effect((check) => {
		if (check) {
			const [isSettled, canBeSettled] = check;
			if (isSettled || !canBeSettled)
				ifCannotSettle?.();
		}
	}, [settleCheckLoadable?.value]);

	async function checkout(checkoutParams) {
		const response = null;

		if (response.ok)
			oncePaymentFinished?.(response.content);
		else
			response.throw();
	}

	const { order } = vOrder || {};

	return (
		<CheckoutUI
			defaultReceptionMean={order?.receptionMean}
			defaultPaymentMean={order?.paymentMean}
			defaultContactInfos={order?.contactInfos}
			defaultInstructions={order?.instructions}
			{...{ loadable, checkout }}
			{...props}
		/>
	);
}

export const orderCheckoutLinking = {
	path: 'checkout/order',
	...linking,
};

export async function orderIsOrCanBeSettled(vItems) {
	const vOrder = vItems?.first?.vOrder;
	const settled = vOrder && !vOrder.currentState.type.is.canRetryPayment;

	let canBeSettled;
	if (settled === false) {
		const { shop } = vItems.first.vShop;
		const productIds = vItems.map(({ product }) => product.id);
		const vProdshops = await strictAPI(Server.public.prodshop.get)({ [shop.id]: productIds });
		canBeSettled = areItemsAvailable(vItems, vProdshops);
	}

	return [settled, canBeSettled];
}


export function areItemsAvailable(vItems, vProdshops) {
	const vOrder = vItems.first?.vOrder;
	const shopId = vOrder?.vShop.id;

	return vItems && vProdshops
		&& vOrder?.currentState.type.is.canRetryPayment
		&& vItems.every(vItem => {
			const productId = vItem.product.id;
			const vProdshop = vProdshops[shopId][productId];
			return vProdshop?.available
				&& vProdshop.finalPrice?.equalsTo(vItem.prodshop.finalPrice)
				&& (
					vItem.item.size ?
						vProdshop.product.sizes?.includes(vItem.item.size)
						: !vProdshop.product.sizes?.length
				);
		});
}
