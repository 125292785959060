import React from "react";
import View from "../../../component/View/v2";
import use from "../../../hook";
import Text from "../../../component/Text";
import {styles} from "../../../res"
import CheckoutStateContext from "../CheckoutUI/CheckoutStateContext";

function ContactDetails({...props}) {
	const {contactInfos, instructions} = use.context(CheckoutStateContext);
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<View {...props}>
			<Text style={localStyles.text}>
				{contactInfos?.lastName} {contactInfos?.firstName}
			</Text>
			{
				Boolean(contactInfos?.address) &&
				<Text style={localStyles.text}>
					{contactInfos?.address}
				</Text>
			}
			<Text style={localStyles.text}>
				{contactInfos?.phone}
			</Text>
			{
				Boolean(instructions) &&
				<>
					<Text style={localStyles.text}>
						{`Instructions pour votre commande`}
					</Text>

					<Text style={localStyles.text}>
						{instructions}
					</Text>
				</>
			}
		</View>
	)
}

export default React.memo(ContactDetails);

const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.column,
	},
	text: {
		color: '#000000',
		bold: true,
		marginBottom: 15,
	}
};
