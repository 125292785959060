import React from "react"
import {View as RNView} from "react-native"
import {styles} from "@main/res"
import use from "library-react/hook";

export default function AccountSectionDesktop({title, subtitle, children, ...props}) {
	const {Text} = use.theme();
	return (
		<RNView {...props}>
			<Text style={localStyles.title}>
				{title}
			</Text>

			<Text style={localStyles.subtitle}>
				{subtitle}
			</Text>

			{children}
		</RNView>
	);
}

const localStyles = {

	title: {
		fontSize: 19,
		color: styles.color.black,
		marginBottom: 22,
	},

	subtitle: {
		fontSize: 19,
		color: styles.color.black,
		bold: true,
		marginBottom: 25,
	},
};
