import TextMessage from "../../event/message/TextMessage";
import ProductMessage from "../../event/message/ProductMessage";
import OrderItemMessage from "../../event/message/OrderItemMessage";
import OrderNotification from "../../event/notification/OrderNotification";
import OrderItemNotification from "../../event/notification/OrderItemNotification";
import ReservationNotification from "../../event/notification/ReservationNotification";

export default function entryToEvent({key, value: data}){
	if (!data)
		return;

	data = {...data}; // copy, do not alter original
	const Class = Types[data.type];
	delete data.type;
	data.id = key;

	return Class?.from(data);
}


const Types = {
	0: TextMessage,
	1: ProductMessage,
	2: OrderItemMessage,
	3: OrderNotification,
	4: OrderItemNotification,
	5: ReservationNotification,
};
