import React from "react";
import {ScrollView as RNScrollView} from "react-native";
import use from "../hook"
import ScrollViewRecorder from "../plugin/ScrollViewRecorder";
import Functions from "../library-js/utils/Functions";

export default React.memo(
	React.forwardRef((
		{
			onStartReached,
			onStartReachedThreshold,
			onEndReached,
			onEndReachedThreshold,
			onSizesChanged,

			onLayout,
			onContentSizeChange,
			onScroll,

			...props
		},
		ref
	) => {
		props.ref = ref;

		const recorder = use.memo(() => new ScrollViewRecorder());

		// correct thresholds
		if (!(onStartReachedThreshold >= 0))
			onStartReachedThreshold = 0;
		if (!(onEndReachedThreshold >= 0))
			onEndReachedThreshold = 0;

		// set thresholds
		recorder.threshold.top = props.horizontal ? 0 : onStartReachedThreshold;
		recorder.threshold.bottom = props.horizontal ? 0 : onEndReachedThreshold;
		recorder.threshold.left = props.horizontal ? onStartReachedThreshold : 0;
		recorder.threshold.end = props.horizontal ? onEndReachedThreshold : 0;

		// set callbacks
		recorder.onLayout = onLayout;
		recorder.onContentSizeChange = onContentSizeChange;
		recorder.onScroll = Functions.append( // append onScroll for bounds listeners
			onScroll,
			(onStartReached || onEndReached) && (
				() => {
					const {reached} = recorder;

					const start = props.horizontal ? "left" : "top";
					if (onStartReached && reached[start])
						onStartReached();

					const end = props.horizontal ? "right" : "bottom";
					if (onEndReached && reached[end])
						onEndReached();
				}
			)
		);
		Object.assign(props, recorder.sensors);

		props.contentContainerStyle = use.defaultStyle(props.contentContainerStyle, {flexGrow: 0});
		if (!props.keyboardShouldPersistTaps)
			props.keyboardShouldPersistTaps = 'handled';

		return <RNScrollView {...props}/>;
	})
)
