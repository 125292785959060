import React from "react"
import use from "../../../library-react/hook"
import MobileUI from "./mobile";
import DesktopUI from "./desktop";
import {BasketManager} from "library-js/app/model/basket";
import useAuth from "library-react/hook/useAuth";
import globals from "@main/globals";
import {useFocusEffect, useNavigation} from "@react-navigation/native";
import SEO from "library-react/component/SEO";

function BasketUI({...props}) {
	const display = use.context.display();
	const infos = use.context.infos();
	const shopId = infos.vShop.id;
	const user = useAuth.user();

	const [version, upgrade] = use.version();
	const navigation = useNavigation();
	const upgradeOnFocus = use.callback(() => {
			if (navigation.isFocused())
				upgrade();
		},
		[navigation]
	);
	useFocusEffect(upgradeOnFocus);


	const loadable = props.loadable = use.loadable.server(
		user &&
		(() => BasketManager.getItemsIn(shopId, globals.NUMBER_BASKET_ITEMS)),
		[shopId, user, version]
	);

	const items = loadable?.value;
	const sum = items?.reduce((sum, item) => sum + item.finalPrice.value, 0);
	props.total = use.memo(
		sum => items?.first?.finalPrice.copy().setValue(sum),
		[sum, items],
	);

	const forceUpdate = use.forceUpdate();
	props.setQuantityOf = use.callback((vItem, quantity) => {
		if (!(quantity > 0))
			quantity = 1;

		if (vItem.item.quantity !== quantity) {
			vItem.item.quantity = quantity;
			forceUpdate();
			//TODO should debounce and send the last clicked quantity only
			BasketManager.persist(shopId, vItem.product.id, vItem.size, quantity);
		}
	});

	props.removeItem = use.callback(
		(item) => {
			const items = loadable.value;
			const index = items.indexOf(item);
			if (index >= 0) {
				items.splice(index, 1);
				loadable.value = items.slice();
				forceUpdate();

				BasketManager.remove(shopId, item.product.id, item.size);
			}
		},
		[loadable]
	);

	return (
		<>
			<SEO status={200}/>

			{
				display.is.mobile ?
					<MobileUI {...props}/> :
					<DesktopUI {...props}/>
			}
		</>
	);
}

export default React.memo(BasketUI);
