import React from "react"
import responsive from "@main/hoc/responsive";
import desktop from "./desktop.js"
import mobile from "./mobile.js"
import useAuth from "library-react/hook/useAuth";
import useApp from "library-react/app/useApp";
import ProfileUI from "@main/component/ProfileUI";
import use from "library-react/hook";

export default React.memo(
	responsive(
		{mobile, default: desktop},
		props => {
			props.user = useAuth.user();
			const app = useApp();
			props.updateInfos = use.callback(() => {
				const close = app.openModal(<ProfileUI onSuccess={() => close()}/>)
			});
		}
	)
);
