import React from "react"
import {View as RNView} from "react-native"
import use from "../../../../../hook"
import {fonts, styles} from "../../../../../res"
import {Enum} from "../../../../../library-js/class";
import Icon from "../../../../../component/Icon";
import Color from "../../../../../library-js/utils/Color";
import Text from "../../../../../component/Text";
import useAppWidthLevel from "../../../../../app/useAppWidthLevel";

function InputWrapper_render({label, validate, onInfosClicked, children, state, textError, ...props}) {
	const [isDesktop] = useAppWidthLevel([1024]);

	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<RNView {...props}>
			<RNView style={localStyles.title.layout(isDesktop)}>
				{
					Boolean(label) &&
					<Text style={localStyles.title.label(isDesktop)}>
						{label}
					</Text>
				}

				{
					!validate &&
					<Text style={localStyles.title.optional(isDesktop)}>
						{'Facultatif'}
					</Text>
				}

				{
					onInfosClicked &&
					<Icon
						name='information'
						style={localStyles.title.information}/>
				}
			</RNView>

			<RNView style={localStyles.content(state)}>
				{children}
			</RNView>

			{/* error message */}
			<Text style={localStyles.error}>
				{
					state?.is.error &&
					(textError || `Champs incorrect`)
				}
			</Text>
		</RNView>
	);
}

const InputWrapper = React.memo(InputWrapper_render);
export default InputWrapper;

InputWrapper.State = Enum.make(['focus', 'valid', 'blur', 'error']);

const localStyles = {
	layout: {},

	title: {
		layout: styles.static.bool(
			{
				flexDirection: styles.flexDirection.row,
				alignItems: styles.alignItems.center,
			},
			{
				marginBottom: 18,
			},
		),
		label: styles.static.bool(
			{
				font: fonts.Poppins,
				color: styles.color.secondary3,
			},
			{
				fontSize: 19,
			},
			{
				fontSize: 12,
				bold: true,
			}),
		optional: styles.static.bool(
			{
				font: fonts.Poppins,
				color: '#acb1c0',
				italic: true,
				marginLeft: 12,
			},
			{
				fontSize: 13,
			},
			{
				fontSize: 8,
			}
		),

		information: {
			marginHorizontal: 6,
		},
	},

	content: styles.static({
			borderBottomWidth: 1,
		},
		{
			valid: {
				borderColor: '#27ae60',
				backgroundColor: new Color('#27ae60').fade(1 - 0.04).toString(),
			},
			blur: {
				borderColor: '#d1d0da',
				backgroundColor: '#f7f7f7',
			},
			focus: {
				borderColor: '#5d5d5d',
				backgroundColor: '#f7f7f7',
			},
			error: {
				borderColor: '#e95454',
				backgroundColor: 'rgba(233,84,84,0.04)'
			},
		}),

	error: {
		marginTop: 2,
		marginLeft: 2.5,
		color: '#f42d55',
		fontSize: 10,
	}
};
