import React from "react"
import {
	OrderItemNotification,
	OrderNotification,
	ReservationNotification
} from "../../../../../../../library-js/app/model/chat";
import OrderNotificationView from "./OrderNotificationView";
import OrderItemNotificationView from "./OrderItemNotificationView";
import ReservationNotificationView from "./ReservationNotificationView";
import {__, always, find, ifElse, is, nth, pipe} from "ramda";

function NotificationView(props) {
	const notification = props.item;

	const Component = pipe(
		find(pipe(nth(0), is(__, notification))),
		ifElse(Boolean,
			nth(1),
			always(NO_RENDER),
		)
	)([
		[OrderNotification, OrderNotificationView],
		[OrderItemNotification, OrderItemNotificationView],
		[ReservationNotification, ReservationNotificationView],
	]);

	return <Component {...props} />;
}

function NO_RENDER(){
	return null;
}


export default React.memo(NotificationView);
