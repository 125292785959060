import Base from "../../../../class/Base"
import VProdshop from "../general/VProdshop"
import VShop from "../general/VShop"
import VUser from "../general/VUser"
import Basket from "../../entity/Basket"
import Price from "../../Price";

export default class VBasket extends Base {
	/**
	 * Give the the shop of the basket item
	 */
	get shop() {
		return this.vShop?.shop;
	}
}
VBasket.addProperties({
	vUser: VUser,
	vShop: VShop,
	numberOfItems: Number,
	lastUpdate: Number,
	total: Price,
});

VBasket.Item = class VBasketItem extends Base {
	get id(){
		return this.item?.id;
	}

	get quantity(){
		return this.item?.quantity;
	}

	get product(){
		return this.vProdshop?.product;
	}

	get vShop(){
		return this.vProdshop?.vShop;
	}

	get shop(){
		return this.vProdshop?.shop;
	}

	get prodshop(){
		return  this.vProdshop?.prodshop;
	}

	get finalPrice(){
		const price = this.unitPrice?.copy();
		if (price)
			price.value *= (this.quantity || 0);
		return price;
	}

	get oldPrice(){
		const price = this.oldUnitPrice?.copy();
		if (price)
			price.value *= (this.quantity || 0);
		return price;
	}

	get unitPrice(){
		return this.prodshop?.finalPrice;
	}

	get oldUnitPrice(){
		return this.prodshop?.oldPrice;
	}


	get size(){
		return this.item?.size;
	}

	/**
	 * Give the original price of the product
	 */
	get originalPrice() {
		return this.vProdshop ?
			this.vProdshop.prodshop.price :
			this.vSaleProduct && this.vSaleProduct.saleProduct.oldPrice;
	}

	/**
	 * return true if expired
	 */
	get expired() {
		return this.expire < Date.now();
	}
};
VBasket.Item.addProperties({
	item: Basket.Item,
	vProdshop: VProdshop,
	basket: VBasket,
});
