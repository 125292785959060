import React from "react"
import use from "library-react/hook"
import {styles} from "@main/res"
import View from "library-react/component/View";
import Icon from "library-react/component/Icon/v2";
import CommentList from "./CommentList";
import CommentForm from "./Form"

export default function CommentsModalMobile({title, subtitle, name, rateLoadable, myCommentLoadable, iterator, editMode, setEditMode, persist, close, ...props}) {
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<View {...props}>
			<View style={localStyles.header.layout}>
				<Icon
					name="EvilIcons/close"
					size={30}
					onPress={close}
					style={localStyles.header.close}
				/>
			</View>

			{
				editMode ?
					<CommentForm
						title={title}
						subtitle={subtitle}
						persist={persist}
						loadable={myCommentLoadable}
					/> :
					<CommentList
						iterator={iterator}
						rateLoadable={rateLoadable}
						contentContainerStyle={localStyles.list.container}
						setEditMode={setEditMode}
						style={localStyles.list.layout}
					/>
			}
		</View>
	);
}

const localStyles = {
	layout: {
		...styles.fit,
		backgroundColor: styles.color.white,
	},

	header: {
		layout: {
			alignItems: styles.alignItems.flexEnd,
			justifyContent: styles.justifyContent.center,
			height: 70
		},

		close: {
			color: 'grey',
			padding: 20,
		}
	},

	list: {
		layout: {
			paddingHorizontal: 20,
		},

		container: {
			paddingTop: 30,
		}
	}
};
