import Entity from "./Entity"

/**
 * https://docs.google.com/document/d/1Q6V9MqC98t4Qh5qAK3mx6SAly-ToDvF99DN-Q2TTAZs/edit#bookmark=id.d6a6hmf8gjqq
 */
export default class PaymentCard extends Entity {}
PaymentCard.addProperties({
	alias : String,
	expirationDate : String,
	provider : String,
	creationDate : String,
	name: String,
	address: String,
});
