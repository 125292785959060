import React from "react"
import {TouchableOpacity, Animated} from "react-native"

// layers
import pressable from "./pressable"
import hoverable from "./hoverable"
import focusable from "./focusable"
import runChildren from "./runChildren"
import Environment from "../../../library-js/Environment";
import useDefaultStyle from "../../../hook/useDefaultStyle";

function View({...props}, refProp) {
	props.ref = refProp;

	const data = {Component: TouchableOpacity};
	if (!(props.activeOpacity >= 0))
		props.activeOpacity = .8;

	[hoverable, pressable, focusable, runChildren] // layers
		.forEach(layer => layer(props, data));


	fixSelectionInWeb(props, data);
	return <data.Component {...props}/>;
}

export default Animated.createAnimatedComponent(React.forwardRef(View));



const fixSelectionInWeb = Environment.select({
	web(props, data) {
		props.style = useDefaultStyle(props.style,
			!data.pressable && {userSelect: 'auto'},
			[data.pressable]
		);
	},

	default() {
	}
});
