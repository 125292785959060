import useApp from "../../../../app/useApp";
import use from "../../../../hook";
import PaymentMean from "../../../../library-js/app/model/entity/Order/PaymentMean";
import ReceptionMean from "../../../../library-js/app/model/entity/Shop/ReceptionMean";
import getResourcesForPaymentMean from "../../../../library-js/res/getResourcesForPaymentMean";
import getResourcesForReceptionMean from "../../../../library-js/res/receptionMeans";
import { styles } from "../../../../res";
import ErrorText from "../../component/ErrorText";
import SelectButton from "../../component/SelectButton";
import CheckoutFrameContext from "../CheckoutFrameContext";
import CheckoutStateContext from "../CheckoutStateContext";
import checkoutScreens from "../screens";
import SelectOfflineMeanModal from "./SelectOfflineMeanModal";

export default function SelectPaymentMeanUI({ navigation, ...props }) {
	let {
		vShop, receptionMean,
		paymentMean, setPaymentMean,
	} = use.context(CheckoutStateContext);

	const offlineMeans = vShop.shop.paymentMeans.filter(mean => !mean.is.online);
	const offlineMean = (paymentMean && !paymentMean.is.online) ? paymentMean : offlineMeans.first;

	const [submitted, setSubmittedTo] = use.state.bool();

	const hasOnline = vShop.shop.paymentMeans.includes(PaymentMean.online);

	const submit = (selectedMean) => {
		setSubmittedTo(true);

		if (!selectedMean)
			selectedMean = paymentMean;

		if (selectedMean) {
			if (selectedMean !== paymentMean)
				setPaymentMean(selectedMean);

			setTimeout(() => navigation.push(checkoutScreens.contact));
		}
	};

	const app = useApp();
	const inviteUserToSelectOfflineMean = () => new Promise((resolve) =>
		app.openModal(
			<SelectOfflineMeanModal
				receptionMean={receptionMean}
				means={offlineMeans}
				onceSelected={resolve} />)
	).then(submit);

	const FrameControl = use.context(CheckoutFrameContext);
	props.style = use.defaultStyle(props.style, localStyles.layout);

	const { Text } = use.theme();
	return (
		<FrameControl
			step={2}
			title={
				<>
					{`MODES DE PAIEMENT `}
					<Text style={{ color: '#395aff' }}>
						{getResourcesForReceptionMean(receptionMean || ReceptionMean.collect).title().toUpperCase()}
					</Text>
				</>
			}
			submit={submit}>
			{
				<>
					<ErrorText display={!paymentMean && submitted}>
						{`Veuillez choisir un moyen de paiement`}
					</ErrorText>

					{
						hasOnline &&
						<SelectButton
							title={`Je paye en ligne`}
							selected={paymentMean?.is.online}
							icon="PaymentMean.online"
							onPress={() => submit(PaymentMean.online)}
							style={localStyles.selectButton} />
					}

					{
						(offlineMeans.length > 0) &&
						<SelectButton
							title={`Je paye ${ReceptionMean.select(receptionMean, {
								delivery: `à la livraison`,
								collect: `au point de vente`,
								default: `hors ligne`,
							})
								}`}
							subtitle={getResourcesForPaymentMean(offlineMean)?.title()}
							icon={
								ReceptionMean.select(receptionMean, {
									delivery: "ReceptionMean.delivery",
									default: "PaymentMean.offline"
								})
							}
							selected={paymentMean && !paymentMean.is.online}
							onPress={() => submit(offlineMean)}
							onActionClicked={inviteUserToSelectOfflineMean}
							style={localStyles.selectButton} />
					}
				</>
			}
		</FrameControl>
	);
}

const localStyles = {
	layout: {
		flex: 1,
	},

	scroll: {
		layout: {
			flex: 1,
		},

		content: {
			justifyContent: styles.justifyContent.spaceBetween,
			flexGrow: 1,
			paddingHorizontal: 15,
			maxWidth: 390,
			alignSelf: styles.alignSelf.center,
			width: '100%',
		},
	},

	stepPoint: {
		marginVertical: 15,
		alignSelf: styles.alignSelf.center,
	},

	title: {
		marginBottom: 30,
	},

	selectButton: {
		marginBottom: 17,
		minHeight: 196,
	},

	bottomBar: {
		marginBottom: 20,
		alignSelf: styles.alignSelf.center,
		maxWidth: 390,
		width: '100%',
	},
};
