import {Accessor} from "../../../../utils";

export default class SplitCacheAccessor extends Accessor {
	constructor(splitParameters = SplitCacheAccessor.default.SPLIT_PARAMETERS, getParametersOf = SplitCacheAccessor.default.GET_PARAMETERS_OF) {
		super({
			// split request into multiple request with only one id
			get(cache, request){
				// save request parameters & response's type
				let requestParameters = request.parameters;

				// split parameters into multiple requests'parameters in order to generate multiple cache keys.
				let splittedParameters = splitParameters(request.parameters);

				// generate multiple cache keys
				let requestKeys = splittedParameters.map(param => {
					request.parameters = param;
					return cache.requestToKey(request);
				});


				// get all results
				let results = cache.get(...requestKeys)
				// filter not cached results
					.filter(Boolean);

				// set back the original request's parameters & response's type
				request.parameters = requestParameters;

				// return a value only if all ids are found in the cache
				if (results.length === splittedParameters.length)
					return results;
			},

			set(cache, entries, timeout){
				entries = entries.flatMap(({request, content}) => {
					// save request parameters
					let requestParameters = request.parameters;

					let splittedEntry = content.filter(Boolean)
						.map(item => {
							request.parameters = getParametersOf(item);

							return {
								content : item,
								request : cache.requestToKey(request),
							};
						});

					// set back the original request's parameters
					request.parameters = requestParameters;

					return splittedEntry;
				});

				return cache.set(entries, timeout);
			}
		});
	}
}

SplitCacheAccessor.default = {
	SPLIT_PARAMETERS : ({ids}) => ids.map(id => ({ids: [id]})),
	GET_PARAMETERS_OF: ({id}) => ({ids: [id]}),
};
