import React from "react"
import {View as RNView} from "react-native"
import use from "library-react/hook"
import {styles} from "@main/res"
import Portal from "library-react/component/Portal";
import {screens} from "@main/links";

export default function BasketNotificationMobile({vProdshop, ...props}) {
	const {product} = vProdshop;

	const {Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<RNView {...props}>
			<Text
				numberOfLines={1}
				style={localStyles.title}>
				{product.title}
			</Text>

			<Text
				numberOfLines={1}
				style={localStyles.label}>
				{`cet article a été ajouté `}
				<Portal to={screens.basket}>
					<Text
						numberOfLines={1}
						style={localStyles.basket}>
						{`à votre panier.`}
					</Text>
				</Portal>
			</Text>
		</RNView>
	);
};


const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
		backgroundColor: styles.color.black,
		// ...styles.absolute({bottom: 0, left: 0, right: 0}),
		...styles.center,
		paddingVertical: 17,
		paddingHorizontal: 15,
	},

	title: {
		fontSize: 14,
		color: styles.color.white,
		bold: true,
		marginRight: 5,
		flex: 1,
	},

	label: {
		fontSize: 14,
		color: styles.color.white,
	},

	basket: {
		textDecorationLine: styles.textDecorationLine.underline,
	}
};
