import React from "react"
import use from "library-react/hook"
import {Animated, ScrollView, View as RNView} from "react-native"
import {styles} from "@main/res"
import View from "library-react/component/View/v2";
import Icon from "library-react/component/Icon/v2";
import StaticComponent from "library-react/component/StaticComponent";

function SizeSelectionModalDesktop({sizes, selected, onSelected, close, animatedValue, ...props}) {
	const {Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout, [animatedValue]);

	return (
		<Animated.View {...props}>
			<RNView style={localStyles.head}>
				<Text style={localStyles.title}>
					{`Taille`}
				</Text>

				<Icon
					name="EvilIcons/close"
					size={40}
					onVoidPress={close}
					style={localStyles.close}
				/>
			</RNView>


			<ScrollView
				contentContainerStyle={localStyles.scroll.content}
				style={localStyles.scroll.layout}>
				{sizes?.map(size =>
					<View
						key={size}
						onPress={() => onSelected(size)}
						style={localStyles.item}>
						<Text style={localStyles.size}>
							{size}
						</Text>

						{
							(selected === size) &&
							<SelectedIcon/>
						}
					</View>
				)}
			</ScrollView>
		</Animated.View>
	);
}

export default React.memo(SizeSelectionModalDesktop);

const SelectedIcon = new StaticComponent(
	<Icon
		name="Feather/check"
		size={21}/>
);


const localStyles = {
	layout: av => ({
		backgroundColor: styles.color.white,
		width: 682,
		...styles.absolute({top: 0, bottom: 0}),
		right: av.interpolate({
			inputRange: [0, 1],
			outputRange: [-682, 0],
		}),
		paddingLeft: 60,
		paddingRight: 40,
	}),

	head: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
		justifyContent: styles.justifyContent.spaceBetween,
		paddingVertical: 25,
	},

	title: {
		fontSize: 19,
		bold: true,
	},

	close: {
		padding: 10,
	},

	scroll: {
		content: {},
		layout: {}
	},

	item: {
		flexDirection: styles.flexDirection.row,
		justifyContent: styles.justifyContent.spaceBetween,
		alignItems: styles.alignItems.center,
		minHeight: 70,
		borderBottomWidth: 1,
		borderColor: styles.color.lightgrey,
	},

	size: {
		fontSize: 19,
	}
};
