import {Enum} from "../../../../class";


const PaymentMean = new Enum({
	online: "ONLINE",
	cash: "CASH",
	card: "CARD",
	check: "CHECK",
	restaurantTicket: "RESTAURANT_TICKET",
	transfert: "TRANSFERT",
	// cryptoCurrency: "CRYPTO_CURRENCY",
	else: "ELSE",
});

PaymentMean.domains.offline = PaymentMean.values.filter(value => !value.is.online);

PaymentMean.preference = [
	PaymentMean.online,
	PaymentMean.card,
	PaymentMean.cash,
	PaymentMean.check,
	PaymentMean.transfert,
	PaymentMean.else,
	// PaymentMean.restaurantTicket, // not usable for now
];


export default PaymentMean;

