import resolveUrl from "to-absolute-url";

const fonts = new Proxy({}, {
	set(target, key, value) {

		// -- add to css in DOM --
		styles[key]?.disconnect(); // clean
		const style = styles[key] = document.createElement('style');
		document.head.appendChild(style);

		style.innerHTML = '';

		function declareFont(fontRes, suffix) {
			const font = suffix ? `${key}-${suffix}` : key;

			if (target[key])
				target[key][suffix] = font;
			else
				target[key] = font;

			style.innerHTML += `@font-face {
				font-family: '${font}';
				src: url('${resolveUrl(fontRes, 1)}');
			}`;
		}

		if (value instanceof Object) {
			target[key] = {};
			Object.entries(value).forEach(([subKey, res]) => declareFont(res, subKey));
		} else
			declareFont(value);

		return true;
	},

	get(target, key) {
		return target[key];
	},
});

export default fonts;

const styles = {};
