import { pipe, defaultTo, slice, join } from 'ramda'

const MAX_TAGS = 3;
const toReadableShopTags = pipe(
	defaultTo([]),
	slice(0, MAX_TAGS),
	// map(prop('name')),
	join(' '),
);

export default toReadableShopTags;
