import {Animated} from "react-native";

const desktopToastConfig = {
	duration: 3 * 1000,

	openingAnimation: (av, toValue) => Animated.timing(av, {
		toValue,
		duration: 500,
		useNativeDriver: true,
	}),

	position: ({layouts, index}) => {
		const gap = 15;
		const previousLayout = layouts[index - 1];

		let startPosition = gap;
		if (previousLayout){
			const previousPosition = previousLayout.position || 0;
			const previousHeight = previousLayout.height;
			startPosition += previousPosition + previousHeight;
		}

		return startPosition;
	},
	positionAnimation: (av, toValue) => Animated.timing(av, {
		toValue,
		duration: 500,
		useNativeDriver: true,
	}),

	cardStyle: (openingAv, translateY) => ({
		position: 'absolute',
		right: 15,
		top: 0,
		width: 500,
		transform: [
			{translateY},
			{
				translateX: openingAv.interpolate({
					inputRange: [0, 1],
					outputRange: [500, 0],
				})
			},
		]
	}),
};

export default desktopToastConfig;
