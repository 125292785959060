import Entity from "./Entity";
import ReceptionMean from "./Shop/ReceptionMean";
import {map} from "ramda";

export default class ContactInfos extends Entity {
	validate(receptionMean){
		return this.getInvalidFields(receptionMean).length === 0;
	}

	get valid() {
		return this.validate();
	}

	getInvalidFields(receptionMean) {
		const invalidFields = Object.entries(ContactInfos.validator)
			.filter(([, validator]) => !validator.length) // remove validator that need arguments
			.filter(([field, getValidator]) => !getValidator().apply(this, [this[field], field]))
			.map(([field]) => field);

		// address required if reception mean is with delivery
		if (!ContactInfos.validator.address(receptionMean).apply(this, [this.address, 'address']))
			invalidFields.push('address');

		return invalidFields;
	}
}

ContactInfos.addProperties({
	firstName: String,
	lastName: String,
	phone: String,
	address: String,
});

ContactInfos.validator = {
	firstName: () => Boolean,
	lastName: () => Boolean,
	phone: () => Boolean,
	address: receptionMean => ReceptionMean.select(receptionMean, {
		delivery: Boolean,
		default: () => true,
	}),
};


/**
 * @deprecated
 */
ContactInfos.validate = map(getValidator => getValidator(), ContactInfos.validator);
