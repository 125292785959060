import Entity from "./Entity";
import OpenHour from "./Shop/OpenHour";

export default class ReservationSpan extends Entity { }
ReservationSpan.addProperties({
	name: String,
	numberOfPlaces: Number,
	openHours: {
		type: Array,
		template: OpenHour,
		get: function (hours) {
			if (!hours)
				this.openHours = hours = [];
			return hours;
		}
	}
});