import Media from "./Media"

export default class Audio extends Media {
	onReset(){
		super.onReset();
		this.duration = null;
	}

	get hasDatas() {
		return super.hasDatas
			// && Boolean(this.duration);
	}
}
Audio.TYPE = "audio";
Audio.addProperties({
	duration : Number,
});
