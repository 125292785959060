import React from "react"
import use from "library-react/hook";
import Comment from "library-js/app/model/Comment";
import {State} from "./constants";
import responsive from "@main/hoc/responsive";
import desktop from "./desktop"
import mobile from "./mobile"
import AuthenticateView from "@main/component/AuthenticateView";

export default React.memo(
	responsive(
		{default: desktop, mobile},
		({persist, ...props}) => {
			// --- data ---
			const [comment, updateComment, setComment] = use.legacyState(null);
			props.comment = comment;

			const [state, setState] =  use.state(State.idle);
			props.state = state;


			// --- actions ---
			props.update = {
				stars: stars => updateComment(comment => comment.stars = stars),
				text: text => updateComment(comment => comment.text = text),
			};

			const save = use.asyncCallback(shouldStop =>
				(promise, updateState) => {
					if (promise instanceof Promise) {
						updateState();
						promise.stopOn(shouldStop)
							// on success, parent component will remove the form
							.catch(error => {
								console.error(error);
								setState(State.error);
							});
					}
				}
			);

			props.submit = state.is.editable &&
				(() => {
					if (comment?.stars > 0)
						save(persist(comment), () => setState(State.saving));
				});
			props.remove = state.is.editable &&
				(() => save(persist(null), () => setState(State.deleting)));


			// --- effects ---
			use.subscribe(props.loadable?.onStateChanged, [props.loadable]);

			// set comment form once my comment loaded
			const {value: remoteVComment, end} = props.loadable || {};
			use.effect(() =>
					setComment(
						!end ? null :
							(remoteVComment?.comment?.copy() || new Comment)
					),
				[remoteVComment, end]
			);

			return props.loadable ? props : authenticate;
		}
	)
);

const authenticate = <AuthenticateView style={{flex: 1, margin: 50}} />;
