import React from "react"
import use from "../../../../../../hook";
import Text from "../../../../../../component/Text";
import moment from "moment";
import {fonts, styles} from "../../../../../../res";

export default React.memo(
	function TimeView({time, isAuthor, message, ...props}) {
		props.style = use.defaultStyle(props.style, localStyles.layout(isAuthor));
		return (
			<Text {...props}>
				{moment(time).format("HH[h]:mm")}
			</Text>
		);
	}
)

const localStyles = {
	layout:styles.static.bool({
		textAlign: styles.textAlign.right,
		fontSize: 9,
		font: fonts.Poppins,
	}, {
			color: styles.color.white,
		},
		{
			color: '#9a9999',
		}
	)
};
