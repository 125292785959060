import { Loadable } from "../library-js/utils";
import ShopCategoryTree from "../library-js/app/model/entity/Shop/ShopCategoryTree";
import use from "../hook";

export const loadable = new Loadable(() =>
	ShopCategoryTree.init()
		.then(() => ShopCategoryTree)
);

/**
 * @returns {[ShopCategoryTree, Loadable<ShopCategoryTree>]}
 */
export default function useShopCategories() {
	if (loadable.times === 0)
		loadable.load();

	use.subscribe(rerender => loadable.onStateChanged(rerender));
	return [loadable.value, loadable];
}
