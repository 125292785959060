import React from "react"
import { View as RNView } from "react-native"
import Text from "../../../../component/Text";
import use from "../../../../hook";
import Icon from "../../../../component/Icon/v2";
import moment from "moment";
import { fonts, styles } from "../../../../res";
import getResourcesForOrderState from "../../../../library-js/res/getResourcesForOrderState";
import Order from "../../../../library-js/app/model/entity/Order";

function OrderInfos({ state, orderId, creationDate, iconSize, iconStyle, textStyle, ...props }) {
	const display = (state instanceof Order.Item.State) ? getResourcesForOrderState.item(state) : getResourcesForOrderState(state);
	iconStyle = use.defaultStyle(iconStyle, localStyles.icon, [display]);
	textStyle = use.defaultStyle(textStyle, localStyles.text);

	return (
		<RNView {...props}>
			<Icon
				name={display?.icon()}
				size={iconSize || 11.7}
				style={iconStyle}
			/>

			{/* order id */}
			<Text style={textStyle}>
				{`Article Cde #`}
				{orderId}
			</Text>

			{/* order time */}
			{
				(creationDate > 0) &&
				<Text style={textStyle}>
					{moment(creationDate).format('DD/MM/YYYY')}
				</Text>
			}
		</RNView>
	);
}

export default OrderInfos;


const localStyles = {
	icon: display => ({
		backgroundColor: display?.color(),
		...styles.circle(19),
		color: styles.color.white,
		marginBottom: 6,
	}),

	text: {
		...styles.text(9, '#1e2432', fonts.Poppins),
	},
};
