import Image from "../../../component/Image/Image";
import { styles } from "../../../res";
import React from "react";
import use from "../../../hook";

export default ({ ...props }) => {
	props.style = use.defaultStyle(props.style, { height: 75, maxWidth: 250, alignSelf: styles.alignSelf.center, width: '100%' });
	return (
		<Image source={require("../res/secure-payment-certificate.png")}
			resizeMode="contain"
			{...props} />
	);
};
