import {Graphs, is, Objects} from "../../utils";
import Server from "../Server"
import Product from "../model/entity/Product";


// build graph
const allCategories = [/*String paths*/];
let filled = false;
export default class Category {
	static from(path){
		if (!path)
			return;

		if (is(path, Category))
			return path; // already a Category

		let splitted = path.split('>');
		let current = "";
		splitted.forEach((name, index) => {
			current += (index ? '>' : '') + name;

			if (!this[current]){
				allCategories.push(current);
				this[current] = new Category(current);
			}
		});

		return this[path];
	}

	static get children(){
		return allCategories.mapFiltering(
			path => this[path],
			path => !path.includes('>'),
		);
	}

	static setCounts(mapCategoryCount){
		Objects.forEach(mapCategoryCount, (path, count) => this.from(path).count = count);
		return this;
	}

	static get filled(){
		return filled;
	}

	static async fill(){
		let response = await Server.public.product.getAllCategories();
		if (response.ok)
			response.content.forEach(path => this.from(path));

		return (filled = response.ok);
	}

	/**
	 * TODO rename to getShopRoots on shop-web project.
	 * @returns {*|Array} Nodes which at least 2 of them have products, or an empty array. Must be use after {@link setCounts}.
	 */
	static getRoots(){
		// find the node which have at least 2 children with products
		let node = Graphs.find(
			this,
			node => node.children,
			node => {
				let numberOfChildrenWithProducts = 0;
				node.children.forEach(child => {
					if (child.count)
						numberOfChildrenWithProducts++;
				});

				return numberOfChildrenWithProducts > 1;
			}
		);

		return (node && node.children.filter(child => child.count > 0)) || [];
	}

	constructor(value){
		if (this.constructor[value]) // check if already exist
			return this.constructor[value];

		this.value = value;
	}

	get parent(){
		let path = this.path;
		if (path.length <= 1)
			return this.constructor;

		path.pop();
		return this.constructor[path.join(">")]
	}

	get children(){
		let Constructor = this.constructor;
		return allCategories.mapFiltering(
			path => Constructor[path],
				path =>  path.startsWith(this.value + '>')
					&& !path.slice(this.value.length + 1).includes('>') // remove grand-sons
		);
	}

	get path(){
		return this.value.split('>');
	}

	get pathOfNodes(){
		let node = this;
		let path = [];

		while (node.parent){
			path.unshift(node);
			node = node.parent;
		}

		return path;
	}

	get siblings(){
		return this.parent.children
			.filter(node => node !== this); // remove current node
	}

	get name(){
		return this.path.last;
	}

	isChildOf(node){
		if (!is(node, Category))
			return false;

		let path = this.pathOfNodes;
		path.pop(); // remove current node from path
		return path.includes(node);
	}

	isParentOf(node){
		if (!is(node, Category))
			return false;

		return node.isChildOf(this);
	}

	toJSON() {
		return this.value;
	}

	toString() {
		return this.value;
	}
}

Product.addProperties({category: Category});
