import React from "react"
import { View as RNView } from "react-native"
import use from "library-react/hook"
import { strings, styles } from "@main/res"
import ProductItem from "@main/component/ProductItem";
import ErrorView from "@main/component/ErrorView";
import Icon from "library-react/component/Icon/v2";
import Color from "library-js/utils/Color";
import Portal from "library-react/component/Portal";
import { screens } from "@main/links";
import { is } from "library-js/utils";


export default function CatalogSampleViewDesktop(
	{
		title, titleStyle,
		contentStyle, gap,
		iterator,
		content,
		loading,
		numberOfItemsLeft,
		portalProps,
		...props
	}
) {
	const { Text } = use.theme();
	titleStyle = use.defaultStyle(titleStyle, localStyles.title);

	props.style = use.defaultStyle(props.style, localStyles.layout);
	contentStyle = use.defaultStyle(contentStyle, localStyles.content);
	return (
		<RNView {...props}>
			<Portal {...portalProps}>
				<Text
					accessibilityRole="heading"
					accessibilityLevel={2}
					numberOfLines={1}
					style={titleStyle}>
					{title}
					{/*Force title height with invisible char*/ strings.invisibleChar}
				</Text>
			</Portal>

			<RNView style={contentStyle}>
				{
					content?.map((vProdshop, index) => {
						const lastDesign = (numberOfItemsLeft > 0) && index === MAX_ITEMS - 1 && !loading;
						return (
							<Portal
								key={index}
								to={lastDesign ? portalProps.to : vProdshop && screens.product}
								params={lastDesign ? portalProps.params : { id: vProdshop?.product.id }}>
								<ProductItem
									loading={loading}
									vProdshop={vProdshop}

									//TODO use.defaultStyles
									style={
										[
											localStyles.item(lastDesign),
											styles.if(is.defined(gap), { marginRight: gap }),
											styles.if(lastDesign, { marginRight: 0 })
										].flat()
									}

									renderInfos={
										lastDesign &&
										<RNView style={localStyles.toSection.layout}>
											<Text
												numberOfLines={1}
												style={localStyles.toSection.text}>
												{`VOIR PLUS `}
												<Text style={localStyles.toSection.count}>
													({numberOfItemsLeft + 1})
												</Text>
											</Text>

											<Icon
												name="AntDesign/arrowright"
												size={26}
												style={localStyles.toSection.icon}
											/>
										</RNView>
									}
								/>
							</Portal>
						);
					})
					|| <ErrorView retry={iterator?.load} />
				}

			</RNView>
		</RNView>
	);
}

const MAX_ITEMS = CatalogSampleViewDesktop.MAX_ITEMS = 5;

const localStyles = {
	layout: {
		backgroundColor: styles.color.white,
		paddingLeft: 0,
	},

	title: {
		alignSelf: styles.alignSelf.flexStart,
		// for loading
		minWidth: 200,
		borderRadius: 8,
		fontSize: 22,
		color: "#6f6f6f",
		bold: true,
		marginBottom: 40,
	},

	content: {
		flexDirection: styles.flexDirection.row,
	},

	item: styles.static.bool(
		{
			marginRight: 20,
		},
		{
			backgroundColor: new Color("#9a9999").fade(1 - .18).toString(),
		}
	),

	toSection: {
		layout: {
			...styles.fit,
			...styles.center,
			flexDirection: styles.flexDirection.row,
			alignItems: styles.alignItems.center,
			backgroundColor: styles.color.black,
		},

		text: {
			fontSize: 19,
			marginRight: 10,
			bold: true,
			color: styles.color.white,
		},

		count: {
			fontSize: 15,
		},

		icon: {
			color: styles.color.white,
		}
	}
};
