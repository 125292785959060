import React from "react"
import use from "library-react/hook";
import View from "library-react/component/View/v2";
import Icon from "library-react/component/Icon/v2";
import {styles} from "@main/res";

function DesktopCommentButton({...props}) {
	const {Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<View {...props}>
			<Icon name='edit' size={17} style={localStyles.icon}/>
			<Text style={localStyles.text}>
				{`RÉDIGEZ UN AVIS`}
			</Text>
		</View>
	);
}

export default React.memo(DesktopCommentButton);

const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
		justifyContent: styles.justifyContent.spaceBetween,
		backgroundColor: '#000000',
		paddingLeft: 18,
		paddingRight: 35,
		paddingVertical: 12,
	},
	text: {
		color: '#ffffff',
		fontSize: 13,
	},
	icon: {
		color: '#ffffff'
	},
};
