import forceUpdate from "./useForceUpdate"
import legacyState from "./useNewState"
import callback from "./useCallback"
import asyncCallback from "./useAsyncCallback"
import memo from "./useMemo"
import ref from "./useRef"
import setRef from "./setRef"
import element from "./useElement"
import effect from "./useEffect"
import syncEffect from "./useSyncEffect"
import defaultStyle from "./useDefaultStyle"
import interval from "./useInterval"
import loadable from "./useLoadable"
import createLoadable from "./useCreateLoadable"
import infiniteIterator from "./infiniteIterator"
import memorise from "./useMemorise"
import instances from "./useInstances"
import subscribe from "./useSubscribe"

import {useContext as context, useState as state} from "react"
import isMounted from "./useIsMounted";
import renderTime from "./useRenderTime";
import onChange from "./useOnChange"
import version from "./useVersion"
import useNavigation from "./useNavigation"
import useBooleanState from "./useBooleanState";
import theme from "./useTheme";
import component from "./useComponent"

const use = {
	forceUpdate,
	legacyState,
	callback,
	asyncCallback,
	memo,
	memorise,
	ref,
	setRef,
	element,
	effect,
	syncEffect,
	defaultStyle,
	interval,
	state,	// react's original
	booleanState: useBooleanState,
	context,
	loadable,
	createLoadable,
	infiniteIterator,
	isMounted,
	instances,
	renderTime,
	onChange,
	version,
	subscribe,
	theme,
	/**
	 * @deprecated
	 */
	newState: legacyState,
	navigation: useNavigation,
	component,
};

/**
 * @deprecated
 * Unable to declare the type.
 */
state.bool = useBooleanState;

export default use;
