import moment from "moment";
import { ScrollView, StyleSheet, View } from "react-native";
import OrderContactView from "../../../library-react/component/order/OrderContactView";
import OrderInstructionsView from "../../../library-react/component/order/OrderInstructionsView";
import OrderInfo from "../../../library-react/component/OrderInfo";
import use from "../../../library-react/hook";
import generateInvoiceURL from "../../../library-react/library-js/app/generateInvoiceURL";
import canBeSettled from "../../../library-react/library-js/app/model/utils/order/state/canBeSettled";
import { formatPrice } from "../../../library-react/library-js/app/model/utils/price";
import AddToBasketButton from "./AddToBasketButton";
import OrderItemView from "./OrderItemView";
import OrderOptions from "./OrderOptions";
import { ResponsiveUIProps } from "./ResponsiveUIProps";
import SettleButton from "./SettleButton";


export default function OrderUI_mobile({ order, addToBasket, total, currency, ...props }: ResponsiveUIProps) {
	const orderMoment = order && moment(order.creationDate);

	const { Text } = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<ScrollView
			{...props}
			contentContainerStyle={localStyles.container}>
			{
				order &&
				<>
					<View style={localStyles.header}>
						<Text style={localStyles.title}>
							{`CDE du ${orderMoment.format("DD MMM YYYY")}`}
							<Text style={localStyles.time}>
								{` ${orderMoment.format("HH[H]mm")}`}
							</Text>
						</Text>

						<OrderOptions
							pdfURL={generateInvoiceURL(order)}
							addToBasket={addToBasket} />
					</View>

					<OrderInfo
						order={order}
						displayOrderInfos={false}
						style={localStyles.state}
					/>

					{
						canBeSettled(order.currentState) &&
						<SettleButton
							orderId={order.id}
							style={localStyles.settleButton} />
					}
				</>
			}

			<View style={localStyles.list}>
				{
					order.items.map(item =>
						<OrderItemView
							key={item.id}
							item={item}
							style={localStyles.item} />
					)
				}
			</View>

			{/* total */}
			<View style={localStyles.summary}>
				<View style={localStyles.summaryIntern}>
					{/* Total */}
					<View style={localStyles.total}>
						<Text style={localStyles.totalLabel}>
							{`Total`}
						</Text>
						<Text style={localStyles.totalValue}>
							{formatPrice(total, currency)}
						</Text>
					</View>
				</View>
			</View>

			{/* Contact infos */}
			<OrderContactView
				order={order}
				style={localStyles.contact} />

			{
				Boolean(order?.instructions) &&
				<OrderInstructionsView>
					{order.instructions}
				</OrderInstructionsView>
			}

			{
				addToBasket &&
				<AddToBasketButton addToBasket={addToBasket} />
			}
		</ScrollView>
	);
}

const localStyles = StyleSheet.create({
	layout: {
		flex: 1,
	},

	container: {
		flexGrow: 1,
		paddingBottom: 80,
		paddingHorizontal: 20,
	},

	header: {
		flexDirection: "row",
		marginVertical: 14,
		justifyContent: "space-between",
		zIndex: 1,
	},

	title: {
		fontSize: 25,
		fontWeight: "bold",
	},

	time: {
		fontSize: 15,
	},

	state: {
		padding: 20,
		paddingBottom: 15,
		borderRadius: 11,
		marginBottom: 20,
	},

	settleButton: {
		alignSelf: "center",
	},

	list: {
		paddingTop: 40,
	},

	item: {
		marginBottom: 25, paddingHorizontal: 5,
	},

	summary: {
		backgroundColor: "#f7f7f7",
		padding: 10,
		marginBottom: 30,
		flexDirection: "row",
		justifyContent: "flex-end",
	},

	summaryIntern: {
		minWidth: 240,
	},

	total: {
		flexDirection: "row", justifyContent: "space-between",
	},

	totalLabel: {
		fontSize: 17, fontWeight: "bold",
	},

	totalValue: {
		fontSize: 19, fontWeight: "bold",
	},

	contact: {
		marginBottom: 30,
	},
});