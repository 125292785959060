import React from "react";
import View from "library-react/component/View/v2";
import Image from "library-react/component/Image";
import LoadingPlaceholder from "library-react/component/LoadingPlaceholder";
import fonts from "@main/res/fonts";
import use from "../../../library-react/hook";
import Text from "../../../library-react/component/Text/v2";
import {strings, styles} from "../../res";

export default function MobileUI({item: vShowcase, image, inPortal, ...props}) {
	const {Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<View {...props}>
			{
				inPortal(
					<LoadingPlaceholder disabled={vShowcase} style={localStyles.image}>
						<Image source={{uri: image?.url, width: 500}} style={styles.fit}/>
					</LoadingPlaceholder>
				)
			}

			{
				inPortal(
					<Text style={localStyles.title}>
						{vShowcase?.title}
					</Text>
				)
			}

			<LoadingPlaceholder disabled={vShowcase} style={localStyles.wrapper}>
				<Text style={localStyles.description}
					  numberOfLines={7}>{vShowcase?.description || strings.invisibleChar}</Text>
			</LoadingPlaceholder>
		</View>
	);
}

const localStyles = {
	layout: {},
	image: {
		height: 400,
	},
	wrapper: {
		borderRadius: 5,
		minWidth: 300,
	},
	title: {
		fontSize: 35,
		bold: true,
		font: fonts.BebasNeue,
		paddingTop: 12,
		marginBottom: 12,
	},
	description: {
		fontSize: 15,
		lineHeight: 21,
	},
};
