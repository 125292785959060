export default function getObjectIdOf(value) {
	if (value instanceof Object) {
		let id = weakMap.get(value);

		if (!id) {
			id = nextId++;
			weakMap.set(value, id);
		}

		return id;
	}
}

const weakMap = new WeakMap();
let nextId = 0;
