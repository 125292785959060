import "./version";
import App from './main/App';
import globals from "@main/globals";
import parse, { SourceModel } from "library-js/app/model/parse";
import { VShop } from "library-js/app/model/view";
import Debug from "library-js/Debug";
import ReactDOM from 'react-dom';
import "./global.css";
import ShopWebsiteConfiguration from "./library-react/library-js/app/ShopWebsiteConfiguration";

if (window.vShop) {
	globals.vShop = window.vShop = parse(SourceModel.server, VShop, window.vShop);
	window.websiteConfiguration = ShopWebsiteConfiguration.from(window.websiteConfiguration) || new ShopWebsiteConfiguration();
}

if (window.vShop?.shop?.dev)
	Debug.mode = Debug.Modes.development;

ReactDOM.render(
	<App
		vShop={window.vShop}
		config={window.websiteConfiguration} />,
	document.getElementById('root')
);



try {
	// Remove scroll bar for windows, or gallery
	let textStyle;
	if (navigator.platform === "Win32")
		textStyle = `*::-webkit-scrollbar {width: 0px}`;
	else
		textStyle = `*[data-class=noScrollBar]::-webkit-scrollbar { width: 0px }`;

	const style = document.createElement('style');
	style.appendChild(document.createTextNode(textStyle));
	document.head.appendChild(style);
} catch (error) {
	console.warn(error);
}