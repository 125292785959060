import Entity from "../Entity"
import Image from "../../media/Image"
import Location from "../../../../location/Location"
import Address from "../../Address"
import Functions from "../../../../utils/Functions"
import is from "../../../../utils/is"
import Objects from "../../../../utils/Objects"
import PaymentMean from "../Order/PaymentMean";
import OpenHour from "./OpenHour";
import Category from "./Category";
import ReceptionMean from "./ReceptionMean";
import simpleValidate from "../../../../utils/simpleValidate";
import equalContent from "../../../../utils/array/equalContent";
import Commercialization from "./Commercialization";
import { pipe, prop, T } from "ramda";
import retrieveInvalidFields from "../../../../utils/formValidator/retrieveInvalidFields";
import Platform from "../../../../Platform";
import Debug from "../../../../Debug";
import moment from "moment";
import toMoment from "../../../../utils/toMoment";
import OpenHoursUtils from "library-js/utils/OpenHoursUtils";


export default class Shop extends Entity.extendsWith({
	openHours: {
		type: Array,
		template: OpenHour
	}
}) {
	static from(shopJson, ...params) {
		if (!is(shopJson.activated))
			shopJson.activated = shopJson.production;

		if (!shopJson.commercialization)
			shopJson.commercialization = Commercialization.withOrders;

		return super.from(shopJson, ...params);
	}

	static get socialNetworks() {
		return ["facebook", "twitter", /*"google",*/ "instagram", "youtube"];
	}

	get mainPicture() {
		return this.gallery?.first;
	}

	set mainPicture(value) {
		this.gallery.first = value;
	}

	get weekOpenHours() {
		return OpenHoursUtils.weekOpenHours(this.openHours);
	}

	get todayOpenHours() {
		const day = new Date().getDay();
		return this.weekOpenHours[day];
	}

	isOpenedThisDay(timestamp) {
		if (timestamp) {
			const day = toMoment(this.timezone, timestamp).day();
			return this.isOpenAt(day);
		} else
			return false;
	}

	isOpenedAt(timestamp) {
		const momentValue = timestamp instanceof moment ? timestamp
			: moment(timestamp).tz(this.timezone);

		return this.isOpenAt(momentValue);
	}

	isOpenAt(day, hour, minute) {
		Debug.assert(!is.defined(day), 'Shop.isOpenAt must receive at least one parameter (week day or timestamp)');
		return OpenHoursUtils.isInOpenHours(this.openHours, this.timezone, day, hour, minute);
	}

	get opened() {
		const now = new Date();
		return this.isOpenAt(now.getDay(), now.getHours(), now.getMinutes());
	}

	get nextSwitch() {
		const distance = Date.DAY;
		const today = new Date();
		const time = today.getTime();
		const currentDay = today.getDay();

		if (this.openHours?.length) {
			let open = false;
			let next = null;

			this.openHours.forEach(({ day, openHour, openMinute, closeHour, closeMinute }) => {
				const addDay = ((day.value - currentDay) % 7 + 7) % 7; //not negative modulo

				const openDate = new Date(time + addDay * distance);
				openDate.setHours(openHour, openMinute, 0, 0);
				if (today < openDate && (!next || openDate < next)) {
					open = false;
					next = openDate;
				}

				const closedDate = new Date(time + addDay * distance);
				closedDate.setHours(closeHour, closeMinute, 0, 0);
				if (today < closedDate && (!next || closedDate < next)) {
					open = true;
					next = closedDate;
				}
			});

			next = next.getTime();

			return { open, next };
		}
	}

	get hiddenPrice() {
		return this.commercialization?.is.withoutPrices;
	}

	/**
	 * @deprecated use timezone
	 */
	get timeZone() {
		console.warn('Shop.timeZone is deprecated. Use Shop.timezone');
		return this.timezone;
	}

	get orderPermanentlyDisabled() {
		return Boolean(this.commercialization?.is.withoutPrices ||
			(!this.ordersEnabled && !this.unavailableNote)
		);
	}

	get ordersEnabled() {
		return Boolean(
			this.paymentMeans?.length > 0
			&& this.commercialization?.is.withOrders
		);
	}

	get ordersOpened() {
		return this.ordersAlwaysOpened || this.opened;
	}

	get ordersAvailable() {
		return this.ordersEnabled && this.ordersOpened;
	}

	getThirdState() {
		const nextSwitch = this.nextSwitch;

		const distance = Date.DAY;
		const today = new Date(nextSwitch.next + 1);
		const time = today.getTime();
		const currentDay = today.getDay();

		if (this.openHours[0]) {
			let open = false;
			let next = null;

			this.openHours.forEach(({ day, openHour, openMinute, closeHour, closeMinute }) => {
				const addDay = ((day.value - currentDay) % 7 + 7) % 7; //not negative modulo

				const openDate = new Date(time + addDay * distance);
				openDate.setHours(openHour, openMinute, 0, 0);
				if (today < openDate && (!next || openDate < next)) {
					open = false;
					next = openDate;
				}

				const closedDate = new Date(time + addDay * distance);
				closedDate.setHours(closeHour, closeMinute, 0, 0);
				if (today < closedDate && (!next || closedDate < next)) {
					open = true;
					next = closedDate;
				}
			});

			next = next.getTime();

			return { open, next };
		}
	}

	getOpenHours() {
		return super.getOpenHours() || [];
	}

	get socialLinks() {
		const shopNetworks = Shop.socialNetworks.toObject(key => key, network => this[network]);
		Objects.forEach(shopNetworks, (key, value) => {
			if (!Boolean(value))
				delete shopNetworks[key];
		});
		return shopNetworks;
	}

	getInvalidFields() {
		return retrieveInvalidFields(this.constructor.validator, this);
	}

	equalsTo(otherShop) {
		return super.equalsTo(otherShop, {
			paymentMeans: equalContent,
			receptionMeans: equalContent,
		});
	}
}

Shop.OpenHour = OpenHour;
Shop.Category = Category;
Shop.Commercialization = Commercialization;
Shop.ReceptionMean = ReceptionMean;

Shop.addProperties({
	name: String,
	logo: Image,
	description: String,
	gallery: {
		type: Array,
		template: Image
	},
	phone: String,
	email: String,

	domain: String,
	url: String,
	map: String,

	address: Address,
	location: Location,

	categoryId: Number,
	production: {
		type: Boolean,
		get: function () {
			return this.activated;
		}
	},

	timezone: String,
	emailVerified: Number,
	verifiedByMarketer: Number,

	requiredAge: Number,

	commercialization: Commercialization,

	ordersAlwaysOpened: Boolean,
	paymentMeans: {
		type: Array,
		template: PaymentMean,
	},
	unavailableNote: String,

	receptionMeans: {
		type: Array,
		template: ReceptionMean,
	},

	acceptsReservations: Boolean,

	checkoutNote: String,
	activated: Boolean,
	dev: Boolean,
	creationDate: Number,

	google: { // TODO remove this field when this task is resolved: https://app.clickup.com/t/6byme7
		type: null,
		get: () => null,
	},
});

Shop.addProperties(Shop.socialNetworks.toObject(key => key, () => String));
Shop.validator = {
	name: () => simpleValidate.text,
	logo: () => pipe(prop('url'), simpleValidate.text),
	gallery: () => gallery => gallery?.length > 0,
	email: () => simpleValidate.text,
	phone: () => simpleValidate.text,

	address: () => address => Boolean(address && !address.getInvalidFields().length),

	location: () => location => Boolean(location?.isValid()),

	categoryId: () => Platform.is.marketer ? Boolean : T,
	receptionMeans: () =>
		Platform.is.marketer ? T :
			value => {
				if (value?.length)
					return true
			},
};
