import React from "react"
import { View as RNView } from "react-native"
import use from "library-react/hook";
import MobileUI from "./MobileUI";
import DesktopUI from "./DesktopUI";
import Server from "../../../library-react/library-js/app/Server";
import Search from "../../../library-react/library-js/Search";
import Spinner from "../../component/Spinner";
import { useRoute } from "@react-navigation/native";
import { styles } from "../../res";
import ErrorView from "../../component/ErrorView";
import Brand from "../../../library-react/library-js/app/model/entity/Brand";
import SEO, {
	iteratorToStatus,
	loadableToStatus,
	mergeStatus,
	optionalLoadableToStatus
} from "library-react/component/SEO";
import { paths } from "@main/links";
import pageTitle from "@main/utils/pageTitle";
import toReadableTags from "@main/utils/toReadableShopTags";
import { useStaticShopCategoryLoadable } from "@main/hook/useStaticShopCategory";
import { useStaticShopTagsNamesLoadable } from "@main/hook/useStaticShopTags";
import buildSeoParams from "library-js/utils/buildSeoParams";

export default function BrandUI({ ...props }) {

	const route = useRoute();
	const name = Brand.parseName(route.params?.name);

	const { value: brand, loading, load } = use.loadable.server(
		() => Server.public.brand.get(name).updateContent(map => map[name]),
		[name]
	);
	props.brand = brand;

	const { vShop } = use.context.infos();
	const iterator = props.iterator = use.infiniteIterator((shopId, brand) =>
		Search.index.prodshop.getIterator({
			shopId,
			brand,
			available: true
		}),
		[vShop.shop.id, name]
	);

	const shop = vShop?.shop;
	const categoryLoadable = useStaticShopCategoryLoadable();
	const category = categoryLoadable.value;
	const shopTagsNamesLoadable = useStaticShopTagsNamesLoadable();
	const shopTagsNames = shopTagsNamesLoadable.value;

	const display = use.context.display();
	return (
		<>
			<SEO
				status={mergeStatus([
					iteratorToStatus(iterator, true),
					optionalLoadableToStatus(categoryLoadable),
					optionalLoadableToStatus(shopTagsNamesLoadable),
				])}
				image={brand?.logo || vShop.logo}
				canonical={`${paths.brand}/${name}`}
				optimizationParams={
					buildSeoParams({
						keywords: [
							brand?.name,
							category?.name,
							shopTagsNames,
							shop.address.city
						]
					})
				}
				title={pageTitle([
					brand?.name,
					category?.name,
					toReadableTags(shopTagsNames),
					shop?.address.locality,
				])}
				description={
					`Retrouvez tous nos articles ${brand?.name || name}. `
					+ (brand?.description || brand?.slogan || '')
				}
			/>

			{
				brand ? (
					display.is.mobile ?
						<MobileUI {...props} /> :
						<DesktopUI {...props} />
				) :
					<RNView style={[styles.center, { flex: 1 }]}>
						{
							loading ? <Spinner /> :
								<ErrorView retry={load} />
						}
					</RNView>
			}
		</>
	);
}
