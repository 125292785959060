import React from "react"
import {View as RNView} from "react-native"
import {strings, styles} from "@main/res"
import ProductItem from "@main/component/ProductItem";
import Portal from "library-react/component/Portal";
import {screens} from "@main/links";
import use from "library-react/hook";
import Icon from "library-react/component/Icon/v2";


export default function CatalogSampleViewMobile({title, titleStyle, gap, seeMoreOnLastProduct, iterator, numberOfItemsLeft, content, loading, portalProps, noCards, ...props}) {
	const {Text} = use.theme();

	titleStyle = use.defaultStyle(titleStyle, localStyles.title);
	const firstItemStyle = localStyles.item;
	const itemStyle = use.defaultStyle(localStyles.item, gap && {marginLeft: gap}, [gap]);

	return (
		<RNView {...props}>
			<Portal {...portalProps}>
				<Text
					numberOfLines={1}
					style={titleStyle}>
					{title}
					{/*Force title height with invisible char*/ strings.invisibleChar}
				</Text>
			</Portal>


			<RNView style={localStyles.content}>
				{
					content.slice(0, MAX_ITEMS)
						.map((vProdshop, index, {lastIndex}) => {
								const seeMoreOnProduct = seeMoreOnLastProduct && numberOfItemsLeft > 0 && (index === lastIndex);
								return (
									<Portal
										key={index}
										to={seeMoreOnProduct ? portalProps.to : vProdshop && screens.product}
										params={seeMoreOnProduct ? portalProps.params : {id: vProdshop?.product.id}}>
										<ProductItem
											loading={loading}
											vProdshop={vProdshop}
											noCard={noCards}
											renderInfos={
												seeMoreOnProduct &&
												<RNView style={localStyles.toSectionFromLastProduct.layout}>
													<Text
														numberOfLines={1}
														style={localStyles.toSectionFromLastProduct.text}>
														{`VOIR PLUS `}
														<Text style={localStyles.toSectionFromLastProduct.count}>
															({numberOfItemsLeft + 1})
														</Text>
													</Text>

													<Icon
														name="AntDesign/arrowright"
														size={26}
														style={localStyles.toSectionFromLastProduct.icon}
													/>
												</RNView>
											}
											style={!index ? firstItemStyle : itemStyle}
										/>
									</Portal>
								);
							}
						)
				}
			</RNView>

			{
				!seeMoreOnLastProduct &&
				(numberOfItemsLeft > 0) &&
				<Portal {...portalProps}>
					<Text
						numberOfLines={1}
						style={localStyles.toSection}>
						{`VOIR PLUS${numberOfItemsLeft ? ` (${numberOfItemsLeft})` : ""}`}
					</Text>
				</Portal>
			}
		</RNView>
	);
}

const MAX_ITEMS = CatalogSampleViewMobile.MAX_ITEMS = 4;

const localStyles = {
	layout: {},

	title: {
		fontSize: 16,
		paddingBottom: 8.5,
		borderBottomWidth: 1,
		borderBottomColor: styles.color.lightgrey,
		fontWeight: styles.fontWeight.bold,
		marginBottom: 16,
	},

	content: {
		flexDirection: styles.flexDirection.row,
		flexWrap: styles.flexWrap.wrap,
		justifyContent: styles.justifyContent.spaceBetween,
	},

	item: {
		marginBottom: 16,
	},

	toSection: {
		fontSize: 13,
		textAlign: styles.textAlign.center,
		backgroundColor: "#e4e4e4",
		paddingVertical: 12,
		bold: true,
	},
	toSectionFromLastProduct: {
		layout: {
			...styles.fit,
			...styles.center,
			flexDirection: styles.flexDirection.row,
			alignItems: styles.alignItems.center,
			backgroundColor: styles.color.black,
		},

		text: {
			fontSize: 16,
			marginRight: 10,
			bold: true,
			color: styles.color.white,
		},

		count: {
			fontSize: 15,
		},

		icon: {
			color: styles.color.white,
		}
	}
};
