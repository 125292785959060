import React from 'react'
import createGallery from "./createGallery";
import View from "../View";
import use from "../../hook";
import Image from "../Image";
import Icon from "../Icon/v2";
import adaptSize from "../../../main/adaptSize";
import styles from "../../../main/res/styles";
import useApp from "library-react/app/useApp";
import GalleryModal from "library-react/component/GalleryModal";

export default React.memo(
	createGallery(
		function GalleryWithCornerArrow({ resizeMode, innerStyles, enableFullScreen, onPress, alt, ...props }, {
			items,
			selected,
			next,
			back
		}) {

			const app = useApp();
			const openFullscreenGallery = (index) => app.openModal(<GalleryModal items={items} startIndex={index} />);

			props.onPress = () => {
				if (onPress)
					onPress();

				if (enableFullScreen)
					openFullscreenGallery(selected);
			}

			props.style = use.defaultStyle(props.style, localStyles.layout);
			const mainStyle = use.defaultStyle(innerStyles?.main, localStyles.main);

			return (
				<View {...props}>
					<Image
						source={items[selected]}
						resizeMode={resizeMode}
						alt={alt}
						style={mainStyle} />
					<View style={localStyles.arrow.layout}>
						<Icon onPress={next}
							name='rightArrow'
							size={adaptSize(20)}
							style={[localStyles.arrow.item, { backgroundColor: '#000000' }]} />
						<Icon onPress={back}
							name='leftArrow'
							size={adaptSize(20)}
							style={localStyles.arrow.item} />
					</View>
				</View>
			);
		}
	)
)
const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
	},

	main: {
		flex: 1,
	},
	arrow: {
		layout: {
			position: styles.position.absolute,
			flexDirection: styles.flexDirection.column,
			bottom: 0,
			right: 0,
		},
		item: {
			width: 50,
			height: 50,
			color: styles.color.white,
			backgroundColor: '#363636'
		}
	}
};