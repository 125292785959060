import Text from "../../../component/Text";
import React from "react";
import { View as RNView } from "react-native";
import getResourcesForPaymentMean from "../../../library-js/res/getResourcesForPaymentMean";
import use from "../../../hook";
import CheckoutStateContext from "../CheckoutUI/CheckoutStateContext";

export default function PaymentInfos({ ...props }) {
	const { paymentMean } = use.context(CheckoutStateContext);

	const resources = use.memo(
		() => getResourcesForPaymentMean(paymentMean),
		[paymentMean]
	);

	return (
		<RNView {...props}>
			{
				<Text style={localStyles.text}>
					{resources.title()}
				</Text>
			}
		</RNView>
	)
}
const localStyles = {
	text: {
		fontSize: 16,
		color: '#1a1824',
	}
}
