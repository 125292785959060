import {styles} from "@main/res"

// ----- columns -------
const columnsStyles = [
	 // Date && ID
	{
		maxWidth: 200,
		marginLeft: 20,
		justifyContent: styles.justifyContent.center,
		flexShrink: 3,
	},
	// State
	{
		justifyContent: styles.justifyContent.center,
		paddingRight: 10,
		alignItems: styles.alignItems.center,
	},
	// Quantity
	{
		maxWidth: 90,
		alignItems: styles.alignItems.center,
		justifyContent: styles.justifyContent.center,
		flexShrink: 3,
	},

];



export default {
	row: {
		paddingVertical: 15,
		borderColor: "#f7f7f7",
		borderBottomColor: styles.color.background,
		borderLeftWidth: 5,
	},

	columns: columnsStyles,
};
