import React from "react";
import {View as RNView} from "react-native";
import {styles, images} from "@main/res"
import Image from "library-react/component/Image";
import Text from "library-react/component/Text/v2";
import use from "library-react/hook";

export default function RegionSupport({...props}) {
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<RNView {...props}>
			<Text style={localStyles.text}>
				{`Site internet réalisé avec le soutien de la Région Sud Provence-Alpes-Côte d'Azur`}
			</Text>
			<Image
				source={images.supportedByRegion}
				resizeMode="contain"
				style={localStyles.image}
			/>
		</RNView>
	);
}

const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.column,
		alignItems: styles.alignItems.center,
	},
	text: {},
	image:{
		width: 200,
		height: 60,
	},
}