import React from "react"
import moment from "moment";
import use from "../../../../../../../../hook";
import NotificationLayout from "../NotificationLayout";
import OrderView from "../../../../../../component/OrderView/OrderView";
import TimeView from "../../TimeView";
import {styles} from "../../../../../../../../res";

export default React.memo(
	function OrderItemNotificationView({item: orderItemNotification, onOrderItemClicked, ...props}) {
		const {vOrderItem} = orderItemNotification;
		const image = vOrderItem.product.gallery.first;
		const title = use.memo(
			() => `Article de Cde du ${moment(vOrderItem.creationDate).format('DD/MM/YYYY')}`,
			[vOrderItem.creationDate]
		);

		return (
			<NotificationLayout title={title} {...props}>
				<OrderView
					gallery={image}
					price={vOrderItem.price}
					state={vOrderItem.state}
					receptionMean={vOrderItem.receptionMean}
					onPress={onOrderItemClicked && (() => onOrderItemClicked(vOrderItem.item.orderId, vOrderItem))}>

					<TimeView
						time={orderItemNotification.time}
						style={localStyles.time}
					/>
				</OrderView>
			</NotificationLayout>
		);
	}
);

const localStyles = {
	time: {
		alignSelf: styles.alignSelf.center,
	}
};
