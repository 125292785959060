import Debug from "../../../Debug";
import {Cache, is, Objects} from "../../../utils";
import AuthManager from "../../../AuthManager";

const version = 0; // ! change version here each time you make a critical changes in loading/storing data algorithm
let cache; // instance of Cache (instantiated in init method)

const TIME = {
	SHORT: 5 * Date.MINUTE,
	DEFAULT: 20 * Date.MINUTE,
	LONG: Date.HOUR,
	MAX: Date.DAY,
};

export default {
	TIME,

	// enabled: true,
	get enabled() {
		return false;
	},
	set enabled(enabled) {
		// cannot be enabled
	},

	init(mapStorage) {
		Debug.assert(!mapStorage, "Server cache must be initiated with a map storage.", true);

		cache = new Cache(mapStorage, {
			version,
			defaultTime: TIME.DEFAULT,
			maxTime: TIME.MAX,
		});

		AuthManager
			.onUserChanged(user => {
				if (!user)
					this.clear();
			});

		return this.initiating = cache.initiating;
	},

	get(...requests) {
		if (!this.enabled) // check if enabled
			return new Array(requests.length);

		Debug.assert(!cache, "Server cache has not been initiated.", fallbackInProduction);

		let keys = requests.map(requestToStringKey);

		let results = cache.get(...keys);

		return keys.map(key => results[key]);
	},

	getAll(domain, path, method) {
		if (!this.enabled) // check if enabled
			return [];

		Debug.assert(!cache, "Server cache has not been initiated.", fallbackInProduction);

		let keyPattern = requestToStringKey({domain, path, method});
		let keys = cache.keys.filter(key => key.startsWith(keyPattern));
		let results = cache.get(...keys);
		return keys.map(key => ({
			request: stringKeyToRequest(key),
			content: results[key],
		}));
	},

	/**
	 * @param entries A list of sets of `{request, content}`
	 * @param timeout Timeout for entries.
	 * when a request is an instance of {@link Request}, or can be just a set of `{domain, path, method[, parameters]},
	 * the content is the **serializable** data to store,
	 * and the optionally timeout is the time`(in millis) to store the data content.
	 * The requests are the keys to retrieve their corresponding contents later on.
	 * Entries with null/undefined content are meant to be deleted.
	 */
	set(entries, timeout) {
		if (!this.enabled) // check if enabled
			return Promise.resolve();

		entries = entries.toObject(({request}) => requestToStringKey(request), ({content}) => content);

		// entries with null or undefined content will be deleted
		let entriesToKeep = Objects.groupBy(entries,
			(key, entry) => Boolean(is(entry)),
			[true, false]
		);

		return Promise.all([
			cache.remove(...Object.keys(entriesToKeep.false)),
			cache.set(entriesToKeep.true, timeout),
		]);
	},

	clear(domain, path, method, parameters) {
		Debug.assert(!cache, "Server cache has not been initiated.", fallbackInProduction);

		if (domain) {
			let keysToRemove = cache.keys.filter(key => {
				let keyPattern = requestToStringKey({domain, path, method, parameters});
				return key.startsWith(keyPattern);
			});

			return cache.remove(...keysToRemove);
		}

		return cache.clear()
			.then(() => console.log("Cache empty"));
	},

	get length() {
		return cache.keys.length;
	},

	requestToKey(request) {
		if (!request) return;

		let {domain, path, method, parameters, responseType} = request;
		return {domain, path, method, parameters: {...parameters}, responseType};
	},
}

function requestToStringKey(request) {
	if (!request) return;

	let {domain, path, method, parameters} = request;
	let key = "";

	if (domain) {
		key += domain;

		if (path) {
			key += " " + path;

			if (method) {
				key += " " + method;

				if (parameters)
					key += " " + parametersToKey(parameters);
			}
		}
	}

	return key;
}

function stringKeyToRequest(key) {
	let [domain, path, method, parameters] = key.split(' ');
	return {domain, path, method, parameters};
}

function parametersToKey(parameters) {
	return is(parameters, String, true) ? parameters : JSON.stringify(parameters);
}

/**
 * Fallback in production if cache hasn't been initiated.
 */
function fallbackInProduction() {
	cache = new Cache(null, {defaultTime: TIME.DEFAULT});
}
