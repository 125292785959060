import React from "react"
import {ActivityIndicator, View as RNView} from "react-native";
import use from "library-react/hook";
import {styles} from "@main/res";
import ScrollView from "../../../library-react/component/ScrollView";
import ShowcaseItem from "../../component/ShowcaseItem";

export default function MobileUI({loadable, onSelected, ...props}) {
	use.subscribe(loadable?.onStateChanged, [loadable]);

	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<ScrollView contentContainerStyle={localStyles.content}
					{...props}>
			<RNView style={localStyles.items}>
				{
					loadable.value?.map(vShowcaseShop =>
						<ShowcaseItem key={vShowcaseShop.vShowcase.id}
									  item={vShowcaseShop.vShowcase}
									  portrait
									  style={localStyles.item}/>
					)
				}
			</RNView>
			{loadable.loading && <ActivityIndicator style={localStyles.loading}/>}
		</ScrollView>
	);
}

const localStyles = {
	layout: {},
	content: {
		paddingHorizontal: 52,
		paddingBottom: 26,
	},

	items: {
		flexDirection: styles.flexDirection.column,
	},

	item: {
		marginBottom: 42,
	},

	loading: {
		padding: 50,
	},

}
