import IMemoryMapStorage from "./IMapStorage"
import Objects from "../Objects";

/**
 * Simple implementation of {@link IMapStorage} using memory.
 * Useful as a fallback when no map storage provided for your modules.
 */
export default class MemoryMapStorage extends IMemoryMapStorage {
	constructor() {
		super();
		this._memory = {};
	}

	equals(storage) {
		// each MemoryMapStorage instance has its own memory
		return this === storage;
	}

	/**
	 * @override
	 */
	async set(map){
		let copies = {};
		let errors = {};

		Objects.forEach(map, (key, value) => {
			try {// try to clone each value
				copies[key] = JSON.stringify(value);
			}
			catch (error) {
				errors[key] = error;
			}
		});

		// set copied values
		Object.assign(this._memory, copies);

		return errors;
	}

	/**
	 * @override
	 */
	async get(...keys){
		return keys.toObject(
			key => key,
			key => {
				try {
					let value = this._memory[key] && JSON.parse(this._memory[key]);
					return {value};
				}
				catch (error) {
					return {error};
				}
			}
		);
	}

	/**
	 * @override
	 */
	async remove(...keys){
		keys.forEach(key => {delete this._memory[key]});
		return {/*no errors deleting*/};
	}

	/**
	 * @override
	 */
	async getKeys(){
		return Object.keys(this._memory);
	}
}
