import React from "react";
import View from "library-react/component/View/v2";
import Environment from "library-js/Environment";
import Portal from "library-react/component/Portal";
import useRouteForFrame from "@main/App/Frame/useRoute";
import use from "library-react/hook";
import fonts from "@main/res/fonts";
import {styles} from "@main/res";
import Icon from "library-react/component/Icon/v2";
import doesThisRouteTargetThisScreen from "../doesThisRouteTargetThisScreen"

export default function MenuItem({title, screen, params, subItems, shouldBeOpened, onPress}) {
	const route = useRouteForFrame();

	const isSelected = doesThisRouteTargetThisScreen(route, screen, params);
	if (isSelected)
		screen = null;

	const displaySubItems = Boolean(
		/* let crawlers find links */ Environment.crawling ||
		(route && (
			(shouldBeOpened instanceof Function) ?
				shouldBeOpened(route, screen, params)
				: shouldBeOpened
		))
	);

	return (
		<View style={localStyles.layout}>
			<Portal
				to={screen}
				params={params}>
				<MenuOption
					title={title}
					selected={isSelected}
					hasSubOptions={subItems?.length > 0}
					onPress={onPress}
				/>
			</Portal>

			{
				(displaySubItems) &&
				subItems?.map(({title, screen, params, navigate}, index) =>
					<Portal
						key={index}
						to={screen}
						params={params}>
						<MenuOption
							title={title}
							selected={doesThisRouteTargetThisScreen(route, screen, params)}
							isSubOption
						/>
					</Portal>
				)
			}
		</View>
	);
}

function MenuOption({title, isSubOption, selected, hasSubOptions, ...props}) {
	const {Text} = use.theme();
	const [hovered, setHovered] = use.state.bool();

	props.style = localStyles.option.layout(isSubOption, hovered);
	return (
		<View onHoverChanged={setHovered} {...props}>
			<Text
				numberOfLines={1}
				style={localStyles.option.text(isSubOption, selected)}>
				{title}
			</Text>
			{
				hasSubOptions &&
				<Icon
					name="chevron"
					size={14}
					style={localStyles.option.icon(selected)}
				/>
			}
		</View>
	);
}

const localStyles = {
	layout: {
	},

	option: {
		layout: (isSubOption, hovered) => ({
			paddingRight: 19,
			paddingLeft: isSubOption ? 40 : 32,
			backgroundColor: hovered ? '#ededed' : undefined,
			paddingVertical: (isSubOption ? 28 : 33)/2,
			flexDirection: styles.flexDirection.row,
			alignItems: styles.alignItems.center,
			justifyContent: styles.justifyContent.spaceBetween,
		}),

		text: (isSubOption, selected) => ({
			fontSize: 15,
			fontFamily: selected ? fonts.HelveticaNeue.bold :
				isSubOption ? fonts.HelveticaNeue.light
					: fonts.HelveticaNeue.medium,
			flex: 1,
		}),

		icon: selected => ({
			transform: selected ? [{rotate: '90deg'}] : undefined,
		})
	}
};
