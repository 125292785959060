import { createRequestWithDefaultDomain } from "../Request"

import { PaymentResponse, VComment, VOrder, VProdshop } from "../../model/view"
import { evolve } from 'ramda'
import Debug from "../../../Debug"
import HTTPRequest from "../../../network/HTTPRequest"
import Iterator from "../Iterator"
import { NaturalUser } from "../../model/pay/mango"
import Comment from "../../model/Comment"
import APIFunction from "../APIFunction";
import cache, { ListCacheAccessor, updateList, updateOneItemInList } from "../cache";
import Public from "./public"
import cacheUpdateCenter from "./cacheUpdateCenter";
import { Order, PaymentCard } from "../../model/entity";
import AuthManager from "../../../AuthManager";
import ContactInfos from "../../model/entity/ContactInfos";
import VBasket from "../../model/view/user/VBasket";
import VReservation from "../../model/view/general/VReservation";
import { path } from "ramda";
import moment from "moment"

let API;
export default API = {
	Request: null,

	init() {
		API.Request = createRequestWithDefaultDomain("https://v9-dot-user-dot-rcm55-bagshop.appspot.com");
	},

	auth: {
		persistProfile: new APIFunction({
			request: (profile) => (
				new API.Request("/user/profile/", HTTPRequest.Methods.PUT)
					.setParameters(profile)
					.needsAuth()
			)
		}),

		refreshPushToken: new APIFunction({
			request: (token, previousToken, authToken) => (
				new API.Request("/user/refresh-push-token/")
					.needsAuth()
					.setAuthToken(authToken)
					.setParameters({ aNew: token, old: previousToken })
			)
		}),

		contactInfo: {
			get: new APIFunction({
				request: () => (
					new API.Request("/user/contact")
						.needsAuth()
						.setResponseType(ContactInfos)
				)
			})
		}
	},

	product: {
		myComment: {
			/**
			 * @param {Number} id Product's id.
			 * @return {Promise.<Server.Response.<Comment>>} A server response containing the comment.
			 * @see https://docs.google.com/document/d/1yDZWaUut-aODqNilGWeN7KD_qhnxp4Tl67NBQPsIcns/edit#bookmark=id.naalfcw5okl0
			 */
			get: new APIFunction({
				request: productId => (
					new API.Request("/product/comment/me")
						.needsAuth()
						.setParameters({ productId })
						.setResponseType(Comment)
				),

				cache: true,
			}),

			/**
			 * @param {Number} id product's id.
			 * @param {Comment} comment.
			 * @return {Promise.<Server.Response.>} A server response indicating if done.
			 * @see https://docs.google.com/document/d/1yDZWaUut-aODqNilGWeN7KD_qhnxp4Tl67NBQPsIcns/edit#bookmark=id.6iaxnxew14hf
			 */
			persist: new APIFunction({
				request: (productId, comment) => (
					new API.Request("/product/comment/me", HTTPRequest.Methods.POST)
						.needsAuth()
						.setParameters({ productId, comment })
				),

				updateOtherAPIFunctions(_, { parameters: { productId, comment } }) {
					API.product.myComment.get.cache.persist([productId], comment);

					updateOneItemInList(
						() => Public.comment.getListOnProduct.last(productId),
						({ author }) => author.id === AuthManager.user?.id,
						vComment => vComment.setComment(comment),
						list => Public.comment.getListOnProduct.cache.persist([productId], list, cache.TIME.MAX),
					);
				}
			}),
		},
		myVComment: {
			/**
			 * @param {Number} id Product's id.
			 * @return {Promise.<Server.Response.<Comment>>} A server response containing the comment.
			 * @see https://docs.google.com/document/d/1yDZWaUut-aODqNilGWeN7KD_qhnxp4Tl67NBQPsIcns/edit#bookmark=id.naalfcw5okl0
			 */
			get: new APIFunction({
				request: productId => (
					new API.Request("/product/comment/mine")
						.needsAuth()
						.setParameters({ productId })
						.setResponseType(VComment)
				),

				cache: true,
			}),

			/**
			 * @param {Number} id product's id.
			 * @param {Comment} comment.
			 * @return {Promise.<Server.Response.>} A server response indicating if done.
			 * @see https://docs.google.com/document/d/1yDZWaUut-aODqNilGWeN7KD_qhnxp4Tl67NBQPsIcns/edit#bookmark=id.6iaxnxew14hf
			 */
			persist: new APIFunction({
				request: (productId, comment) => (
					new API.Request("/product/comment/me", comment ? HTTPRequest.Methods.POST : HTTPRequest.Methods.DELETE)
						.needsAuth()
						.setParameters({ productId, comment })
				),

				updateOtherAPIFunctions(_, { parameters: { productId, comment } }) {
					API.product.myVComment.get.cache.clear(productId);
					Public.product.get.cache.clear(productId);
				}
			}),
		},

		/**
		 * https://docs.google.com/document/d/1yDZWaUut-aODqNilGWeN7KD_qhnxp4Tl67NBQPsIcns/edit#bookmark=id.xlbzgrzgatf
		 */
		doILikeIt: new APIFunction({
			request: (productId, shopId) => (
				new API.Request("/product/like/me")
					.needsAuth()
					.setParameters({ productId, shopId })
			)
		}),

		/**
		 * https://docs.google.com/document/d/1yDZWaUut-aODqNilGWeN7KD_qhnxp4Tl67NBQPsIcns/edit#bookmark=id.ooae4w58fxpg
		 */
		setLike: new APIFunction({
			request: (productId, shopId, like) => (
				new API.Request("/product/like/me", HTTPRequest.Methods.POST)
					.needsAuth()
					.setParameters({ productId, shopId, like })
			)
		})
	},

	shop: {
		membership: {
			PATH: "/shop/membership",

			/**
			 * @param {Number|String} shopId  Shop's id.
			 * @return {Promise.<Server.Response.<Number>>} A server response containing the timestamp of the membership creation.
			 * @see https://docs.google.com/document/d/1yDZWaUut-aODqNilGWeN7KD_qhnxp4Tl67NBQPsIcns/edit#bookmark=id.bwuc8m10297f
			 */
			get: new APIFunction({
				request: shopId => (
					new API.Request(API.shop.membership.PATH)
						.needsAuth()
						.setParameters({ shopId })
				),

				cache: { time: cache.TIME.MAX },
			}),

			/**
			 * @param {Number|String} shopId id of the Shop to follow
			 * @see https://docs.google.com/document/d/1yDZWaUut-aODqNilGWeN7KD_qhnxp4Tl67NBQPsIcns/edit#bookmark=id.fsupvym1ri9h
			 */
			persist: new APIFunction({
				request: (shopId, isMember) => (
					new API.Request(API.shop.membership.PATH, HTTPRequest.Methods.POST)
						.needsAuth()
						.setParameters({ shopId, isMember })
				),

				updateOtherAPIFunctions(_, { parameters: { shopId } }) {
					API.shop.membership.get.cache.persist([shopId], Date.now(), cache.TIME.MAX)
				}
			}),
		},

		myComment: {
			PATH: "/shop/comment/me",

			/**
			 * @param {Number} id Shop's id.
			 * @return {Promise.<Server.Response.<Comment>>} A server response containing the comment.
			 * @see https://docs.google.com/document/d/1yDZWaUut-aODqNilGWeN7KD_qhnxp4Tl67NBQPsIcns/edit#bookmark=id.x9cav5jnp38k
			 */
			get: new APIFunction({
				request: shopId => (
					new API.Request(API.shop.myComment.PATH)
						.needsAuth()
						.setParameters({ shopId })
						.setResponseType(Comment)
				),

				cache: true,
			}),

			/**
			 * @param {Number} id Shop's id.
			 * @param {Comment} comment.
			 * @return {Promise.<Server.Response.>} A server response indicating if done.
			 * @see https://docs.google.com/document/d/1yDZWaUut-aODqNilGWeN7KD_qhnxp4Tl67NBQPsIcns/edit#bookmark=id.k9ons3kgljua
			 */
			persist: new APIFunction({
				request: (shopId, comment) => (
					new API.Request("/shop/comment/me", HTTPRequest.Methods.POST)
						.needsAuth()
						.setParameters({ shopId, comment })
				),

				updateOtherAPIFunctions(_, { parameters: { shopId, comment } }) {
					API.shop.myComment.get.cache.persist([shopId], comment);

					updateOneItemInList(
						() => Public.comment.getListOnShop.last(shopId),
						({ author }) => author.id === AuthManager.user?.id,
						vComment => vComment.setComment(comment),
						list => Public.comment.getListOnShop.cache.persist([shopId], list, cache.TIME.MAX),
					);
				}
			}),
		},

		myVComment: {
			/**
			 * @param {Number} id Shop's id.
			 * @return {Promise.<Server.Response.<Comment>>} A server response containing the comment.
			 * @see https://docs.google.com/document/d/1yDZWaUut-aODqNilGWeN7KD_qhnxp4Tl67NBQPsIcns/edit#bookmark=id.naalfcw5okl0
			 */
			get: new APIFunction({
				request: shopId => (
					new API.Request("/shop/comment/mine")
						.needsAuth()
						.setParameters({ shopId })
						.setResponseType(VComment)
				),

				cache: true,
			}),

			/**
			 * @param {Number} id Shop's id.
			 * @param {Comment} comment.
			 * @return {Promise.<Server.Response.>} A server response indicating if done.
			 * @see https://docs.google.com/document/d/1yDZWaUut-aODqNilGWeN7KD_qhnxp4Tl67NBQPsIcns/edit#bookmark=id.6iaxnxew14hf
			 */
			persist: new APIFunction({
				request: (shopId, comment) => (
					new API.Request("/shop/comment/me", comment ? HTTPRequest.Methods.POST : HTTPRequest.Methods.DELETE)
						.needsAuth()
						.setParameters({ shopId, comment })
				),

				updateOtherAPIFunctions(_, { parameters: { productId, comment } }) {
					API.product.myVComment.get.cache.clear(productId);
				}
			}),
		},


		/**
		 * @param {productId} productId Product's id.
		 * @param {Location} location User's coordinates.
		 * @param {Location} lastShopLocation Location of the last shop (cursor).
		 * @return {Promise.<Server.Response.<Array.<VProdshop>>>} A server response containing an array A server response containing an array of {@link VProdshop}.
		 * @see https://docs.google.com/document/d/1yDZWaUut-aODqNilGWeN7KD_qhnxp4Tl67NBQPsIcns/edit#bookmark=id.czhsn3l7fiuh
		 */
		getAroundFor: new APIFunction({
			request: (productId, location, lastShopLocation) => (
				new API.Request("/shop/product")
					.needsAuth()
					.setParameters({
						id: productId,
						location,
						lastLocation: lastShopLocation,
					})
					.setResponseType({ type: Array, template: VProdshop })
			),
		}),

		/**
		 *
		 * @param {productId} productId Product's id.
		 * @param {Location} location User's coordinates.
		 * @returns {Iterator<VSale.VProdshop>} Iterator of infinite list of VProdshop of a product
		 */
		getAroundIterator(productId, location) {
			return new Iterator()
				.hydrate({
					function: cursor => API.shop.getAroundFor(productId, location, cursor),
					getCursor: response => response.content.last.shop.shop.location,
				});
		},
	},

	payment: {
		identity: {
			PATH: "/payment/identity",

			/**
			 * @return {Promise.<Server.Response.<NaturalUser>>} A server response that contains if exist the natural user else null.
			 * @see https://docs.google.com/document/d/1yDZWaUut-aODqNilGWeN7KD_qhnxp4Tl67NBQPsIcns/edit#bookmark=id.brujxm2a7kyn
			 */
			get: new APIFunction({
				request: () => (
					new API.Request(API.payment.identity.PATH)
						.needsAuth()
						.devable()
						.setResponseType(NaturalUser)
				),

				cache: true,
			}),

			/**
			 * @param {MangoPay.NaturalUser} identity : User's info to create or update
			 * @return {Promise<Server.Response>} A server response indicating if done.
			 * @see https://docs.google.com/document/d/1yDZWaUut-aODqNilGWeN7KD_qhnxp4Tl67NBQPsIcns/edit#bookmark=id.jrrupap8n9tk
			 */
			update: new APIFunction({
				request: naturalUser => (
					new API.Request(API.payment.identity.PATH, HTTPRequest.Methods.PUT)
						.needsAuth()
						.devable()
						.setParameters({ naturalUser })
				),

				updateOtherAPIFunctions(_, { parameters: identity }) {
					API.payment.identity.get.cache.persist(null, identity, { time: cache.TIME.MAX });
				}
			}),
		},

		card: {
			PATH: {
				LIST: "/payment/card/list",
				REGISTRATION: "/payment/card/registration",
				CARD: "/payment/card",
			},

			/**
			 * @return {Promise.<Server.Response.<Array.<PaymentCard>>>} A server response that contains a list of {@link Card}.
			 * @see https://docs.google.com/document/d/1yDZWaUut-aODqNilGWeN7KD_qhnxp4Tl67NBQPsIcns/edit#bookmark=id.1cocbqqhuizx
			 */
			getList: new APIFunction({
				request: () => (
					new API.Request(API.payment.card.PATH.LIST)
						.needsAuth()
						.devable()
						.setResponseType({ type: Array, template: PaymentCard })
				),

				cache: { time: cache.TIME.MAX },
			}),

			/**
			 * @return {Promise.<Server.Response.<Array.<Mangopay.CardRegistration>>>}  A server response that contains the cardRegistration object to create a card token.
			 * @see https://docs.google.com/document/d/1yDZWaUut-aODqNilGWeN7KD_qhnxp4Tl67NBQPsIcns/edit#bookmark=id.gye5anu39jj8
			 */
			getRegistration: new APIFunction({
				request: () => (
					new API.Request(API.payment.card.PATH.REGISTRATION)
						.needsAuth()
						.devable()
				)
			}),

			/**
			 * @return {Promise.<Server.Response.<Array.<PaymentCard>>>} A server response that contains a list of {@link Card}.
			 * @see https://docs.google.com/document/d/1yDZWaUut-aODqNilGWeN7KD_qhnxp4Tl67NBQPsIcns/edit#bookmark=id.vrlya0sps0ym
			 */
			add: new APIFunction({
				request: (cardRegistration, name, address) => (
					new API.Request(API.payment.card.PATH.CARD, HTTPRequest.Methods.PUT)
						.needsAuth()
						.devable()
						.setParameters({ card: cardRegistration, name, address })
						.setResponseType(PaymentCard)
				),

				updateOtherAPIFunctions() {
					API.payment.card.getList.cache.clear();
				}
			}),

			update: new APIFunction({
				request: (cardId, name, address) => (
					new API.Request(API.payment.card.PATH.CARD, HTTPRequest.Methods.POST)
						.needsAuth()
						.devable()
						.setParameters({ cardId, name, address })
						.setResponseType(PaymentCard)
				),
			}),

			/**
			 * @param {number} id : Card's info id to remove
			 * @return {Promise.<Server.Response>} A server response that contains a the confirmation
			 * @see https://docs.google.com/document/d/1yDZWaUut-aODqNilGWeN7KD_qhnxp4Tl67NBQPsIcns/edit#bookmark=id.4ugwch2xr1di
			 */
			remove: new APIFunction({
				request: cardId => (
					new API.Request(API.payment.card.PATH.CARD, HTTPRequest.Methods.DELETE)
						.needsAuth()
						.devable()
						.setParameters({ cardId })
				),

				updateOtherAPIFunctions(_, { parameters: { cardId } }) {
					updateList.fromCache(
						() => API.payment.card.getList.last(),
						({ id }) => id === cardId,
						() => null,
						() => API.payment.card.getList.cache.persist(null, list, { time: cache.TIME.MAX })
					);
				}
			}),
		},

		/**
		 *Pay basket items
		 * @deprecated use .checkout.order
		 * @param cardId Id of the card to pay with
		 * @param basketItemIds Id 's of the basket item to pay
		 * @returns {Promise.<Server.Response>} A server response that contains the confirmation
		 * @see https://docs.google.com/document/d/1yDZWaUut-aODqNilGWeN7KD_qhnxp4Tl67NBQPsIcns/edit#bookmark=id.sqah2zweo47j
		 */
		payBasketItems: new APIFunction({
			request: (cardId, basketItems, returnUrl) => {
				if (!(basketItems instanceof Array))
					basketItems = [basketItems];

				return new API.Request("/payment/", HTTPRequest.Methods.PUT)
					.needsAuth()
					.setParameters({ cardId, basketItems, returnUrl })
					.setResponseType(PaymentResponse);
			},

			updateOtherAPIFunctions() {
				// TODO update corresponding basket items
				API.order.getList.cache.clear();
			}
		}),
	},

	checkout: {
		basket: new APIFunction({
			request: (basketItems, { receptionMean, paymentMean, cardId, contactInfos, instructions }, returnUrl) => {
				if (!(basketItems instanceof Array))
					basketItems = [basketItems];

				return new API.Request("/checkout/basket", HTTPRequest.Methods.PUT)
					.needsAuth()
					.setParameters({
						basketItems,
						receptionMean,
						paymentMean,
						cardId,
						contactInfos,
						instructions,
						returnUrl
					});
			},

			updateOtherAPIFunctions() {
				// TODO update corresponding basket items
				API.order.getList.cache.clear();
			}
		}),
		order: new APIFunction({
			request: (orderId, { receptionMean, paymentMean, cardId, contactInfos, instructions }, returnUrl) => {
				return new API.Request("/checkout/order", HTTPRequest.Methods.PUT)
					.needsAuth()
					.setParameters({
						orderId,
						receptionMean,
						paymentMean,
						cardId,
						contactInfos,
						instructions,
						returnUrl,
					});
			},

			updateOtherAPIFunctions() {
				// TODO update corresponding basket items
				API.order.getList.cache.clear();
			}
		}),
	},

	reservation: {
		PATH: {
			ADD: "/reservation/add",
		},

		/**
		 * https://docs.google.com/document/d/1yDZWaUut-aODqNilGWeN7KD_qhnxp4Tl67NBQPsIcns/edit#bookmark=kix.mvh0iti3k8gn
		 */
		add: new APIFunction({
			request: (reservation) => {
				reservation = evolve({ time: time => moment.parseZone(time).valueOf() }, reservation);
				reservation.userId = AuthManager.user?.uid;
				return (
					new API.Request(API.reservation.PATH.ADD, HTTPRequest.Methods.PUT)
						.needsAuth()
						.setParameters({ reservation })
				);
			},
		}),

		/**
		 * https://docs.google.com/document/d/1yDZWaUut-aODqNilGWeN7KD_qhnxp4Tl67NBQPsIcns/edit#bookmark=kix.10guafagllx7
		 */
		getList: APIFunction({
			request: (shopId, config) =>
				new API.Request('/reservation/list')
					.needsAuth()
					.setParameters(Object.assign({}, { shopId }, config))
					.setResponseType({
						type: Array,
						template: VReservation,
					}),

			iterator: {
				load: ({ params: [shopId, config], cursor, api }) => api(shopId, Object.assign({}, { cursor }, config)),
				getCursor: path(['reservation', 'time']),
				minimum: 30,
			},
		}),

		persist: APIFunction({
			request: ({ id, reservation, state }) =>
				new API.Request('/reservation', HTTPRequest.Methods.POST)
					.needsAuth()
					.setParameters({ id: id || reservation?.id, state })
					.setResponseType(VReservation),

		})
	},

	order: {
		PATH: {
			LIST: "/order/list",
			ONE: "/order/",
			ITEMS: "/order/item",
			ITEM_STATES: "/order/item/state",
			STATES: "/order/state",
		},

		/**
		 *
		 * @param {Number} from Time of the last order from the last list (cursor)
		 * @return {Promise.<Server.Response.<Array.<Order>>>}  A server response that contains  array of order.
		 * @see https://docs.google.com/document/d/1yDZWaUut-aODqNilGWeN7KD_qhnxp4Tl67NBQPsIcns/edit#bookmark=id.gp9dc9h7031v
		 */
		getList: new APIFunction({
			constructor() {
				cacheUpdateCenter.VOrder.persist.listen(this, vOrders => {
					let map = vOrders.toObject(({ id }) => id);

					updateList.fromCache(
						this.last,
						({ id }) => map[id],
						({ id }) => map[id],
						list => this.cache.persist(null, list),
					);
				});
			},

			request: (shopId, filters, from) => (
				new API.Request(API.order.PATH.LIST)
					.needsAuth()
					.setParameters({ shopId, ...(filters || {}), from })
					.setResponseType({
						type: Array,
						template: VOrder,
					})
			),

			iterator: {
				load: ({ params: [shopId, filters], cursor, api }) => api(shopId, filters, cursor),
				getCursor: vOrder => vOrder.order.creationDate,
				minimum: 10,
			},

			cache: {
				accessor: new ListCacheAccessor,
			},

			updateOtherAPIFunctions(vOrders) {
				cacheUpdateCenter.VOrder.persist.fire(this, ...vOrders);
			}
		}),


		/**
		 *
		 * @param {Number} id Order's id
		 * @returns {Promise.<Server.Response.<Order>>} A server response that contains the order
		 * @see https://docs.google.com/document/d/1yDZWaUut-aODqNilGWeN7KD_qhnxp4Tl67NBQPsIcns/edit#bookmark=id.mlrmyis2gnl1
		 */
		get: new APIFunction({
			constructor() {
				cacheUpdateCenter.VOrder.persist.listen(this, vOrders => {
					let entries = vOrders.map(vOrder => ({
						params: [vOrder.id],
						content: vOrder,
					}));

					this.cache.persistMulti(entries, cache.TIME.MAX);
				});
			},

			request: id => (
				new API.Request(API.order.PATH.ONE)
					.needsAuth()
					.setParameters({ id })
					.setResponseType(VOrder)
			),

			cache: { time: cache.TIME.MAX },
		}),

		getItems: new APIFunction({
			request: (orderId) => (
				new API.Request(API.order.PATH.ITEMS)
					.needsAuth()
					.setParameters({ orderId })
					.setResponseType({
						type: Array,
						template: VOrder.Item,
					})
			),

			// make all items share same vOrder instance
			onSuccess({ content: items }) {
				const vOrder = items?.first?.vOrder;
				if (vOrder)
					items.forEach(vItem => vItem.vOrder = vOrder);
			},

			// No need for now, server returns all items in one shot
			iterator: {
				load: ({ params: [orderId], cursor, api }) => api(orderId, cursor),
				getCursor: vItem => vItem.item.creationDate,
				minimum: 10,
			},

			cache: {
				time: cache.TIME.MAX,
				accessor: new ListCacheAccessor,
			},

			updateOtherAPIFunctions(vOrderItems) {
				let [{ vOrder }] = vOrderItems && vOrderItems.length ? vOrderItems : [{}];
				if (vOrder)
					cacheUpdateCenter.VOrder.persist.fire(this, vOrder);
			}
		}),

		getItemsIterator(orderId) {
			return new Iterator()
				.hydrate({
					function: cursor => API.order.getItems(orderId, cursor),
					getCursor: response => response.content.last.item.cursorTime,
					minimum: 10,
				});
		},

		/**
		 *	https://docs.google.com/document/d/1yDZWaUut-aODqNilGWeN7KD_qhnxp4Tl67NBQPsIcns/edit#bookmark=id.o8wrlryxwr5j
		 */
		updateState: new APIFunction({
			request: (id, state) => (
				new API.Request(API.order.PATH.STATES, HTTPRequest.Methods.POST)
					.needsAuth()
					.setParameters({ id, state })
			),

			updateOtherAPIFunctions(_, { parameters: { id, state: type } }) {
				let state = new Order.State(type, id);
				let update = vOrder => vOrder.setCurrentState(state);

				API.order.get.cache.updateIfExist([id], update);

				updateList.fromCache(
					() => API.order.getList.last(),
					vOrder => vOrder.id === id,
					update,
					list => API.order.get.cache.persist(null, list),
				);

				API.order.getHistory.cache.updateIfExist([id], history => {
					history.push(state);
					return history;
				});
			}
		}),

		confirmOrderReceived: (id) => API.order.updateState(id, Order.State.Type.finished),

		cancelOrder: (id) => API.order.updateState(id, Order.State.Type.canceled),

		getHistory: new APIFunction({
			request: (orderId, from) => (
				new API.Request(API.order.PATH.STATES)
					.needsAuth()
					.setParameters({ orderId, from })
			),

			cache: {
				accessor: new ListCacheAccessor,
			},
		}),

		getHistoryIterator(orderId) {
			return new Iterator()
				.hydrate({
					function: cursor => API.order.getHistory(orderId, cursor),
					getCursor: response => response.content.last.time,
					minimum: 5,
				});
		},
	},

	basket: {
		/**
		 * https://docs.google.com/document/d/1yDZWaUut-aODqNilGWeN7KD_qhnxp4Tl67NBQPsIcns/edit#bookmark=kix.6tiq57qo032
		 */
		persistItem: new APIFunction({
			request: (shopId, productId, size, quantity) => (
				new API.Request("/basket", HTTPRequest.Methods.POST)
					.needsAuth()
					.setParameters({ shopId, productId, size, quantity })
					.setResponseType(VBasket.Item)
			),
		}),

		getList: new APIFunction({
			request: from => (
				new API.Request("/basket")
					.needsAuth()
					.setParameters({ from })
					.setResponseType({
						type: Array,
						template: VBasket,
					})
			),

			iterator: {
				getCursor: basket => basket.lastUpdate,
				minimum: 20,
			}
		}),

		/**
		 * https://docs.google.com/document/d/1yDZWaUut-aODqNilGWeN7KD_qhnxp4Tl67NBQPsIcns/edit#bookmark=kix.nlhn8cs3f39d
		 */
		getItems: new APIFunction({
			request: (shopId, limit, from) => {
				return new API.Request("/basket/item")
					.needsAuth()
					.setParameters({ shopId, limit, from })
					.setResponseType({
						type: Array,
						template: VBasket.Item,
					});
			},

			iterator: {
				load: ({ params: [shopId, number], cursor, api }) => api(shopId, number, cursor),
				getCursor: vItem => vItem.item.creationDate,
				minimum: 1,
			}
		}),

		/**
		 * https://docs.google.com/document/d/1yDZWaUut-aODqNilGWeN7KD_qhnxp4Tl67NBQPsIcns/edit#bookmark=kix.bj8lj8ri6re0
		 */
		getItemsForProductInShop: new APIFunction({
			request: (productId, shopId) => (
				new API.Request("/basket/item/ids/product")
					.needsAuth()
					.setParameters({ productId, shopId })
					.setResponseType({
						type: Array,
						template: VBasket.Item,
					})
			)
		}),

		getItemsByIds: new APIFunction({
			request: (ids) => (
				new API.Request("/basket/item/unique")
					.needsAuth()
					.setParameters({ ids })
					.setResponseType({
						type: Array,
						template: VBasket.Item,
					})
			),

			onSuccess: response => {
				response.content = response.content.toObject(item => item.id);
			},

			// cache: {
			// 	accessor: new SplitCacheAccessor,
			// },
		}),
	},
};

// init at least once with default behavior
API.init();
