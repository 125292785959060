import React from "react"
import { View as RNView } from "react-native";
import use from "../../hook";
import Text from "../Text";
import Icon from "../Icon/v2";
import { fonts, styles } from "../../res";
import { is } from "../../library-js/utils";
import Color from "../../library-js/utils/Color"
import getResourcesForOrderState from "../../library-js/res/getResourcesForOrderState";

/**
 * @param {any}
 */
function OrderStateView({ state, receptionMean, forItem, displayText = true, background, iconSize, innerStyles = { icon: null, text: null }, ...props }) {
	const display = use.memo(forItem ? getResourcesForOrderState.item : getResourcesForOrderState, [state, forItem]);

	const textStyle = use.defaultStyle(
		innerStyles?.text,
		() => [localStyles.text, { color: display?.color() }],
		[display?.color()]
	);

	const iconStyle = use.defaultStyle(
		innerStyles?.icon,
		() => [
			localStyles.iconBackground(background),
			{ color: display?.color() }
		],
		[display?.color()]
	);

	if (!iconSize)
		iconSize = displayText ? 16 : 18;



	props.style = use.defaultStyle(
		props.style,
		() => [
			localStyles.layout,
			background &&
			localStyles.background(is(background, Boolean) ? display?.color() : background),
		],
		[background, display]
	);

	return (
		<RNView {...props}>
			{
				Boolean(display?.icon()) &&
				<Icon
					name={display?.icon()}
					size={iconSize}
					style={iconStyle} />
			}
			{
				Boolean(displayText) &&
				<Text
					numberOfLines={1}
					style={textStyle}>
					{display?.title(receptionMean)}
				</Text>
			}
		</RNView>
	);
}

export default React.memo(OrderStateView);


const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
	},

	background: (color) => ({
		borderRadius: 50 / 2,
		paddingVertical: 4,
		backgroundColor: color && new Color(color).fade(.9).toString(),
	}),

	iconBackground: styles.static.bool(
		{},
		{
			marginLeft: 10,
		},
		{
			marginRight: 5,
		}
	),

	text: {
		font: fonts.Poppins,
		bold: true,
		fontSize: 13,
		textAlign: styles.textAlign.center,
		flex: 1,
	}
};
