import React from 'react';
import { QueryResult } from "@apollo/client";
import { View, ViewProps } from "react-native";
import use from "../../hook";
import { styles } from "../../res";

export default function LoaderHall({ loader, children, skip, spinnerSize, ...props }: LoaderHallProps) {
	const { Spinner, ErrorView } = use.theme();
	return (
		<View {...props}>
			{
				(loader.data || skip) ? children
					: (
						<View style={[styles.center, { flex: 1 }]}>
							{
								loader.loading ? <Spinner size={spinnerSize} />
									: <ErrorView onLoadClicked={loader.refetch} />
							}
						</View>
					)
			}
		</View>
	)
}

export interface LoaderHallProps extends ViewProps {
	loader: QueryResult,
	skip?: any,
	spinnerSize?: number,
}