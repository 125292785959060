import React from "react";
import View from "library-react/component/View/v2";
import Icon from "library-react/component/Icon/v2";
import StarsView from "library-react/component/StarsView";
import {strings, styles} from "@main/res";
import use from "library-react/hook";
import adaptSize from "@main/adaptSize";

function RatePreview({rate, gap = 10, ...props}) {
	const {Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<View {...props}>
			<View style={localStyles.section.layout}>
				<StarsView
					useEyalStyle
					size={adaptSize(31)}
					gap={gap}
					stars={rate?.stars}
					{...localStyles.stars}
				/>
				<Text style={localStyles.section.text}>
					{(rate && rate.stars) || 0}
				</Text>
			</View>

			<View style={[localStyles.section.layout, localStyles.numberOfVotes.layout]}>
				<Icon
					name='person'
					size={15}
					style={localStyles.numberOfVotes.icon}/>

				<Text style={localStyles.numberOfVotes.text}>
					{(rate && rate.numberOfVotes) || 0} {strings.component.RateView.rates}
				</Text>
			</View>
		</View>
	);
}

export default React.memo(RatePreview);

const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,

	},

	section: {
		layout: {
			flexDirection: styles.flexDirection.row,
			...styles.center,
		},

		text: {fontSize: adaptSize(18)},
	},

	stars: {
		onStyle: {},
		style: {
			paddingTop: adaptSize(5),
			alignSelf: styles.alignSelf.flexStart,
			marginRight: adaptSize(20),
		}
	},

	numberOfVotes: {
		layout: {
			marginLeft: adaptSize(41),
		},

		icon: {
			marginRight: adaptSize(10),

		},
		text:{
			textDecorationLine: styles.textDecorationLine.underline,
			fontSize: adaptSize(18)
		},
	},
};
