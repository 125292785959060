import Message from "./Message";
import {Product} from "../../../entity";
import Prodshop from "../../../Prodshop";

export default class ProductMessage extends Message {
	get ok(){
		return Boolean(
			super.ok
			&& this.product?.id
			&& this.product?.name
			&& this.prodshop?.price
		);
	}

	get attachment(){
		return this.product;
	}

	get safeText(){
		return super.safeText || this.product?.title || "";
	}
}

ProductMessage.addProperties({
	product: Product,
	prodshop: Prodshop,
});
