import React, { useEffect, useRef } from "react"
import { Animated } from "react-native"
import View from "./View"
import styles from "../res/styles"
import ComponentUtils from "../ComponentUtils"
import useForceUpdate from "../hook/useForceUpdate"
import Environment from "../library-js/Environment";
import useDefaultStyle from "../hook/useDefaultStyle";
import getObjectIdOf from "../library-js/utils/getObjectIdOf"

export default function ModalFrame({ children }) {
	const { current: stack } = useRef([]);
	const forceUpdate = useForceUpdate();

	children = ComponentUtils.childrenToArray(children);

	let index = 0;
	while (index < stack.length || index < children.length) {
		let frame = stack[index];
		const child = children[index];

		if (!frame) { // open a new frame with animation
			const animatedValue = new Animated.Value(0);
			stack.push(
				frame = {
					animatedValue,
					opened: false, // considered as closed
					animating: false,
					animation: Animated.timing(animatedValue, {
						toValue: 1,
						duration: ANIMATION_DURATION,
						useNativeDriver: true,
					}), // set a opening animation
				}
			);
		}

		if (!child) { // close the corresponding frame with animation
			if (frame.animation && frame.animating) // stop animation if animating
				frame.animation.stop();

			frame.animating = false;
			frame.opened = true; // considered as opened
			frame.animation = Animated.timing(frame.animatedValue, {
				toValue: 0,
				duration: ANIMATION_DURATION,
				useNativeDriver: true,
			}); // set a closing animation
		} else
			frame.child = child; // update child

		index++;
	}

	useEffect( // start animations
		() => {
			stack.forEach((frame, index) => {
				if (frame.animation && !frame.animating) { // start animation
					frame.animating = true;
					frame.animation.start(() => { // on end
						frame.animating = false; // animation finished
						frame.animation = null; // remove animation

						if (!frame.opened) // update state
							frame.opened = true;
						else {
							stack.splice(index, 1);
							forceUpdate(); // remove the view
						}
					});
				}
			})
		},
		// if children length change, the some frames need to be opened/closed
		[children.length]
	);

	return stack.map(frame => (
		// frame
		<View
			key={getObjectIdOf(frame)}
			style={[styles.fit, styles.center]}>

			{/*backdrops*/}
			<Animated.View
				style={[styles.fit, {
					backgroundColor: styles.color.black,
					opacity: frame.animatedValue.interpolate({
						inputRange: [0, 1],
						outputRange: [0, .1],
					})
				}]}>
				<View
					feedback={false}
					style={localStyles.backdrop}
					onPress={frame.child && frame.child.props.onBackdropPress} />
			</Animated.View>

			{// add child with the animated value
				React.cloneElement(frame.child, { animatedValue: frame.animatedValue })
			}
		</View>
	));
}

const ANIMATION_DURATION = 400;

ModalFrame.FullScreen = function ({ animatedValue, ...props }) {
	props.style = useDefaultStyle(props.style, localStyles.fullScreen, [animatedValue]);
	return <Animated.View {...props} />;
};

/**
 * @param {any}
 */
ModalFrame.BottomSheet = function ({ animatedValue, ...props }) {
	props.style = useDefaultStyle(props.style, localStyles.bottomSheet, [animatedValue]);
	return <Animated.View {...props} />;
};

/**
 * @param {import("../hook/useTheme").ModalProps}
 */
ModalFrame.Dialog = function Dialog({ animatedValue, ...props }) {
	props.style = useDefaultStyle(props.style, localStyles.dialog, [animatedValue]);

	return <Animated.View {...props} />;
};

const localStyles = {
	backdrop: [
		styles.fit,
		styles.if(Environment.current === Environment.web, { cursor: "default" }),
	],
	fullScreen: av => [
		styles.fit,
		{
			opacity: av.interpolate({
				inputRange: [.8, 1],
				outputRange: [0, 1],
				extrapolate: "clamp",
			}),

			transform: [
				{
					scale: av.interpolate({
						inputRange: [.5, 1],
						outputRange: [.8, 1],
						extrapolate: "clamp",
					}),
				}
			]
		}
	],

	bottomSheet: av => ({
		borderTopLeftRadius: 10,
		borderTopRightRadius: 10,
		backgroundColor: styles.color.white,
		...styles.absolute({ left: 0, right: 0, bottom: 0 }),
		transform: [
			{
				translateY: av.interpolate({
					inputRange: [0, 1],
					outputRange: [350, 0],
					extrapolate: "clamp",
				}),
			}
		],
		opacity: av.interpolate({
			inputRange: [0, 1],
			outputRange: [0, 1],
			extrapolate: "clamp",
		}),
	}),

	dialog: av => ({
		backgroundColor: styles.color.white,
		minWidth: 320,
		// maxWidth: "50%",
		maxHeight: "90%",
		padding: 40,
		paddingBottom: 20,
		borderRadius: 10,
		opacity: av.interpolate({
			inputRange: [0, 1],
			outputRange: [0, 1],
			extrapolate: "clamp",
		}),
		transform: [
			{
				scale: av.interpolate({
					inputRange: [0, 1],
					outputRange: [.8, 1],
					extrapolate: "clamp",
				}),
			}
		]
	})
};
