/**
 * @type {const}
 */
export const CURRENCIES = {
	AED: "AED",
	AFN: "AFN",
	ALL: "ALL",
	AMD: "AMD",
	ANG: "ANG",
	AOA: "AOA",
	ARS: "ARS",
	AUD: "AUD",
	AWG: "AWG",
	AZN: "AZN",
	BAM: "BAM",
	BBD: "BBD",
	BDT: "BDT",
	BGN: "BGN",
	BHD: "BHD",
	BIF: "BIF",
	BMD: "BMD",
	BND: "BND",
	BOB: "BOB",
	BRL: "BRL",
	BSD: "BSD",
	BTN: "BTN",
	BWP: "BWP",
	BYR: "BYR",
	BZD: "BZD",
	CAD: "CAD",
	CDF: "CDF",
	CHF: "CHF",
	CLP: "CLP",
	CNY: "CNY",
	COP: "COP",
	CRC: "CRC",
	CUP: "CUP",
	CUC: "CUC",
	CVE: "CVE",
	CZK: "CZK",
	DJF: "DJF",
	DKK: "DKK",
	DOP: "DOP",
	DZD: "DZD",
	EEK: "EEK",
	EGP: "EGP",
	ERN: "ERN",
	ETB: "ETB",
	EUR: "EUR",
	FJD: "FJD",
	FKP: "FKP",
	GBP: "GBP",
	GEL: "GEL",
	GGP: "GGP",
	GHS: "GHS",
	GIP: "GIP",
	GMD: "GMD",
	GNF: "GNF",
	GTQ: "GTQ",
	GYD: "GYD",
	HKD: "HKD",
	HNL: "HNL",
	HRK: "HRK",
	HTG: "HTG",
	HUF: "HUF",
	IDR: "IDR",
	ILS: "ILS",
	IMP: "IMP",
	INR: "INR",
	IQD: "IQD",
	IRR: "IRR",
	ISK: "ISK",
	JEP: "JEP",
	JMD: "JMD",
	JOD: "JOD",
	JPY: "JPY",
	KES: "KES",
	KGS: "KGS",
	KHR: "KHR",
	KMF: "KMF",
	KPW: "KPW",
	KRW: "KRW",
	KWD: "KWD",
	KYD: "KYD",
	KZT: "KZT",
	LAK: "LAK",
	LBP: "LBP",
	LKR: "LKR",
	LRD: "LRD",
	LSL: "LSL",
	LTL: "LTL",
	LVL: "LVL",
	LYD: "LYD",
	MAD: "MAD",
	MDL: "MDL",
	MGA: "MGA",
	MKD: "MKD",
	MMK: "MMK",
	MNT: "MNT",
	MOP: "MOP",
	MRO: "MRO",
	MUR: "MUR",
	MVR: "MVR",
	MWK: "MWK",
	MXN: "MXN",
	MYR: "MYR",
	MZN: "MZN",
	NAD: "NAD",
	NGN: "NGN",
	NIO: "NIO",
	NOK: "NOK",
	NPR: "NPR",
	NZD: "NZD",
	OMR: "OMR",
	PAB: "PAB",
	PEN: "PEN",
	PGK: "PGK",
	PHP: "PHP",
	PKR: "PKR",
	PLN: "PLN",
	PRB: "PRB",
	PYG: "PYG",
	QAR: "QAR",
	RON: "RON",
	RSD: "RSD",
	RUB: "RUB",
	RWF: "RWF",
	SAR: "SAR",
	SBD: "SBD",
	SCR: "SCR",
	SDG: "SDG",
	SEK: "SEK",
	SGD: "SGD",
	SHP: "SHP",
	SLL: "SLL",
	SOS: "SOS",
	SRD: "SRD",
	SSP: "SSP",
	STD: "STD",
	SVC: "SVC",
	SYP: "SYP",
	SZL: "SZL",
	THB: "THB",
	TJS: "TJS",
	TMT: "TMT",
	TND: "TND",
	TOP: "TOP",
	TRY: "TRY",
	TTD: "TTD",
	TWD: "TWD",
	TZS: "TZS",
	UAH: "UAH",
	UGX: "UGX",
	USD: "USD",
	UYU: "UYU",
	UZS: "UZS",
	VEF: "VEF",
	VND: "VND",
	VUV: "VUV",
	WST: "WST",
	XCD: "XCD",
	YER: "YER",
	ZAR: "ZAR",
	ZMW: "ZMW",
};

/**
 * @type {const} 
*/
export const SYMBOLS = {
	AED: "د.إ",
	AFN: "؋",
	ALL: "L",
	AMD: "֏",
	ANG: "ƒ",
	AOA: "Kz",
	ARS: "$",
	AUD: "$",
	AWG: "ƒ",
	AZN: "ман",
	BAM: "KM",
	BBD: "$",
	BDT: "৳",
	BGN: "лв",
	BHD: ".د.ب",
	BIF: "FBu",
	BMD: "$",
	BND: "$",
	BOB: "$b",
	BRL: "R$",
	BSD: "$",
	BTN: "Nu.",
	BWP: "P",
	BYR: "Br.",
	BZD: "BZ$",
	CAD: "$",
	CDF: "Fr.",
	CHF: "CHF",
	CLP: "$",
	CNY: "¥",
	COP: "$",
	CRC: "₡",
	CUP: "₱",
	CUC: "$",
	CVE: "Esc",
	CZK: "Kč",
	DJF: "Fdj",
	DKK: "kr",
	DOP: "RD$",
	DZD: "د.ج",
	EEK: "kr",
	EGP: "£",
	ERN: "Nfk",
	ETB: "Br.",
	EUR: "€",
	FJD: "$",
	FKP: "£",
	GBP: "£",
	GEL: "ლ",
	GGP: "£",
	GHS: "₵",
	GIP: "£",
	GMD: "D",
	GNF: "GFr",
	GTQ: "Q",
	GYD: "$",
	HKD: "$",
	HNL: "L",
	HRK: "kn",
	HTG: "G",
	HUF: "Ft",
	IDR: "Rp",
	ILS: "₪",
	IMP: "£",
	INR: "₹",
	IQD: "ع.د",
	IRR: "﷼",
	ISK: "kr",
	JEP: "£",
	JMD: "J$",
	JOD: "د.ا",
	JPY: "¥",
	KES: "Sh",
	KGS: "лв",
	KHR: "៛",
	KMF: "CF",
	KPW: "₩",
	KRW: "₩",
	KWD: "د.ك",
	KYD: "$",
	KZT: "₸",
	LAK: "₭",
	LBP: "ل.ل",
	LKR: "රු",
	LRD: "$",
	LSL: "L",
	LTL: "Lt",
	LVL: "Ls",
	LYD: "ل.د",
	MAD: "د.م.",
	MDL: "L",
	MGA: "Ar",
	MKD: "ден",
	MMK: "Ks",
	MNT: "₮",
	MOP: "P",
	MRO: "UM",
	MUR: "₨",
	MVR: ".ރ",
	MWK: "MK",
	MXN: "$",
	MYR: "RM",
	MZN: "MT",
	NAD: "$",
	NGN: "₦",
	NIO: "C$",
	NOK: "kr",
	NPR: "₨",
	NZD: "$",
	OMR: "ر.ع.",
	PAB: "B/.",
	PEN: "S/.",
	PGK: "K",
	PHP: "₱",
	PKR: "₨",
	PLN: "zł",
	PRB: "p.",
	PYG: "₲",
	QAR: "ر.ق",
	RON: "lei",
	RSD: "Дин.",
	RUB: "руб",
	RWF: "R₣",
	SAR: "ر.س",
	SBD: "$",
	SCR: "₨",
	SDG: "£",
	SEK: "kr",
	SGD: "$",
	SHP: "£",
	SLL: "Le",
	SOS: "Sh",
	SRD: "$",
	SSP: "£",
	STD: "Db",
	SVC: "$",
	SYP: "ل.س",
	SZL: "L",
	THB: "฿",
	TJS: "SM",
	TMT: "m",
	TND: "د.ت",
	TOP: "T$",
	TRY: "₺",
	TTD: "TT$",
	TWD: "NT$",
	TZS: "Sh",
	UAH: "₴",
	UGX: "Sh",
	USD: "$",
	UYU: "$U",
	UZS: "лв",
	VEF: "Bs",
	VND: "₫",
	VUV: "Vt",
	WST: "T",
	XCD: "$",
	YER: "﷼",
	ZAR: "R",
	ZMW: "ZK",
};

export const DEFAULT_CURRENCY = "EUR";