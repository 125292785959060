import React from "react"
import View from "../../../../component/View/v2";
import Text from "../../../../component/Text";
import Icon from "../../../../component/Icon/v2";
import { is } from "../../../../library-js/utils";
import { styles } from "../../../../res";
import use from "../../../../hook";
import SimpleButton from "../../../../component/SimpleButton";

export default function CheckoutBottomBar_mobile({ sum, loading, onSumClicked, nextLabel, onNextClicked, ...props }) {
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<View {...props}>
			<View style={localStyles.leftSide.layout} onPress={onSumClicked}>
				<Text style={localStyles.leftSide.sumLabel}>
					{`Total`}
				</Text>
				<View style={localStyles.leftSide.sumWithArrow.layout}>
					<Text style={localStyles.leftSide.sumWithArrow.sumText}>
						{sum?.formatted}
					</Text>
					<Icon size={30} name="MaterialIcons/keyboard-arrow-up"
						style={localStyles.leftSide.sumWithArrow.dropArrow} />
				</View>
			</View>

			<SimpleButton
				border
				disabled={loading || !onNextClicked}
				onVoidPress={!loading && onNextClicked}
				filled={!loading}
				colors={localStyles.submit.colors}
				style={localStyles.submit.style}>
				<Text style={localStyles.submit.text}>
					{loading ? (is(loading, String) ? loading : `Envoi...`) : (nextLabel || `VALIDER`)}
				</Text>
			</SimpleButton>
		</View>
	);
}

const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
		justifyContent: styles.justifyContent.spaceBetween,
	},

	leftSide: {
		layout: {
			flexDirection: styles.flexDirection.column,
		},
		sumLabel: {
			color: '#b2b2b2',
			fontSize: 13,
			bold: true,
		},
		sumWithArrow: {
			layout: {
				flexDirection: styles.flexDirection.row,
			},
			sumText: {
				color: '#395aff',
				fontSize: 24,
				bold: true,
			},
			dropArrow: {
				color: '#395aff',
			}
		},
	},

	submit: {
		colors: {
			primary: '#395aff',
			secondary: 'white',
		},

		style: {
			maxWidth: '63%',
			flex: 1,
		},

		text: {
			fontSize: 20,
			textAlign: styles.textAlign.center,
		}
	},
};
