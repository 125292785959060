import Debug from "./Debug";

let crashlytics;

try {
	crashlytics = require('@react-native-firebase/crashlytics').default();
} catch (e) {
	// crashlytics no available
}

export default crashlytics;


export function report(message, error) {
	Debug.run({
		development: () => console.error(message, '\n', error),
		default: crashlytics &&
			function () {
				crashlytics.log(message);
				crashlytics.recordError(error);
			}
	})
}
