export default function pipe(...path) {
	path = (path || [])
		.flat()
		.map(fn => fn instanceof Function ? fn : () => fn);

	return function (...params) {
		return path.reduce(
			(value, next, index) => !index ? /* first */ next(...params) : next(value),
			undefined
		);
	}
}
