import React from "react";
import use from "library-react/hook";
import View from "library-react/component/View";
import ContactView from "../ContactView/mobile";
import globals from "../../../globals";
import Image from "library-react/component/Image";

export default React.memo(
	function Footer({item, ...props}) {
		const {shop} = item;
		props.style = use.defaultStyle(props.style, localStyles.layout);

		return (
			<View {...props}>
				{/*Map*/}
				<Image
					source={{
						uri: `https://maps.googleapis.com/maps/api/staticmap?${
							URL.Params.encode({
								size: "600x300",
								key: globals.apiKeys.google,
								center: `${shop.location.latitude},${shop.location.longitude}`,
								markers: `|${shop.location.latitude},${shop.location.longitude}`
							})
						}`
					}}
					resizeMode="cover"
					style={localStyles.map}
				/>
				<ContactView
					shop={shop}
					style={localStyles.contact}/>
			</View>
		);
	}
)

const localStyles = {
	layout: {},

	map: {
		height: 255,
	},
	contact: {
		marginTop: 25,
		marginLeft: 30,
	}
};
