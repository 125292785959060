import useSyncEffect from "./useSyncEffect";
import {useState} from "react";
import {compose, is} from "ramda";

export default function useSubscribe(subscribe, dependencies, initials = []) {
	const [values, set] = useState(initials);
	useSyncEffect(
		() => {
			if (is(Function, subscribe))
				return subscribe(compose(set, Array.of));
		},
		dependencies,
	);
	return [values];
}
