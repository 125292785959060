import React from "react";
import MobileUI from "./MobileUI";
import DesktopUI from "./DesktopUI";
import use from "../../../library-react/hook";
import { paths, screens } from "../../links";
import { useNavigation } from "@react-navigation/native";
import useShowcases from "../../hook/useShowcases";
import SEO, { loadableToStatus } from "library-react/component/SEO";
import pageTitle from "@main/utils/pageTitle";
import { prop } from "ramda";


export default function ShowcaseListUI({ ...props }) {
	const navigation = useNavigation();
	props.onSelected = use.callback(id => navigation.navigate(screens.showcase, { id }));

	const loadable = props.loadable = useShowcases.loadable();
	const { value: showcases } = loadable;

	props.title = `VITRINES`;
	const display = use.context.display();
	const { vShop } = use.context.infos();
	return (
		<>
			<SEO
				status={
					showcases?.length === 0 ? 404
						: loadableToStatus(loadable)
				}
				canonical={paths.showcase}
				title={pageTitle([`Vitrines`])}
				image={vShop.logo}
				description={
					showcases && (
						showcases.map(prop('title'))
							.join(', ')
						+ `... Retrouvez toutes nos vitrines.`
					)
				}
			/>

			{
				display.is.mobile ?
					<MobileUI {...props} /> :
					<DesktopUI {...props} />
			}
		</>
	);
}
