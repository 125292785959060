import React from "react";
import View from "library-react/component/View";
import NavigationView from "./NavigationView"
import use from "library-react/hook";
import { styles } from "../../res"
import { appContext } from "library-react/app/useApp";
import useRouteForFrame from "@main/App/Frame/useRoute";
import Chat, { ChatProvider } from "./Chat"
import { screens } from "@main/links";
import Icon from "library-react/component/Icon/v2";
import useSharable from "library-react/component/sharable/context";
import { showSocialModalForUrl } from "library-react/utils/showSocialModal";
import { reservation } from "./icons"
import ResevationFormModal from "../../component/ResevationFormModal";

function Frame({ children, ...props }) {
	const instances = use.instances({ chat: null });
	const route = useRouteForFrame();

	const sharable = useSharable();
	const { vShop: { shop } } = use.context.infos();
	const display = use.context.display();

	props.style = use.defaultStyle(props.style, localStyles.layout, [display]);
	return (
		<appContext.Consumer>
			{
				app => {
					Object.assign(app, {
						openChat: () => instances.chat?.open(),
						setChatAttachment: attachment => instances.chat?.setAttachment(attachment),
					});


					return (
						<View {...props}>
							<ChatProvider>
								<NavigationView />

								<View
									accessibilityRole="main" // SEO
									style={localStyles.main}>
									{children}
								</View>
								{
									sharable &&
									<View
										style={shop?.acceptsReservations ? localStyles.share : localStyles.reservation(display)}
										onPress={() => showSocialModalForUrl(app, sharable)}>
										<Icon
											name={"MaterialIcons/share"}
											size={24}
										/>
									</View>
								}
								{
									shop?.acceptsReservations &&
									<View style={localStyles.reservation(display)}
										onPress={() => app.openModal(<ResevationFormModal shopId={shop.id} />)}>
										<Icon
											name={reservation}
											size={24}
										/>
									</View>
								}
								{
									route &&
									![screens.checkout, screens.orderCheckout].includes(route.name) &&
									<Chat
										ref={instances.set.chat}
										style={localStyles.chat(display)} />
								}

							</ChatProvider>
						</View>
					);
				}
			}
		</appContext.Consumer>
	);
}

export default React.memo(Frame);


const localStyles = {
	layout: styles.static(
		{
			flex: 1,
		},
		{
			mobile: {},

			default: {
				flexDirection: styles.flexDirection.row,
			}
		}
	),

	share: styles.static(
		{
			marginTop: 20,
			...styles.center,
			...styles.circle(52),
			backgroundColor: "#d7d6d8",
			overflow: styles.overflow.visible,
			position: styles.position.absolute,
		},
		{
			mobile: {
				right: 25,
				bottom: 40 + (52 + 10) * 2,
			},

			default: {
				right: 50,
				bottom: 60 + (52 + 10) * 2,
			},
		}
	),
	reservation: styles.static(
		{
			marginTop: 20,
			...styles.center,
			...styles.circle(52),
			backgroundColor: "#dddddd",
			overflow: styles.overflow.visible,
			position: styles.position.absolute,
		},
		{
			mobile: {
				right: 25,
				bottom: 40 + 52 + 10,
			},

			default: {
				right: 50,
				bottom: 60 + 52 + 10,
			},
		}
	),
	chat: styles.static(
		{
			position: styles.position.absolute
		},
		{
			mobile: {
				right: 25,
				bottom: 40,
			},

			default: {
				right: 50,
				bottom: 60,
			},
		}
	),

	main: {
		flex: 1,
		alignSelf: styles.alignSelf.stretch,
		overflow: styles.overflow.hidden,
	},
};

