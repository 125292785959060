import React from "react";
import MobileUI from "./mobile"
import DesktopUI from "./desktop"
import use from "library-react/hook";
import Section from "library-js/app/model/entity/CatalogSection/Section";
import Search from "library-js/Search/index";
import useSectionsLoadable from "@main/hook/useSectionsLoadable";
import { useRoute } from "@react-navigation/native";
import { paths } from "@main/links";
import pageTitle from "@main/utils/pageTitle";
import SEO, { iteratorToStatus, loadableToStatus, mergeStatus } from "library-react/component/SEO";
import toReadableSection from "../../utils/toReadableSection";
import { useStaticShopCategoryLoadable } from "../../hook/useStaticShopCategory";
import toReadableTags from "../../utils/toReadableShopTags";
import { useStaticShopTagsNamesLoadable } from "../../hook/useStaticShopTags";
import { optionalLoadableToStatus } from "../../../library-react/component/SEO";
import buildSeoParams from "library-js/utils/buildSeoParams";

function CatalogUI({ ...props }) {
	const route = useRoute();
	const sectionParam = route.params?.section;

	const shopCategoryLoadable = useStaticShopCategoryLoadable();
	const category = shopCategoryLoadable.value;

	const shopTagsNamesLoadable = useStaticShopTagsNamesLoadable();
	const shopTagsNames = shopTagsNamesLoadable.value;

	const display = use.context.display();
	const section = props.section = use.memo(
		() => (sectionParam && new Section(sectionParam)),
		[sectionParam],
	);

	const { vShop } = use.context.infos();
	const shop = vShop?.shop;
	const productsIterator = use.infiniteIterator(() =>
		Search.index.prodshop.getIterator({
			available: true,
			shopId: vShop.id,
			inShop: true,
			sections: [sectionParam],
			number: 30,
		}),
		[sectionParam, vShop?.id]
	);

	const { value: cupboard } =
		props.sectionsLoadable = useSectionsLoadable();

	if (cupboard) // do not display products before sections
		props.productsIterator = productsIterator;

	const { config } = use.context.infos();
	const status = props.status = mergeStatus([
		loadableToStatus(props.sectionsLoadable),
		iteratorToStatus(productsIterator, sectionParam),
		optionalLoadableToStatus(shopTagsNamesLoadable),
		optionalLoadableToStatus(shopCategoryLoadable),
	]);
	const optimizationParams = props.optimizationParams = status === 200 ?
		buildSeoParams({
			keywords: [
				section?.path.last || config?.catalog?.name || `catalogue`,
				category?.name,
				shopTagsNames,
				shop.address.city
			]
		})
		: null;

	return (
		<>
			<SEO
				status={status}
				image={vShop.logo}
				canonical={paths.product}
				canonicalParams={{ section: sectionParam?.toString() }}
				optimizationParams={optimizationParams}
				title={pageTitle([
					toReadableSection(section) || config?.catalog?.name || `Catalogue`,
					category?.name,
					toReadableTags(shopTagsNames),
					shop?.address.locality,
				])}
				description={(() => {
					// sub sections
					let description = cupboard?.getChildrenOf(sectionParam)?.map(section => section.path.last).join(', ');

					// products
					if (productsIterator?.items?.length) {
						if (description)
							description += ', ';

						description += productsIterator?.items?.map(vProdshop => vProdshop.product.fullTitle).distinct().join(', ')
					}

					return description;
				})()}
			/>

			{
				display.is.mobile ?
					<MobileUI {...props} /> :
					<DesktopUI  {...props} />
			}
		</>
	);
}

export default React.memo(CatalogUI);
