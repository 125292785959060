import Base from "../../class/Base"
import Price from "./Price"

/***
 * A link between a product and the current shop.
 */
export default class Prodshop extends Base {
	constructor() {
		super();
		this.price = new Price(null);
	}

	get finalPrice() {
		return this.discount || this.price;
	}

	get oldPrice() {
		return this.discount ? this.price : null;
	}

	get discountPercent() {
		const discount = this.discount?.value;
		const price = this.price?.value;

		if (discount >= 0 && price >= 0)
			return Math.floor((1 - discount / price) * 100) / 100;
	}
}
Prodshop.Id = class Id extends Base {
	constructor(shopId, productId) {
		super();
		this.shopId = shopId;
		this.productId = productId;
	}
};

Prodshop.addProperties({
	id: Prodshop.Id,
	price: Price,
	discount: Price,
	note: String,
	available: Boolean,
	creationDate: Number,
});

Prodshop.Id.addProperties({
	shopId: Number,
	productId: Number,
});
