import is from "./is"
import Objects from "./Objects";

/**
 * Compare deeply 2 variables.
 * @returns {boolean} True if the 2 variable have the same content. False otherwise.
 */
export default function equals(item1, item2, options = {}) {
	options = Object.assign({}, DEFAULT_OPTIONS, options || {});

	if (item1 === item2)
		return true;

	if (!(item1 instanceof Object) || !(item2 instanceof Object))
		return false;

	if (options.constructor && item1.constructor !== item2.constructor)
		return false;


	const descriptions1 = Objects.getDescriptions(item1);
	const descriptions2 = Objects.getDescriptions(item2);

	let index = Object.keys(descriptions1)
		.findIndex((key, description1) => {
			const description2 = descriptions2[key];
			delete descriptions2[key];

			// create getter functions to check it's not the case where there is a set without a get, which would crash
			const getItem1Value = () => (description1.get || !description1.set) && item1[key];
			const getItem2Value = () => (!description2 || description2.get || !description2.set) && item2[key];

			if (description1.get)
				return !(
					options.getters ?
						equals(/* get */ item1[key], getItem2Value(), options)
						: !(description2 && is.null(description2.value))
				);

			if (description2 && description2.get)
				return !(
					options.getters ?
						equals(getItem1Value(), /* get */ item2[key], options) :
						is.null(description1.value)
				);

			return !equals(getItem1Value(), getItem2Value(), options);
		});

	if (index >= 0)
		return false;

	index = Object.keys(descriptions2)
		.findIndex((key, description2) => {
			if (description2.get)
				return !(
					!options.getters
					|| equals(item1[key], item2[key], options)
				);

			if (description2.set)
				return false;

			return !equals(item1[key], item2[key], options);
		});

	return index < 0;
}

const DEFAULT_OPTIONS = {
	constructor: true,
	getters: false,
};
