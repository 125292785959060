import React from "react"
import use from "library-react/hook";
import View from "library-react/component/View";
import {styles} from "@main/res";
import RateView from "./RateView";
import StarsList from "./StarsList";
import CommentButton from "../CommentButton";
import ErrorView from "../../../ErrorView";

export default function HeaderDesktop({title, rateLoadable, edit, ...props}) {
	const {value: rate, loading, load: reload, failed} = rateLoadable;

	const {Text, Spinner} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<View {...props}>
			{
				rate ?
					<>
						<RateView rate={rate}/>
						<StarsList rate={rate}/>
					</> :
					loading ?
						<Spinner style={{flex: 1}}/> :
						failed && <ErrorView style={{flex: 1}} retry={reload}/>
			}

			<View style={localStyles.comment.layout}>
				<Text style={localStyles.comment.text} numberOfLines={1}>
					{`Votre avis sur ${title} ?`}
				</Text>
				<CommentButton style={localStyles.comment.button} onPress={edit}/>
			</View>
		</View>
	);
}


const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
		justifyContent: styles.justifyContent.spaceBetween,
		padding: 25,
	},
	comment: {
		layout: {
			alignSelf: styles.alignSelf.flexEnd,
			alignItems: styles.alignItems.center,
			paddingVertical: 20,
			paddingRight: 12,
		},
		text: {
			width: 240,
			textAlign: styles.textAlign.center,
			fontSize: 18,
			marginBottom: 21,
		},

		button: {
			width: 210,
		}
	}
};
