import {DisplayType} from "@main/contexts";
import {DESIGN_WIDTH, MIN_DESIGN_WIDTH} from "@main/adaptSize";

export default function getNecessaryZoom(){
	const width = window.innerWidth;
	const display = DisplayType.getFor(width);

	return (
		display.is.desktop ? /* force desktop min width to be design's definition */
			Math.min(width / DESIGN_WIDTH, 1)

			: display.is.tablet ? /* force to behave like desktop */
			width / DESIGN_WIDTH /*DisplayType.desktop.value*/

			// min width accepted: 400
			: Math.min(width / MIN_DESIGN_WIDTH, 1)
	);
}
