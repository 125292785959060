import Base from "../class/Base";
import is from "./is";

export default class Accessor  extends Base {
	constructor(get, set){
		super();

		// for constructor new Accessor({get, set})
		if (is(get, Object) && !is(get, Function)){
			set = get.set;
			get = get.get;
		}

		this.get = get;
		this.set = set;
	}
}
Accessor.addProperties({
	get : Function,
	set : Function,
});
