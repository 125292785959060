import useMemo from "./useMemo";
import { useBackHandler } from "@react-native-community/hooks";

export default function useOnBack(handle) {
	const memory = useMemo({});
	memory.handle = handle;

	useBackHandler(() => {
		const { handle } = memory;
		if (handle instanceof Function) {
			handle();
			return true;
		}
		return false;
	});
}
