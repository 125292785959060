import Enum from "../../../../class/Enum"

/**
 * @property IDENTITY_PROOF
 * @property REGISTRATION_PROOF
 * @property ARTICLES_OF_ASSOCIATION
 * @property SHAREHOLDER_DECLARATION
 * @property ADDRESS_PROOF
 */
const KYCDocumentType = Enum.make(["IDENTITY_PROOF", "REGISTRATION_PROOF", "ARTICLES_OF_ASSOCIATION", "SHAREHOLDER_DECLARATION", "ADDRESS_PROOF"]);
export default KYCDocumentType;

KYCDocumentType.domains.required = [KYCDocumentType.IDENTITY_PROOF, KYCDocumentType.REGISTRATION_PROOF, KYCDocumentType.ARTICLES_OF_ASSOCIATION];
