import "@main/init";
// --- init icons ---
import Frame from "@main/App/Frame";
import { desktopToastConfig, mobileToastConfig } from "@main/App/toastConfigs";
import ProdshopPrice from "@main/component/ProdshopPrice";
import ProfileUI from "@main/component/ProfileUI";
import Spinner from "@main/component/Spinner";
import { DisplayType } from "@main/contexts";
import contexts from "@main/contexts/contexts";
import OrderListFragment from "@main/fragment/OrderListFragment";
import PaymentStateFragment from "@main/fragment/PaymentStateFragment";
import { linking, screens } from "@main/links";
import fonts from "@main/res/fonts";
import AccountUI from "@main/ui/AccountUI";
import BasketUI from "@main/ui/BasketUI";
import BrandListUI from "@main/ui/BrandListUI";
import BrandUI from "@main/ui/BrandUI";
import CatalogFragment from "@main/ui/CatalogUI";
import CheckoutUI from "@main/ui/CheckoutUI";
import HomeFragment from "@main/ui/HomeUI";
import OrderCheckoutUI from "@main/ui/OrderCheckoutUI";
import OrderUI from "@main/ui/OrderUI";
import ProductUI from "@main/ui/ProductUI";
import ReservationListUI from "@main/ui/reservation/ReservationListUI";
import SearchUI from "@main/ui/SearchUI";
import ShopUI from "@main/ui/ShopUI";
import ShowcaseListUI from "@main/ui/ShowcaseListUI";
import ShowcaseUI from "@main/ui/ShowcaseUI";
import { createStackNavigator } from '@react-navigation/stack';
import "firebase/analytics";
import firebase from "firebase/app";
import AuthManager from "library-js/AuthManager";
import Environment from "library-js/Environment";
import debounce from "library-js/utils/debounce";
import timeout from "library-js/utils/timeout";
import Navigation from "library-js/web/Navigation";
import AppLayout from "library-react/app/AppLayout";
import responsiveFactory from "library-react/component/abstract/responsiveFactory";
import withMaterialInputStyle from "library-react/component/abstract/withMaterialInputStyle";
import AgeDialog from "library-react/component/AgeDialog";
import DefaultToast from "library-react/component/DefaultToast";
import ModalFrame from "library-react/component/ModalFrame";
import SharableContainer from "library-react/component/sharable/SharableContainer";
import { TextStyleContext } from "library-react/component/Text/v2";
import use from "library-react/hook";
import useAuth from "library-react/hook/useAuth";
import ThemeContext from "library-react/hook/useTheme/ThemeContext";
import ToastContainer from "library-react/hook/useToast/ToastContainer";
import styles from "library-react/res/styles";
import { pickAll, pickBy } from "ramda";
import { Helmet } from "react-helmet";
import createGraphQLClient from '../../library-react/library-js/app/graphql';
import DomainAvailableUI from "./DomainAvailableUI";

function App({ vShop, config }) {
	const shop = vShop?.shop;

	const [version, refresh] = use.version();
	const infos = use.memo(() => ({ vShop: vShop?.copy(), config }), [version]);

	// refresh app when shop opens or closes
	use.effect((nextTimeStamp) => {
		if (nextTimeStamp)
			return timeout(refresh, nextTimeStamp - Date.now());
	}, [shop?.nextSwitch?.next]);


	const [display, setDisplay] = use.state(DisplayType.getCurrent);
	use.syncEffect(() =>
		window.on('resize', debounce(() => {
			const newDisplay = DisplayType.getCurrent();
			setDisplay(newDisplay);
		}, 500)
		));

	const forceUpdate = use.forceUpdate();
	use.effect(async () => {
		const { params } = Navigation;
		if (params.credential)
			try {
				const credential = firebase.auth.AuthCredential.fromJSON(params.credential);
				if (credential)
					await firebase.auth().signInWithCredential(credential);
			} catch (error) {
				console.warn("An error occurred while authenticating using credential from url: ", error);
			} finally {
				delete params.credential;
				Navigation.params = params;
				forceUpdate();
			}
	});

	const user = useAuth.user();
	const isAgeCorrect = () => !(vShop?.shop.requiredAge > 0)
		|| Number(localStorage.ageConfirmedAt) > Date.now() - Date.DAY
		|| Environment.crawling; // SEO

	const [app, setApp] = use.state();
	const memo = use.memo({});
	memo.app = app;

	use.effect((isUserAnonymous) => {
		if (isUserAnonymous)
			return AuthManager.onceAuthenticated(user => {
				const close = memo.app?.openModal(<ProfileUI onSuccess={() => close()} />, Boolean(user.email));
			});
	}, [user === null]);

	use.effect(() => {
		if (app && !isAgeCorrect() && vShop) {
			app.openModal(
				<AgeDialog
					shop={vShop.shop}
					onceConfirmed={() => {
						localStorage.ageConfirmedAt = Date.now(); // save
						forceUpdate();
					}}
					style={localStyles.dialog} />,
				true,
				() => {
					if (!isAgeCorrect())
						window.location.href = 'https://google.com'; // quit
				}
			);
		}
	}, [app]);

	function trackFocusedScreen({ routes, index }) {
		const route = routes[index];
		const params = pickBy((_, key) => !key.startsWith('_'), route.params || {});

		firebase.analytics()
			.logEvent('screen_view', {
				screen: route.name,
				params,
				shop: pickAll(['id', 'name', 'domain'], shop),
				host: window.location.host,
			});
	}

	return (
		<GraphqlProvider>
			<SharableContainer>
				<TextStyleContext.Provider value={TEXT_STYLE}>
					<ThemeContext.Provider value={THEME}>
						{
							<contexts.infos.Provider value={infos}>
								<contexts.display.Provider value={display}>
									<ToastContainer
										DefaultToast={DefaultToast}
										config={display?.is.mobile ? mobileToastConfig : desktopToastConfig}>
										<AppLayout
											ref={setApp}
											linking={linking}
											documentTitle={{ enabled: false }}
											onStateChange={shop && trackFocusedScreen}
											style={localStyles.layout}>
											<ToastContainer
												DefaultToast={DefaultToast}
												config={display?.is.mobile ? mobileToastConfig : desktopToastConfig}>
												<Helmet>
													<meta name="robots" content="noindex" />
												</Helmet>

												{
													// true ? <DraftUI/> : // TODO comment this line

													vShop ? (
														isAgeCorrect() &&
														<>
															<Frame>
																<Stack.Navigator screenOptions={localStyles.screen}>

																	{/* home */}
																	<Stack.Screen name={screens.home}
																		component={HomeFragment} />

																	{/* search */}
																	<Stack.Screen name={screens.search}
																		component={SearchUI} />

																	{/* catalog */}
																	<Stack.Screen name={screens.catalog}
																		component={CatalogFragment} />

																	{/* product */}
																	<Stack.Screen name={screens.product}
																		component={ProductUI} />

																	{/* shop */}
																	<Stack.Screen name={screens.shop} component={ShopUI} />

																	{/* basket */}
																	{
																		shop.ordersEnabled &&
																		<Stack.Screen name={screens.basket}
																			component={BasketUI} />
																	}

																	{/* brand list */}
																	<Stack.Screen name={screens.brandList}
																		component={BrandListUI} />

																	<Stack.Screen name={screens.brand} component={BrandUI} />

																	<Stack.Screen name={screens.showcaseList}
																		component={ShowcaseListUI} />

																	<Stack.Screen name={screens.showcase}
																		component={ShowcaseUI} />

																	{
																		((user === undefined) || user || Navigation.params.credential) &&
																		<>
																			{/* account */}
																			<Stack.Screen name={screens.account}
																				component={AccountUI} />

																			{/* checkout */}
																			{
																				shop.ordersAvailable &&
																				<Stack.Screen name={screens.checkout}
																					component={CheckoutUI} />
																			}


																			{/* checkout result */}
																			<Stack.Screen name={screens.paymentResult}
																				component={PaymentStateFragment} />

																			{/* reservation list */}
																			<Stack.Screen name={screens.reservationList}
																				component={ReservationListUI} />

																			{/* order list */}
																			<Stack.Screen name={screens.orderList}
																				component={OrderListFragment} />

																			{/* order */}
																			<Stack.Screen name={screens.order}
																				component={OrderUI} />

																			{/* Checkout order */}
																			<Stack.Screen name={screens.orderCheckout}
																				component={OrderCheckoutUI} />

																		</>
																	}

																</Stack.Navigator>
															</Frame>

															<address style={{ zIndex: -1, width: 0, height: 0, overflow: "hidden" }}>
																{vShop.shop.address.formatted}
															</address>
														</>
													) :
														<DomainAvailableUI />
												}
											</ToastContainer>
										</AppLayout>
									</ToastContainer>

								</contexts.display.Provider>
							</contexts.infos.Provider>
						}
					</ThemeContext.Provider>
				</TextStyleContext.Provider>

			</SharableContainer>
		</GraphqlProvider>
	);
}

const GraphqlProvider = createGraphQLClient();

export default App;

const Stack = createStackNavigator();

const THEME = ({ BottomSheet, TextInput }) => {
	const AppBottomSheet = function ({ ...props }) {
		props.style = use.defaultStyle(props.style, localStyles.bottomSheet);
		return <BottomSheet {...props} />;
	}
	return ({
		BottomSheet: AppBottomSheet,
		DefaultModal: responsiveFactory(AppBottomSheet, ModalFrame.Dialog),
		PriceComponent: ProdshopPrice,
		Spinner,
		MaterialTextInput: withMaterialInputStyle(TextInput),
		TextInput: withMaterialInputStyle(TextInput),
	});
}

const TEXT_STYLE = {
	font: fonts.HelveticaNeue,
	lineHeight: '1.2',
};


const localStyles = {
	layout: { flex: 1 },

	screen: {
		headerShown: false,
	},

	dialog: {
		marginHorizontal: 15,
	},
	bottomSheet: {
		...styles.absolute({ left: "4%", right: "4%", bottom: 0 }),
		borderTopLeftRadius: 0,
		borderTopRightRadius: 0,
		padding: 30,
		paddingBottom: 50,
	}
};
