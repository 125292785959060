import Request from "./Request"
import Response from "./Response"
import Public from "./api/public"
import user from "./api/user"
import retailer from "./api/retailer"
import marketer from "./api/marketer"
import Loadable from "./Loadable"
import cache from "./cache"
import Environment from "../../Environment";

const Server = {
	/**
	 * Init the server module
	 * @param Firebase Firebase app instance for auth token.
	 * @param mapStorage Instance of {@link IMapStorage} to create a cache.
	 */
	init(Firebase, mapStorage){
		// cache
		if (mapStorage)
			cache.init(mapStorage);

		// apis
		Public.init();
		marketer.init();
		retailer.init();
		user.init();
	},

	// ---- APIs ----
	public : Public,
	Public,
	user,
	retailer,
	marketer,

	// ---- classes ----
	Request,
	Response,
	Loadable,
	cache,
};
export default Server;

export {Public, user, retailer, marketer, Request, Response, Loadable, cache};

export const MAX_SHOP_SHOWCASES = 50;
export const MAX_SHOP_SHOWCASES_ACTIVE = 5;


if (Environment.is.web)
	window.Server = Server;
