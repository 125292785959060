import React from "react"
import {View as RNView} from "react-native"
import use from "../../../../../../../../hook";
import MessageLayout from "../MessageLayout";
import TextContentView from "../TextView";
import {styles} from "../../../../../../../../res";
import ProductPreview from "../../../../ProductPreview";
import TimeView from "../../TimeView";
import Chain from "../Chain";

function ProductMessageView({message, onProductClicked, hidePrice, ...props}) {
	const onClicked = use.callback(
		onProductClicked && (() => {
			if (message?.product)
				onProductClicked(message?.product, message?.prodshop);
		}),
		[message?.product, onProductClicked]
	);

	props.style = use.defaultStyle(props.style, localStyles.layout, [message.isAuthor]);

	return (
		<RNView {...props}>
			<ProductPreview
				product={message.product}
				price={message.prodshop?.finalPrice}
				oldPrice={message.prodshop?.oldPrice}
				hidePrice={hidePrice}
				onPress={onClicked}
			/>
			{
				Boolean(message?.text) ?
					<>
						<Chain/>
						<MessageLayout message={message} style={localStyles.text}>
							<TextContentView message={message}/>
						</MessageLayout>
					</> :
					<TimeView
						time={message.time}
						style={localStyles.time}
					/>
			}
		</RNView>
	);
}

export default React.memo(ProductMessageView);


const localStyles = {
	layout: styles.static.bool(
		{},
		{alignItems: styles.alignSelf.flexEnd},
		{alignItems: styles.alignSelf.flexStart},
	),

	text: {
		minWidth: ProductPreview.WIDTH, // message should not be smaller than the attachment
	},

	time: {
		color: '#1e2432',
		marginTop: 9,
		marginHorizontal: 17,
	}
};
