import React from "react";
import { View as RNView } from "react-native"
import ModalFrame from "../../../component/ModalFrame";
import Text from "../../../component/Text";
import ScrollView from "../../../component/ScrollView";
import { styles } from "../../../res";
import use from "../../../hook";
import Icon from "../../../component/Icon/v2";

export default function BasketReviewModal({ items, close, ...props }) {
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<ModalFrame.BottomSheet  {...props}>
			<ScrollView contentContainerStyle={localStyles.containerStyle}>
				{
					items.map(item =>
						<BasketItemRow key={item.id} item={item} />
					)
				}
			</ScrollView>

			{
				close &&
				<Close onPress={close} />
			}
		</ModalFrame.BottomSheet>
	);
}

const CLOSE_SIZE = 30;
const Close = React.memo(({ ...props }) =>
	<Icon
		name="MaterialCommunityIcons/close-circle"
		size={CLOSE_SIZE}
		style={localStyles.close}
		{...props}
	/>
)

function BasketItemRow({ item: { product, size, quantity, finalPrice: price }, ...props }) {
	props.style = use.defaultStyle(props.style, localStyles.item.layout);

	return (
		<RNView {...props}>
			<RNView style={localStyles.item.info.layout}>
				<Text numberOfLines={1}
					style={localStyles.item.info.title}>
					{product.title}
				</Text>
				{
					Boolean(size) &&
					<Text numberOfLines={1}
						style={localStyles.item.info.subtitle}>
						{`TAILLE `}{size}
					</Text>
				}

			</RNView>
			<Text numberOfLines={1}
				style={localStyles.item.quantity}>
				{quantity}
			</Text>
			<Text numberOfLines={1}
				style={localStyles.item.price}>
				{price.formatted}
			</Text>
		</RNView>
	)
}

const localStyles = {
	layout: {
		paddingHorizontal: 0,
		paddingVertical: 0,
	},
	containerStyle: {
		paddingHorizontal: 35,
		paddingTop: 70,
		paddingBottom: 25,
		backgroundColor: '#f7f7f7'
	},

	item: {
		layout: {
			flexDirection: styles.flexDirection.row,
			flex: 1,
			justifyContent: styles.justifyContent.spaceBetween,
		},
		info: {
			layout: {
				flexDirection: styles.flexDirection.column,
				width: '40%'
			},
			title: {
				color: '#000000',
				fontSize: 16,
				bold: true,
			},
			subtitle: {
				color: '#101010',
				fontSize: 10,
				bold: true,
			}
		},
		quantity: {
			color: '#000000',
			fontSize: 16,
			bold: true,
		},
		price: {
			width: 70,
			textAlign: styles.textAlign.right,
			color: '#000000',
			fontSize: 19,
			bold: true,
		}
	},
	close: {
		position: styles.position.absolute,
		top: 0,
		right: 0,
	}
};
