import init from "library-react/init"
import Debug from "library-js/Debug";
import Platform from "library-js/Platform";
import globals from "./globals"
import { ChatManager } from "library-js/app/model/chat";
import firebase from "library-react/Firebase";
import "firebase/analytics"
import use from "library-react/hook";
import { use as useAppContext } from "./contexts";
import AuthManager from "library-js/AuthManager";
import BasketManager from "library-js/app/model/basket/BasketManager";
import moment from "moment";
import 'moment/locale/fr';
import Environment from "library-js/Environment";
import getNecessaryZoom from "@main/getNecessaryZoom";
import Icon from "library-react/component/Icon/v2";
import icons from "@main/res/icons";
import Server from "../library-react/library-js/app/Server";
import URL from "library-js/utils/URL";

moment.locale("fr");

BasketManager.shopId = window.shopId;

firebase.init({
	apiKey: globals.apiKeys.google,
	appId: "1:607058134897:web:8fff10ac651c5b1c2ed8d0",
});

firebase.analytics();

init({
	mode: process.env.NODE_ENV,
	firebase,
	platform: Platform.user,
	searchKey: globals.apiKeys.algolia,
});

Icon.add(icons);

// If globals.devParameter is true switch mode to development
const { params } = new URL(window.location.href);
if (params[globals.devParameter])
	Debug.mode = Debug.Modes.development;


if (Debug.is.production && document.domain.endsWith("shopinzon.com"))
	document.domain = "shopinzon.com";

Object.assign(use.context, useAppContext);

// Init chat : on user authenticated
AuthManager.onUserChanged(user => {
	if (user)
		ChatManager.init(firebase, user.uid);
	else
		ChatManager.reset();
});

// force tablet to behave like a desktop
if (Environment.is.web) {
	const { style } = document.body;
	let setZoom;
	// if (Environment.browser.is.Firefox) {
	// style.position = "absolute";
	setZoom = zoom => {
		style.transform = `scale(${zoom})`;

		// original algorithm
		// const leftWidth = (window.innerWidth - window.innerWidth * zoom) / zoom;
		// const horizontalSideShift = leftWidth / 2;
		// const leftHeight = (window.innerWidth - window.innerWidth * zoom) / zoom;
		// const verticalSideShift = leftHeight / 2;

		// can be reduced to
		const fixRatio = (1 - zoom) / (zoom * 2);
		const horizontalSideShift = window.innerWidth * fixRatio;
		const verticalSideShift = window.innerHeight * fixRatio;

		style.right = style.left = `-${horizontalSideShift}px`;
		style.top = style.bottom = `-${verticalSideShift}px`;
	}
	// } else
	// 	setZoom = zoom => style.zoom = `${zoom * 100}%`;

	const resize = () => setZoom(getNecessaryZoom());
	style.position = 'absolute';
	window.addEventListener("resize", resize);
	resize(); // run at start
}

// membership automatic on authentication
if (window.shopId)
	AuthManager.onUserChanged(user => {
		if (user)
			Server.user.shop.membership.persist(window.shopId, true);
	});
