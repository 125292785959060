import Debug from "../Debug"
import is from "../utils/is"


let Classes;
export default Classes = {
	select(Class, classes){
		let item = selectClass(Class, classes);
		return item && item.value;
	},

	run(Class, classes){
		let item = selectClass(Class, classes);
		return item && item.run && item.run();
	},

	getParentOf(klass) {
		return Object.getPrototypeOf(klass);
	},

	getMethodsOf(Class, upToClass){
		let methods = {};

		// stop when class reach top class or null (prevent infinite loop)
		while (Class !== upToClass && Class !== MAX_PARENT_CLASS && Class){
			// add new methods
			Object.assign(
				methods,
				Object.getOwnPropertyNames(Class.prototype)
					.filter(key =>
						key !== "constructor"
						&& !( Object.getOwnPropertyDescriptor(Class.prototype, key ) || {} ).get // filter getters
						&& is(Class.prototype[key], Function) // functions only
						&& !methods[key] // filter override methods
						&& (!upToClass || !upToClass.prototype || !upToClass.prototype[key]) // filter methods from upToClass
					)
					.toObject(key => key, key => Class.prototype[key])
			);

			// move to parent class
			Class = Classes.getParentOf(Class);
		}

		return methods;
	},

	changeParentOf(Class, Parent){
		Class.prototype.__proto__ = Parent.prototype;
	},

	injectParent(Class, Parent){
		let GrandParent = this.getParentOf(Class);
		this.changeParentOf(Class, Parent);
		this.changeParentOf(Parent, GrandParent);
	},

	/**
	 * Make a class abstract. Must be called in the constructor of the parameter class.
	 */
	abstract : function (instance, klass) {
		Debug.assert(instance.constructor === klass,
			klass.name ?
				klass.name + " is an abstract class, you cannot instantiate it." :
				"You cannot instantiate an abstract class"
		);
	},

	setName(Class, name){
		if (Class.name !== name){
			try { // React-Native
				Class.name = name;
			} catch (e) { // React-Native-Web
				Object.defineProperties(Class, {
					name: {
						configurable: true,
						enumerable: true,
						writable: true,
						value: name,
					}
				});
			}
		}
	}
}

const MAX_PARENT_CLASS = Classes.getParentOf(class {});


function selectClass(Class, classes){
	if (!classes || classes.constructor !== Array)
		return;

	let defaultItem;
	let item = classes.find(item => {
		if (item && !item.class){
			defaultItem = item;
			return false;
		}

		return (
			item
			&& (
				item.class === Class
				|| item.class.prototype instanceof Class
			)
		)
	});

	if (!item) // select default item
		item = defaultItem;

	return item;
}
