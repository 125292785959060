export default {
	perfectPictureRatio: 1.09, // R = width / height

	color: {
		white: "white",
		black: "black",
		transparent: "transparent",
		lightgrey: "lightgrey",
		grey: "grey",
		mint: "#08f575",
		azure2: "#0bafee",

		order: {
			processingPayment: "#ff9901",
			waiting: "#607D8B",
			preparing: "#ed9005",
			ready: "#6ac259",
			finished: "#3dbea3",
			canceled: "#eb1b2b",
		},
	},
};
