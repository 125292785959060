export default function updateList(list, predicate, update){
	let hasUpdated = false;

	// update all items which pass the predicate
	list.reverseForEach((item, index) => {
		if (predicate(item)){
			hasUpdated = true;

			// update
			item = update(item, index);

			if (item)
				list.splice(index, 1, item);
			else // delete from list  if null
				list.splice(index, 1);
		}
	});

	return hasUpdated;
}

updateList.fromCache = async function(load, predicate, update, persist){
	let list = await load();
	if (list){
		let hasUpdated = updateList(list, predicate, update);
		if (hasUpdated)
			return persist(list);
	}
};
