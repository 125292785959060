export default function strictAPI(fn){
	return async function strictApiCall(...params){
		const response = await fn(...params);
		if (!response.ok){
			console.error(response.error);
			response.throw();
		}
		return response.content;
	};
}
