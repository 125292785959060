import useMemorise from "./useMemorise";
import {Objects} from "../library-js/utils";
import runUndo from "./runUndo";

export default function useInstances(map) {
	if (map?.set)
		throw "The 'set' key cannot be used in useInstances.";

	if (!map)
		map = {};

	const memory = useMemorise(
		() => {
			const memory = {callbacks: map};
			const undo = memory.undo = {};
			const instances = memory.instances = {
				set: Object.keys(map || {})
					.toObject(
						key => key,
						key => {
							return instance => {
								instances[key] = instance; // set instance

								const currentUndo = undo[key];
								delete undo[key]; // clean undo
								if (currentUndo)
									runUndo(currentUndo);


								const callback = memory.callbacks[key]; // get callbacks from memory to get the last one set
								if (callback instanceof Function) {
									const currentUndo = callback(instance);
									if (currentUndo instanceof Function)
										undo[key] = currentUndo;
								}
							};
						},
					)
			};

			return memory;
		},
		[],
		memory => Objects.valuesOf(memory.undo),
	);

	memory.callbacks = map;

	return memory.instances;
}
