import React from "react"
import use from "library-react/hook";
import ProductGrid from "@main/component/ProductGrid";
import {styles} from "@main/res";
import ScrollView from "library-react/component/ScrollView";
import Button from "@main/component/Button";
import View from "library-react/component/View/v2";
import Image from "library-react/component/Image";
import ArticleUI from "./ArticleUI";
import fonts from "@main/res/fonts";

export default function MobileUI({vShowcase, iterator, onSelected, title, ...props}) {
	const vProdshops = iterator.items.map(({vProdshop}) => vProdshop);

	const {Text, Spinner} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);
	props.contentContainerStyle = use.defaultStyle(props.contentContainerStyle, localStyles.content);

	return (
		<ScrollView {...props} >
			<View style={localStyles.showcase.layout}>
				<Image
					source={{uri: vShowcase?.portrait.url, width: 600}}
					resizeMode="cover"
					alt={title}
					style={localStyles.showcase.image}/>

				<Text style={localStyles.showcase.title}>
					{vShowcase?.title}
				</Text>
				<Text style={localStyles.showcase.description}>
					{vShowcase?.description}
				</Text>
			</View>
			<View style={localStyles.articles}>
				{
					vShowcase?.articles?.map((item, index) =>
							<ArticleUI
								key={index}
								item={item}
								style={localStyles.article}/>
					)
				}
			</View>
			{
				iterator &&
				<ProductGrid
					items={vProdshops}
					onSelected={onSelected}
					style={localStyles.items}/>
			}

			{
				Boolean(iterator?.items?.length) && (
					iterator.loading ? <Spinner style={localStyles.button}/> :
						!iterator.end &&
						<Button
							onPress={iterator.load}
							style={localStyles.button}>
							<Text style={localStyles.text}>
								{`Voir plus`}
							</Text>
						</Button>
				)
			}
		</ScrollView>
	);
}

const localStyles = {
	layout: {},

	content: {
		alignItems: styles.alignItems.center,
		paddingHorizontal: 20,
		marginVertical: 15,
	},

	showcase: {
		layout: {
			flexDirection: styles.flexDirection.column,
			marginBottom: 35,
		},
		image: {
			height: 400,
			marginBottom: 15,
		},
		title: {
			fontSize: 35,
			bold: true,
			marginBottom: 8,
			font: fonts.BebasNeue,
		},
		description: {
			fontSize: 15,
			lineHeight: 23,
		},
	},

	articles: {
		alignSelf: styles.alignSelf.stretch,
	},
	article: {},

	items: {
		marginTop: 50,
	},

	button: {
		alignSelf: styles.alignSelf.center,
		paddingHorizontal: 100,
		paddingVertical: 19,
		marginBottom: 46,
		marginTop: 10,
	},

	text: {
		fontSize: 20,
	},
};
