import { is } from "ramda";
import React from 'react';
import formatShopAddress from "../../library-js/app/model/utils/shop/formatShopAddress";
import { VProdshop } from "../../library-js/app/model/view";
import VShowcase from "../../library-js/app/model/view/general/VShowcase";
import assignParamsToUrl from "../../library-js/utils/URL/assignParamsToUrl";
import SocialModal from "./SocialModal";
// import firebase from "../../Firebase"
import firebase from "../../Firebase";
import smartJoin from "../../library-js/utils/string/smartJoin";
import parsePathname from "../../library-js/utils/URL/parsePathname";
import setPathParamsToUrl from "../../library-js/utils/URL/setPathParamsToUrl";
import urlParamsObject from "../../library-js/utils/URL/urlParamsObject";

export default function showSocialModal(app, shop, sharedContent, params) {
	const url = createShopWebUrl(shop, sharedContent, params);
	showSocialModalForUrl(app, url);
}

/**
 * Follows https://firebase.google.com/docs/dynamic-links/create-manually
 */
export function createDynamicUrlForShop(shop) {
	const link = `https://app.shopinzon.com/shop/${shop.id}`; // deep link
	const shopWebUrl = shop.domain ? `https://${shop.domain}.shopinzon.com` : undefined;

	// --- Android ---
	const apn = "com.shopinzon.app";
	const afl = shopWebUrl;


	// --- iOS ---
	const ibi = "com.shopinzon.app";
	const isi = "1206940503"; // TODO add iOS app store ID when deployed
	const ifl = shopWebUrl;

	// --- Web ---
	const ofl = shopWebUrl;

	// --- Social ---
	const st = format([
		shop.name,
		formatShopAddress(shop),
	]);

	const sd = format([`Boutique Shopinzon`, shop.description.slice(0, 100)]);
	const si = shop.galleryArray?.[0] || shop.logoUrl || shop.gallery?.[0] || shop.logo;

	// ---
	const params = { link, apn, afl, ibi, ifl, st, sd, si, ofl };
	return assignParamsToUrl(params, 'https://shopinzonapp.page.link');
}

/**
 * Follows https://firebase.google.com/docs/dynamic-links/create-manually
 */
export function createDynamicUrlForVProdshop(vProdshop) {
	const product = vProdshop.vProduct.product;
	const productId = product.id;
	const shop = vProdshop.vShop.shop;
	const shopId = shop.id;
	const price = vProdshop.prodshop.price;

	const link = assignParamsToUrl({ shopId }, `https://app.shopinzon.com/product/${productId}`); // deep link
	const webUrl = shop.domain ? `https://${shop.domain}.shopinzon.com/product/${productId}` : undefined;

	// --- Android ---
	const apn = "com.shopinzon.app";
	const afl = webUrl;


	// --- iOS ---
	const ibi = "com.shopinzon.app";
	const isi = "1206940503"; // TODO add iOS app store ID when deployed
	const ifl = webUrl;

	// --- Web ---
	const ofl = webUrl;

	// --- Social ---
	const st = product.fullTitle;

	const sd = format([price.formatted, product.description?.slice(0, 100)]);
	const si = product.gallery?.[0] || shop.logo;

	// ---
	const params = { link, apn, afl,ibi, ifl, st, sd, si, ofl };
	return assignParamsToUrl(params, 'https://shopinzonapp.page.link');
}

const format = smartJoin(' • ');

export async function shortenDynamicLink(longDynamicLink) {
	const endpoint = assignParamsToUrl(
		{ key: firebase.app().options.apiKey },
		'https://firebasedynamiclinks.googleapis.com/v1/shortLinks'
	);

	const response = await fetch(endpoint, {
		method: "POST",
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ longDynamicLink }),
	});

	if (!response.ok) throw new Error(`${response.status} ${response.statusText}`);

	const { shortLink } = await response.json();
	console.log({ shortLink });
	return shortLink;
}

export function showSocialModalForUrl(app, url) {
	app.openModal(<SocialModal url={url} />);
}

export function createShopWebUrl(shop, sharedContent, params) {

	if (sharedContent instanceof VProdshop)
		return buildUrl(shop.domain, 'product', sharedContent.product.id, params);

	else if (sharedContent instanceof VShowcase)
		return buildUrl(shop.domain, 'showcase', sharedContent.showcase.id, params);

	else if (is(String, sharedContent))
		return buildUrl(shop.domain, sharedContent);

	else if (Boolean(shop?.domain)){
		const {pathname} = window.location;
		return buildUrl(shop.domain, parsePathname(pathname), undefined, urlParamsObject(window.location.href));
	}

	else
		return window.location.href;
}

function buildUrl(domain, page, id, params) {
	return setPathParamsToUrl({
		path: [page, id],
		params,
	}, `https://${domain}.shopinzon.com`);
}

