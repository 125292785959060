import React from "react"
import View from "../View";
import Text from "../Text";
import useDefaultStyle from "../../hook/useDefaultStyle";
import {strings, styles} from "../../res"
import AuthManager from "../../library-js/AuthManager";

export default function ErrorView ({error = strings.onError, onLoadClicked, showDisconnectOption, buttonTextStyle, buttonText = strings.reload, ...props}){
	props.style = useDefaultStyle(props.style, styles.center);

	return (
		<View {...props}>
			<Text>
				{error}
			</Text>

			{
				onLoadClicked &&
				<View
					onPress={onLoadClicked}
					style={localStyles.button}>
					<Text style={[localStyles.buttonText, buttonTextStyle]}>
						{buttonText}
					</Text>
				</View>
			}

			{
				showDisconnectOption &&
				AuthManager.user &&
				<View
					onPress={AuthManager.signOut}
					style={localStyles.button}>
					<Text style={[localStyles.disconnect, buttonTextStyle]}>
						{`Se deconnecter`}
					</Text>
				</View>
			}


		</View>
	);
}

const localStyles = {
	button: {
		...styles.center,
		marginTop: 10,
		padding: 10,
	},

	buttonText: {
		color: "blue",
	},

	disconnect: {
		color: "red",
	}
};
