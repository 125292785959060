import React from "react"
import use from "../../../hook"
import {styles} from "../../../res"
import Text from "../../../component/Text";

function ErrorText({display, ...props}) {
	props.style = use.defaultStyle(props.style, {
		color: 'red',
		textAlign: styles.textAlign.center,
		fontSize: 12,
		marginVertical: 10,
		opacity: display ? 1 : 0,
	}, [Boolean(display)]);

	return <Text {...props}/>;
}

export default ErrorText;
