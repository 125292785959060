import React from "react"
import { View as RNView } from "react-native"
import use from "library-react/hook"
import { styles } from "../res"
import GalleryWithArrow from "../../library-react/component/GalleryWithArrow";
import Icon from "./Icon/v2";

function GalleryModal({ items, startIndex = 0, close, ...props }) {
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<RNView {...props}>
			<GalleryWithArrow
				items={items}
				indexOnMounted={startIndex}
				arrowsStyle={localStyles.arrow}
				style={styles.fit}
			/>

			{
				close &&
				<Icon
					name="MaterialIcons/clear"
					onPress={close}
					size={50}
					style={localStyles.close} />
			}

		</RNView>
	);
}

export default React.memo(GalleryModal);


const localStyles = {
	layout: [
		styles.fit,
		{backgroundColor: "rgba(0, 0, 0, .8)"}
	],

	arrow: {
		size: 70,
		color: styles.color.greyish,
		accent: styles.color.white,
		padding: 30,
	},

	close: {
		padding: 30,
		color: styles.color.white,
		...styles.absolute({ top: 0, right: 0 }),
	}
};
