import Enum from "./class/Enum"
import Debug from "./Debug";


/**
 * Contains:
 * <ul>
 *     <li>{@link current} : Contains the current platform. Values is one of the {@link Environment.values}.</li>
 *     <li>{@link isMobile} : True if {@link current} is {@link Environment.ios} or {@link Environment.android}.</li>
 *     <li>{@link select}(Object) : Select a value according to the current.</li>
 *     <li>{@link run}(Object<Function>) : Run the function according to the current.</li>
 * </ul>
 */
const Environment = new Enum(["web", "android", "ios", "windows", "vr"]);
export default Environment;

Environment.domains.mobile = Environment.domains.native = [Environment.android, Environment.ios];
Environment.domains.react = [Environment.android, Environment.ios]; // web can be included below

Environment.Browser = new Enum("Opera", "Firefox", "Safari", "IE", "Edge", "Chrome", "other");


Environment.select = function (map) {
	return this.current ? this.current.select(map) : map.default;
};
Environment.run = function (map) {
	return this.current ? this.current.run(map)
		: map.default && map.default();
};

Object.defineProperties(Environment, {
	isMobile: {
		configurable: true,
		get: function () {
			return this.domains.mobile.some(env => env === Environment.current);
		}
	},

	crawling: {
		configurable: true,
		get: function () {
			// return true; // for simulation
			return Boolean(
				this.current === this.web
				&& window.bhm?.crawling
			);
		}
	},

	is: {
		configurable: true,
		get: function () {
			return this.current.is;
		}
	}
});



// ---- Get current ----
try {
	// check if react native
	let RN = Environment.ReactNative = require("react-native");
	Environment.current = Environment.from(RN.Platform.OS); // set current platform

	Environment.run({
		web() { // current web app is running in react environment
			Environment.domains.react.push(Environment.web);
		}
	});
}
catch (e) {
	try {
		// check if web
		if (window)
			Environment.current = Environment.web;
	} catch (e) { }
}


Environment.run({
	web() {
		// define company namespace
		window.bhm = window.bhm || {};
		window.bhm.Environment = Environment;
		window.bhm.Debug = Debug;

		// Define browser
		Environment.browser =
			// Opera 8.0+
			window.opera ? Environment.Browser.Opera :

				// Firefox 1.0+
				window.InstallTrigger ? Environment.Browser.Firefox :

					// Safari 3.0+
					window.safari ? Environment.Browser.Safari :

						// Internet Explorer 6-11
						document.documentMode ? Environment.Browser.IE :

							// Edge 20+
							window.StyleMedia ? Environment.Browser.Edge :

								// Chrome 1 - 71
								window.chrome ? Environment.Browser.Chrome :

									// other
									Environment.Browser.other;

		// Define OS
		let osName = require('platform').os.family?.toLowerCase();
		if (osName == 'OS X') osName = 'macOS';

		const isMobileOS = ['android', 'ios'].some(os => osName.includes(os));
		Environment.OS = {
			value: osName,
			is: {
				[osName]: true,
				mobile: isMobileOS,
				desktop: !isMobileOS,
			},
		};
	}
});

Environment.os = Environment.run({
	web() {
		const userAgent = navigator.userAgent.toLowerCase();

		if (userAgent.includes("win"))
			return "windows";

		if (userAgent.includes("mac"))
			return "macos";

		if (userAgent.includes("linux"))
			return "linux";

		if (userAgent.includes("android"))
			return "android";

		if (userAgent.includes("like mac"))
			return "ios";
	}
});
