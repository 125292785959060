import React from "react";
import useDefaultStyle from "../hook/useDefaultStyle";
import { styles } from "../res";
import Text from './Text';

function DefaultToast({ ...props }) {
	// const { Text } = useTheme(); Default context component should never use the theme or it will make a cycle-import
	props.style = useDefaultStyle(props.style, localStyles.layout);
	return <Text {...props} />;
}

export default React.memo(DefaultToast);


const localStyles = {
	layout: {
		backgroundColor: 'white',
		...styles.newShadow(0, 7, 10, .2),
		padding: 20,
	},
};
