import React from "react"
import { ScrollView } from "react-native"
import use from "library-react/hook"
import AccountTabs from "./AccountTabs"
import MyInfos from "./MyInfos"

export default function AccountUI_mobile(
	{
		renders,
		onOrdersClicked,
		onAccountClicked,
		...props
	}) {

	props.style = use.defaultStyle(props.style, localStyles.layout);
	props.contentContainerStyle = localStyles.content;

	return (
		<ScrollView {...props}>

			<AccountTabs
				onAccountClicked={onAccountClicked}
				onOrdersClicked={onOrdersClicked}
				style={localStyles.tabs} />
			<MyInfos />

		</ScrollView>
	);
}

const localStyles = {
	layout: {
		flex: 1,
	},

	content: {
		padding: 20,
		paddingBottom: 70,
	},

	tabs: {
		marginBottom: 30,
	}
};
