import {Public as Server} from "../../../Server";
import Category from "./Category";

const ShopCategoryTree = {
	/**
	 * @type {Promise<Category[]>}
	 */
	initiating: null, // initiating promise

	/**
	 * @type {Promise<Category[]>}
	 */
	ready: null, // ready promise

	/**
	 * @type {Promise<Category[]>}
	 */
	get initPromise() {
		return this.initiating || this.ready
	},

	/**
	 * @returns {Promise<Category[]>}
	 */
	init(force = false) {
		const done = this.initiating || (!force && this.ready);
		if (done)
			return done;

		// ---

		async function startInit() {
			const response = await Server.shop.loadCategories();
			if (!response.ok)
				response.throw();

			const categories = response.content;
			for (const category of categories) {
				const {id, parentId} = category;

				dictionaryById[id] = category;

				if (!parentId) // category is root if it doesn't have parent
					roots.push(category);
				else {
					if (!(parentId in dictionaryByParentId)) // This category is the first of its parent
						dictionaryByParentId[parentId] = [];

					dictionaryByParentId[parentId].push(category);
				}
			}
		}

		// ---

		// reset
		this.ready = null;
		roots = [];
		dictionaryById = {};
		dictionaryByParentId = {};

		// start
		const promise = this.initiating = startInit();
		promise.then(() => this.ready = promise) // success
			.finally(() => this.initiating = null); // clean

		return promise;
	},

	/**
	 * @param {number} id
	 * @returns {Promise<Category>}
	 */
	load(id) {
		return this.init().then(() => this.get(id));
	},

	/**
	 * @returns {Category[]}
	 */
	getRoots() {
		return roots.copy();
	},

	/**
	 * @param {number} id
	 * @returns {Category}
	 */
	get(id) {
		return dictionaryById[id];
	},

	/**
	 * @param {number} id
	 * @returns {Array<Category>}
	 */
	getChildrenOf(id) {
		return dictionaryByParentId[id];
	},
};


/**
 * @param {number} id
 * @returns {Promise<Category>}
 */
ShopCategoryTree.get.async = async function (id) {
	await ShopCategoryTree.initPromise;
	return ShopCategoryTree.get(id);
};

/**
 * @param {number} id
 * @returns {Promise<Array<Category>>}
 */
ShopCategoryTree.getChildrenOf.async = async function (id) {
	await ShopCategoryTree.initPromise;
	return ShopCategoryTree.getChildrenOf(id);
};

/**
 * @returns {Promise<Category[]>}
 */
ShopCategoryTree.getRoots.async = async function () {
	await ShopCategoryTree.initPromise;
	return ShopCategoryTree.getRoots();
};

export default ShopCategoryTree;


// ----------

let roots = [];
let dictionaryById = {};
let dictionaryByParentId = {};
// let dictionaryByPath = {};

// ----- setup Category getters ----
Object.defineProperties(Category.prototype, {
	parent: {
		configurable: true,
		get: function () {
			return ShopCategoryTree.get(this.parentId);
		}
	},

	children: {
		configurable: true,
		get: function () {
			return ShopCategoryTree.getChildrenOf(this.id)?.copy() || [];
		}
	},
});
