import config from "../config";
import Platform from "../../../../../Platform";
import IDs from "./IDs";

export default {
	ROOT: "chat",
	VERSION: 3,

	get prefix(){
		return `${this.ROOT}/${this.VERSION}`;
	},

	get app(){
		return `${this.prefix}/app/${config.id}`;
	},

	get list(){
		return `${this.prefix}/list/${config.id}`;
	},

	oneChat(interlocutorId){
		return `${this.list}/${interlocutorId}`;
	},

	chat(interlocutorId){
		const path = `${this.prefix}/chat/${IDs.chat(interlocutorId)}`;
		return {
			infos: `${path}/infos/${Platform.is.user ? "user" : "shop"}`,
			timeline: `${path}/timeline`
		};
	},
}
