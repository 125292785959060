import latinize from "latinize";
import { filter, gt, has, pipe, T, toLower, when, __ } from "ramda";
import Search from "../Search";
import Loadable from "../utils/Loadable";
import Objects from "../utils/Objects";
import Server from "./Server";

export default function generateShopCupboardLoadable(shopId, config) {
	if (shopId)
		return new Loadable(() =>
			Promise.all([
				// from server for sections order
				Server.public.shop.getSections(shopId)
					.then(response => {
						if (response.ok)
							return response.content;
						throw response.toJSON();
					}),

				// from algolia for quantities
				Search.index.prodshop.getIterator({
					inShop: true,
					shopId,
					available: when(has('available'), T)(config?.available),
				})
					.getMetadatas()
					.then(response => {
						if (response.ok)
							return pipe(
								filter(gt(__, 0)),
								obj => Objects.map(obj, null, pipe(latinize, toLower)),
							)(response.content?.facets?.sections || {});

						throw response;
					})
			]).then(([cupboard, quantities]) => {
				// fusion
				cupboard.getAll()
					.forEach(section => {
						// set quantities
						section.quantity = quantities?.[section];
						// remove empty sections
						if (!(section.quantity > 0) && !config?.keepEmptySections)
							cupboard.delete(section);
					});

				return cupboard;
			})
		);
}
