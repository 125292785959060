import React from "react"
import { View as RNView } from "react-native"
import MessageInput from "./MessageInput"
import Timeline from "./Timeline"
import use from "../../../../hook";
import { styles } from "../../../../res";
import useApp from "../../../../app/useApp";
import SearchView from "./SearchView";
import Platform from "../../../../library-js/Platform";
import { ChatManager } from "../../../../library-js/app/model/chat";

export default React.memo(
	React.forwardRef(
		function ChatUI(
			{
				interlocutorId,
				interlocutorLoadable,
				visible,
				onOrderClicked,
				onOrderItemClicked,
				onProductClicked,
				onReservationClicked,
				selectProduct,
				textInputStyle,
				hidePrice,
				...props
			},
			setRef
		) {

			const app = useApp();

			const displaySearch = use.callback(() => {
				const [promise, passOver] = Promise.external();
				let close;
				const resolve = (data) => {
					close();
					passOver(data);
				};

				close = app.openModal(
					<SearchView
						onItemSelected={resolve}
						shopId={Platform.select({
							user: interlocutorId,
							retailer: ChatManager.id,
						})}
						goBack={resolve}
					/>
				);

				return promise;
			});


			props.style = use.defaultStyle(props.style, localStyles.layout);
			return (
				<RNView {...props}>
					<Timeline
						interlocutorId={interlocutorId}
						interlocutorLoadable={interlocutorLoadable}
						visible={visible}
						onOrderClicked={onOrderClicked}
						onOrderItemClicked={onOrderItemClicked}
						onProductClicked={onProductClicked}
						onReservationClicked={onReservationClicked}
						hidePrice={hidePrice}
					/>

					<MessageInput
						ref={setRef}
						visible={visible}
						textInputStyle={textInputStyle}
						interlocutorId={interlocutorId}
						selectProduct={selectProduct}
						displaySearch={displaySearch}
						onProductClicked={onProductClicked}
					/>
				</RNView>
			);
		}
	)
);

const localStyles = {
	layout: {
		flex: 1,
		backgroundColor: styles.color.white,
	},
};
