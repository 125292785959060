import React from "react"
import {View as RNView} from "react-native"
import use from "../../../../../../hook"
import {strings, styles} from "../../../../../../res"
import LoadingPlaceholder from "../../../../../../component/LoadingPlaceholder";
import Image from "../../../../../../component/Image";
import Text from "../../../../../../component/Text";
import View from "../../../../../../component/View/v2";

function ProductCard({item: vProdshop, onSelected, ...props}) {
	const {product, finalPrice, oldPrice, shop} = vProdshop || {};

	props.onPress = use.callback(onSelected && vProdshop &&
		function select() {
			onSelected(vProdshop);
		},
		[onSelected, vProdshop],
	);

	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<View {...props}>
			{/* picture */}
			<LoadingPlaceholder
				disabled={vProdshop}
				style={localStyles.pictureWrapper}>
				<Image
					source={product?.mainPicture}
					resizeMode="cover"
					style={styles.fit}
				/>
			</LoadingPlaceholder>

			{/* main infos */}
			<RNView style={localStyles.infos}>
				{/* title */}
				<LoadingPlaceholder
					disabled={vProdshop}
					style={localStyles.titleWrapper}>
					<Text
						numberOfLines={1}
						style={localStyles.title}>
						{product?.title || strings.invisibleChar}
					</Text>
				</LoadingPlaceholder>

				{/* subtitle */}
				<Text
					numberOfLines={1}
					style={localStyles.subtitle}>
					{product?.subtitle || strings.invisibleChar}
				</Text>

				{/* prices */}
				{
					!shop?.hiddenPrice &&
					<RNView style={localStyles.footer}>
						{/* old price */}
						{
							oldPrice &&
							<Text style={localStyles.oldPrice}>
								{oldPrice?.value}
								<Text style={localStyles.currency}>
									{oldPrice?.symbol}
								</Text>
							</Text>
						}

						{/* main price */}
						<RNView style={localStyles.price.layout(oldPrice)}>
							<Text style={localStyles.price.text(oldPrice)}>
								{finalPrice?.value || strings.invisibleChar}
								<Text style={localStyles.currency}>
									{finalPrice?.symbol}
								</Text>
							</Text>
						</RNView>
					</RNView>
				}
			</RNView>
		</View>
	);
}

export default React.memo(ProductCard);


const localStyles = {
	layout: {
		backgroundColor: styles.color.white,
		borderRadius: 8,
		...styles.newShadow(0, 0, 5, .15),
	},

	pictureWrapper: {
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		overflow: styles.overflow.hidden,
		height: 71,
	},

	infos: {
		paddingVertical: 5,
		paddingHorizontal: 4,
	},

	titleWrapper: {
		borderRadius: 10,
		minWidth: 80,
		alignSelf: styles.alignSelf.flexStart,
	},

	title: {
		...styles.text(9, '#1e2432', undefined, undefined, true),
	},

	subtitle: {
		...styles.text(10, '#1e2432', undefined, undefined, true),
		marginBottom: 6,
	},

	footer: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
		justifyContent: styles.justifyContent.flexEnd,
	},

	price: {
		layout: styles.static.bool({
				paddingVertical: 2,
				paddingHorizontal: 5,
			},
			{
				borderRadius: 12,
				backgroundColor: '#e95454',
			},
			{}
		),

		text: styles.static.bool({
				fontSize: 10,
				bold: true,
			},
			{color: styles.color.white},
			{color: '#1e2432'},
		),
	},

	oldPrice: {
		marginRight: 8,
		textDecorationLine: styles.textDecorationLine.lineThrough,
		...styles.text(9, '#1e2432', undefined, undefined, true),
	},

	currency: {
		fontSize: 7,
	},
};
