import React from "react"
import {PixelRatio} from "react-native"
import {Medias} from "../../library-js/app/model/media";
import {is} from "../../library-js/utils";
import use from "../../hook"
import {parallel} from "../../library-js/utils/function";
import {pipe} from "ramda";
import CoreComponent from "./core-component"

/**
 * @param {any}
 */
function Image({autoThumbnail, shouldNotResize, ...props}) {
	let [level, setLevel] = use.state();

	// convert source format
	props.source = use.memo(source => {
		// Accept media Image as source
		if (is(source, Medias.Image))
			return {uri: source.url};

		// and as uri
		if (source && is(source.uri, Medias.Image))
			return {uri: source.uri.url};

		return source;
	}, [props.source]);

	const originalSource = props.source;


	// resize image link if possible
	if (!shouldNotResize && Medias.Image.canResizeFromUrl(props.source?.uri)) {
		if (props.source.width) // width forced
			level = getLevel(props.source.width);
		else if (!level)  // should listen layout
			props.onLayout = parallel([props.onLayout,
				pipe(
					event => event.nativeEvent.layout.width,
					getLevel,
					setLevel
				)
			]);


		let width = LEVELS[level];
		if (width) {
			props.source = {...props.source};
			props.source.uri = Medias.Image.getUrlWithSize(props.source.uri, PixelRatio.getPixelSizeForLayoutSize(width));

			// Border color by level
			// props.style = ComponentUtils.defaultStyle({borderWidth: 1, borderColor: colors[level]}, props.style);
		} else
			props.source = autoThumbnail ?  // set min level image while waiting for layout
				{uri: Medias.Image.getUrlWithSize(props.source.uri, LEVELS.first)}
				: undefined; // otherwise wait for layout size
	}

	if (is(props.source, Object)) {
		if (props.source === originalSource)
			props.source = {...originalSource};
		delete props.source.width;
		delete props.source.height;
	}

	return <CoreComponent key={Boolean(originalSource?.uri)} {...props}/>;
}

export default React.memo(Image);

const LEVELS = [/*50*/ 100, /*300*/500, 1500, 2500];

function getLevel(width) {
	if (isNaN(width))
		return null;

	width = Number(width);

	let level = LEVELS.findIndex(levelWidth => width <= levelWidth);
	if (level == -1) {
		// bigger than any level, take the highest level
		level = LEVELS.lastIndex;
		console.warn("There's a image component which load the biggest possible resolution.");
	}


	return level;
}

const colors = ["black", "purple", "blue", "green", "yellow", "orange", "red"];
