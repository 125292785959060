import React from "react"
import OrderGallery from "./OrderGallery";
import {styles} from "../../../../res";
import Text from "../../../../component/Text";
import View from "../../../../component/View";
import OrderStateView from "../../../../component/OrderStateView";
import use from "../../../../hook";

export default React.memo(
	function OrderView({gallery, price, state, receptionMean, children, ...props}) {
		gallery = use.memo(
			() => gallery instanceof Array ? gallery : [gallery],
			[gallery]
		);

		props.style = use.defaultStyle(props.style, localStyles.layout);

		return (
			<View {...props}>
				<OrderGallery gallery={gallery}/>

				<Text
					numberOfLines={1}
					style={localStyles.price}>
					{price?.formatted}
				</Text>

				{
					Boolean(state) &&
					<OrderStateView
						state={state}
						receptionMean={receptionMean}
						{...localStyles.state}/>
				}

				{children}
			</View>
		);
	}
);

const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
		// alignItems: styles.alignItems.center, // TODO check https://github.com/facebook/react-native/issues/27573
		justifyContent: styles.justifyContent.spaceBetween,
	},

	price: {
		bold: true,
		fontSize: 14,
		color: '#1e2432',
		paddingHorizontal: 5,
	},

	state: {
		innerStyles: {
			text: {textAlign: styles.textAlign.left},
		},

		style: {
			flexShrink: 1,
			flexBasis: 143,
		}
	}
};
