import React from "react"
import { View as RNView } from "react-native"
import use from "library-react/hook";
import MobileUI from "./MobileUI";
import DesktopUI from "./DesktopUI";
import Server from "../../../library-react/library-js/app/Server";
import Spinner from "../../component/Spinner";
import { useRoute } from "@react-navigation/native";
import { styles } from "../../res";
import ErrorView from "../../component/ErrorView";
import useShowcases from "../../hook/useShowcases";
import { useStaticShopCategoryLoadable } from "../../hook/useStaticShopCategory";
import { useStaticShopTagsNamesLoadable } from "../../hook/useStaticShopTags";
import { pathEq } from "ramda";
import SEO, { loadableToStatus } from "library-react/component/SEO";
import { paths } from "@main/links";
import pageTitle from "@main/utils/pageTitle";
import toReadableTags from "../../utils/toReadableShopTags"
import { mergeStatus, optionalLoadableToStatus } from "../../../library-react/component/SEO";
import buildSeoParams from "library-js/utils/buildSeoParams";
import toReadableSection from "@main/utils/toReadableSection";
import pen from "library-js/utils/function/paragraph";
import toReadableShopTags from "@main/utils/toReadableTags";

export default function ShowcaseUI({ ...props }) {
	const route = useRoute();
	const { id } = route.params || {};

	const { vShop } = use.context.infos();
	const shop = vShop?.shop;

	const categoryLoadable = useStaticShopCategoryLoadable();
	const category = categoryLoadable.value;
	const shopTagsNamesLoadable = useStaticShopTagsNamesLoadable();
	const shopTagsNames = shopTagsNamesLoadable.value;

	const loadable = useShowcases.loadable();
	const { value: list, loading, load } = loadable;
	const iterator = props.iterator = use.infiniteIterator(() =>
		Server.public.showcase.shop.getItems.getIterator(id, vShop.id),
		[id, vShop.id],
	);

	window.pathEq = pathEq;
	const vShowcase = props.vShowcase = (
		list?.find(vShowcaseShop => vShowcaseShop.vShowcase.showcase.id == id)
		|| iterator.items?.first?.vShowcaseShop
	)?.vShowcase;

	const status = mergeStatus([
		loadableToStatus(loadable),
		optionalLoadableToStatus(categoryLoadable),
		optionalLoadableToStatus(shopTagsNamesLoadable),
	])
	const optimizationParams = props.optimizationParams = status === 200 ?
		buildSeoParams({
			keywords: [
				vShowcase?.title,
				category?.name,
				shopTagsNames,
				shop.address.city
			]
		})
		: null;

	const title = props.title = pageTitle([
		vShowcase?.title,
		category?.name,
		toReadableTags(shopTagsNames),
		shop?.address.locality,
	]);

	const display = use.context.display();
	return (
		<>
			<SEO
				status={status}
				canonical={`${paths.showcase}/${id}`}
				optimizationParams={optimizationParams}
				image={vShowcase?.banner}
				title={title}
				description={vShowcase?.description}
			/>

			{
				vShowcase ? (
					display.is.mobile ?
						<MobileUI {...props} /> :
						<DesktopUI {...props} />
				) :
					<RNView style={[styles.center, { flex: 1 }]}>
						{
							(loading || iterator.loading) ? <Spinner /> :
								<ErrorView retry={load} />
						}
					</RNView>
			}
		</>
	);
}
