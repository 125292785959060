import React from "react"
import {strings, styles} from "../../res"
import use from "../../hook"

function OpenableText({numberOfLines, maxChars, children: text = "", displayToggle, toggleStyle, ...props}) {
	const [opened, setOpened] = use.state(false);
	text = text || "";
	const cutText = text.slice(0, maxChars) + "...";
	const isOk = (cutText.length >= text.length);
	if (displayToggle === false)
		displayToggle = toggleNotDisplayed;
	else if (!(displayToggle instanceof Function))
		displayToggle = defaultDisplayToggle;

	const {Text} = use.theme()

	return (
		<Text
			onPress={!isOk ? (() => setOpened(!opened)) : undefined}
			numberOfLines={opened ? undefined : numberOfLines} {...props}>
			{(opened || isOk) ? text : cutText}

			{
				!isOk &&
				<>
					<Text>{'  '}</Text>
					<Text
						style={[{textAlign: styles.textAlign.center}, toggleStyle].flat()}>
						{displayToggle(opened)}
					</Text>
				</>
			}
		</Text>
	);
}

export default React.memo(OpenableText);

const defaultDisplayToggle = opened => opened ? strings.less : strings.more;

const toggleNotDisplayed = () => '';
