export const desktopToastConfig = {
	position: ({layouts, index}) => {
		return layouts.slice(index + 1)
			.filter(Boolean)
			.reverse()
			.reduce((sum, {height}) => sum + height, 0);
	},

	cardStyle: (openingAv, position) => {
		return {
			position: 'absolute',
			right: 0,
			top: 10,
			left: "70%",
			opacity: openingAv,

			transform: [
				{translateY: position},
				{
					translateY: openingAv.interpolate({
						inputRange: [0, 1],
						outputRange: [-100, 0],
					})
				},
			]
		};
	},
}

export const mobileToastConfig = {
	position: ({layouts, index}) => {
		return -layouts.slice(index + 1)
			.filter(Boolean)
			.reverse()
			.reduce((sum, {height}) => sum + height, 0);
	},

	cardStyle: (openingAv, position) => {
		return {
			position: 'absolute',
			right: 0,
			bottom: 0,
			left: 0,
			opacity: openingAv,

			transform: [
				{translateY: position},
				{
					translateY: openingAv.interpolate({
						inputRange: [0, 1],
						outputRange: [100, 0],
					})
				},
			]
		};
	},
}
