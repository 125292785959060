import React from "react"
import {View as RNView} from "react-native"
import use from "../../../../hook";
import Text from "../../../../component/Text";
import {fonts, styles} from "../../../../res";
import Image from "../../../../component/Image";
import View from "../../../../component/View/v2";
import MainPrice from "../../../../component/price/MainPrice";
import OldPrice from "../../../../component/price/OldPrice";

function ProductPreview_render(
	{
		product,
		price,
		oldPrice,
		size,
		children,
		innerStyles = {
			image: null,
			title: null,
			subtitle: null,
			price: null,
			oldPrice: null,
			priceLayout: null
		},
		hidePrice,
		...props
	}
) {
	const imageStyle = use.defaultStyle(innerStyles?.image, localStyles.image);
	const titleStyle = use.defaultStyle(innerStyles?.title, localStyles.title);
	const subtitleStyle = use.defaultStyle(innerStyles?.subtitle, localStyles.subtitle);
	const priceStyle = use.defaultStyle(innerStyles?.price, localStyles.price);
	const oldPriceStyle = use.defaultStyle(innerStyles?.oldPrice, localStyles.oldPrice);

	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<View {...props}>
			<Image
				source={product.gallery.first}
				resizeMode="cover"
				style={imageStyle}/>

			<RNView style={localStyles.infos}>
				{/*	title */}
				<Text
					numberOfLines={1}
					style={titleStyle}>
					{product.title}
				</Text>

				{/* subtitle */}
				<Text
					numberOfLines={1}
					style={subtitleStyle}>
					{product.subtitle}
				</Text>

				<RNView style={localStyles.footer}>
					<Text
						numberOfLines={1}
						style={[localStyles.size, ...subtitleStyle]}>
						{size}
					</Text>

					{
						!hidePrice &&
						<RNView style={localStyles.prices}>
							{
								oldPrice &&
								<OldPrice price={oldPrice} style={oldPriceStyle}/>
							}

							<MainPrice
								price={price}
								discounted={oldPrice}
								textStyle={priceStyle}
								style={innerStyles?.priceLayout}/>

						</RNView>
					}

				</RNView>
			</RNView>

			{children}
		</View>
	);
}

const ProductPreview = React.memo(ProductPreview_render);
export default ProductPreview;
const WIDTH = ProductPreview.WIDTH = 131;

const localStyles = {
	layout: {
		backgroundColor: styles.color.white,
		...styles.newShadow(0, 0, 5, .15),
		width: WIDTH,
		borderRadius: 8,
	},

	image: {
		height: WIDTH / styles.perfectPictureRatio,
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
	},

	title: {
		...styles.text(9, '#1e2432', fonts.Poppins, undefined, true),
	},

	subtitle: {
		...styles.text(8, '#1e2432', fonts.Poppins, undefined, true),
	},

	infos: {
		padding: 5,
	},

	footer: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
	},

	size: {
		flex: 1,
	},

	prices: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
	},

	price: {
		fontSize: 12,
	},

	oldPrice: {
		marginRight: 9,
	},
};
