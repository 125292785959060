import React from "react"
import use from "library-react/hook"
import DesktopUI from "./desktop"
import MobileUI from "./mobile"

function ProfileUI({...props}) {

	const display = use.context.display();

	return (
			display.is.mobile ?
				<MobileUI {...props} /> :
				<DesktopUI {...props} />
	);
}

export default React.memo(ProfileUI);
