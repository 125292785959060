import React from "react"
import {View as RNView} from "react-native"
import use from "library-react/hook"
import {styles} from "../../../res"
import Icon from "library-react/component/Icon/v2";
import useAuth from "library-react/hook/useAuth";
import ChatUI from "./ChatUI";
import Image from "library-react/component/Image/Image";
import images from "library-react/res/image";
import GradientView from "library-react/component/GradientView";
import AuthenticateView from "@main/component/AuthenticateView";

function ChatWindow({close, onProductClicked, onOrderClicked, onOrderItemClicked, ...props}, refProp) {
	const user = useAuth.user();
	const {shop} = use.context.infos().vShop;
	const display = use.context.display();

	// // auto un-zoom
	use.subscribe(
		refresh => !display.is.mobile && window.on("resize", refresh),
		[display.is.mobile]
	);
	const reverseZoom = 1;
	// const appZoom = getNecessaryZoom();
	// const reverseZoom = 1 + (1.1 - appZoom);

	const zoom = `${reverseZoom * 100}%`;
	const width = !display.is.mobile ? 350 : undefined;

	const {Text} = use.theme();

	props.style = use.defaultStyle(
		props.style,
		[localStyles.layout, {zoom, width}],
		[reverseZoom, display]
	);

	return (
		<RNView {...props}>
			{
				user &&
				<GradientView
					start={{x: 0, y: 0}}
					end={{x: 1, y: 0}}
					colors={['#6200ea', '#1976d2']}
					style={localStyles.header.layout}>

					<RNView style={localStyles.header.user.layout}>
						<Image source={shop.logo}
							   defaultSource={images.interlocutor}
							   resizeMode="cover"
							   style={localStyles.header.user.image}/>

						<Text
							numberOfLines={1}
							style={localStyles.header.user.title}>
							{`Bonjour ${user.displayName}`}
						</Text>
					</RNView>

					{/*Close icon*/}
					{
						display.is.mobile &&
						<Icon name="EvilIcons/close"
							  size={20}
							  onPress={close}
							  style={localStyles.header.close}
						/>
					}
				</GradientView>
			}

			{
				user ?
					<ChatUI
						ref={refProp}
						onOrderClicked={onOrderClicked}
						onOrderItemClicked={onOrderItemClicked}
						onProductClicked={onProductClicked}
						textInputStyle={localStyles.textInputStyle}
						style={localStyles.main.chat}
					/> :
					// if no user, invite to authenticate
					<AuthenticateView
						style={localStyles.main.auth}
					/>
			}
		</RNView>
	);
}

export default React.memo(React.forwardRef(ChatWindow));

const localStyles = {
	layout: {
		backgroundColor: styles.color.white,
	},

	header: {
		layout: {
			flexDirection: styles.flexDirection.row,
			height: 40,
		},
		user: {
			layout: {
				flexDirection: styles.flexDirection.row,
				alignItems: styles.alignItems.center,
				flex: 1,
			},
			image: {
				marginLeft: 15,
				marginRight: 18,
				...styles.circle(30),
				backgroundColor: styles.color.white,
			},

			title: {
				fontSize: 13,
				bold: true,
				flex: 1,
				color: styles.color.white,
			},
		},
		close: {
			paddingHorizontal: 15,
			color: styles.color.white,
		},
	},

	main: styles.static(
		{
			flex: 1,
		},
		{
			chat: {},
			auth: {margin: 20},
		}
	),

	textInputStyle: {
		fontSize: 17,
	}
};
