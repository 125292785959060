import Base from "../../../class/Base"
import Debug from "../../../Debug"
import {clone, is} from "../../../utils"

const cache = {};

/**
 * A media. Can be a video or an image.
 */
export default class Media extends Base.extendsWith({url : String}) {
	constructor(url){
		super();
		// check input
		Debug.assert(!is(url, String, true), "Media constructor can only takes a string url as parameter.");

		this.url = url;

		// add a non enumerable (to prevent to send it to server) promise property
		Object.defineProperties(this, {
			datasPromise : {
				writable : true,
				configurable : true,
				enumerable : false,
			}
		});
	}

	get uri(){
		return this.url;
	}

	get type(){
		return this.contentType;
	}

	set type(type){
		this.contentType = type;
	}

	setUrl(url){
		// replace http with https
		url = url && url.replace(/^http:/, 'https:');

		if (this.url !== url){
			this.onReset();

			if (cache[url]){
				let datas = clone(cache[url]);
				this.hydrate(datas);
				this.datasPromise = Promise.resolve(datas);
			}
		}

		return super.setUrl(url);
	}

	loadDatas(){
		let {url} = this;
		return this.datasPromise = this.onLoadDatas(url)
			.result(async (datas, error) => {
				if (!error)
					cache[url] = datas;

				if (this.url !== url) return; // check if url changed

				if (!error){
					if (datas){
						this.hydrate(datas);
						if (!this.contentType){ // content type not loaded
							await fetch(url, { method: 'HEAD' })
								.result(response => {
									if (response)
										this.contentType = response.headers.get('Content-type');
								});
						}
					}
				}
				else {
					this.datasPromise.error = error;
					console.warn(`Error loading datas for ${this.constructor.TYPE} : `, url);
				}

				return datas;
			});
	}

	/**
	 * Reset media's datas. Called when url property changed.
	 */
	onReset(){
		this.datasPromise = null;
		this.contentType = null;
	}

	get hasDatas(){
		return Boolean(this.contentType);
	}

	/**
	 * Called when url property changed.
	 * @param {String} url New url set.
	 * @return {Promise.<void>} Promise to load media's datas.
	 */
	async onLoadDatas(url){};

	toString(){
		return this.url || "";
	}

	static from(value){
		if (is.primitive(value)) {
			let instance = this.instantiate();
			instance.url = value;
			return instance;
		}

		else if (value) {
			value = {...value};
			const url = value.url;
			delete value.url;
			const instance = super.from(value);
			instance.url = url;
			return instance;
		}
	}
}

// ---- properties ----
Media.addProperties({
	contentType : String,
});
