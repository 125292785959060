import React from "react"
import useAppWidthLevel from "../../../../app/useAppWidthLevel";
import ModalFrame from "../../../../component/ModalFrame";
import Text from "../../../../component/Text";
import getResourcesForPaymentMean from "../../../../library-js/res/getResourcesForPaymentMean";
import use from "../../../../hook";
import {styles} from "../../../../res";
import {parallel} from "../../../../library-js/utils/function";
import PaymentMean from "../../../../library-js/app/model/entity/Order/PaymentMean";

export default function SelectOfflineMeanModal({receptionMean, means, onceSelected, close, ...props}) {
	const [selected, select] = use.state();
	use.onChange(parallel(onceSelected, close), [selected]);

	const [isDesktop] = useAppWidthLevel([1024]);
	const Modal = isDesktop ? ModalFrame.Dialog : ModalFrame.BottomSheet;

	props.style = use.defaultStyle(props.style, localStyles.layout);

	means.sort(sortPaymentMean);

	return (
		<Modal {...props}>
			<Text style={localStyles.title}>
				{`Je souhaiterai regler en`}
			</Text>

			{
				means.map(mean =>
					<Text
						key={mean.key}
						onPress={() => select(mean)}
						style={[localStyles.mean, (selected === mean) && {color: '#395aff'}]}>
						{getResourcesForPaymentMean(mean).title()}
					</Text>
				)
			}
		</Modal>
	);
}

function sortPaymentMean(paymentMean, secondPaymentMean) {
	if (paymentMean === PaymentMean.else)
		return 1

	else if (secondPaymentMean === PaymentMean.else)
		return -1

	else
		return 0
}

const localStyles = {
	layout: {
		// width: 700, //TODO test on web
		// maxWidth: '100%',
		paddingTop: 10,
		paddingBottom: 50,
	},

	title: {
		textAlign: styles.textAlign.center,
		paddingVertical: 50,
		fontSize: 20,
		bold: true,
	},

	mean: {
		textAlign: styles.textAlign.center,
		paddingVertical: 20,
		fontSize: 20,
	},
}
