import React from "react"
import {View as RNView} from "react-native"
import use from "library-react/hook"
import {styles} from "@main/res"
import Image from "library-react/component/Image";
import moment from "moment";
import StarsView from "library-react/component/StarsView";
import {images} from "library-react/res";

function CommentView({item: {comment, author}, ...props}) {
	const {Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<RNView {...props}>
			<RNView style={localStyles.header.layout}>
				<Image
					defaultSource={images.interlocutor}
					source={author.picture}
					resizeMode="cover"
					style={localStyles.header.picture}
				/>

				<RNView style={localStyles.header.infos.layout}>
					<Text
						numberOfLines={1}
						style={localStyles.header.infos.name}>
						{author.name}
					</Text>

					<Text
						numberOfLines={1}
						style={localStyles.header.infos.date}>
						{moment(comment.creationDate).format('DD/MM/YYYY')}
					</Text>
				</RNView>

				<StarsView
					stars={comment.stars}
					useEyalStyle
					size={30}
					gap={10}
				/>
			</RNView>

			<Text style={localStyles.text}>
				{comment.text}
			</Text>
		</RNView>
	);
}

export default React.memo(CommentView);


const localStyles = {
	layout: {},

	header: {
		layout: {
			flexDirection: styles.flexDirection.row,
			alignItems: styles.alignItems.center,
			marginBottom: 23,
		},

		picture: {
			...styles.circle(60),
			marginRight: 15,
			backgroundColor: "#f2f2f2"
		},

		infos: {
			layout: {
				flex: 1,
			},

			name: {
				fontSize: 17,
				color: '#395aff',
				marginBottom: 10,
			},

			date: {
				fontSize: 16,
				color: '#5e6383',
			},
		},

		stars: {
		}
	},

	text: {
		fontSize: 20,
	}
};
