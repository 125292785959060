import {Base} from "../../../class"
import Event from "./event/Event"

export default class Chat extends Base {
	constructor(id, interlocutorId){
		super();
		this.id = id;
		this.interlocutorId = interlocutorId;
		this.unread = 0;
	}

	get ok(){
		return Boolean(
			this.id
			&& this.interlocutorId
		);
	}

	get time(){
		return this.event?.time;
	}
}

Chat.addProperties({
	id : String,
	interlocutorId : null, // String (user) or Number (shop)
	event: Event,
	unread: Number, // number of unread messages
	interlocutorRead: Number, // timestamp
});
