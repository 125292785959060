import { isNil, tryCatch } from "ramda";
import Params, { decode } from "./Params";
import parsePathname from "./parsePathname";

export default function plugFeatures(URL) {
	URL.Params = Params;

	URL.parseSafely = tryCatch(url => {
		const urlInstance = new URL(url);
		urlInstance.params; // try it
		return urlInstance;
	}, () => undefined);

	Object.defineProperties(URL.prototype, {
		params: {
			configurable: true,
			get: function () {
				return decode(this.search);
			}
		},

		path: {
			configurable: true,
			get: function () {
				return parsePathname(this.pathname);
			},
		}
	});


	const URLSearchParams = new URL('http://a.co').searchParams.constructor;
	URLSearchParams.prototype.assign = function (object) {
		if (object instanceof Object)
			Object.entries(object)
				.forEach(([key, value]) => {
					if (isNil(value))
						this.delete(key);
					else
						this.set(key, value);
				});
	};
}
