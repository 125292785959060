import {paths} from "@main/links";
import pageTitle from "@main/utils/pageTitle";
import SEO from "library-react/component/SEO";
import use from "library-react/hook";
import React from "react";
import {mergeStatus, optionalLoadableToStatus} from "../../../library-react/component/SEO";
import {useStaticShopCategoryLoadable} from "../../hook/useStaticShopCategory";
import {useStaticShopTagsNamesLoadable} from "../../hook/useStaticShopTags";
import toReadableTags from "../../utils/toReadableShopTags";
import DesktopUI from "./desktop";
import MobileUI from "./mobile";
import {buildSeoParamsForShop} from "library-js/utils/buildSeoParams";

export default function ShopUI({...props}) {
	const {shop} = use.context.infos().vShop;
	const categoryLoadable = useStaticShopCategoryLoadable();
	const category = categoryLoadable.value;
	const shopTagsNamesLoadable = useStaticShopTagsNamesLoadable();
	const shopTagsNames = shopTagsNamesLoadable.value;

	const title = props.alt = pageTitle([
		`À propos`,
		shop.name,
		(({locality, postalCode}) => `${locality} ${postalCode}`)(shop.address),
		toReadableTags(shopTagsNames),
		category?.name,
	]);
	const display = use.context.display();
	return (
		<>
			<SEO
				status={mergeStatus([
					optionalLoadableToStatus(shopTagsNamesLoadable),
					optionalLoadableToStatus(categoryLoadable),
				])}
				canonical={paths.shop}
				optimizationParams={buildSeoParamsForShop({shop, shopTagsNames, category})}
				image={shop.mainPicture}
				title={title}
				description={
					shop.address.formatted + '.\n'
					+ `Horaires d’ouverture, carte, gallerie, avis, etc.`
				}
			/>

			{
				display.is.mobile ?
					<MobileUI {...props} /> :
					<DesktopUI {...props} />
			}
		</>
	);
}
