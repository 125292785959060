import React from "react"
import {View as RNView} from "react-native"
import {fonts} from "../../../../../../../res";
import Text from "../../../../../../../component/Text";
import use from "../../../../../../../hook";

export default React.memo(
	function NotificationLayout({title, children, ...props}) {
		props.style = use.defaultStyle(props.style, localStyles.layout);

		return (
			<RNView {...props}>
				<Text style={localStyles.title}>
					{title}
				</Text>

				{children}
			</RNView>
		);
	}
);


const localStyles = {
	layout: {
		marginVertical: 14,
	},

	title: {
		font: fonts.Poppins,
		bold: true,
		fontSize: 12,
		color: '#0ab9ed',
		marginBottom: 6,
	},
};
