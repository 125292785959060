import React from "react"
import {ActivityIndicator, View as RNView} from "react-native";
import use from "library-react/hook";
import {styles} from "@main/res";
import ScrollView from "../../../library-react/component/ScrollView";
import ProdshopBrandView from "./BrandItem/ProdshopBrandView";
import BrandView from "../../component/BrandView";
import TitleWithBack from "../../component/TitleWithBack";
import Portal from "../../../library-react/component/Portal";
import {screens} from "../../links";

export default function DesktopUI({title, iterator, prodshopBrands, onBrandSelected, ...props}) {
	use.subscribe(iterator?.onStateChanged, [iterator]);

	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<ScrollView onEndReached={iterator?.ready && iterator.load}
					onEndReachedThreshold={0.01}
					contentContainerStyle={localStyles.content}
					{...props}>
			<TitleWithBack style={localStyles.title}>
				{title}
			</TitleWithBack>
			<RNView style={localStyles.items}>
				{
					iterator.items.map(
						(brand, index) =>
							brand.logo ?
								<ProdshopBrandView
									key={index}
									brand={brand}
									prodshops={prodshopBrands[brand.name]}
									style={localStyles.item(brand.logo)}/> :
								<Portal
									key={index}
									to={screens.brand}
									params={{name: brand.name}}>
									<BrandView brand={brand} style={localStyles.item(brand.logo)}/>
								</Portal>
					)
				}
			</RNView>
			{
				!iterator.end && <ActivityIndicator style={localStyles.loading}/>
			}
		</ScrollView>
	);
}

const localStyles = {
	layout: {},
	content: {
		paddingHorizontal: 52,
		paddingBottom: 26,
		maxWidth: 1200,
	},

	title: {
		paddingLeft: 31,
	},
	items: {
		flexDirection: styles.flexDirection.row,
		justifyContent: styles.justifyContent.spaceBetween,
		flexWrap: styles.flexWrap.wrap,
	},

	item: styles.static.bool({},
		{
			marginTop: 22,
			flexBasis: '100%'
		},
		{
			height: 120,
			marginTop: 27,
			flexBasis: '45%'
		}
	),

	loading: {
		padding: 50,
	}

}
