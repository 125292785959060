import nodeToEntry from "./nodeToEntry";

export default function nodeChildrenToEntries(node){
	const entries = [];

	node.forEach(child => {
		const entry = nodeToEntry(child);
		entries.push(entry);
	});

	return entries;
}
