import React from "react";
import Functions from "../library-js/utils/Functions";
import {is} from "../library-js/utils";
import PropTypes from "prop-types";

React.cloneElement = Functions.override(React.cloneElement, (cloneElement, element, props, ...children) => {
	if (is(props, Function)){
		const update = props;
		props = {...element.props};
		update(props);
	}

	return cloneElement(element, props, ...children);
});

// --- prop type style ---
PropTypes.style = PropTypes.oneOfType([PropTypes.object, PropTypes.array]);
