import React from "react";
import use from "../../hook";
import { BasketManager } from "../../library-js/app/model/basket";
import useAuth from "../../hook/useAuth";
import { path } from "ramda";
import CheckoutUI from "./CheckoutUI";
import { linking } from "./CheckoutUI/screens";
import { callMicroservice } from "../../library-js/app/Server/microservices";

export default function BasketCheckoutUI(
	{
		shopId,
		onEmptyBasket,
		onceOrderCreated,
		oncePaymentLinkCreated,
		...props
	}
) {
	const user = useAuth.user();
	const loadable = use.loadable.server(user &&
		(() => BasketManager.getItemsIn(shopId, 150)),
		[shopId, user]
	);

	use.effect((end) => {
		if (end && !loadable.value.length)
			onEmptyBasket?.();
	}, [loadable?.end]);

	const vItems = loadable?.value;

	async function checkout(checkoutParams) {
		const { paymentMean: expectedPaymentMean, receptionMean, instructions, contactInfos } = checkoutParams;
		const basketItemIds = vItems.map(path(['item', 'id']));

		const response = await callMicroservice("order/checkout",
			{
				basketItemIds, shopId,
				expectedPaymentMean, receptionMean, instructions, contactInfos,
				returnPlatformType: "WEB",
			},
			{ method: "POST" }
		);
		if (expectedPaymentMean.is.online)
			oncePaymentLinkCreated?.(response.paymentLink);

		if (response)
			onceOrderCreated?.(response?.order?.id);
		else
			response.throw();
	}

	return (
		<CheckoutUI {...{ loadable, checkout }} {...props} />
	);
}

export const basketCheckoutLinking = {
	path: 'checkout/basket',
	...linking,
};
