import React from "react";
import {is} from "../../library-js/utils"

export default ([, updateModals]) =>
	function (component, escapable, onClosed, overrideClose) {
		component = React.cloneElement(component, {key: String(Date.now())});

		let closed = false;
		let close = () => {
			if (closed) return;
			closed = true;

			// remove the corresponding dialog
			updateModals(modals => {
				let index = modals.findIndex(entry => entry.component === component);
				if (index >= 0)
					modals.splice(index, 1);
			});

			// notify
			if (onClosed)
				onClosed();
		};

		if (overrideClose)
			close = overrideClose(close);

		escapable = is(escapable) ? escapable : true;
		component = React.cloneElement(component, props => {
			if (escapable){
				if (!props.close)
					props.close = close;

				props.onBackdropPress = close;
			}
		});

		updateModals(modals => modals.push({component, close}));
		return close;
	};
