import React from "react";
import { ScrollView, View as RNView } from "react-native"
import PinView from "./PinView";
import { styles } from "@main/res";
import View from "library-react/component/View/v2";
import use from "library-react/hook";
import Portal from "library-react/component/Portal";
import { screens } from "@main/links";
import fonts from "@main/res/fonts";


export default function PinHome({ ...props }) {
	const { config } = use.context.infos()
	const pins = config?.home?.pins;

	return (
		<ScrollView {...props}>
			<RNView style={localStyles.grid}>
				{
					pins.map((pin, index) => <PinView key={index} item={pin} style={localStyles.item} />)
				}
			</RNView>

			<CatalogPin />
		</ScrollView>
	);
}

function CatalogPin() {
	const display = use.context.display();
	const title = use.context.infos().config?.catalog?.name || `NOTRE CATALOGUE`;
	const { Text } = use.theme();

	return (
		display.is.mobile ?
			<Portal to={screens.catalog}>
				<View style={localStyles.catalog.layout}>
					<Text
						accessibilityRole="heading"
						accessibilityLevel={2}
						style={localStyles.catalog.text}>
						{title}
					</Text>
				</View>
			</Portal> :
			null
	);
}

const localStyles = {
	grid: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
		flexWrap: 'wrap',
		maxWidth: 1530,
		marginBottom: 35,
	},
	item: {
		marginVertical: 40,
	},
	catalog: {
		layout: {
			backgroundColor: '#f7f7f7',
			padding: 34,
			marginHorizontal: 20,
			marginBottom: 35,
		},
		text: {
			fontSize: 35,
			bold: true,
			font: fonts.BebasNeue,
			textAlign: styles.textAlign.center,
		}
	}
}
