import {Base, Enum} from "./class"
import Debug from "./Debug"
import {is, Trigger} from "./utils"

/**
 * An iterator for infinite lists.
 * The class has 1 function and 3 readable properties:
 * <ul>
 *     <li>Promise {@link Iterator.load}() : Load the next page.</li>
 *     <li>Boolean {@link end} : Indicates if the iterator reached the end.</li>
 *     <li>Boolean {@link loading} : Indicates if the iterator is already loading the next page.</li>
 *     <li>Boolean {@link ready} : Indicates if the iterator can load. Should be checked before calling {@link load}.</li>
 * </ul>
 *
 * Must implement:
 * <ul>
 *     <li>Promise {@link Iterator.onLoading}(page) : Load the next page. The page parameter increments for each load.</li>
 *     <li>Boolean {@link Iterator.isEnd}(page, result) (optional) : The returned value will update the end property. The result parameter is the returned value from the onLoading method.</li>
 * </ul>
 *
 * You can implement those methods using {@link Base.hydrate} method on an instance:
 * <code>
 *     // exemple
 *     new InfiniteIterator()
 *     		.hydrate({
 *     			onLoading : page => loadDatas(page),
 *     			isEnd : (page, results) => results.length < 20
 *     		})
 * </code>
 *
 * @template of
 */
export default class InfiniteIterator extends Base {
	constructor() {
		super();
		this.destroyed = false;

		/**
		 * Indicates the next page to load.
		 * @readonly
		 * @type {number}
		 */
		this.page = 0;

		/**
		 * Indicates if the iterator reached the end.
		 * @readonly
		 * @type {boolean}
		 */
		this.end = false;

		this.last = {
			result: null,
			error: null,
		};

		this._trigger = new Trigger();

		this.load = this.load.bind(this);
		this.shouldStop = this.shouldStop.bind(this);
		this.onStateChanged = this.onStateChanged.bind(this);
		this.onStateChanged(() => this.loadNextPage = this.ready ? this.load : undefined);
	}

	get loading() {
		return Boolean(this.promise);
	}

	get neverLoaded() {
		return this.page === 0 && !this.loading;
	}

	get state() {
		return this.loading ? States.loading
			: this.end ? States.end
				: this.last.error ? States.error
					: States.idle;
	}

	get failed() {
		return this.state === States.error;
	}

	get idle() {
		return this.state === States.idle;
	}

	/**
	 * @returns {boolean} If true, you can call {@link load} method. This meas it's not ended or already loading.
	 * @readonly
	 */
	get ready() {
		return !this.loading && !this.end && !this.destroyed;
	}

	/**
	 * @return Promise A promise to load the next page.
	 * If {@link ready} is false, this will return the promise of the last load call. So check it before calling this method.
	 */
	load() {
		if (!this.ready) {
			if (this.promise)
				return this.promise;

			return Promise.resolve(this.last.result);
		}

		let result, error;

		const start = (this.delay > 0) ?
			// add a delay
			(...params) => Promise.process(this.shouldStop)
				.then(() => Promise.await(this.delay))
				.then(() => this.onLoading(...params)) :
			// load directly (onLoading might be synchronous)
			(...params) => this.onLoading(...params);

		try {
			result = start(this.page);
		} catch (caught) {
			error = caught;
		}

		if (result instanceof Promise) {
			this.promise = result;
			this.promise.stopOn(this.shouldStop)
				.result((result, error) => {
					this.promise = null;
					this.handleResult(result, error);
				});

			this.notify(); // on loading
		} else
			this.handleResult(result, error);

		return Promise.result(result, error);
	}

	notify() {
		this._trigger.fire(this.state);
	}

	handleResult(result, error) {
		if (!error) {
			this.last.result = result;
			this.last.error = null;

			if (this.shouldIncrementPage(result)) {
				this.page++;
				this.end = this.isEnd(this.page, result);
			}
		} else {
			this.last.result = null;
			this.last.error = error;
		}

		this.notify();
	}

	/**
	 * Should load datas.
	 * If an error occurs, the reject method should be called, passing it the error.
	 * The default implementation is empty.
	 * @return Promise Promise to load the result.
	 */
	async onLoading(page) {
	}

	/**
	 * This method is called after each onLoading call. The returned value will update the 'end' property.
	 * If you wish to write end property from your 'onLoading' implementation, then don't override this method.
	 * Default implementation: returns 'end' (doesn't change the end property).
	 * @param page {Number} The last loaded page to load.
	 * @param result {*} The last result returned by onLoading method.
	 * @return {boolean} False if page parameter was last page to load. False otherwise.
	 */
	isEnd(page, result) {
		return this.end;
	}

	/**
	 * This method is called after each load, if no error has been thrown.
	 * Its returned value indicates if the page should increment (means the current page load is finished & has gone well).
	 * Default implementation: returns true.
	 * @param result {*} The last result returned by onLoading method.
	 * @returns {boolean} A boolean to indicates if the page should increment (in case the load was well) or not.
	 */
	shouldIncrementPage(result) {
		return true;
	}

	// --- lifecycle ---

	onStateChanged(run) {
		return this._trigger.add(run);
	}

	onLoad(run) {
		return this.onStateChanged(runIfStateIs(States.loading, run));
	}

	onIdle(run) {
		return this.onStateChanged(
			runIfStateIs([States.idle, States.end], run)
		);
	}

	onError(run) {
		return this.onStateChanged(runIfStateIs(States.error, run));
	}

	onEnd(run) {
		return this.onStateChanged(runIfStateIs(States.end, run));
	}

	shouldStop() {
		return Boolean(this.destroyed);
	}

	destroy() {
		this._trigger.clear();
		this.destroyed = true;
	}

	// ---- static ----

	/**
	 * Join multiple iterators, into one iterator.
	 * @param {InfiniteIterator[]} iterators
	 * @return InfiniteIterator An iterator of the given iterators.
	 */
	static join(iterators, isResultEmpty) {
		Debug.assert(iterators.includes(iterator => !is(iterator, InfiniteIterator)), "InfiniteIterator.join can only accepts InfiniteIterator as parameters.");

		return new InfiniteIterator()
			.hydrate({
				async onLoading() {
					let result = await iterators.first.load();
					while (isResultEmpty(result) && iterators.first.end) {
						iterators = iterators.slice(1);

						if (this.isEnd())
							break;

						try {
							result = await iterators.first.load();
						} catch (error) {
							console.warn("Error while loading from next iterator: ", error);
						}
					}

					if (iterators.length && iterators.first.end)
						iterators = iterators.slice(1);

					return result;
				},

				isEnd() {
					return iterators.length === 0;
				},
			})
	}
}

InfiniteIterator.addProperties({delay: Number});

const States = InfiniteIterator.States = new Enum("idle", "loading", "error", "end");
States.domains.ok = [States.idle, States.end];

const runIfStateIs = (states, run) => {
	if (!(states instanceof Array))
		states = [states];

	return state => {
		if (states.includes(state))
			run();
	};
};
