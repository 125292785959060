import React from "react"
import use from "library-react/hook";
import getResourcesForReservationState from "library-js/res/reservationState";
import {View} from "react-native";
import Icon from "library-react/component/Icon/v2";
import {styles} from "@main/res";

export default function ReservationState({item: vReservation, iconSize, ...props}) {
	const {Text} = use.theme();
	const resource = getResourcesForReservationState(vReservation?.currentState.type);

	props.style = use.defaultStyle(props.style, localStyles.layout(resource?.color(vReservation.time)), [resource]);
	return (
		<View {...props}>
			<Icon name={resource?.icon(vReservation.time)}
				  size={iconSize || 20}
				  style={localStyles.icon(resource?.color(vReservation.time))}/>
			<Text style={localStyles.text(resource?.color(vReservation.time))}>
				{resource?.title(vReservation.time)}
			</Text>
		</View>
	)
}
const localStyles = {
	layout: (backgroundColor) => ({
		flexDirection: styles.flexDirection.row,
		backgroundColor: `${backgroundColor || "#707070"}19`,
		...styles.center,
	}),
	icon: (color) => ({
		color,
		marginRight: 10,
	}),
	text: (color) => ({
		textAlign: styles.textAlign.center,
		bold: true,
		color,
	}),
}