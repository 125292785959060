import React from "react"
import {View as RNView} from "react-native"
import use from "library-react/hook"
import {styles} from "@main/res"
import Section from "../Section"

export default function MyInfosDesktop({user, updateInfos, ...props}) {

	const {Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<RNView {...props}>
			<Section
				title={`Mes informations`}
				subtitle={user.displayName}>

				<RNView style={localStyles.field.layout}>
					<Text style={localStyles.field.label}>
						{`Email`}
					</Text>
					<Text style={localStyles.field.value}>
						{user.email}
					</Text>
				</RNView>

				{
					updateInfos &&
					<Text
						onPress={updateInfos}
						style={localStyles.add}>
						{`Modifier`}
					</Text>
				}
			</Section>

		</RNView>
	);
}

const localStyles = {
	layout: {},

	field: {
		layout: {
			flexDirection: styles.flexDirection.row,
			alignItems: styles.alignItems.baseline,
			justifyContent: styles.justifyContent.spaceBetween
		},

		label: {
			fontSize: 19,
			color: "#838383",
		},

		value: {
			fontSize: 19,
			color: styles.color.black,
			overflow: styles.overflow.hidden,
			paddingRight: 30
		}
	},

	add: {
		color: "#0ab9ed",
		fontSize: 15,
		textDecorationLine: styles.textDecorationLine.underline,
		padding: 10,
		paddingLeft: 0,
		marginTop: 10,
	},
};
