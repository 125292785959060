import is from "../is";
import identity from "ramda/es/identity";
import subtract from "ramda/es/subtract";

export default function willSafeSort(path = identity, sort = subtract) {
	return function safeSort(a, b) {
		let x = path(a);
		let y = path(b);
		if (areSame(x, y))
			return 0;

		const result = sort(x, y);
		if (!isNaN(result) && is(result, Number))
			return result;

		// correct x & y
		x = x ?? Infinity;
		y = y ?? Infinity;
		if (areSame(x, y))
			return 0;

		return sort(x, y);
	};
}

function areSame(x, y){
	return x === y || Object.is(x, y);
}
