import React, {useState} from "react"
import {TextInput as RNTextInput} from "react-native"
import {is, Strings} from "../../library-js/utils";
import ComponentUtils from "../../ComponentUtils";
import Environment from "../../library-js/Environment";
import Functions from "../../library-js/utils/Functions";
import timeout from "../../library-js/utils/timeout";
import useMemo from "../../hook/useMemo";
import useOnChange from "../../hook/useOnChange";
import useSyncEffect from "../../hook/useSyncEffect";
import useInstances from "../../hook/useInstances";
import useDefaultStyle from "../../hook/useDefaultStyle";
import extractProps from "../../utils/extractProps";


function TextInput(immutableProps, refProp) {
	const [value, setValue] = useState(() => retrieveNextValueFromProps(immutableProps));

	const {
		value: forcedValue,
		onValueChanged,
		onChangeText,
		onSubmitted,
		onSubmitEditing,
		validate,
		errorStyle,
		defaultValue,
		...props
	} = immutableProps;


	// ref to store last value & function to get sync value by instance
	const ref = useMemo({getValue: () => value});

	// on change
	useOnChange(onChangeText, [value]);

	const oldValue = ref.getValue();
	useOnChange(
		onValueChanged &&
		(value !== forcedValue) &&
		function notify() {
			onValueChanged(/*new*/ value, /*old*/ oldValue);
		},
		[value],
	);

	// set forced value
	/// Should be after onValueChanged callback
	useSyncEffect(() => {
			if (is.defined(forcedValue))
				return timeout(() => setValue(forcedValue), 300);
		},
		[value, forcedValue],
	);

	// prepare a value function accessible from instance and stores the last value
	ref.getValue = () => value;
	props.value = value;

	// On instance created:
	// 1. Attach computed .value property
	// 2. Override clear() to trigger on-change callbacks
	// TODO TRACK https://github.com/facebook/react-native/issues/28998
	// callback is run on each render
	props.ref = useInstances({
		input: input => {
			if (input) {
				// allow to get/set value from instance
				if (!Environment.is.web)
					Object.defineProperties(input, {
						value: {
							configurable: true,
							get: () => ref.getValue(),
							set: setValue,
						},
					});

				// override default .clear() in order to be updated & onValueChanged to be triggered
				input.clear = () => setValue('');
			}

			if (refProp) // pass instance up
				ComponentUtils.ref.set(refProp, input);
		}
	}).set.input;


	props.onChangeText = setValue;
	props.onSubmitEditing = (event) => {
		if (onSubmitted)
			onSubmitted(event.nativeEvent.text);

		if (onSubmitEditing)
			onSubmitEditing(event);
	};


	// ---- styles ----

	const valid = !validate || (
		is(validate, Function) ?
			validate(value) :
			Boolean(value)
	);

	// set style according to validated value
	props.style = useDefaultStyle(props.style, [
			defaultStyle,
			!valid && {color: "red"},
			!valid && errorStyle,
		],
		[valid, errorStyle]
	);


	// default placeholder color
	if (!props.placeholderTextColor)
		props.placeholderTextColor = valid ? 'grey' : 'red';


	// ----- iOS -----
	if (Environment.is.ios) {
		const [focused, setFocused] = useState(false);
		const onFocus = useMemo(onFocus => Functions.runBefore(onFocus,
				() => setTimeout(() => setFocused(true), 500),
			),
			[props.onFocus]
		);

		const onBlur = useMemo(
			(onBlur) => Functions.runBefore(onBlur, () => setFocused(false)),
			[props.onBlur]
		);

		if (props.multiline) {
			props.onFocus = onFocus;
			props.onBlur = onBlur;
			props.scrollEnabled = is(props.scrollEnabled) ? props.scrollEnabled && focused : focused;
		}

		// ------
		props.style = useMemo(
			() => {
				const style = ComponentUtils.style.merge(props.style);
				const box = ComponentUtils.getBoundingBox(style);
				style.paddingTop = Math.max(box.paddingTop, 0) + 4;
				style.paddingBottom = Math.max(box.paddingBottom, 0) + 4;
				return style;
			},
			[props.style]
		);
	}

	if (props.disabled) {
		console.warn("TextInput.disabled is not a prop from RN doc. Use editable instead.");
		props.disabled = Boolean(props.disabled);
	}

	if (props.editable !== undefined)
		props.editable = Boolean(props.editable);

	if (props.textAlignVertical === undefined)
		props.textAlignVertical = props.multiline ? 'top' : 'center';

	return <RNTextInput {...props}/>;
}

export default React.forwardRef(TextInput);

export const DEFAULT_INPUT_PROPS = {
	editable: true,

	value: undefined,
	onValueChanged: undefined,
	defaultValue: undefined,
	placeholder: undefined,
	onFocus: undefined,
	onBlur: undefined,
	onSubmitted: undefined,
	formatter: undefined,
	textAlignVertical: undefined,
	multiline: undefined,
	errorStyle: undefined,
	autoFocus: undefined,
	textContentType: undefined,
	validate: undefined,
	autoCompleteType: undefined,
	secureTextEntry: undefined,
	returnKeyType: undefined,
	blurOnSubmit: undefined,
	submitted: undefined,
};
export const INPUT_PROPS = Object.keys(DEFAULT_INPUT_PROPS);
export const extractInputProps = extractProps(DEFAULT_INPUT_PROPS)

export const retrieveNextValueFromProps = (props, stateValue) => Strings.toString(
	is.defined(props.value) ? props.value :
		is.defined(stateValue) ? stateValue :
			props.defaultValue
);

const defaultStyle = {
	color: '#000000',
};
