import React from "react"
import { View as RNView } from "react-native"
import use from "../../../../../../../../hook";
import TimeView from "../../TimeView";
import { fonts, styles } from "../../../../../../../../res"
import Icon from "../../../../../../../../component/Icon/v2";
import Text from "../../../../../../../../component/Text";
import View from "../../../../../../../../component/View/v2";
import getResourcesForOrderState from "../../../../../../../../library-js/res/getResourcesForOrderState";

function OrderNotificationView({ item: orderNotification, onOrderClicked, ...props }) {
	const orderState = orderNotification?.vOrder?.currentState?.type;
	const display = getResourcesForOrderState(orderState);

	const { vOrder, time } = orderNotification || {};

	if (!props.onPress && onOrderClicked)
		props.onPress = () => onOrderClicked(vOrder);

	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<View {...props}>
			<RNView style={localStyles.row}>
				<Icon
					name={display.icon()}
					size={11.6}
					style={[localStyles.icon, { backgroundColor: display.color() }]}
				/>

				<Text style={localStyles.text}>
					{`Commande `}
					<Text
						style={localStyles.orderId}>
						{vOrder?.id}
					</Text>
					{` `}
					{display.title(vOrder.receptionMean).firstDown}
				</Text>
			</RNView>

			<TimeView
				time={time}
				style={localStyles.time}
			/>
		</View>
	);
}

export default React.memo(OrderNotificationView);

const localStyles = {
	layout: {
		marginVertical: 7.5,
	},

	row: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
	},

	icon: {
		...styles.circle(20),
		...styles.newShadow(0, 3, 6, .16),
		color: styles.color.white,
		marginRight: 11,
	},

	text: {
		...styles.text(14, '#1e2432', fonts.Poppins),
	},

	orderId: {
		color: '#452ee0',
	},

	time: {
		color: '#1e2432',
		alignSelf: styles.alignSelf.flexEnd,
		marginHorizontal: 17,
	}
};
