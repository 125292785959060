import React from "react";
import View from "library-react/component/View/v2";
import Image from "library-react/component/Image";
import use from "library-react/hook";
import LoadingPlaceholder from "library-react/component/LoadingPlaceholder";
import { strings, styles } from "@main/res";
import ArticleUI from "./ArticleUI";
import TitleWithBack from "@main/component/TitleWithBack";
import fonts from "@main/res/fonts";

export default function Header({ item: vShowcase, title, ...props }) {
	const { Text } = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<View {...props}>
			<TitleWithBack />
			<Image
				source={vShowcase.banner}
				resizeMode="cover"
				alt={title}
				style={localStyles.image} />
			<View style={localStyles.infos}>
				<Text style={localStyles.title} numberOfLines={1}
					accessibilityRole="heading">
					{vShowcase?.title}
				</Text>
				<LoadingPlaceholder disabled={vShowcase} style={localStyles.wrapper}>
					<Text style={localStyles.description}>
						{vShowcase?.description || strings.invisibleChar}
					</Text>
				</LoadingPlaceholder>
				<View style={localStyles.articles}>
					{
						vShowcase?.articles?.map(
							(item, index) =>
								<ArticleUI
									key={index}
									item={item}
									inverted={index % 2}
									style={localStyles.article} />
						)
					}
				</View>
			</View>
		</View>
	);
}

const localStyles = {
	layout: {},
	image: {
		marginBottom: 30,
		height: 400,
	},

	wrapper: {
		borderRadius: 5,
		minWidth: 300,
		alignSelf: styles.alignSelf.center,
		marginBottom: 40,
	},
	infos: {
		maxWidth: 1020,
		width: '100%',
		alignSelf: styles.alignSelf.center
	},
	title: {
		marginBottom: 10,
		fontSize: 44,
		bold: true,
		textAlign: styles.textAlign.center,
		font: fonts.BebasNeue,
	},
	description: {
		fontSize: 19,
		lineHeight: 24,
	},
	articles: {
		paddingHorizontal: 26,
		overflow: styles.overflow.hidden,
	},
	article: {
		marginBottom: 55
	}
}
