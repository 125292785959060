import Debug from "../../Debug";

/**
 * Abstract storage class.
 */
export default class Storage {
	/**
	 * Storage classes must override this method using their own means.
	 * 2 storage instances are considered the same if they point to the same storage location.
	 * A database storage for instance must check if the 2 database use the same native database.
	 */
	equals(storage){
		return Debug.assert(true, `${this.constructor.name} should implement equals() method.`,
			// the best fallback we can do in production is to compare the 2 storage instances
			() => this === storage,
		);
	}
}
