import React from "react"
import View from "library-react/component/View";
import use from "library-react/hook";
import CatalogView from "@main/component/CatalogView";
import Search from "library-js/Search/index";
import useSectionsLoadable from "@main/hook/useSectionsLoadable";
import ShowcasesBanner from "@main/component/ShowcasesBanner";

export default function HomeFragmentMobile({vShop, banner, ...props}) {
	const {value: cupboard} = useSectionsLoadable();

	const productsIterator = use.infiniteIterator(() =>
			Search.index.prodshop.getIterator({
				available: true,
				shopId: vShop.id,
				inShop: true,
				number: 30,
			})
	);

	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<View {...props}>
			<CatalogView
				header={<ShowcasesBanner/>}
				// do not display products before sections
				productsIterator={cupboard && productsIterator}
			/>
		</View>
	)
}

const localStyles = {
	layout: {
		flex: 1,
	},

	image: {
		height: 144,
		width: '100%'
	},

};
