import useMemo from "./useMemo";
import useSubscribe from "./useSubscribe";

/**
 * @template T
 * @param {() => T} factory
 * @param {Array} [dependencies]
 * @return {T}
 */
export default function useInfiniteIterator(factory, dependencies) {
	const iterator = useMemo(
		(...params) => {
			const iterator = (factory instanceof Function) ? factory(...params) : undefined; // prevent false values

			if (iterator?.page === 0)
				iterator.load();

			return iterator || undefined;
		},
		dependencies,
		destructor,
	);

	useSubscribe(render => iterator?.onStateChanged(render), [iterator]);

	return iterator;
}

function destructor(iterator) {
	iterator?.destroy();
}
