import React from "react"
import abstractComponent from "library-react/component/abstract/abstractComponent";
import use from "library-react/hook";
import ComponentUtils from "library-react/ComponentUtils";

/**
 * @callback renderFn
 * @param {Object} props
 * @params {Object} responsiveData
 * @params {DisplayType} responsiveData.display
 * @params {function} responsiveData.Component
 */

/**
 * @callback responsiveFn
 * @param {Object} components
 * @param {function} components.desktop
 * @param {function} components.tablet
 * @param {function} components.mobile
 * @param {function} components.default
 * @param {renderFn} render
 * If render returns undefined, the component is rendered with the props.
 * If render returns an object, the component is rendered with the object as props.
 * If render returns an element, the element is rendered.
 * Null is an element.
 */

/**
 * @type {responsiveFn}
 */
const responsive = (components, updatePropsOrRender) =>{
	if (!components)
		throw new Error("Components (1st argument) needs to be provided.");

	return function ResponsiveComponent({...props}){
		const display = use.context.display()
		const Component = display.select(components);

		let elementOrProps, element;
		if (updatePropsOrRender)
			elementOrProps = updatePropsOrRender(props, {display, Component});

		// check if it's props
		if ((elementOrProps instanceof Object) && !ComponentUtils.isElement(elementOrProps))
			Object.assign(props, elementOrProps); // extends props
		else // then it's an element
			element = elementOrProps;

		if (element === undefined) // render() returned props or void
			element = <Component {...props} />;

		return element;
	}
};

export default responsive;
