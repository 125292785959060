import {styles} from "@main/res";
import SimpleButton from "library-react/component/SimpleButton";
import use from "library-react/hook";
import Icon from "library-react/component/Icon/v2";

export default function AddToBasketButton({addToBasket, ...props}) {
	const {Text, Spinner} = use.theme();

	const [tasking, setTaskingTo] = use.state.bool();
	props.onPress = async function addAllToBasket() {
		if (addToBasket) {
			setTaskingTo(true);
			await addToBasket();
			setTaskingTo(false);
		}
	}
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<SimpleButton
			disabled={tasking}
			filled
			colors={localStyles.colors}
			{...props}>
			{
				tasking ? <Spinner size={20}/> :
					<>
						<Icon
							name="renewOrder"
							size={20}
						/>
						<Text style={localStyles.text}>
							{`RENOUVELER LA COMMANDE`}
						</Text>
					</>
			}
		</SimpleButton>
	)
}
const localStyles = {
	layout: {
		minWidth: 360,
		paddingVertical: 10,
	},
	loader: {},
	colors: {
		primary: '#e4e4e4',
		secondary: styles.color.black,
	},
	text: {
		marginLeft: 10,
		fontSize: 20
	}
}