import listenersRemovers from "./listenersRemovers";
import nodeToEntry from "../utils/nodeToEntry";

export default function listenValueOf(ref, listener){
	// wrap listener: convert node into entry
	const fire = node => {
		const entry = nodeToEntry(node);
		listener(entry);
	};

	ref.on("value", fire);
	return listenersRemovers.add(() => ref.off("value", fire));
}
