import { allPass, complement, defaultTo, gt, isNil, pipe, trim, unless, __ } from "ramda";
import modify from "ramda/es/modify"; // TODO check why is undefined when imported from index
import { Strings } from "../../../../../utils";
import toMoment from "../../../../../utils/toMoment";

export default function validateReservation({ shop } = {}, reservation) {
	const startState = {
		valid: true,
		correction: reservation,
	};



	return [
		validateFullName,
		validateEmail,
		validatePhone,
		validateNumberOfPlaces,
		validateTime,
	].reduce((state, validate, index) => {
		if (!state.valid) // skip
			return state;

		return validate(state.correction, shop);
	}, startState);
}

function validatorFactory(correct, validate) {
	return function (value, ...rest) {
		const correction = correct(value, ...rest);
		const valid = Boolean(validate(correction, ...rest));
		return { valid, correction };
	};
}

const isDefined = complement(isNil);
function fieldValidatorFactory(field, correctField, validateField = isDefined) {
	return validatorFactory(
		(instance, ...rest) => modify(field, value => correctField(value, ...rest), instance),
		(instance, ...rest) => validateField(instance?.[field], ...rest),
	);
}

const correctString = pipe(defaultTo(''), String, trim);
const simpleTextValidatorFactory = field => fieldValidatorFactory(field, correctString, Boolean);

const validateFullName = simpleTextValidatorFactory('fullName');
const validatePhone = simpleTextValidatorFactory('phone');
const validateEmail = fieldValidatorFactory('email', correctString, Strings.isEmail);

const validateNumberOfPlaces = fieldValidatorFactory('numberOfPlaces',
	unless(isNil, Math.trunc),
	allPass([Number.isFinite, gt(__, 0)])
);

const validateTime = fieldValidatorFactory('time',
	(time, shop) => toMoment(shop?.timezone, time)?.format(),
	(time, shop) => {
		// TODO check open hours, spans and closures
		return Boolean(time);
	},
);