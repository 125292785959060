import { useContext } from "react"
import contexts from "../contexts"

export default function useDisplay() {
	const displayEnumInstance: any = useContext(contexts.display);
	return displayEnumInstance.key as ShopWebDisplay;
}

export type ShopWebDisplay =
	| "mobile"
	| "tablet"
	| "desktop"