import React from "react"
import { Pressable, View as RNView } from 'react-native'
import use from "library-react/hook";
import { styles } from "@main/res";
import Image from "../../library-react/component/Image";
import Icon from "../../library-react/component/Icon/v2";
import LoadingPlaceholder from "../../library-react/component/LoadingPlaceholder";
import fonts from "@main/res/fonts";
import { BUTTON_ANIMATED_ON_HOVER_TAG } from "@src/cssTags";
import { parallel } from "library-js/utils/function";

export default function BrandView({ brand, logoStyle, seeMore, discoverButton, ...props }) {
	const display = use.context.display();
	const { Text } = use.theme();

	const hoverEffect = use.memo(() => display.select({
		default: { [BUTTON_ANIMATED_ON_HOVER_TAG]: true },
		mobile: {},
	}),
		[display],
	);


	const [descriptionOpened, setDescriptionOpenedTo] = use.state.bool();
	const openable = seeMore && brand?.description;
	if (openable)
		props.onPress = parallel(setDescriptionOpenedTo.toggle, props.onPress, console.log);

	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<LoadingPlaceholder
			View={openable ? Pressable : undefined}
			disabled={brand} {...props}>
			{
				brand?.logo ?
					<>
						<Image source={brand.logo}
							resizeMode='contain'
							style={[localStyles.image, logoStyle].flat()} />
						<Text
							style={localStyles.description(display)}
							numberOfLines={
								descriptionOpened ? undefined :
									display.select({
										mobile: 3,
										default: 2
									})
							}>
							{brand.description}
						</Text>
					</> :

					<Text
						accessibilityRole="heading"
						style={localStyles.title} numberOfLines={1}>
						{brand?.name}
					</Text>
			}
			{
				discoverButton && brand &&
				<RNView
					style={localStyles.discover.layout(display)}
					dataSet={hoverEffect}>
					<Text style={localStyles.discover.text(display)}>
						{`DÉCOUVRIR`}
					</Text>
					<Icon name="MaterialIcons/arrow-right-alt" size={21} style={localStyles.discover.icon} />
				</RNView>
			}
		</LoadingPlaceholder>
	);
}

const localStyles = {
	layout: {
		borderWidth: 1,
		borderColor: '#9a9999',
		alignItems: styles.alignItems.center,
		justifyContent: styles.justifyContent.spaceEvenly,
	},
	image: {
		alignSelf: styles.alignSelf.stretch,
		height: '40%',
		minHeight: 100,
	},

	description: display => ({
		fontSize: display.is.mobile ? 14 : 20,
		lineHeight: display.is.mobile ? 24 : 36,
		width: '65%',
		textAlign: styles.textAlign.center,
	}),

	title: {
		fontSize: 35,
		bold: true,
	},
	discover: {
		layout: display => ({
			flexDirection: styles.flexDirection.row,
			justifyContent: styles.justifyContent.spaceBetween,
			alignItems: styles.alignItems.center,
			backgroundColor: '#70707020',
			paddingVertical: 10,
			marginVertical: display.select({ mobile: 20, default: 40 }),
			width: display.select({ mobile: 200, default: 220 }),
			paddingHorizontal: display.select({ mobile: 30 }),
		}),

		text: styles.static({
			font: fonts.BebasNeue,
			bold: true,
			marginLeft: 20,
		},
			{
				default: { fontSize: 37 },
				mobile: { fontSize: 22 },
			}),

		icon: {
			marginRight: 20,
			color: styles.color.black,
		}
	}
};
