import {strings} from "../../res";

export const countriesMap = strings.countries;
export const ISOs = Object.keys(strings.countries);
export const countriesList = Object.values(strings.countries);
export const countriesReversedMap = Object.entries(strings.countries)
	.reduce((rMap, [iso, name]) => {
		rMap[name] = iso;
		return rMap;
	}, {});
