import Base from "../../../../class/Base"

/**
 * Abstract timeline event.
 */
export default class Event extends Base {
	/**
	 * @returns {boolean} Ok to be read.
	 */
	get ok(){
		return Boolean(this.id && this.time);
	}

	/**
	 * Tell if 2 events are the same by comparing their sent time or client token.
	 */
	equals(event){
		if (!event || event.constructor.type !== this.constructor.type || event.author !== this.author)
			return false;

		if (event.time && this.time)
			return event.time === this.time;

		return event.clientToken === this.clientToken;
	}
}


Event.addProperties({
	id: String,

	/**
	 * Time the message was sent. Set by the server.
	 */
	time: Number,
});
