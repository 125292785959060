import React from "react"
import {View as RNView} from "react-native"
import use from "../../../../../../../../hook"
import MessageLayout from "../MessageLayout"
import TextContentView from "../TextView"
import {styles} from "../../../../../../../../res"
import ProductPreview from "../../../../ProductPreview";
import View from "../../../../../../../../component/View/v2";
import Chain from "../Chain";
import TimeView from "../../TimeView";
import OrderInfos from "../../../../OrderInfos";

function OrderItemMessageView({message, onOrderItemClicked, ...props}) {
	const vOrderItem = message?.vOrderItem;
	const isAuthor = message.isAuthor;
	const onClicked = onOrderItemClicked && (() => onOrderItemClicked(message.vOrderItem));

	props.style = use.defaultStyle(props.style, localStyles.layout, [isAuthor]);

	return (
		<RNView {...props}>
			{/* order item infos */}
			<View
				onPress={onClicked}
				style={localStyles.main(isAuthor)}>

				<ProductPreview
					product={vOrderItem?.product}
					price={vOrderItem?.price}
					oldPrice={vOrderItem?.oldPrice}
					size={vOrderItem?.size}
				/>

				<OrderInfos
					state={vOrderItem?.vOrder?.currentState || vOrderItem?.item.state}
					orderId={vOrderItem?.item?.orderId}
					creationDate={vOrderItem?.item?.creationDate}
					style={localStyles.orderInfos(isAuthor)}
				/>
			</View>

			{
				Boolean(message?.text) ?
					<>
						<Chain/>
						<MessageLayout message={message} style={localStyles.text}>
							<TextContentView message={message}/>
						</MessageLayout>
					</> :
					<TimeView
						time={message.time}
						style={localStyles.time}
					/>
			}
		</RNView>
	);
}

export default React.memo(OrderItemMessageView);

const localStyles = {
	layout: styles.static.bool(
		{},
		{alignItems: styles.alignItems.flexEnd},
		{alignItems: styles.alignItems.flexStart},
	),

	main: styles.static.bool({
			alignSelf: styles.alignSelf.stretch,
			alignItems: styles.alignItems.flexStart,
		},
		{flexDirection: styles.flexDirection.rowReverse},
		{flexDirection: styles.flexDirection.row},
	),


	orderInfos: styles.static.bool(
		{marginHorizontal: 15},
		{alignItems: styles.alignItems.flexEnd},
		{alignItems: styles.alignItems.flexStart},
	),

	text: {
		minWidth: ProductPreview.WIDTH, // message should not be smaller than the attachment
	},


	time: {
		color: '#1e2432',
		marginTop: 9,
		marginHorizontal: 17,
	}
};
