import useSectionsLoadable from "@main/hook/useSectionsLoadable";
import { paths } from "@main/links";
import pageTitle from "@main/utils/pageTitle";
import { useRoute } from "@react-navigation/native";
import Server from "library-js/app/Server";
import Public from "library-js/app/Server/api/public";
import Search from "library-js/Search";
import timeout from "library-js/utils/timeout";
import useApp from "library-react/app/useApp";
import ScrollView from "library-react/component/ScrollView";
import View from "library-react/component/View";
import use from "library-react/hook";
import React from "react";
import SEO, { loadableToStatus, mergeStatus, optionalLoadableToStatus } from "../../../library-react/component/SEO";
import pen from "../../../library-react/library-js/utils/function/paragraph";
import CommentsModal from "../../component/CommentsModal";
import ErrorView from "../../component/ErrorView";
import Spinner from "../../component/Spinner";
import { useStaticShopTagsNamesLoadable } from "../../hook/useStaticShopTags";
import { styles } from "../../res";
import toReadableSection from "../../utils/toReadableSection";
import toReadableTags from "../../utils/toReadableShopTags";
import toReadableShopTags from "../../utils/toReadableTags";
import { VProdshopContext } from "./contexts";
import ProductSection from "./ProductSection";
import RelativeProducts from "./RelativeProducts";
import { buildSeoParamsForProduct } from "library-js/utils/buildSeoParams";


function ProductUI({ navigation, ...props }) {
	const route = useRoute();
	const { id } = route.params || {};

	const shopTagsNamesLoadable = useStaticShopTagsNamesLoadable();
	const shopTagsNames = shopTagsNamesLoadable.value;


	// shop
	const { shop } = use.context.infos().vShop;
	// product
	const [sectionNames, setSectionNames] = use.state();
	const loadable = use.loadable(() => Search.index.prodshop.getIterator({
		shopId: shop.id,
		inShop: true,
		productId: id,
		available: true
	}).load()
		.then(response => {
			if (response.ok) {
				setSectionNames(Object.keys(response.facets?.sections || {}));
				return response.content.first;
			}
			throw response;
		}),
		[id],
	);

	const sectionsLoadable = useSectionsLoadable();
	const { value: cupboard } = sectionsLoadable;
	const productSections = use.memo(() =>
		cupboard && sectionNames?.map(name => cupboard.getSection(name)).filter(Boolean),
		[sectionNames, cupboard]
	);


	const { value: vProdshop, loading, load } = loadable;

	const app = useApp();
	const openCommentsModal = () => {
		app.openModal(
			<CommentsModal
				name={vProdshop.product.fullTitle}
				title={vProdshop.product.title}
				subtitle={vProdshop.product.shortSubtitle}
				loadRate={() => Public.product.rate(id)}
				createIterator={() => Server.public.comment.getListOnProduct.getIterator(id, null, null, false)}
				loadMyComment={() => Server.user.product.myVComment.get(id)}
				persistMyComment={comment => Server.user.product.myVComment.persist(id, comment)}
			/>
		);
	};

	const display = use.context.display();


	const [basketNotification, setBasketNotification] = use.state(false);
	use.syncEffect( // hide basket notification after 4 seconds
		basketNotification &&
		(() => timeout(
			() => setBasketNotification(false),
			4 * 1000
		)),
		[basketNotification]
	);


	props.style = use.defaultStyle(props.style, localStyles.layout);

	const { price, product } = vProdshop || {};

	const title = props.title = pageTitle([
		product?.fullTitle,
		...[
			productSections
				// keep only leaves
				?.filter(section => !productSections.some(other => other.ascendants.includes(section)))
				.map(toReadableSection)
		].flat(),
		toReadableTags(product?.tags),
		(({ locality, postalCode }) => pen`${locality} ${postalCode}`)(shop.address),
		toReadableShopTags(shopTagsNames),
	]);

	return (
		<View {...props}>

			<SEO
				status={mergeStatus([
					loadableToStatus(loadable),
					optionalLoadableToStatus(shopTagsNamesLoadable),
					optionalLoadableToStatus(sectionsLoadable),
				])}
				canonical={`${paths.product}/${id}`}
				optimizationParams={buildSeoParamsForProduct({ vProdshop, shopTagsNames })}
				image={vProdshop?.product?.mainPicture}
				title={title}
				description={`${price?.formatted} - ${product?.fullTitle}${product?.description ? `: ${product?.description}` : ''}`}
			/>

			<VProdshopContext.Provider value={vProdshop}>
				<ScrollView
					contentContainerStyle={localStyles.container}
					style={styles.fit}>
					{
						vProdshop ?
							<>
								<ProductSection
									title={title}
									onRateClicked={openCommentsModal}
									style={localStyles.product(display)}
								/>

								<RelativeProducts
									productSections={productSections}
								/>
							</>
							: (
								<View style={[styles.center, { flex: 1 }]}>
									{
										loading ? <Spinner /> :
											<ErrorView retry={load} />
									}
								</View>
							)
					}
				</ScrollView>
			</VProdshopContext.Provider>
		</View>
	);
}

export default React.memo(ProductUI);

const localStyles = {
	layout: {
		flex: 1,
	},

	product: styles.static(
		{},
		{
			default: { marginBottom: 50 }
		}
	),

	container: {
		flexGrow: 1,
		paddingBottom: 50,
	},
};
