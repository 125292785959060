import ComponentUtils from "../ComponentUtils";
import React from "react"

export default {
	fromStyle: {
		get(element){
			return element
				&& element.props
				&& ComponentUtils.mergeStyles(element.props.style).color;
		},

		set(element, color){
			if (element && element.props)
				return React.cloneElement(element, {
					key: element.key,
					style: {
						...ComponentUtils.mergeStyles(element.props.style),
						color,
					}
				});
		}
	},

	fromProps: {
		get(element){
			return element
				&& element.props
				&& element.props.color;
		},

		set(element, color){
			if (element && element.props)
				return React.cloneElement(element, {
					key: element.key,
					color
				});
		}
	},
}
