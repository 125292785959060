import React from "react";

import OrderRow from "./OrderRow"
import { styles } from "@main/res";
import { View as RNView } from "react-native";
import use from "library-react/hook";
import Footer from "../Footer";
import FlatList from "library-react/component/FlatList";
import BackNavigationButton from "@main/component/BackNavigationButton";

export default function OrderListDesktopFragment({ title, iterator, ...props }) {
	const items = use.memo(() => iterator.items?.slice(), [iterator.items, iterator.items?.length]);

	const { Text } = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<FlatList
			ListHeaderComponent={() =>
				<RNView style={localStyles.header}>
					<RNView style={localStyles.title.layout}>
						<BackNavigationButton />

						<Text style={localStyles.title.text}>
							{title}
						</Text>
					</RNView>
					<OrderRow.Header />
				</RNView>
			}
			stickyHeaderIndices={STICKY_HEADER}
			data={items}
			keyExtractor={item => String(item.id)}
			renderItem={({ item, index }) =>
				<OrderRow
					item={item}
					style={localStyles.row(index % 2)}
				/>
			}
			onEndReachedThreshold={.0001}
			onEndReached={iterator.ready ? iterator.load : undefined}
			ListFooterComponentStyle={Footer.WRAPPER_STYLE}
			ListFooterComponent={() => <Footer iterator={iterator} />}
			contentContainerStyle={localStyles.content}
			{...props}
		/>
	);
}

const STICKY_HEADER = [0];

const localStyles = {
	header: {
		backgroundColor: styles.color.white,
		borderBottomColor: '#f7f7f7',
		borderBottomWidth: .5,
	},

	title: {
		layout: {
			flexDirection: styles.flexDirection.row,
			alignItems: styles.alignItems.center,
			paddingBottom: 35,
			paddingTop: 24,
			paddingLeft: 31,
		},

		text: {
			fontSize: 40,
			bold: true,
			paddingLeft: 35,
		},
	},


	layout: {
		maxWidth: 1200,
	},

	content: {
		// flexGrow: 1,
	},

	row: styles.static.bool(
		{},
		{ backgroundColor: '#f7f7f7' },
		{},
	),
};
