import Entity from "../Entity"
import ReservationState from "./State";
import {
	__,
	allPass,
	anyPass,
	apply,
	complement,
	forEachObjIndexed,
	gt,
	gte,
	isNil,
	juxt,
	lt,
	map,
	pipe,
	prepend,
	T
} from "ramda";
import moment from "moment";
import 'moment-timezone'
import toMoment from "../../../../utils/toMoment";
import OpenHour from "../Shop/OpenHour";
import callMethod from "../../../../utils/function/callMethod";
import retrieveInvalidFields from "../../../../utils/formValidator/retrieveInvalidFields";
import simpleValidate from "../../../../utils/simpleValidate";
import toIsoDate from "../../../../utils/toIsoDate";

export default class Reservation extends Entity {
	constructor() {
		super();
		this.setDate = this.setDate.bind(this);
		this.setDayTime = this.setDayTime.bind(this);

		/**
		 * @deprecated use setDayTimestamp()
		 */
		this.setTimeWithoutDate = this.setDayTime;
	}

	/**
	 * @deprecated use isValid(shop) because the open hours and closures are neeeded
	 */
	get valid() {
		return this.isValid();
	}

	getInvalidFields(shop) {
		const fields = retrieveInvalidFields(Reservation.validator, this);


		forEachObjIndexed((params, name) => {
			const isValid = Reservation.validator[name](...params);
			if (!isValid(this[name]))
				fields.push(name);
		}, {
			time: [shop],
		});

		return fields;
	}

	isValid(...params) {
		return !this.getInvalidFields(...params).length;
	}

	/**
	 * @deprecated use getDayTimestamp
	 */
	get timeWithoutDate() {
		return this.dayTime;
	}

	getMoment(timezone) {
		return toMoment(timezone || this.timeZone, this.time);
	}

	getDateMoment(timezone) {
		const isoDate = toIsoDate(this.time, timezone);
		if (isoDate)
			return moment.tz(isoDate, timezone);
	}

	getDay(timezone) {
		return this.getMoment(timezone)?.day();
	}

	getHours(timezone) {
		return this.getMoment(timezone)?.hours();
	}

	getMinutes(timezone) {
		return this.getMoment(timezone)?.minutes();
	}


	/**
	 * @deprecated use getDateTimestamp
	 */
	get date() {
		return this.getMoment()?.hours(0)
			.minutes(0)
			.seconds(0)
			.milliseconds(0)
			.valueOf();
	}

	getDayTimestamp(timezone) {
		if (this.time)
			return this.time - (this.getDateTimestamp(timezone) || 0);
	}

	setDayTimestamp(timezone, dayTimestamp) {
		const dateTimestamp = this.getDateTimestamp(timezone);
		if (dateTimestamp) // unable to set daytime without date set
			this.time = (dateTimestamp || 0) + (dayTimestamp || 0);
		return this;
	}


	getDateTimestamp(timezone) {
		return this.getDateMoment(timezone)?.valueOf();
	}

	setDateTimestamp(timezone, timestamp) {
		const dayTimestamp = this.getDayTimestamp(timezone);
		this.time = timestamp + (dayTimestamp || 0);
		return this;
	}

	/**
	 * @deprecated use setDateTimestamp
	 */
	setDate(date) {
		this.time = isNaN(date) ? undefined : date + (this.dayTime || 0);
		return this;
	}

	/**
	 * @deprecated use setDayTimestamp
	 */
	setDayTime(dayTimestamp) {
		if (isNaN(dayTimestamp) && !isNaN(this.time))
			dayTimestamp = 0;

		this.time = isNaN(dayTimestamp) ? undefined
			: (this.date || 0) + dayTimestamp;

		return this;
	}

	/**
	 * @deprecated use getDayTimestamp
	 */
	get dayTime() {
		return (this.time - this.date) || undefined;
	}

	get expired() {
		return !isNil(this.time)
			&& this.time < Date.now();
	}
}

Reservation.State = ReservationState;

Reservation.addProperties({
	id: Number,
	creationDate: Number,
	shopId: Number,
	userId: String,
	email: String,
	phone: String,
	fullName: String,
	time: Number,
	numberOfPlaces: Number,
	instructions: String,
});

Reservation.validator = {
	fullName: () => simpleValidate.text,
	email: () => anyPass([complement(Boolean), simpleValidate.email]),
	phone: () => simpleValidate.text,
	numberOfPlaces: () => allPass([Number.isFinite, gt(__, 0)]),
	time: (shop) =>
		allPass([
			isInFuture,
			shop?.isOpenedAt.bind(shop) || T,
		]),

	date: (shop) => allPass([
		pipe(
			toMoment(shop.timeZone),
			callMethod('isSameOrAfter', new Date, 'day'),
		),
		shop?.isOpenedThisDay.bind(shop) || T,
	]),

	dayTime: (shop, day) => allPass([
		gte(__, 0),
		lt(__, Date.DAY),
		(shop && OpenHour.Days.from(day)) ?
			pipe(
				t => moment.utc(t),
				juxt(map(callMethod, ['hours', 'minutes'])),
				prepend(day),
				apply(shop.isOpenAt.bind(shop)),
			)
			: T,
	]),
};

const isInFuture = allPass([
	Number.isFinite,
	gt(__, Date.now())
]);
