import {curry} from "ramda";

/**
 * ⚠️ Keys will be deleted from the source props, so it must to be mutable.
 * @param what {String[] | Object} A map of key to default values, or an array of keys.
 * @param props {Object} Mutable props.
 * @returns {Object}
 */
function extractProps(what, props) {
	const result = {};

	if (Array.isArray(what)) // array
		what.forEach(key => {
			result[key] = props[key];
			delete props[key];
		});
	else // object
		Object.entries(what)
			.forEach(([key, defaultValue]) => {
				let value = props[key];
				if (value === undefined)
					value = defaultValue;
				result[key] = value;
				delete props[key];
			});

	return result;
}

export default curry(extractProps);
