import React from "react"
import ComponentUtils from "../ComponentUtils";
import {is} from "../library-js/utils"

// TODO wait for solution: https://github.com/react-native-community/react-native-maps/issues/2736
const DisplayGoneContext = React.createContext(true);
export default DisplayGoneContext;

DisplayGoneContext.Dependant = ({style, children}) => {
	let {display} = ComponentUtils.mergeStyles(style);

	return (
		<DisplayGoneContext.Consumer>
			{displayed => {
				displayed = displayed && display !== "none";
				return (
					<DisplayGoneContext.Provider value={displayed}>
						{is(children, Function) ? children(displayed) : children}
					</DisplayGoneContext.Provider>
				);
			}}
		</DisplayGoneContext.Consumer>
	)
};
