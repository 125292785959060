import Base from "../class/Base"

export default class Distance extends Base {
	constructor(metters){
		super();
		this.value = metters;
	}

	get km(){
		return handleFractional(this.value/1000);
	}

	get m(){
		return handleFractional(this.value);
	}

	get formatted(){
		if (this.km >= 10)
			return  Math.round(this.km) + "km";
		else if (this.km > 1)
			return this.km.toFixed(1) + "km";

		return Math.round(this.m) + "m";
	}
}

Distance.addProperties({
	value : Number
});

function handleFractional(value){
	if (!value)
		return value;

	// display 2 decimal if has decimals. None otherwise.
	let fractional = value % 1;
	let integer = Math.trunc(value);
	let numberOfDigits = 2;
	let fractionalAllowed = Math.pow(10, numberOfDigits);

	fractional = Math.trunc(fractional * fractionalAllowed) / fractionalAllowed;

	return integer + fractional;
}
