import React from "react"
import use from "library-react/hook"
import {SIDEBAR_WIDTH} from "../App/Frame/NavigationView/SideBar";
import ModalFrame from "../../library-react/component/ModalFrame";

function Dialog({...props}) {
	const display = use.context.display();
	props.style = use.defaultStyle(props.style, localStyles.layout, [display]);
	return <ModalFrame.Dialog {...props}/>;
}

export default Dialog;


const localStyles = {
	layout: display => !display.is.mobile && {marginLeft: SIDEBAR_WIDTH},
};
