import React from "react";
import {View as RNView} from "react-native"
import {styles} from "@main/res"
import use from "library-react/hook";
import CompanyLogo from "../../../../library-react/component/company/Logo";
import CompanyName from "../../../../library-react/component/company/Name";
import Portal from "library-react/component/Portal";

function ByShopinzon({corporation, textStyle, darkTheme, height = 30, ...props}) {
	const {Text} = use.theme();

	textStyle = use.defaultStyle(textStyle, {
		fontSize: 10,
		color: styles.color.black,
		lineHeight: 10,
		flexShrink: 1,
		textAlign: styles.textAlign.right,
		marginRight: 5,
	});

	props.style = use.defaultStyle(props.style, {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
	});

	return (
		<Portal to="https://shopinzon.com">
			<RNView {...props}>

				<Text
					numberOfLines={1}
					style={textStyle}>
					{`PROPULSÉ PAR`}
				</Text>

				<CompanyLogo
					dark={darkTheme}
					style={{height: 15, width: 15}}/>

				<CompanyName
					dark={darkTheme}
					style={{height: 15, width: 80}}/>

				{
					corporation &&
					<Text style={{fontSize: 13, color: styles.color.black, lineHeight: 13}}>
						{`©`}
					</Text>
				}
			</RNView>
		</Portal>
	);
}

export default React.memo(ByShopinzon);
