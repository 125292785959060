import { assoc } from "ramda";
import React from "react";
import {Keyboard, ScrollView} from "react-native";
import { View as RNView } from 'react-native';
import use from "../../hook";
import useToast from "../../hook/useToast";
import { Server } from "../../library-js/app";
import validateReservation from "../../library-js/app/model/utils/reservation/validate";
import strictAPI from "../../library-js/app/Server/strictAPI";
import { styles } from "../../res";
import willCatchAndToast from "../../utils/willCatchAndToast";
import Icon from "../Icon/v2";
import Image from "../Image";
import MainButton from "../MainButton";


export default function ReservationContactForm({ shop, reservation, setReservation, back, next, ...props }) {
	function syncWith(field) {
		return { // input props
			value: reservation[field],
			onValueChanged: value => setReservation(assoc(field, value)),
		};
	}


	const [submitted, setSubmittedTo] = use.state.bool();
	const [submitting, setSubmittingTo] = use.state.bool();
	const toast = useToast();
	function submit() {
		Keyboard.dismiss();
		setSubmittedTo(true);
		const { valid, correction } = validateReservation({ shop }, reservation);
		if (!valid) return;

		setSubmittingTo(true);
		correction.shopId = shop.id;
		strictAPI(Server.user.reservation.add)(correction)
			.then(next)
			.catch(error => {
				setSubmittingTo(false);
				willCatchAndToast(toast)(error);
			});
	}

	const { Text, TextInput, Spinner } = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<ScrollView
			keyboardShouldPersistTaps="handled"
			{...props}>
			<RNView style={localStyles.header}>
				<Icon
					name="Entypo/chevron-left"
					size={15}
					style={localStyles.back}
					onPress={back}
				/>

				<Text style={localStyles.title}>
					{`On y est presque`}
				</Text>
			</RNView>


			<RNView style={localStyles.shopRow}>
				<Image
					source={{ uri: shop.logoUrl }}
					resizeMode="cover"
					style={localStyles.shopLogo}
				/>

				<Text style={localStyles.shopName}>
					{shop.name}
				</Text>
			</RNView>

			<TextInput
				label={`Nom`}
				{...syncWith('fullName')}
				validate
				submitted={submitted}
				style={localStyles.input}
			/>
			<TextInput
				label={`Telephone`}
				{...syncWith('phone')}
				validate
				submitted={submitted}
				style={localStyles.input}
			/>
			<TextInput
				label={`Email`}
				{...syncWith('email')}
				style={localStyles.input}
			/>


			<TextInput
				label={`Des demandes spéciales?`}
				{...syncWith('instructions')}
				multiline
				inputStyle={{ height: 100 }}
				style={[localStyles.input, { minHeight: 100, marginBottom: 60 }]}
			/>


			<MainButton
				onPress={submit}
				disabled={submitting}
				style={localStyles.submit}>
				{`VALIDER`}
			</MainButton>

			{
				submitting &&
				<RNView style={[styles.fit, styles.center, { backgroundColor: '#ffffffaa' }]}>
					<Spinner size={40} />
				</RNView>
			}
		</ScrollView>
	);
}

const localStyles = {
	layout: {
		padding: 20,
	},

	header: {
		flexDirection: 'row',
		alignItems: 'center',
		marginBottom: 13,
	},

	back: {
		color: 'black',
		paddingRight: 16,
	},


	title: {
		bold: true,
		fontSize: 23,
	},

	shopRow: {
		flexDirection: "row",
		alignItems: 'center',
		marginBottom: 22,
	},

	shopLogo: {
		...styles.circle(23),
		marginRight: 9,
	},

	shopName: {
		fontSize: 17,
	},

	input: {
		marginBottom: 15,
	},

	instructions: {
		marginBottom: 65,
	},
};