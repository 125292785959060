import timeout from "./timeout";

export default function debounce(to, time, shouldSet) {
	let undo;
	return (...params) => {
		undo?.();
		undo = timeout(
			() => {
				if (!shouldSet || shouldSet(params))
					to(...params);
			},
			time,
		);
	};
}
