import reactGlobals from "library-react/globals"
import { clone, Objects } from "library-js/utils";

const apiKeys = {
	google: process.env.REACT_APP_GOOGLE_API_KEY,
	algolia: window.vShop?.shop?.dev ?
		"YzA1ZWY5OTIxYTA3OWQ3M2UyNDI2ZDljMWQ0ZjJkNGNhMGI3M2NhMGZhZjdkNmUxMTQ3NzUzMWUwNmNmNjkwY2ZpbHRlcnM9cHJvZHNob3AuYXZhaWxhYmxlJTNBdHJ1ZQ=="
		: "ZGQ3YTg1OGNlMmRmODE0YTk3MDk5MDU2NDliNmQ1MjY4ZTI2OWNlNGZkNmRiMWUyYTAzZmE2Y2ZkNjIyNjllN2ZpbHRlcnM9cHJvZHNob3AuYXZhaWxhYmxlJTNBdHJ1ZStBTkQrc2hvcC5kZXYlM0FmYWxzZStBTkQrc2hvcC5hY3RpdmF0ZWQlM0F0cnVl",
};

const devParameter = "dev"

export default Objects.deepMerge(
	{
		apiKeys,
		NUMBER_BASKET_ITEMS: 150,
		supportContactUrl: "https://forms.clickup.com/f/3hxg8-1153/R40GF8P6MUOXQKQGG1",
		devParameter,
	},

	clone(reactGlobals),
);