import React from "react";
import {styles} from "../../../res";
import {View as RNView} from "react-native"
import use from "../../../hook";
import View from "../../../component/View/v2";


export default function StepPoint({current = 0, total = 4, ...props}) {
	props.style = use.defaultStyle(props.style, localStyles.layout);
	const mapper = use.memo(
		() => {
			current = Math.max(current, 0);
			total = Math.max(total, current);
			const unselected = total - current;
			return [...Array.range(current, true), ...Array.range(unselected, false)];
		},
		[current, total]
	);
	return (
		<View {...props}>
			{mapper.map((selected, index) => <Dot key={index} index={index} selected={selected}/>)}
		</View>
	);
}

const Dot = React.memo(({index, selected, ...props}) => {
	props.style = use.defaultStyle(
		localStyles.dotSelected(selected),
		localStyles.dot(index),
		[selected && index]
	);

	return <RNView {...props} />;
});

const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
	},
	dot: styles.static.bool(
		{
			...styles.circle(10),
		},
		{
			marginLeft: 10,
		}
	),

	dotSelected: styles.static.bool(
		{
			backgroundColor: '#e4e4e4',
		},
		{
			backgroundColor: '#395aff',
		}
	),
}
