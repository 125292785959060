import React from "react"
import {styles} from "@main/res"
import use from "library-react/hook";
import View from "library-react/component/View";
import ProductItem from "../ProductItem";

import Grid from "../Grid";
import ReadyCallbacks from "@main/component/ReadyCallbacks";
import {screens} from "@main/links";
import Portal from "library-react/component/Portal";

export default function ProductGridDesktop({items, onSelected, ...props}) {
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<View {...props}>
			<Grid
				items={items}
				numberOfColumns={4}
				spaceHorizontalBetweenItems={62}
				spaceVerticalBetweenRows={42}
				renderItem={item =>
					<Portal
						key={item.product.id}
						to={screens.product}
						params={{id: item.product.id}}>
						<ProductItem
							vProdshop={item}
							style={localStyles.item}/>
					</Portal>

				}
			/>
		</View>
	)
}

const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
		flexWrap: styles.flexWrap.wrap,
	},
	item: {
	},
};
