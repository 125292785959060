import map from "./map";
import filter from "./filter";
import reverse from "./reverse";
import pushTo from "./pushTo";
import fromIterator from "./fromIterator";
import move from "./move";
import forEach from "./forEach";

export default {
	map,
	filter,
	reverse,
	pushTo,
	fromIterator,
	move,
	forEach,
}

export {
	map,
	filter,
	reverse,
	pushTo,
	fromIterator,
	move,
	forEach,
}
