import { dec, inc, max, min, pipe } from "ramda";
import React from "react";
import { View as RNView } from "react-native";
import withDefaultStyle from "../../HOC/withDefaultStyle";
import use from "../../hook";
import useOnBack from "../../hook/useOnBack";
import { gql, useQuery } from "../../library-js/app/graphql";
import AuthManager from "../../library-js/AuthManager";
import { styles } from "../../res";
import animateNextRender from "../../utils/animateNextRender";
import responsiveFactory from "../abstract/responsiveFactory";
import FullScreenDialog from "../FullScreenDialog";
import Icon from "../Icon/v2";
import KeyboardSpacer from "../KeybordSpacer";
import ModalFrame from "../ModalFrame";
import ConfirmationForm from "./ConfirmationForm";
import ContactForm from "./ReservationContactForm";
import TimeForm from "./ReservationTimeForm";

export default function ResevationFormModal({ shopId, close, ...props }) {
  use.memo(() => {
    if (!AuthManager.user) console.error("User not authenticated");
  });

  const [reservation, setReservation] = use.state({
    fullName: AuthManager.user?.displayName,
    userId: AuthManager.user?.uid,
    phone: AuthManager.user?.phoneNumber,
    email: AuthManager.user?.email,
    numberOfPlaces: 1,
  });

  let loader = useQuery(GRAPHQL_QUERY, {
    variables: { shopId },
    onCompleted: animateNextRender,
  });

  const [fragmentIndex, setFragmentIndex] = use.state(0);
  const willChangeFragment = (update) => () => {
    animateNextRender();
    setFragmentIndex(update);
  };
  const Fragment = FRAGMENTS[fragmentIndex];
  const isLastFragment = fragmentIndex == FRAGMENTS.length - 1;

  const shop = loader.data?.shop_by_pk;
  const back = willChangeFragment(decIndex);
  const fragmentProps = {
    back,
    next: isLastFragment ? close : willChangeFragment(incIndex),
    shop,
    reservation,
    setReservation,
  };

  useOnBack(fragmentIndex > 0 && back);

  const { ErrorView, Text, Spinner } = use.theme();

  return (
    <Layout {...props}>
      {loader.data ? (
        loader.data.shop_by_pk ? (
          <Fragment {...fragmentProps} />
        ) : (
          // No shop found
          <DefaultFrame>
            <Icon
              name="MaterialIcons/error-outline"
              size={40}
              style={{
                marginBottom: 20,
                color: "red",
              }}
            />
            <Text style={{ textAlign: "center" }}>
              {`Cette boutique n'éxiste pas.`}
            </Text>
          </DefaultFrame>
        )
      ) : (
        <DefaultFrame>
          {loader.loading ? (
            <Spinner size={40} />
          ) : (
            <ErrorView onLoadClicked={loader.refetch} />
          )}
        </DefaultFrame>
      )}

      <KeyboardSpacer />
    </Layout>
  );
}

export const Layout = withDefaultStyle(
  { maxHeight: "100%" },
  responsiveFactory(ModalFrame.BottomSheet, FullScreenDialog)
);

function DefaultFrame({ ...props }) {
  props.style = use.defaultStyle(props.style, [{ height: 500 }, styles.center]);
  return <RNView {...props} />;
}

const FRAGMENTS = [TimeForm, ContactForm, ConfirmationForm];

const incIndex = pipe(inc, min(FRAGMENTS.length - 1));
const decIndex = pipe(dec, max(0));

const GRAPHQL_QUERY = gql`
  query ReservationFormModal_getShop($shopId: bigint!) {
    shop_by_pk(id: $shopId) {
      id
      name
      logo
      logoUrl @client
      openHours
      openHoursList @client
      timezone

      reservationSpans {
        name
        openHours
        openHoursList @client
      }
      reservationClosures {
        title
        start
        days
      }
    }
  }
`;

const localStyles = {
  layout: {},
};
