import React from "react"
import {ActivityIndicator, View as RNView} from "react-native";
import use from "library-react/hook";
import {styles} from "@main/res";
import ScrollView from "../../../library-react/component/ScrollView";
import TitleWithBack from "../../component/TitleWithBack";
import ShowcaseItem from "../../component/ShowcaseItem";
import fonts from "@main/res/fonts";

export default function DesktopUI({title, loadable, onSelected, ...props}) {
	use.subscribe(loadable?.onStateChanged, [loadable]);

	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<ScrollView contentContainerStyle={localStyles.content}
					{...props}>
			<TitleWithBack style={localStyles.title}>
				{title}
			</TitleWithBack>
			<RNView style={localStyles.items}>
				{
					loadable.value?.map((vShowcaseShop, index) =>
						<ShowcaseItem key={vShowcaseShop.vShowcase.id}
									  item={vShowcaseShop.vShowcase}
									  portrait={index % 3 !== 0}
									  style={localStyles.item(index % 3)}/>
					)
				}
			</RNView>
			{loadable.loading && <ActivityIndicator style={localStyles.loading}/>}
		</ScrollView>
	);
}

const localStyles = {
	layout: {},
	content: {
		paddingHorizontal: 52,
		paddingBottom: 26,
	},

	title: {
		paddingLeft: 31,
		font: fonts.BebasNeue,
	},
	items: {
		flexDirection: styles.flexDirection.row,
		justifyContent: styles.justifyContent.spaceBetween,
		flexWrap: styles.flexWrap.wrap,
	},

	item: styles.static.bool(
		{
			marginBottom: 70,
		},
		{
			flexBasis: '47%'
		},
		{
			flexBasis: '100%'
		},
	),

	loading: {
		padding: 50,
	},

}
