import Entity from "../Entity"
import PaymentCard from "../PaymentCard"
import Shop from "../Shop";
import User from "../User";
import State from "./State";
import Item from "./Item";
import ContactInfos from "../ContactInfos";
import PaymentMean from "./PaymentMean";
import ReceptionMean from "../Shop/ReceptionMean";

export default class Order extends Entity {
}
Order.addProperties({
	userId: String,
	shop: Shop,
	user: User,
	card : PaymentCard,

	expectedPaymentMean: PaymentMean,
	paymentMean: PaymentMean,
	receptionMean: ReceptionMean,

	contactInfos: ContactInfos,
	instructions: String,

	creationDate : Number,
});

Order.State = State;
Order.Item = Item;
Order.PaymentMean = PaymentMean;
