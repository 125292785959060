import {Base, Enum} from "../../class"
import HomeConfiguration from "./Home";

export default class ShopWebsiteConfiguration extends Base {
	static Home = HomeConfiguration;

	constructor() {
		super();
		this.type = ShopWebsiteConfiguration.Type.shopping;
	}
}

ShopWebsiteConfiguration.Type = new Enum({
	shopping: 1,
	restaurant: 2,
	service: 3,
	pharmacy: 4,
});


ShopWebsiteConfiguration.Catalog = class CatalogConfiguration extends Base {
	static default(){
		const catalog = new this();
		catalog.name = `Catalogue`;
		return catalog;
	}
}

ShopWebsiteConfiguration.Catalog.addProperties({
	name: String,
	shouldShowBrands: Boolean,
	shouldShowShowcases: Boolean,
});


ShopWebsiteConfiguration.addProperties({
	type: ShopWebsiteConfiguration.Type,
	home: ShopWebsiteConfiguration.Home,
	catalog: ShopWebsiteConfiguration.Catalog,
});
