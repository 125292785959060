import Component from "../../Component"
import {TouchableOpacity, View as RNView} from "react-native"
import React from "react"
import Functions from "../../library-js/utils/Functions"
import {is} from "../../library-js/utils"
import ComponentUtils from "../../ComponentUtils"
import Objects from "../../library-js/utils/Objects"
import DisplayGoneContext from "../../context/DisplayGoneContext"
import Environment from "../../library-js/Environment";

export default class View1 extends Component {
	onResetState(state, props) {
		super.onResetState(state, props);
		if (props.defaultState)
			Object.assign(state, props.defaultState);
	}

	forceUpdate(){
		if (this.mounted)
			super.forceUpdate();
	}

	onConvertProps(props){
		props = super.onConvertProps(props);

		props.ref = this.getInstance;

		let thereIsAChildFunction = props.children.some(child => is(child, Function)) || props.style.some(style => is(style, Function));
		if (thereIsAChildFunction || props.onHover){
			props.onMouseEnter = Functions.prepend(props.onMouseEnter, () => {
				this.hover = true;
				this.forceUpdate();
				props.onHover && props.onHover(true);
			});

			props.onMouseLeave = Functions.prepend(props.onMouseLeave, () => {
				this.hover = false;
				this.forceUpdate();
				props.onHover && props.onHover(false);
			});
		}

		if (thereIsAChildFunction)
			props.onLayout = Functions.prepend(props.onLayout, ([{nativeEvent}]) => {
				let hasChanges = Objects.findKey(nativeEvent.layout, (key, value) => this[key] !== value);
				if (hasChanges){
					Object.assign(this, nativeEvent.layout);
					this.forceUpdate();
				}

				if (this.instance)
					this.instance.measure((x, y, width, height, pageX, pageY) => {
						let dimensions = {x, y, width, height, pageX, pageY};
						let hasChanges = Objects.findKey(dimensions, (key, value) => this[key] !== value);

						if (hasChanges){
							Object.assign(this, dimensions);
							this.forceUpdate();
						}
					});
			});

		// set properties on the instance
		if (props.properties){
			Object.assign(this, props.properties);
			delete props.properties;
		}

		return props;
	}

	getInstance(instance){
		this.instance = instance;
	}

	compareTwoProps(key, currentProp, newProp) {
		return (key === "onPress") ?
			Boolean(currentProp) === Boolean(newProp) : // prevents to render for just onPress changed
			super.compareTwoProps(key, currentProp, newProp);
	}

	onPress(event){
		if (this.props.onClick)
			this.props.onClick(event);

		if (this.props.onPress)
			this.props.onPress();
	}


	onRender({defaultState, params = [], feedback = true, onDefaultPress, ...props}, state){
		props.style = ComponentUtils.executeFunctionChildren(props.style, this, ...params);
		props.children = ComponentUtils.executeFunctionChildren(props.children, this, ...params);

		let ClassComponent = RNView;
		if (props.onPress || props.onClick || onDefaultPress){ // clickable view
			ClassComponent = TouchableOpacity;

			props.onPress = this.onPress;

			if (feedback){
				if (!("delayPressIn" in props))
					props.delayPressIn = 80; // prevent feedback when scrolling
			}
			else { // remove feedback by keeping same opacity when clicked
				props.style = ComponentUtils.mergeStyles(props.style);
				props.activeOpacity = is(props.style.opacity) ? props.style.opacity : 1;
			}

			// TODO check new about web issue: https://github.com/necolas/react-native-web/issues/1219
			// workaround: https://github.com/necolas/react-native-web/issues/1219#issuecomment-491017893
			// (Original issue: https://github.com/necolas/react-native-web/issues/1124)
			// Below a temporary workaround for RNWeb
			if (Environment.is.web && View1.useClickWorkaround){
				props.onClick = props.onPress;
				delete props.onPress;
				props.style = ComponentUtils.defaultStyle({cursor: "pointer"}, props.style);
			}
			// if user still want to use the RN onPress (eg. to use with onFocus / onBlur)
			if (onDefaultPress)
				props.onPress = onDefaultPress;
		}
		else
			delete props.onPress; // might be a false boolean

		let result = <ClassComponent {...props}/>;

		// workaround for https://github.com/react-native-community/react-native-maps/issues/2736
		// pass in the context that the children are not display
		// so map children won't set markers
		Environment.run({
			android(){
				result = (
					<DisplayGoneContext.Dependant style={props.style}>
						{result}
					</DisplayGoneContext.Dependant>
				);
			}
		});

		return result;
	}
}
