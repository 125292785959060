import React from "react"
import View from "../View/v2";
import ComponentUtils from "../../ComponentUtils";
import use from "../../hook";
import { styles } from "../../res"
import Environment from "../../library-js/Environment";
import Platform from "../../library-js/Platform";

export default function Row({ columnsStyles, ...props }) {
	if (!columnsStyles)
		columnsStyles = [];
		
	props.children = ComponentUtils.childrenToArray(props.children)
		.map((child, column) =>
			<View
				key={"cell:" + column}
				style={[defaultStyles.cell, columnsStyles[column]]}>
				{child}
			</View>
		);

	props.style = use.defaultStyle(props.style, defaultStyles.layout);

	return <View hoverable {...props} />;
}

const defaultStyles = {
	layout: [{ flexDirection: styles.flexDirection.row }],
	cell: {
		flex: 1,
		flexBasis: (Environment.is.web && Platform.is.retailer) ? 0 : undefined, // https://app.clickup.com/t/4zqd91
		// overflow: styles.overflow.hidden,
	},
};