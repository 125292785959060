import MobileUI from "./mobile";
import DesktopUI from "./desktop";
import React from "react";
import use from "../../../library-react/hook";
import useAuth from "../../../library-react/hook/useAuth";
import AuthenticateView from "@main/component/AuthenticateView";
import { styles } from "@main/res"
import { gql, useQuery } from "@apollo/client";
import AuthManager from "../../../library-react/library-js/AuthManager";

export default function OrderListFragment({ ...props }) {
	const display = use.context.display();
	props.title = 'COMMANDES';
	const { vShop } = use.context.infos();


	const user = useAuth.user();

	const [end, setEnd] = use.state(false);
	const query = useQuery(ORDER_PAGE, {
		skip: !vShop.id || !AuthManager?.user?.uid,
		variables: {
			shopId: vShop.id,
			userId: AuthManager?.user?.uid,
		},
		onCompleted: (data) => data?.order_v2.length < PAGE_SIZE && setEnd(true)
	});

	props.iterator = {
		items: query.data?.order_v2 || [],
		ready: Boolean(query.data),
		loading: query.loading,
		end,
		load: end ? null : () => {
			const currentLength = query.data?.order_v2.length;
			query.fetchMore({
				variables: {
					offset: currentLength
				}
			}).then(fetchMoreResult => setEnd(fetchMoreResult.data?.order_v2.length < PAGE_SIZE));
		}
	};

	return (
		user ? (
			display.is.mobile ?
				<MobileUI {...props} /> :
				<DesktopUI {...props} />
		) :
			<AuthenticateView style={[styles.center, styles.fit]} />
	);
}


const PAGE_SIZE = 10;
export const ORDER_PAGE = gql`
query orderPage($shopId: bigint, $userId: String, $offset: Int = 0) {
	order_v2(
		where: {shopId: {_eq: $shopId}, userId: {_eq: $userId}}, 
		order_by: {creationDate: desc},
		limit: ${PAGE_SIZE}, 
		offset: $offset 
	) {
	  id
	  creationDate
	  states(limit:1, order_by: {time: desc}) {
		state
		time
	  }
	  items_aggregate {
		aggregate {
		  count
		  sum {
			  totalPrice
		  }
		}
	  }
	}
  }	  
`;