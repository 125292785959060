import Message from "./Message";

export default class TextMessage extends Message {
	get ok(){
		return Boolean(
			super.ok
			&& this.text?.trim()
		);
	}
}
