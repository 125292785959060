import Trigger from "../../library-js/utils/Trigger";
import useSubscribe from "../useSubscribe";
import useMemorise from "../useMemorise";

export default function useComponent(factory, dependencies) {
	const values = useMemorise(() => dependencies?.slice?.() || []);
	return useMemorise((newValues, lastComponent) => {
			values.clear();
			Object.assign(values, newValues || {});
			const render = factory?.(values, lastComponent?.render);

			if (render) {
				if (render === lastComponent?.render) {
					lastComponent.trigger.fire();
					return lastComponent;
				}
				else {
					const trigger = new Trigger();

					function Component(...params) {
						useSubscribe(trigger.add);
						return render(...params);
					}

					Component.render = render;
					Component.trigger = trigger;
					Component.displayName = render.displayName || render.name || DEFAULT_NAME;

					return Component;
				}
			}
		},
		dependencies,
		Component => Component?.trigger.clear()
	);
}

const DEFAULT_NAME = "AnonymousUseComponent";
