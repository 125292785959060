import React from "react"
import {View as RNView} from "react-native"
import {styles} from "../../res"
import Text from "../Text";
import Platform from "../../library-js/Platform";

function OrderInstructionsView({children, ...props}) {
	return (
		<RNView {...props}>
			<Text style={localStyles.title}>
				{Platform.select({
					retailer: `Instructions du client`,
					default: `Vos instructions pour la commande`,
				})}
			</Text>

			<Text style={localStyles.content}>
				{children}
			</Text>
		</RNView>
	);
}

export default React.memo(OrderInstructionsView);


const localStyles = {
	title: {fontSize: 20, bold: true, marginBottom: 10},

	content: {
		padding: 10,
		paddingRight: 5,
		borderLeftWidth: 2,
		borderColor: styles.color.lightgrey,
		italic: true,
		backgroundColor: '#fafafa',
		fontSize: 17,
	}
};
