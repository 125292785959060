import useMemo from "./useMemo";
import runUndo from "./runUndo";
import React from "react";

export default function useSyncEffect(effect, dependencies) {
	if (!dependencies)
		dependencies = [/*No dependencies by default*/];

	const memory = useMemo({ unmounted: false });

	function startEffect() {
		runUndo(memory.undo);

		memory.undo = effect instanceof Function ? effect(...dependencies)
			: undefined;
	}

	if (!dependencies.match(memory.dependencies)) {
		startEffect();
		memory.dependencies = dependencies;
	}

	// undo on unmount
	React.useEffect(() => {
		if (memory.unmounted) { // fix for fast-refresh which runs the undo-effect for unmount and then re-runs the effect
			memory.unmounted = false;
			startEffect();
		}

		return () => {
			memory.unmounted = true;
			runUndo(memory.undo);
		};
	},
		[]
	);
}
