import Media from "./Media"
import Base from "../../../class/Base"
import Environment from "../../../Environment"

export default class Image extends Media {
	static async loadDatas(url) {
		let {width, height} = await Environment.run({
			web: () => new Promise(resolve => {
				let img = document.createElement('img');
				img.onload = () => resolve({width: img.width, height: img.height});
				img.src = url;
			}),

			// TODO android:  use react-native-image-size
			android: () => new Promise((resolve, reject) => {
				Environment.ReactNative.Image.getSize(url, (width, height) =>
					resolve({width, height}), reject);
			}),

			ios: () => {
				throw "Ios has no image cache"
			}, // TODO
		});

		return {width, height};
	}

	getUrl(width) {
		let url = super.getUrl();

		return Image.getUrlWithSize(url, width);
	}

	static getUrlWithSize(url, width) {
		if (width > 0 && this.canResizeFromUrl(url)) {
			let sizeCode = `=s${width}`;
			let newUrl = url.replace(/=s[0-9]+/, "");
			if (newUrl !== url)
				url = newUrl + sizeCode;
			else
				url = newUrl;
		}

		return url;
	}

	static canResizeFromUrl(url) {
		return Boolean(url && url.startsWith("https://lh3.googleusercontent.com/"));
	}

	// -------

	onReset() {
		this.width = null;
		this.height = null;
		this.color = null;
	}

	onLoadDatas(url) {
		return Image.loadDatas(url);
	}

	get ratio() {
		return this.height / this.width;
	}

	get hasDatas() {
		return super.hasDatas
			&& Boolean(
				this.width
				&& this.height
				// && this.color
			);
	}
}

Image.Type = "image";

Image.Crop = class Crop extends Base {
};


// --- properties ---

Image.addProperties({
	width: Number,
	height: Number,
	color: String,
	crop: Image.Crop,
});

Image.Crop.addProperties({
	// position
	x: Number,
	y: Number,
	// dimensions
	width: Number,
	height: Number,
});
