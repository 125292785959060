import React from "react"
import View from "library-react/component/View/v2";
import {styles} from "@main/res";
import OpenableText from "library-react/component/OpenableText";
import GalleryWithCornerArrow from "library-react/component/Gallery/GalleryWithCornerArrow";
import adaptSize from "@main/adaptSize";
import use from "library-react/hook";

export default function Header({title, gallery, description, alt, ...props}) {
	const {Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<View {...props}>
			<GalleryWithCornerArrow
				items={gallery}
				enableFullScreen
				innerStyles={localStyles.gallery.innerStyles}
				alt={alt}/>
			<View style={localStyles.textWithTitle.layout}>
				<Text style={localStyles.textWithTitle.title}>
					{title}
				</Text>

				<OpenableText
					maxChars={400}
					style={localStyles.textWithTitle.text}
					toggleStyle={localStyles.textWithTitle.toggle}>
					{description}
				</OpenableText>
			</View>
		</View>
	);
}

const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.column,
	},
	textWithTitle: {
		layout: {
			flexShrink: 1,
			padding: 20,
		},
		title: {
			fontSize: 23,
			bold: true,
		},
		text: {
			marginTop: 14,
			fontSize: 18,
		},
		toggle: {
			textDecorationLine: styles.textDecorationLine.underline,
		}

	},
	gallery: {
		innerStyles: {
			main: {
				width: adaptSize(655),
				height: adaptSize(319),
			},
		}
	}
};
