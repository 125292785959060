import React from "react"
import use from "library-react/hook"
import DesktopUI from "./desktop"
import MobileUI from "./mobile"

export default React.memo(
	function SizeSelectionModal({...props}) {
		const display = use.context.display();

		return (
			display.is.mobile ?
				<MobileUI {...props} /> :
				<DesktopUI {...props} />
		);
	}
);
