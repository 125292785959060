import Notification from "./Notification";
import VReservation from "../../../view/general/VReservation";
import {Enum} from "../../../../../class";

export default class ReservationNotification extends Notification {
	get ok() {
		return Boolean(
			super.ok
			&& this.vReservation?.reservation?.time
			&& this.vReservation?.currentState?.type
		);
	}
}


const Reason =
	ReservationNotification.Reason = Enum.make({
		create: 'CREATE',
		state: 'STATE',
		update: 'UPDATE',
	});

ReservationNotification.addProperties({
	reason: Reason,
	vReservation: VReservation,
});
