import {Base, Enum} from "../../../class"
import {Image} from "../../model/media";
import PINS from "./PINS"

export default class HomeConfiguration extends Base {
	constructor(){
		super();
		this.mode = HomeMode.wall;
	}

	static defaultFromShop(shop) {
		const home = new this();
		home.banner = shop.gallery.first;
		home.displayTitle = true;
		home.title = shop.name;
		home.subtitle = `Visitez notre établissement`;
		return home;
	}

	static from(json, ...params){
		const homeConfig = super.from(json, ...params);

		homeConfig.pins = (json.pins || [])
			.map(pin => PINS[pin.type]?.from(pin))
			.filter(Boolean);

		return homeConfig;
	}
};

export const HomeMode = HomeConfiguration.Mode = Enum.make(['wall', 'pinList']);


HomeConfiguration.addProperties({
	banner: Image,
	title: String,
	subtitle: String,
	displayTitle: Boolean,
	link: String,

	mode: HomeMode,
	pins: Array,
});
