import VShowcase from "../../model/view/general/VShowcase";
import VReservation from "../../model/view/general/VReservation";
import Showcase from "../../model/entity/Showcase";
import Image from "../../model/media/Image";
import Reservation from "../../model/entity/Reservation";
import VUser from "../../model/view/general/VUser";
import { times } from "ramda";
import { User } from "../../model/entity";
import ReservationSpan from "../../model/entity/ReservationSpan";
import ReservationClosure from "../../model/entity/ReservationClosure";
import OpenHour from "../../model/entity/Shop/OpenHour";

const mock = {
	ReservationClosure: () => ReservationClosure.from({
		title: mock.text(1, 3),
		start: mock.futureTimestamp(),
		days: mock.integer(1, 7), // integer
	}),

	ReservationSpan: () => ReservationSpan.from({
		name: mock.text(1, 3),
		numberOfPlaces: mock.integer(50, 300),
		openHours: times(mock.OpenHour, mock.integer(1, 5)),
	}),

	OpenHour: () => OpenHour.from({
		day: mock.integer(0, 6),
		open: mock.integer(0, 24 * 60 / 2),
		close: mock.integer(24 * 60 / 2, 24 * 60),
	}),

	VReservation: () => VReservation.instantiate()
		.hydrate({
			reservation: mock.Reservation(),
			vUser: mock.VUser(),
			currentState: Reservation.State.from({
				type: mock.pick(Reservation.State.Type.values),
				creationDate: mock.timestamp(),
			}),
		}),

	VUser: () => VUser.instantiate()
		.hydrate({
			user: mock.User(),
		}),

	VShowcaseShop: () => new VShowcase.Shop().hydrate({
		vShowcase: mock.VShowcase(),
		showcaseShop: mock.ShowcaseShop(),
		itemsCount: mock.integer(0, 30),
	}),

	VShowcase: () => new VShowcase().hydrate({
		showcase: mock.Showcase(),
		shopsCount: mock.integer(0),
	}),

	ShowcaseShop: () => new Showcase.Shop().hydrate({
		showcaseId: mock.integer(0),
		shopId: 22,
		activated: mock.boolean(),
		creationDate: mock.timestamp(),
	}),

	Showcase: () => new Showcase().hydrate({
		id: mock.integer(),
		title: mock.text(1, 3),
		description: mock.text(1, 200),
		banner: mock.image(300, 150),
		portrait: mock.image(200, 300),
		gallery: Array.range(mock.integer(0, 3), () => mock.image()),
		ownerShopId: mock.boolean() ? 22 : undefined,
		categories: Array.range(mock.integer(0, 5), () => mock.integer(1, 10)).distinct(),
	}),

	Reservation: () => Reservation.from({
		id: mock.integer(),
		creationDate: mock.timestamp(),
		shopId: mock.integer(),
		userId: mock.integer(),
		email: mock.email(),
		phone: mock.phone(),
		fullName: mock.text(2, 2),
		time: mock.timestamp(Date.now(), Date.now() + Date.WEEK),
		numberOfPlaces: mock.integer(1, 7),
		instructions: mock.boolean() ? mock.text(0, 30) : undefined,
	}),


	User: () => User.instantiate()
		.hydrate({
			name: mock.text(2, 2),
			picture: mock.image(50, 50),
			email: mock.email(),
			phone: mock.phone(),
		}),

	image: (width, height) => new Image(mock.imageURL(width, height)),
	imageURL: (width = mock.integer(10, 20) * 10, height = mock.integer(10, 20) * 10) => {
		const provider = pictureProviders[mock.integer(0, pictureProviders.lastIndex)];
		const url = provider(width, height);
		return `${url}?random=${mock.integer()}`;
	},

	text: (minWords = 1, maxWords = 20) => {
		const numberOfWords = mock.integer(minWords, maxWords);

		let lastDot = 0;
		return Array.range(numberOfWords, () => {
			let word = mock.word();

			if (lastDot > 4 && mock.integer(lastDot, 25) - mock.integer(4, 25) > 0) {
				word += '.';
				lastDot = 0;
			} else {
				if (lastDot === 0)
					word = word.firstUp;

				lastDot++;
			}

			return word;
		}).join(' ');
	},

	email: () => `${mock.word(3, 6)}@${mock.word(3, 6)}.com`,
	phone: () => '0' + times(mock.digit, 9).join(''),

	timestamp: (
		min = Date.now() - Date.YEAR,
		max = Date.now(),
	) => mock.integer(min, max),

	futureTimestamp: (min = Date.now(), max = Date.now() + Date.YEAR) => mock.integer(min, max),

	word: (minLength = 1, maxLength = 10) => Array.range(
		mock.integer(minLength, maxLength),
		mock.letter,
	).join(''),

	letter: () => mock.pick(alphabet),

	pick: array => array[mock.integer(0, array.lastIndex)],

	integer: (min, max) => {
		if (!min)
			min = 0;
		if (!max)
			max = 10 ** 6;

		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min + 1) + min);
	},

	digit: () => Math.trunc(Math.random() * 10),

	boolean: () => Math.random() < .5,
};

export default mock;

const alphabet = "abcdefghijklmnoprstuvwxyz".split("");
window.mock = mock;

const simpleProvider = (origin) => (width, height) => `${origin}/${width}/${height}`
const pictureProviders = [
	simpleProvider('https://picsum.photos'),
	// simpleProvider('https://loremflickr.com'), // too much cats bad quality images
	// simpleProvider('http://lorempixel.com'), // too slow
	(width, height) => `http://placeimg.com/${width}/${height}/any`
];
