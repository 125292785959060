import Text from "../../../component/Text";
import getResourcesForReceptionMean from "../../../library-js/res/receptionMeans";
import React from "react";
import use from "../../../hook";
import CheckoutStateContext from "../CheckoutUI/CheckoutStateContext";

export default function ReceptionInfos({...props}) {
	const {receptionMean} = use.context(CheckoutStateContext);

	const resources = use.memo(
		() => getResourcesForReceptionMean(receptionMean),
		[receptionMean]
	);

	props.style = use.defaultStyle(props.style, localStyles);
	return (
		<Text {...props}>
			{resources.title()}
		</Text>
	);
}
const localStyles = {
	fontSize: 16,
	color: '#1a1824',
}
