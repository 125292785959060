const privates = new WeakMap();
const sort = (a, b) => (a - b);

/**
 * Class to store inside values, generating automatically keys.
 * Methods:
 * <ul>
 *     <li>String|Number {@link store}(value) : store in the object the given value. Returns the generated key.</li>
 *     <li>* {@link pop}(key) : Return the value for the given key, and delete it from the instance.</li>
 *     <li>Array.<String|Number> {@link keys} : All generated keys stored in the instance.</li>
 *     <li>Array {@link values} : All values in generated keys stored in the instance.</li>
 *     <li>Array<{key, value}> {@link sets} : All sets of key values in generated keys stored in the instance.</li>
 * </ul>
 */
export default class AutoMap {
	constructor() {
		privates.set(this, { keyIndex: 0 });
	}


	/**
	 * @deprecated use push()
	 */
	store(value) {
		return this.push(value);
	}

	/**
	 * Store the value into the instance. To retrieve it, just do <code>autoMap[key]</code>.
	 * @param {*} value Value to store.
	 * @returns {Number|String} Generated key for the stored value.
	 */
	push(value) {
		const myPrivates = privates.get(this);
		const key = myPrivates.keyIndex++;
		this[key] = value;
		return key;
	}

	/**
	 * Return the value & delete it from the instance.
	 * @param {String|Number} key Key of the value to pop.
	 * @returns {*} The stored value.
	 */
	pop(key) {
		let record = this[key];
		delete this[key];
		return record;
	}

	/**
	 * @returns {Array.<String|Number>} Array of all generated keys.
	 */
	get keys() {
		return Object.keys(this)
			.mapFiltering(Number, key => !Object.keys(this.constructor.prototype).includes(key))
			.sort(sort);
	}

	/**
	 * @returns {Array} Array of all stored values.
	 */
	get values() {
		return this.keys.map(key => this[key]);
	}

	/**
	 * @returns {Array} Array of all stored sets of key-values.
	 */
	get sets() {
		return this.keys.map(key => ({ key, value: this[key] }));
	}

	clear() {
		this.keys.forEach(key => { delete this[key] });
	}
}
