import company from "./company"

export default {
	company,
	interlocutor: require("./interlocutor"),
	chatSale: require("./chat-sale.png"),
	chatContextButton: require("./chat-context-button.png"),
	chatSendButton: require("./chat-send-button.png"),
	chatBuyButton: require("./chat-buy-button.png"),
	googleAttribution: require("./google-attribution"),
	emptyResult: require("./empty-result.png"),
}
