import React from "react"
import View from "library-react/component/View";
import {styles} from "@main/res";
import OpenableText from "library-react/component/OpenableText";
import GalleryWithCornerArrow from "library-react/component/Gallery/GalleryWithCornerArrow";
import adaptSize from "@main/adaptSize";
import RateView from "../RatePreview";
import use from "library-react/hook";
import CommentsModal from "../CommentsModal";
import Server from "library-js/app/Server";
import Public from "library-js/app/Server/api/public";
import useApp from "library-react/app/useApp";
import GalleryModal from "library-react/component/GalleryModal";

function Header({title, rate, gallery, description, alt, ...props}) {
	const {vShop} = use.context.infos();
	const {id} = vShop;

	const app = useApp();
	const openCommentsModal = use.callback(() => {
			const close = app.openModal(
				<CommentsModal
					name={vShop.name}
					title={vShop.name}
					rate={vShop.rate}
					loadRate={() => Public.shop.rate(id)}
					createIterator={() => Server.public.comment.getListOnShop.getIterator(id, null, false)}
					loadMyComment={() => Server.user.shop.myVComment.get(id)}
					persistMyComment={comment => Server.user.shop.myVComment.persist(id, comment)}
					close={() => close()}
				/>
			);
		},
		[vShop]
	);

	const {Text} = use.theme();

	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<View>
			<View {...props}>
				<View style={localStyles.textWithTitle.layout}>
					<Text style={localStyles.textWithTitle.title}>
						{title}
					</Text>

					<OpenableText
						maxChars={400}
						style={localStyles.textWithTitle.text}
						toggleStyle={localStyles.textWithTitle.toggle}>
						{description}
					</OpenableText>

					{
						rate &&
						<RateView
							rate={rate}
							onPress={openCommentsModal}
						/>
					}
				</View>

				<GalleryWithCornerArrow
					items={gallery}
					enableFullScreen
					innerStyles={localStyles.gallery.innerStyles}
					style={localStyles.gallery.layout}
					alt={alt}/>
			</View>

			<Separator color='#dad9e1'/>
		</View>
	);
}

export default React.memo(Header);

function Separator({color}) {
	return (
		<View style={{height: 2, width: "100%", marginTop: 22, backgroundColor: color}}/>
	);
}

const localStyles = {
	layout: {
		justifyContent: styles.justifyContent.spaceBetween,
		flexDirection: styles.flexDirection.row,
	},
	textWithTitle: {
		layout: {
			flexShrink: 1,
			maxWidth: adaptSize(655),
		},
		title: {
			fontSize: adaptSize(23),
			bold: true,
		},
		text: {
			marginTop: adaptSize(10),
			fontSize: adaptSize(20),
			flex: 1,
			lineHeight: 24,
		},
		toggle: {
			textDecorationLine: styles.textDecorationLine.underline,
		}

	},
	gallery: {
		layout: {
			alignSelf: styles.alignSelf.flexStart,
		},
		innerStyles: {
			main: {
				width: adaptSize(655),
				height: adaptSize(319),
			},
		}
	}
};
