import {Functions} from "../../../utils";

const cacheUpdateCenter = ["VShop", "VProduct", "VProdshop", "VSale", "VSaleProduct", "VOrder", "ShopWebsiteConfiguration", "Client", "VUser", "Brand", "Collection"]
	.toObject(
		type => type,
		() => ({
			persist: {
				listeners : [],

				listen(key, callback){
					if (callback)
						this.listeners.push({key, callback});
				},

				fire(myKey, ...items){
					this.listeners.forEach(({key, callback}) => key !== myKey && callback(items));
				},
			},

			update : {
				listeners : [],

				listen(callback){
					if (callback)
						this.listeners.push(callback);
				},

				fire(predicate, update){
					this.listeners.forEach(callback => callback(predicate, update));
				},
			},
		}),
	);


// VProdshop trigger should update VShop & VProduct
cacheUpdateCenter.VProdshop.persist.fire = Functions.prepend(cacheUpdateCenter.VProdshop.persist.fire, ([key, ...vProdshops]) => {
	let vShops = vProdshops.mapFiltering(({vShop}) => vShop, Boolean);
	cacheUpdateCenter.VShop.persist.fire(key, ...vShops);

	let vProducts = vProdshops.mapFiltering(({vProduct}) => vProduct, Boolean);
	cacheUpdateCenter.VProduct.persist.fire(key, ...vProducts);
});

// Client trigger should update VUser
cacheUpdateCenter.Client.persist.fire = Functions.append(cacheUpdateCenter.Client.persist.fire, (_, key, ...clients) => {
	let vUsers = clients.map(({vUser}) => vUser);
	cacheUpdateCenter.VUser.persist.fire(key, ...vUsers);
});


export default cacheUpdateCenter;
