import {Base, Enum} from "../../class";
import {Shop} from "library-js/app/model/entity";
import ReceptionMean from "library-js/app/model/entity/Shop/ReceptionMean";
import isEligibleForReservations from "library-js/app/isEligibleForReservations";
import {curry, isNil} from "ramda";

function parse(source, Target, json) {
	const clean = prepareParsing(source);
	try {
		return Base.from(json, Target);
	} finally {
		clean?.();
	}
}

export default curry(parse);

/**
 * @type {{server: Enum, algolia: Enum, values: Enum[]}}
 */
export const SourceModel = Enum.make(['server', 'algolia']);


function prepareParsing(source) {
	source = SourceModel.from(source);

	if (!source) return;
	return overrides.reduce((clean, {sources, Class, adjust}) => {
		if (sources.includes(source)){
			const originalFrom = Class.from;

			Class.from = function (...p) {
				const result = originalFrom.apply(this, p);
				const edition = adjust(result);
				return edition === undefined ? result : edition;
			};

			return () => {
				Class.from = originalFrom;
				clean?.();
			};
		}
		else
			return clean;
	}, null);
}


const overrides = [
	{
		sources: SourceModel.values,
		Class: Shop,
		adjust(result) {
			if (!(result.receptionMeans?.length > 0))
				result.receptionMeans = [ReceptionMean.collect];

			if (!isEligibleForReservations(result.categoryId))
				result.acceptsReservations = false;

			if (isNil(result.ordersAlwaysOpened))
				result.ordersAlwaysOpened = true;
		},
	},
];
