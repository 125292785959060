import Base from "../../class/Base"

export default class Badges extends Base {
	constructor(){
		super();
		this.spender = false;
		this.unresolved = false;
		this.friendly = false;
	}
}
Badges.addProperties({
	spender : Boolean,
	unresolved : Boolean,
	friendly : Boolean,
});
