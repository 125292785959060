import Debug from "../../Debug"
import InfiniteIterator from "../../InfiniteIterator"
import Response from "./Response"
import {is} from "../../utils"

/**
 * Must implement :
 * <ul>
 *     <li><b>Server.Response function(cursor, page)</b> Function called when loading data. Receive the last cursor (returned by {@link Index.Iterator.getCursor} from the last response).</li>
 *     <li><b>Server.Response getCursor(response)</b> (optional) Function called when response success & if the iterator didn't reached the end.</li>
 *     <li><b>Number (+) minimum</b> (optional) Minimum response content's length. If less, it means the iterator reached the end (iterator.end = true).</li>
 * </ul>
 * Extends {@link InfiniteIterator}
 */
export default class Iterator extends InfiniteIterator.extendsWith({minimum : Number}) {
	constructor(){
		super();
		this.minimum = 1;
		this.items = [];
	}

	get state(){
		const {States} = InfiniteIterator;
		const state = super.state;
		const response = this.last.result;
		if ([States.idle, States.end].includes(state) && response && !response.ok)
			return States.error;

		return state;
	}

	setMinimum(value){
		// force positive number
		super.setMinimum(value > 0 ? value : 1);
	}

	load(){
		try {
			return super.load();
		} catch (error) {
			return is(error, Response) ? /* Response */ error : new Response(Response.Status.BUG, error);
		}
	}

	async onLoading(page){
		// check if function is implemented
		const implemented = is(this.function, Function);
		Debug.assert(!implemented, "Iterator hasn't implemented function property.", true);
		if (!implemented)
			return Response.BUG;

		// send
		let response = await this.function(this.cursor, page);
		response = this.resolveResponse(response);

		// check response & get cursor (if implemented)
		if (response.ok){
			this.items.push(...response.content);

			if (is(this.getCursor, Function) && response.content.length >= this.minimum)
				this.cursor = this.getCursor(response);
		}
		else {
			response.log();
			throw response;
		}

		// return
		return response;
	}

	resolveResponse(response){
		return response;
	}

	isEnd(page, response){
		return response && response.ok && response.content.length < this.minimum;
	}

	/**
	 * Must return the cursor to send fot the next loading.
	 * Called only if response success & response.content.length >= minimum property.
	 */
	getCursor(response){}
}
