import React from "react";
import {ActivityIndicator as Spinner, AppState, FlatList} from "react-native";
import use from "../../../../../hook";
import {ChatManager} from "../../../../../library-js/app/model/chat";
import EventView from "./EventView"
import {styles} from "../../../../../res"
import Welcome from "../../Welcome";
import Platform from "../../../../../library-js/Platform";
import Environment from "../../../../../library-js/Environment";
import WebLayout from "./WebLayout";

function Timeline(
	{
		interlocutorId,
		interlocutorLoadable,
		visible,
		onOrderClicked,
		onProductClicked,
		onOrderItemClicked,
		onReservationClicked,
		hidePrice,
		...props
	}
) {
	const iterator = use.infiniteIterator(
		() => ChatManager.getEventIterator(interlocutorId),
		[interlocutorId]
	);
	const items = iterator.items;

	const memory = use.memo({lastIdRead: null});

	use.effect(lastEventId => {
			if (!lastEventId) return; // not visible or no event to read

			const onRead = ({active}) => {
				if (active && lastEventId !== memory.lastIdRead) {
					memory.lastIdRead = lastEventId;
					ChatManager.onRead(interlocutorId);
				}
			};

			onRead(AppState.is); // check now
			return AppState.listen(onRead); // listen for app state updates
		},
		[
			visible
			&& iterator.items.first?.id
		]
	);

	use.subscribe(render =>
			ChatManager.listenEvents(
				interlocutorId,
				newEvent => {
					iterator.items.unshift(newEvent);
					render();
				}
			),
		[iterator]
	);

	const Layout = Environment.is.web ? WebLayout : FlatList;
	const {Spinner} = use.theme();
	return (
		<Layout
			data={items}
			keyExtractor={keyExtractor}
			renderItem={({item, index}) => (
				<EventView
					previous={items[index + 1]}
					item={item}
					onOrderClicked={onOrderClicked}
					onOrderItemClicked={onOrderItemClicked}
					onProductClicked={onProductClicked}
					onReservationClicked={onReservationClicked}
					hidePrice={hidePrice}
				/>
			)}
			inverted
			keyboardShouldPersistTaps="always"
			contentContainerStyle={localStyles.container}
			style={localStyles.layout}
			onEndReached={iterator.ready ? iterator.load : undefined}
			onEndReachedThreshold={.01}
			ListFooterComponent={ // on top
				iterator.end ?
					<Welcome
						interlocutorLoadable={interlocutorLoadable}
						style={localStyles.welcome}>
						{
							Platform.select({
								user: `Bienvenue chez`,
								retailer: `Vous discutez avec`,
							})
						}

					</Welcome> :
					<Spinner style={{marginVertical: 30, alignSelf: styles.alignSelf.center}}/>
			}
			{...props}
		/>
	);
}

export default React.memo(Timeline);

const keyExtractor = event => event.id;


const localStyles = {
	layout: {
		flex: 1,
	},

	container: {
		justifyContent: styles.justifyContent.flexEnd,
		flexGrow: 1,
		paddingHorizontal: 22,
	},

	welcome: {
		marginTop: 20,
		marginBottom: 10,
	}
};
