import React from "react"
import CatalogSampleViewMobile from "../../../../../../component/CatalogSampleView/mobile"
import ScrollView from "../../../../../../../library-react/component/ScrollView";
import use from "../../../../../../../library-react/hook";
import {screens} from "../../../../../../links";


export default function MobileUI({loading, iterator, brand, ...props}) {
	const content = (
		(iterator?.items.length > 0 || iterator?.end) ? // content ready
			iterator.items.slice(0, CatalogSampleViewMobile.MAX_ITEMS) // products
			: (loading && Array.range(CatalogSampleViewMobile.MAX_ITEMS, null)) // loading placeholder
	);

	const facet = iterator?.facets ? iterator.facets['product.brand'] : null;
	const numberOfItemsLeft = facet && Math.max(Object.values(facet)[0] - MobileUI.MAX_ITEMS, 0);


	const portalProps = use.memo({
			to: screens.brand,
			params: {name: brand}
		},
		[brand],
	);
	return (
		(content?.length > 0) &&
		<ScrollView horizontal {...props}>
			<CatalogSampleViewMobile
				iterator={iterator}
				loading={loading}
				content={content}
				portalProps={portalProps}
				numberOfItemsLeft={numberOfItemsLeft}
				titleStyle={localStyles.title}
				seeMoreOnLastProduct
				noCards
				gap={48}
			/>
		</ScrollView>
	);
}

const localStyles = {
	title: {
		display: 'none',
	},
}
