import Base from "../../class/Base"

export default class Comment extends Base {
	constructor(stars = 0, text = undefined) {
		super();
		this.stars = stars;
		this.text = text;
	}
}
Comment.addProperties({
	stars: Number,
	text: String,
	creationDate: Number,
});
