import React from "react"
import rowStyles from "./styles";
import {styles} from "@main/res"
import use from "library-react/hook";
import Row from "library-react/component/Row";

function OrderRowHeader({noUser, ...props}) {
	const {Text} = use.theme();
	props.style = use.defaultStyle(props.style, rowStyles.row);

	return (
		<Row columnsStyles={rowStyles.columns} {...props}>

			{/*	Titles */}
			{
				TITLES.map((title, index) =>
					<Text key={index}
						  style={localStyles.text}>
						{title}
					</Text>
				)
			}
		</Row>
	);
}

export default React.memo(OrderRowHeader);

const TITLES = ['Achat', 'Statut', 'Quantite', 'Total', ''];

const localStyles = {
	text: {
		textAlign: styles.textAlign.left,
		color: "#9a9999",
		fontSize: 20,
		bold: true,
	}
};
