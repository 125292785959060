import useEffect from "./useEffect"
import useMemo from "./useMemo"

export default function setRef(setRef, anInterface){
	const memory = useMemo(() => ({interface: getInterface(anInterface)}));

	useEffect(
		() => set(setRef, memory.interface),
		[setRef]
	);

	return memory.interface;
}

function set(refProp, instance){
	let undo;

	if (refProp instanceof Function){
		refProp(instance);
		undo = () => refProp(undefined);
	}
	else if (refProp) {
		refProp.current = instance;
		undo = () => refProp.current = undefined;
	}

	return undo;
}

setRef.set = set;

function getInterface(factoryOrInterface){
	return factoryOrInterface instanceof Function ? factoryOrInterface() : factoryOrInterface;
}
