import { StyleSheet } from "react-native";
import { is } from "../library-js/utils";
import useMemo from "./useMemo";

export default function useDefaultStyle(style, defaultStyles, dependencies) {
	return useMemo(
		() => {
			if (is(defaultStyles, Function))
				defaultStyles = defaultStyles(...(dependencies || []));
 
			return [StyleSheet.compose(defaultStyles, style)].flat();
		},
		[style, ...(dependencies || [])]
	);
}
