import use from "../../../hook";
import parallel from "../../../library-js/utils/function/parallel";

export default function focusable(props, data) {
	let {focusable, onFocusChanged} = props;
	delete props.focusable;
	delete props.onFocusChanged;

	// make it focusable
	focusable = !data.wasDisabled && (data.pressable || focusable);

	if (focusable && !data.pressable){
		props.onPress = DO_NOTHING;
		props.disabled = false; // cancel isPressable's disabling
	}

	const [focus, setFocusTo] = use.state.bool(false);
	use.onChange(onFocusChanged, [focus]);
	data.focus = focus;

	const debounce = use.callback(fn => {
		clearTimeout(debounce.key);
		debounce.key = setTimeout(fn);
	});

	if (focusable){
		const {onFocus, onBlur} = props;
		props.onFocus = () => debounce(parallel([setFocusTo.true, onFocus]));
		props.onBlur = () => debounce(parallel([setFocusTo.false, onBlur]));
	}
}

const DO_NOTHING = () => 0;
