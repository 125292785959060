import Enum from "../../class/Enum"
import Base from "../../class/Base"
import Debug from "../../Debug"

/**
 * A server request.
 **/
export default class Response extends Base {
	constructor(status, content) {
		super();
		this.status = status;
		this.content = content;
	}

	log() {
		if (this.ok)
			console.trace(this.toString());
		else
			console.warn(
				this.status === Response.Status.BUG ?
					(this.content || JSON.stringify(this)) :
					JSON.stringify(this)
			);
	}

	/**
	 * @returns {boolean} True if the state is {@link Response.Status.Success}. False otherwise.
	 */
	get ok() {
		return this.status === Response.Status.Success;
	}

	/**
	 * @returns {Error|undefined} Error object if response not ok.
	 */
	get error() {
		if (!this.ok) {
			const error = new Error(this.message || this.toString());
			error.code = this.status.value;
			error.response = this;
			return error;
		}
	}

	toString() {
		return JSON.stringify({
			...this.toJSON(),
			codeError: this.status?.key,
		}, null, 2); // for logs
	}

	throw() {
		throw this.error || this;
	}
};

/**
 * All possibles status of a response from a server.
 * https://docs.google.com/document/d/1Q6V9MqC98t4Qh5qAK3mx6SAly-ToDvF99DN-Q2TTAZs/edit#bookmark=id.xzk7uphv4hbw
 **/
Response.Status = Enum.make({
	BUG: -2,
	NetworkError: -1,

	Success: 26,

	InvalidRequest: 100,

	DatabaseError: 200,
	NotFound: 201,

	InvalidToken: 300,
	InvalidEmail: 301,
	InvalidPassword: 302,

	AnonymeUserNotAuthorized: 303,
	AccessDenied: 304,
	UserNotSignedIn: 305,

	ServerError: 500,

	PaymentSystemException: 600,
	NoRessourceForPaymentSystem: 601,
	NoUserCreatedForPayment: 602,

	ServerDead: 800,
});

Response.addProperties({
	// request: Request, // added by Request.js
	status: Response.Status,
	message: String,
	content: null
});

// add a isSuccess method and defines server response with ready status
Response.Status.forEach((key, status) => {
	// define a boolean isSuccess on each status
	Object.defineProperties(status, {
		/**
		 * @deprecated
		 */
		isSuccess: {
			configurable: true,
			get: function () {
				Debug.assert(true, "Response.Status.isSuccess is deprecated. Use Response.Status.ok.", true);
				return this.ok;
			},
		},

		ok: {
			configurable: true,
			value: (status === Response.Status.Success),
		}
	});

	// define a pre-ready status response.
	Object.defineProperty(Response, key, {
		configurable: true,
		get: function () {
			return new Response(status);
		}
	});
});
