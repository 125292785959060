import React from "react"
import moment from "moment";
import Header from "./Header"
import rowStyles from "./styles";
import { styles } from "@main/res"
import use from "library-react/hook";
import Row from "library-react/component/Row"
import Icon from "library-react/component/Icon/v2";
import StateView from "library-react/component/OrderStateView/StateView";
import View from "library-react/component/View";
import { screens } from "@main/links";
import Portal from "library-react/component/Portal";
import { sum, map, prop } from "ramda";

let OrderRow;
export default OrderRow = React.memo(({/* VOrder */ item: order, ...props }) => {
	const { id } = order;
	const currentState = order?.states[0]?.state;
	const price = order.items_aggregate?.aggregate?.sum?.totalPrice;
	const numberOfItems = order.items_aggregate?.aggregate?.count;

	props.style = use.defaultStyle(props.style, localStyles.layout);
	props.columnsStyles = rowStyles.columns;
	const [hover, setHover] = use.state(false);
	props.onHoverChanged = setHover;

	const { Text } = use.theme();

	return (
		<Portal to={screens.order} params={{ id }}>
			<Row {...props}>
				<View style={localStyles.infos.layout}>
					<Text style={localStyles.infos.date(hover)}>
						{'Cde du'} {moment(order.creationDate).format("DD/MM/YYYY")}
					</Text>
					<Text style={localStyles.infos.id}>
						{id}
					</Text>
				</View>

				{/* State */}
				<StateView
					state={currentState}
					receptionMean={order.receptionMean}
					displayText
					background
					innerStyles={localStyles.state.innerStyles}
					style={localStyles.state.layout} />

				{/* Nb of items */}
				<Text style={localStyles.text}>
					{numberOfItems}
				</Text>

				{/* Price */}
				{
					price &&
					<Text style={localStyles.price}>
						{price}
					</Text>
				}

				{/* Nb of items */}
				<Icon name={'FontAwesome/caret-right'} size={16} style={localStyles.caret} />
			</Row>
		</Portal>

	);
}
);

OrderRow.Header = Header;
export { OrderRow as Row, Header }


const localStyles = {
	layout: rowStyles.row,

	infos: {
		layout: {},
		date: styles.static.bool({
			color: '#1e2432',
			fontSize: 20,
		},
			{
				textDecorationLine: styles.textDecorationLine.underline,
			}
		),
		id: {
			color: '#acb1c0',
			fontSize: 16,
		},
	},

	text: {
		fontSize: 19,
		color: styles.color.main,
	},

	state: {
		layout: {
			paddingVertical: 9,
			width: 260,
			borderRadius: 0,
		},
		innerStyles: {
			text: { fontSize: 15 }
		}
	},
	price: {
		color: '#504747',
		fontSize: 21,
		bold: true,
	},

	caret: {}

};

