import {useState} from "react"
import useMemo from "../useMemo";
import {pipe} from "ramda";

export default function useBooleanState(defaultvalue = false) {
	const [state, setState] = useState(() => Boolean(
		defaultvalue instanceof Function ? defaultvalue() : defaultvalue
	));

	const controls = useMemo(() => ({
		toggle: () => setState(state => !state),
		true: () => setState(true),
		yes: () => setState(true),
		false: () => setState(false),
		no: () => setState(false),
	}));

	return [
		state,
		useMemo(() => Object.assign(
			function set(state){
				if (state instanceof Function)
					setState(pipe(state, Boolean));
				else
					setState(Boolean(state));
			},
			controls
		)),
		controls,
	];
}
