import React from "react";
import View from "../../../../library-react/component/View/v2";
import use from "../../../../library-react/hook";
import {strings, styles} from "../../../res";
import Image from "../../../../library-react/component/Image";
import LoadingPlaceholder from "../../../../library-react/component/LoadingPlaceholder";
import fonts from "@main/res/fonts";

export default function ArticleUI({item: article, inverted, ...props}) {
	const {Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout, [inverted]);
	return (
		<View {...props}>
			{
				article.media &&
				<Image source={{uri: article.media, width: 500}}
					   resizeMode="cover"
					   style={localStyles.image}/>
			}
			<LoadingPlaceholder disabled={article} style={localStyles.wrapper}>
				<Text style={localStyles.title} numberOfLines={1}>{article?.title}</Text>
				<Text style={localStyles.description}>
					{article?.description || strings.invisibleChar}
				</Text>
			</LoadingPlaceholder>
		</View>
	);
}

const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.column,
		marginBottom: 35,
	},
	image: {
		height: 275,
		width: '100%',
		marginBottom: 21,
	},

	wrapper: {
		alignSelf: styles.alignSelf.center,
		borderRadius: 5,
		maxWidth: 450,
		width: '100%',
	},

	title: {
		font: fonts.BebasNeue,
		marginBottom: 17,
		fontSize: 35,
		bold: true,
	},
	description: {
		fontSize: 15,
		lineHeight: 23,
	},
}
