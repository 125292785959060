import React from "react"
import {Animated, Linking, ScrollView, View as RNView} from "react-native";
import use from "library-react/hook"
import {styles} from "@main/res"
import Icon from "library-react/component/Icon/v2";
import useAuth from "library-react/hook/useAuth";
import MenuItem from "./MenuItem";
import Objects from "library-js/utils/Objects";
import ByShopinzon from "../ByShopinzon";
import AuthManager from "library-js/AuthManager";
import DisplayType from "@main/contexts/DisplayType";
import globals from "@main/globals";
import fonts from "@main/res/fonts";

function MobileMenu({animatedValue, close, menuItems, navigationItems, onAccountClicked, ...props}) {
	const {shop} = use.context.infos().vShop;
	const user = useAuth.user();
	use.subscribe(render => !AuthManager.ready && AuthManager.onceReady.then(render));

	const {Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout, [animatedValue]);

	return (
		<Animated.View {...props}>

			{/* Menu */}
			<ScrollView
				style={localStyles.scroll.layout}
				contentContainerStyle={localStyles.scroll.container}>

				{/* Navigation */}
				<RNView>
					{
						menuItems.map((item) => (
							<MenuItem {...item}
									  close={close}/>
						))
					}
				</RNView>
				<RNView style={localStyles.navigation.layout}>
					{
						navigationItems.map((item) => (
							<MenuItem
								{...item}
								close={close}/>
						))
					}
				</RNView>

				{/* annex */}
				<RNView style={localStyles.annex.layout}>
					{
						user &&
						<Text
							numberOfLines={1}
							style={[
								localStyles.annex.item,
								{color: "#0ab9ed", fontFamily: fonts.HelveticaNeue.bold}
							]}>
							{`BONJOUR `}{user?.displayName?.toUpperCase()}
						</Text>
					}
					{
						AuthManager.ready &&
						<Text
							numberOfLines={1}
							onPress={onAccountClicked}
							style={[localStyles.annex.item, {fontFamily: fonts.HelveticaNeue.bold}]}>
							{user ? `MON COMPTE` : `SE CONNECTER`}
						</Text>
					}
					<Text
						numberOfLines={1}
						{...Linking.linkPropsFor(`mailto:${shop.email}`)}
						style={localStyles.annex.item}>
						{`CONTACT`}
					</Text>

					<Text
						numberOfLines={1}
						{...Linking.linkPropsFor(globals.supportContactUrl)}
						style={localStyles.annex.item}>
						{`SUPPORT`}
					</Text>

					<RNView style={localStyles.annex.last.layout}>
						<Text
							numberOfLines={1}
							onPress={() => window.open('https://shopinzon.com/legal-agreement', "_blank")}
							style={localStyles.annex.item}>
							{`MENTIONS LÉGALES`}
						</Text>

						{
							user &&
							<Icon
								name='logout'
								onPress={AuthManager.signOut}
								size={18}
								style={localStyles.annex.last.logout}/>
						}
					</RNView>

				</RNView>

			</ScrollView>

			{SEPARATOR}

			{/* Footer */}
			<RNView style={localStyles.footer.layout}>
				{
					Objects.setsOf(shop.socialLinks)
						.map(({key: network, value: link}, index) =>
							<Icon
								key={network}
								name={network}
								size={35}
								style={(index > 0) && localStyles.footer.icon}
								{...Linking.linkPropsFor(link)}
							/>
						)
				}

				<ByShopinzon
					darkTheme
					textStyle={localStyles.footer.byShopinzon.text}
					style={localStyles.footer.byShopinzon.layout}/>
			</RNView>

			{/* Close */}
			<Icon
				name="EvilIcons/close"
				size={25}
				onPress={close}
				style={localStyles.close}
			/>
		</Animated.View>
	);
}

export default React.memo(MobileMenu);

const SEPARATOR = (
	<RNView style={{
		marginHorizontal: 52,
		borderBottomWidth: .5,
		borderColor: styles.color.white,
	}} />
);

const localStyles = {
	layout: av => ({
		...styles.fit,
		backgroundColor: styles.color.black,
		transform: [
			{
				translateX: av.interpolate({
					inputRange: [0, 1],
					outputRange: [-DisplayType.tablet.value, 0],
				})
			}
		]
	}),

	close: {
		...styles.absolute({top: 0, right: 0}),
		paddingTop: 25,
		paddingRight: 25,
		padding: 10,
		color: styles.color.white,
	},

	scroll: {
		layout: {
			flex: 1,
		},

		container: {
			justifyContent: styles.justifyContent.spaceBetween,
			flexGrow: 1,
			paddingTop: 114,
			paddingBottom: 10,
		}
	},

	navigation: {
		layout: {
			marginTop: 10,
		},
	},

	annex: {
		layout: {
			marginTop: 20,
			paddingHorizontal: 52,
		},

		item: {
			fontSize: 12,
			color: styles.color.white,
			paddingVertical: 10,
		},

		last: {
			layout: {
				flexDirection: styles.flexDirection.row,
				justifyContent: styles.justifyContent.spaceBetween,
			},
			logout: {
				paddingLeft: 10,
				paddingVertical: 10,
			}
		}

	},

	footer: {
		layout: {
			flexDirection: styles.flexDirection.row,
			alignItems: styles.alignItems.center,
			paddingVertical: 25,
			marginHorizontal: 52,
		},

		copyright: {
			fontSize: 7,
			flex: 1,
			color: styles.color.white,
		},


		icon: {
			marginLeft: 20,
		},

		byShopinzon: {
			layout: {
				flex: 1,
				marginBottom: 5,
				justifyContent: styles.justifyContent.flexEnd,
				marginLeft: 0,
			},

			text: {
				color: styles.color.white,
			}
		},
	}
};
