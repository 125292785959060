import React from "react"
import {View as RNView} from "react-native";
import use from "library-react/hook";
import Footer from "./Footer/desktop";
import Header from "../../component/Header/desktop";
import ScrollView from "library-react/component/ScrollView";
import RegionSupport from "@main/ui/ShopUI/Footer/RegionSupport";
import {styles} from "@main/res"
import Sharable from "library-react/component/sharable/Sharable";
import {createShopWebUrl} from "library-react/utils/showSocialModal";

export default function DesktopUI({alt, ...props}) {
	const {vShop} = use.context.infos();
	props.style = use.defaultStyle(props.style, localStyles.layout);
	props.contentContainerStyle = localStyles.content;

	return (
		<ScrollView {...props}>
			<RNView>
				<Header title={'VENEZ NOUS RENDRE VISITE'}
						rate={vShop.rate}
						gallery={vShop.shop.gallery}
						description={vShop.shop.description}
						alt={alt}/>
				<Footer item={vShop} style={localStyles.footer}/>
			</RNView>
			<RegionSupport styles={localStyles.support}/>
		</ScrollView>
	);
}

const localStyles = {
	layout: {
		paddingTop: 50,
		paddingLeft: 70,
		paddingRight: 100,
	},
	content: {
		flexGrow: 1,
		justifyContent: styles.justifyContent.spaceBetween
	},
	footer: {
		marginTop: 42
	},
	support: {
		marginTop: 42,
		alignSelf: styles.alignSelf.center,
	}
};
