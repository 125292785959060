import is from "../../utils/is"
import Debug from "../../Debug"
import HTTPRequest from "../../network/HTTPRequest"
import Objects from "../../utils/Objects"
import Response from "./Response"
import AuthManager from "../../AuthManager";
import Environment from "../../Environment";
import willParse, { SourceModel } from "../model/parse"

/**
 * The header's key of the authentication (firebase) token.
 */
export const AUTH_TOKEN_KEY = "shopinzon-token";

/**
 * A server request.
 */
export default class Request extends HTTPRequest {
	/**
	 * Default values:
	 * <ul>
	 *     <li>method : {@link HTTPRequest.Methods.GET} </li>
	 *     <li>needsAuth : false </li>
	 * </ul>
	 **/
	constructor(path, method) {
		super(null, method);
		this.path = path;
		this._needAuth = false;
	}

	send() {
		let promise = this._send();
		promise.onSuccess = onResponseSuccess;
		return promise;
	}

	async _send() {
		const startDate = Date.now();

		// if no url is set, build with domain & path
		if (!this.url) {
			let domain = this.domain;
			Debug.assert(!domain, "A domain or an url must be set to send a request to server.");

			let path = this.path || "";

			let domainHasSlash = domain[domain.length - 1] == "/";
			let pathHasSlash = path[0] == "/";

			if (domainHasSlash && pathHasSlash)
				domain = domain.slice(0, domain.length - 1);

			else if (!domainHasSlash && !pathHasSlash)
				path = "/" + path;

			// if no url is explicitly set, use path
			this.url = domain + path;
		}

		// place parameters
		if (this.parameters) {
			if (this.method.is.byQueryParameters) {
				this.queryParameters = Object.assign(this.queryParameters || {}, this.parameters); // as query parameters
				// convert array and objects
				Objects.forEach(this.queryParameters, (key, value) => {
					if (is(value, Array) || is(value, Object))
						this.queryParameters[key] = JSON.stringify(value);
				});

			} else { // or in body
				this.contentType = "application/json";
				this.body = this.parameters;
			}
		}

		if (this._isInDev()) {
			if (this.method.is.byQueryParameters) {
				if (!this.queryParameters)
					this.queryParameters = {};

				this.queryParameters.dev = true;
			} else {
				if (!this.body)
					this.body = {};
				this.body.dev = true;
			}
		}

		try {
			await this._autoSetAuthToken();
		} catch (error) {
			console.error(error);
		}

		if (this._needAuth && !this.headers[AUTH_TOKEN_KEY])
			return new Response(Response.Status.BUG, { url: this.url })
				.setMessage("Server request won't be sent: auth-token is needed for this request.");

		// send http request an convert http response into a Response
		try {
			const networkTime = Date.now() - startDate;
			const send = () => super.send()
				.then(httpResponse => {
					if (!httpResponse.ok)
						throw httpResponse.status;
					return httpResponse.json();
				})
				.then(originalResponse => {
					const response = { ...originalResponse };
					// convert response into a Response
					const status = Response.Status.from(originalResponse?.status);

					// check status
					Debug.assert(!status,
						"Unknown response status : " + originalResponse.status,
						() => originalResponse = { status: Response.Status.BUG } // in production
					);

					response.status = status;
					return [response, originalResponse];
				});

			// --
			let [response, originalResponse] = await send();

			// -- check if token is invalid
			let tokenWasRefreshed = 0;
			if (response.status.is.InvalidToken) {
				tokenWasRefreshed = 1;
				await this._autoSetAuthToken(true);
				[response, originalResponse] = await send();
			}

			// -- parsing content
			const parsingDate = Date.now();
			if (response.status.ok && response.content && this.responseType) {
				// convert content if response type set
				if (this.responseType.type === Array && this.responseType.template) {
					const result = [];
					for (let item of response.content) {
						item = parse(this.responseType.template, item);
						result.push(item);
						await Promise.await(1); // free cpu for user interactions
					}

					response.content = result;
				} else
					response.content = parse(this.responseType, response.content);
			}

			// -- benchmark
			if (!Environment.is.web && Debug.is.development) {

				const parsingTime = Date.now() - parsingDate;
				const params = this.parameters;
				console.log("Fetch:\n", {
					url: this.url,
					method: this.method,
					params,
					get serializedParams() {
						return JSON.parse(JSON.stringify(params));
					},
					unserializedResponse: originalResponse,
					response,
					networkTime,
					parsingTime,
					totalTime: Date.now() - startDate,
					tokenWasRefreshed,
					request: this,
				});
			}

			// --
			return new Response()
				.hydrate({ request: this, ...response });

		} catch (error) {
			console.log("Error fetching : ", error);

			return error instanceof Error ?
				Response.BUG.setContent(error)
				: Response.NetworkError.setContent(error);
		}
	}

	async _autoSetAuthToken(refreshed) {
		if (this._needAuth) {
			const token = await AuthManager.getToken(refreshed);
			this.addHeader(AUTH_TOKEN_KEY, token);
		}
	}

	needsAuth() {
		this._needAuth = true;
		return this;
	}

	devable() {
		this._devable = true;
		return this;
	}

	_isInDev() {
		return this._devable && Debug.is.development;
	}

	/**
	 * TODO remove [marketer]
	 * @deprecated
	 */
	cache() {
		console.warn("TODO remove this deprecated call");
		return this;
	}

	setAuthToken(token) {
		this.addHeader(AUTH_TOKEN_KEY, token);
		return this;
	}

	clone() {
		const parameters = this.parameters;
		this.parameters = null;
		const clone = super.clone();
		this.parameters = clone.parameters = parameters;
		return clone;
	}
};

Request.addProperties({
	domain: String,
	path: String,
	parameters: Object,
	responseType: null,
});

Response.addProperties({ request: Request });

// export Request as 'Request' as well
export { Request };


/**
 * Create a class with a default domain set in its constructor.
 */
export function createRequestWithDefaultDomain(domain) {
	return class extends Request {
		constructor(...params) {
			super(...params);
			this.domain = this.domain || domain;
		}
	}
}

function onResponseSuccess(onSuccess) {
	if (onSuccess)
		this.then(response => {
			if (response && response.ok)
				onSuccess(response);
		});

	return this;
}

const parse = willParse(SourceModel.server);
