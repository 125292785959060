import Entity from "./Entity"
import Image from "../media/Image"
import Price from "../Price"
import Base from "../../../class/Base"
import Enum from "../../../class/Enum"

export default class Sale extends Entity {
	get coming(){
		return Boolean(this.start && Date.now() < this.start);
	}

	get current(){
		let now = Date.now();
		return Boolean(
			this.start
			&& now > this.start
			&& (
				!this.end
				|| now < this.end
			)
		);
	}

	/**
	 * @returns {Boolean} True if accepted, started & still not finished. False otherwise.
	 */
	get active(){
		return Boolean(this.accepted && this.current);
	}

	get main(){
		return !this.accepted || Date.now() < this.end;
	}

	get past(){
		return Boolean(this.end && Date.now() > this.end);
	}

	get state(){
		return !this.accepted ? Sale.States.waiting
			: this.coming ? Sale.States.future
				: this.active ? Sale.States.active
					: this.current ? Sale.States.current
						: Sale.States.past;
	}
}

Sale.addProperties({
	shopId : Number,
	title : String,
	picture : Image,
	description : String,
	start : Number,
	end : Number,
	accepted : Number,
	creationDate : Number,
});

Sale.States = Enum.make(["waiting", "future", "current", "active", "past"]);

// -----------

Sale.Product = class SaleProduct extends Entity {};
Sale.Product.addProperties({
	productId : Number,
	description : String,
	discount : Number,
	price : Price,
	oldPrice : Price,
	creationDate : Number,
});

// -----------

Sale.Product.Stock = class Stock extends Base {
	constructor(size, volume){
		super();
		this.size = size;
		this.volume = volume;
	}
};
Sale.Product.Stock.addProperties({
	size : String,
	volume : Number,
});
