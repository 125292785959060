import AutoMap from "./AutoMap"

export default class Trigger {
	constructor({listenersCanStopEvent, paramsConverter} = {listenersCanStopEvent: false}) {
		this.listeners = new AutoMap();
		this.listenersCanStopEvent = Boolean(listenersCanStopEvent);
		this.paramsConverter = paramsConverter;

		this.add = this.add.bind(this);
		this.add.trigger = this;
		this.fire = this.fire.bind(this);
		this.fire.trigger = this;
		this.fireAsyncOnce = this.fireAsyncOnce.bind(this);
		this.fireAsyncOnce.trigger = this;
		this.clear = this.clear.bind(this);
		this.clear.trigger = this;
	}

	add(listener) {
		if (!listener)
			return doNothing;

		const key = this.listeners.push(listener);
		return () => {
			delete this.listeners[key];
		};
	}

	fire(...params) {
		clearTimeout(this.fireKey);
		this.fireKey = null;

		for (let listener of this.listeners.values) {
			let newParams = this.paramsConverter ? this.paramsConverter(...params) : params;
			let handled;
			try {
				handled = listener(...newParams);
			} catch (error) {
				console.error(error);
			}

			if (this.listenersCanStopEvent && handled)
				break;
		}
	}

	fireAsyncOnce(...params) {
		clearTimeout(this.fireKey);
		this.fireKey = setTimeout(() => this.fire(...params));
	}

	clear() {
		this.listeners.clear();
	}
}

const doNothing = () => {
};
