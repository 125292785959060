import React from "react"
import use from "../../../../../hook"
import InputWrapper from "./InputWrapper";
import Objects from "../../../../../library-js/utils/Objects";
import parallel from "../../../../../library-js/utils/function/parallel";
import TextInput, {INPUT_PROPS} from "../../../../../component/input/TextInput";
import {is} from "../../../../../library-js/utils";
import {identity, pipe} from "ramda";


function CheckoutInput({submitted, inputStyle, formatter, ...props}, refProp) {
	const inputProps = Objects.retrieve(props, INPUT_PROPS, true);
	inputProps.ref = refProp;

	const [focus, setFocusTo] = use.state.bool(false);
	inputProps.onFocus = parallel([inputProps.onFocus, setFocusTo.true]);
	inputProps.onBlur = parallel([inputProps.onBlur, setFocusTo.false]);

	const [value, setValue] = use.state(() => is.defined(inputProps.value) ? inputProps.value : inputProps.defaultValue);
	inputProps.onValueChanged = pipe(formatter || identity, parallel([inputProps.onValueChanged, setValue]));

	const validate = props.validate = inputProps.validate;
	inputProps.validate = submitted ? validate : null;

	const valid = (validate instanceof Function) ? validate(value)
		: (!validate || value);
	props.state = focus ? InputWrapper.State.focus
		: (submitted && !valid) ? InputWrapper.State.error
			: (validate && value && valid) ? InputWrapper.State.valid
				: InputWrapper.State.blur;

	inputProps.style = use.defaultStyle(inputStyle, localStyles.input);

	return (
		<InputWrapper {...props}>
			<TextInput {...inputProps} value={value}/>
		</InputWrapper>
	);
}

export default React.memo(React.forwardRef(CheckoutInput));

const localStyles = {
	input: {
		paddingLeft: 15,
		paddingVertical: 9,
	}
};
