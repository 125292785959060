import useForceUpdate from "../useForceUpdate";
import useSyncEffect from "../useSyncEffect";
import AuthManager from "../../library-js/AuthManager";

export default function useAuthUser(){
	const forceUpdate = useForceUpdate();
	useSyncEffect(() => {
		if (!AuthManager.ready)
			AuthManager.onceReady.then(forceUpdate);
	});
	useSyncEffect(() => AuthManager.onUserChanged(forceUpdate));
	return AuthManager.ready ? (AuthManager.user || null) : undefined;
}
