import { FunctionComponent } from "react";
import selectByEnum from "../../library-react/library-js/app/model/utils/enum/selectByEnum";
import useDisplay, { ShopWebDisplay } from "../hook/useDisplay";

export default function withResponsiveness<Props extends {}>(
	components: { [level in ShopWebDisplay | "default"]?: FunctionComponent<Props>; },
	render?: ResponsiveComponent<Props>
): FunctionComponent<Props> {
	if (!components)
		throw new Error("Components (1st argument) needs to be provided.");

	return function ResponsiveComponent(props: Props) {
		const display = useDisplay();
		const Component = selectByEnum(components, display) as FunctionComponent<Props>;
		return render ? render(props, { display, Component })
			: Component ? <Component {...props} />
				: null;
	}
}

export type ResponsiveTools<Props> = { Component: FunctionComponent<Props>, display: ShopWebDisplay };
export type ResponsiveComponent<Props> = (props: Props, tools: ResponsiveTools<Props>) => JSX.Element;