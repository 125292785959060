import React from "react";
import View from "library-react/component/View/v2";
import Icon from "library-react/component/Icon/v2";
import {styles} from "@main/res";
import use from "library-react/hook";
import TextInput from "library-react/component/input/TextInput";

export default function SearchBar(
	{
		initialQuery,
		onQueryChanged,
		onQuerySubmitted,
		inputStyle,
		inputRef,
		clear,
		...props
	}
) {
	props.style = use.defaultStyle(props.style, localStyles.layout);
	inputStyle = use.defaultStyle(inputStyle, localStyles.input);
	return (
		<View {...props}>
			<TextInput
				ref={inputRef}
				placeholder={`Recherchez …`}
				defaultValue={initialQuery}
				placeholderTextColor="lightgrey"
				onChangeText={onQueryChanged}
				onSubmitted={onQuerySubmitted}
				style={inputStyle}
			/>

			<Icon name='Ionicons/md-close'
				  size={20}
				  style={localStyles.icon}
				  onPress={clear}
			/>
		</View>
	)
}

const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
		justifyContent: styles.justifyContent.spaceBetween,
		alignItems: styles.alignItems.center,
		borderBottomWidth: .5,
		borderColor: "lightgrey",
	},
	input: {
		fontSize: 18,
		flex: 1,
	},
	icon: {
		padding: 10,
	}
};
