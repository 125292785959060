import { Enum } from "../class"
import is from "./is";
import Trigger from "./Trigger";
import Async from "./Async";

/**
 * @template T
 * @property {T} value
 */
export default class Loadable extends Async {
	constructor(load) {
		super();
		this.loader = load;
		this._trigger = new Trigger();

		this.load = this.load.bind(this);
		this.shouldStop = this.shouldStop.bind(this);
		this.onStateChanged = this.onStateChanged.bind(this);
		this.setValue = this.setValue.bind(this);
		this.reset();
	}

	// ---- state shortcuts ----

	get state() {
		return !this.times ? State.default
			: this.promise ? State.loading
				: is(this.error) ? State.failed
					: State.ok;
	}

	get ready() {
		return ![State.loading, State.ok].includes(this.state);
	}

	get end() {
		return this.state === State.ok;
	}

	/**
	 * @deprecated use .end property.
	 */
	get ok() {
		return this.end;
	}

	get failed() {
		return this.state === State.failed;
	}

	get loading() {
		return Boolean(this.promise);
	}

	get firstLoading() {
		return this.loading && this.times === 1;
	}

	// ---- methods ----

	load(...params) {
		const startDate = Date.now();
		if (!this.ready) {
			if (this.promise)
				return this.promise;

			if (this.end)
				return Promise.resolve(this.value);

			return;
		}

		this.times++;
		this.promise = null;
		this.error = null;

		const { delay, loader } = this;

		const start = () => loader.apply(this, params);

		let result, error;
		try {
			result = (delay > 0) ?
				Promise.await(delay)
					.stopOn(this.shouldStop)
					.then(start)
				: start();
		} catch (caught) {
			error = caught;
		}

		const handleResult = (value, error) => {
			if (!is.defined(error))
				this.setValue(value);
			else {
				this.error = error;
				console.warn(error);
				this._notify(); // state ok or failed
			}
		};


		if (result instanceof Promise) { // async
			const promise = this.promise =
				result.stopOn(this.shouldStop);

			promise.result((value, error) => {
				this.promise = null;
				handleResult(value, error);
			});

			this._notify(); // state loading
		} else // sync
			handleResult(result, error);

		return Promise.result(result, error);
	}

	onStateChanged(callback) {
		return this._trigger.add(callback);
	}

	/**
	 * @deprecated
	 */
	onLoad(callback) {
		return this.onStateChanged(state => {
			if (state === State.loading)
				callback(this.promise);
		});
	}

	/**
	 * @deprecated
	 */
	onSuccess(callback) {
		return this.onStateChanged(state => {
			if (state === State.ok)
				callback(this.value);
		});
	}

	/**
	 * @deprecated
	 */
	onError(callback) {
		return this.onStateChanged(state => {
			if (state === State.failed)
				callback(this.error);
		});
	}

	setValue(value) {
		this.value = value instanceof Function ? value(this.value) : value;
		this._notify();
	}

	reset() {
		// this._trigger.clear();
		this.times = 0;
		this.promise = null;
		this.error = null;
		this.value = null;
	}

	destroy() {
		super.destroy();
		this._trigger.clear();
	}

	proxy(map) {
		return new Proxy(this, {
			get(loadable, key) {
				if (key === 'value')
					return loadable.end ? map(loadable.value) : undefined;

				const field = loadable[key];
				return is(field, Function) ? field.bind(loadable) : field;
			},

			set() {
				throw new Error('You should not set fields on a proxy loadable.');
			}
		});
	}

	// ----- privates ------

	_notify() {
		this._trigger.fire(this.state);
	}
}

const State = Loadable.State = new Enum("default", "loading", "failed", "ok");
