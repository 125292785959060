import React from "react"
import CatalogView from "@main/component/CatalogView";
import {styles} from "@main/res";
import use from "library-react/hook";
import ShowcasesBanner from "@main/component/ShowcasesBanner";

export default function CatalogFragmentMobile({section, ...props}) {
	const {config} = use.context.infos();
	const {Text} = use.theme();
	return (
		<>
			<Text
				numberOfLines={1}
				style={localStyles.title}>
				{
					section?.path.last.toUpperCase()
					|| config?.catalog?.name?.toUpperCase()
					|| `CATALOGUE`
				}
			</Text>

			<CatalogView
				header={<ShowcasesBanner/>}
				section={section}
				{...props} />
		</>
	);
};
const localStyles = {
	title: {
		fontSize: 18,
		textAlign: styles.textAlign.center,
		bold: true,
		paddingVertical: 13,
		paddingHorizontal: 5,
		backgroundColor: styles.color.white,
		flexShrink: 0,
	},
};
