import React from "react";
import View from "library-react/component/View";
import Icon from "library-react/component/Icon/v2";
import TextInput from "library-react/component/input/TextInput";
import {styles} from "@main/res";
import use from "library-react/hook";
import {parallel} from "library-js/utils/function";

export default function SearchBar(
	{
		initialQuery,
		onQueryChanged,
		onQuerySubmitted,
		inputStyle,
		inputRef,
		clear,
		upperCase,
		...props
	}
) {
	const [query, setQuery] = use.state(initialQuery || '');
	props.style = use.defaultStyle(props.style, localStyles.layout);
	inputStyle = use.defaultStyle(inputStyle, localStyles.input(upperCase));
	return (
		<View {...props}>
			<TextInput
				placeholder={'Recherchez …'}
				defaultValue={initialQuery}
				placeholderTextColor="lightgrey"
				onChangeText={parallel(onQueryChanged, setQuery)}
				onSubmitted={onQuerySubmitted}
				ref={inputRef}
				style={inputStyle}
			/>

			{
				Boolean(query) &&
				<Icon
					name='Ionicons/md-close'
					size={25}
					onPress={clear}
				/>
			}
		</View>
	)
}
const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
		borderBottomWidth: .5,
		borderColor: "lightgrey",
	},

	input: styles.static.bool({
			fontSize: 15,
			bold: true,
			flex: 1,
			paddingVertical: 16,
		},
		{
			textTransform: 'uppercase',
		}
	),
};
