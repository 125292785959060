import {__, gt} from "ramda";
import Base from "../../../../class/Base"
import {MangoPayAddressField, validateMangoPayAddress} from "./Address"
import Birthplace from "./Birthplace";
import simpleValidate from "../../../../utils/simpleValidate";

export default class UBO extends Base {
	constructor() {
		super();
		this.Birthplace = new Birthplace();
	}

	get valid() {
		return this.validate();
	}

	validate() {
		return this.getInvalidFields().length === 0;
	}

	get Birthday(){
		return Math.trunc(this.birthday / 1000);
	}

	set Birthday(timestampInSecs){
		this.birthday = Math.trunc(timestampInSecs * 1000);
	}

	getInvalidFields() {
		return Object.entries(this.constructor.generateValidateOf)
			.filter(([, willValidate]) => !willValidate.length) // remove validates with dependencies
			.filter(([key, willValidate]) => !willValidate().apply(this, [this[key], key]))
			.map(([key]) => key);
	}

	toJSON(){
		const json = super.toJSON();
		json.Birthday = this.Birthday;
		return json;
	}
}

UBO.addProperties({
	Id: String,
	FirstName: String,
	LastName: String,
	Address: MangoPayAddressField,
	Nationality: String,
	birthday: Number,
	Birthplace: Birthplace,
	IsActive: Boolean,
});

UBO.generateValidateOf = {
	FirstName: () => simpleValidate.text,
	LastName: () => simpleValidate.text,
	Address: () => validateMangoPayAddress,
	Nationality: () => simpleValidate.text,
	Birthday: () => isFinite,
	Birthplace: () => birthplace => birthplace?.isValid && birthplace.isValid(),
};
