import Strings from "./Strings";
import {__, allPass, complement, isNil, or, pipe, prop, startsWith, trim} from "ramda";

const simpleValidate = {
	image: pipe(
		prop('url'),
		allPass([
			complement(isNil),
			startsWith('https://')
		])
	),

	text: pipe(or(__, ''), String, trim, Boolean),

	email: pipe(or(__, ''), String, trim, Strings.isEmail),
};

export default simpleValidate;
