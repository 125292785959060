import {isPrimitive} from "../../is"
import {always, complement, filter, ifElse, isNil, join, map, not, nth, pipe, when} from "ramda";

const encode = ifElse(not,
	always(''),
	pipe(
		Object.entries,
		filter(pipe(
			nth(1),
			complement(isNil)
		)),
		map(pipe(
			map(pipe(
				when(complement(isPrimitive), JSON.stringify),
				encodeURIComponent,
			)),
			join('=')
		)),
		join('&')
	),
);

export default encode;
