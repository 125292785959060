import use from "library-react/hook";
import MobileUI from "./mobile";
import DesktopUI from "./desktop";
import React from "react";

export default function ProductGrid({...props}) {
	const display = use.context.display();
	return (
		display.is.mobile ?
			<MobileUI {...props}/> :
			<DesktopUI {...props}/>
	);
}
