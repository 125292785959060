import { createRequestWithDefaultDomain } from "../Request"
import HTTPRequest from "../../../network/HTTPRequest"
import Iterator from "../Iterator"

import { Order, Shop, Transaction, User } from "../../model/entity"
import * as Views from "../../model/view"
import { VOrder, VProdshop, VSale } from "../../model/view"

import Debug from "../../../Debug"
import APIFunction from "../APIFunction";
import cache, {
	ListCacheAccessor,
	SplitCacheAccessor,
	updateList,
	updateMultipleEntries,
	updateMultipleLists
} from "../cache"
import Public from "./public"
import Prodshop from "../../model/Prodshop";
import cacheUpdateCenter from "./cacheUpdateCenter";
import CatalogCupboard from "../../model/CatalogCupboard";
import CatalogSection from "../../model/entity/CatalogSection";
import VShowcase from "../../model/view/general/VShowcase";
import Showcase from "../../model/entity/Showcase";
import { IBANBankAccount, KYCDocument, LegalUser, UBODeclaration } from "../../model/pay/mango";
import PaymentConfigurationStatus from "../../model/entity/Shop/PaymentConfigurationStatus";
import Price from "../../model/Price";
import Response from "../Response"
import { path, times } from "ramda";
import VReservation from "../../model/view/general/VReservation";
import ReservationClosure from "../../model/entity/ReservationClosure"
import ReservationSpan from "../../model/entity/ReservationSpan"
import mock from "../mock"
import moment from "moment"

const API = {
	Request: null,

	init() {
		API.Request = createRequestWithDefaultDomain("https://v9-dot-retailer-dot-rcm55-bagshop.appspot.com");
	},

	auth: {
		/**
		 * Authenticate via email & password.
		 * @returns {Promise.<Server.Response.<String>>} A token to authenticate in firebase.
		 * @see https://docs.google.com/document/d/1VYIb-6otClm0p30ucmbfL9zjdHBTn3B6eEff2h0UWVg/edit#bookmark=id.anq59tt6xu2k
		 */
		authenticate: new APIFunction({
			request: (email, password) => {
				email = email.toLowerCase();
				return new API.Request("/login/", HTTPRequest.Methods.POST)
					.setParameters({ email, password })
			}
		}),

		refreshPushToken: new APIFunction({
			request: (token, previousToken, authToken) => (
				new API.Request("/refresh-push-token/")
					.needsAuth()
					.setAuthToken(authToken)
					.setParameters({ aNew: token, old: previousToken })
			)
		}),

		/**
		 * https://docs.google.com/document/d/1VYIb-6otClm0p30ucmbfL9zjdHBTn3B6eEff2h0UWVg/edit#bookmark=id.tbt9rxix6u2r
		 */
		renewPassword: new APIFunction({
			request: (old, newPassword, confirmation) => (
				new API.Request("/new-password/")
					.needsAuth()
					.setParameters({ old, newPassword, confirmation })
			)
		}),
	},

	me: {
		/**
		 * @returns {Promise.<Server.Response.<Me>>} Shop's and retailer's informations.
		 * @see https://docs.google.com/document/d/1VYIb-6otClm0p30ucmbfL9zjdHBTn3B6eEff2h0UWVg/edit#bookmark=id.ok2nih6y54or
		 */
		getInfos: new APIFunction({
			request: () => (
				new API.Request("/me/")
					.needsAuth()
					.setResponseType(Views.retailer.Me)
			),

			cache: {
				time: cache.TIME.MAX,
			},
		}),

		/**
		 * Edit shop's informations.
		 * @returns {Promise.<Server.Response>}
		 * @see https://docs.google.com/document/d/1VYIb-6otClm0p30ucmbfL9zjdHBTn3B6eEff2h0UWVg/edit#bookmark=id.es89jij6pal5
		 */
		editShop: new APIFunction({
			request: shop => (
				new API.Request('/me/', HTTPRequest.Methods.POST)
					.needsAuth()
					.setParameters(shop)
					.setResponseType(Shop)
			),

			async updateOtherAPIFunctions(_, { parameters: shop }) {
				// get shop id
				if (!shop.id) {
					let me = await API.me.getInfos.last();
					if (me)
						shop.id = me.shop.shop.id;
				}

				// update me
				API.me.getInfos.cache.updateIfExist(null, me => {
					me.shop.shop = shop;
					return me;
				});

				// update public get shop
				if (shop.id)
					Public.shop.get.cache.updateIfExist([shop.id], vShop => vShop.setShop(shop));
				else // no shop id, clear all shops
					Public.shop.get.cache.clear();
			}
		}),

		/**
		 * https://docs.google.com/document/d/1VYIb-6otClm0p30ucmbfL9zjdHBTn3B6eEff2h0UWVg/edit#bookmark=kix.hrt6hazd8xyx
		 */
		async editWebTemplate(domain, config) {
			if (config === undefined) {// prevent config to be cleared
				const meResponse = await API.me.getInfos();
				if (!meResponse.ok)
					return meResponse;

				const shopId = meResponse.content?.shop?.shop?.id;
				if (!shopId)
					return new Response(Response.Status.BUG)
						.setMessage("Unable to update web template, unable to auto load config since Me is in no shop.");

				const configResponse = await Public.shop.getConfigOf(shopId);
				if (!configResponse.ok)
					return configResponse;

				config = configResponse.content || null;
			}

			config = JSON.stringify(config); // only string is accepted
			return new API.Request('/me/web', HTTPRequest.Methods.POST)
				.needsAuth()
				.setParameters({ domain, config })
				.send();
		},

		/**
		 * https://docs.google.com/document/d/1VYIb-6otClm0p30ucmbfL9zjdHBTn3B6eEff2h0UWVg/edit#bookmark=id.ud8h1xyyexya
		 */
		isDomainAvailable: new APIFunction({
			request: domain => (
				new API.Request('/me/domain/available')
					.needsAuth()
					.setParameters({ domain })
			),

			cache: true,
		}),

		/**
		 * https://docs.google.com/document/d/1VYIb-6otClm0p30ucmbfL9zjdHBTn3B6eEff2h0UWVg/edit#bookmark=id.7x9vutd3nezq
		 */
		getAgencyUrl: new APIFunction({
			request: () => (
				new API.Request("/me/agency")
					.needsAuth()
			),

			cache: { time: cache.TIME.MAX }
		}),

		checkoutConfiguration: {
			persist: new APIFunction({
				request(receptionMeansOrShop, paymentMeans, checkoutNote, unavailableNote) {
					let receptionMeans = receptionMeansOrShop
					if (receptionMeansOrShop instanceof Shop) {
						receptionMeans = receptionMeansOrShop.receptionMeans;

						if (!paymentMeans)
							paymentMeans = receptionMeansOrShop.paymentMeans;

						if (!checkoutNote)
							checkoutNote = receptionMeansOrShop.checkoutNote;

						if (!unavailableNote)
							unavailableNote = receptionMeansOrShop.unavailableNote;
					}

					return new API.Request("/config/checkout", HTTPRequest.Methods.POST)
						.needsAuth()
						.setParameters({ receptionMeans, paymentMeans, checkoutNote, unavailableNote })
				},
			}),
		},
	},

	catalog: {
		/**
		 * Add/modify/remove a product to/from the shop's catalog.
		 * @returns {Promise.<Server.Response>}
		 * @see https://docs.google.com/document/d/1VYIb-6otClm0p30ucmbfL9zjdHBTn3B6eEff2h0UWVg/edit#bookmark=id.7peuvmg77phu
		 */
		persist: new APIFunction({
			request: (productId, product, prodshop) => (
				new API.Request("/catalog/", HTTPRequest.Methods.POST)
					.needsAuth()
					.setParameters({ productId, product, prodshop })
			),

			onSuccess(response, request) {
				if (request.parameters.product)
					request.parameters.product.id = response.content;
			},

			// replace proshop in public api
			async updateOtherAPIFunctions(content, { parameters: { productId, prodshop } }) {
				// check if prodshop.id is correctly set
				let isProdshopIdCorrectlySet = productId && prodshop && prodshop.id && prodshop.id.shopId;
				await Debug.assert(
					prodshop && !isProdshopIdCorrectlySet, // prodshop id not set
					"Impossible to clean cache because proshop.id not set properly.",
					async () => {
						// try to get shop id from me
						let me = await API.me.getInfos.last();
						if (me) {
							let shopId = me.shop.shop.id;
							if (!prodshop.id)
								prodshop.id = new Prodshop.Id(productId);
							prodshop.id.shopId = shopId;

							isProdshopIdCorrectlySet = true;
						} else
							// clear entire prodshop cache, no other way. Better than keeping deprecated data
							cache.clear(Public.domain, Public.prodshop.PATH, HTTPRequest.Methods.GET);
					},
				);

				// update cached vProdshop
				if (isProdshopIdCorrectlySet) {
					let { shopId } = prodshop.id;
					let params = [{ [shopId]: [productId] }];

					Public.prodshop.get.cache.updateIfExist(params, vProdshop => vProdshop.setProdshop(prodshop));
				}

				//TODO clear algolia cache
			}
		}),

		sections: {
			persist: new APIFunction({
				request: (sections) => {
					if (sections instanceof CatalogCupboard)
						sections = sections.getAll(false);

					return new API.Request("/catalog/section", HTTPRequest.Methods.POST)
						.needsAuth()
						.setParameters(sections)
						.setResponseType({
							type: Array,
							template: CatalogSection,
						})
				},

				onSuccess(response) {
					response.content = new CatalogCupboard(response.content);
				},
			}),

			/**
			 * https://docs.google.com/document/d/1VYIb-6otClm0p30ucmbfL9zjdHBTn3B6eEff2h0UWVg/edit#bookmark=id.1ihk1xq882xz
			 */
			setProduct: new APIFunction({
				request: (item, exist) => (
					new API.Request("/catalog/section/product", HTTPRequest.Methods.POST)
						.needsAuth()
						.setParameters({ item, exist: Boolean(exist) })
				),
			}),

			// shortcut
			applyOnProduct: (productId, sectionId, bind) =>
				API.catalog.sections.setProduct({ productId, sectionId }, bind),
		}
	},

	payment: {
		legalUser: {
			get: new APIFunction({
				request: () => (
					new API.Request("/payment/legal-user")
						.needsAuth()
						.setResponseType(LegalUser)
				)
			}
			),
			persist: new APIFunction({
				request: (legalUser) => (
					new API.Request("/payment/legal-user", HTTPRequest.Methods.POST)
						.needsAuth()
						.setParameters(legalUser)
						.setResponseType(LegalUser)
				)
			}
			),
		},

		kyc: {
			get: new APIFunction({
				request: (documentType) => (
					new API.Request("/payment/kycdocument")
						.needsAuth()
						.setParameters({ documentType })
						.setResponseType(KYCDocument)
				)
			}
			),
			add: new APIFunction({
				request: (documentType, file) => (
					new API.Request("/payment/kycpage", HTTPRequest.Methods.PUT)
						.needsAuth()
						.setParameters({ documentType, file })
						.setResponseType(KYCDocument)
				)
			}
			),
			submit: new APIFunction({
				request: (Id) => new API.Request("/payment/kycdocument", HTTPRequest.Methods.PUT)
					.needsAuth()
					.setParameters({ Id })
					.setResponseType(KYCDocument)
			}
			),
		},

		ubo: {
			get: new APIFunction({
				request: () => (
					new API.Request("/payment/ubodeclaration")
						.needsAuth()
						.setResponseType(UBODeclaration)
				)
			}
			),
			add: new APIFunction({
				request: (ubo) => (
					new API.Request("/payment/ubo", HTTPRequest.Methods.PUT)
						.needsAuth()
						.setParameters(ubo)
						.setResponseType(UBODeclaration)

				)
			}
			),
			update: new APIFunction({
				request: (ubo) => (
					new API.Request("/payment/ubo", HTTPRequest.Methods.POST)
						.needsAuth()
						.setParameters(ubo)
						.setResponseType(UBODeclaration)

				)
			}
			),
			submit: new APIFunction({
				request: (Id) => new API.Request("/payment/ubodeclaration", HTTPRequest.Methods.PUT)
					.needsAuth()
					.setParameters({ Id })
					.setResponseType(UBODeclaration)
			}
			),
		},

		bankAccount: {
			get: new APIFunction({
				request: () => (
					new API.Request("/payment/bankaccount")
						.needsAuth()
						.setResponseType(IBANBankAccount)
				)
			}
			),
			persist: new APIFunction({
				request: (ibanBankAccount) => (
					new API.Request("/payment/bankaccount", HTTPRequest.Methods.PUT)
						.needsAuth()
						.setParameters(ibanBankAccount)
						.setResponseType(IBANBankAccount)
				)
			}
			),
		},

		getConfigState: APIFunction({
			request: () => (
				new API.Request("/payment/configuration/status")
					.needsAuth()
					.setResponseType(PaymentConfigurationStatus)
			)
		}),
		transaction: {

			/**
			 * https://docs.google.com/document/d/1VYIb-6otClm0p30ucmbfL9zjdHBTn3B6eEff2h0UWVg/edit#bookmark=kix.6pbjws7ebmll
			 */
			getList: new APIFunction({
				request: (from) =>
					new API.Request('/payment/transaction/list')
						.needsAuth()
						.setParameters({ from })
						.setResponseType({
							type: Array,
							template: Transaction
						}),

				iterator: {
					getCursor: transaction => transaction.creationDate,
					minimum: 20,
				}
			}),

			/**
			 * https://docs.google.com/document/d/1VYIb-6otClm0p30ucmbfL9zjdHBTn3B6eEff2h0UWVg/edit#bookmark=kix.82vk89yvck
			 */
			getRemainingAmount: new APIFunction({
				request: () => (
					new API.Request("/payment/transaction/remaining")
						.needsAuth()
						.setResponseType(Price)
				)
			}),

			/**
			 * https://docs.google.com/document/d/1VYIb-6otClm0p30ucmbfL9zjdHBTn3B6eEff2h0UWVg/edit#bookmark=kix.19299u9r7ahr
			 */
			transferRemainingAmount: new APIFunction({
				request: () => (
					new API.Request("/payment/transaction/remaining", HTTPRequest.Methods.PUT)
						.needsAuth()
						.setResponseType(Transaction)
				)
			}),

		},
	},

	club: {
		/**
		 * @returns {Promise.<Server.Response.<Shop.Club>>} Shop's club.
		 * @see https://docs.google.com/document/d/1VYIb-6otClm0p30ucmbfL9zjdHBTn3B6eEff2h0UWVg/edit#bookmark=id.qtb6t9u6hpcq
		 */
		get: new APIFunction({
			request: () => (
				new API.Request("/club/")
					.needsAuth()
					.setResponseType(Shop.Club)
			)
		}),

		/**
		 * Create a club card.
		 * @param {Shop.Club.Card} card Card to create.
		 * @returns {Promise.<Server.Response>}
		 * @see https://docs.google.com/document/d/1VYIb-6otClm0p30ucmbfL9zjdHBTn3B6eEff2h0UWVg/edit#bookmark=id.vmzog0x075vv
		 */
		createCard: new APIFunction({
			request: card => (
				new API.Request("/club/card/", HTTPRequest.Methods.PUT)
					.setParameters(card)
					.needsAuth()
			)
		}),

		/**
		 * Activate a club card. So new members will subscribe to this new card.
		 * @returns {Promise.<Server.Response>}
		 * @see https://docs.google.com/document/d/1VYIb-6otClm0p30ucmbfL9zjdHBTn3B6eEff2h0UWVg/edit#bookmark=id.r02sgfi5jakz
		 */
		activateCard: new APIFunction({
			request: id => (
				new API.Request("/club/card/activate")
					.needsAuth()
					.setParameters({ id })
			)
		}),

		/**
		 * Delete a club card. Works only if there's no user on this card.
		 * @returns {Promise.<Server.Response>}
		 * @see https://docs.google.com/document/d/1VYIb-6otClm0p30ucmbfL9zjdHBTn3B6eEff2h0UWVg/edit#bookmark=id.gof03r6rjavo
		 */
		deleteCard: new APIFunction({
			request: id => (
				new API.Request("/club/card/", HTTPRequest.Methods.DELETE)
					.needsAuth()
					.setParameters({ id })
			)
		})
	},

	client: {
		/**
		 * @param {Object.<{query, from, levels}>} filters Contains filter properties:
		 * <ul>
		 *     <li><b>String</b> <i>query</i> : Filter by the {@link User.name} or the {@link User.email}.</li>
		 *     <li><b>{isMember; bool, isBuyer: bool}</b> <i>filters</i> : Filters.</li>
		 *     <li><b>Timestamp</b> <i>from</i> : last client's email from the last list.</li>
		 * </ul>
		 * @returns {Promise.<Server.Response.<Array.<Client>>>} An infinite list of clients.
		 * @see https://docs.google.com/document/d/1VYIb-6otClm0p30ucmbfL9zjdHBTn3B6eEff2h0UWVg/edit#bookmark=id.qffj8x5qa3ka
		 */
		getList: new APIFunction({
			constructor() {
				cacheUpdateCenter.Client.persist.listen(this, clients => {
					clients = clients.toObject(client => client.id);
					updateMultipleLists(
						this.config.request(),
						({ id }) => clients[id],
						({ id }) => clients[id],
					);
				});

				cacheUpdateCenter.Client.update.listen((predicate, update) =>
					updateMultipleLists(this.config.request(), predicate, update)
				);
			},

			request: (query, filters, from) => {
				let parameters = Object.assign({ query, from }, filters);
				return new API.Request("/client/list")
					.needsAuth()
					.setParameters(parameters)
					.setResponseType({
						type: Array,
						template: Views.retailer.Client
					})
			},

			cache: { accessor: new ListCacheAccessor },

			updateOtherAPIFunctions(clients) {
				cacheUpdateCenter.Client.persist.fire(this, ...clients);
			},

			iterator: {
				load: ({ params: [query, filters], cursor, api }) => api(query, filters, cursor),
				getCursor: client => client.user.email,
				minimum: 10,
			},
		}),

		getIterator(query, filters) {
			return new Iterator()
				.hydrate({
					function: cursor => API.client.getList(query, filters, cursor),
					getCursor: response => response.content.last.user.email,
					minimum: 10,
				});
		},

		/**
		 * @param {String} id User's id
		 * @returns {Promise.<Server.Request.<Client>>}
		 * @see https://docs.google.com/document/d/1VYIb-6otClm0p30ucmbfL9zjdHBTn3B6eEff2h0UWVg/edit#bookmark=id.7xg55e9ctvgv
		 */
		get: new APIFunction({
			constructor() {
				cacheUpdateCenter.Client.persist.listen(this, clients => {
					let entries = clients.map(client => ({
						params: [client.id],
						content: client,
					}));

					this.cache.persistMulti(entries, cache.TIME.MAX);
				});

				cacheUpdateCenter.Client.update.listen((predicate, update) => {
					updateMultipleEntries(
						() => cache.getAll(this.config.request()),
						predicate,
						update,
						entries => cache.set(entries, cache.TIME.MAX),
					)
				});
			},

			request: id => (
				new API.Request("/client/")
					.needsAuth()
					.setParameters({ id })
					.setResponseType(Views.retailer.Client)
			),

			cache: { time: cache.TIME.MAX },

			updateOtherAPIFunctions(client) {
				cacheUpdateCenter.Client.persist.fire(this, client);
			}
		}),

		/**
		 * Evaluate a user.
		 * @param {String} userId User's id.
		 * @param {Evaluation} evaluation Evaluation to persist.
		 * @returns {Promise.<Server.Response>}
		 * @see https://docs.google.com/document/d/1VYIb-6otClm0p30ucmbfL9zjdHBTn3B6eEff2h0UWVg/edit#bookmark=id.gfpzetcgwidu
		 */
		evaluate: new APIFunction({
			request: (userId, evaluation) => (
				new API.Request("/client/evaluate", HTTPRequest.Methods.POST)
					.needsAuth()
					.setParameters({ userId, evaluation })
			),

			updateOtherAPIFunctions(_, { parameters: { userId, evaluation } }) {
				cacheUpdateCenter.Client.update.fire(
					client => client.id === userId,
					client => client.setEvaluation(evaluation)
				);
			}
		}),

		/**
		 * @param {Array} ids Users' ids.
		 * @returns {Promise.<Server.Response.<Map.<userId, User>>>} Map of users mapped by their ids.
		 * @see https://docs.google.com/document/d/1VYIb-6otClm0p30ucmbfL9zjdHBTn3B6eEff2h0UWVg/edit#bookmark=id.nzez1ywmwa3t
		 */
		getUsers: new APIFunction({
			constructor() {
				cacheUpdateCenter.VUser.persist.listen(this, vUsers => {
					this.cache.persist(
						vUsers.map(({ id }) => id),
						vUsers.map(({ user }) => user),
						cache.TIME.MAX,
					);
				});
			},

			request: (...ids) => (
				new API.Request("/client/chat/list")
					.needsAuth()
					.setParameters({ ids })
					.setResponseType({
						type: Array,
						template: User
					})
			),

			cache: { accessor: new SplitCacheAccessor },

			// map by ids
			onSuccess(response) {
				response.content = response.content.toObject(user => user.id);
			}
		}),
	},

	order: {
		/**
		 *
		 * @param {Number} orderId Order's id to update.
		 * @param {Order.State} state New state of the order.
		 * @returns {Promise.<Server.Response>}
		 * @see https://docs.google.com/document/d/1VYIb-6otClm0p30ucmbfL9zjdHBTn3B6eEff2h0UWVg/edit#bookmark=id.rrl2dmxn0uta
		 */
		updateState: new APIFunction({
			request: (orderId, state) => (
				new API.Request("/order/state", HTTPRequest.Methods.POST)
					.needsAuth()
					.setParameters({ id: orderId, state })
			),
		}),
	},

	showcase: {
		link: APIFunction({
			request: (showcaseId, shopId, isActivated) =>
				new API.Request('/showcase/link', HTTPRequest.Methods.POST)
					.needsAuth()
					.setParameters({ showcaseId, shopId, isActivated }),
		}),

		getShowcase: APIFunction({
			request: (shopId, showcaseIds) =>
				new API.Request('/showcase/list')
					.needsAuth()
					.setParameters({ shopId, showcaseIds })
					.setResponseType({
						type: Array,
						template: VShowcase.Shop
					}),

		}),

		persist: new APIFunction({
			request: (shopId, id, showcase) =>
				new API.Request('/showcase', HTTPRequest.Methods.POST)
					.needsAuth()
					.setParameters({ shopId, id, showcase })
					.setResponseType(Showcase),
		}),

		editItems: APIFunction({
			request: (showcaseId, shopId, editedItems) =>
				new API.Request('/showcase/item/edit', HTTPRequest.Methods.POST)
					.needsAuth()
					.setParameters({ showcaseId, shopId, editedItems }),
		}),
	},

	reservation: {
		/**
		 * https://docs.google.com/document/d/1VYIb-6otClm0p30ucmbfL9zjdHBTn3B6eEff2h0UWVg/edit#bookmark=kix.sis66gcv2lhr
		 */
		getList: APIFunction({
			request: (shopId, config) => {
				return new API.Request('/reservation/list')
					.needsAuth()
					.setParameters(Object.assign({}, { shopId }, config))
					.setResponseType({
						type: Array,
						template: VReservation,
					});
			},

			iterator: {
				load: ({ params: [shopId, config], cursor, api }) => api(shopId, Object.assign({}, config, cursor && { cursor })),
				getCursor: path(['reservation', 'time']),
				minimum: 30,
			},
		}),


		get: APIFunction({
			request: (id) =>
				new API.Request('/reservation')
					.needsAuth()
					.setParameters({ id })
					.setResponseType(VReservation),
		}),

		persist: APIFunction({
			request: ({ reservation, state }) =>
				new API.Request('/reservation', HTTPRequest.Methods.POST)
					.needsAuth()
					.setParameters({ reservation, state })
					.setResponseType(VReservation),
		}),

		persistSpan: APIFunction({
			request: (id, span) => new API.Request('/reservation/span', HTTPRequest.Methods.POST)
				.needsAuth()
				.setParameters({ id, span })
				.setResponseType(ReservationSpan),
		}),

		persistClosure: APIFunction({
			request: (id, closure) => new API.Request('/reservation/closure', HTTPRequest.Methods.POST)
				.needsAuth()
				.setParameters({ id, closure })
				.setResponseType(ReservationClosure),
		}),

		getMonthReports: APIFunction({
			request: (shopId, date) => new API.Request('/reservation/insight')
				.needsAuth()
				.setParameters({ shopId, date })
		})
	}
};

export default API;
