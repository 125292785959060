import AntDesign from "react-native-vector-icons/dist/AntDesign"
import Entypo from "react-native-vector-icons/dist/Entypo"
import EvilIcons from "react-native-vector-icons/dist/EvilIcons"
import Feather from "react-native-vector-icons/dist/Feather"
import FontAwesome from "react-native-vector-icons/dist/FontAwesome"
import Foundation from "react-native-vector-icons/dist/Foundation"
import Ionicons from "react-native-vector-icons/dist/Ionicons"
import MaterialIcons from "react-native-vector-icons/dist/MaterialIcons"
import MaterialCommunityIcons from "react-native-vector-icons/dist/MaterialCommunityIcons"
import Octicons from "react-native-vector-icons/dist/Octicons"
import Zocial from "react-native-vector-icons/dist/Zocial"
import SimpleLineIcons from "react-native-vector-icons/dist/SimpleLineIcons"
import resolveUrl from "to-absolute-url"

const icons = {
	AntDesign,
	Entypo,
	EvilIcons,
	Feather,
	FontAwesome,
	Foundation,
	Ionicons,
	MaterialIcons,
	MaterialCommunityIcons,
	Octicons,
	Zocial,
	SimpleLineIcons,
};

// set all fonts in document
const style = document.createElement('style');
Object.keys(icons)
	.forEach(namespace => {
		let url = require(`react-native-vector-icons/Fonts/${namespace}.ttf`);
		if (url.default)
			url = url.default;

		style.appendChild(
			document.createTextNode(`@font-face {
				font-family: ${namespace};
				src: url('${resolveUrl(url)}') format('truetype');
			}`)
		);
	});
document.head.appendChild(style);


export default icons;
