import React from "react"
import use from "../../../../hook";
import {View as RNView} from "react-native";
import Image from "../../../../component/Image";
import {styles} from "../../../../res";

export default React.memo(
	function ProductImage({source, ...props}) {
		props.style = use.defaultStyle(props.style, localStyles.layout);

		return (
			// wrapp with a view for shadow
			<RNView {...props}>
				<Image
					source={source}
					resizeMode="cover"
					style={styles.fit}
				/>
			</RNView>
		);
	}
);

const localStyles = {
	layout: {
		height: 26,
		width: 36,
		backgroundColor: styles.color.white,
		...styles.newShadow(0, 1, 4, .16),
	},
};
