import {flatten, map, pipe, uniq} from "ramda";


export default function createResForEnum(Enum, RESOURCES){
	const resourcesKeys = pipe(
		Object.values,
		map(Object.keys),
		flatten,
		uniq,
	)(RESOURCES);

	 const resourcesByEnum = Enum.map(enumKey =>
		 resourcesKeys.reduce((enumRes, resKey) => {
			 const resource = RESOURCES[enumKey]?.[resKey];
			 enumRes[resKey] = (resource instanceof Function) ? resource
				 : () => resource;
			 return enumRes;
		 }, {})
	 );

	function getResource(enumValue){
		enumValue = Enum.from(enumValue);
		if (enumValue)
			return enumValue.select(resourcesByEnum);
	}

	Object.assign(getResource, resourcesByEnum);

	return getResource;
}
