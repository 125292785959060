import Firebase from "firebase/app"
import is from "../utils/is";

export default Firebase;

window.firebase = Firebase;
require("firebase/firebase-auth");
require("firebase/firebase-database");

Firebase.init = function (keyOrConfig) {
	let config;
	if (is(keyOrConfig, String))
		config = {
			...DEFAULT_CONFIG,
			apiKey: keyOrConfig,
		};
	else
		config = {
			...DEFAULT_CONFIG,
			...keyOrConfig,
		};

	Firebase.initializeApp(config);
	Firebase.initialized = true;
};

const DEFAULT_CONFIG = {
	authDomain: "rcm55-bagshop.firebaseapp.com",
	databaseURL: "https://rcm55-bagshop.firebaseio.com",
	projectId: "rcm55-bagshop",
	storageBucket: "rcm55-bagshop.appspot.com",
	messagingSenderId: "607058134897",
};
