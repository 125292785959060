import Base from "../../../../class/Base"
import simpleValidate from "../../../../utils/simpleValidate";

export default class Birthplace extends Base {

	getInvalidFields() {
		return Object.entries(Birthplace.generateValidateOf)
			.filter(([, validator]) => !validator.length) // remove validator that need arguments
			.filter(([field, getValidator]) => !getValidator().apply(this, [this[field], field]))
			.map(([field]) => field);
	}

	isValid() {
		return this.getInvalidFields()?.length === 0;
	}

	get formatted() {
		return (this.City ? this.City + " " : "") +
			(this.Country ? this.Country : "");
	}
}
Birthplace.addProperties({
	City: String,
	Country: String,
});
Birthplace.generateValidateOf = {
	City: () => simpleValidate.text,
	Country: () => simpleValidate.text,
};
