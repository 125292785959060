import React from "react";
import View from "library-react/component/View/v2";
import use from "library-react/hook";
import {Brand} from "library-react/library-js/app/model/entity";
import Server from "library-react/library-js/app/Server";
import {styles} from "../../../../../res";
import {BrandItem} from "./BrandItem";
import Portal from "../../../../../../library-react/component/Portal";
import {screens} from "../../../../../links";
import Title from "../Title";


export default function BrandListPinView({item, ...props}) {
	const names = use.memo(
		() => item?.names.map(name => Brand.parseName(name)),
		[item?.names]
	);

	const BRAND_PLACEHOLDERS = Array.range(names.length, null);

	const {value: brands, loading} = use.loadable.server(
		() => Server.public.brand.get(...names)
			.updateContent(content => names.map(name => content[name]).filter(Boolean)),
		[names]
	);


	const display = use.context.display();
	props.style = use.defaultStyle(props.style, localStyles.layout);


	return (
		<View {...props}>
			{
				Boolean(item?.title) && (brands?.length || loading) &&
				<Portal to={screens.brandList}>
					<Title>
						{item?.title}
					</Title>
				</Portal>
			}
			<View style={localStyles.items(display)}>
				{
					(brands || BRAND_PLACEHOLDERS).map((brand, index) =>
						<BrandItem key={index} item={brand} style={localStyles.item(display)}/>)
				}
			</View>
		</View>
	);
}


const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.column,
		flex: 1,
		flexBasis: '100%',
		paddingHorizontal: 20,
	},

	items: styles.static(
		{
			flexGrow: 1,
		},
		{
			mobile: {
				flexDirection: styles.flexDirection.column,
				alignItems: styles.alignItems.center
			},
			default: {
				flexDirection: styles.flexDirection.row,
				flexWrap: styles.flexWrap.wrap,
			}
		}
	),
	item: styles.static(
		{
			marginLeft: 0,
			margin: 10,
		},
		{
			mobile: {
				width: '100%',
			},
			default: {}
		}
	),
}