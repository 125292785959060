import React, { useState } from "react"
import { ViewProps } from "react-native";
import PopupMenu from "../../../library-react/component/PopupMenu";
import Portal from "../../../library-react/component/Portal";
import use from "../../../library-react/hook";
import bindPromiseToBoolState from "../../../library-react/utils/action/bindPromiseToBoolState";

export default function OrderOptions({ pdfURL, addToBasket, ...props }: OrderOptionsProps) {
	const [tasking, setTaskingTo] = useState(false);

	const addAllToBasket = !addToBasket ? undefined :
		function () {
			const result = addToBasket();
			if (result instanceof Promise)
				bindPromiseToBoolState(setTaskingTo, result);
		};

	const { Spinner } = use.theme();
	return (
		<PopupMenu
			disabled={tasking}
			trigger={tasking ? <Spinner size={24} /> : "MaterialCommunityIcons/dots-vertical"}
			{...props}>
			{
				addAllToBasket &&
				<PopupMenu.Item onPress={addAllToBasket}>
					{`Renouveler la commande`}
				</PopupMenu.Item>
			}

			{
				Boolean(pdfURL) &&
				<Portal to={pdfURL}>
					<PopupMenu.Item>
						{`Afficher la facture`}
					</PopupMenu.Item>
				</Portal>
			}
		</PopupMenu>
	);
}

interface OrderOptionsProps extends ViewProps {
	pdfURL: string;
	addToBasket?(): Promise<any>,
}