export default async function updateOneItemInList(load, predicate, update, persist){
	let list = await load();
	if (list){
		let index = list.findIndex(predicate);
		if (index >= 0){
			let item = update(list[index]);
			if (item)
				list.splice(index, 1, item);
			else // null -> delete
				list.splice(index, 1);

			return persist(list);
		}
	}
}
