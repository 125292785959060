import React from "react"
import use from "library-react/hook"
import MobileUI from "./mobile"
import DesktopUI from "./desktop"
import SizeSelectionModal from "../SizeSelectionModal"
import useApp from "library-react/app/useApp";
import {SizeContext, VProdshopContext} from "@main/ui/ProductUI/contexts";
import showSocialModal from "library-react/utils/showSocialModal";

export default function ProductSection({...props}) {
	const display = use.context.display();
	const vProdshop = use.context(VProdshopContext);

	const [titleOpened, setTitleOpened] = use.state(false);
	props.titleOpened = titleOpened;
	props.toggleTitle = use.callback(() => setTitleOpened(opened => !opened));

	const [size, setSize] = use.state(undefined);

	const app = useApp();
	const selectSize = props.selectSize =
		use.asyncCallback(shouldStop =>
				() => new Promise(select => {
					let close;
					const resolve = (newSize) => {
						if (newSize && !shouldStop())
							setSize(newSize);
						select(newSize);
						close();
					};

					close = app.openModal(
						<SizeSelectionModal
							sizes={vProdshop?.product.sizes}
							selected={size}
							onSelected={resolve}
							close={resolve}
						/>
					);
				}),
			[size, vProdshop?.product.sizes, app],
		);

	props.selectSize = selectSize;

	props.onChatClicked = vProdshop &&
		function startChattingAbout() {
			app.setChatAttachment(vProdshop);
		};



	props.share = () => showSocialModal(app, vProdshop);

	return (
		<SizeContext.Provider value={size}>
			{
				display.is.mobile ?
					<MobileUI {...props} /> :
					<DesktopUI {...props} />
			}
		</SizeContext.Provider>
	);
}
