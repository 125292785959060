import React from "react"
import use from "../../../../hook";
import {View as RNView} from "react-native";
import Text from "../../../../component/Text";
import {styles} from "../../../../res";
import ProductImage from "./ProductImage";

export default React.memo(
	function OrderGallery({gallery, ...props}) {
		props.style = use.defaultStyle(props.style, localStyles.layout);

		return (
			<RNView {...props}>
				{
					gallery.slice(0,3)
						.map((image, index) =>
							<ProductImage
								key={index}
								source={image}
								style={localStyles.image(index)}/>
						)
				}

				{(gallery.length > 3) && dots}
			</RNView>
		);
	}
);

const dots = (
	<Text
		numberOfLines={1}
		style={{
			alignSelf: styles.alignSelf.flexEnd,
			bold: true,
			fontSize: 11,
			color: '#1e2432',
		}}>
		…
	</Text>
);

const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
	},

	image: styles.static(
		{},
		[
			{},
			{marginLeft: -24},
			{marginLeft: -24},
		]
	),
};
