import React from "react"
import use from "../../../../../../hook";
import Text from "../../../../../../component/Text";
import {fonts, styles} from "../../../../../../res";
import moment from "moment";

export default React.memo(
	function DateView({time, ...props}) {
		props.style = use.defaultStyle(props.style, localStyles.layout);
		return (
			<Text {...props}>
				{moment(time).format("DD/MM/YYYY")}
			</Text>
		);
	}
)

const localStyles = {
	layout: {
		color: '#9a9999',
		textAlign: styles.textAlign.center,
		fontSize: 11,
		font: fonts.Poppins,
		marginVertical: 7.5,
	},
};
