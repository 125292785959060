import React from "react"
import use from "library-react/hook";
import Icon from "library-react/component/Icon/v2";
import {useNavigation} from "@react-navigation/native";
import {screens} from "@main/links";

/**
 * @param {any}
 */
export default function BackNavigationButton({onPress, ...props}) {
	const navigation = useNavigation();
	props.onPress = use.callback(() => {
		if (onPress)
			onPress();
		else if (navigation.canGoBack())
			navigation.goBack();
		else
			navigation.navigate(screens.home);
	}, [navigation, onPress])

	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<Icon
			name='back'
			size={28}
			{...props}/>
	);
};

const localStyles = {
	layout: {
		width: 50,
		height: 50,
		backgroundColor: '#e4e4e4',
		marginRight: 11,
	},
};
