import React from "react"
import {View as RNView} from "react-native"
import use from "library-react/hook"
import {styles} from "@main/res"
import Icon from "library-react/component/Icon/v2";
import Price from "library-js/app/model/Price";
import SimpleButton from "library-react/component/SimpleButton";
import {is} from "library-js/utils";
import {Linking} from "react-native-web";
import Portal from "library-react/component/Portal";
import {screens} from "@main/links";
import OrdersUnavailableMessage from "library-react/component/OrdersUnavailableMessage";

function CheckoutFooterDesktop(
	{
		basketItems, totalPrice,
		checkingOut,
		conditions,
		alignRightAt,
		onHelpClicked, onBuyClicked,
		...props
	}
) {
	if (is.null(totalPrice) && basketItems) {
		totalPrice = basketItems.reduce((total, vItem) => {
			total.value += vItem.vProdshop.prodshop.finalPrice.value;
			return total;
		}, new Price(0));
		totalPrice.currency = basketItems.first?.prodshop?.finalPrice.currency;
	}

	const {shop} = use.context.infos().vShop;

	const disabled = !shop.ordersAvailable;

	const {Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);
	const wrapperStyle = use.defaultStyle(localStyles.wrapper, {width: alignRightAt}, [alignRightAt]);

	return (
		<RNView {...props}>
			<RNView style={wrapperStyle}>
				{conditions && <Conditions/>}

				{/* total */}
				<RNView style={localStyles.total.layout}>
					<Text style={localStyles.total.label}>
						{`Total`}
					</Text>

					<Text style={localStyles.total.tva}>
						{`TVA compris`}
					</Text>

					<Text style={localStyles.total.price}>
						{totalPrice.formatted}
					</Text>
				</RNView>

				<RNView style={localStyles.buy.layout}>
					{/* buy */}
					<Portal to={!disabled && !checkingOut && !onBuyClicked && screens.checkout}>
						<SimpleButton
							border
							disabled={disabled}
							onPress={!checkingOut && onBuyClicked}
							filled={!checkingOut}
							colors={shop.ordersAvailable ? localStyles.buy.colors : localStyles.buy.disabledColors}
							hoverColors={shop.ordersAvailable && localStyles.buy.hoverColors}
							style={localStyles.buy.style}>
							<Text style={localStyles.buy.text(checkingOut)}>
								{checkingOut ? `Commande en cours..` : `COMMANDER`}
							</Text>

							{
								!shop.ordersOpened &&
								<OrdersUnavailableMessage
									shop={shop}
									style={{textAlign: styles.textAlign.center}}/>
							}
						</SimpleButton>
					</Portal>
				</RNView>
			</RNView>

			{
				onHelpClicked &&
				<Icon
					name="SimpleLineIcons/question"
					size={30}
					onPress={onHelpClicked}
					style={{marginLeft: 15}}
				/>
			}
		</RNView>
	);
}

export default React.memo(CheckoutFooterDesktop);

function Conditions() {
	const {Text} = use.theme();

	return (
		<RNView>
			<RNView style={{
				flexDirection: styles.flexDirection.row,
				alignItems: styles.alignItems.center,
				marginRight: 60,
				marginBottom: 5,
			}}>
				<Icon
					name="MaterialCommunityIcons/shield-lock"
					size={35}
					style={{marginRight: 13, color: "#9a9999"}}
				/>

				<Text
					numberOfLines={3}
					style={{width: 340, color: "#9a9999", fontSize: 11}}>
					{`En effectuant votre achat, vous acceptez `}
					<Text
						{...Linking.linkPropsFor("https://shopinzon.com/legal-agreement")}
						style={{color: styles.color.black}}>
						{`nos conditions d’utilisation`}
					</Text>
					{` et `}
					<Text
						{...Linking.linkPropsFor("https://shopinzon.com/private-policy")}
						style={{color: styles.color.black}}>
						{`notre politique de confidentialité`}
					</Text>
				</Text>
			</RNView>
		</RNView>
	);
}

const localStyles = {
	layout: {
		borderTopWidth: 1,
		borderColor: styles.color.lightgrey,
		paddingVertical: 18,
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.flexStart,
	},

	wrapper: {
		flexDirection: styles.flexDirection.row,
		justifyContent: styles.justifyContent.flexEnd,
		height: 65,
	},

	total: {
		layout: {
			flexDirection: styles.flexDirection.row,
			alignItems: styles.alignItems.baseline,
			backgroundColor: "#f7f7f7",
			padding: 20,
			minWidth: 420,
			marginRight: 50,
		},

		label: {
			fontSize: 19,
			bold: true,
		},

		tva: {
			fontSize: 14,
			color: "#9a9999",
			flex: 1,
			paddingHorizontal: 10,
		},

		price: {
			fontSize: 20,
			bold: true,
		}
	},
	buy: {
		layout: {
		},

		note: {
			marginTop: 5,
			textAlign: styles.textAlign.center,
			italic: true,
			color: styles.color.grey,
			fontSize: 17
		},
		colors: {
			primary: styles.color.black,
			secondary: styles.color.white,
		},
		hoverColors: {
			primary: '#70707020',
			secondary: styles.color.black,
		},
		disabledColors: {
			primary: '#70707020',
			secondary: '#707070',
		},


		text: styles.static.bool(
			{
				fontSize: 20,
			},
			{bold: true}
		),

		style: {
			minWidth: 370,
			flexGrow: 1,
			flexDirection: styles.flexDirection.column,
			justifyContent: styles.justifyContent.spaceEvenly,
		}
	}
};
