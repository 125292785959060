import "../../init"
import {AppState} from "react-native"
import permissionsMap from "./permissionsMap"
import {Objects, Trigger} from "../../library-js/utils"

// Module

/**
 * @typedef PermissionModule
 * @property {boolean} allowed
 * @property {Function} listen
 * @property {Function} check
 * @property {Function} request
 */

/**
 * @property {PermissionModule} location
 * @property {PermissionModule} camera
 * @property {PermissionModule} microphone
 * @property {PermissionModule} contacts
 * @property {PermissionModule} medias
 * @property {PermissionModule} bluetooth
 * @property {PermissionModule} notifications
 */
const Permissions = {};
export default Permissions;

Objects.forEach(permissionsMap, (key, checkPermission) => {
	if (checkPermission instanceof Function){
		let module;
		const trigger = new Trigger;

		// update 'allowed' property on changes
		const update = (allowed, error) => {
			if (!error) {
				if (module.allowed !== allowed) {
					const oldAllowed = Permissions[key].allowed;
					module.allowed = allowed;
					trigger.fire(allowed, oldAllowed);
				}
			} else
				console.warn("Error asking / checking permission: ", error);
		};

		// create module
		module = Permissions[key] = {
			allowed: undefined,
			listen: trigger.add.bind(trigger),
			check: () => checkPermission(false).transparent(update),
			request: () => checkPermission(true).transparent(update),
			// check: batchPromise(() => checkPermission(false).transparent(update)),
			// request: batchPromise(() => checkPermission(true).transparent(update)),
		};
	}
	// The permission is already decided according to platform (eg. Bluetooth is always allowed on Android)
	else {
		const allowed = Boolean(checkPermission);
		Permissions[key] = {
			allowed,
			listen: () => () => undefined,
			check: async () => allowed,
			request: async () => allowed,
		};
	}
});

// check values when app becomes active
const checkAllAtStart = () => Objects.forEach(Permissions, (_, module) => module.check());
if (AppState.is.active)
	checkAllAtStart();
else
	AppState.on.active.then(checkAllAtStart);
