import React from "react"
import { View as RNView } from "react-native"
import use from "../../../../../../../hook"
import { styles } from "../../../../../../../res"
import Icon from "../../../../../../../component/Icon/v2";
import TimeView from "../TimeView";
import View from "../../../../../../../component/View/v2";
import useShop from "../../../../../useShop";
import ReservationState from "../../../../../../../library-js/app/model/entity/Reservation/State";
import toMoment from "library-js/utils/toMoment";

function ReservationNotificationView({ item, onReservationClicked, ...props }) {
	const shop = useShop();
	const { vReservation, time } = item;
	const { reservation, currentState } = vReservation;
	const at = toMoment(shop?.timezone, reservation.time);

	const state = currentState.type;
	const { Text } = use.theme();

	if (onReservationClicked)
		props.onPress = () => onReservationClicked(vReservation);

	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<View {...props}>
			<Icon
				name="reservation-restaurant"
				size={15}
				style={localStyles.icon(state)}
			/>

			<RNView style={{ flex: 1 }}>
				<Text
					numberOfLines={1}
					style={localStyles.title(state)}>
					{reservationNotificationTxt(item)}
				</Text>

				<RNView style={localStyles.body}>
					<RNView style={localStyles.fields}>
						{
							[
								// date
								at.format('D MMMM YYYY'),

								// time
								at.format('HH[h:]mm'),

								// places
								`${reservation.numberOfPlaces}p`
							].map((field, index) => (
								<Text
									key={index}
									numberOfLines={1}
									style={localStyles.field(index)}>
									{field}
								</Text>
							))
						}
					</RNView>

					<TimeView time={time} />
				</RNView>
			</RNView>
		</View>
	);
}

export default React.memo(ReservationNotificationView);

export function reservationNotificationTxt(notification) {
	const id = notification.vReservation.reservation.id;
	const state = notification.vReservation.currentState.type;
	const reason = notification.reason;

	return (
		reason?.select({
			create: `Nouvelle réservation #${id}`,
			state: ReservationState.Type.select(state, {
				waiting: `Réservation #${id} mise en attente`,
				refused: `Réservation #${id} annulée`,
				accepted: `Réservation #${id} validée`,
			}),

			default: `La réservation #${id} a été mise a jour`,
		})

		|| state?.select({
			refused: `Réservation #${id} annulée`,
			accepted: `Réservation #${id} validée`,
			default: `Nouvelle réservation #${id}`,
		})
	);
}

const localStyles = {
	layout: {
		flexDirection: 'row',
		alignItems: 'flex-start',
		marginVertical: 10,
	},

	icon: stateType => ({
		color: selectColor(stateType),
		marginRight: 11,
		marginTop: 3,
	}),

	title: stateType => ({
		color: selectColor(stateType),
		fontSize: 13,
	}),

	body: {
		flexDirection: 'row',
	},

	fields: {
		flexDirection: 'row',
		flex: 1,
	},

	field: styles.static.bool({
		fontSize: 11,
		color: 'black',
	}, { marginLeft: 10 }),
};

function selectColor(stateType) {
	return stateType?.select(COLORS) || 'black';
}

const COLORS = {
	accepted: '#30b167',
	refused: '#ca293e',
};
