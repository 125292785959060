import Base from "../../../../class/Base";

export default class CatalogSectionItem extends Base {
	constructor(productId, sectionId) {
		super();
		this.hydrate({productId, sectionId});
	}
}
CatalogSectionItem.addProperties({
	productId: Number,
	sectionId: Number,
});
