import React from "react"
import { View as RNView } from "react-native"
import use from "library-react/hook";
import { styles } from "@main/res";
import useAuth from "library-react/hook/useAuth";
import Spinner from "@main/component/Spinner";
import desktop from "./desktop.js"
import mobile from "./mobile.js"
import responsive from "@main/hoc/responsive";
import { useNavigation } from "@react-navigation/native";

function AccountUI({ onNoUser, ...props }, { Component }) {
	const authState = useAuth.state();
	use.effect(authState.is.no && onNoUser, [authState]); // redirect to home

	// DISPLAY
	const navigation = useNavigation();
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<RNView {...props}>
			{
				authState.run({
					yes: () => (
						<Component onBackClicked={navigation.goBack} />
					),
					authenticating: () => <Spinner style={styles.fit} />,
					no: () => null,
				})
			}
		</RNView>
	);
}

export default React.memo(responsive({ default: desktop, mobile }, AccountUI));


const localStyles = {
	layout: { flex: 1 },
};
