import React from "react"
import use from "library-react/hook"
import {styles} from "../../../res"
import AppBar from "library-react/component/AppBar/AppBar";
import View from "library-react/component/View";
import MobileMenu from "./MobileMenu"
import ShopLogo from "../../../component/ShopLogo";
import BasketButton from "../BasketButton";
import useApp from "library-react/app/useApp";
import Portal from "library-react/component/Portal";
import {screens} from "@main/links";

function MainAppBar({menuItems, navigationItems, onAccountClicked, ...props}) {
	const {shop} = use.context.infos().vShop;

	const app = useApp();
	const onMenuClicked = () => {
		const close = app.openModal(
			<MobileMenu
				menuItems={menuItems}
				navigationItems={navigationItems}
				onAccountClicked={() => {
					close();
					onAccountClicked();
				}}/>
		);
	};

	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<AppBar {...props}>
			{/* Menu */}
			<AppBar.ActionButton
				icon='menu'
				onPress={onMenuClicked}
				style={localStyles.menu}/>

			{/* Logo */}
			<Portal to={screens.home}>
				<ShopLogo style={localStyles.logo}/>
			</Portal>

			{/* Actions */}
			<View style={localStyles.actions.layout}>
				{/* Search */}
				<Portal to={screens.search}>
					<AppBar.ActionButton
						icon='search'
						style={localStyles.actions.button}
					/>
				</Portal>

				{/*	 Basket */}
				{
					shop.ordersEnabled &&
					<Portal to={screens.basket}>
						<View style={[localStyles.actions.button, styles.center]}>
							<BasketButton/>
						</View>
					</Portal>
				}
			</View>
		</AppBar>
	);
}

export default React.memo(MainAppBar);


const actionButtonStyle = {
	paddingHorizontal: 20,
};

const localStyles = {
	layout: {
		minHeight: 72,
		borderBottomWidth: 1,
		alignItems: styles.alignItems.center,
		borderColor: '#f2f2f2',
	},

	menu: {
		...actionButtonStyle,
		position: styles.position.absolute,
		left: 0,
		top: 0,
		bottom: 0,
	},

	logo: {
		flex: 1,
		width: 150,
	},

	actions: {
		layout: {
			flexDirection: styles.flexDirection.row,
			position: styles.position.absolute,
			right: 0,
			top: 0,
			bottom: 0,
		},

		button: actionButtonStyle,
	}
};
