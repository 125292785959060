import React from "react"
import { View as RNView } from "react-native"
import use from "library-react/hook"
import { styles } from "@main/res"
import View from "library-react/component/View/v2";
import Image from "library-react/component/Image";
import Icon from "../../library-react/component/Icon/v2";
import { dec, inc } from "ramda";
import timeout from "../../library-react/library-js/utils/timeout";
import useShowcases from "@main/hook/useShowcases"
import Portal from "../../library-react/component/Portal";
import { screens } from "../links";

function ShowcasesBanner({ ...props }) {
	const showcases = useShowcases();
	const [selection, setSelection] = use.state(0);

	const length = showcases?.length;
	const isCarousel = length > 1;
	const selectedIndex = isCarousel ? Math.abs(selection % length) : 0;
	use.effect(() => isCarousel && timeout(() => setSelection(inc), 5000), [isCarousel, selection]);

	const display = use.context.display();
	props.style = use.defaultStyle(props.style, localStyles.layout, [display]);
	if (!(length > 0))
		return null;

	return (
		<RNView {...props}>
			{
				showcases.map((item, index) =>
					<Portal key={item.showcase.id}
						to={screens.showcase}
						params={{ id: item.showcase.id }}>
						<ShowcaseSlide
							item={item}
							style={localStyles.slide(index === selectedIndex)} />
					</Portal>
				)
			}


			{
				isCarousel &&
				<>
					<Icon
						name="MaterialIcons/chevron-left"
						size={40}
						onPress={() => setSelection(dec)}
						style={localStyles.arrow.left}
					/>
					<Icon
						name="MaterialIcons/chevron-right"
						size={40}
						onPress={() => setSelection(inc)}
						style={localStyles.arrow.right}
					/>
				</>
			}
		</RNView>
	);
}

export default React.memo(ShowcasesBanner);

function ShowcaseSlide({ item: { vShowcase }, ...props }) {
	const { Text } = use.theme();
	const picture = vShowcase.banner || vShowcase.portrait;

	const display = use.context.display();
	props.style = use.defaultStyle(props.style, styles.fit);
	return (
		<View {...props}>
			<Image shouldNotResize source={picture} style={styles.fit} />
			<Text
				numberOfLines={2}
				style={localStyles.title(display)}>
				{vShowcase.title}
			</Text>

		</View>
	);
}

const localStyles = {
	layout: display => ({
		height: display.is.mobile ? 150 : 260,
	}),

	slide: styles.static.bool(
		{
			...styles.fit,
			backgroundColor: styles.color.white,
			justifyContent: styles.justifyContent.flexEnd,
		},
		{ zIndex: 1 },
		{
			zIndex: -1,
		},
	),

	title: styles.static({
		color: styles.color.white,
		bold: true,
		paddingBottom: 12,
		paddingLeft: 30,
		zIndex: 1,
		maxHeight: '100%',
		textShadowColor: 'rgba(0, 0, 0, 0.5)',
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 5,
	},
		{
			mobile: { fontSize: 17 },
			default: { fontSize: 43 }
		}),

	arrow: styles.static({
		...styles.absolute({ top: 0, bottom: 0 }),
		color: styles.color.white,
		paddingHorizontal: 20,
		zIndex: 2,
		textShadowColor: 'rgba(0, 0, 0, 0.5)',
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 5,
	}, {
		left: { left: 0 },
		right: { right: 0 },
	})
};
