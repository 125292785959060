import React from "react"
import {styles} from "@main/res";
import use from "library-react/hook";

export default function Title({...props}) {
	const {Text} = use.theme();
	const display = use.context.display();
	props.style = use.defaultStyle(props.style, localStyles.title(display), [display]);

	return <Text {...props}/>;
}


const localStyles = {
	title: styles.static(
		{
			alignSelf: styles.alignSelf.stretch,
			bold: true,
			marginBottom: 20,
		},
		{
			mobile: {fontSize: 16},
			default: {fontSize: 22}
		}
	),
}
