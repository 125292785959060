import { curry, Dictionary } from "ramda";
import { nil, Primitive } from "../../types";
import assignParamsToUrl from "./assignParamsToUrl";
import setPathToUrl from "./setPathToUrl";
const {pipe} = require('ramda');

function setPathParamsToUrl_core(pathParams: PathParams | nil, url: string | URL): string {
	return pipe(
		setPathToUrl(pathParams?.path),
		assignParamsToUrl(pathParams?.params),
	)(url) as string;
}

const setPathParamsToUrl = curry(setPathParamsToUrl_core);
export default setPathParamsToUrl;

export interface PathParams {
	path?: string | string[] | nil,
	params?: Dictionary<Primitive | nil> | nil,
}