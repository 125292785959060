import React from 'react'
import { View as RNView, StyleSheet } from "react-native"
import vectorIcons from "../vectorIcons"
import Debug from "../../../library-js/Debug"
import View from "../../View/v2"
import ComponentUtils from "../../../ComponentUtils"
import Colorable from "../../../plugin/Colorable";
import { styles } from "../../../res"
import use from "../../../hook";
import { Objects } from "../../../library-js/utils";
import * as SVG from "react-native-svg";
import icons, { add } from "../icons";
import { TextStyleContext } from "../../Text/v2";
import { is, isNil } from "ramda";
import { SvgXml } from "react-native-svg";


function Icon2({ name, size = 14, children, Layout = View, ...props }) {
	if (!(size >= 0))
		size = 14;

	// convert style to object in order to extract color
	const mergedStyles = use.memo(
		style => StyleSheet.flatten([styles.center, style]),
		[props.style]
	);

	

	props.style = { ...mergedStyles };

	const inheritedTextStyle = use.context(TextStyleContext);
	const inheritedColor = use.memo(() => ComponentUtils.style.merge(inheritedTextStyle).color, [inheritedTextStyle]);
	const { color = inheritedColor } = Objects.retrieve(props.style, ["color"], true);
	const colors = [color].flat(); // force colors as array

	let icon; // result

	// vector icons
	if (is(String, name) && name.includes("/")) {
		let [namespace, iconName] = name.split("/");
		let VectorIcon = vectorIcons[namespace];
		Debug.assert(!VectorIcon, "Non existing icon set : " + namespace, true);

		if (VectorIcon)
			icon = (
				<VectorIcon
					name={iconName}
					color={colors[0]}
					size={size} />
			);
	} else if (!isNil(name)) { // app icons
		const renderIcon = (name instanceof Function) ? name(SVG) : icons[name];
		if (!colors.first)
			colors.first = styles.color.black;

		let element;
		if (is(Function, renderIcon)) {
			try {
				element = renderIcon(...colors);
				if (is(String, element)) {
					try {
						element = (
							<SvgXml
								xml={element}
								width={`${size}%`}
								height={`${size}%`} />
						);
					} catch (error) {
						console.warn("Error displaying icon: ", error);
					}
				}
			} catch (error) {
				console.warn("Error displaying icon: ", error);
			}
		}
		else
			try {
				element = evalIcon(name)(colors);
			} catch (error) {
				console.warn(`icon ${name} not defined & cannot be evaluated.`);
			}

		if (element) // set size to Svg
			icon = React.cloneElement(element, styles.square(size));
	}

	if (!icon) // placeholder
		icon = <RNView style={styles.square(size)} />;

	return (
		<Layout {...props}>
			{icon}
			{children}
		</Layout>
	);
}

const Icon = React.memo(Icon2);
Icon.add = add;
export default Icon;

// --- auto styling ----
Icon.colorable = Colorable.fromStyle;

function evalIcon(codeIcon) {
	return eval(`({
					  Svg, Rect, Circle, Ellipse,
					  Line, Polygon, Polyline,
					  Path, Text, TSpan,
					  TextPath, G, Use,
					  Symbol, Defs, Image,
					  ClipPath, LinearGradient, RadialGradient,
					  Mask, Pattern,
				  }, React) => ${codeIcon}`)(SVG, React);
}
