import React from "react"
import {View as RNView} from "react-native"
import use from "library-react/hook"
import {styles} from "@main/res"
import ProductItem from "@main/component/ProductItem";
import Portal from "library-react/component/Portal";
import {screens} from "@main/links";

export default function ({title, iterator, ...props}) {
	const {Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<RNView {...props}>
			<Text style={localStyles.title}>
				{title}
			</Text>

			<RNView style={localStyles.products.layout}>
				{
					iterator.items.slice(0, 4)
						.map(vProdshop => {
							const {id} = vProdshop.product;
							return (
								<Portal
									key={id}
									to={screens.product}
									params={{id}}>
									<ProductItem
										vProdshop={vProdshop}
										style={localStyles.products.item}
									/>
								</Portal>
							)
						})
				}
			</RNView>
		</RNView>
	);
};

const localStyles = {
	layout: {
		paddingHorizontal: 30,
	},

	title: {
		fontSize: 23,
		marginBottom: 30,
		bold: true,
	},

	products: {
		layout: {
			flexDirection: styles.flexDirection.row,
			flexWrap: styles.flexWrap.wrap,
		},

		item: {
			marginRight: 60,
		},
	},
};
