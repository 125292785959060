import {Accessor} from "../../../../utils";

export default class ListCacheAccessor extends Accessor {
	constructor(cursorKey = "from") {
		let removeCursorTemporally = request => {
			// retrieve cursor from request
			let cursor = request.parameters[cursorKey];
			delete request.parameters[cursorKey];

			// return the function to put back the cursor
			return () => {
				request.parameters[cursorKey] = cursor;
			};
		};

		super({
			// split request into multiple request with only one id
			get(cache, request){
				//if the parameters contain a cursor key this means it doesn't want to load from cache anymore
				if (request.parameters[cursorKey])
					return;

				let [cached] = cache.get(request);
				return cached;
			},

			set(cache, entries, timeout){
				entries = entries.map(({request, content}) => {
					let putBackCursor = removeCursorTemporally(request);
					request = cache.requestToKey(request);
					putBackCursor();

					let cached = this.get(cache, request);
					if (cached){
						cached.push(...content);
						content = cached;
					}

					return {request, content};
				});

				return cache.set(entries, timeout);
			}
		});
	}
}
