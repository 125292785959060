import React from 'react';
import {Classes} from "./library-js/class";
import ComponentUtils from "./ComponentUtils";
import {is, Objects} from "./library-js/utils";

export default class Component extends React.Component {
	constructor(props) {
		super(props);
		const {constructor} = this;

		// ---- auto bind methods ----
		let methods = methodsCache.get(constructor); // check cache
		if (!methods)
			methodsCache.set(constructor, methods = Object.keys(Classes.getMethodsOf(constructor, Component)));

		methods.forEach(key => this[key] = this[key].bind(this));
		this.forceUpdate = this.forceUpdate.bind(this);

		// -------- properties -------------
		this.state = {_instance : this};
		this.mounted = 0;
		this.propsForRender = {};

		// fire is the collection of callbacks. It checks automatically the current props
		this.fire = {};
		if (constructor.callbacks)
			constructor.callbacks.forEach(callback => {
				this.fire[callback] = (...params) => {
					if (this.props[callback])
						this.props[callback](...params);
				}
			});
	}

	/**
	 * Decides if props updates are `critic` or not.
	 * Default implementation: check if each props defined in the {@link criticalProps} static array have changed between the new and old props.
	 * If the component is mounting, or if one of those {@link criticalProps} changed, the update is considered as `critic` (returns true).
	 * A `critic` update means for the component that its state becomes now obsolete and it should reset it at its initial values (using {@link onResetState}),`
	 * If an update is considered as critic:
	 * <ul>
	 *     <li>{@link onResetState} method will be called.</li>
	 *     <li>{@link mounted} property will be incremented.</li>
	 *     <li>All previously returned checker function from {@link getAsyncStopChecker} will now start to return `true` in order to tell all current async works in the component to stop and to do not update component state since they work becomes obsolete because this critical update happened on the component.</li>
	 * </ul>
	 *
	 * @param {Object} props New props.
	 * @returns {Boolean} True if the update is critic. False otherwise.
	 */
	isUpdateCritic(props, oldProps, state){
		return !oldProps || (is(this.constructor.criticalProps, Array) && this.constructor.criticalProps.some(name => oldProps[name] !== props[name]));
	}

	// --------- Mounting --------

	/**
	 * @override
	 */
	componentDidMount(){
		this._onNewProps(this.props, undefined);
	}

	// ----------- Updating -----------
	editStateOnNewProps(state, props, critic){}

	/**
	 * Callback when props update is considered `critic.
	 * Critic: component mounted (oldProps is undefined) or props listed in `static criticalProps` have changed their value.
	 * You should reset the received state.
	 * @param {Object} state Current component instance's state.
	 * @param {Object} props New props.
	 */
	onResetState(state, props){}

	/**
	 * @returns {function(): boolean} THe returned function will indicates if launched async work in the component became obsolete and should then be stopped, or when finished, if they should then not update the component state.
	 *
	 * Exemple:
	 * <code>
	 *     // `id` prop is a critical prop.
	 *     const shouldStop = this.getAsyncStopChecker();
	 *     // load item from Server, takes time
	 *     let item = await Server.loadItemById(this.props.id);
	 *
	 *     // Here, while fetching data, the id prop changed.
	 *     // So now our loaded item is obsolete, and our `shouldStop` function will now return `true`.
	 *     if (shouldStop()) return;
	 *
	 *     // This check above will prevent the state to be updated (below) with wrong data.
	 *     this.setState({item});
	 * </code>
	 */
	getAsyncStopChecker(){
		let mounted = this.mounted;
		return () => (!this.mounted || this.mounted !== mounted);
	}

	/**
	 * @override
	 * @returns {Promise}
	 */
	setState(updater, callback){
		return new Promise(resolve =>
			super.setState(
				!is(updater, Function) ? updater :
					(state, props) => {
						updater(state, props);
						this.onUpdatingState(state);
						return state;
					},
				() => {
					if (callback)
						callback(this.state);
					resolve(this.state);
				})
		);
	}

	onUpdatingState(state){}

	/**
	 * @override
	 */
	shouldComponentUpdate(nextProps, nextState){
		let {trivialProps, callbacks} = this.constructor;

		return !Object.is(this.state, nextState) // state changed using setState
			|| Objects.some(nextProps, (key, value) => // compare props
				!trivialProps.includes(key) // not trivial
				&& !callbacks.includes(key) // not callback
				&& !this.compareTwoProps(key, this.props[key], value) // the 2 props are different
			);
	}

	/**
	 * Default implementation of {@link shouldComponentUpdate} uses this method to compare non trivial props, and triggers the render method if compareTwoProps returns false.
	 * @param {String} key The key in {@link props} object.
	 * @param {*} currentProp The 1st prop.
	 * @param {*} newProp The 2nd prop.
	 * @returns {boolean} True if the 2 props are the same. False otherwise.
	 */
	compareTwoProps(key, currentProp, newProp){
		return Object.is(currentProp, newProp);
	}

	forceUpdate(){
		return new Promise(resolve => super.forceUpdate(resolve));
	}

	/**
	 * @override
	 */
	render(){
		return this.onRender({...this.propsForRender}, this.state);
	}

	/**
	 * Called on render invoked.
	 * @param {Object} props Those props are {@link propsForRender}. Those are modifiable props returned by the {@link onConvertProps} methods..
	 * @param {Object} state Current state.
	 * @returns {*} The returned value will returned by the {@link render} original method.
	 */
	onRender(props, state){
		return null;
	}

	/**
	 * @override
	 */
	componentDidUpdate(props){
		// Check if props have changed
		if (props !== this.props)
			this._onNewProps(this.props, props);
	}

	_onNewProps(props, oldProps){
		// check if props update is critic
		let critic = this._criticalUpdate || !oldProps;
		this._criticalUpdate = null; // clean

		// callback
		this.onNewProps(oldProps, critic);
	}

	/**
	 * This lifecycle method is triggered when new props have been placed.
	 * This method is triggered AFTER the render method.
	 * @param {Object?} oldProps The old props
	 * @param {Boolean} critic Indicates if one of the {@link criticalProps} has been changed.
	 */
	onNewProps(oldProps, critic){}

	/**
	 * This method convert {@link props} on new props set, and set the value in {@link propsForRender} which will be passed to the {@link onRender} method as parameter.
	 * Default behavior will just use {@link ComponentUtils.convertProps} function to make modifiable props object.
	 * @param {Object} props Current props;
	 * @returns {Object} Should return
	 */
	onConvertProps(props){
		return ComponentUtils.convertProps(props);
	}

	/**
	 * This will attach to your promise a 'then' & 'catch' functions which will throw an error with the message {@link Component.Errors.NEW_PROPS} in case the component has received a critic change when the promise has been resolved/rejected.
	 * The error have properties 'resolved' or 'rejected' which contain the result or the error of the promise if it was either resolved or rejected.
	 * @param {Promise} promise Promise to cancel on component get critic changes.
	 * @returns {Promise} The new cancelable promise.
	 * TODO remove [user]
	 */
	cancelOnNewProps(promise){
		let shouldStop = this.getAsyncStopChecker();

		return promise.result((result, error) => {
			if (shouldStop()){
				let criticChangeError = new Error(Component.Errors.NEW_PROPS);
				criticChangeError.rejected = error;
				criticChangeError.resolved = result;
				throw criticChangeError;
			}

			if (error)
				throw error;

			return result;
		});
	}

	// ----------- Unmounting ---------
	/**
	 * @override
	 */
	componentWillUnmount(){
		this.mounted = null;
	}

	/**
	 * This will attach to your promise a 'then' & 'catch' functions which will throw an error with the message {@link Component.Errors.UNMOUNTED} in case the component was unmounted when the promise has been resolved/rejected.
	 * The error have properties 'resolved' or 'rejected' which contain the result or the error of the promise if it was either resolved or rejected.
	 * @param {Promise} promise Promise to cancel on component unmounted.
	 * @returns {Promise} The new cancelable promise.
	 * TODO remove [user]
	 */
	cancelOnUnmounted(promise){
		return promise.result((result, error) => {
			if (!this.mounted){
				let unmountedError = new Error(Component.Errors.UNMOUNTED);
				unmountedError.resolved = result;
				unmountedError.rejected = error;
				throw unmountedError;
			}

			if (error)
				throw error;

			return result;
		});
	}


	// ------------ Error handling --------
	/**
	 * @override
	 */
	componentDidCatch(){}

	// ---------- STATIC ----------

	/**
	 * @returns {Component} Return the parent class.
	 */
	static get parent(){
		return Classes.getParentOf(this);
	}

	/**
	 * @override
	 */
	static getDerivedStateFromProps(props, state){
		const instance = state._instance;

		// check if update is critic
		let critic = instance._criticalUpdate = !instance.mounted
			|| instance.isUpdateCritic(props, instance.props, state);

		instance.editStateOnNewProps(state, props, critic);

		if (critic){
			// cancel async works
			instance.mounted++;
			// reset the state
			instance.onResetState(state, props);
		}

		// convert props for onRender function
		instance.propsForRender = instance.onConvertProps(props);

		return state;
	}

	/**
	 * Array of props' names considered critical.
	 * You should concat your component's array of critical props to its parent's one.
	 * If one of this props is changed, the update is considered as `critic`.
	 * You can rewrite the algorithm to decide if an update considered `critic` by overriding {@link isUpdateCritic} method.
	 * @type {Array.<String>}
	 */
	static criticalProps = [];

	/**
	 * Array of props' names considered as trivial.
	 * Any changes in these props will not trigger the render method.
	 * @type {Array.<String>}
	 */
	static trivialProps = [];

	/**
	 * Array of callbacks props. Callbacks can be called via the {@link fire} object and are considered as trivial.
	 * @type {Array.<String>}
	 */
	static callbacks = [];

	/**
	 * Possible errors throw by {@link Component}.
	 * @type {{CRITIC: string, UNMOUNTED: string}}
	 */
	static Errors = {
		/**
		 * Error message for promise subscribed to {@link cancelOnNewProps}.
		 */
		NEW_PROPS : "Component has new props.",

		/**
		 * Error message for promise subscribed to {@link cancelOnUnmounted}.
		 */
		UNMOUNTED : "Component has been unmounted.",
	};
}

const methodsCache = new WeakMap();
