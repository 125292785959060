import { CommonActions, StackActions, useLinkProps } from "@react-navigation/native";
import React from "react";
import { Linking, View, Pressable, TouchableOpacity } from "react-native";
import LinkContext from "../../context/LinkContext";
import use from "../../hook";
import Environment from "../../library-js/Environment";
import parallel from "../../library-js/utils/function/parallel";
import URL from "../../library-js/utils/URL";


/**
 * Why Portal ? Wasn't Link good enough ?
 * 1. Link needs an ENCODED link string which you must create.
 * 2. Link requires the action
 * 3. Portal unify mobile & web
 * 4. Link is a Text.
 * 		While Portal is just a function which passes props to the component of your choice.
 * 		And those props are automatically generated from just a screen name by the way.
 */
export default function Portal({ to: destination, params, onWillNavigate, onNavigated, children: child, action: actionProp }) {
	const url = URL.parseSafely(destination);
	const screen = url ? undefined : destination;
	const openURL = url && (() => Linking.openURL(url.href));

	const action = screen && correctAction(actionProp, screen, params);
	const { onPress: goToScreen, href } = useLinkProps({
		to: { screen, params },
		action,
	});

	const inLink = use.context(LinkContext);
	const props = {};

	let navigate = Environment.run({
		web() {
			if (screen) {
				if (!inLink) {
					props.href = href;

					if ([Pressable, TouchableOpacity].includes(child?.type))
						console.warn('Portal: You are using a TouchableOpacity or Pressable component as a child of Portal. This is not supported in web. Please use a View component instead.');
				}
				
				return goToScreen;
			}

			if (url) {
				if (inLink)
					return openURL;

				props.href = url.href;
				props.hrefAttrs = { target: "_blank" };
			}
		},

		default() {
			return openURL || (screen && goToScreen);
		}
	});

	if (navigate) {
		const handleKey = getHandleKey(inLink);
		props[handleKey] = parallel(
			onWillNavigate,
			navigate,
			onNavigated,
		);
	}

	if (props.href)
		props.accessibilityRole = 'link';



	return (
		<LinkContext.Provider value={inLink || props.href}>
			{React.cloneElement(child, props)}
		</LinkContext.Provider>
	);
}


const DEFAULT_ACTION = Environment.select({
	web: StackActions.push,
	default: CommonActions.navigate,
});

function getHandleKey(inLink) {
	return Environment.is.web && !inLink ? 'onClick' : 'onPress';
}


function correctAction(action, name, params) {
	if (!action)
		action = DEFAULT_ACTION;

	if (action instanceof Function)
		action = action(name, params);

	return action;
}

