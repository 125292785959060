import React from "react"
import View from "./View/v2";
import Text from "./Text";
import use from "../hook";
import Image from "./Image";
import {fonts, images, styles} from "../res";
import is from "../library-js/utils/is";

export default function EmptyResult({title, subtitle, ...props}) {
	title = is.defined(title) ? title : `Aucun résultats`;

	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<View {...props}>
			<Image
				source={images.emptyResult}
				resizeMode="contain"
				style={{width: 300, height: 245}}
			/>

			<Text style={localStyles.title}>
				{title}
			</Text>

			{
				Boolean(subtitle) &&
				<Text style={localStyles.subtitle}>
					{subtitle}
				</Text>
			}
		</View>
	)
}

const localStyles = {
	layout: [{paddingVertical: 20}, styles.center],
	
	title: {
		color: '#1e2432',
		fontSize: 19,
		bold: true,
		font: fonts.Poppins,
		marginTop: 5,
		textAlign: styles.textAlign.center,
	},
	subtitle: {
		color: '#939191',
		fontSize: 14,
		font: fonts.Poppins,
		marginTop: 5,
	},
}
