import Base from "../../../../class/Base"

export default class PaymentResponse extends Base {}
PaymentResponse.addProperties({
	orderId : Number,

	/**
	 * Optional 3D secure url.
	 */
	secure : String,
});
