import Portal from "library-react/component/Portal";
import {screens} from "@main/links";
import React from "react";
import use from "library-react/hook";
import Image from "library-react/component/Image";
import {styles} from "@main/res";
import LoadingPlaceholder from "library-react/component/LoadingPlaceholder";

export function BrandItem({item: brand, ...props}) {
	const {Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<Portal to={screens.brand} params={{name: brand?.name}}>
			<LoadingPlaceholder disabled={brand} {...props}>
				{
					brand?.logo ?
						<Image source={{uri: brand?.logo?.url, width: 500}}
							   resizeMode='contain'
							   style={localStyles.logo}/> :
						<Text style={localStyles.title} numberOfLines={1}>
							{brand?.name}
						</Text>
				}
			</LoadingPlaceholder>
		</Portal>
	)
}

const localStyles = {
	layout: {
		...styles.center,
		borderWidth: 1,
		borderColor: '#9a9999',
		minWidth: 350,
		maxWidth: 520,
		height: 100,
		padding: 10,
	},
	logo: {
		...styles.fit,
	},
	title: {
		fontSize: 35,
		bold: true,
	}
}
