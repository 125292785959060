import moment from "moment";
import Debug from "../../../Debug";
import toMoment from "../../../utils/toMoment";
import Entity from "./Entity";

export default class ReservationClosure extends Entity {
	get reopenTime() {
		if (this.start && this.days)
			return this.start + this.days * Date.DAY;
	}

	get lastDay() {
		const reopen = this.reopenTime;
		if (reopen)
			return reopen - Date.DAY;
	}

	getStartMoment(timezone) {
		Debug.assert(!timezone, 'ReservationClosure.getStartMoment requires timezone as parameter', true);
		return toMoment(timezone, this.start);
	}

	getReopenMoment(timezone) {
		if (this.days >= 1)
			return this.getStartMoment(timezone)?.add(this.days, 'days');
	}

	getLastMoment(timezone) {
		return this.getReopenMoment(timezone)?.subtract(1);
	}

	// I do not recommend to implement and use this method
	// setLastDay(timestamp) {
	// 	if (this.start){
	// 		if (timestamp) {
	// 			let days = ...;
	// 			if (days < 1)
	// 				days = 1;
	// 			this.days = days;
	// 		}
	// 		else
	// 			this.days = undefined;
	// 	}
	// 	else {
	// 		this._maybeLastDay = timestamp;
	// 	}

	// 	return this;
	// }
}
ReservationClosure.addProperties({
	title: String,
	start: String, // timestamp
	days: Number, // integer
});