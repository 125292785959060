import React from "react"
import runUndo from "./runUndo";

export default function useEffect(effect, dependencies) {
	if (!dependencies)
		dependencies = [];

	const memory = React.useRef({unmounted: false}).current;

	function startEffect(){
		runUndo(memory.undo);
		memory.undo = effect instanceof Function ? effect(...dependencies)
			: undefined;
	}

	if (!memory.dependencies || !memory.dependencies.match(dependencies)) {
		memory.dependencies = [...dependencies];
		// run async work
		setTimeout(startEffect);
	}

	// undo on unmount
	React.useEffect(() => {
			if (memory.unmounted){ // fix for fast-refresh which runs the undo-effect for unmount and then re-runs the effect
				memory.unmounted = false;
				startEffect();
			}

			return () => {
				memory.unmounted = true;
				runUndo(memory.undo);
			};
		},
		[]
	);
}
