import React from "react"
import {Helmet} from "react-helmet";
import {useIsFocused, useNavigation} from "@react-navigation/native";
import URL from "../library-js/utils/URL";
import is from "../library-js/utils/is";
import Image from "../library-js/app/model/media/Image";
import {equals} from "ramda";
import use from "../hook";

export default function SEO({
								canonical,
								canonicalParams,
								optimizationParams,
								title,
								image,
								description,
								children,
								status,
								...props
							}) {
	const canonicalURL = is(canonical, String) ? new URL(canonical, window.location.origin) : undefined;

	if (canonicalURL && canonicalParams)
		canonicalURL.searchParams.assign(canonicalParams);

	let alternateURL = undefined;

	if (is(canonicalURL, URL) && is(optimizationParams)) {
		alternateURL = new URL(canonicalURL);
		alternateURL.searchParams.assign(optimizationParams);
	}

	const navigation = useNavigation();
	use.effect(status === 200 && optimizationParams && (() => navigation.setParams(optimizationParams)), [status])

	{
		if (!is(image, String, true))
			image = String(image);

		if (Image.canResizeFromUrl(image))
			image = Image.getUrlWithSize(image, 500);
	}


	let isFocused = true
	try {
		isFocused = useIsFocused();
	} catch (error) {
	}

	return isFocused && (
		<Helmet {...props}>
			<meta property="og:type" content="website"/>

			<meta name="robots"
				  content={!canonicalURL ? "noindex" : undefined}/>

			{
				status !== undefined &&
				<meta name="status" content={status}/>
			}

			{
				canonicalURL && [
					// SEO
					<link
						key="canonic-SEO"
						rel="canonical"
						href={canonicalURL.href}/>,
					// Social
					<meta
						key="canonic-social"
						property="og:url"
						content={canonicalURL.href}/>
				]
			}
			{
				alternateURL && [
					// SEO
					<link
						key="alternate-SEO"
						rel="alternate"
						media="all"
						href={alternateURL.href}/>
				]
			}

			{
				Boolean(image) &&
				<meta property="og:image" content={image}/>
			}

			{
				(title !== undefined) && [
					// SEO
					<title key="title-SEO">
						{title}
					</title>,
					// Social
					<meta
						key="title-social"
						property="og:title"
						content={title}/>
				]
			}

			{
				(description !== undefined) && [
					// SEO
					<meta
						key="description-SEO"
						name="description"
						content={description}/>,
					// Social
					<meta
						key="description-social"
						property="og:description"
						content={description}/>
				]
			}


			{children}
		</Helmet>
	);
}

export const loadableToStatus = loadable => (
	loadable?.value ? 200
		: loadable?.end ? 404
			: loadable?.loading ? undefined
				: 500
);

export const optionalLoadableToStatus = loadable => (
	loadable?.loading ? undefined
		: 200
);

export const optionalIteratorToStatus = optionalLoadableToStatus;

export const iteratorToStatus = (iterator, contentRequired) => (
	iterator?.items?.length > 0 ? 200
		: iterator?.end ? (contentRequired ? 404 : 200)
			: iterator?.loading ? undefined
				: 500
);

export const mergeStatus = (statuses) => {
	const errorStatus = statuses?.find(status => ![200, undefined].includes(status));
	if (errorStatus) return errorStatus;

	if (statuses.every(equals(200))) return 200;

	return undefined;
};
