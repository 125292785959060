import Base from "../../../../class/Base"
import Rate from "./Rate"
import {User} from "../../entity"

export default class VUser extends Base {}
VUser.Stats = class VUserStats extends Base {};

VUser.addProperties({
	user : User,
	rate : Rate,
	stats: VUser.Stats,
});

VUser.Stats.addProperties({
	spender : Number,
	unresolved : Number,
	friendly : Number,
});
