import React from "react"
import {View as RNView} from "react-native"
import use from "library-react/hook"
import {styles} from "@main/res"
import Button from "./Button";

function ErrorView({message, retry, ...props}) {
	if (!message)
		message = `Une erreur s'est produite lors du chargement.`;

	const {Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<RNView {...props}>
			<Text style={localStyles.text(retry)}>
				{message}
			</Text>

			{
				retry &&
				<Button
					onPress={retry}
					style={localStyles.button}>
					{`Relancer`}
				</Button>
			}
		</RNView>
	);
}

export default React.memo(ErrorView);


const localStyles = {
	layout: styles.center,
	text: styles.static.bool(
		{},
		{marginBottom: 10},
	),
	button: {
		paddingVertical: 5,
		paddingHorizontal: 15,
	}
};
