import React from "react"
import use from "library-react/hook"
import {styles} from "../../res"
import View from "library-react/component/View/v2";
import Icon from "library-react/component/Icon/v2";

function SelectSize({selectSize, children, ...props}) {
	const {Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<View {...props}>
			<Text
				numberOfLines={1}
				style={localStyles.label}>
				{`Taille`}
			</Text>

			<Text
				numberOfLines={1}
				style={localStyles.value}>
				{children || `Sélectionnez une taille`}
			</Text>

			<Icon
				name="MaterialIcons/chevron-right"
				size={20}
				style={localStyles.arrow}
			/>
		</View>
	);
}

export default React.memo(SelectSize);

const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
	},

	label: {
		fontSize: 19,
		flex: 1,
	},

	value: {
		fontSize: 19,
		marginLeft: 5,
	},

	arrow: {
		color: styles.color.grey,
		marginLeft: 20,
	},
};
