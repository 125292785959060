import React from "react"
import { View as RNView } from "react-native"
import use from "library-react/hook"
import { styles } from "@main/res"
import Gallery from "@main/component/Gallery";
import View from "library-react/component/View/v2";
import RateView from "./RateView";
import AddToBasketButton from "./AddToBasketButton";
import OpenableText from "library-react/component/OpenableText";
import ProdshopPrice from "@main/component/ProdshopPrice";
import { VProdshopContext } from "@main/ui/ProductUI/contexts";
import Icon from "library-react/component/Icon/v2";
import { ChatController } from "@main/App/Frame/Chat";
import useApp from "library-react/app/useApp";

export default function ProductMobileUI(
	{
		titleOpened, toggleTitle, selectSize,
		onChatClicked, onRateClicked, setBasketNotification,
		title, share, ...props
	}
) {
	const vProdshop = use.context(VProdshopContext);
	const { product, prodshop, vProduct: { rate }, shop } = vProdshop;

	const app = useApp();
	const { Text } = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<RNView {...props}>
			<ChatController suggestedAttachment={vProdshop} />

			<Gallery
				items={product.gallery}
				resizeMode="cover"
				direction={Gallery.Direction.vertical}
				enableFullScreen
				alt={title}
				{...localStyles.galleryProps}
			/>


			<RNView style={localStyles.infos.layout}>

				{/* title */}
				<RNView style={localStyles.infos.head}>
					<Text numberOfLines={titleOpened ? undefined : 1}
						onPress={toggleTitle}
						accessibilityRole="heading"
						style={localStyles.infos.title}>
						{product.fullTitle}
					</Text>

					<Icon name="MaterialIcons/share"
						style={localStyles.infos.share}
						size={20}
						onPress={share}
					/>
				</RNView>

				{/* size selection */}
				<View style={localStyles.infos.size} />

				{/* price */}
				{
					!shop?.hiddenPrice &&
					<ProdshopPrice
						prodshop={prodshop}
						fontSize={19}
						style={localStyles.price}
					/>
				}

				<RateView
					rate={rate}
					onPress={onRateClicked}
					style={localStyles.infos.rate} />

				<AddToBasketButton
					selectSize={selectSize}
					style={localStyles.infos.mainButton} />

				{
					!shop.ordersEnabled &&
					<View style={localStyles.control.layout}
						onPress={app.openChat}>
						<Icon
							name="chat"
							size={24}
							style={localStyles.control.icon}
						/>

						<Text style={localStyles.control.text}>
							{`En discuter`}
						</Text>
					</View>
				}


				<OpenableText
					maxChars={150}
					displayToggle={opened =>
						<Text style={{ textDecorationLine: styles.textDecorationLine.underline }}>
							{!opened ? `Voir plus` : `\nFermer`}
						</Text>
					}
					style={localStyles.infos.description}>
					{product.description}
				</OpenableText>
			</RNView>
		</RNView>
	);
}


const localStyles = {
	layout: {},

	galleryProps: {
		innerStyles: {
			main: {
				marginHorizontal: 18,
			}
		},

		style: {
			height: 560,
		}
	},

	infos: {
		layout: {
			padding: 20,
		},

		ref: {
			fontSize: 20
		},

		head: {
			flex: 1,
			flexDirection: styles.flexDirection.row,
			justifyContent: styles.justifyContent.spaceBetween,
		},

		title: {
			fontSize: 25,
			bold: true,
			marginBottom: 10,
		},

		share: {
			alignSelf: styles.alignSelf.flexEnd,
		},

		rate: {
			marginBottom: 15,
		},

		mainButton: {
			marginBottom: 20,
		},

		description: {
			fontSize: 18,
			marginBottom: 7,
		}
	},
	control: {
		layout: {
			padding: 10,
			marginBottom: 10,
			backgroundColor: styles.color.black,
			flexDirection: styles.flexDirection.row,
			...styles.center,
		},
		text: {
			fontSize: 20,
			color: styles.color.white
		},

		icon: {
			marginRight: 28,
			color: styles.color.white
		}
	},
	price: {
		marginBottom: 17,
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.flexEnd,
	},
};
