import React from "react"
import {ScrollView} from "react-native"
import use from "library-react/hook";
import ProductGrid from "../../component/ProductGrid";
import {styles} from "../../res";
import SearchBar from "../../component/SearchBar";
import Button from "../../component/Button";

export default function MobileUI({iterator, onQueryChanged, onSelected, ...props}) {
	const {Text, Spinner} = use.theme();

	props.style = use.defaultStyle(props.style, localStyles.layout);
	props.contentContainerStyle = use.defaultStyle(props.contentContainerStyle, localStyles.content);

	return (
		<ScrollView {...props} >
			<SearchBar
				onQueryChanged={onQueryChanged}
				style={localStyles.search}/>

			{
				iterator &&
				<ProductGrid
					items={iterator.items}
					onSelected={onSelected}
					style={localStyles.catalog}/>
			}

			{
				iterator && (
					iterator.loading ? <Spinner style={localStyles.button}/> :
						!iterator.end &&
						<Button
							onPress={iterator.load}
							style={localStyles.button}>
							<Text style={localStyles.text}>
								{`Voir plus`}
							</Text>
						</Button>
				)
			}
		</ScrollView>
	);
}

const localStyles = {
	layout: {},

	content: {
		alignItems: styles.alignItems.center,
		marginHorizontal: 20,
		marginVertical: 15,
	},

	search: {
		width: "100%"
	},

	catalog: {
		marginTop: 50,
	},

	button: {
		alignSelf: styles.alignSelf.center,
		paddingHorizontal: 100,
		paddingVertical: 19,
		marginBottom: 46,
		marginTop: 10,
	},

	text: {
		fontSize: 20,
	},
};
