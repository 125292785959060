import React from "react"
import Component from "../Component";
import ComponentUtils from "../ComponentUtils";
import {Objects} from "../library-js/utils";
import View from "./View";
import {styles} from "../res";
import Icon from "./Icon";
import Image from "./Image";

export default class GalleryCarousel extends Component {
	onResetState(state, props) {
		super.onResetState(state, props);
		state.index = props.indexOnMounted || 0;
	}

	onLeftClicked() {
		if (this.state.index > 0)
			this.setState(state => state.index--);
	}


	onRightClicked() {
		if (this.props.items && this.state.index + 1 < this.props.items.length)
			this.setState(state => state.index++);
	}

	onRender({items, arrowsStyle, leftArrowStyle, rightArrowStyle, resizeMode, ...props}, {index}) {
		arrowsStyle = ComponentUtils.mergeStyles(arrowsStyle);
		leftArrowStyle = ComponentUtils.mergeStyles(leftArrowStyle);
		rightArrowStyle = ComponentUtils.mergeStyles(rightArrowStyle);

		leftArrowStyle = {...arrowsStyle, ...leftArrowStyle};
		rightArrowStyle = {...arrowsStyle, ...rightArrowStyle};

		let leftProps = Objects.retrieve(leftArrowStyle, ["color", "size", "accent"], true);
		let rightProps = Objects.retrieve(rightArrowStyle, ["color", "size", "accent"], true);

		return (
			<View {...props}>
				{items.map((image, itemIndex) =>
					<Image
						key={itemIndex}
						source={image}
						style={index === itemIndex ? styles.fit : {display: "none"}}
						resizeMode={resizeMode || 'contain'}
					/>
				)}

				{// left
					(index > 0) &&
					<Arrow left
						   onPress={this.onLeftClicked}
						   {...leftProps}
						   style={leftArrowStyle}
					/>
				}


				{// right
					(index + 1 < items.length) &&
					<Arrow right
						   onPress={this.onRightClicked}
						   {...rightProps}
						   style={rightArrowStyle}
					/>
				}
			</View>
		);
	}
}

const Arrow = ({left, right, color, accent, size, ...props}) => {
	props.style = (left ? localStyles.arrow.left : localStyles.arrow.right).concat([props.style]);

	return (
		<View {...props}>
			{({hover}) =>
				<Icon
					name={left ? "MaterialIcons/chevron-left" : "MaterialIcons/chevron-right"}
					style={{color: hover ? accent : color, width: size, height: size}}/>
			}
		</View>
	);
};

const localStyles = {
	arrow: {
		common: [
			styles.absolute({top: 0, bottom: 0}),
			styles.center,
		],

		left: {left: 0},
		right: {right: 0},
	}
};

localStyles.arrow.left = [localStyles.arrow.common, localStyles.arrow.left];
localStyles.arrow.right = [localStyles.arrow.common, localStyles.arrow.right];
