import React from "react"
import responsive from "@main/hoc/responsive";
import desktop from "./desktop"
import mobile from "./mobile"
import use from "library-react/hook";
import useAuth from "library-react/hook/useAuth";

export default React.memo(
	responsive(
		{default: desktop, mobile},
		({createIterator, loadMyComment, persistMyComment, loadRate, ...props}) => {
			const [version, reloadMyComment] = use.version();
			const user = useAuth.user();
			props.myCommentLoadable = use.loadable.server(user && loadMyComment, [version, user]);
			props.rateLoadable = use.loadable.server(loadRate, [version]);
			props.iterator = use.infiniteIterator(createIterator, [version]);

			const [editMode, setEditMode] = use.state(false);
			props.editMode = editMode;
			props.setEditMode = setEditMode;

			props.persist = use.asyncCallback(shouldStop =>
				persistMyComment && (
					comment => {
						if (persistMyComment)
							return persistMyComment(comment)
								.then(response => {
									if (response.ok)
										return response.content;
									throw response.toJSON();
								})
								.stopOn(shouldStop)
								.then(() => {
									reloadMyComment();
									setEditMode(false);
								});
					}),
				[persistMyComment],
			);

			return props;
		}
	),
);
