import {styles} from "@main/res"

// ----- columns -------
const columnsStyles = [
	 // Date && ID
	{
		maxWidth: 300,
		marginLeft: 135,
		justifyContent: styles.justifyContent.center,
	},
	// State
	{
		maxWidth: 320,
		justifyContent: styles.justifyContent.center,
	},

	// Quantity
	{
		maxWidth: 225,
		alignItems: styles.alignItems.center,
		justifyContent: styles.justifyContent.center,
	},
	// Price
	{
		maxWidth: 100,
		justifyContent: styles.justifyContent.center,
	},

	// caret
	{
		maxWidth: 120,
		alignItems: styles.alignItems.center,
		justifyContent: styles.justifyContent.center,
		paddingRight: 10,
	},
];



export default {
	row: {
		paddingVertical: 15,
	},

	columns: columnsStyles,
};
