import React, {useContext} from "react"
import {getFontFamily} from "./Text"
import {Text as RNText} from "react-native"
import ComponentUtils from "../../ComponentUtils"
import {Objects} from "../../library-js/utils"
import styles from "../../res/styles"
import useMemo from "../../hook/useMemo";
import Environment from "../../library-js/Environment";
import LinkContext from "../../context/LinkContext";
import parseLinks from "./parseLinks";
import { is } from "ramda"

/**
 * @param {{
 * 	noLinks?: any
 * } & import("react-native").TextProps
 * }
 */
function Text({noLinks, style, a, ...props}) {
	const ascendantStyle = useContext(TextStyleContext);

	const inheritedStyle = useMemo(ComponentUtils.style.merge, [ascendantStyle, style]);

	props.style = useMemo(() => {
		const copyStyle = {...inheritedStyle};

		let {font, bold, italic} = Objects.retrieve(copyStyle, ["font", "bold", "italic"], true);

		if (copyStyle.fontFamily) {
			copyStyle.fontWeight = styles.fontWeight.normal;
			copyStyle.fontStyle = styles.fontStyle.normal;
		} else {
			if (font) {
				if (bold === undefined)
					bold = copyStyle.fontWeight === styles.fontWeight.bold;

				if (italic === undefined)
					italic = Boolean(copyStyle.fontStyle === styles.fontStyle.italic);

				copyStyle.fontFamily = getFontFamily(font, bold, italic);
			}

			// sync bold
			if (bold !== undefined) // if bold defined, priority to it over fontWeight
				copyStyle.fontWeight = bold ? styles.fontWeight.bold : styles.fontWeight.normal;

			// sync italic
			if (italic !== undefined) // if italic defined, priority to it over fontStyle
				copyStyle.fontStyle = italic ? styles.fontStyle.italic : styles.fontStyle.normal;
		}

		return copyStyle;
	}, [inheritedStyle]);

	const descendantStyle = useMemo(extractContextStyles, [props.style]);

	// auto detect links, TODO open issue to RNW to demand prop "dataDetectorType"
	const inLink = useContext(LinkContext);

	if (props.href && props.accessibilityRole === undefined)
		props.accessibilityRole = 'link';
	const isLink = props.accessibilityRole === 'link' ? props.href : undefined;

	// ---
	if (!props.onPress)
		props.onPress = undefined;
	// ---

	if (Environment.is.web && !inLink && !isLink && !noLinks)
		props.children = ComponentUtils.childrenToArray(props.children)
			.flatMap(parseLinks);


	let result = (
		<TextStyleContext.Provider value={descendantStyle}>
			<RNText {...props}/>
		</TextStyleContext.Provider>
	);

	if (Environment.is.web)
		result = (
			<LinkContext.Provider value={inLink || isLink}>
				{result}
			</LinkContext.Provider>
		);

	return result;
}

const extractContextStyles = style => {
	return Objects.retrieve(style, [
		'fontSize',
		'color',
		'font',
		'lineHeight',
		'fontFamily',
	], false);
};

export default React.memo(Text);

export const TextStyleContext = React.createContext();

const OriginalProvider = TextStyleContext.Provider;
TextStyleContext.Provider = function MergeProvider({value, ...props}) {
	const inheritedStyle = useContext(TextStyleContext);
	props.value = useMemo(ComponentUtils.style.merge, [inheritedStyle, value]);
	return <OriginalProvider {...props}/>;
};
