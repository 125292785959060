import Component from "../Component"
import React from "react" // enable JSX
import Icon from "./Icon/v2"
import styles from "../res/styles"
import PropTypes from "prop-types"
import View from "./View"
import ComponentUtils from "../ComponentUtils";
import { is, Objects } from "../library-js/utils";

export default class StarsView extends Component {
	static propTypes = {
		size: PropTypes.number,

		/**
		 * Number of stars filled.
		 * @type Number
		 */
		stars: PropTypes.number,

		/**
		 * Number total of stars.
		 * @type Number
		 */
		total: PropTypes.number,

		/**
		 * Indicates if stars are editable.
		 * @type Boolean
		 */
		editable: PropTypes.bool,

		/**
		 * Callback when the number of filled stars changed.
		 * Type: Number => void
		 * @type Function
		 */
		onStarsChanged: PropTypes.func,
		/**
		 * Icon name
		 * @type string
		 */
		icons: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.object
		]),
		/**
		 * Eyal style
		 * @type Boolean
		 */
		useEyalStyle: PropTypes.bool,
	};

	// reset the state
	static criticalProps = ["stars"];

	onResetState(state, props) {
		super.onResetState(state, props);
		state.stars = props.stars || 0;
	}

	onRender({ useEyalStyle = true, ...originalProps }, { stars }) {
		originalProps = ComponentUtils.props.merge(
			[this.constructor.default, useEyalStyle && EyalStyle[Boolean(originalProps.editable)], originalProps],
			{ style: ['iconStyle', 'onStyle', 'offStyle'] },
		);

		let { total, editable, size, onStarsChanged, icons, iconStyle, offStyle, onStyle, gap, ...props } = originalProps;

		if (is.primitive(icons)) // unique icon for both modes
			icons = { on: icons, off: icons };

		// retrieve color
		props.style = ComponentUtils.style.merge(props.style);
		const { color } = Objects.retrieve(props.style, ["color"], true);

		const starValues = Array.range(total)
			.map(i => i < Math.trunc(stars));

		return (
			<View {...props}>
				{
					starValues.map((isOn, index, { lastIndex }) =>
						<Icon
							key={index}
							name={isOn ? icons.on : icons.off}
							size={size}
							style={
								[
									{ color },
									gap ? {// style
										[// key
											!index ? "paddingRight" :
												index === lastIndex ? "paddingLeft" :
													"paddingHorizontal"
										]: /*value*/ gap / 2
									} :
										{
											width: Number(size) || 14,
											maxWidth: Number(size) || 14,
											flexGrow: 1,
											alignItems: (index === 0) ? styles.alignItems.flexStart
												: index === starValues.lastIndex ? styles.alignItems.flexEnd
													: styles.alignItems.center,
										},
									iconStyle,
									isOn ? onStyle : offStyle,
								].flat()
							}
							onPress={editable && (() => {
								// set stars
								const stars = index + 1;
								this.setState({ stars });

								// callback
								if (onStarsChanged)
									onStarsChanged(stars);
							})}
						/>
					)
				}
			</View>
		);
	}
}

const EyalStyle = {
	false: { // not editable
		icons: "star",
		onStyle: { color: '#fec72e' },
		offStyle: { color: '#dad9e1' },
		style: {},
		gap: 3,
	},

	true: { // editable
		icons: {
			on: "AntDesign/star",
			off: "AntDesign/staro",
		},
		onStyle: { color: '#fec72e' },
		offStyle: { color: '#fec72e' },
		style: {},
		gap: 3,
	}
};

StarsView.default = {
	total: 5,

	icons: {
		on: "AntDesign/star",
		off: "AntDesign/staro",
	},

	style: {
		flexDirection: styles.flexDirection.row,
	},
};
