import Base from "../class/Base"
import Enum from "../class/Enum"
import URL from "../utils/URL"

/**
 * Build an HTTP request (using the standard <a href="https://developer.mozilla.org/fr/docs/Web/API/Fetch_API">fetch API</a>) according to its properties, and executes it in a {@link Promise} on {@link HTTPRequest.send} method called.
 * Properties:
 * <ul>
 *     <li>String url : Url to send the request to.</li>
 *     <li>{@link HTTPRequest.Methods} method : HTTP method.</li>
 *     <li>Object<String> headers : Request's headers.</li>
 *     <li>String contentType : Shortcut property to set/get request's header content-type.</li>
 *     <li>Object<String> queryParameters : Request's parameters in the query.</li>
 *     <li>* body : Request's body.</li>
 *     <li>Function serializer : Function to serialize the body object. Default is {@link JSON.stringify}. To upload blob, remove it.</li>
 * </ul>
 *
 * Methods:
 * <ul>
 *     <li>Promise <a href="https://developer.mozilla.org/fr/docs/Web/API/Response">fetch.Response</a> {@link send} : Send the HTTP request.</li>
 * </ul>
 *
 */
export default class HTTPRequest extends Base {
	/**
	 *
	 * @param {String} url Url to send the request to.
	 * @param {HTTPRequest.Methods} [method=HTTPRequest.Methods.GET] Http method.
	 */
	constructor(url, method) {
		super();
		this.url = url;
		this.method = method || HTTPRequest.Methods.GET;
		this.serializer = JSON.stringify;
	}

	/**
	 * Add an header to the HTTP request.
	 * @param key {String} Key of the header.
	 * @param value {String} Value of the header.
	 * @return This instance of {@link HTTPRequest} to chain methods.
	 **/
	addHeader(key, value) {
		if (!this.headers)
			this.headers = {};

		this.headers[key] = value;
		return this;
	}

	/**
	 * Set the header content-type.
	 * @param value Content type to set
	 * @returns {HTTPRequest} This instance, to chain setters.
	 */
	setContentType(value) {
		this.addHeader('content-type', value);
		return this;
	}


	/**
	 * Header content type.
	 */
	get contentType() {
		return this.headers && this.headers['content-type'];
	}

	set contentType(value) {
		this.setContentType(value);
	}

	/**
	 * Add a GET parameter inside the url of the HTTP request.
	 * @param key {String} Key of the parameter.
	 * @param value {String} Value of the parameter.
	 * @return This instance of {@link HTTPRequest} to chain methods.
	 **/
	addQueryParameter(key, value) {
		if (!this.queryParameters)
			this.queryParameters = {};

		this.queryParameters[key] = value;
		return this;
	}


	/**
	 * Send the request.
	 * @return {Promise} A promise which will return an <a href="https://developer.mozilla.org/fr/docs/Web/API/Response">fetch API response</a>.
	 * The catch method is triggered when it failed to send the request (no network).
	 */
	send() {
		if (!this.url)
			throw "Cannot send the request, there is no url.";

		if (!this.method)
			throw "Cannot send the request, there is no method.";

		// serialize body
		let body = this.serializer ? this.serializer(this.body) : this.body;

		// add GET parameters to url
		let url = this.url;
		if (this.queryParameters)
			url += `?${URL.Params.encode(this.queryParameters)}`;

		return fetch(url, {
			method: this.method.value,
			headers: this.headers,
			body,
		});


		// // create request
		// let request = new XMLHttpRequest();
		// request.open(this.method.value, url);
		//
		// // put headers
		// if (this.headers)
		// 	Objects.forEach(this.headers, function (key, value) {
		// 		request.setRequestHeader(key, value);
		// 	});

		// return new Promise(
		// 	function (resolve, reject) {
		// 		request.onloadend = function(){
		// 			resolve(new HTTPResponse(request.status)
		// 				.setType(HTTPResponse.Types.from(request.responseType))
		// 				.setData(request.response)
		// 			);
		// 		};
		//
		// 		request.onerror = function (error) {
		// 			reject(error);
		// 		};
		//
		// 		request.send(body);
		// 	}
		// );
	}

	clone() {
		const body = this.body; // prevent body to be cloned
		this.body = null;

		const clone = super.clone();
		this.body = clone.body = body;

		clone.headers = this.headers && { ...this.headers };
		clone.queryParameters = this.queryParameters && { ...this.queryParameters };

		return clone;
	}
}

/**
 * Enum of the http methods. Possible values are:
 * <ul>
 *     <li>{@link HTTPRequest.Methods.POST}</li>
 *     <li>{@link HTTPRequest.Methods.GET}</li>
 *     <li>{@link HTTPRequest.Methods.PUT}</li>
 *     <li>{@link HTTPRequest.Methods.DELETE}</li>
 * </ul>
 * @type {Enum}
 */
const Methods = HTTPRequest.Methods = Enum.make([
	"GET",
	"POST",
	"PUT",
	"DELETE",
]);

Methods.domains.byQueryParameters = [Methods.GET, Methods.DELETE];

HTTPRequest.addProperties({
	url: String,
	method: HTTPRequest.Methods,
	headers: {
		type: Object,
		template: String
	},

	/**
	 * Function to serialize the body of the request. {@link JSON.stringify} by default.
	 */
	serializer: Function,
	queryParameters: Object,
	body: null,
});
