import Debug from "../Debug"
import is from "./is"
import pipe from "./function/pipe";

/**
 * Set of functions for Functions:
 * <ul>
 *     <li>Function {@link prepend}(Function, Function) : Prepend a function to another.</li>
 *     <li>Function {@link append}(Function, Function) : Append a function to another.</li>
 *     <li>Function {@link generateSorter}(String, Boolean) : Generate a sorter function.</li>
 *     <li>Function {@link buildGenerator}(Function*) : Build a generator function.</li>
 * </ul>
 */
let Functions;
export default Functions = {

	/**
	 * Prepend a function to another.
	 * @param {Function?} func Function which will be prepended by the 2nd one.
	 * @param {Function?} prepend Function to prepend. This method receive all parameters as an array, so it is able to modify it.
	 * @returns {Function} The result function.
	 *
	 * Exxemple
	 * <code>
	 *     const f1 = (params) => console.log("f1", ...params);
	 *     const f1 = (param1, param2) => console.log("f2", param1, param2);
	 *     const f3 = Functions.prepend(f2, f1);
	 *     f3(1, 2) // log: "f1 1 2" & "f2 1 2"
	 * </code>
	 */
	prepend(func, prepend){
		Debug.assert(func && !is(func, Function), "Functions.append's 1st parameter must be a function.");
		Debug.assert(prepend && !is(prepend, Function), "Functions.append's 2nd parameter must be a function.");

		if (!func) // nothing to prepend to
			return function (...args){prepend.call(this, args)};

		if (!prepend) // nothing to prepend
			return func;

		return function (...args) {
			prepend.call(this, args);
			return func.apply(this, args);
		}
	},

	/**
	 * Like {@link prepend}, but without getting the next function's parameters in an array.
	 * @param func Next function.
	 * @param before Function to run before, with the same parameters.
	 * @returns Function A wrapping function.
	 */
	runBefore(func, before){
		return this.prepend(func, params => before(...params));
	},

	/**
	 * Append a function to another.
	 * The function to append will receive as 1st parameter the value returned by the appended function, and its parameters following.
	 * @param {Function?} func Function which will be appended by the 2nd one.
	 * @param {Function?} append Function to append.
	 * @returns {Function} The result function.
	 *
	 * Exxemple
	 * <code>
	 *     const f1 = (param) => (param + 1);
	 *     const f2 = (returned, param) => (returned + param);
	 *     const f3 = Functions.prepend(f1, f2);
	 *     f3(1) // returns 3
	 * </code>
	 */
	append(func, append) {
		Debug.assert(func && !is(func, Function), "Functions.append's 1st parameter must be a function.");
		Debug.assert(append && !is(append, Function), "Functions.append's 2nd parameter must be a function.");

		if (!append) // nothing to append
			return func;

		return function (...args) {
			let value = func ? func.apply(this, args) : undefined;
			args.unshift(value);
			return append.apply(this, args);
		};
	},

	/**
	 * Like {@link append}, but without getting the previous function's result as first parameter.
	 * @param func Previous function.
	 * @param after Function to run after, with the same parameters.
	 * @returns Function A wrapping function.
	 */
	runAfter(func, after){
		return this.append(func, (result, ...params) => after(...params));
	},

	override(func, override){
		Debug.assert(!is(func, Function, true), "Functions.override's 1st parameter must be a function.");
		Debug.assert(!is(override, Function, true), "Functions.override's 2nd parameter must be a function.");

		if (!override) // nothing to override with
			return func;

		return function(...args) {
			let superFunction = func ? func.bind(this) : (() => {});
			return override.call(this, superFunction, ...args)
		}
	},

	/**
	 * Generate a sorter function. The function will sort items by making item1 - item2 (or item1[property] - item2[property]).
	 * @param {String} property Property's name to compare. If undefined, items themself will be compared.
	 * @param {Boolean=false} desc Indicates if to sort DESC.
	 * @return {function(*, *)} The sorter function.
	 */
	generateSorter(property, desc){
		// desc comparator
		if (desc){
			// according to item property
			if (is(property))
				return (item1, item2) => (item2[property] - item1[property]);

			// according to value
			return (item1, item2) => (item2 - item1)
		}

		// asc comparator
		// according to item property
		if (is(property))
			return (item1, item2) => (item1[property] - item2[property]);

		// according to value
		return (item1, item2) => (item1 - item2);
	},

	/**
	 * This will build a generator and place it in a function, so that the function will directly return the value of the generator.
	 * The generator factory should take 1 argument which will be the array of arguments passed to the returned function each times it will be called.
	 * @param {Function} generatorFactory The generator definition.
	 * @return {Function} The function which wraps the generator.
	 */
	buildGenerator(generatorFactory){
		// initiate the arguments array
		let paramsArray = [];
		// build the generator
		let generator = generatorFactory(paramsArray);

		// return the generator function
		return function(...params){
			// replace ald params with new ones
			paramsArray.splice(0, paramsArray.length, ...params);
			// call the function and return the value
			return generator.next().value;
		};
	},

	pipe,
}
