import React from "react"
import { View as RNView } from "react-native"
import use from "../../hook"
import Icon from "../Icon/v2";
import Text from "../Text";
import { styles, fonts } from "../../res";

function RatingBadge({ children, iconSize, textStyle, ...props }) {
	textStyle = use.defaultStyle(textStyle, localStyles.text);
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<RNView {...props}>
			<Icon
				name="FontAwesome/star"
				size={iconSize || 9}
				style={localStyles.icon} />

			<Text
				numberOfLines={1}
				style={textStyle}>
				{children}
			</Text>
		</RNView>
	);
}

export default React.memo(RatingBadge);


const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
		borderRadius: 10,
		paddingHorizontal: 5,
		backgroundColor: 'rgba(254, 199, 46, .18)',
	},

	icon: {
		marginRight: 3.2,
		color: 'rgb(254, 199, 46)',
	},

	text: {
		fontSize: 10,
		font: fonts.Poppins,
		color: 'rgb(254, 199, 46)',
		bold: true,
	},
};
