import Debug from "../Debug"
import is from "./is"
import map from "./array/map";

/**
 * Sets of functions for Arrays:
 * <ul>
 *     <li>Array {@link filterFromArray}(Array, Array) : Filter values of an array which are included in the 2nd array.</li>
 *     <li>Array {@link fusionUnique}(Array, Array) : Concat from 2nd array only items that aren't already in the 1st one.</li>
 *     <li>Array {@link deleteCopies}(Array) : Remove copies.</li>
 * </ul>
 */
export default {
	/**
	 * Get all items from 1st array which are included in the 2nd one.
	 * @param {Array} array Array to filter.
	 * @param {Array} acceptedValues Array of item to accept.
	 */
	filterFromArray(array, acceptedValues) {
		return array.filter(item => acceptedValues.includes(item));
	},

	/**
	 * Filter an array (with a predicate) into 2 arrays: accepted and refused.
	 * @param {Array} array - Array to filter.
	 * @param {Function} predicate - Predicate to apply on each item of the array to filter it.
	 * @returns {Object} - An object containing 3 arrays:
	 * <ul>
	 *     <li><b>accepted</b> : Array of accepted items.</li>
	 *     <li><b>refused</b> : Array of refused items.</li>
	 *     <li><b>results</b> : Array of booleans, which each indicates if the item at the same index was accepted (true) or refused (false).</li>
	 * </ul>
	 * @deprecated [shop-web]
	 * @see Array.prototype.groupBy
	 **/
	filter(array, predicate) {
		Debug.assert(!is(array, Array), "Arrays.filter 1st parameter must be an array.");
		Debug.assert(!is(predicate, Function), "Arrays.filter 2nd parameter must be a Function.");

		let results = [];
		let accepted = [];
		let refused = [];

		array.forEach((item, index) => {
			// apply predicate on item, and store result
			results[index] = Boolean(predicate(item, index)); // cast to boolean
			if (results[index]) // if accepted
				accepted.push(item);
			else // if refused
				refused.push(item);
		});
		// filter args
		return {
			results : results,
			accepted : accepted,
			refused : refused,
		} ;
	},

	/**
	 * Concat the 2nd to the 1st one array only items that aren't already in the 1st array.
	 * @param {Array} array Array to concat to.
	 * @param {Array} array2 Array of items to concat.
	 * @returns {Array} Concatenated array.
	 *
	 * Exemple
	 * <code>
	 *     let array1 = [1, 2, 3];
	 *     let array2 = [3, 4, 5, 2];
	 *     Arrays.fusionUnique(array1, array2) // [1, 2, 3, 4, 5]
	 * </code>
	 */
	fusionUnique(array, array2) {
		return array.concat(Arrays.filterFromArray(array2, array));
	},

	/**
	 * Return an array with all copies removed.
	 * @param {Array} array Array to delete copies.
	 * @returns {Array} Array Clean array.
	 */
	deleteCopies(array) {
		Debug.assert(!is(array, Array), "Arrays.deleteCopies' parameter must be an array.");
		let filtered = [];
		array.forEach(item => {
			if (!filtered.includes(item))
				filtered.push(item);
		});
		return filtered;
	},

	/**
	 * Maps all values of an array, into an object.
	 * The key for each value is defined by the 2nd parameter which is a function which generate the key for a given value (passed as parameter).
	 * @param {Array} array Array to map.
	 * @param {Function?} keyGenerator Function which receive an item and its index from the array, and return the corresponding key in which the value will be stored inside the object. Default function returns the index as key.
	 * @param {Function?} valueConverter Function which receive an item, along with its index and its generated key, from the array, and return the corresponding value to set. Default function will return the item as value.
	 * @return {Object} The built object.
	 * @deprecated
	 * @see Array.prototype.toObject
	 */
	toObject(array, keyGenerator, valueConverter) {
		// check inputs
		Debug.assert(!is(array, Array), "Arrays.toObject 1st parameter must be an array.");
		Debug.assert(!is(keyGenerator, Function, true), "Arrays.toObject 2nd parameter must be an function.");
		Debug.assert(!is(valueConverter, Function, true), "Arrays.toObject 3rd parameter must be an function.");
		// default key generator
		keyGenerator = keyGenerator || ((item, index) => index);
		valueConverter = valueConverter || (item => item);

		let object = {};
		array.forEach((value, index) => {
			let key = keyGenerator(value, index);
			// check returned key's type
			Debug.assert(!is.primitive(key), "Arrays.toObject's 2nd parameter function must return only a String or a number.",
				// in production, cast key  to string
				() => (key += "")
			);

			// store value
			object[key] = valueConverter(value, index, key);
		});
		return object;
	},

	/**
	 * Maps all values of an array, into an object <bold>as key</bold>.
	 * The value for each key is defined by the 2nd parameter which is a function which generate the value for a given key passed as parameter.
	 * @param {Array} array Array to map as keys.
	 * @param {Function?} valueGenerator Function which receive a key & index from the previous array, and return a corresponding value which will be placed at the key. Default function returns the index as value.
	 * @return {Object} The built object.
	 * @deprecated
	 * @see Array.prototype.toObject
	 */
	toObjectAsKey(array, valueGenerator){
		// check inputs
		Debug.assert(!is(array, Array), "Arrays.toObjectAsKey 1st parameter must be an array.");
		Debug.assert(!is(valueGenerator, Function, true), "Arrays.toObjectAsKey 2nd parameter must be an function.");
		// default key generator
		valueGenerator = valueGenerator || ((_, index) => index);

		let object = {};
		array.forEach((key, index) => {
			let value = valueGenerator(key, index);
			// check returned key's type
			Debug.assert(!is.primitive(key), "Arrays.toObject's 2nd parameter function must return only a String or a number.",
				// in production, cast key  to string
				function () {key += ""});

			// store value
			object[key] = value;
		});

		return object;
	},

	mapper: map,
};
