import React from "react"
import ToastCard from "./ToastCard"
import {prop} from "ramda";

export default function ToastChannel({stackState: [stack, setStack], frameLayout}) {
	const layouts = stack.map(prop('layout'));
	return stack.map((entry, index) => {
		const position = entry.config.position({index, layouts, frame: frameLayout});
		if (layouts[index])
			layouts[index].position = position;

		return (
			<ToastCard
				key={entry.id}
				{...entry}
				{...{
					position,
					stack, setStack,
					index,
				}}
			/>
		);
	});
}
