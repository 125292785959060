import Debug from "../Debug"
import is from "./is"

/**
 * A set of functions for objects:
 * <ul>
 *     <li>String[] {@link keysOf}(Object) : Like {@link Object.keys}(object).</li>
 *     <li>Array {@link valuesOf}(Object) : Return all values of the passed object.</li>
 *     <li>Object[] {@link setsOf}(Object) : Return all sets of {key, value} of the passed object.</li>
 *     <li>{@link forEach}(Object, (key, value) => void) : Apply a function on each key-value of an object.</li>
 *     <li>Object {@link map}(Object, (key, value) => value) : Map all values of an object into another object.</li>
 *     <li>Object {@link mapKeys}(Object, (key, value) => key) : Map all keys of an object into another object.</li>
 *     <li>Object {@link filter}(Object, (key, value) => boolean) : Filter all values of an object into another object.</li>
 *     <li>* {@link find}(Object, (key, value) => boolean) : Return the first value on which the predicate is true.</li>
 *     <li>String {@link findKey}(Object, (key, value) => boolean) : Return the first key on which the predicate is true.</li>
 *     <li>String {@link keyOf}(Object, value) : Return the first key which contains the given value.</li>
 *     <li>Number {@link indexOf}(Object, value) : Return the first index of the {@link valuesOf} array.</li>
 *     <li>Number {@link indexOfKey}(Object, value) : Return the first key of the {@link valuesOf} array.</li>
 *     <li>Object {@link clean}(Object, nullToo) : Delete all undefined (and optionally null) values of the object.</li>
 *     <li>Array unclonables : Array of all unclonable types, like Enum instances.</li>
 * </ul>
 */
let Objects;
export default Objects = {
	/**
	 * @param object {object} The object to retrieve its properties' and getters keys.
	 * @return array An array of set all the keys of the object's properties.
	 **/
	keysOf(object) {
		if (is.null(object))
			return;

		return Object.keys(Objects.getDescriptions(object));
	},

	/**
	 * @param object {object} The object to retrieve its properties' values.
	 * @return array An array of set all the values of the  object's properties.
	 **/
	valuesOf(object) {
		let array = [];
		Objects.forEach(object, (key, value) => array.push(value));
		return array;
	},

	/**
	 * @param object {object} The object to convert into an array of set.
	 * @return array An array of set {key, value}.
	 **/
	setsOf(object) {
		return Object.keys(object)
			.map(key => ({
				key,
				value: object[key]
			}));
	},

	entries(object){
		return Object.keys(object)
			.map(key => [object[key], key]);
	},


	// ---- iterate -----

	/**
	 * Apply a function on each pair keys/values of an object.
	 * The parameter function receives the key and the value as parameters when executed.
	 * @param object {object} Object to pass over its properties.
	 * @param doOnEach {Function} Function to apply on each pair keys/values of the object.
	 **/
	forEach(object, doOnEach) {
		Object.keys(object)
			.forEach(key => doOnEach(key, object[key]))
	},

	/**
	 * Apply a reduce function on each set of the object.
	 * @param {Object} object Object to apply the reducer on.
	 * @param {Function} reducer (accumulator, key, value) => result : THe accumulator param is the last returned result by the reducer itself or the initialValue.
	 * @param {*} initialValue The initial value of the accumulator.
	 * @returns {*} The last result returned by the reducer.
	 * Exemple:
	 * <code>
	 *        Objects.reduce(stocks, (sum, key, value) => sum + value) // makes the sum of all stocks (mapped by sizes let's say) of a product.
	 * </code>
	 */
	reduce(object, reducer, initialValue = 0) {
		return Objects.setsOf(object)
			.reduce((accumulator, set) => reducer(accumulator, set.key, set.value), initialValue);
	},

	/**
	 * Check if at least one of the key/value respects the predicate checker.
	 * @param {Object} object Object to check.
	 * @param {Function} checker Predicate function.
	 * @returns {boolean} True if at least one key/value respected the predicate checker. False otherwise.
	 */
	some(object, checker) {
		let key = Objects.findKey(object, checker);
		return (key in object);
	},

	/**
	 * Check if all of the key/value respect the predicate checker.
	 * @param {Object} object Object to check.
	 * @param {Function} checker Predicate function.
	 * @returns {boolean} True if all key/value respected the predicate checker. False otherwise.
	 */
	every(object, checker) {
		return !Objects.some(object, (...params) => !checker(...params));
	},

	/**
	 * Map values of an object into another object (anonyme) with the same keys.
	 * The parameter function is executed on each property's value of the object, and should returns its mapped value.
	 * @param object Object to pass over its properties.
	 * @param mapper Function to map properties. Receives value and key as parameters.
	 **/
	map(object, mapper, keyMapper) {
		mapper = mapper || ((key, value) => value); // default function, return original value
		keyMapper = keyMapper || ((key, value) => key); // default function, return original key

		let result = {};
		Objects.forEach(object, (key, value) => {
			result[keyMapper(key)] = mapper(key, value)
		});
		return result;
	},

	/**
	 * Use Objects.map with 2nd parameter instead.
	 * @deprecated
	 */
	mapKeys(object, keyConverter) {
		keyConverter = keyConverter || (key => key); // default value of function

		let result = {};
		Objects.forEach(object, (key, value) => {
			result[keyConverter(key, value)] = value
		});
		return result;
	},

	filter(object, predicate) {
		let result = {};
		Objects.forEach(object, (key, value) => {
			if (predicate(key, value))
				result[key] = value;
		});
		return result;
	},

	retrieve(object, keys, deleteKeys) {
		if (!is(keys, Array)) // allow 1 value
			keys = [keys];

		let result = {};

		keys.forEach(key => {
			if (key in object) {
				result[key] = object[key];
				if (deleteKeys)
					delete object[key];
			}
		});

		return result;
	},

	groupBy(object, groupTo, requiredGroups) {
		let groups = {};
		Objects.forEach(object, (key, value) => {
			// get the group the entry belongs to
			let groupKey = groupTo(key, value, requiredGroups || []);

			// check if group created
			if (!groups[groupKey])
				groups[groupKey] = {};

			// set entry in groups
			groups[groupKey][key] = value;
		});

		// fill required groups
		if (requiredGroups)
			requiredGroups.forEach(group => {
				if (!groups[group])
					groups[group] = {};
			});

		return groups;
	},

	// ---- search -----

	/**
	 * Find the first property which the predicate returns true on it.
	 * @param object {object} Object to search into.
	 * @param predicate {Function} A predicate that takes as parameter the key and the corresponding property of the object.
	 * @return {String} The value of the found property.
	 */
	find(object, predicate) {
		return object[this.findKey(object, predicate)];
	},

	/**
	 * Find the first property which the predicate returns true on it.
	 * @param object {object} Object to search into.
	 * @param predicate {Function} A predicate that takes as parameter the key and the corresponding property of the object.
	 * @return {String} The key of the found property.
	 */
	findKey(object, predicate) {
		Debug.assert(!is(predicate, Function), "Objects.findKey 2nd parameter must be a predicate.");

		for (let key of Object.keys(object))
			if (predicate(key, object[key]))
				return key;
	},

	/**
	 * Find the first property which the predicate returns true on it.
	 * @param object {object} Object to search into.
	 * @param value {*} A the value of the key we must search for.
	 * @return {String} The key of the found property.
	 */
	keyOf(object, value) {
		return Objects.findKey(object, (key, objectValue) => objectValue === value);
	},

	/**
	 * Iterates over the object properties, and find the index of the property which is equal the 2nd parameter.
	 * @param {Object} object Object to iterate over.
	 * @param {*} property Property to find.
	 * @return {number} The index of the property in the object. -1 if the property wasn't found.
	 */
	indexOf(object, property) {
		return Objects.valuesOf(object)
			.findIndex(value => value === property);
	},

	/**
	 * Iterates over the object properties, and find the index of the property which is equal the 2nd parameter.
	 * @param {Object} object Object to iterate over.
	 * @param {*} key Key to find.
	 * @return {number} The index of the key in the object. -1 if the property wasn't found.
	 */
	indexOfKey(object, key) {
		return Objects.keysOf(object).indexOf(key);
	},

	/**
	 * Use {@link Object.assign} instead.
	 * @deprecated
	 */
	override(object, properties) {
		return Object.assign(object, properties);
	},

	/**
	 * Delete all undefined properties.
	 * @param {Object} object - Object to clean.
	 * @param {Boolean} [nullToo=true] - Clean null values too.
	 * @returns {Object} The clean object parameter.
	 */
	clean(object, nullToo) {
		if (nullToo === undefined)
			nullToo = true; // default value

		Objects.forEach(object, (key, value) => {
			if (value === undefined || (nullToo && value === null))
				delete object[key];
		});
		return object;
	},

	/**
	 * Deep merge source to target.
	 * @param source
	 * @param target
	 */
	deepMerge(target, ...sources) {
		sources.forEach(source => {
				//If target and source are objects
				if (is(target, Object) && is(source, Object))
					this.forEach(source, (key, value) => target[key] = this.deepMerge(target[key], value));
				else
					target = source;

			}
		);
		return target;
	},

	getDescriptions(target){
		if (is.null(target))
			return;

		const result = {};
		runOnEach(target, (key, description) => result[key] = description);

		return result;
	}
};

const MAX_PROTOTYPE = Object.getPrototypeOf({});

function runOnEach(target, run){
	const runned = {};
	let node = target;

	while (MAX_PROTOTYPE !== node && node) {
		for (const key of Object.getOwnPropertyNames(node)){
			if (key !== "constructor" && !runned[key]){
				runned[key] = true;
				run(key, Object.getOwnPropertyDescriptor(node, key));
			}
		}

		node = Object.getPrototypeOf(node);
	}
}
