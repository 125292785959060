import React from "react"
import {View as RNView} from "react-native"
import use from "library-react/hook"
import {styles} from "@main/res"
import Image from "library-react/component/Image";
import Debug from "library-js/Debug";

function ShopLogo({resizeMode = 'contain', ...props}) {
	const {shop} = use.context.infos().vShop;

	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<RNView {...props}>
			<Image
				source={shop.logo}
				resizeMode={resizeMode}
				alt={`${shop.name} ${shop.address.street} ${shop.address.city}`}
				style={styles.fit}
			/>

			{
				Debug.is.development &&
				devLabel
			}
		</RNView>
	);
}

export default React.memo(ShopLogo);

const devLabel = (
	<Image
		source="https://storage.googleapis.com/public-res/shopinzon-logos/israel-circle.png"
		resizeMode="contain"
		alt="Shopinzon Israel Headquarters"
		style={[styles.square('20%'), styles.absolute({bottom: 10, right: '25%'})]}
	/>
);


const localStyles = {
	layout: {},
};
