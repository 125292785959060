import React from "react"
import { View as RNView } from "react-native-web"
import use from "library-react/hook"
import { styles } from "@main/res"
import Gallery from "@main/component/Gallery";
import View from "library-react/component/View";
import Icon from "library-react/component/Icon/v2";
import RateView from "./RateView";
import AddToBasketButton from "./AddToBasketButton";
import OpenableText from "@main/component/GradientOpenableText";
import SelectSize from "../SelectSize";
import BackNavigationButton from "@main/component/BackNavigationButton";
import ProdshopPrice from "@main/component/ProdshopPrice";
import Image from "library-react/component/Image";
import Server from "library-react/library-js/app/Server";
import { screens } from "@main/links";
import Portal from "library-react/component/Portal";
import { SizeContext, VProdshopContext } from "@main/ui/ProductUI/contexts";

export default function ProductDesktopUI(
	{
		titleOpened, toggleTitle,
		onChatClicked, onRateClicked,
		selectSize, setBasketNotification,
		share, title,
		...props
	}
) {
	const vProdshop = use.context(VProdshopContext);
	const { product, prodshop, vProduct: { rate }, shop } = vProdshop;

	const size = use.context(SizeContext);


	const { value: brand } = use.loadable.server(
		() => Server.public.brand.get(product.brand)
			.updateContent(map => map[product.parsedBrand]),
		[product.brand]
	);

	const { Text } = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<RNView {...props}>
			<BackNavigationButton style={localStyles.arrow} />

			<RNView style={localStyles.content}>
				<Gallery
					items={product.gallery}
					resizeMode="cover"
					direction={Gallery.Direction.horizontal}
					enableFullScreen
					alt={title}
					{...localStyles.galleryProps}
				/>

				<RNView style={localStyles.separator} />

				{/* Infos */}
				<View style={localStyles.infos.layout}>

					{/* main infos */}
					<View style={localStyles.wrapper}>
						<View style={localStyles.infosWrapper}>
							{
								brand?.logo &&
								<Portal to={screens.brand} params={{ name: brand.name }}>
									<Image source={{ uri: brand?.logo?.url, width: 100 }}
										resizeMode='contain'
										style={localStyles.brand} />
								</Portal>
							}

							<View style={localStyles.titleWrapper}>
								{/* reference */}
								<Text
									numberOfLines={1}
									style={localStyles.infos.ref}>
									{product.reference}
								</Text>

								<View onPress={toggleTitle}>
									{/* title */}
									<Text
										numberOfLines={titleOpened ? undefined : 1}
										accessibilityRole="heading"
										style={localStyles.infos.title}>
										{product.title}
									</Text>

									{/* short subtitle */}
									{
										Boolean(product.shortSubtitle) &&
										<Text
											numberOfLines={titleOpened ? undefined : 1}
											accessibilityRole="heading"
											accessibilityLevel={2}
											style={localStyles.infos.subtitle}>
											{product.shortSubtitle}
										</Text>
									}
								</View>
							</View>
						</View>
						{/* size selection */}
						{
							(product.sizes?.length > 0) &&
							<SelectSize
								onPress={selectSize}
								style={localStyles.infos.size}>
								{size}
							</SelectSize>
						}

						{/* price */}
						{
							!shop?.hiddenPrice &&
							<ProdshopPrice
								prodshop={prodshop}
								fontSize={26}
								gap={10}
								style={localStyles.price}
							/>
						}

						<RateView
							rate={rate}
							onPress={onRateClicked}
							style={localStyles.infos.rate} />

						<AddToBasketButton
							selectSize={selectSize}
							style={localStyles.infos.mainButton} />
					</View>

					{/* share & chat */}
					<View style={localStyles.controls}>
						{/* share */}

						<ControlButton
							onPress={share}
							icon={"Feather/share"}
							text={`Partager`}
							style={localStyles.share} />

						{/* chat */}
						<ControlButton
							onPress={onChatClicked}
							icon="chat"
							filled={!shop.ordersAvailable}
							text={`En discuter`} />
					</View>

					{/* description */}

					<OpenableText
						maxHeight={100}
						showToggle
						textStyle={localStyles.infos.description.text}
						style={localStyles.infos.description.layout}>
						{product.description}
					</OpenableText>
				</View>
			</RNView>
		</RNView>
	);
}

function ControlButton({ icon, text, filled, ...props }) {
	const { Text } = use.theme();

	const styleToUse = use.memo(localStyles.control(filled), [filled]);
	props.style = use.defaultStyle(props.style, styleToUse.layout, [styleToUse]);
	return (
		<View {...props}>
			{({ hover }) => (
				<>
					{
						icon &&
						<Icon
							name={icon}
							size={24}
							style={styleToUse.icon}
						/>
					}

					<Text style={styleToUse.text(hover)}>
						{text}
					</Text>
				</>
			)
			}
		</View>
	)
}


const localStyles = {
	layout: {
		...styles.newPaddings(20, 0, 0, 31),
	},
	content: {
		flexDirection: styles.flexDirection.row,
		flexGrow: 1,
	},
	arrow: {
		marginTop: 20,
		marginBottom: 15,
	},

	wrapper: {
		paddingHorizontal: 50,
	},

	titleWrapper: {
		flex: 1
	},

	infosWrapper: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center
	},

	brand: {
		marginBottom: 16,
		marginRight: 16,
		width: 80,
		height: 80,
		borderWidth: 1,
		borderColor: '#e4e4e4',

	},
	galleryProps: {
		innerStyles: {
			main: {}
		},

		style: {
			flex: 1,
			height: 771,
		}
	},

	separator: {
		width: 1,
		backgroundColor: styles.color.lightgrey,
		marginLeft: 55,
	},

	controls: {
		maxWidth: "100%",
		borderTopWidth: 1,
		borderBottomWidth: 1,
		borderColor: styles.color.lightgrey,
		flexDirection: styles.flexDirection.row,
		height: 80,
		marginBottom: 33,
	},

	share: {
		borderRightWidth: 1,
		borderColor: styles.color.lightgrey,
	},

	control: (filled) => ({
		layout: {
			flex: 1,
			flexBasis: 0,
			backgroundColor: filled ? styles.color.black : styles.color.white,
			flexDirection: styles.flexDirection.row,
			...styles.center,
		},
		text: styles.static.bool({
			fontSize: 23,
			color: filled ? styles.color.white : styles.color.black,
		}, {
			textDecorationLine: styles.textDecorationLine.underline,
		}
		),

		icon: {
			marginRight: 28,
			color: filled ? styles.color.white : styles.color.black,
		}
	}),

	infos: {
		layout: {
			flex: 1,
			flexBasis: 0,
			alignSelf: styles.alignSelf.stretch,
			overflow: styles.overflow.hidden,
		},

		ref: {
			fontSize: 20,
			marginBottom: 3,
		},

		title: {
			fontSize: 38,
			bold: true,
			marginBottom: 6,
		},
		subtitle: {
			fontSize: 20,
			marginBottom: 40,
		},

		size: {
			borderTopWidth: 1,
			borderBottomWidth: 1,
			borderColor: styles.color.lightgrey,
			paddingVertical: 27,
			marginBottom: 50,
			zIndex: 1,
		},

		rate: {
			maxWidth: 400,
			paddingVertical: 20,
			marginBottom: 20,
		},

		mainButton: {
			marginBottom: 20,
		},


		description: {
			layout: {
				paddingHorizontal: 50,
			},
			text: {
				fontSize: 20,
			}
		},
	},

	price: {
		alignSelf: styles.alignSelf.flexStart,
		marginBottom: 10,
		flexDirection: styles.flexDirection.rowReverse,
		alignItems: styles.alignItems.flexEnd,
	},
};
