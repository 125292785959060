import use from "library-react/hook/index";
import {append, update, without} from "ramda";

export default function useStack(initialEntry){
	const [entries, setEntries] = use.state(initialEntry ? [initialEntry] : []);

	function setEntry(entry, oldEntry) {
		setEntries(entries => {
			const index = oldEntry ? entries.indexOf(oldEntry) : -1;

			const mutate = !(index >= 0) ? append(entry)
				: entry ? update(index, entry)
					: without([oldEntry]);

			return mutate(entries);
		});
	}

	return [entries, setEntry];

}