import React from "react"
import use from "library-react/hook";
import ProductGrid from "@main/component/ProductGrid";
import SearchBar from "@main/component/SearchBar";
import ScrollView from "library-react/component/ScrollView";
import {styles} from "@main/res";
import Button from "@main/component/Button";

export default function DesktopUI({iterator, onQueryChanged, ...props}) {
	const {Text, Spinner} = use.theme();

	props.style = use.defaultStyle(props.style, localStyles.layout);
	props.contentContainerStyle = use.defaultStyle(props.contentContainerStyle, localStyles.content);

	return (
		<ScrollView {...props}>
			<SearchBar
				upperCase
				inputStyle={localStyles.input}
				onQueryChanged={onQueryChanged}
				style={localStyles.search}/>

			{/* products */}
			{
				iterator &&
				<ProductGrid
					items={iterator.items}
					style={localStyles.catalog}/>
			}

			{/* footer */}
			{
				iterator && (
					iterator.loading ? <Spinner style={localStyles.button}/> :
						!iterator.end &&
						<Button style={localStyles.button} onPress={iterator.load}>
							<Text style={localStyles.text}>
								Voir plus
							</Text>
						</Button>
				)
			}
		</ScrollView>
	);
}

const localStyles = {
	layout: {
		flex: 1,
	},
	input:{
		fontSize: 17,
	},

	content: {
		marginHorizontal: 71,
		marginVertical: 50,
	},

	search: {
		maxWidth: 1000,
		width: "100%"
	},

	catalog: {
		marginTop: 80,
	},

	button: {
		alignSelf: styles.alignSelf.center,
		paddingHorizontal: 200,
		paddingVertical: 16,
		marginBottom: 50,
		marginTop: 42,
	},

	text: {
		fontSize: 23,
	},
};
