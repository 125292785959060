import View from "library-react/component/View/v2";
import Text from "library-react/component/Text/v2";
import Image from "library-react/component/Image";
import {styles} from "library-react/res";
import Icon from "library-react/component/Icon/v2";
import use from "library-react/hook";
import {parallel} from "library-js/utils/function";

export default function SuggestedAttachment({value, onAcceptSuggestedAttachment, ...props}) {
	const {product, price, shop} = value;
	const [closed, setClosed] = use.state.bool();

	const onAccept = use.callback(
		parallel(onAcceptSuggestedAttachment, setClosed.true),
		[onAcceptSuggestedAttachment]
	);

	props.style = use.defaultStyle(props.style, localStyles.layout(closed), [closed]);
	return (
		<View {...props}>
			<View style={localStyles.header.layout}>
				<Image source={product.gallery.first}
					   style={localStyles.header.image}
					   resizeMode="cover"/>
				<View style={localStyles.header.infos}>
					<Text style={localStyles.header.text}>
						{product.title}
					</Text>
					<Text style={localStyles.header.text}>
						{product.subtitle}
					</Text>
					{
						!shop.hiddenPrice &&
						<Text style={localStyles.header.price}>
							{price.formatted}
						</Text>
					}
				</View>
			</View>

			<View style={localStyles.footer.layout}>
				<Text style={localStyles.footer.text}>
					{`Voulez vous discuter de cet article ?`}
				</Text>
				<Text onPress={onAccept}
					  style={localStyles.footer.button}>
					{`Oui`}
				</Text>
			</View>
			<Icon
				name="MaterialIcons/close"
				size={15}
				style={localStyles.close}
				onPress={setClosed.true}
			/>
		</View>
	)
}
const WIDTH = 101;

const localStyles = {
	layout: styles.static.bool(
		{},
		{
			height: 0,
		},
		{}
	),
	header: {
		layout: {
			flexDirection: styles.flexDirection.row,
			backgroundColor: "rgba(46, 84, 217, .26)",
		},

		image: {
			height: WIDTH / styles.perfectPictureRatio,
			width: WIDTH,
		},

		infos: {
			flex: 1,
		},

		text: {
			padding: 10,
			paddingBottom: 0,
			fontSize: 13,
			bold: true,
		},

		price: {
			marginRight: 30,
			fontSize: 16,
			bold: true,
			textAlign: styles.textAlign.right,
		},
	},

	close: {
		color: "#1e2432",
		padding: 13,
		...styles.absolute({top: 0, right: 0}),
	},
	footer: {
		layout: {
			...styles.center,
			flexDirection: styles.flexDirection.row,
			backgroundColor: "rgba(46, 84, 217, .16)",
			paddingVertical: 22,
			justifyContent: styles.justifyContent.spaceEvenly
		},
		text: {
			fontSize: 12,
			bold: true
		},
		button: {
			bold: true,
			paddingVertical: 5,
			paddingHorizontal: 10,
			borderRadius: 14,
			color: styles.color.white,
			backgroundColor: "#452ee0",
		}
	},
}