import { complement, curry, is, isNil, trim } from "ramda";
import { nil } from "../../types";

function setPathToUrl_core(path: string | string[] | nil, url: string | URL): string {
	const urlObject = new URL(url);
	urlObject.pathname = isNil(path) ? ""
		: is(Array, path) ? path.flat(Infinity).filter(complement(isNil)).map(String).map(trim).filter(Boolean).join('/')
			: String(path);

	return urlObject.href;
}

const setPathToUrl = curry(setPathToUrl_core) as {
	(path: string | string[] | nil, url: string | URL): string;
	(path: string | string[] | nil): (url: string | URL) => string;
};
export default setPathToUrl;