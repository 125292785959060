import React from "react"
import {View as RNView} from "react-native"
import use from "../../../hook"
import {styles} from "../../../res"
import Image from "../../../component/Image/index";
import Text from "../../../component/Text";

function ItemRow({item: {vProdshop: {product, prodshop}, size, quantity}, ...props}) {
	const {PriceComponent} = use.theme();

	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<RNView {...props}>
			<Image
				source={{uri: product.mainPicture?.url, width: localStyles.image.width}}
				resizeMode="cover"
				style={localStyles.image}
			/>

			{/* infos */}
			<RNView style={localStyles.infos}>
				{/* title */}
				<Text
					numberOfLines={1}
					style={localStyles.title}>
					{product.title}
				</Text>

				{/* subtitle */}
				<Text
					numberOfLines={1}
					style={localStyles.subtitle}>
					{product.subtitle}
				</Text>

				{
					Boolean(size) &&
					<Text
						numberOfLines={1}
						style={localStyles.size}>
						{`TAILLE `}{size}
					</Text>
				}
			</RNView>

			{/*	quantity */}
			<Text
				numberOfLines={1}
				style={localStyles.quantity}>
				{quantity}
			</Text>

			{/*	price */}
			<PriceComponent
				finalPrice={prodshop.finalPrice}
				oldPrice={prodshop.oldPrice}
				fontSize={19}
				style={localStyles.price} />
		</RNView>
	);
}

export default React.memo(ItemRow);


const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.flexStart,
		marginBottom: 20
	},

	image: {
		height: 100,
		width: 80,
	},

	infos: {
		paddingLeft: 40,
		flex: 1,
	},

	title: {
		fontSize: 23,
		bold: true,
	},

	subtitle: {
		fontSize: 20,
		color: "#504747",
	},

	size: {
		fontSize: 19,
		bold: true,
		color: "#504747",
	},

	quantity: {
		minWidth: 50,
		fontSize: 23,
		textAlign: styles.textAlign.center,
		alignSelf: styles.alignSelf.center,
	},

	price: {
		flexDirection: styles.flexDirection.row,
		alignSelf: styles.alignSelf.center,
		justifyContent: styles.justifyContent.center,
		minWidth: 130,
	},
};
