import ReceptionMean from "../app/model/entity/Shop/ReceptionMean";
import createResForEnum from "./createResForEnum";

const getResourcesForReceptionMean = createResForEnum(ReceptionMean, {
	delivery: {
		title: `En livraison`,
		icon: 'ReceptionMean.delivery',
	},

	collect: {
		title: `Sur place`,
		icon: 'ReceptionMean.collect',
	},
});

export default getResourcesForReceptionMean;