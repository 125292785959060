import React from "react"
import {View as RNView} from "react-native"
import use from "library-react/hook"
import {styles} from "@main/res"
import ShopLogo from "../ShopLogo";
import Icon from "library-react/component/Icon/v2";
import {Logo, Name} from "library-react/component/company";
import AuthenticateView from "@main/component/AuthenticateView";
import Dialog from "../Dialog";

export default function AuthDialog_desktop({user, close, title, ...props}) {
	const {Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<Dialog {...props}>
			<RNView style={localStyles.header.layout}>
				<ShopLogo style={localStyles.header.shopLogo}/>

				<RNView style={localStyles.shopinzonLabel.layout}>
					<Text style={localStyles.shopinzonLabel.label}>
						{`Powered by`}
					</Text>

					<Logo style={localStyles.shopinzonLabel.logo}/>
					<Name style={localStyles.shopinzonLabel.name}/>
				</RNView>
			</RNView>


			<Text style={localStyles.title.wrapper}>
				<Text style={localStyles.title.main}>
					{
						user ? `CONNECTÉ !` :
							`Se connecter`
					}
				</Text>
			</Text>

			{
				!user &&
				<>
					<Text style={localStyles.invitation}>
						{title}
					</Text>

					<AuthenticateView
						title={null}
						style={localStyles.form.layout}/>
				</>
			}

			{
				close &&
				<Icon
					name="EvilIcons/close"
					size={30}
					onPress={close}
					style={localStyles.close}
				/>
			}
		</Dialog>
	);
};

const localStyles = {
	layout: {
		paddingHorizontal: 62,
		paddingTop: 43,
		paddingBottom: 68,
		backgroundColor: '#f8f8f8',
		maxHeight: undefined,
		...styles.newShadow(0, 0, 15, .16),
		...styles.newBorder(1, styles.color.lightgrey),
	},

	header: {
		layout: {
			alignItems: styles.alignItems.center,
			marginBottom: 78,
		},

		shopLogo: {
			height: 150,
			width: 150,
			marginBottom: 15,
		},
	},

	shopinzonLabel: {
		layout: {
			flexDirection: styles.flexDirection.row,
			alignItems: styles.alignItems.center,
		},

		label: {
			fontSize: 11,
		},

		logo: {
			height: 20,
			width: 20,
			marginLeft: 5,
		},

		name: {
			width: 73,
			height: 20,
		},
	},

	title: {
		wrapper: {
			fontSize: 23,
			marginBottom: 15,
		},

		main: {
			bold: true,
		}
	},

	invitation: {
		fontSize: 18,
		marginBottom: 45,
	},

	form: {
		layout: {},

		google: {
			minWidth: 410,
			marginBottom: 25,
		},

		facebook: {
			minWidth: 410,
		}
	},

	close: {
		position: styles.position.absolute,
		top: 0,
		right: 0,
		padding: 20,
		color: 'grey'
	}
};
