import OrderContactView from "library-react/component/order/OrderContactView";
import OrderInstructionsView from "library-react/component/order/OrderInstructionsView";
import OrderInfo from "library-react/component/OrderInfo";
import use from "library-react/hook";
import moment from "moment";
import { ScrollView, StyleSheet, View } from "react-native";
import generateInvoiceURL from "../../../library-react/library-js/app/generateInvoiceURL";
import canBeSettled from "../../../library-react/library-js/app/model/utils/order/state/canBeSettled";
import { formatPrice } from "../../../library-react/library-js/app/model/utils/price";
import BackNavigationButton from "../../component/BackNavigationButton";
import AddToBasketButton from "./AddToBasketButton";
import OrderItemView from "./OrderItemView";
import OrderOptions from "./OrderOptions";
import { ResponsiveUIProps } from "./ResponsiveUIProps";
import SettleButton from "./SettleButton";


export default function OrderUI_desktop({ order, addToBasket, total, currency, ...props }: ResponsiveUIProps) {
	const { Text } = use.theme();

	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<View {...props}>
			<BackNavigationButton style={localStyles.back} />

			<ScrollView
				contentContainerStyle={localStyles.scrollContent}
				style={localStyles.scroll}>
				{/* title & state*/}
				<View style={localStyles.header}>
					<View>
						{/* date */}
						<Text
							numberOfLines={1}
							style={localStyles.title}>
							{`CDE du ${moment(order.creationDate).format("DD MMM YYYY")}`}
							<Text style={localStyles.time}>
								{` ${moment(order.creationDate).format("HH[H]mm")}`}
							</Text>
						</Text>

						{/* id */}
						<Text
							numberOfLines={1}
							style={localStyles.id}>
							{`N° `}{order.id}
						</Text>
					</View>

					<OrderOptions
						addToBasket={addToBasket}
						pdfURL={generateInvoiceURL(order)} />
				</View>

				{/* state */}
				<OrderInfo
					order={order}
					displayOrderInfos={false}
					innerStyles={innerStateStyles}
					style={localStyles.state}
				/>

				{
					canBeSettled(order.currentState) &&
					<SettleButton
						orderId={order.id}
						style={localStyles.retry} />
				}

				{/* items */}
				<View style={localStyles.items}>
					{
						order.items?.map(item =>
							<OrderItemView
								key={item.id}
								item={item}
								style={localStyles.item} />
						)
					}
				</View>


				{/* footer */}
				<View style={localStyles.footer}>
					{/* Contact infos */}

					<OrderContactView
						order={order}
						style={localStyles.contact} />
						
					{/* total */}
					<View style={localStyles.summary}>

						{/* Total */}
						<View style={localStyles.totalLayout}>
							<Text
								numberOfLines={1}
								style={localStyles.totalLabel}>
								{`Total`}
							</Text>
							<Text
								numberOfLines={1}
								style={localStyles.totalValue}>
								{formatPrice(total, currency)}
							</Text>
						</View>
					</View>
				</View>


				{
					Boolean(order?.instructions) &&
					<OrderInstructionsView style={localStyles.instructions}>
						{order.instructions}
					</OrderInstructionsView>
				}
				{
					addToBasket &&
					<AddToBasketButton
						addToBasket={addToBasket}
						style={localStyles.addToBasket} />
				}
			</ScrollView>
		</View>
	);
}

const localStyles = StyleSheet.create({
	layout: {
		flexDirection: "row",
		flex: 1,
		paddingTop: 20,
	},

	back: {
		marginLeft: 30,
		marginRight: 45,
		alignSelf: "flex-start",
	},

	scroll: {
		flex: 1,
	},

	scrollContent: {
		maxWidth: 1200,
		paddingBottom: 40,
		flexGrow: 1,
	},

	header: {
		marginBottom: 20,
		flexDirection: "row",
		alignItems: "flex-end",
		justifyContent: "space-between",
		zIndex: 1,
	},

	title: {
		fontSize: 34,
		paddingBottom: 14,
		fontWeight: "bold",
	},

	time: {
		fontSize: 20,
	},

	id: {
		fontSize: 30,
		fontWeight: "bold",
	},

	state: {
		padding: 20,
		paddingBottom: 15,
		borderRadius: 11,
		minHeight: 100,
		flexDirection: "row-reverse",
		justifyContent: "space-between",
		alignItems: "center",
	},

	stateText: {
		fontSize: 16,
	},

	retry: {
		marginTop: 25,
		alignSelf: "center",
	},


	items: {
		paddingVertical: 40,
	},

	item: {
		marginBottom: 25,
		paddingHorizontal: 5,
	},

	footer: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: 30,
	},

	contact: {
		width: '45%'
	},
	instructions: {
		width: '45%'
	},

	addToBasket: {
		alignSelf: "flex-end",
	},



	summary: {
		backgroundColor: "#f7f7f7",
		padding: 20,
		paddingLeft: 30,
		minWidth: 470,
	},

	totalLayout: {
		flexDirection: "row",
		justifyContent: "space-between",
	},

	totalLabel: {
		fontSize: 19,
		fontWeight: "bold",
	},

	totalValue: {
		fontSize: 22,
		fontWeight: "bold",
	},
});

const innerStateStyles = {
	details: {
		fontSize: 20
	},
	state: {
		text: { fontSize: 20 }
	}
} as const;