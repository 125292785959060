import Entity from "../Entity";
import Functions from "../../../../utils/Functions";

export default class Category extends Entity {
	toString() {
		return this.id;
	}

	get parent() {
		// will be set by ShopCategoryTree.js
	}

	get pathOfNodes() {
		return (this.parent?.pathOfNodes || [])
			.concat(this);
	}

	get path() {
		return this.pathOfNodes.map(category => category.name);
	}

	get stringPath() {
		return this.getPathString();
	}

	getPathString(pretty){
		return this.path.join(pretty ? ' / ' : '/');
	}

	get children() {
		// will be set by ShopCategoryTree.js
	}
}


Category.addProperties({
	name: String,
	banner: String,
	parentId: Number,
	icon: String, // URL
	description: String,
	color: String,
});


// --- inheritance ---
const NOT_INHERITABLE = ['id', 'parentId', 'name'];
Object.keys(Category.properties)
	.filter(key => !NOT_INHERITABLE.includes(key))
	.forEach(key => {
		const getterName = `get${key.firstUp}`;
		Category.prototype[getterName] = Functions.append(Category.prototype[getterName],
			function (value) {
				return value || this.parent?.[key];
			}
		);
	});
