export default function improveReturnedPromiseOf(api){
	const result = function(...params) {
		const promise = api(...params);
		Object.assign(promise, prototype);
		return promise;
	};

	Object.assign(result, api);

	return result;
};

const prototype = {
	updateContent(update){
		return this.then(response => {
			if (response.ok){
				response = response.copy();
				response.content = update(response.content)
			}

			return response;
		})
	}
};
