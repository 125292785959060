import React from "react"
import use from "library-react/hook";
import {View} from "react-native";
import moment from "moment";
import Row from "library-react/component/Row";
import {styles} from "@main/res";
import Icon from "library-react/component/Icon/v2";
import ReservationState from "@main/ui/reservation/ReservationListUI/ReservationState";
import Spinner from "@main/component/Spinner";
import toMoment from "library-js/utils/toMoment";

export default function ReservationRowDesktopUI({item: vReservation, canceling, cancel, ...props}) {
	const {vShop} = use.context.infos();
	const {reservation} = vReservation;
	props.columnsStyles = columnsStyles;
	const at = toMoment(vShop.shop.timeZone, reservation.time);

	const {Text, Spinner} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<Row {...props}>
			<Text style={localStyles.id}>
				{`Créée le ${moment(reservation.creationDate).format("DD/MM/YYYY")}\n`}
				{`N°${vReservation.id}`}
			</Text>
			<ReservationState item={vReservation} style={localStyles.state}/>

			<View style={localStyles.wrapper(120)}>
				<Icon name={'MaterialCommunityIcons/calendar-range'}
					  size={20}
					  style={localStyles.icon}/>
				<Text style={localStyles.number}>
					{at.format("DD/MM/YYYY")}
				</Text>
			</View>

			<View style={localStyles.wrapper(100)}>
				<Icon name='MaterialCommunityIcons/clock-time-nine'
					  size={20}
					  style={localStyles.icon}/>
				<Text style={localStyles.number}>
					{at.format("HH:mm")}
				</Text>
			</View>

			<View style={localStyles.wrapper(100)}>
				<Icon name='reservation-restaurant'
					  size={20}
					  style={localStyles.icon}/>
				<Text style={localStyles.number}>
					{reservation.numberOfPlaces}
				</Text>
			</View>
			{
				canceling ?
					<Spinner size={15}/> :
					(
						vReservation.isCancellable && cancel &&
						<Text style={localStyles.cancel}
							  onPress={() => cancel(vReservation)}>
							{`Annuler`}
						</Text>
					)
			}
		</Row>
	)
}

ReservationRowDesktopUI.Header = function Header({...props}) {
	props.columnsStyles = columnsStyles;
	const {Text} = use.theme();
	return (

		<Row {...props}>
			<Text style={localStyles.label}>
				{`Date de creation`}
			</Text>
			<Text style={localStyles.label}>
				{`Statut`}
			</Text>
			<Text style={localStyles.label}>
				{`Reservation`}
			</Text>
			<Text style={localStyles.label}>
				{`Heure`}
			</Text>
			<Text style={localStyles.label}>
				{`Nombre de places`}
			</Text>
		</Row>
	)
}

const localStyles = {
	label: {
		color: "#9a9999",
		fontSize: 20,
	},
	id: {
		color: "#acb1c0",
		fontSize: 16,
	},
	layout: {
		paddingVertical: 20,
	},
	state: {
		paddingVertical: 8,
		maxWidth: 260,
	},
	date: {
		fontSize: 18,
	},
	time: {
		fontSize: 18,
	},

	number: {
		fontSize: 18,
	},
	cancel: {
		paddingHorizontal: 14,
		paddingVertical: 2,
		fontSize: 16,
		backgroundColor: "#5e6383",
		color: "#ffffff",
		textAlign: styles.textAlign.center,
	},
	wrapper: (maxWidth) => ({
		flexDirection: styles.flexDirection.row,
		maxWidth,
	}),
	icon: {
		color: "#5e6383",
		marginRight: 8,
	}
}
const columnsStyles = [
	// Date && ID
	{
		maxWidth: 300,
		marginLeft: 135,
		justifyContent: styles.justifyContent.center,
	},
	// State
	{
		maxWidth: 390,
		justifyContent: styles.justifyContent.center,
	},

	// date
	{
		maxWidth: 160,
		justifyContent: styles.justifyContent.center,
	},
	// time
	{
		maxWidth: 120,
		justifyContent: styles.justifyContent.center,
	},

	// number
	{
		maxWidth: 200,
		justifyContent: styles.justifyContent.center,
		paddingRight: 10,
	},
	// cancel
	{
		maxWidth: 100,
		justifyContent: styles.justifyContent.center,
		paddingRight: 10,
	},
];


