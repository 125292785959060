import React from "react"
import View from "library-react/component/View/v2";
import {strings, styles} from "@main/res";
import StarsView from "library-react/component/StarsView";
import use from "library-react/hook";

function RateView({rate, ...props}) {
	const {Text} = use.theme();
	const isMobile = use.context.display().is.mobile;

	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<View {...props}>
			<Text style={localStyles.rate(isMobile)}>
				{rate.stars}
				<Text style={localStyles.text(isMobile)}>/5</Text>
			</Text>
			<StarsView
				useEyalStyle
				size={isMobile ? 16 : 26}
				style={localStyles.averageStars}
				stars={rate && rate.stars}
			/>

			<Text style={localStyles.numberOfVotes(isMobile)}>
				{(rate && rate.numberOfVotes) || 0} {strings.component.RateView.rates}
			</Text>
		</View>
	)
}

export default React.memo(RateView);

const localStyles = {
	layout: {
		alignItems: styles.alignItems.center,
	},
	rate: styles.static.bool({},
		{
			fontSize: 57,
			height: 80,
		},
		{
			fontSize: 89,
			height: 120,
		}
	),
	text: styles.static.bool({},
		{fontSize: 15},
		{fontSize: 24}
	),
	averageStars: {
		paddingVertical: 13,
	},
	numberOfVotes: styles.static.bool(
		{textAlign: styles.textAlign.center,},
		{fontSize: 10},
		{fontSize: 16}
	)
};
