import {screens} from "@main/links";
import useApp from "library-react/app/useApp";
import React from "react";
import use from "library-react/hook";
import useShowcases from "@main/hook/useShowcases";
import ResevationFormModal from "../../../component/ResevationFormModal";

export default function useNavigationItems() {
	const showcases = useShowcases();
	const infos = use.context.infos();
	const {config: {type, catalog}, vShop: {shop}} = infos;

	const app = useApp();
	return [
		{
			key: "catalog",
			screen: screens.catalog,
			title: catalog?.name?.toUpperCase()
				|| selectTitle(type, {
					restaurant: "BOIRE ET MANGER",
					service: "TARIFS",
					default: "CATALOGUE",
				}),
		},
		catalog?.shouldShowBrands &&
		{
			key: "brand",
			screen: screens.brandList,
			title: `MARQUES`,
		},
		catalog?.shouldShowShowcases && showcases?.length > 0 &&
		{
			key: "showcase",
			screen: screens.showcaseList,
			title: `VITRINES`,
		},
		vShop?.shop?.acceptsReservations &&
		{
			key: "reservation",
			title: `RESERVER UNE TABLE`,
			onPress: () => app.openModal(<ResevationFormModal shopId={shop.id}/>)
		},
		{
			key: "shop",
			screen: screens.shop,
			title: `NOTRE ÉTABLISSEMENT`,
		},
	].filter(Boolean);
}
const selectTitle = (type, selection) => selection[type] || selection.default;
