import BankAccount from "./BankAccount";
import BankAccountType from "./BankAccountType";
import simpleValidate from "../../../../utils/simpleValidate";
import {validateMangoPayAddress} from "./Address";

export default class IBANBankAccount extends BankAccount {
	getType(){
		return BankAccountType.IBAN;
	}
	setType(type){
		// Debug.assert(type === BankAccountType.IBAN, "IBANBankAccount can only have IBAN Type");
		super.setType(type);
	}

	getInvalidFields() {
		return Object.entries(IBANBankAccount.generateValidateOf)
			.filter(([, validator]) => !validator.length) // remove validator that need arguments
			.filter(([field, getValidator]) => !getValidator().apply(this, [this[field], field]))
			.map(([field]) => field);
	}

}
IBANBankAccount.addProperties({
	IBAN: String,
	BIC: String,
});
IBANBankAccount.generateValidateOf = {
	OwnerName: () => simpleValidate.text,
	OwnerAddress: () => validateMangoPayAddress,
	IBAN: () => iban => IBAN_REGEX.test(iban?.trim()),
};
const IBAN_REGEX = /^[a-zA-Z]{2}\d{2}\s?(\w{4}\s?){2,7}\w{1,4}\s*$/
