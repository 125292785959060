import updateList from "./updateList"
import cache from "../cache"

export default function updateMultipleLists({domain, path, method}, predicate, updater){
	// get all (filtered) lists
	let updatedEntries = cache.getAll(domain, path, method)
		.filter(({request, content}) => updateList(content, predicate, updater));

	// update
	if (updatedEntries.length)
		cache.set(updatedEntries);
}
