import React from "react"
import { View as RNView } from "react-native"
import use from "../../../hook"
import { styles } from "../../../res"
import View from "../../../component/View/v2";
import Text from "../../../component/Text";
import Icon from "../../../component/Icon/v2";

function SelectButton({ title, subtitle, selected, icon, actionIcon, onActionClicked, ...props }) {
	props.style = use.defaultStyle(props.style, localStyles.layout, [selected]);
	return (
		<View {...props}>
			{
				Boolean(icon) &&
				<Icon
					name={icon}
					size={45}
					style={localStyles.icon(selected)} />
			}

			<Text
				style={localStyles.title}>
				{title}
			</Text>
			<Text
				style={localStyles.subtitle}>
				{subtitle}
			</Text>

			{selected && SELECT_ICON}

			{
				onActionClicked &&
				<View
					onPress={onActionClicked}
					style={localStyles.action.layout}>
					<Icon name={actionIcon || "MaterialIcons/edit"}
						size={28}
						style={localStyles.action.icon}
					/>
				</View>
			}
		</View>
	);
}

export default React.memo(SelectButton);


const SELECT_ICON = (
	<RNView style={{
		...styles.absolute({ top: 0, left: 26, bottom: 0 }),
		justifyContent: styles.justifyContent.center,
	}}>
		<Icon name="MaterialIcons/check"
			size={21}
			style={{
				...styles.circle(26),
				backgroundColor: '#395aff',
				color: styles.color.white,
			}}
		/>
	</RNView>
);

const localStyles = {
	layout: selected => ({
		...styles.center,
		paddingVertical: 5,
		minHeight: 72,
		...styles.newBorder(1, selected ? '#3c60fb' : '#e4e4e4'),
		backgroundColor: selected ? '#f0f3ff' : styles.color.white,
	}),

	icon: styles.static.bool(
		{ marginBottom: 15 },
		{ color: '#395aff' },
	),

	title: {
		textAlign: styles.textAlign.center,
		fontSize: 20,
		color: styles.color.black,
		maxWidth: 190,
		alignSelf: styles.alignSelf.center,
	},

	subtitle: {
		textAlign: styles.textAlign.center,
		fontSize: 16,
		color: '#395aff',
		maxWidth: 190,
		alignSelf: styles.alignSelf.center,
	},

	action: {
		layout: {
			...styles.absolute({ top: 0, right: 0, bottom: 0 }),
			justifyContent: styles.justifyContent.center,
			paddingRight: 25,
			paddingLeft: 10,
		},

		icon: { color: '#838686' }
	}
};
