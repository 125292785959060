import Entity from "../Entity"
import Price from "../../Price"
import TransactionState from "./State";

export default class Transaction extends Entity {
}
Transaction.State = TransactionState

Transaction.addProperties({
	amount: Price,
	fees: Price,
	IBAN: String,
	creationDate: Number,
	orderId: Number,
	state: TransactionState,
	message: String
});
