import React from "react"
import {Notification} from "../../../../../../library-js/app/model/chat";
import MessageView from "./MessageView";
import NotificationView from "./NotificationView";
import DateView from "./DateView";

export default React.memo(
	function EventView({item: event, previous, hidePrice,...props}) {
		return (
			<>
				{
					event instanceof Notification ?
						<NotificationView item={event} {...props}/> :
						<MessageView item={event} hidePrice={hidePrice} {...props}/>
				}

				{// Date
					(
						!previous ||
						!areSameDay(previous.time, event.time)
					) &&
					<DateView time={event.time}/>
				}
			</>
		);
	}
)

function areSameDay(time1, time2) {
	const date1 = new Date(time1);
	const date2 = new Date(time2);
	return (
		date1.getFullYear() === date2.getFullYear() &&
		date1.getMonth() === date2.getMonth() &&
		date1.getDate() === date2.getDate()
	);
}
