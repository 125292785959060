import { map, pipe, trim } from "ramda";
import { SYMBOLS } from "./currencies";

export function formatPrice(price, currency) {
	return [
		// trim end .00
		formatPriceAmount(price),
		toCurrencySymbol(currency),
	]
		.filter(Boolean)
		.map(pipe(String, trim))
		.filter(Boolean)
		.join('')
}


export function toCurrencySymbol(currency) {
	return SYMBOLS[currency] || currency;
}

export function formatPriceAmount(amount) {
	if (!isNaN(amount))
		return Number(amount).toFixed(2).replace(/\.00$/, "");
}