import React from 'react';
import { Animated, useWindowDimensions } from "react-native";
import use from '../hook';
import { styles } from "../res";
import StatusBar from './StatusBar';

/**
 * Dialog on desktop, fullscreen on mobile. You set the threshold width.
 */
export default function FullScreenDialog({ animatedValue, threshold = 500, children, ...props }) {
	const { width } = useWindowDimensions();
	const fullscreen = width < threshold;

	props.style = use.defaultStyle(props.style, [
		{
			backgroundColor: styles.color.white,
			borderRadius: 10,
			opacity: animatedValue.interpolate({
				inputRange: [0, 1],
				outputRange: [0, 1],
				extrapolate: "clamp",
			}),
		},
		fullscreen ? [ // mobile fullscreen
			styles.fit,
			{
				transform: [
					{
						translateX: animatedValue.interpolate({
							inputRange: [0, 1],
							outputRange: [500, 0],
							extrapolate: "clamp",
						}),
					}
				]
			}
		] : { // desktop dialog 
			width: '100%',
			alignSelf: 'center',
			maxWidth: threshold,
			maxHeight: '90%',
			overflow: "hidden",
			transform: [
				{
					translateY: animatedValue.interpolate({
						inputRange: [0, 1],
						outputRange: [500, 0],
						extrapolate: "clamp",
					}),
				}
			]
		},
	], [width]);

	return (
		<Animated.View {...props}>
			<StatusBar translucent={!fullscreen} />
			{children}
		</Animated.View>
	);
}

