import React from "react";
import use from "../../../hook";
import Price from "../../../library-js/app/model/Price";
import useApp from "../../../app/useApp";
import BasketReviewModal from "../component/BasketReviewModal";
import BottomBar from "../component/BottomBar";
import CheckoutStateContext from "./CheckoutStateContext";

export default function BasketBottomBar({...props}) {
	const {items} = use.context(CheckoutStateContext);


	props.sum = use.memo(() => {
			if (items) {
				const total = items.reduce((total, vItem) =>
					total.setValue(total.value + vItem.finalPrice.value),
					new Price(0),
				);
				total.currency = items.first?.finalPrice?.currency;
				return total;
			}
		},
		items,
	);

	const app = useApp();
	props.onSumClicked = use.callback(() =>
			app.openModal(
				<BasketReviewModal items={items}/>
			),
		[items]
	);

	return Boolean(items)
		&& <BottomBar {...props}/>;
}
