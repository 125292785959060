import { useNavigation } from "@react-navigation/native";
import useSyncEffect from "../useSyncEffect";
import useMemo from "../useMemo";
import useEffect from "../useEffect";


export default function useFocusEffect(effect, dependencies) {
	const memory = useMemo({ undo: null });

	const willRunEffect = focus => () => {
		if (memory.undo)
			memory.undo();
		memory.undo = null;

		if (effect)
			return memory.undo = effect(focus, dependencies);
	};

	const navigation = useNavigation();
	useEffect(willRunEffect(navigation.isFocused()), dependencies);

	useSyncEffect(() => {
		const action = () => setTimeout(willRunEffect(true));
		navigation.addListener('focus', action);
		return () => navigation.removeListener('focus', action);
	},
		dependencies
	);

	useSyncEffect(() => {
		const action = () => setTimeout(willRunEffect(false));
		navigation.addListener('blur', action);
		return () => navigation.removeListener('blur', action);
	},
		dependencies
	);
}
