import React from "react";
import MobileUI from "./MobileUI";
import DesktopUI from "./DesktopUI";
import use from "../../../library-react/hook";
import Server from "../../../library-react/library-js/app/Server";
import { identity, prop } from "ramda";
import SEO, { iteratorToStatus } from "library-react/component/SEO";
import { paths } from "@main/links";
import pageTitle from "@main/utils/pageTitle";


const NUMBER_OF_PRODUCTS = 3;
export default function BrandListUI({ ...props }) {
	const { vShop } = use.context.infos();

	props.title = 'MARQUES';

	const [prodshopBrands, setProdshopBrands] = use.state({});
	props.prodshopBrands = prodshopBrands;

	const createdBrandIterator = use.memo(
		(shopId) => {
			const iterator = Server.public.brand.getList.getIterator({
				created: true,
				available: true,
				shopId
			});

			iterator.resolveResponse = response => {
				if (response.ok) {
					const names = response.content.map(({ name }) => name);
					setProdshopBrands(map => ({ ...map, ...names.toObject(identity, () => undefined) }))
					Server.public.brand.getProdshopsMap(names, shopId, NUMBER_OF_PRODUCTS).then(
						response => {
							if (response.ok)
								setProdshopBrands(map => ({ ...map, ...response.content }));
							else
								setProdshopBrands(map => ({ ...map, ...names.toObject(identity, () => null) }));
						}
					)

				}
				return response;
			};
			return iterator;
		},
		[vShop.shop.id]
	);
	const uncreatedBrandIterator = use.memo(
		(shopId) => Server.public.brand.getList.getIterator({
			created: false,
			shopId,
			available: true,
		}),
		[vShop.shop.id]
	);

	const { items } = props.iterator = use.infiniteIterator(() => {
		let iterator = createdBrandIterator;
		if (iterator.end) {
			iterator = uncreatedBrandIterator;
			iterator.items.unshift(...createdBrandIterator.items)
		}
		return iterator;
	},
		[createdBrandIterator, uncreatedBrandIterator, createdBrandIterator.end]
	);

	const display = use.context.display();
	return (
		<>
			<SEO
				status={iteratorToStatus(props.iterator, true)}
				canonical={paths.brand}
				title={pageTitle([`MARQUES`])}
				image={vShop.logo}
				description={
					items && (
						items.map(prop('name'))
							.join(', ')
						+ `... Retrouvez toutes nos marques.`
					)
				} />


			{
				display.is.mobile ?
					<MobileUI {...props} /> :
					<DesktopUI {...props} />
			}
		</>
	);
}
