import IDs from "./IDs";
import entryToEvent from "./entryToEvent";
import Chat from "../../Chat";

export default function entryToChat({key: interlocutorId, value: data}){
	data = {...data}; // copy, do not alter original
	// correct data
	data.interlocutorId = interlocutorId;
	data.id = IDs.chat(interlocutorId);
	data.unread = data.unread ? Object.keys(data.unread).length : 0;
	delete data.lastEventTime;

	// instantiate
	data.event = entryToEvent(data.event);

	const chat = new Chat();
	chat.hydrate(data);
	return chat;
}
