import cache from "../cache"
import Response from "../Response"
import Debug from "../../../Debug"
import {Accessor, Functions, is} from "../../../utils"
import {Base} from "../../../class"
import Iterator from "../Iterator";
import improveReturnedPromiseOf from "./improveReturnedPromiseOf";

export default function APIFunction(configuration) {
	// check parameters TODO correct fallback
	let fallbackInProd = Debug.assert(
		!configuration || !is(configuration.request, Function),
		"APIFunction({request}) is required in the constructor.",
		() => fallbackInProduction
	);

	if (fallbackInProd) // error in configuration during production
		return fallbackInProd;


	// ------
	// api function to return
	let apiFunction;

	let buildRequest = configuration.request;
	let cacheConfiguration = configuration.cache;
	let {onSuccess, updateOtherAPIFunctions} = configuration;

	// change response before returning it to client
	let resolveResponse = async (response, request, isFromServer) => {
		if (response.ok) {
			try {
				if (onSuccess)
					await onSuccess.apply(apiFunction, [response, request, isFromServer]);
			} catch (error) {
				console.warn("An error happened during the APIFunction's onSuccess: ", error);
				// change response
				response = new Response(Response.Status.BUG, error);
				response.message = "An error happened during the APIFunction's onSuccess";
			}

			// run update after response changes
			try {
				if (updateOtherAPIFunctions && isFromServer && cache.enabled)
					updateOtherAPIFunctions.apply(apiFunction, [response.content, request]);
			} catch (error) {
				console.warn("An error happened during the APIFunction's updateOtherAPIFunctions: ", error);
			}
		}

		return response;
	};

	// check cache config
	if (configuration.mock) {
		buildRequest = Functions.append(buildRequest, (request, ...params) => {
			request.send = async function () {
				await Promise.await(1000);
				return new Response(Response.Status.Success, configuration.mock(...params));
			};

			return request;
		});
	}


	if (cacheConfiguration) {
		// get configuration variables
		let defaults = APIFunction.default.cache;
		let {
			time = defaults.time,
			accessor = defaults.accessor,
		} = cacheConfiguration;

		// basic functions
		let persistInCache = async (request, content, time) => {
			await cache.initiating;
			return accessor.set(cache, [{request, content}], time);
		};
		let getFromServer = (request) => {
			// batch: get already existing promise for current server request
			let promiseKey = promisesGettingFromServer.getKeyForRequest(request);
			if (promisesGettingFromServer[promiseKey])
				return promisesGettingFromServer[promiseKey];

			// batch: if no promise existing, create one for this current request
			return promisesGettingFromServer[promiseKey] = (async () => {
				try {
					let response = await request.send();

					// save in cache
					if (response.ok && response.content)
						try {
							await persistInCache(request, response.content, time);
						} catch (error) {
							console.warn("Error persisting data in cache: ", error, request, response);
						}

					// batch: resolve all awaiting functions for this request
					response = await resolveResponse(response, request, true);
					"".toLowerCase();
					return response;
				} finally {
					delete promisesGettingFromServer[promiseKey];
				}
			})();
		};
		let getContentFromCache = async request => {
			await cache.initiating;
			let content = accessor.get(cache, request);

			if (request.responseType) // convert content if necessary
				content = Base.from(content, request.responseType);

			return content;
		};
		let buildResponseWithContentCached = (request, content) => {
			let response = new Response(Response.Status.Success, content);
			return resolveResponse(response, request, false);
		};

		// default: check cache if available, otherwise get from server
		apiFunction = async (...params) => {
			let request = buildRequest(...params);
			let response;

			try {// check cache
				let content = await getContentFromCache(request);
				if (content)
					response = buildResponseWithContentCached(request, content);

			} catch (error) {
				Debug.assert(true, ["Error with cache: ", error], true);
			}

			if (!response) // get from server
				response = await getFromServer(request);

			return response;
		};

		// force: get response from server
		apiFunction.force = async (...params) => {
			let request = buildRequest(...params);
			return getFromServer(request);
		};

		// last: get last content saved
		apiFunction.last = async (...params) => {
			let request = buildRequest(...params);
			let content = await getContentFromCache(request);

			if (content) {
				let response = buildResponseWithContentCached(request, content);
				content = response.content;
			}

			return content
		};

		// last and force: allow to get data from cache and display it while refreshing
		apiFunction.lastAndForce = (...params) => [
			apiFunction.last(...params),
			apiFunction.force(...params),
		];

		// set a way to update cache
		apiFunction.cache = {
			persist(params, content, time) {
				params = params || [];
				let request = buildRequest(...params);
				persistInCache(request, content, time);
			},

			persistMulti(entries, time) {
				entries = entries.map(entry => {
					entry.request = buildRequest(...entry.params);
					delete entry.params;
					return entry;
				});

				accessor.set(cache, entries, time);
			},

			async updateIfExist(params, update, time) {
				params = params || [];
				let request = buildRequest(...params);
				let cached = await getContentFromCache(request);
				if (cached) {
					// update
					let updated = update(cached);
					persistInCache(request, updated, time);
				}
			},

			clear(...params) {
				let request = buildRequest(...params);
				cache.clear(request.domain, request.path, request.method,
					// no parameter means to clear all
					params.length ? request.parameters : null
				);
			},
		};

		// export config
		apiFunction.config = {cache: {accessor, time}};
	} else {
		// no cache config
		apiFunction = async (...params) => {
			let request = buildRequest(...params);
			let response = await request.send();
			return resolveResponse(response, request, true);
		};

		// export config
		apiFunction.config = {};
	}

	// --- iterator ---
	if (configuration.iterator) {
		let {
			load,
			getCursor,
			minimum,
		} = configuration.iterator;

		if (!load) // default load function
			load = ({cursor, params, api}) => api(...params.concat([cursor]));

		apiFunction.getIterator = (...params) => new Iterator()
			.hydrate({
				function: function (cursor) {
					return load.call(this, {cursor, params, api: apiFunction});
				},
				getCursor: response => getCursor(response.content.last),
				minimum: minimum instanceof Function ? minimum(params) : minimum,
			});
	}

	// export common config
	apiFunction.config.request = buildRequest;

	if (configuration.constructor)
		configuration.constructor.apply(apiFunction);

	// wrap api function to improve returned promise
	return improveReturnedPromiseOf(apiFunction);
}


APIFunction.default = {
	cache: {
		time: null,
		accessor: new Accessor({
			get(cache, request) {
				return cache.get(request).first;
			},

			set(cache, entries, timeout) {
				return cache.set(entries, timeout);
			}
		}),
	},
};


// always return a response with a BUG status
async function fallbackInProduction() {
	new Response(Response.Status.BUG);
}

fallbackInProduction.force = fallbackInProduction;
fallbackInProduction.last = () => null;
fallbackInProduction.lastAndForce = () => [null, fallbackInProduction()];

// server request's promises container, used to batch  server requests
const promisesGettingFromServer = {
	getKeyForRequest({domain, path, method, parameters}) {
		let parametersKey;
		try {
			parametersKey = JSON.stringify(parameters);
		} catch (error) {
			parametersKey = `{${Object.keys(parameters).join(',')}}`;
		}

		return `${domain} ${path} ${method} ${parametersKey}`;
	}
};
