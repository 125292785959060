import React from "react";
import { Linking, Pressable, View } from "react-native";
import use from "../../hook";
import { styles } from "../../res";
import Icon from "../Icon/v2";
import Text from "../Text";

import { adjust, is, nth, trim, when } from "ramda";
import { copyToClipboard } from "../../utils/clipboard";

/**
 * @param {{
 * 	order: import("../../library-js/app/graphql").Order_V2,
 * 	phoneClickable?: any,
 * 	addressClickable?: any,
 * 	emailClickable?: any,
 * } & import('react-native').ViewProps}} 
 */
function OrderContactView({ order, phoneClickable, addressClickable, emailClickable, ...props }) {
	const onEmailClicked = (
		emailClickable
		&& Boolean(order?.customerEmail)
		&& (() => Linking.openURL(`mailto:${order.customerEmail}`))
	) || undefined;

	const onPhoneClicked = (
		phoneClickable
		&& Boolean(order?.customerPhone)
		&& (() => Linking.openURL(`tel:${order.customerPhone}`))
	) || undefined;

	const [addressCopied, setAddressCopied] = use.state.bool(false);
	use.effect(() => setTimeout(setAddressCopied.false, 4000), [addressCopied]);

	const onAddressClicked = (
		addressClickable
		&& Boolean(order?.customerAddressFormated)
		&& function copyAddress() {
			copyToClipboard(order?.customerAddressFormated);
			setAddressCopied(true);
		}
	) || undefined;

	return (
		<View {...props}>
			<Text style={localStyles.title}>
				{`Informations de contact`}
			</Text>

			<View style={localStyles.content}>
				<Text style={localStyles.name}>
					{order?.customerName}
				</Text>

				{
					[
						["MaterialIcons/email", order?.customerEmail, onEmailClicked],
						["MaterialIcons/phone", order?.customerPhone, onPhoneClicked],
						[
							addressCopied ? "MaterialIcons/check" :
								addressClickable ? "MaterialIcons/content-copy" :
									'MaterialIcons/place',
							addressCopied ? `Adresse copié !` :
								order?.customerAddressFormated,
							onAddressClicked
						],
					]
						.map(adjust(1, when(is(String), trim)))
						.filter(nth(1))
						.map(([icon, label, onClicked]) =>
							<Pressable
								key={label}
								onPress={onClicked}
								disabled={!onClicked}
								style={localStyles.row}>
								<Icon
									name={icon}
									size={17}
									style={localStyles.icon}
								/>

								<Text numberOfLines={1}
									style={localStyles.field(onEmailClicked)}>
									{label}
								</Text>
							</Pressable>
						)
				}
			</View>
		</View>
	);
}

export default React.memo(OrderContactView);


const localStyles = {
	title: { fontSize: 20, bold: true, marginBottom: 10 },
	content: {
		paddingLeft: 10,
		borderLeftWidth: 2,
		borderColor: styles.color.lightgrey,
	},

	row: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
		paddingVertical: 5,
	},

	icon: {
		color: styles.color.grey,
		marginRight: 10,
	},

	name: {
		fontSize: 18,
		paddingBottom: 7,
	},

	field: styles.static.bool(
		{ fontSize: 17, letterSpacing: 1.1 },
		{ color: styles.color.azure2 }
	),

	address: { lineHeight: 17 },
};
