import React from "react"
import use from "../../../../hook";
import View from "../../../../component/View";
import Text from "../../../../component/Text";
import Image from "../../../../component/Image";
import {styles} from "../../../../res";

export default React.memo(
	/**
	 * @param {VBasket.Item} item
	 */
	function BasketItem({vBasketItem: item, ...props}) {
		props.style = use.defaultStyle(props.style, localStyles.layout);

		return (
			<View {...props}>
				<Image
					source={item.product.gallery.first}
					resizeMode="cover"
					style={localStyles.image}
				/>
				<View style={localStyles.infos.layout}>
					<Text
						numberOfLines={1}
						style={localStyles.infos.title}>
						{item.product.title}
					</Text>
					<Text
						numberOfLines={2}
						style={localStyles.infos.subtitle}>
						{item.product.subtitle}
					</Text>

					<Text
						numberOfLines={1}
						style={localStyles.infos.size}>
						{item.size}
					</Text>

				</View>
				<Text
					numberOfLines={1}
					style={localStyles.infos.quantity}>
					{item.quantity}
				</Text>
				<Text
					numberOfLines={1}
					style={localStyles.infos.price}>
					{item?.finalPrice.formatted}
				</Text>

			</View>
		)
	}
)

let localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
		justifyContent: styles.justifyContent.spaceBetween,
		flexGrow: 0,
	},

	image: {
		height: 58,
		width: 58,
		backgroundColor: styles.color.lightgrey,
		alignSelf: styles.alignSelf.center,
		marginRight: 13,
	},

	infos: {
		layout: {
			flex: 1,
			maxWidth: 180,
		},

		title: {
			fontSize: 20,
			bold: true,
		},
		subtitle: {
			fontSize: 15,
		},

		brand: {
			fontSize: 11,
			bold: true,
		},

		size: {
			fontSize: 14,
			bold: true,
		},
		quantity: {
			width: 40,
			textAlign: styles.textAlign.right,
			fontSize: 20,
			bold: true,
		},

		price: {
			width: 70,
			textAlign: styles.textAlign.right,
			fontSize: 19,
			bold: true,
		},
	},
};
