import React from "react"
import { Animated } from "react-native"
import use from "../hook";

/**
 * @param {{
 * 	View?: any,
 * 	disabled?: any,
 * 	delay?: any,
 * } & import("react-native").ViewProps } 
 */
export default function LoadingPlaceholder({ View, disabled, delay, ...props }) {
	disabled = Boolean(disabled);
	if (!View)
		View = Animated.View;

	const value = use.memo(() => new Animated.Value(.4));

	use.effect(() => {
		if (disabled) return;

		const animation = Animated.loop(
			Animated.sequence([
				Animated.delay(delay || 0),
				Animated.timing(value, {
					toValue: .99999,
					duration: Date.SECOND,
					useNativeDriver: true,
				}),
				Animated.timing(value, {
					toValue: .4,
					duration: Date.SECOND,
					useNativeDriver: true,
				}),
			])
		);

		animation.start();
		return () => animation.stop();
	},
		[disabled],
	);

	props.style = use.defaultStyle(props.style,
		!disabled && {
			backgroundColor: "lightgrey",
			opacity: value,
		},
		[disabled]
	);

	return <View {...props} />;
}
