import MobileUI from "./MobileUI";
import DesktopUI from "./DesktopUI";
import use from "library-react/hook";
import useToast from "library-react/hook/useToast";
import strictAPI from "library-js/app/Server/strictAPI";
import useAuth from "library-react/hook/useAuth";
import {user as Server} from "library-js/app/Server";
import ReservationState from "library-js/app/model/entity/Reservation/State";
import {assoc, dissoc} from "ramda";

export default function ReservationListUI({...props}){
	const display = use.context.display();
	const toast = useToast();

	const [handling, setHandling] = use.state({});
	const {vShop} = use.context.infos();
	const user = useAuth.user();
	props.iterator = use.infiniteIterator(() =>
			user &&
			Server.reservation.getList.getIterator(vShop.id, {desc: true}),
		[user, vShop.id]
	);

	props.cancel = function cancel(vReservation) {
		if (vReservation.isCancellable){
			setHandling(assoc(vReservation.id, true, handling));

			strictAPI(Server.reservation.persist)({reservation: vReservation.reservation, state: ReservationState.Type.refused})
				.then(({currentState}) => {
					vReservation.currentState = currentState;
					toast(`Réservation annulée`)
				})
				.catch(() => setHandling(assoc(vReservation.id, false, handling)))
				.finally(() => setHandling(dissoc(vReservation.id, handling)));
		}

	}
	props.handling = handling;
	return (
		display.is.mobile ?
			<MobileUI {...props}/> :
			<DesktopUI {...props}/>
	)
}
