import React from "react"
import {View as RNView, ScrollView} from "react-native"
import use from "library-react/hook";
import ErrorView from "../../ErrorView";
import {styles} from "@main/res"
import TextInput from "library-react/component/input/TextInput";
import StarsView from "library-react/component/StarsView";
import SimpleButton from "library-react/component/SimpleButton";
import {STARS_STYLES} from "./constants";

export default function CommentFormDesktop(
	{
		loadable: {value: remoteVComment, loading, load, end},  // data
		state, comment,
		update, submit, remove, // actions
		...props
	}
) {
	const {Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);
	props.contentContainerStyle = use.defaultStyle(props.contentContainerStyle, localStyles.container);

	return (
		<ScrollView {...props}>
			{
				comment ?
					<>
						<RNView style={localStyles.rate}>
							<Text style={localStyles.rateLabel}>
								{`Ma note`}
							</Text>

							<StarsView
								stars={comment.stars}
								onStarsChanged={update.stars}
								useEyalStyle
								editable={state.is.editable}
								size={41}
								gap={21}
								{...STARS_STYLES}
							/>
						</RNView>

						<Text style={localStyles.label}>
							{`Mon avis`}
						</Text>


						<Text style={localStyles.instruction}>
							{`Nous vous rappelons qu’il n’est pas permis de tenir de propos violents, diffamatoires ou discriminatoires.`}
						</Text>

						<TextInput
							value={comment.text}
							onValueChanged={update.text}
							multiline
							disabled={!state.is.editable}
							style={localStyles.input}
						/>

						<RNView style={localStyles.infos}>
							<Text
								numberOfLines={1}
								style={localStyles.numberOfChars}>
								{comment.text?.length || 0}{` caractères`}
							</Text>

							{
								state.is.error &&
								<Text
									numberOfLines={1}
									style={localStyles.error}>
									{`Une erreur s'est produite`}
								</Text>
							}
						</RNView>

						<RNView style={localStyles.buttons}>
							{/* delete */}
							{
								(!state.is.saving && remoteVComment) &&
								<SimpleButton
									onPress={remove}
									border
									filled={!state.is.deleting}
									colors={localStyles.delete.colors}
									style={localStyles.delete.layout}>
									<Text style={localStyles.delete.text}>
										{
											state.is.deleting ? `Suppression en cours...` :
												`SUPPRIMER`
										}
									</Text>
								</SimpleButton>
							}

							{/* submit */}
							<SimpleButton
								onPress={submit}
								filled={state.is.editable}
								border
								colors={localStyles.submit.colors}
								style={localStyles.submit.layout(!state.is.deleting)}>
								<Text style={localStyles.submit.text}>
									{
										state.is.saving ? `Sauvegarde en cours...` :
											`PUBLIER MON AVIS`
									}
								</Text>
							</SimpleButton>
						</RNView>

						<Text style={localStyles.legal}>
							{`En cliquant sur le bouton “Publier mon avis”, vous acceptez `}
							<Text style={localStyles.conditions}>
								{`les conditions d’utilisations de Shopinzon`}
							</Text>
						</Text>
					</> :

					// ------
					loading || (end && !comment) ? (
							<Text style={[localStyles.loading, localStyles.other]}>
								{`Chargement...`}
							</Text>
						) :
						!end &&
						<ErrorView
							retry={load}
							style={localStyles.other}
						/>
			}
		</ScrollView>
	);
}


const localStyles = {
	layout: {},

	container: {
		...styles.newPaddings(25, 75, 54, 75),
	},

	rate: {
		flexDirection: styles.flexDirection.row,
		justifyContent: styles.justifyContent.center,
		alignItems: styles.alignItems.center,
		marginBottom: 60,
	},

	rateLabel: {
		fontSize: 18,
		color: "#504747",
		marginRight: 30,
	},

	stars: {},

	label: {
		fontSize: 18,
		color: "#504747",
		marginBottom: 6,
	},

	instruction: {
		fontSize: 16,
		color: "#504747",
		marginBottom: 10,
	},

	input: {
		height: 300,
		paddingHorizontal: 12,
		paddingVertical: 18,
		borderWidth: 1,
		borderColor: "#bfc1cd",
		fontSize: 16,
		color: "#504747",
		marginBottom: 10,
	},

	infos: {
		flexDirection: styles.flexDirection.row,
		justifyContent: styles.justifyContent.spaceBetween,
		marginBottom: 10,
	},

	numberOfChars: {
		fontSize: 16,
		color: "#504747",
	},

	error: {
		color: styles.color.error,
		fontSize: 16,
	},

	buttons: {
		flexDirection: styles.flexDirection.row,
		justifyContent: styles.justifyContent.flexEnd,
		height: 40,
		marginBottom: 70,
	},

	delete: {
		colors: {
			primary: "#cd081e",
			secondary: styles.color.white,
		},

		layout: {
			minWidth: 208,
			marginRight: 14,
			...styles.center,
		},

		text: {
			fontSize: 13,
		}
	},

	submit: {
		colors: {
			primary: styles.color.black,
			secondary: styles.color.white,
		},

		layout: styles.static.bool(
			{
				minWidth: 208,
				...styles.center,
			},
			null,
			styles.invisible
		),

		text: {
			fontSize: 13,
		}
	},


	legal: {
		fontSize: 18,
		color: "#504747",
	},

	conditions: {
		bold: true,
	},

	loading: {
		fontSize: 20,
	},

	other: {
		padding: 100,
	}
};
