import init from "../library-js/init"
import Firebase from "../Firebase"
import React from "react"
import {Objects} from "../library-js/utils"
import MemoryMapStorage from "../library-js/utils/storage/MemoryMapStorage"
import {Linking} from "react-native"
import Environment from "../library-js/Environment";

export default function initWeb(settings) {
	if (settings.apiKey)
		Firebase.init(settings.apiKey);

	if (Firebase.initialized)
		settings.firebase = Firebase;

	settings.globalMapStorage = new MemoryMapStorage;
	settings.serverMapStorage = new MemoryMapStorage;

	init(settings);
};

// ----- Linking ----
Linking.linkPropsFor = function (href) {
	const props = {onPress: () => window.open(href, "_blank")};

	if (Environment.crawling) {
		props.href = href;
		props.accessibilityRole = "link";
	}

	return props;
};

// metas setters & getters
document.metas = {
	get canonical() {
		return document.querySelector("head > link[rel='canonical']")
			?.href;
	},
	set canonical(href) {
		this.set("link", {rel: "canonical"}, {href});
	},

	get title() {
		return document.querySelector("head > title")
			?.innerText;
	},
	set title(title) {
		this.set("title", null, {innerText: title});
	},

	get icon() {
		return document.querySelector("head > link[rel='icon']")
			?.href;
	},
	set icon(href) {
		this.set("link", {rel: "icon"}, {href});
	},

	get description() {
		return document.querySelector("head > meta[name='description']")
			?.content;
	},
	set description(content) {
		this.set("meta", {name: "description"}, {content});
	},

	set indexable(indexable) {
		this.set("meta", {name: "robots"}, !indexable && {content: "noindex"});
	},


	set(type, attributes, contents) {
		attributes = attributes || {};

		let selector = type + Objects.setsOf(attributes)
			.map(({key, value}) => `[${key}=${value}]`)
			.join('');

		let elements = Array.from(document.querySelectorAll(selector));
		let element = elements.shift(); // get the first one
		elements.forEach(element => element.remove()); // remove all others (only one should be left)

		if (contents && Object.keys(contents).some(key => contents[key])) {
			if (!element) { // not created
				element = document.createElement(type);
				Object.assign(element, attributes);
			}

			if (element.parentElement !== document.head) // not well placed
				document.head.appendChild(element);

			Object.assign(element, contents);
		} else if (element)
			element.remove();
	}
};


// define a blob.url getter
Object.defineProperties(Blob.prototype, {
	url: {
		configurable: true,
		get: function () {
			return URL.createObjectURL(this);
		}
	},
	toBase64: {
		configurable: true,
		writable: true,
		value: function () {
			return new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.readAsDataURL(this);
				reader.onload = () => resolve(reader.result);
				reader.onerror = (error) => reject(error);
			});
		}
	}
});