import React from "react"
import use from "library-react/hook";
import View from "library-react/component/View";
import {styles} from "@main/res";
import RateView from "@main/component/CommentsModal/CommentList/Header/RateView";
import StarsList from "@main/component/CommentsModal/CommentList/Header/StarsList";
import CommentButton from "@main/component/CommentsModal/CommentList/CommentButton";
import ErrorView from "@main/component/ErrorView";
import Spinner from "@main/component/Spinner";

export default function HeaderMobile({rateLoadable, edit, ...props}) {
	const {value: rate, loading, load: reload, failed} = rateLoadable;

	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<View {...props}>
			<View style={localStyles.header}>
				{
					rate ?
						<>
							<RateView rate={rate} style={{marginRight: 35}}/>
							<StarsList rate={rate}/>
						</> :
						loading ?
							<Spinner style={{flex: 1}}/> :
							failed && <ErrorView style={{flex: 1}} onLoadClicked={reload}/>
				}
			</View>
			<CommentButton style={localStyles.button} onPress={edit}/>
		</View>
	);
}


const localStyles = {
	layout: {
		alignItems: styles.alignItems.center,
	},

	header: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
		justifyContent: styles.justifyContent.center,
		marginTop: 30,
		marginBottom: 26,
	},
	button: {
	}
};
