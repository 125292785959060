import SimpleButton from "library-react/component/SimpleButton"
import React from "react"
import use from "library-react/hook";

export default function Button({...props}) {
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return <SimpleButton border {...props} />;
}

const localStyles = {
	layout : {
		borderWidth: 1,
	}
};
