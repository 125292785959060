import Iterator from "./Iterator";
import config from "../config";
import {convertEntries, entryToChat, nodeChildrenToEntries, Paths} from "../utils";

export default class ChatIterator extends Iterator {
	constructor() {
		super();
		this.items = [];
	}

	onLoading(page) {
		const {cursor: fromTime} = this;

		return Promise.process(this.shouldStop)
			// load DB node of chats
			.then(() => {
				let ref = config.firebase.database().ref(Paths.list)
					.orderByChild("lastEventTime")
					.limitToLast(NUMBER_PER_PAGE);
				if (fromTime)
					ref = ref.endAt(fromTime - 1);

				return ref.once("value");
			})
			// convert node into array of entries
			.then(nodeChildrenToEntries)
			// reverse
			.then(entries => entries.reverse())
			// convert data into instances of chats
			.then(async entries => {
				const chats = await convertEntries(
					entries,
					entryToChat,
					this.shouldStop,
					entry => entry?.value?.event, // not a chat phantom
				);

				chats.cursor = entries.last?.value?.lastEventTime;
				return chats;
			})
			// add new chats to .items | log error
			.transparent((chats, error) => {
				if (chats)
					this.items.push(...chats);
				else if (error)
					console.warn(error);
			});
	}

	isEnd(page, chats) {
		this.cursor = chats.cursor;
		return !this.cursor || !((chats.length + chats.incorrectCount) >= NUMBER_PER_PAGE);
	}
}

const NUMBER_PER_PAGE = 30;
