import produce from "immer";
import AuthManager from "../../../AuthManager";
import simpleFetch from "../../../network/simpleFetch";
import assignParamsToUrl from "../../../utils/URL/assignParamsToUrl";

// function generateNode(path = '/') {
// 	return new Proxy(aFn, {
// 		get(_, next) {
// 			if (next == 'post')
// 				return function post(params) {
// 					return send(getUrl(path, params), {
// 						method: 'POST',
// 						headers: {
// 							'Content-Type': 'application/json'
// 						},
// 						body: JSON.stringify(params)
// 					});
// 				};

// 			return generateNode(concatPaths(path, next));
// 		},

// 		apply(_, __, [params]) {
// 			const url = getUrl(path, params);
// 			return send(url);
// 		},
// 	});
// }

// const microservices = generateNode();



/**
 * Use it like:
 * - microservices.path.to.a.get.endpoint({ ...params }) // params are query params
 * - microservices.path.to.another.endpoint.post({ ...params }) // params are body params
 * 
 * The return type is guessed from the Content-Type header.
 * You receive directly the json body as the returned value.
 * If the fetch failled, it throws an error.
 */
// export default microservices;
export default {};


export async function callMicroservice(path, params, config) {
	const paramsInUrl = !config?.method || config.method == 'GET';

	const url = getUrl(path, paramsInUrl ? params : undefined);
	const body = paramsInUrl ? undefined : JSON.stringify(params);
	if (!config) config = {};
	if (!paramsInUrl) config.body = body;
	
	return send(url, config);
}

function send(url, config) {
	const token = AuthManager.token;

	if (token) {
		if (!config.headers) config.headers = {};
		config.headers.Authorization = `Bearer ${token}`;
		if (config.method && config.method !== "GET")
			config.headers['Content-Type'] = 'application/json';
	}

	return simpleFetch(url, config);
}

function getUrl(path, params) {
	if (!path) path = "";
	const url = new URL(ENDPOINT);
	url.pathname = concatPaths(url.pathname, path);
	return params ? assignParamsToUrl(params, url.href) : url.href;
}

function concatPaths(start, end) {
	if (!start?.endsWith('/'))
		start = (start || "") + '/';
	if (end?.startsWith('/'))
		end = end.slice(1);
	const startURL = new URL(start, 'http://a.com');
	const finalURL = new URL(end, startURL.href);
	return finalURL.pathname;
}

const ENDPOINT = `https://us-central1-rcm55-bagshop.cloudfunctions.net`;