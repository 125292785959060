import { ApolloClient, gql, useQuery } from "@apollo/client";
import { map, pipe, sortBy } from "ramda";
import use from "../../../hook";

export function useShopTags(shopId) {
	const query = useQuery(SHOP_TAGS_QUERY, {
		variables: { shopId },
		skip: !shopId
	});

	const shopTags = query.data?.shopTagRelationship;
	const tags = use.memo(() => {
		if (shopTags)
			return pipe(
				sortBy(shopTag => shopTag.index),
				map(shopTag => shopTag.tag.name)
			)(shopTags)
	},
		[!query.data, ...(shopTags || [])],
	);

	return [tags, query];
}


export const SHOP_TAGS_QUERY = gql`
	query getShopTags($shopId: bigint!) {
		shopTagRelationship(where: {shopId: {_eq: $shopId}}) {
			id
			index
			tag { id name }
		}
	}
`;

/**
 * @param {ApolloClient} apollo
 */
export async function persistShopTags(apollo, shopId, tags) {
	if (tags)
		return apollo.mutate({
			mutation: SHOP_TAGS_MUTATION,
			variables: {
				shopId,
				shopTags: tags.map((name, index) => ({
					shopId,
					index,
					tag: {
						data: { name },
						// soft insert
						on_conflict: { constraint: "tag_name_key", update_columns: "dumb" },
					}
				})),
			},
			update(cache, { data }) {
				cache.updateQuery({
					query: SHOP_TAGS_QUERY,
					variables: { shopId },
				}, () => ({
					shopTagRelationship: data.insert_shopTagRelationship.returning
				}));
			}
		});
}

export const SHOP_TAGS_MUTATION = gql`
	mutation persist_shop_tags($shopId: bigint!, $shopTags: [shopTagRelationship_insert_input!]!){
		delete_shopTagRelationship(where: {shopId: {_eq: $shopId}}) {
			affected_rows
		}
		
		insert_shopTagRelationship(
			on_conflict: {constraint: shopTagRelationship_shopId_tagId_key, update_columns: index},
			objects: $shopTags
		) {
			affected_rows
			returning {
				id
				index
				tag {
					id
					name
				}
			}
		}
	}
`;