export default function updateMultipleEntries(load, predicate, update, persist){
	let allEntries = load();
	let entriesToUpdate = allEntries.mapFiltering(
		entry => {
			entry.content = update(entry.content);
			return entry;
		},
		({content}) => predicate(content),
	);

	persist(entriesToUpdate);
}
