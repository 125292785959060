import Base from "../../../../class/Base"
import {Retailer} from "../../entity"
import VShop from "../general/VShop"

export default class Me extends Base {
	get id(){
		console.warn('Replace me.id by me.shopId');
		return this.shopId;
	}

	get shopId(){
		return this.shop?.id;
	}

	get vShop(){
		return this.shop;
	}
}
Me.addProperties({
	retailer : Retailer,
	shop : VShop,
});
