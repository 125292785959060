import use from "../../../hook";
import timeout from "../../../library-js/utils/timeout";
import abstractComponent from "../abstractComponent";

function inputComponent(render, config) {
	if (!config)
		config = {};

	config.forceValueTimeout = Number(config.forceValueTimeout);
	if (!(config.forceValueTimeout >= 0))
		config.forceValueTimeout = 100;

	function InputComponent({value: forcedValue, defaultValue, onValueChanged, ...props}, ...rest) {
		const isValueForced = forcedValue !== undefined;

		let [value, setValue] = use.state(
			isValueForced ? forcedValue :
				defaultValue !== undefined ? defaultValue :
					config?.defaultValue
		);

		const memory = use.memo({});
		memory.onValueChanged = onValueChanged;
		use.onChange(
			function notify() {
				const {onValueChanged} = memory;
				if (onValueChanged && value !== forcedValue)
					onValueChanged(value);
			},
			[value],
		);

		// set forced value
		/// Should be after onValueChanged callback
		use.syncEffect(() => {
				if (isValueForced)
					return timeout(() => setValue(forcedValue), config.forceValueTimeout);
			},
			[value, forcedValue],
		);

		props.value = value;
		props.onValueChanged = setValue;

		return render(props, ...rest);
	}

	// prevents React.forwardRef error log
	Object.defineProperties(InputComponent, {
		length: {
			configurable: true,
			writable: true,
			value: 2,
		}
	});

	return InputComponent;
}

export default abstractComponent(inputComponent);
