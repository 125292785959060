import React from "react"
import Dialog from "@main/component/Dialog";
import Form from "./Form";
import use from "library-react/hook";
import {styles} from "@main/res";


export default function DesktopUI({onSuccess, ...props}) {
	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<Dialog {...props}>
			<Form onSuccess={onSuccess} contentContainerStyle={localStyles.content}/>
		</Dialog>
	);
}

const localStyles = {
	layout: {
		backgroundColor: styles.color.white,
		borderWidth: .5,
		borderColor: '#cfd0d3',
		...styles.newPaddings(0, 0, 0, 0),
	},

	content: {
		padding: 40,
	}
}
