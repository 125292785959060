import React from "react"
import { ScrollView, Keyboard } from "react-native"
import use from "../../../../../../hook";
import View from "../../../../../../component/View/v2";
import Icon from "../../../../../../component/Icon/v2";
import Search from "../../../../../../library-js/Search";
import Text from "../../../../../../component/Text";
import { styles } from "../../../../../../res";
import ProductCard from "./ProductCard";

function SelectProduct({ of: shopId, onSelected, onSearchClicked, ...props }) {
	const iterator = use.infiniteIterator(
		() => Search.index.prodshop.getIterator({ available: true, shopId, inShop: true }),
		[shopId],
	);

	// code block to test discount and brand
	// if (iterator.items.first){
	// 	const e = iterator.items.first;
	// 	e.prodshop.discount = new Price(99.99);
	// 	e.product.brand = 'Adidas';
	// }

	use.effect(() => Keyboard.dismiss());

	const content = ((iterator.items.length === 0) && iterator.loading) ?
		[null, null] // placeholders
		: iterator.items;

	props.style = use.defaultStyle(props.style, localStyles.layout);
	props.contentContainerStyle = localStyles.content;

	return (
		<ScrollView {...props}>
			{/* search button */}
			<View onPress={onSearchClicked} style={[localStyles.item, localStyles.search]}>
				<Icon
					name="search"
					size={25}
					style={localStyles.searchIcon}
				/>
				<Text style={localStyles.searchLabel}>
					{`Recherche\ncatalogue`}
				</Text>
			</View>

			{content.map((item, index) => <ProductCard key={index} item={item} onSelected={onSelected} style={localStyles.item} />)}
		</ScrollView>
	);
}

export default React.memo(SelectProduct);

const localStyles = {
	layout: {
		height: 235,
	},

	content: {
		paddingVertical: 10,
		flexDirection: styles.flexDirection.row,
		flexWrap: styles.flexWrap.wrap,
		justifyContent: styles.justifyContent.spaceEvenly,
	},

	item: {
		marginVertical: 6,
		marginHorizontal: 4.5,
		backgroundColor: styles.color.white,
		borderRadius: 8,
		...styles.newShadow(0, 0, 5, .15),
		width: 112,
	},

	search: styles.center,

	searchIcon: {
		color: styles.color.black,
		marginBottom: 11,
	},

	searchLabel: {
		...styles.text(9, '#1e2432', undefined, 13, true),
		textAlign: styles.textAlign.center,
	},
};
