import Base from "../../../../class/Base"
import KYCDocumentType from "./KYCDocumentType";
import DocumentStatus from "./DocumentStatus";
import KYCDocRefusedReasonType from "./KYCDocRefusedReasonType";

export default class KYCDocument extends Base {
}
KYCDocument.addProperties({
	Type: KYCDocumentType,
	UserId: String,
	Id: String,
	Tag: String,
	CreationDate: Number,
	ProcessedDate: Number,
	Status: DocumentStatus,
	RefusedReasonType: KYCDocRefusedReasonType,
	RefusedReasonMessage: String,
});

