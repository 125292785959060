import React from "react";
import styles from "@main/res/styles";
import View from "library-react/component/View";
import Image from "library-react/component/Image";
import use from "library-react/hook";
import adaptSize from "../adaptSize";
import useApp from "library-react/app/useApp";
import GalleryModal from "library-react/component/GalleryModal";

export default function Gallery(
	{
		items,
		direction,
		resizeMode = 'contain',
		innerStyles = {
			main: null,
			items: null,
		},
		onPress,
		enableFullScreen,
		alt,
		...props
	}
) {
	// selected index
	let [selected, setSelected] = use.state(0);

	// correct items
	if (!items)
		items = [];

	// correct direction
	direction = String(direction).toLowerCase();
	if (!Gallery.Direction[direction])
		direction = Gallery.Direction.vertical;

	// correct selected index
	let correction;
	if (!(selected >= 0))
		correction = 0;
	else if (selected >= items.length)
		correction = items.lastIndex;

	const corrected = correction >= 0;

	use.effect(
		corrected
		&& (() => setSelected(correction)),
		[selected, correction]
	);

	if (corrected)
		selected = correction;

	const app = useApp();
	const openFullscreenGallery = (index) => app.openModal(<GalleryModal items={items} startIndex={index}/>);

	props.onPress = () => {
		if (onPress)
			onPress();

		if (enableFullScreen)
			openFullscreenGallery(selected);
	}

	props.style = use.defaultStyle(props.style, localStyles.layout(direction), [direction]);
	const mainStyle = use.defaultStyle(innerStyles?.main, localStyles.main);

	return (
		<View {...props}>
			<Image
				source={items[selected]}
				resizeMode="contain"
				style={mainStyle}
				alt={alt}
			/>

				<View data-class='noScrollBar'
					  style={localStyles.items.layout(direction)}>
					{
						items.map((source, index) => {
							const isSelected = (index === selected);

							return (
								<View
									key={index}
									style={localStyles.items.item(direction)}>
									<Image
										source={source}
										resizeMode="cover"
										style={styles.fit}
									/>

									<View
										onPress={!isSelected && (() => setSelected(index))}
										style={localStyles.items.filter(isSelected)}/>
								</View>
							);
						})
					}
				</View>
		</View>
	);
};

Gallery.Direction = {
	vertical: "vertical",
	horizontal: "horizontal",
};

const localStyles = {
	layout: styles.static(
		{},
		{
			vertical: {},
			horizontal: {flexDirection: styles.flexDirection.rowReverse},
		}
	),

	main: {
		flex: 1,
	},

	items: {
		layout: styles.static(
			{overflow: styles.overflow.auto,},
			{
				vertical: {
					height: adaptSize(130),
					marginTop: adaptSize(15),
					paddingLeft: adaptSize(18),
					flexDirection: styles.flexDirection.row
				},

				horizontal: {
					width: adaptSize(260),
					paddingRight: adaptSize(25),
					flexDirection: styles.flexDirection.column
				}
			},
		),


		item: styles.static(
			{},
			{
				vertical: {
					width: adaptSize(104),
					marginRight: adaptSize(32),
				},

				horizontal: {
					height: adaptSize(243),
					marginBottom: adaptSize(20),
				}
			},
		),

		filter: styles.static.bool(
			styles.fit,
			{backgroundColor: "rgba(255,255,255,0.4)"}
		),
	}
};
