import Base from "../../../class/Base";
import {Enum} from "../../../class";
import {__, all, allPass, apply, bind, call, filter, gt, keys, length, map, mapObjIndexed, not, pipe} from "ramda";
import simpleValidate from "../../../utils/simpleValidate";

class Pin extends Base {
	toJSON() {
		const json = super.toJSON();
		json.type = this.constructor.type;
		return json;
	}

	static from(json, ...params){
		if (json)
			delete json.type;
		return super.from(json, ...params);
	}

	getInvalidFields(){
		return pipe(
			map(pipe(call, bind(__, this))),
			mapObjIndexed((validate, key) => [validate, this[key]]),
			map(apply(call)),
			filter(not),
			keys,
		)(this.constructor.validator);
	}
}

export class ShowcasePin extends Pin {
	static type = "ShowcasePin";
	static Size = Enum.make(['M', 'L']);
	static validator = {
		id: () => Boolean,
		size: () => Boolean,
	};
}

ShowcasePin.addProperties({
	id: Number,
	size: ShowcasePin.Size,
});

export class SectionPin extends Pin {
	static type = "SectionPin";
	static validator = {
		name: () => simpleValidate.text,
	};

	get displayTitle() {
		return this.title || this.name;
	}
}

SectionPin.addProperties({
	title: String,
	name: String,
});

export class BrandPin extends Pin {
	static type = "BrandPin";
	static validator = {
		name: () => simpleValidate.text,
	};
}

BrandPin.addProperties({
	name: String,
});

export class BrandListPin extends Pin {
	static type = "BrandListPin";
	static validator = {
		title: () => simpleValidate.text,
		names: () => allPass([
			pipe(length, gt(__, 0)),
			all(simpleValidate.text),
		]),
	};
}

BrandListPin.addProperties({
	title: String,
	names: {
		type: Array,
		template: String,
	}
});


export default [ShowcasePin, SectionPin, BrandPin, BrandListPin]
	.toObject(Pin => Pin.type);


