import React from "react"
import View from "library-react/component/View/v2";
import {styles} from "@main/res";
import ComponentUtils from "library-react/ComponentUtils";
import StarsView from "library-react/component/StarsView";
import use from "library-react/hook";

function StarsList({rate, ...props}) {
	const total = rate?.numberOfVotes || 0;

	props.style = use.defaultStyle(props.style, localStyles.layout);
	return (
		<View style={localStyles.layout}>
			<Stars numberOfVotes={rate.star5} rate={5} total={total} style={localStyles.star}/>
			<Stars numberOfVotes={rate.star4} rate={4} total={total} style={localStyles.star}/>
			<Stars numberOfVotes={rate.star3} rate={3} total={total} style={localStyles.star}/>
			<Stars numberOfVotes={rate.star2} rate={2} total={total} style={localStyles.star}/>
			<Stars numberOfVotes={rate.star1} rate={1} total={total}/>
		</View>
	);
}

export default React.memo(StarsList);

function Stars({rate, numberOfVotes, total, ...props}) {
	const {Text} = use.theme();
	props.style = ComponentUtils.defaultStyle(props.style, localStyles.item.layout);
	const isMobile = use.context.display().is.mobile;

	return (
		<View {...props}>
			<StarsView
				useEyalStyle
				size={isMobile ? 9 : 16}
				stars={rate}
			/>
			<View style={localStyles.item.percentageBar(isMobile)}>
				<View style={{backgroundColor: '#fec72e', width: `${Math.min(numberOfVotes * 100 / total, 100) || 0}%`}}/>
			</View>
			<Text style={localStyles.item.text(isMobile)}>
				{numberOfVotes || 0}
			</Text>
		</View>
	)
}

const localStyles = {
	item: {
		layout: {
			flexDirection: styles.flexDirection.row,
			alignItems: styles.alignItems.center,
		},
		starsProps: {
			size: 16
		},
		percentageBar: styles.static.bool({
				flexDirection: styles.flexDirection.row,
				backgroundColor: '#f7f7f7'
			},
			{
				width: 92,
				height: 8,
				marginLeft: 8,

			},
			{
				width: 151,
				height: 12,
				marginLeft: 14,

			}
		),

		text: styles.static.bool(
			{},
			{
				fontSize: 12,
				marginLeft: 12,
			},
			{
				fontSize: 13,
				marginLeft: 20,
			}
		),
	},
	star: {marginBottom: 22},
	layout: {},
};
