import useLoadable from "./loadable";

export default function (load, dependencies) {
	return useLoadable(
		load &&
		(async (...params) => {
			const response = await load(...params);
			if (response.ok)
				return response.content;

			throw response.toJSON();
		}),
		dependencies,
	);
};
