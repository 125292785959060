import Enum from "./class/Enum"
import Debug from "./Debug"

/**
 * Enum which indicates which platform the app is.
 * Set it in the current property of the module.
 */
const Platform = new Enum("user", "retailer", "marketer");
export default Platform;

const [whenReady, resolveReady] = Promise.external();
Platform.whenReady = whenReady;

let current;
Object.defineProperties(Platform, {
	current: {
		configurable: true,
		get: () => current,
		set: platform => {
			Debug.assert(current, "Platform.current was already set", true);

			platform = Platform.from(platform);
			Debug.assert(!platform, "Platform.current must be an enum value of Platform.", true);

			current = platform;
			resolveReady(platform);
		}
	}
});


Object.defineProperties(Platform,
	['is', 'select', 'run']
		.reduce((properties, fieldName) => {
			properties[fieldName] = {
				configurable: true,
				get: () => current[fieldName],
			};

			return properties;
		}, {}),
);
