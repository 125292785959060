import React from "react"
import { curry } from "ramda";
import useDefaultStyle from "../hook/useDefaultStyle";

function withDefaultStyle(defaultStyle, Component) {
	return function WithDefaultStyle({...props}) {
		props.style = useDefaultStyle(props.style, defaultStyle);
		return <Component {...props} />;
	}
}

export default curry(withDefaultStyle);