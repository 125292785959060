import Platform from "../../../../Platform";
import Server from "../../../Server";
import HTTPRequest from "../../../../network/HTTPRequest";
import {is} from "../../../../utils";
import {Product} from "../../entity";
import {VOrder, VProdshop} from "../../view";

export default {
	send(recipient, text, attachment){
		let url = "/chat/send/";
		const params = {recipient, text};

		switch(is(attachment)){
			case VOrder.Item:
				url += "OrderItemMessage";
				params.itemId = attachment.id;
				break;

			case VProdshop: // accept  VProdshop
				attachment = attachment.product;

			case Product:
				url += "ProductMessage";
				params.productId = attachment.id;
				break;

			default:
				url += "TextMessage";
		}

		return newRequest(url, HTTPRequest.Methods.PUT)
			.setParameters(params)
			.send();
	},

	onRead(interlocutor){
		return newRequest("/chat/read")
			.setParameters({interlocutor})
			.send();
	}
}


function newRequest(path, method){
	const API = Platform.select({
		retailer: Server.retailer,
		default: Server.user,
	});

	return new API.Request(path, method)
		.needsAuth();
}
