import React from "react"
import View from "library-react/component/View/v2";
import use from "library-react/hook"
import {styles} from "@main/res"
import {Linking, ScrollView} from "react-native";
import Icon from "library-react/component/Icon/v2";
import ShopLogo from "@main/component/ShopLogo";
import MenuItem from "./MenuItem";
import BasketButton from "../../BasketButton";
import useAuth from "library-react/hook/useAuth";
import ChatCounterLabel from "@main/component/ChatLabelCounter";
import {Objects} from "library-js/utils";
import ByShopinzon from "../ByShopinzon";
import Portal from "library-react/component/Portal";
import {screens} from "@main/links";
import useApp from "library-react/app/useApp";
import globals from "@main/globals";

export default function SideBar({menuItems, navigationItems, onAccountClicked, ...props}) {
	const {shop} = use.context.infos().vShop;
	const user = useAuth.user();

	const app = useApp();

	const {Text} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<View {...props}>
			{/* Shop's logo */}
			<Portal to={screens.home}>
				<ShopLogo style={localStyles.logo}/>
			</Portal>

			{/* Actions */}
			<View style={localStyles.actions}>
				<Icon
					name='account'
					size={27}
					onPress={onAccountClicked}
					style={localStyles.account}
				/>

				<Icon
					name='chat'
					size={27}
					onPress={app.openChat}
					style={localStyles.chat.icon}>
					<ChatCounterLabel
						textStyle={localStyles.chat.label.text}
						style={localStyles.chat.label.layout}
					/>
				</Icon>


				{
					shop.ordersEnabled &&
					<BasketButton
						size={27}
						style={localStyles.basket}
					/>
				}

				{/* Search */}

				<Portal to={screens.search}>
					<Icon
						name='search'
						size={23}
						style={localStyles.search}
					/>
				</Portal>
			</View>


			{/* Menu */}
			<ScrollView
				style={localStyles.menu.layout}
				contentContainerStyle={localStyles.menu.container}>

				{/* Navigation options */}
				<View style={localStyles.menu.navigation.layout}>
					{menuItems.map((item) => <MenuItem {...item}/>)}
				</View>

				<View style={localStyles.menu.navigation.navigationItems}>
					{navigationItems.map((item) => <MenuItem {...item}/>)}
				</View>
			</ScrollView>

			{/* footer */}
			<View style={localStyles.footer}>
				{
					user &&
					<Text
						numberOfLines={1}
						style={[localStyles.menu.annex.item, {color: "#395aff", fontSize: 10}]}>
						{`BONJOUR `}{user?.displayName?.toUpperCase()}
					</Text>
				}

				<Text
					onPress={onAccountClicked}
					numberOfLines={1}
					style={localStyles.menu.annex.item}>
					{user ? `MON COMPTE` : `SE CONNECTER`}
				</Text>

				<Text
					numberOfLines={1}
					{...Linking.linkPropsFor(`mailto:${shop.email}`)}
					style={localStyles.menu.annex.item}>
					{`CONTACT`}
				</Text>
				<Text
					numberOfLines={1}
					{...Linking.linkPropsFor(globals.supportContactUrl)}
					style={localStyles.menu.annex.item}>
					{`SUPPORT`}
				</Text>
				<Text
					numberOfLines={1}
					onPress={() => window.open('https://shopinzon.com/legal-agreement', "_blank")}
					style={localStyles.menu.annex.item}>
					{`MENTIONS LÉGALES`}
				</Text>

				<View style={localStyles.menu.annex.social}>
					{
						Objects.setsOf(shop.socialLinks)
							.map(({key: network, value: link}) =>
								<Icon key={network}
									  name={network}
									  size={30}
									  style={localStyles.menu.annex.icon}
									  {...Linking.linkPropsFor(link)}
								/>
							)
					}
				</View>

				<ByShopinzon corporation style={localStyles.byShopinzon}/>
			</View>

		</View>
	);
}

export const SIDEBAR_WIDTH = 330;


const localStyles = {
	layout: {
		flexBasis: SIDEBAR_WIDTH,
		flexGrow: 0,
		flexShrink: 0,

		borderRightWidth: 1,
		borderColor: '#e4e4e4',
	},

	logo: {
		marginTop: 30,
		margin: 15,
		height: 120,
		marginBottom: 50,
	},

	actions: {
		flexDirection: styles.flexDirection.row,
		alignItems: styles.alignItems.center,
		justifyContent: styles.justifyContent.center,
		marginBottom: 30,
	},

	account: {
		color: styles.color.black,
	},

	chat: {
		icon: {
			color: styles.color.black,
			marginLeft: 20,
		},

		label: {
			layout: {
				...styles.circle(20),
				...styles.center,
				...styles.absolute({bottom: -3, right: -3}),
			},

			text: {
				fontSize: 11,
				lineHeight: 10,
			}
		}
	},

	basket: {
		marginLeft: 20,
	},

	search: {
		color: styles.color.black,
		marginLeft: 20,
	},

	menu: {
		layout: {flex: 1},

		container: {
			flexGrow: 1,
			paddingTop: 35,
			paddingBottom: 40,
		},

		navigation: {
			navigationItems: {
				marginTop: 20,
			},

			item: {
				fontSize: 15,
				bold: true,
				paddingVertical: 10,
				paddingRight: 48,
			}
		},

		annex: {
			item: {
				paddingVertical: 5,
				paddingLeft: 32,
				fontSize: 12,
			},

			social: {
				paddingTop: 8,
				paddingLeft: 32,
				flexDirection: styles.flexDirection.row,
			},
			icon: {
				marginRight: 9,
			}
		},
	},

	footer: {
		paddingBottom: 30,
	},

	byShopinzon: {
		marginLeft: 32,
		marginTop: 20,
	}
};
