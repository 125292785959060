import React from "react"
import use from "../../../hook";
import Text from "../../../component/Text";
import { fonts } from "../../../res";

export default function Welcome({ interlocutorLoadable, children, ...props }) {
	use.subscribe(interlocutorLoadable.onStateChanged, [interlocutorLoadable]);

	const { value: interlocutor } = interlocutorLoadable;

	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		Boolean(interlocutor) &&
		<Text {...props}>
			{children}
			<Text style={localStyles.name}>
				{` ${interlocutor.name}`}
			</Text>
		</Text>
	);
}

const localStyles = {
	layout: {
		color: '#1e2432',
		font: fonts.Poppins,
		fontSize: 14,
	},
	name: {
		color: '#452ee0'
	}
};
