import Base from "../class/Base"
import Debug from "../Debug"
import is from "../utils/is"
import Distance from "./Distance"
import {complement, isNil} from "ramda";
import retrieveInvalidFields from "../utils/formValidator/retrieveInvalidFields";

/**
 * Location with a latitude, longitude and a place name.
 * @param latitude Latitude coordinate.
 * @param longitude Longitude coordinate.
 * @param accuracy Location accuracy.
 * @constructor
 */
export default class Location extends Base {
	constructor(latitude, longitude, accuracy) {
		super();
		this.latitude = latitude;
		this.longitude = longitude;
		this.accuracy = accuracy;
	}

	get lat(){
		return this.latitude;
	}

	set lat(latitude){
		this.latitude = latitude;
	}

	get lng(){
		return this.longitude;
	}

	set lng(longitude){
		this.longitude = longitude;
	}

	to(location){
		Debug.assert(!is(location, Location), "Location.to takes a Location instance as parameter.", true);
		return new Distance(Location.calculateDistance(this, location));
	}

	toLatLng(){
		return {lat: this.latitude, lng: this.longitude};
	}

	toString(){
		return `${this.latitude},${this.longitude}`;
	}

	isValid(){
		return !retrieveInvalidFields(Location.validator, this).length;
	}

	static calculateDistance(from, to){
		// check
		Debug.assert(!(from && to), "A coordinate is missing to calculate coordinates.");

		// get coordinates
		let latitude1 = from.lat || from.latitude;
		let longitude1 = from.lng || from.longitude;
		let latitude2 = to.lat || to.latitude;
		let longitude2 = to.lng || to.longitude;

		// check
		Debug.assert(!(latitude1 && longitude1 && latitude2 && longitude2), "A coordinate is missing to calculate coordinates.");

		// start
		let R = 6371e3; // metres
		let φ1 = toRadians(latitude1);
		let φ2 = toRadians(latitude2);
		let Δφ = toRadians(latitude2-latitude1);
		let Δλ = toRadians(longitude2-longitude1);

		let a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
			Math.cos(φ1) * Math.cos(φ2) *
			Math.sin(Δλ/2) * Math.sin(Δλ/2);
		let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

		return R * c;
	}
}

Location.addProperties({
	latitude : Number,
	longitude : Number,
	accuracy : Number,
});

Location.validator = {
	latitude: () => complement(isNil),
	longitude: () => complement(isNil),
};


const toRadians = number =>  number * Math.PI / 180;
