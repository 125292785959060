import React, {useMemo, useState} from "react"
import {View as RNView} from "react-native"
import ToastContext from "./ToastContext";
import ToastChannel from "./ToastChannel";
import {append, is} from "ramda";
import desktopToastConfig from "./premade-configs/desktopConfig";

export default function ToastContainer({children, config: rootConfig, DefaultToast, ...props}){
	const stackState = useState([/* {element, config} */]);
	const [, setStack] = stackState;

	const context = useMemo(() => {
		return {
			show(element, elementConfig){
				const id = ++nextEntryId;
				const config = Object.assign({}, DEFAULT_CONFIG, context.rootConfig, elementConfig);
				if (!is(Object, element))
					element = (
						<DefaultToast>
							{element}
						</DefaultToast>
					);

				setStack(
					append({
						id,
						element,
						config,
					})
				);

				return {
					// TODO
					// close()
					// replace(newElement = element, newConfig = config)
				};
			}
		};
	}, [DefaultToast]);
	context.rootConfig = rootConfig;

	const [frameLayout, setFrameLayout] = useState();
	props.onLayout = event => setFrameLayout(event.nativeEvent.layout);

	props.style = useMemo(() => [{flex: 1}, props.style].flat(), [props.style]);

	return (
		<ToastContext.Provider value={context}>
			<RNView {...props}>
				{children}

				<ToastChannel
					stackState={stackState}
					frameLayout={frameLayout}/>
			</RNView>
		</ToastContext.Provider>
	);
}

let nextEntryId = 0;
const DEFAULT_CONFIG = desktopToastConfig;
