import { basketCheckoutLinking } from "library-react/fragment/checkout/BasketCheckoutUI";
import { orderCheckoutLinking } from "library-react/fragment/checkout/OrderCheckoutUI";

// ----

export const screens = {
	home: 'home',
	catalog: 'catalog',
	product: 'product',
	shop: 'shop',
	account: 'account',
	orderList: 'orderList',
	order: 'order',
	brandList: 'brandList',
	brand: 'brand',
	showcaseList: 'showcaseList',
	showcase: 'showcase',
	reservationList: 'reservationList',
	search: 'search',
	basket: 'basket',
	checkout: 'checkout',
	orderCheckout: 'orderCheckout',
	paymentResult: 'paymentResult',
};

export const paths = {
	home: '',
	product: 'product',
	shop: 'shop',
	account: 'account',
	order: 'order',
	basket: 'basket',
	brand: 'brand',
	showcase: 'showcase',
	search: 'search',
	checkout: 'checkout',
	reservation: 'reservation',
	paymentResult: '_paymentResult',
};


export const linking = {
	config: {
		screens: {
			home: paths.home,
			catalog: paths.product,
			product: `${paths.product}/:id`,
			shop: `${paths.shop}`,
			search: paths.search,
			brandList: paths.brand,
			brand: {
				path: `${paths.brand}/:name`,
				parse: {
					name: decodeURIComponent,
				},
			},
			showcaseList: paths.showcase,
			showcase: `${paths.showcase}/:id`,

			reservationList: paths.reservation,
			reservation: `${paths.reservation}/:id`,

			//Authenticated screens
			account: paths.account,
			basket: paths.basket,
			checkout: basketCheckoutLinking,
			orderList: paths.order,
			order: `${paths.order}/:id`,
			orderCheckout: orderCheckoutLinking,
			paymentResult: paths.paymentResult,
		}
	},
};

export type RootParamList = {
	order: {id: number},
	paymentResult: {orderId: number},
};