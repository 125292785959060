import Environment from "../library-js/Environment";
import {LayoutAnimation, UIManager} from "react-native";

export default function animateNextRender(){
	if (!activated){
		UIManager.setLayoutAnimationEnabledExperimental?.(true);
		activated = true;
	}

	return new Promise((resolve, reject) => LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut, resolve, reject));
}

let activated = !Environment.is.android;
