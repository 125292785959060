import Base from "../../../../class/Base"
import UBODeclarationStatus from "./UBODeclarationStatus";
import UBO from "./UBO";
import UBOReasonType from "./UBOReasonType";

export default class UBODeclaration extends Base {
}

UBODeclaration.addProperties({
	Id: String,
	CreationDate: Number,
	ProcessedDate: Number,
	Status: UBODeclarationStatus,
	Reason: UBOReasonType,
	Message: String,
	Ubos: {
		type: Array,
		template: UBO
	}
});

