import React from "react"
import {View as RNView} from "react-native"
import use from "library-react/hook"
import {styles} from "@main/res"
import Icon from "library-react/component/Icon/v2";
import CommentList from "./CommentList";
import CommentForm from "./Form"

export default function CommentsModalDesktop({title, subtitle, name, rateLoadable, myCommentLoadable, iterator, editMode, setEditMode, persist, close, ...props}) {
	const {Text, Dialog} = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout);

	return (
		<Dialog {...props}>
			{/* head */}
			<RNView style={localStyles.head}>
				<Text style={localStyles.title}>
					{title}
				</Text>

				{
					subtitle &&
					<Text style={localStyles.subtitle}>
						{subtitle}
					</Text>
				}
			</RNView>

			{
				editMode ?
					<CommentForm
						persist={persist}
						loadable={myCommentLoadable} /> :
					<CommentList
						name={name}
						rateLoadable={rateLoadable}
						iterator={iterator}
						contentContainerStyle={localStyles.list.container}
						setEditMode={setEditMode}
						style={localStyles.list.layout}
					/>
			}

			{
				close &&
				<Icon
					name="EvilIcons/close"
					size={30}
					onPress={close}
					style={localStyles.close}
				/>
			}
		</Dialog>
	);
}

const localStyles = {
	layout: {
		paddingHorizontal: 0,
		paddingVertical: 0,
		backgroundColor: styles.color.white,
		maxHeight: "80%",
		width: 1000,
		maxWidth: "100%",
	},

	head: {
		paddingVertical: 30,
	},

	title: {
		fontSize: 28,
		color: styles.color.black,
		bold: true,
		textAlign: styles.textAlign.center,
	},

	subtitle: {
		fontSize: 18,
		color: "#504747",
		textAlign: styles.textAlign.center,
	},

	list: {
		layout: {},
		container: {
			paddingTop: 60,
		}
	},

	close: {
		position: styles.position.absolute,
		top: 0,
		right: 0,
		padding: 20,
		color: 'grey'
	}
};
