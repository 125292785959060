import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Animated} from "react-native";
import {adjust, assoc, complement, filter, flatten, map, pipe, prop, propEq, remove} from "ramda";

const debounce = require('debounce');


function ToastCard(
	{
		id,
		element,
		config,
		index,
		stack,
		setStack,
		position,
		frameLayout,
		...props
	}
) {
	const validPosition = !isNaN(position);
	const positionAV = useMemo(() =>
			validPosition ? new Animated.Value(position)
				: undefined,
		[validPosition]
	);

	useEffect(() => {
		if (positionAV) {
			const positionAnimation = config.positionAnimation(positionAV, position);
			positionAnimation.start();
			return () => positionAnimation.stop();
		}
	}, [position]);

	const adjustMyEntry = useCallback(adjustment =>
			setStack(adjust(index, adjustment)),
		[index]
	);

	const setLayout = useMemo(() =>
			debounce(layout => adjustMyEntry(assoc('layout', layout)),
				50,
			),
		[adjustMyEntry],
	);

	props.onLayout = event => setLayout(event.nativeEvent.layout);

	const openingAV = useMemo(() => new Animated.Value(0), []);

	const [closing, setClosing] = useState(false);
	useEffect(() => {
		const timoutKey = setTimeout(() => setClosing(true), config.duration);
		return () => clearTimeout(timoutKey);
	}, []);

	useEffect(() => {
		if (positionAV) {
			const openingAnimation = config.openingAnimation(openingAV, closing ? 0 : 1);
			openingAnimation.start(() => {
				if (closing)
					setStack(filter(complement(propEq('id', id))));
			});
			return () => openingAnimation.stop();
		}
	}, [positionAV, closing]);

	props.style = useMemo(() => {
		const styles = [
			props.style,
			config.cardStyle(openingAV, positionAV),
		].flat();

		const transform = pipe(
			map(prop('transform')),
			flatten,
			filter(Boolean),
		)(styles);

		return styles.concat([{transform}]);
	}, [
		openingAV,
		positionAV,
		config.cardStyle,
		props.style,
	]);

	return (
		<Animated.View {...props}>
			{element}
		</Animated.View>
	);
}

export default React.memo(ToastCard);
