import React from "react";
import { Pressable } from "react-native";
import GradientView from "./GradientView";
import { fonts, styles } from "../res";
import is from "../library-js/utils/is";
import use from "../hook";
import { TextStyleContext } from "./Text/v2";

/**
 * @param {{
 * 		filled?: any,
 * 		loading?: any,
 * } & import("react-native").PressableProps}
 */
export default function MainButton({ children, filled, loading, ...props }) {
	if (filled === undefined)
		filled = !props.disabled;

	if (loading)
		props.disabled = true;

	const { Text, Spinner } = use.theme();
	props.style = use.defaultStyle(props.style, localStyles.layout, [!filled]);

	return (
		<Pressable {...props}>
			{
				filled &&
				<GradientView
					colors={props.disabled ? DISABLED_COLORS : COLORS}
					{...styles.gradient.vertical}
					style={localStyles.gradient} />
			}

			<TextStyleContext.Provider value={localStyles.text(filled)}>
				{
					loading ?
						<Spinner size={22} /> :
						is(children, String) ?
							<Text style={{ zIndex: 1 }}>
								{children}
							</Text> :
							children
				}
			</TextStyleContext.Provider>
		</Pressable>
	);
};

const COLORS = ['#6200ea', '#1976d2'];
const DISABLED_COLORS = ['#a7a7a7', '#c0c0c0'];


const localStyles = {
	layout: enabled => ({
		borderRadius: 8,
		overflow: 'hidden',
		minHeight: 55,
		padding: 10,
		justifyContent: 'center',
		borderWidth: enabled ? 1 : 0,
		borderColor: 'lightgrey',
	}),

	text: styles.static.bool({
		textAlign: 'center',
	},
		{
			color: 'white',
			bold: true,
		}
	),

	gradient: styles.fit,
};
