import Entity from "../Entity";
import {Enum} from "../../../../class";
import VProdshop from "../../view/general/VProdshop";
import Price from "../../Price";

export default class OrderItem extends Entity {
	get product(){
		return this.vProdshop?.product;
	}

	get shop(){
		return this.vProdshop?.shop;
	}

	get prodshop(){
		return this.vProdshop?.prodshop;
	}

	get unitPrice(){
		return this.prodshop?.finalPrice;
	}

	get canceled(){
		return this.state === OrderItem.State.canceled;
	}

	get ready(){
		return this.state === OrderItem.State.ready;
	}

	get pending(){
		return this.state === OrderItem.State.pending;
	}

	get cancelable(){
		return this.pending;
	}
};

OrderItem.State = new Enum({
	canceled: -1,
	pending: 0,
	ready: 1,
});

OrderItem.addProperties({
	orderId: Number,
	size : String,
	quantity: Number,
	state: OrderItem.State,

	vProdshop : VProdshop,
	price : Price,

	creationDate : Number,
});
