import React from "react"
import {View as RNView} from "react-native";
import use from "../../../../../../../hook";
import {styles} from "../../../../../../../res";
import GradientView from "../../../../../../../component/GradientView";

export default React.memo(
	function MessageLayout({message, ...props}) {
		props.style = use.defaultStyle(props.style, localStyles.layout, [message.isAuthor]);
		return message.isAuthor ?
			<GradientView {...styles.gradient.vertical} colors={GRADIENT_COLORS} {...props}/>
			: <RNView {...props}/>;
	}
)

const GRADIENT_COLORS = ['#1976d2', '#6200ea'];

const localStyles = {
	layout: styles.static.bool(
		{
			borderRadius: 25,
			maxWidth: '75%',
			...styles.newPaddings(13, 16, 8, 20),
		},

		{
			alignSelf: styles.alignSelf.flexEnd,
			borderBottomRightRadius: 0,
		},

		{
			backgroundColor: '#f7f7f7',
			alignSelf: styles.alignSelf.flexStart,
			borderBottomLeftRadius: 0,
		},
	),
};
