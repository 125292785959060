import React from "react"
import { View as RNView } from "react-native"
import use from "../../hook"
import Price from "../../library-js/app/model/Price";
import { styles } from "../../res"
import Text from "../Text";

/**
 * @param {{
 * 	price: Price,
 * 	discounted?: any,
 * 	textStyle?: import("react-native").TextStyle,
 * } extends import('react-native').ViewProps}
 */
export default function MainPrice({ discounted, price, textStyle, ...props }) {
	discounted = Boolean(discounted);
	textStyle = use.defaultStyle(textStyle, localStyles.text, [discounted]);
	props.style = use.defaultStyle(props.style, localStyles.layout, [discounted]);
	return (
		<RNView {...props}>
			<Text
				numberOfLines={1}
				style={textStyle}>
				{price?.value}
				<Text style={localStyles.currency}>
					{price?.symbol}
				</Text>
			</Text>
		</RNView>
	);
}

const localStyles = {
	layout: styles.static.bool({
		paddingHorizontal: 6,
		paddingVertical: 1,
		borderRadius: 15,
	},
		{ backgroundColor: '#e95454' },
	),

	text: styles.static.bool({
		fontSize: 15,
		bold: true,
		lineHeight: 19,
	},
		{ color: styles.color.white },
		{ color: '#1e2432' }
	),

	currency: {
		fontSize: 7,
	}
};
