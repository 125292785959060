import useIsMounted from "./useIsMounted";
import useMemo from "./useMemo";

export default function useAsyncCallback(factory, dependencies){
	const isMounted = useIsMounted();
	return useMemo(
		() => factory(() => !isMounted()),
		[dependencies],
	);
}
