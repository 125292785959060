import React from "react";
import MobileUI from "./mobile"
import DesktopUI from "./desktop"
import use from "library-react/hook";
import Search from "library-js/Search"
import SEO from "library-react/component/SEO";

function SearchUI({query: initialQuery, ...props}) {
	const [query, setQuery] = use.state(initialQuery || '');
	props.onQueryChanged = setQuery;

	const {vShop} = use.context.infos();
	props.iterator = use.infiniteIterator(
		shopId => (
			Search.index.prodshop.getIterator({available: true, shopId, inShop: true, query})
		),
		[vShop.shop.id, query],
	);

	const display = use.context.display();
	return (
		<>
			<SEO status={200} />

			{
				display.is.mobile ?
					<MobileUI {...props}/> :
					<DesktopUI {...props}/>
			}
		</>
	);
}

export default React.memo(SearchUI);
