// retailer
import Client from "./retailer/Client"
import Evaluation from "./retailer/Evaluation"
import Me from "./retailer/Me"
// user
import VBasket from "./user/VBasket"

// general
export {default as PaymentResponse} from "./general/PaymentResponse"
export {default as Rate} from "./general/Rate"
export {default as VComment} from "./general/VComment"
export {default as VOrder} from "./general/VOrder"
export {default as VProdshop} from "./general/VProdshop"
export {default as VProduct} from "./general/VProduct"
export {default as VSale} from "./general/VSale"
export {default as VShop} from "./general/VShop"
export {default as VCollection} from "./general/collection/VCollection"
export {default as VShopCollection} from "./general/collection/VShopCollection"


export let marketer = {};
export let retailer = {Client, Evaluation, Me};
export let user = {VBasket};
